import React from "react";
import {WithPopover} from "../../../../components";
import {LiveMatchAdditionalInfo} from "./live-match-aditional-info";


export const LIVE_MATCH_ADD_INFO_SPORT_IDS = ['1', '2'];

const LiveMatchCompetitorHolder = ({match, isFirstInList, isLogged, viewSize}) => (
    <div className={`live-match__competitors-holder ${viewSize}`}>
        <div className="live-match__competitors">
            <WithPopover popoverClasses="ll-popover__text">
                <span className="live-match__competitor-text">
                    {match.competitorNames.competitor1}
                </span>
            </WithPopover>
            <WithPopover popoverClasses="ll-popover__text">
                <span className="live-match__competitor-text">
                    {match.competitorNames.competitor2}
                </span>
            </WithPopover>
        </div>
        { viewSize !== 'mobile' && match.isStreaming && match.streamingId && isLogged ? (
            <div className="live-match__stream-icon">
                <i className="doruk-streaming-icon"/>
            </div>
        ) : null }
        <div className="live-match__competitor-table-box">
            <div className="live-match__info">
                { LIVE_MATCH_ADD_INFO_SPORT_IDS.includes(match.sportId) &&
                    <LiveMatchAdditionalInfo
                        info={match.info}
                        sportId={match.sportId}
                        isFirstInList={isFirstInList}
                    />
                }
                <div className="live-match__info-item live-match__score">
                    { LIVE_MATCH_ADD_INFO_SPORT_IDS.includes(match.sportId) && (isFirstInList ?? true) &&
                        <span className="live-match__info-header-item">
                            <i className={`sport_icon_font-${match.sportId} live-match__info-header-icon--sport live-match__info-header-icon`} />
                        </span>
                    }
                    { match.sportId !== 138 &&
                        <>
                            <span>{match.matchScores[0]}</span>
                            <span>{match.matchScores[1]}</span>
                        </>
                    }
                </div>
            </div>
        </div>
    </div>
);

export default LiveMatchCompetitorHolder;