import { toast } from 'react-toastify';

import { trans } from '../../../common/utils';

const llNotifier = ({
    message,
    options = {},
    type = 'info',
    withTranslation = true,
}) => {
    switch (type) {
        case 'error':
            toast.error(withTranslation ? trans(message) : message, options);
            break;
        case 'warn':
            toast.warn(withTranslation ? trans(message) : message, options);
            break;
        case 'success':
            toast.success(withTranslation ? trans(message) : message, options);
            break;
        case 'info':
        default:
            toast.info(withTranslation ? trans(message) : message, options);
            break;
    }

    return null;
};

export default llNotifier;
