import React from "react";

import {WithPopover} from "../../../HOCs";


const LLWidgetBetsNames = ({names}) => {

    return (
        <div className="ll-widget-item__bets-names">
            {names.map((name, idx) =>
                <div
                    key={idx}
                    className="ll-widget-item__bet-name"
                >
                    <WithPopover>
                        <span>
                            {name}
                        </span>
                    </WithPopover>
                </div>
            )}
        </div>
    )
}

export default LLWidgetBetsNames;
