import React, {useState} from 'react';
import cx from 'classnames';

import {LLProgressBar} from '../../../../components';
import Markets from "./markets";


const CustomMarketGroup = (
    {
        marketGroup,
        marketGroupIndex,
        matchInstance,
        viewSize,
        isOldView,
        setOldView
    }
) => {
    const [isOpened, setOpened] = useState(marketGroupIndex === 0);

    return <div
        className="match-details__market custom-market-group"
    >
        <div
            className="match-details__market-header"
            onClick={() => setOpened(v => !v)}
        >
            <div className="match-details__market-header-name">
                <div dangerouslySetInnerHTML={{__html: marketGroup.name}}/>
            </div>
            <span className="match-details__market-expand">
                <i
                    className={cx(
                        'match-details__market-expand-icon llg-chevron-right',
                        isOpened && 'match-details__market-expand-icon--expanded'
                    )}
                />
            </span>
        </div>

        <div className={cx(
            'match-details__content',
            isOpened && 'match-details__content--opened'
        )}>
            <div className="ll-custom-bets__markets">
                {
                    (matchInstance?.customMarketGroups.get(marketGroup.id)
                        && matchInstance?.customMarketGroups.get(marketGroup.id).initialFetching) ?
                        <LLProgressBar
                            customClasses={`ll-custom-bets-${type}-match-details__progress-bar`}
                        />
                        :
                        <Markets
                            match={matchInstance}
                            marketGroup={matchInstance?.customMarketGroups.get(marketGroup.id)}
                            viewSize={viewSize}
                            oddAppearance={false}
                            isOldView={isOldView}
                            setOldView={setOldView}
                        />
                }
            </div>
        </div>
    </div>
};

export default CustomMarketGroup;