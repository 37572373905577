import React from 'react';

import {withCommon, LLElementTypeSetter, LLSlider} from '../../../components';
import {parseUrl, getS3ImageUrl} from "../../../common/utils";
import {urls} from "../../../configs";


/***************** ~~ Represents banner carousel for homepage ***************
 *
 * @param {array} slider - store home item, array with banners for homepage
 *
 * @author S.Nakhodov
 */

const HomeCarousel = withCommon({
    store: ({store}) => ({
        user: store.user,
    }),
})(({user, homeSlides, className, speed, sliderRef, autoplay = false, withOutDescription = false}) => (
    <LLSlider
        speed={speed}
        className={className}
        sliderHeight={'100%'}
        dots={true}
        arrows={false}
        infinite={true}
        autoplay={autoplay}
        sliderRef={sliderRef}
        autoplaySpeed={9000}
    >
        {homeSlides.map((slide) => {
            const linkAttr = parseUrl(slide.url, user);
            const nameCheck = slide.name.replace(/\s/g, '');

            return (
                <LLElementTypeSetter
                    key={slide.id}
                    className="home__slide-container"
                    {...linkAttr.elementAttributes}
                    onClick={linkAttr.someClick}
                >
                    <div
                        className="home__slide-image-container"
                    />
                    <div className="home__slide-shadow"/>
                    <img className="home__slide-image"
                         src={getS3ImageUrl() + '/images/banners/' + user.customerGroup + '/' + slide.image}
                         alt="" title=""
                    />
                    {nameCheck.length ?
                        <div className="home__slide-description-container">
                            <div className="home__slide-description">
                                <p className={'home__slide-title'} dangerouslySetInnerHTML={{
                                    __html: slide.name,
                                }}/>
                                {!withOutDescription && <p className="home__slide-text" dangerouslySetInnerHTML={{
                                    __html: slide.description,
                                }}/>
                                }
                            </div>
                        </div>
                        : null}
                </LLElementTypeSetter>
            );
        })}
    </LLSlider>
));

export default HomeCarousel;