import { values } from 'lodash';

/*
 #######  ##     ## ######## ########  ####  ######   ##     ## ########  ######
##     ## ##     ##    ##    ##     ##  ##  ##    ##  ##     ##    ##    ##    ##
##     ## ##     ##    ##    ##     ##  ##  ##        ##     ##    ##    ##
##     ## ##     ##    ##    ########   ##  ##   #### #########    ##     ######
##     ## ##     ##    ##    ##   ##    ##  ##    ##  ##     ##    ##          ##
##     ## ##     ##    ##    ##    ##   ##  ##    ##  ##     ##    ##    ##    ##
 #######   #######     ##    ##     ## ####  ######   ##     ##    ##     ######
*/

const outrightActions = (s) => ({
    /**
     * @desc puts and update outright bets data
     * @property {object} dataSource
     * @property {object[]} dataSource.data - outright bets
     * @property {object} dataSource.sesi - outright bets sesi
     */

    putUpdateOutrightBets({ dataSource }) {
        {
            dataSource.data.map((newBet) => {
                newBet.name = newBet.name.toString();
                if (!s.bets.has(newBet.id)) {
                    newBet.outcomeValue = newBet.value;
                    s.bets.put(newBet);
                } else {
                    s.bets.get(newBet.id).update(newBet);
                }
            });
        }
    },

    /**
     * @desc delete outright from all instances
     * @property {string} betId - id of outright that need to be deleted
     */

    deleteOutrightInstances({ betId }) {
        s.bets.delete(betId);
    },

    /**
     * @desc compare outright bets and delete old ones
     * @property {object[]} incomingBets - bets that comes from fetch
     */
    deleteOldBets({ incomingBets }) {
        values(s.bets)
            .filter(
                (id) => !incomingBets.some(({ id: incomingId }) => incomingId === id)
            )
            .map(({ id: oldId }) => {
                s.deleteOutrightInstances({
                    betId: oldId,
                });
            });
    },
});

export default outrightActions;
