import React from "react";
import {inject, observer} from "mobx-react";


const TutorialPopup = ({
                           store,
                           indicatorPosition = 'left',
                           popupPosition = 'left',
                           showIndicator = true,
                           content
                       }) => {

    const viewSize = store.site.status.viewSize;

    return (
        <div className={`battle-tutorial-popup battle-tutorial-popup--${popupPosition} battle-tutorial-popup--${viewSize}`}
            onClick={(e) => e.stopPropagation()}
        >
            {showIndicator && <div className={`battle-tutorial-popup__parent-indicator battle-tutorial-popup__parent-indicator--${indicatorPosition}`} />}
            {content}
        </div>
    );
};

export default inject('store')(observer(TutorialPopup));
