import {getRoot, types, types as t} from 'mobx-state-tree';
import {formatNumber} from "../../../../../common/utils";
import React from "react";

const Bet = t
    .model('Bet', {
        id: t.identifier,
        specifier: t.string,
        outcomeValue: t.number,
        name: t.maybeNull(t.string),
        order: t.maybeNull(types.number), /* o */
        available: true,
    })
    .actions((s) => ({
        update({ specifier, value, name, order }) {
            s.specifier = specifier;
            s.outcomeValue = value;
            s.order = order;
            name && (s.name = name);
        },
        placeBet() {
            const user = getRoot(s).user;
            if (!user.isLogged) {
                user.openLogin();
            } else {
                if (!user.coupons.fetching) {
                    const coupon = user.coupons.coupons.get(s.id);
                    if (!coupon) {
                        user.coupons.add({
                            type: 'OutRight',
                            oddsId: s.id,
                            source: 1,
                        });
                    } else {
                        coupon.remove();
                    }
                }
            }
        },
    }))
    .views((s) => ({
        get isActive() {
            return !!getRoot(s)?.user?.coupons?.coupons.get(s.id);
        },
        get roundedValue() {
            return Math.floor(s.outcomeValue * 100) / 100
        },
        get renderOdd() {
           if (s.roundedValue > 1) {
                return formatNumber(
                    s.outcomeValue,
                    2,
                    getRoot(s).user.language
                );
            } else {
                return '—';
            }
        },
    }));

export default Bet;
