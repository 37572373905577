import { types as t, getRoot } from 'mobx-state-tree';

const BranchItem = t
    .model('BranchItem', {
        id: t.identifier,
        order: t.maybeNull(t.integer),
        outrightCount: t.maybeNull(t.integer),
        matchCount: t.maybeNull(t.integer),
        name: t.maybeNull(t.string),
    })
    .actions((s) => ({
        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                        Updater                                       ##
        // ##                                                                                      ##
        // ##========================================================================================

        update({ matchCount = 0, order, outrightCount = 0, parentId, name }) {
            s.matchCount = matchCount;
            s.order = order;

            s.outrightCount = outrightCount;
            /**** ~~ Where parentId is comming from ?
             * @desc parentId is comming from combine branchItem and sportChidren model
             * this thing need to show sport inside sport in menu
             *  ****/
            parentId && (s.parentId = parentId);

            name && (s.name = name);
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                           Branch instance fetch time setter                          ##
        // ##                                                                                      ##
        // ##========================================================================================

        setLastFetchTime() {
            s.lastFetchTime = Date.now();
        },

        setFetching({ status, type }) {
            const betting = getRoot(s).betting;

            if (status && type === 'initialFetching') {
                !betting.requestedDataLoading &&
                    betting.setFetching({
                        type: 'isMenuDisabled',
                        status: true,
                    });
            } else {
                betting.setFetching({
                    type: 'isMenuDisabled',
                    status: false,
                });
            }
            s[type] = status;
        },

        setMatchCount(count) {
            s.matchCount = count;
        },
    }))
    .views((s) => ({}));

export default BranchItem;
