import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import {LLElementTypeSetter, LLScrollBox, withCommon} from '../../';
import {getS3ImageUrl} from "../../../common/utils";


const LLMenuItem = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
        mobileUserAgent: store.user.mobileUserAgent,
        isLogged: store.user.isLogged,
        openLogin: store.user.openLogin,
        setModal: store.modal.setModal,
        setGame: store.modal.setGame,
        liveMatches: store.betting.liveMatches,
        pathname: store.router.location.pathname,
        activeBattleRoomsCount: store.slotsBattles.activeBattleRoomsCount,
    }),
    isTrans: true,
    isObserver: true,
})((
    {
        trans,
        viewSize,
        isLogged,
        mobileUserAgent,
        openLogin,
        setModal,
        setGame,
        liveMatches,
        pathname,
        activeBattleRoomsCount,
        //menu item props
        item = {},
        elementAttributes = item.elementAttributes,
        elementAttributesMobile = item.elementAttributesMobileUserAgent,
        customClasses,
        icon,
        iconPosition = 'before',
        label,
        triggeredOnClick = true,
        triggeredOnHover = true,
        hasDropDownIndicator = false,
        activeIfOpened = false,
        backgroundThumbnail  = null,
        //
        //sub menu item props
        subItemCloseTrigger,
        resetSubItemTrigger,
        submenu,
        isAccordionSubmenu,
        subItemClasses,
        placement = 'bottom',
        subItemInParent = false,
        withOutAwayListener = false,
        id,
        //
        ...rest
    }
) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setOpen] = useState(false);

    /*Modify URL for live events to contain first match if present is store */
    if (item?.elementAttributes?.to === '/sport/live' && liveMatches.getFirstLiveMatchUrl()) {
        item.elementAttributes.to = liveMatches.getFirstLiveMatchUrl()
    }

    const itemRef = useRef();
    const isSlotBattleCounter = !!(item.name === 'SLOT_BATTLE' && activeBattleRoomsCount);

    let bananaDefender = true;

    useEffect(() => {
        if (bananaDefender && !subItemCloseTrigger) {
            setAnchorEl(null);
            setOpen(false);
        }
    }, [subItemCloseTrigger]);

    useEffect(() => {
        bananaDefender && setAnchorEl(null);
    }, [window.innerWidth]);

    useEffect(
        () => () => {
            bananaDefender = false;
        },
        []
    );

    /***************** CREATE MENU ITEM ADDITIONAL PROPS ****************/
    const propObj = {};

    propObj.onClick = (e) => {
        if (submenu?.length && isAccordionSubmenu) {
            setOpen(v => !v);
        }
        rest?.onClick && rest.onClick(e);
        if (triggeredOnClick && item.as !== 'NavLink') {
            setAnchorEl(itemRef.current);
            setOpen(!isOpen);
        }
    };

    if (id) propObj.id = id;

    /* Modify isActive prop for NavLink for live page */
    if (item?.elementAttributes?.to && item?.elementAttributes?.to.includes('/sport/live') && pathname && pathname.includes('/sport/live')) propObj.isActive = () => true;

    if (triggeredOnHover && !mobileUserAgent) {
        propObj.onMouseEnter = (e) => {
            setAnchorEl(e.currentTarget);
            setOpen(true);
        };
        propObj.onMouseLeave = () => {
            setOpen(false);
        };
    }

    /***************** CREATE SUBMENU ITEM ADDITIONAL PROPS ****************/
    const subItemPropObj = {};
    if (triggeredOnHover && !mobileUserAgent) {
        subItemPropObj.onMouseEnter = () => {
            anchorEl && anchorEl.classList.add(`${anchorEl.classList[0]}--hover`);
            setOpen(true);
        };
        subItemPropObj.onMouseLeave = () => {
            anchorEl && anchorEl.classList.remove(`${anchorEl.classList[0]}--hover`);
            setOpen(false);
        };
    }

    const renderSubMenu = () => <Popper
        open={isOpen}
        {...subItemPropObj}
        anchorEl={anchorEl}
        placement={placement}
        className={cx(
            `menu-sub-item--${viewSize}`,
            subItemClasses
        )}
        transition
        disablePortal={subItemInParent}
        modifiers={[
            {
                name: 'preventOverflow',
                enabled: true,
            }
        ]}
    >
        {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={100}>
                <Box>
                    <LLScrollBox id={item.id} autoHeightMax={'50vh'}>
                        {submenu}
                    </LLScrollBox>
                </Box>
            </Fade>
        )}
    </Popper>

    /************************* RENDER **************************/
    return (
        <>
            <LLElementTypeSetter
                ref={itemRef}
                className={cx(
                    `menu-item--${viewSize}`,
                    customClasses,
                    (isOpen && activeIfOpened) ? 'active' : ''
                )}
                as={item.as}
                {...propObj}
                {...(mobileUserAgent
                    ? elementAttributesMobile || elementAttributes
                    : elementAttributes)}
                /****************** add custom click events *******************/
                {...((item.elementAttributes?.onClick ||
                    item.elementAttributesMobile?.onClick) && {
                    onClick: (e) => {
                        propObj?.onClick && propObj.onClick();
                        if (item.gameId && !isLogged) {
                            e.preventDefault();
                            openLogin();
                        }
                    },
                })}
                /****************** -------------------- *******************/
            >
                {item.thumbnail && (
                    <div
                        className="footer-featured__background-img"
                        style={{
                            backgroundImage: `url("${getS3ImageUrl()}/images/thumbnails/${
                                item.thumbnail
                            }")`,
                        }}
                    >
                        <div className="footer-featured__background-img-blur" />
                    </div>
                )}
                {icon && iconPosition === 'before' && <>{icon}</>}
                {/** custom icon object **/}

                <span className="menu-item__label">
                    {label ? label : trans(item.name)} {/** custom label object **/}
                </span>

                {(isAccordionSubmenu && submenu?.length) && (
                    <i className={cx(
                        'doruk-mg-arrow-2',
                        'submenu-icon', isOpen && 'submenu-icon--opened'
                    )} />
                )}

                {icon && iconPosition === 'after' && <>{icon}</>}
                {/** custom icon object **/}

                {(item.isNew || isSlotBattleCounter) && (
                    <div className="menu-item__is-new-indicator-wrapper menu-item__is-new-indicator--animated">
                        <div className="menu-item__is-new-indicator">
                            <div className="menu-item__is-new-indicator-label">
                                {isSlotBattleCounter
                                    ? activeBattleRoomsCount
                                    : trans('New')}
                            </div>
                        </div>
                    </div>
                )}

                {hasDropDownIndicator && (
                    <i className="dx-icon-spindown ll-dropdown-menu__icon" />
                )}

                {/** submenu item **/}
                {(submenu?.length && anchorEl && !isAccordionSubmenu) ?
                    withOutAwayListener ? (
                        renderSubMenu()
                    ) : (
                        <ClickAwayListener
                            onClickAway={(e) => {
                                if (
                                    e.target !== anchorEl &&
                                    e.target.parentElement !== anchorEl
                                ) {
                                    // only for event target not equal anchorEl (submenu parent)
                                    !!resetSubItemTrigger && resetSubItemTrigger();
                                    setOpen(false);
                                }
                            }}
                            mouseEvent={'onMouseDown'}
                            touchEvent={'onTouchStart'}
                        >
                            {renderSubMenu()}
                        </ClickAwayListener>
                    ) : null}
            </LLElementTypeSetter>

            {(submenu?.length && isAccordionSubmenu) ? (
                <div className={cx(
                    'menu-item-accordion-content',
                    isOpen && 'menu-item-accordion-content--opened'
                )}>
                    {submenu}
                </div>
            ) : null}
        </>
    );
});

export default LLMenuItem;
