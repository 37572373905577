import React, {useState} from 'react';
import {CustomRule, Validator} from "devextreme-react/validator";

import {LLBtn2, LLInput} from "../../../components";
import {trans} from "../../../common/utils";


export const SlotBattlesPrivatePassForm = ({
    setLoading,
    setPassForm,
    joinBattle,
    roomId,
}) => {
    const [privateBattlePass, setPrivateBattlePass] = useState('');

    return (
        <div className="slots-battles__private-pass-form"
            onClick={(e) => e.stopPropagation()}
        >
            <div className="slots-battles__private-pass-form-inner">
                <LLInput
                    customClasses={'slots-battles__private-pass'}
                    maxLength={16}
                    value={privateBattlePass}
                    onValueChanged={(e) => setPrivateBattlePass(e.value)}
                    label={trans('Password')}
                    labelPosition="inside"
                    stylingMode="outlined"
                    showClearButton={true}
                >
                    <Validator>
                        <CustomRule
                            message={trans('PASSWORD_EMPTY')}
                            validationCallback={() =>
                                privateBattlePass.length
                            }
                        />
                    </Validator>
                </LLInput>
                <div className="slots-battles__private-pass-form-buttons">
                    <LLBtn2
                        onClick={() => {
                            setLoading(true);
                            joinBattle({roomId, privatePWD: privateBattlePass}).then(() => {
                                setLoading(false);
                                setPassForm(false);
                            });
                        }}
                        disabled={(!privateBattlePass.length) ? "disabled" : ""}
                        customClasses={"slots-battles__btn--accent"}
                    >
                        {trans("Ok")}
                    </LLBtn2>
                    <LLBtn2
                        onClick={() => {
                            setPassForm(false)
                        }}
                        customClasses={"slots-battles__btn--outlined"}
                    >
                        {trans("Cancel")}
                    </LLBtn2>
                </div>
            </div>
        </div>
    );
};