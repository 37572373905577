const urls = {
    customerNotification: !!['localhost', '-dev'].find(el => window.location.href.includes(el)) ?
        'https://customer-api-dev.doruk.tech/CustomerNotification?token='
        : window.location.href.includes('-int') ?
            'https://customer-api-int.doruk.tech/CustomerNotification?token='
            : window.location.href.includes('-stg') ?
            'https://customerservice-prod.doruk.tech/CustomerNotification?token='
            : 'https://cs.betting.works/CustomerNotification?token=',
};

export default urls;