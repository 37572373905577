import  React, {useEffect, useState} from 'react';

import {LLPreloader, withCommon} from '../../components';
import {SlotBattlesHeader, SlotsBattlesList} from './components';
import {CreateBattle} from "./components/create-battle/create-battle";
import {SlotsBattleInfo} from './components/battle-detail/battle-info';
import LLBackdrop from "../../layout/backdrop/backdrop";
import FirstModal from "./components/tutorials/first-modal";

import Image from "../../static/themes/theme/images/slots-and-casino/no-games.png";
import SecondModal from "./components/tutorials/second-modal";
import {
    SLOT_BATTLES_SORTBY_FILTERS,
    SLOT_BATTLES_GAMES_SORTBY_FILTERS,
} from './config';

let isSlotsBattlesMounted;


const SlotsBattles = withCommon({
    store: ({store}) => ({
        slotsBattles: store.slotsBattles,
        storeSearch: store.slotsBattles.slotBattlesSearch,
        tutorialStep: store.slotsBattles.tutorialStep,
        provider: store.slotsBattles.slotBattlesChosenProvider,
        sortby: store.slotsBattles.slotBattlesChosenSortby,
        slotBattlesFetchTime: store.slotsBattles.slotBattlesFetchTime,
        activeBattle: store.slotsBattles.activeBattle,
        slotBattlesType: store.slotsBattles.slotBattlesType,
        infoLoading: store.slotsBattles.slotBattlesBattleInfoLoading,
        slotBattlesGlobalError: store.slotsBattles.slotBattlesGlobalError,
        tutorialNeedsToBeShowed: store.slotsBattles.tutorialNeedsToBeShowed,
        setTutorialNeedsToBeShowed: store.slotsBattles.setTutorialNeedsToBeShowed,
        viewSize: store.site.status.viewSize,
        isLogged: store.user.isLogged,
        isBackdropShown: store.site.status.isBackdropShown,
        isBackDropFullScreen: store.site.status.isBackDropFullScreen,
        currency: store.user.currency,
        canPlaySlotBattles: store.user.canPlaySlotBattles,
        router: store.router,
        setModal: store.modal.setModal,
        isModalActive: store.modal.isActive,
    }),
    defaultName: 'SlotsBattle',
    isObserver: true,
    isTrans: true,
})(({
        slotsBattles,
        storeSearch,
        provider,
        sortby,
        slotBattlesFetchTime,
        slotBattlesType,
        activeBattle,
        tutorialStep,
        viewSize,
        isLogged,
        isBackdropShown,
        isBackDropFullScreen,
        currency,
        infoLoading,
        slotBattlesGlobalError,
        tutorialNeedsToBeShowed,
        setTutorialNeedsToBeShowed,
        router,
        setModal,
        isModalActive,
        canPlaySlotBattles,
        trans,
    }) => {

    const [rooms, setRooms] = useState([])
    const [isNoData, setIsNoData] = useState(false);

    const defaultFilter = () => {
        let newFilter = {room_status: ['room_status', '=', slotBattlesType]};
        if (slotBattlesType === 'completed') {
            newFilter = {
                ...newFilter,
            };
        }
        if (isLogged && slotBattlesType === 'available') {
            newFilter = {
                ...newFilter,
                Currency: ['Currency', '=', currency],
            };
        }
        return newFilter;
    };

    const isBackdrop = isBackdropShown && !isBackDropFullScreen;

    const
        [filter, setFilter] = useState(defaultFilter()),
        [sort, setSort] = useState([{selector: "idt", desc: true}]);

    useEffect(() => {
        if (isSlotsBattlesMounted) {
            if (provider && provider !== 'All') {
                setFilter({
                    ...filter,
                    BrandName: ['BrandName', '=', provider]
                });
            } else {
                setFilter(defaultFilter());
            }
        }
    }, [provider]);

    useEffect(() => {
        if (isSlotsBattlesMounted) {
            if (storeSearch) {
                setFilter({
                    ...filter,
                    GameName: ['GameName', 'contains', storeSearch]
                });
            } else {
                setFilter(defaultFilter());
            }
        }
    }, [storeSearch]);

    useEffect(() => {
        if (isSlotsBattlesMounted) {
            if (provider || sortby || storeSearch) {
                slotsBattles.setSlotBattlesProvider('');
                slotsBattles.setSlotBattlesSortby('');
                slotsBattles.setSlotBattlesSearch('');
            }

            setFilter(defaultFilter());

            if (!isLogged && router.location.pathname === '/slots-battles/create') {
                router.push('/slots-battles/available');
            }
        }
    }, [slotBattlesType, isLogged]);

    useEffect(() => {
        if (isSlotsBattlesMounted) {
            if (sortby) {
                if (sortby === 'My battles') {
                    if (slotBattlesType === 'completed') {
                        setFilter({
                            ...filter,
                            idt: ['idt', '>', new Date(Date.now() - 21 * 60 * 60 * 1000).toISOString()],
                        });
                    }
                    setSort({
                        type: 'My battles',
                        selector: 'idt',
                        direction: 'desc'
                    });
                } else {
                    setSort(
                        (slotBattlesType === 'create' ?
                                SLOT_BATTLES_GAMES_SORTBY_FILTERS :
                                SLOT_BATTLES_SORTBY_FILTERS
                        )[sortby]
                    )
                }
            } else {
                if (slotBattlesType === 'completed') {
                    setFilter({
                        ...filter,
                        idt: ['idt', '>', new Date(Date.now() - 3 * 60 * 60 * 1000).toISOString()],
                    })
                }
                setSort([{selector: "idt", desc: true}])
            }
        }
    }, [sortby]);

    useEffect(() => {
        slotsBattles.getSlotBattlesFilter().then(() => {
            isSlotsBattlesMounted = true;
        });
        return () => isSlotsBattlesMounted = false;
    }, []);

    useEffect(() => {
        slotsBattles.getSlotBattlesBanner().then();
    }, [isLogged]);

    useEffect(() => {
        if (tutorialNeedsToBeShowed) {
            startTutorial();
        }
    }, [tutorialNeedsToBeShowed]);

    useEffect(() => {
        if (!isModalActive) {
            setTutorialNeedsToBeShowed(false);
        }
    }, [isModalActive]);

    useEffect(() => {
        if (tutorialStep === 3)
            setModal({
                active: true,
                maskBackground: true,
                title: trans("SLOT_BATTLE__TUTORIAL_STEP_4_HEADER"),
                currentKey: "custom-content",
                closeOnOuterClick: false,
                customContent: <SecondModal/>,
                customClasses: 'll-modal--battles-tutorial'
            })
    }, [tutorialStep]);

    const startTutorial = () => {
        setModal({
            active: true,
            maskBackground: true,
            title: trans("SLOT_BATTLE__TUTORIAL_STEP_1_HEADER"),
            showCloseButton: true,
            currentKey: "custom-content",
            closeOnOuterClick: false,
            customContent: <FirstModal/>,
            customClasses: 'll-modal--battles-tutorial ll-modal--battles-tutorial--first-step'
        })
    };

    return (
        <div className={`slots-battles slots-battles--${viewSize}`}>
            {slotBattlesGlobalError ?
                <div className="games-no-data">
                    <p className="games-no-data__text">{trans('SLOT_BATTLE__GENERAL_ERROR')}</p>
                    <img src={Image} className="games-no-data__img" alt="no-games-found"/>
                </div>
                :
                !isSlotsBattlesMounted && !slotBattlesFetchTime && slotBattlesType !== 'create' ?
                    <div className="slots-battles__loading">
                        <LLPreloader/>
                    </div>
                    :
                    <>
                        <SlotBattlesHeader {...{rooms}}/>
                        <div
                            className={`slots-battles__content ${!canPlaySlotBattles && 'slots-battles__content--disabled'} ${
                                slotBattlesType === 'create' ? 'slots-battles__content--new' : ''
                            }`}>
                            <div
                                className={`slots-battles-wrp ll-full-width-content-wrapper ${isNoData && 'slots-battles-wrp--no-data'}`}>
                                {(activeBattle && infoLoading) ?
                                    <div className="slots-battles__loading">
                                        <LLPreloader/>
                                    </div>
                                    :
                                    activeBattle ?
                                        <SlotsBattleInfo/>
                                        :
                                        slotBattlesType === 'create' ?
                                            <CreateBattle/>
                                            :
                                            <SlotsBattlesList {...{
                                                filter,
                                                sort,
                                                rooms,
                                                setRooms,
                                                setIsNoData,
                                            }} />
                                }
                            </div>
                        </div>
                        {isBackdrop && <LLBackdrop/>}
                    </>
            }
        </div>
    );
});

export default SlotsBattles;
