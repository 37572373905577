import React from 'react';
import PropTypes from 'prop-types';

import { withCommon } from '../../components';
import configUi from './config-ui';

/**
 * @file Represents About Us page
 *
 * @param {string} email - user email
 * @param {string} language - active site language
 * @param {string} siteName - site name
 *
 * @author A.Green
 */

const ContactUs = withCommon({
    store: ({ store }) => ({
        siteName: store.site.siteName,
        language: store.user.language,
        config: store.modifiedConfig,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        email: PropTypes.string,
        siteName: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
    },
    isTrans: true,
})(({ trans, language, siteName, config, viewSize }) => {
    let name = configUi[siteName] ? configUi[siteName] : configUi['Default'];

    return (
        <div className={`ll-page help help-${viewSize}`}>
            <div className="help__header">{trans('Contact Information')}</div>
            <div className="help__description">
                <p>{name[language].contactAnswersRow}</p>
                <p>{name[language].contactEmailRow}</p>
            </div>
            <div className="help__tiles-container">
                {name.showSupportBlock && (
                    <div className="help__tile">
                        <div className="help__tile-text-container">
                            <span className="help__tile-text__title">
                                {trans('Live support')}
                            </span>
                            <span className="help__tile-text">
                                {name[language].liveSupportBlock}
                            </span>
                        </div>
                    </div>
                )}
                <div className="help__tile">
                    <div className="help__tile-text-container">
                        {!!config.siteConfig.email && <span className="help__tile-text__title">{trans('E-Mail')}</span>}
                        <span className="help__tile-text">
                            {name[language].emailBlock}
                        </span>
                        {(configUi[siteName] && !!config.siteConfig.email) ? <a href={`mailto:${config.siteConfig.email}`}>{config.siteConfig.email}</a> : ''}
                    </div>
                </div>
            </div>
            <div className="help__description">
                <p>{name[language].notForgetRow}</p>
            </div>
        </div>
    );
});

//...

export default ContactUs;
