import {types as t, getRoot, flow} from 'mobx-state-tree';

import api from '../../../../common/api';

/* Variables for global reusage */
import {refreshTime} from '../config';

/* Commands */
import {fetchActions, commonActions} from './matches-actions';

/* Instructions */
import {
    topFavortieInstructions,
    singleInstuructions,
    allInstructions,
} from './matches-instructions';

import {MatchInstance, TVChanelItem} from './instances';
import {values} from 'mobx';
import WithFlags from '../with-flags';

const WaitingItems = t
    .model('WaitingItems', {
        //items for parentId (categoryId...) and boolean topAndFavorites waiting flag
        all: t.maybeNull(t.string),
        topAndFavorites: false,
        singleMatch: t.maybeNull(t.string),
        singleTournament: t.maybeNull(t.string),
    })
    .actions((s) => ({
        setItem(type, status) {
            s[type] = status;
        },
        clearAll() {
            s.all = null;
            s.topAndFavorites = false;
            s.singleMatch = null;
            s.singleTournament = null;
        },
    }));

const Matches = t
    .model('Matches', {
        matches: t.map(t.compose(WithFlags, MatchInstance)),
        matchTV: t.array(TVChanelItem),
        isWaiting: t.optional(WaitingItems, {}),
    })
    .extend((s) => {
        return {
            actions: {
                // ##========================================================================================
                // ##                                                                                      ##
                // ##                                 Commands and fetches                                 ##
                // ##                                                                                      ##
                // ##========================================================================================

                ...commonActions(s),
                ...fetchActions(s),

                // ##========================================================================================
                // ##                                                                                      ##
                // ##                                     Instructions                                     ##
                // ##                                                                                      ##
                // ##========================================================================================

                ...singleInstuructions(s),
                ...allInstructions(s),
                ...topFavortieInstructions(s),
            },
        };
    })
    .actions((s) => ({
        setFetching({status, type}) {
            s[type] = status;
        },

        setWaitingItem(type, status) {
            s.isWaiting.setItem(type, status);
        },

        setTopAndFavoritesBranchChanged(status) {
            s.topAndFavoritesParentBranchChanged = status;
        },

        deleteItem({id}) {
            s.matches.delete(id);
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                       Get tv list for match                                          ##
        // ##                                                                                      ##
        // ##========================================================================================
        getChannels: flow(function* fetch(params) {
            const {data, success} = yield api.betting.getMatchTV(params);
            if (success) {
                s.matchTV = data;
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                       Set update refresh  for specific instance                      ##
        // ##                                                                                      ##
        // ##========================================================================================

        defineUpdateFor({
                            instance,
                            tournamentId,
                            categoryId,
                            sportId,
                            matchId,
                            timeLeft,
                            dropDownRequest,
                            selfTournament,
                        }) {
            const instructionsAlias = {
                TopAndFavorite: () => s.updateForTopAndFavoriteInstruction(),
                Single: () =>
                    s.updateForSingleInstruction({
                        tournamentId,
                        categoryId,
                        sportId,
                        matchId,
                        dropDownRequest,
                        selfTournament,
                    }),
                All: () =>
                    s.updateForAllInstruction({
                        sportId,
                        categoryId,
                        dropDownRequest,
                    }),
            };

            const type = {
                TopAndFavorite: 'topAndFavorites',
                Single: matchId ? 'singleMatch' : 'singleTournament',
                All: 'all',
            };

            const parentId = {
                TopAndFavorite: true,
                Single: matchId ?? tournamentId,
                All: categoryId,
            };

            if (
                !s.isWaiting[type[instance]] &&
                !getRoot(s).betting.bettingErrors.global &&
                !getRoot(s).betting.bettingErrors.list
            ) {
                s.setWaitingItem(type[instance], parentId[instance]);
                clearTimeout(window.__matchesUpdater);
                window.__matchesUpdater = setTimeout(
                    () => instructionsAlias[instance](),
                    timeLeft ?? refreshTime.matchesList
                );
            }
        },

        clearUpdater() {
            clearTimeout(window.__matchesUpdater);
            s.isWaiting.clearAll();
        },
    }))
    .views((s) => ({
        get check() {
            const betting = getRoot(s).betting;

            return {
                matchLastFetchTimeNotExpired: (matchId) => {
                    const match = s.matches.get(matchId);
                    return !!(
                        match &&
                        match.lastFetchTime &&
                        Date.now() <
                        +new Date(
                            +s.matches.get(matchId).lastFetchTime +
                            refreshTime.matchesList
                        )
                    );
                },

                tournamentLastFetchTimeNotExpired({tournament} = {}) {
                    return !!(
                        tournament &&
                        tournament.lastFetchTime &&
                        Date.now() <
                        +new Date(
                            +tournament?.lastFetchTime + refreshTime.matchesList
                        )
                    );
                },

                get categoryLastFetchTimeNotExpired() {
                    const category = getRoot(s).betting.branch.activeCategory();
                    return !!(
                        category &&
                        category.lastAllMatchesFetchTime &&
                        Date.now() <
                        +new Date(
                            +category?.lastAllMatchesFetchTime +
                            refreshTime.matchesList
                        )
                    );
                },

                isMatchesUpdatable:
                    getRoot(s).site?.status.isActive &&
                    !betting.branch.search.searchString &&
                    betting.sportMountStatus,

                isTopAndFavoriteUpdateCleanable: !!(
                    !!betting.activeItems.tournaments.length
                ),
            };
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                 Active branch getter                                 ##
        // ##                                                                                      ##
        // ##========================================================================================

        get getMatchesTV() {
            return values(s.matchTV);
        },
    }));

export default Matches;
