import {Link} from 'react-router-dom';
import cx from 'classnames';

import {getS3ImageUrl} from '../../../common/utils';


const LobbyItem = ({lobby, viewSize}) => {
    const iframeUrl = `/lobby?providerId=${lobby.providerId}`;
    const imgUrl = `${getS3ImageUrl()}/images/lobbies/${lobby.image}`;

    return (
        <div className="lobby-item">
            <div className="lobby-item__title">
                {lobby.name}
            </div>
            <div className="lobby-item__wrp">
                <div className={cx(
                    'lobby-item__body',
                    (viewSize === 'mobile' && lobby.settings?.mobile?.length > 1)
                        && 'lobby-item__body--compact'
                )} style={{
                    backgroundImage: `url('${imgUrl}')`,
                    '--lobby-bg-color': lobby.backgroundColor,
                    '--lobby-color': lobby.textColor,
                }}>
                    <div className="lobby-item__body-content">
                        {lobby.labelText?.length > 0 && (
                            <span className="lobby-item__label-text">
                                {lobby.labelText}
                            </span>
                        )}
                        <Link className="lobby-item__btn" to={iframeUrl}>
                            {lobby.buttonText}
                        </Link>
                    </div>
                </div>
                {lobby.settings ?
                    <div className="lobby-item__games">
                        { lobby.settings[viewSize === 'desktop' ? 'desktop' : 'mobile'].map(game => (
                            <div className="lobby-item__game"
                                 key={game.gameId}
                                 style={{backgroundImage: `url(${game.thumbnail})`}}
                            />
                        ))}
                    </div>
                    : null
                }
            </div>
        </div>
    );
};

export default LobbyItem;