import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { trans } from '../../common/utils';

/**
 * Represents a with common hoc that injects all common things.
 *
 * @param {object} props - component properties
 * @param {(string,func)} store - what we need from store ('store' - all store) or (stores)=>stores.store...
 * @param {object} isObserver - do we need observer or not
 * @param {object} isTrans - do we need trans or not
 * @param {object} isRouter - do we need router or not
 *
 * @author S.TARNAVSKI
 */

const withCommon = (props = {}) => {
    const {
        store,
        // eslint-disable-next-line react/forbid-foreign-prop-types
        propTypes,
        displayName = null,
        isObserver = false,
        isTrans = false,
        isRouter = false,
        defaultProps = {},
    } = props;

    const commonInject = (Component) => {
        const componentInject = (props) => {
            if (isTrans) {
                return <Component trans={trans} {...props} />;
            } else {
                return <Component {...props} />;
            }
        };

        const doInject = () => {
            if (store && !isObserver && !isRouter) {
                return inject(store)(componentInject);
            }
            if (store && isObserver && !isRouter) {
                return inject(store)(observer(componentInject));
            }
            if (isRouter && !store && !isObserver) {
                return withRouter(componentInject);
            }
            if (isRouter && store && !isObserver) {
                return inject(store)(withRouter(componentInject));
            }
            if (isRouter && store && isObserver) {
                return inject(store)(withRouter(observer(componentInject)));
            } else {
                return componentInject;
            }
        };
        componentInject.defaultProps = {
            ...defaultProps,
        };
        componentInject.displayName = displayName ? displayName : Component.name;

        componentInject.propTypes = {
            ...propTypes,
        };
        return doInject();
    };

    return commonInject;
};

export default withCommon;
