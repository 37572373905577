import React, { useRef, useEffect } from 'react';
import { throttle } from 'lodash';
import cx from 'classnames';

import { inject, observer } from 'mobx-react';


const LLScrollBox = ({
    store,
    id = 'none',
    onScroll,
    children,
    customClasses,
    height = '100%',
    autoHeightMax = '100%',
    stayOnScroll = true,
    ...restProps
}) => {
    const scrollBoxes = store.scrollBoxes,
        viewSize = store.site.status.viewSize;

    const scrollViewRef = useRef();

    const handleScroll = throttle(() => {
        if (!scrollViewRef.current) return;

        if (scrollBoxes.getScrollBox(id))
            scrollBoxes
                .getScrollBox(id)
                .setScrollPosition(+scrollViewRef.current.scrollTop);

        if (onScroll) onScroll(+scrollViewRef.current.scrollTop);
        if (!stayOnScroll) store.popover.hidePopover();
    }, 200);

    const handleHidePopover = () => {
        store.popoverCashout.hidePopover()
    }

    let bananaDefender = true;

    useEffect(
        () => () => {
            bananaDefender = false;
        },
        []
    );

    useEffect(() => {
        if (scrollViewRef.current && bananaDefender) {
            scrollBoxes.putScrollBox({
                id,
                methods: {
                    scrollTop(top) {
                        scrollViewRef.current.scrollTo({
                            top: top,
                            behavior: 'smooth',
                        });
                    },
                    scrollToTop() {
                        scrollViewRef.current.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    },
                    scrollToElement(id, top = 0, behavior = 'smooth') {
                        const element = document.querySelector(id);
                        if (element) {
                            scrollViewRef.current.scrollTo({
                                top: element.offsetTop - top,
                                behavior: behavior,
                            });
                        }
                    },
                    scrollLeft(left) {
                        scrollViewRef.current.scrollTo({
                            left: left,
                            behavior: 'smooth',
                        });
                    },
                    scrollToBottom(bottom) {
                        scrollViewRef.current.scrollTo({
                            bottom: bottom,
                            behavior: 'smooth',
                        });
                    },
                    scrollToLeft() {
                        scrollViewRef.current.scrollTo({
                            left: 0,
                            behavior: 'smooth',
                        });
                    },
                    scrollToRight(right) {
                        scrollViewRef.current.scrollTo({
                            right: right,
                            behavior: 'smooth',
                        });
                    },
                    getScrollLeft: scrollViewRef.current.offsetLeft,
                    getScrollTop: scrollViewRef.current.offsetTop,
                    getScrollWidth: scrollViewRef.current.offsetWidth,
                    getScrollHeight: scrollViewRef.current.offsetHeight,
                    getClientWidth: scrollViewRef.current.clientWidth,
                    getClientHeight: scrollViewRef.current.clientHeight,
                    getValues: scrollViewRef.current.getValues,
                },
            });

            return () => {
                scrollBoxes.deleteScrollBox(id);
            };
        }
    }, [scrollViewRef]);

    return (
        <div
            ref={scrollViewRef}
            className={cx(`ll-scrollbox--${viewSize}`, customClasses)}
            onScroll={() => {
                handleScroll();
                handleHidePopover();
            }}
            {...restProps}
        >
            {children}
        </div>
    );
};

export default inject('store')(observer(LLScrollBox));
