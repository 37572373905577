import {types as t, getParent, getRoot, flow} from 'mobx-state-tree';
import {values} from 'mobx';
import {sortBy} from 'lodash';

import BranchItem from './branch-item';
import Tournament from './tournaments-item';
import WithFlags from '../../with-flags';
import {branchFetches, tournamentsActions} from './branch-instances-actions';
import {refreshTime, unsuccessCountLimit} from '../../config';
import trans from '../../../../../common/utils/trans';

/*
 ######     ###    ######## ########  ######    #######  ########  #### ########  ######
##    ##   ## ##      ##    ##       ##    ##  ##     ## ##     ##  ##  ##       ##    ##
##        ##   ##     ##    ##       ##        ##     ## ##     ##  ##  ##       ##
##       ##     ##    ##    ######   ##   #### ##     ## ########   ##  ######    ######
##       #########    ##    ##       ##    ##  ##     ## ##   ##    ##  ##             ##
##    ## ##     ##    ##    ##       ##    ##  ##     ## ##    ##   ##  ##       ##    ##
 ######  ##     ##    ##    ########  ######    #######  ##     ## #### ########  ######
*/

const Categories = t
    .model('Categories', {
        tournaments: t.map(t.compose(BranchItem, Tournament, WithFlags)),
        outrightsTournaments: t.map(t.compose(BranchItem, Tournament, WithFlags)),
        isParent: t.maybeNull(t.boolean),
        parentId: t.maybeNull(t.integer),
        initialAllMatchesFetching: true,
        initialOutrightsFetching: true,
        lastAllMatchesFetchTime: t.maybeNull(t.Date),
        lastOutrightsFetchTime: t.maybeNull(t.Date),
    })
    .extend((s) => ({
        /**** ~~ Extend tournaments by fetchers and actions ****/
        actions: {...branchFetches(s).tournaments, ...tournamentsActions(s)},
    }))
    .actions((s) => ({
        setLastAllMatchesFetchTime() {
            s.lastAllMatchesFetchTime = Date.now();
        },
        setLastOutrightsFetchTime() {
            s.lastOutrightsFetchTime = Date.now();
        },
        setInitialAllMatchesFetching(status) {
            s.initialAllMatchesFetching = status;
        },
        setInitialOutrightsFetching(status) {
            s.initialOutrightsFetching = status;
        },

        deleteItem(id) {
            s.tournaments.delete(id);
        },

        removeItems: flow(function* fetch() {
            yield s.deleteOldTournaments({tournaments: []});
            getParent(s, 2).deleteItem(s.id);
        }),
        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Scenarions instructions                               ##
        // ##                                                                                      ##
        // ##========================================================================================

        unsuccessTournamentsInitializeInstruction({res}) {
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            getRoot(s).betting.setError({type: 'global', error});
        },

        unsuccessTournamentsUpdateInstruction({res, parentCategoryId}) {
            s.setUnsuccessCount(s.unsuccessCount + 1);

            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            if (s.unsuccessCount >= unsuccessCountLimit) {
                getRoot(s).betting.setError({type: 'global', error});
            } else {
                s.setWaitingUpdate(false);
                s.setUpdateForTournaments({parentCategoryId});
            }
        },

        successTournamentsInitializeInstruction({
                                                    incomingTournamentsData,
                                                    parentCategoryId,
                                                }) {
            s.setUnsuccessCount(0);

            if (incomingTournamentsData.length === 0) {
                s.deleteOldTournaments({
                    tournaments: incomingTournamentsData,
                });
            }
            s.putUpdateData({dataSource: incomingTournamentsData});

            s.setWaitingUpdate(false);
            s.setUpdateForTournaments({parentCategoryId});
        },

        successTournamentsUpdateInstruction({
                                                incomingTournamentsData,
                                                parentCategoryId,
                                            }) {
            if (!incomingTournamentsData.length && ![...getRoot(s).betting.activeItems.tournaments].includes('Outrights')) {
                s.setUnsuccessCount(s.unsuccessCount + 1);
                if (s.unsuccessCount >= unsuccessCountLimit) {
                    getRoot(s).betting.setError({
                        type: 'global',
                        error: 'GENERAL_ERROR',
                    });
                }
            } else {
                s.setUnsuccessCount(0);
            }

            s.putUpdateData({dataSource: incomingTournamentsData});
            s.deleteOldTournaments({
                tournaments: incomingTournamentsData,
            });

            s.setWaitingUpdate(false);
            s.setUpdateForTournaments({parentCategoryId});
        },

        // ##========================================================================================

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                             Tournaments getter instruction                            ##
        // ##                                                                                      ##
        // ##========================================================================================

        initializeTournamentsInstruction: flow(function* fetch({
                                                                   id: parentCategoryId,
                                                               } = {}) {
            initializeTournamentsInstruction: {
                if (
                    s.lastFetchTime &&
                    Date.now() < +new Date(+s.lastFetchTime + refreshTime.tournaments)
                ) {
                    const timeLeft =
                        +new Date(+s.lastFetchTime + refreshTime.tournaments) -
                        Date.now();
                    s.setWaitingUpdate(false);
                    s.setUpdateForTournaments({parentCategoryId, timeLeft});
                    break initializeTournamentsInstruction;
                }

                s.setFetching({type: 'initialFetching', status: true});

                const res = yield s.fetchAndConvertDataForTournaments({
                    categoryId: parentCategoryId,
                }) || {};

                if (!s.check.cantUpdateData) {
                    if (!res || !res.success) {
                        s.unsuccessTournamentsInitializeInstruction({res});
                        if (getRoot(s).betting.branch.initialFetching) {
                            throw new Error(res.error);
                        }
                    } else {
                        s.successTournamentsInitializeInstruction({
                            incomingTournamentsData: res.data,
                            parentCategoryId,
                        });
                    }
                }
                s.setFetching({type: 'initialFetching', status: false});
                s.setLastFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                             Update Tournaments instruction                            ##
        // ##                                                                                      ##
        // ##========================================================================================

        updateTournamentsInstruction: flow(function* fetch({parentCategoryId}) {
            updateTournamentsInstruction: {
                if (s.check.isUpdateCleanable) {
                    s.setWaitingUpdate(false);
                    clearTimeout(window.__tournamentsUpdater);
                    break updateTournamentsInstruction;
                }

                if (!s.check.canGetData) {
                    s.setWaitingUpdate(false);
                    s.setUpdateForTournaments({parentCategoryId});
                    break updateTournamentsInstruction;
                }

                s.setFetching({type: 'isFetching', status: true});

                let res = {
                    ...(yield s.fetchAndConvertDataForTournaments({
                        categoryId: s.id,
                    })),
                };

                if (!s.check.cantUpdateData) {
                    if (!res || !res.success) {
                        s.unsuccessTournamentsUpdateInstruction({
                            res,
                            parentCategoryId: s.id,
                        });
                    } else {
                        s.successTournamentsUpdateInstruction({
                            incomingTournamentsData: res.data,
                            parentCategoryId: s.id,
                        });
                    }
                }
                s.setFetching({type: 'isFetching', status: false});
                s.setLastFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                              Set updater for tournaments                             ##
        // ##                                                                                      ##
        // ##========================================================================================

        setUpdateForTournaments({parentCategoryId, timeLeft}) {
            if (!s.isWaitingUpdate && !getRoot(s).betting.bettingErrors.global) {
                s.setWaitingUpdate(true);
                clearTimeout(window.__tournamentsUpdater);
                window.__tournamentsUpdater = setTimeout(
                    () => s.updateTournamentsInstruction({parentCategoryId}),
                    timeLeft ?? refreshTime.tournaments
                );
            }
        },
    }))
    .views((s) => ({
        get listSubCategories() {
            return sortBy(
                values(getParent(s, 2).categories).reduce((a, category) => {
                    return category.parentId == s.id ? [...a, category] : a;
                }, []),
                [
                    'order',
                    (item) => {
                        return item?.name?.toLowerCase();
                    },
                ]
            );
        },
        get check() {
            /**** ~~ betting root ****/
            const betting = getRoot(s).betting;

            return {
                get canGetData() {
                    return getRoot(s)?.site?.status.isActive && betting.sportMountStatus;
                },

                get cantUpdateData() {
                    return !getParent(s, 2);
                },

                get isUpdateCleanable() {
                    let flag =
                        getParent(s, 4).id !== getRoot(s).betting.branchId ||
                        !getParent(s, 2) ||
                        (!betting.activeItems.isActive({
                                id: s.id,
                                type: 'categories',
                            }) &&
                            !getRoot(s).betting.activeMenuItems.isActive({
                                id: s.id,
                                type: 'categories',
                            }));
                    if (!flag) {
                        return this.isMobile
                            ? (!!betting.activeItems.matches.length && !getRoot(s).betting.isBreadcrumbsNavigationDropdownOpen)
                            : false;
                    }
                    return flag;
                },

                isMobile: getRoot(s).site.status.viewSize === 'mobile',
            };
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                 View Tournaments list                                 ##
        // ##                                                                                      ##
        // ##========================================================================================

        get tournamentsList() {
            return [
                /**** ~~ All category tournaments ****/
                ...(s.matchCount > 0 && s.tournaments.size > 1
                    ? [
                        {
                            name: trans('MENU_ALL'),
                            id: `All`,
                        },
                    ]
                    : []),

                /**** ~~ Category outrights ****/
                ...(s.outrightCount > 0
                    ? [
                        {
                            /**
                             * @crutch
                             *
                             * @desc - CK-671
                             *         Custom outrights name for sport 20040
                             *         and sport 25500
                             * @author S.Nakhodov
                             */
                            name: trans(`MENU_OUTRIGHTS${['25500', '20040'].includes(getParent(s, 2).id)
                                ? `_${getParent(s, 2).id}` : ''}`),
                            id: 'Outrights',
                        },
                    ]
                    : []),
                ...sortBy(values(s.tournaments), [
                    'order',
                    (item) => {
                        return item?.name?.toLowerCase();
                    },
                ]),
            ];
        },

        get clearedTournamentsList() {
            return sortBy(values(s.tournaments), [
                'order',
                (item) => {
                    return item?.name?.toLowerCase();
                },
            ])
        },

        get allMatches() {
            return sortBy(values(s.tournaments), [
                'order',
                (item) => {
                    return item?.name?.toLowerCase();
                },
            ]).map((tournament) => tournament.matchesGroupedByDate());
        },

        get allOutrightMatches() {
            return sortBy(values(s.outrightsTournaments), [
                'order',
                (dateObj) => new Date(dateObj.timeStart),
                (item) => {
                    return item?.name?.toLowerCase();
                },
            ]).map((tournament) => tournament.matchesGroupedByDate('outrights'));
        },

        get allTournaments() {
            return sortBy(values(s.tournaments), [
                'order',
                (item) => {
                    return item?.name?.toLowerCase();
                },
            ]);
        },
        get allOutrightsTournaments() {
            return sortBy(values(s.outrightsTournaments), [
                'order',
                (dateObj) => new Date(dateObj.timeStart),
                (item) => {
                    return item?.name?.toLowerCase();
                },
            ]);
        },
    }));

export default Categories;
