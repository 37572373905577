import { getRoot, flow } from 'mobx-state-tree';
import { values } from 'mobx';
/*
 ######  ########   #######  ########  ########  ######      ######   #######  ##     ## ##     ##    ###    ##    ## ########   ######
##    ## ##     ## ##     ## ##     ##    ##    ##    ##    ##    ## ##     ## ###   ### ###   ###   ## ##   ###   ## ##     ## ##    ##
##       ##     ## ##     ## ##     ##    ##    ##          ##       ##     ## #### #### #### ####  ##   ##  ####  ## ##     ## ##
 ######  ########  ##     ## ########     ##     ######     ##       ##     ## ## ### ## ## ### ## ##     ## ## ## ## ##     ##  ######
      ## ##        ##     ## ##   ##      ##          ##    ##       ##     ## ##     ## ##     ## ######### ##  #### ##     ##       ##
##    ## ##        ##     ## ##    ##     ##    ##    ##    ##    ## ##     ## ##     ## ##     ## ##     ## ##   ### ##     ## ##    ##
 ######  ##         #######  ##     ##    ##     ######      ######   #######  ##     ## ##     ## ##     ## ##    ## ########   ######
*/

const sportActions = (s) => ({
    putUpdateData({ dataSource, requestedSportId }) {
        if (
            requestedSportId &&
            requestedSportId !== '0' &&
            parseInt(requestedSportId) &&
            dataSource.findIndex(({ id }) => id == requestedSportId) == -1 &&
            s.sports.has(requestedSportId)
        ) {
            /**** ~~ Set this sport id as removable after initial loading will complete ****/
            getRoot(s).betting.setRemovableInitialInstances({
                sid: requestedSportId,
            });
        }
        /**** ~~ Define all parents id ****/
        const parentsIds = [
            ...new Set(
                dataSource.reduce((a, v) => {
                    return v.parentId ? [...a, v.parentId] : a;
                }, [])
            ),
        ];

        dataSource.map((newSport) => {
            if (!s.sports.has(newSport.id)) {
                s.sports.put({
                    ...newSport,
                    isParent: parentsIds.some((parentId) => {
                        return parentId == newSport.id;
                    }),
                });
                return;
            }

            s.sports.get(newSport.id).update(newSport);
        });
    },

    deleteOldSports: flow(function* fetch({ sports, sportIds }) {
        if (sports) {
            //for pre
            values(s.sports)
                .filter(
                    ({ id }) => !sports.some(({ id: incomingId }) => incomingId === id)
                )
                .map((oldSport) => {
                    oldSport.removeItems();
                });
        } else if (sportIds) {
            //for live
            yield Promise.all([
                values(s.sports).map((sport) => {
                    if (!sportIds.includes(sport.id)) {
                        sport.removeItems();
                    }
                }),
            ]);
        }
    }),
});

export default sportActions;
