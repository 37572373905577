import React, {useEffect, useRef} from 'react';
import LiveChat from 'react-livechat';
import PropTypes from 'prop-types';

import { withCommon } from '../../';
import config from "../../../static/themes/config";

/**
 * @file Represents Live Chat Component
 *
 * @param {number} liveChat - livechat id, received from our server
 * @param {boolean} isLogged - flag is user logged
 * @param {number} customerId - user id
 * @param {string} userName - username
 * @param {string} currency - active currency
 * @param {string} customerGroup - user group
 * @param {boolean} isLiveChatOpen - flag is live chat open
 *
 * @author A. Green
 */

const LiveChatSupport = withCommon({
    store: ({ store }) => ({
        liveChat: store.site.liveChat,
        siteName: store.site.siteName,
        setLoaded: store.liveChat.setLoaded,
    }),
    PropTypes: {
        liveChat: PropTypes.number,
        siteName: PropTypes.string,
        setLoaded: PropTypes.func.isRequired,
    },
    defaultName: 'LiveChatSupport',
})(({ liveChat, siteName, setLoaded }) => {
    const liveChatRef = useRef(null);

    const isCustomLivechat = !!(config(siteName).scripts && config(siteName).scripts.livechatScript);

    useEffect(() => {
        if (isCustomLivechat) {
            const range = document.createRange();
            document.head.appendChild(
                range.createContextualFragment(
                    config(siteName).scripts.livechatScript
                )
            );
        }
    }, []);

    if (liveChat && !isCustomLivechat) {
        return (
            <LiveChat
                ref={liveChatRef}
                onChatLoaded={(ref) => {
                    ref.on_after_load = () => {
                        setLoaded();
                        document.querySelector('#chat-widget-container').style.display =
                            'block';
                        ref.hide_chat_window();
                    };
                }}
                onChatWindowMinimized={() => {
                    try {
                        window.LC_API.hide_chat_window();
                    } catch (e) {
                        console.log(e);
                    }
                }}
                license={parseInt(liveChat)}
            />
        );
    } else {
        return null;
    }
});

export default LiveChatSupport;
