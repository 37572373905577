// /* eslint-disable no-undef */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import urls from '../../../configs/urls';
import invert from './crutch/team-invert';

/**
 * @file Represents React Component to render prematch/live match widget
 *
 * @param {number|string} matchId - id of selected match
 * @param {number|string} tournamentId - id of selected tournamentId
 * @param {string} type - type of widget to render: match.preview | betAssist.standalone | match.statistics | headToHead.standalone | season.liveTable | match.lmtSticky | match.lmtPlus
 * @param {number|string} market - required for betAssist.standalone widget: '3Way' | 'moneyLine' | '2Way'
 * @param {number|string} sportId - required for match.lmtPlus widget / detect sport with id = 16
 * @param {string} className - custom css class
 * @param {func} onError = callback fo error handler in parent comnonent
 * @param {CSSStyleRule} style - inline styles for widget container
 *
 * @author I.Mykhailychenko
 */

const KEY_FOR_WIDGET = 'ce4e82293fd990ccb0586fd2619cc2e2';

let interval;

const getSettings = ({ market, matchId, tournamentId }) => ({
    'betAssist.standalone': {
        market,
        matchId,
    },
    'season.liveTable': {
        tournamentId,
    },
    'headToHead.standalone': {
        matchId,
    },
    'match.lmtPlus': {
        matchId,
        momentum: 'compact',
        activeSwitcher: 'scoreDetails',
        goalBannerImage: '/widget/goal-banner-logo.png',
        collapseTo: 'scoreboard',
        expanded: true,
    },
    'match.lmtSticky': {
        matchId,
        disableWidgetHeader: true,
    },
    'match.preview': {
        matchId,
        disableMoreStats: true,
    },
    'match.statistics': {
        matchId,
        disableWidgetHeader: true,
        disableResult: true,
    },
});

const BetradarWidgets = ({
    matchId,
    tournamentId,
    sportId = null,
    type = 'season.liveTable',
    market = null,
    className = null,
    onError = null,
    style = null,
    setLmtError,
}) => {
    const language = document.documentElement.lang;

    // id - html attribute
    const id = `ll-widget-${type.replace(/\./g, '-')}`;

    const cleanWidget = () => {
        if (window.SIR) window.SIR('removeWidget', `.${id}_${matchId}`);
    };

    const newError = () => {
        if (onError) onError(true);

        if (type === 'match.lmtPlus') {
            setLmtError(true);
            cleanWidget();
        } else {
            document.querySelector(`.${id}_${matchId}`).style.display = 'none';
        }
    };

    const initWidget = () => {
        if (window.SIR) return;
        window.SIR = function () {
            (window.SIR.q = window.SIR.q || []).push(arguments);
        };

        window.SIR.l = 1 * new Date();
        window.SIR.o = { theme: false, language };

        const script = document.createElement('script');
        script.async = 1;
        script.src = `${urls.sportradarWidgets}${KEY_FOR_WIDGET}/widgetloader`;
        script.setAttribute('n', 'SIR');
        script.setAttribute('id', id);

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    };

    const start = () => {
        initWidget();

        const settings = getSettings({ market, matchId, tournamentId });

        if (['match.statistics', 'season.liveTable'].includes(type)) {
            interval = setInterval(() => {
                const errContainer = document.querySelector(
                    `.${id} .sr-error__container`
                );
                if (errContainer?.innerHTML) {
                    newError();
                    clearInterval(interval);
                }
            }, 100);
        }

        settings[type].onTrack = (a, data) => {
            if (data?.error) {
                newError();
            }
        };

        // crutch
        invert.forEach((item) => {
            if (item[type] === +sportId) settings[type].forceTeamInvert = false;
        });

        window.SIR('addWidget', `.${id}_${matchId}`, type, settings[type]);
    };

    useEffect(() => {
        if (matchId || tournamentId) {
            document.querySelector(`.${id}_${matchId}`).style.display = 'block';
            start();
        }
    }, [matchId, tournamentId, type]);

    useEffect(
        () => () => {
            cleanWidget();
        },
        []
    );

    return (
        <>
            <div style={style} className={cx(`${id}_${matchId}`, className, 'live-widgets__item')} />
        </>
    );
};

BetradarWidgets.defaultProps = {
    matchId: null,
    tournamentId: null,
    type: 'season.liveTable',
    market: null,
    className: null,
    onError: null,
    style: null,
};

BetradarWidgets.propTypes = {
    matchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tournamentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf([
        'match.preview',
        'betAssist.standalone',
        'match.statistics',
        'headToHead.standalone',
        'season.liveTable',
        'match.lmtSticky',
        'match.lmtPlus',
    ]),
    onError: PropTypes.func,
    market: PropTypes.oneOf(['3Way', 'moneyLine', '2Way']),
    className: PropTypes.string,
    style: PropTypes.object,
};

export default React.memo(BetradarWidgets);
