import React, {useEffect, useState} from 'react';

import {trans} from '../../../../../common/utils';
import {inject, observer} from "mobx-react";
import {LLElementTypeSetter, LLTextSlider, WithPopover} from "../../../../../components";


const OVERFLOW_WIDTH = 167; //calculated width for popular tournament name element if popular tournament item max width should be 232px

const PopularTournaments = ({store}) => {
    const
        popularTournaments = store.betting.branch.popularTournamentsList,
        viewSize = store.site.status.viewSize,
        branchId = store.betting.branch.routerId;

    const [overflowedItemIndexes, setOverflowedItemIndex] = useState([]);

    useEffect(() => {
        const names = document.getElementsByClassName('popular-tournaments__list-item-name');
        if (names?.length) {
            Array.from(names).forEach((name, i) => {
                if (name.getClientRects()[0].width >= OVERFLOW_WIDTH) {
                    setOverflowedItemIndex((state) => [...state, i]);
                }
            })
        }
    }, []);

    const renderList = () => popularTournaments.map((item, i) =>
        <LLElementTypeSetter
            className="popular-tournaments__list-item"
            to={`/sport/${branchId}/${item.sportId}/${item.categoryId}/${item.id}`}
            as="NavLink"
            key={item.id}
            onClick={() => {
                store.user.collectActivity({area: 'popular-tournaments', itemId: item.id, interactTarget: 'tournament-link'});
            }}
        >
            <div className="popular-tournaments__list-item-name-wrapper">
                <i className={`popular-tournaments__sport-icon sport_icon_font-${item.sportId}`}/>
                <WithPopover
                    popoverClasses="ll-popover__text"
                    customClasses={overflowedItemIndexes.includes(i) ? "popular-tournaments__overflowed-name" : ''}
                >
                    <span className="popular-tournaments__list-item-name">{item.name}</span>
                </WithPopover>
            </div>
            {item.matchCount ? <div className="popular-tournaments__match-count">
                    {item.matchCount}
                </div>
                : null
            }
        </LLElementTypeSetter>
    );

    return popularTournaments.length ? <div className="popular-tournaments">
        <div className="popular-tournaments__header">
            <i className="popular-tournaments__header-icon doruk-success-crown-1"/>
            <WithPopover
                popoverContent={<span>{trans('Popular Tournaments')}</span>}
                popoverClasses="ll-popover__text"
            >
                <span>{trans('Popular Tournaments')}</span>
            </WithPopover>
        </div>
        {viewSize === 'mobile' ?
            <LLTextSlider
                id="popular-tournaments__list"
                viewSize={viewSize}
                withAbsoluteArrows={true}
                showArrows={false}
                customClasses={{
                    slider: 'popular-tournaments__list',
                }}
            >
                {renderList()}
            </LLTextSlider>
            :
            <div className="popular-tournaments__list">
                {renderList()}
            </div>
        }
    </div> : null;
};

export default inject('store')(observer(PopularTournaments));
