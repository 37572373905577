import React, {useEffect} from 'react';

import {LLPreloader, withCommon} from '../../../../components';
import BattleCard from "./battle-card";
import BattleLobby from "./battle-lobby";
import {trans} from "../../../../common/utils";
import Image from "../../../../static/themes/theme/images/slots-and-casino/no-games.png";


export const SlotsBattleInfo = withCommon({
    store: ({store}) => ({
        activeBattle: store.slotsBattles.activeBattle,
        updateActiveBattle: store.slotsBattles.updateActiveBattle,
        slotBattlesBattleInfoLoading: store.slotsBattles.slotBattlesBattleInfoLoading,
    }),
    defaultName: "SlotsBattleInfo",
    isObserver: true
})(({
        activeBattle,
        slotBattlesBattleInfoLoading,
        updateActiveBattle,
    }) => {

    useEffect(() => {
        activeBattle?.room_status !== 'completed' && updateActiveBattle();
        return () => {
            clearTimeout(window._activeBattleUpdateInterval);
            window._activeBattleUpdateInterval = null;
        }
    }, []);

    return (
        <div
            className="battle-info">
            {slotBattlesBattleInfoLoading ?
                <div className="slots-battles__loading">
                    <LLPreloader/>
                </div>
                :
                activeBattle?.playersList?.length ?
                    <>
                        <div className="battle-info__first-part">
                            <BattleCard game={activeBattle}/>
                        </div>
                        <BattleLobby/>
                    </>
                    :
                    <div className="games-no-data">
                        <p>{trans('SLOT_BATTLE__NOT_AVAILABLE')}</p>
                        <img src={Image} className="games-no-data__img" alt="no-games-found"/>
                    </div>
            }
        </div>
    );
});