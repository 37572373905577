import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { withCommon } from '../../HOCs';
import { LLBtn2 } from '../';

/**
 * @desc Represents a Tabs component
 *
 * @param {object[]} data - data to render tabs
 * @param {string} data[0].title - label of certain button
 * @param {string} data[0].value - unique value / to identify click event
 * @param {string} className - custom className for container
 * @param {string} btnClass - custom className for tab
 * @param {string} btnClassActive - custom className for active tab
 * @param {func} onClick - on click event callback
 *
 * @author I.Mykhailychenko
 */

const LLTabs = withCommon({
    propTypes: {
        data: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                icon: PropTypes.element,
            })
        ).isRequired,
        className: PropTypes.string,
        btnClass: PropTypes.string,
        btnClassActive: PropTypes.string,
        onClick: PropTypes.func.isRequired,
    },
    defaultProps: {
        className: null,
        btnClass: null,
        btnClassActive: null,
    },
    isTrans: true,
})(
    ({
        trans,
        data,
        className = null,
        btnClass = null,
        btnClassActive = null,
        onClick,
    }) => {
        const [activeTab, setActiveTab] = useState(data[0].value);

        const handleClick = (value) => () => {
            setActiveTab(value);
            onClick(value);
        };

        return data?.length ? (
            <div className={cx(className)}>
                {data.map(({ icon, title, value }) => (
                    // there is some problem with rendering active button
                    // for some reasons active class do not expire and we have several active tabs at the same time
                    // so it should be extra wrapper to solve this problem
                    <div
                        key={value}
                        className={cx(btnClass, activeTab === value && btnClassActive)}
                    >
                        <LLBtn2 onClick={handleClick(value)}>{trans(title)}</LLBtn2>
                        {icon && icon}
                    </div>
                ))}
            </div>
        ) : null;
    }
);

export default LLTabs;
