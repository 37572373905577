import React from 'react';
import {inject, observer} from 'mobx-react';
import cx from 'classnames';

import {LLErrorVisualizer, LLPreloader} from '../../../components';
import LLMatchDetailsMobile from '../desktop/components/match-detail';
import {PrematchMenu} from '../components';
import {
    PreMenuFiltersBoardMobile,
    MobileLiveFilters,
    LiveMatchListMobile,
    LLLiveMatchDetailMobile,
    LLMatchListMobile,
    LLOutrightListMobile,
    LLOutrightsDetailsMobile,
} from './components';
import {PreSearchFilterPanel} from "../components/pre-filters/pre-search-filters-panel";
import Image from "../../../static/themes/theme/images/errors/empty-sports-data.png";
import {trans} from "../../../common/utils";


const SportMobile = ({store}) => {
    const
        matchDetailError = store.betting.matchDetailError,
        branch = store.betting.branch,
        mobilePrematchDashboardView = store.router.mobilePrematchDashboardView,
        siteStatus = store.site.status,
        drawerIsActive = store.drawer.isActive,
        language = store.user.language,
        couponsList = store.user.coupons.list,
        activeRange = store.betting.filteredRanges.activeRange,
        filteredRanges = store.betting.filteredRanges,
        isSearchFilterPanelOpened = store.betting.isSearchFilterPanelOpened,
        setSearchFilterPanelOpened = store.betting.setSearchFilterPanelOpened,
        viewSize = store.site.status.viewSize;

    const renderBettingDashboard = () => {
        return {
            LLMenuMobile: <PrematchMenu view={mobilePrematchDashboardView.view}/>,
            LLMatchListMobile: (
                <LLMatchListMobile view={mobilePrematchDashboardView.view}/>
            ),
            LLOutrightListMobile: <LLOutrightListMobile/>,
            LLMatchDetailsMobile: (
                <LLMatchDetailsMobile view={mobilePrematchDashboardView}/>
            ),
            LLOutrightsDetailsMobile: (
                <LLOutrightsDetailsMobile view={mobilePrematchDashboardView}/>
            ),
            LiveList: <LiveMatchListMobile view={mobilePrematchDashboardView}/>,
            LiveDetail: <LLLiveMatchDetailMobile/>,
        }[mobilePrematchDashboardView.component];
    };

    return (
        <div className={cx(
            'prematch__content--mobile',
            isSearchFilterPanelOpened && 'prematch__content--with--panel-opened'
        )}>
            {['LLMenuMobile', 'LLMatchListMobile', 'LLOutrightListMobile'].includes(
                    mobilePrematchDashboardView.component) &&
                <>
                    <PreMenuFiltersBoardMobile {...{
                        setPanelOpened: setSearchFilterPanelOpened,
                        language,
                        viewSize,
                        mountedBranch: store.betting.branch.id,
                        siteStatus: store.site.status,
                    }} />
                    {isSearchFilterPanelOpened &&
                        <PreSearchFilterPanel {...{
                            viewSize,
                            setPanelOpened: setSearchFilterPanelOpened,
                            searchResult: store.betting.branch.search.searchString,
                            filteredRanges,
                            searchFiltersFetching: activeRange?.searchFiltersFetching,
                            searchInitialFetching: store.betting.branch.search.initialFetching,
                            searchFiltersResult: activeRange?.searchFiltersResult,
                            couponsList,
                        }} />
                    }
                </>
            }
            {mobilePrematchDashboardView.component === 'LiveList' && (
                <MobileLiveFilters
                    isFetching={mobilePrematchDashboardView.isLoading}
                    siteStatus={siteStatus}
                    drawerIsActive={drawerIsActive}
                    liveMatches={store.betting.liveMatches}
                    isCalendar={store.betting.liveMatches.isCalendar}
                />
            )}
            {(branch && !branch.sportsList.length && !mobilePrematchDashboardView.isLoading)
                && <div className="prematch-mobile__empty-data">
                    <p className="prematch-mobile__empty-data-msg">{trans('SPORTS_SEARCH_NO_DATA_TEXT')}</p>
                    <img className="prematch-mobile__empty-data-img" src={Image} alt="empty-data"/>
                </div>}
            {mobilePrematchDashboardView.isLoading && mobilePrematchDashboardView.view !== 'rangedEvents' ? (
                <div className="prematch__loading--mobile">
                    <LLPreloader height={50} width={50}/>
                </div>
            ) : (
                <>
                    {matchDetailError &&
                    (mobilePrematchDashboardView.component === 'LLMatchDetailsMobile' ||
                        mobilePrematchDashboardView.component ===
                        'LLOutrightsDetailsMobile') ? (
                        <LLErrorVisualizer error={matchDetailError}/>
                    ) : (
                        renderBettingDashboard()
                    )}
                </>
            )}
        </div>
    );
};

export default inject('store')(observer(SportMobile));
