/**
 * @crutch Detect market name depending on sport id. Only for widget betAssist.standalone
 *
 * @task 3632
 *
 * @key sport id
 * @value market name
 *
 * @author I.Mykhailychenko
 */

const markets = {
    1: '3Way',
    // ---
    2: 'moneyLine',
    3: 'moneyLine',
    4: 'moneyLine',
    16: 'moneyLine',
    // ---
    5: '2Way',
    6: '2Way',
    29: '2Way',
};

export default markets;
