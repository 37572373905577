import api from '../../api';


const TIME_RANGE = 60 * 1000;
const ERRORS_RANGE = 10;

const errorLogger = ({errorObj} = {}) => {
    const lastErrorSendTime = +localStorage.getItem("lastErrorSendTime");
    const errorsCount = +localStorage.getItem("errorsCount");
    const isTimeExpired = !lastErrorSendTime || Date.now() - lastErrorSendTime > TIME_RANGE;
    if (errorsCount < ERRORS_RANGE || isTimeExpired) {
        // api.logs.sendLog(errorObj).then(() => localStorage.setItem("errorsCount", '' + (errorsCount + 1)));
    }
    if (isTimeExpired) {
        localStorage.setItem("lastErrorSendTime", '' + Date.now());
    }
};

export default errorLogger;
