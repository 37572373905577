import React from 'react';

import {LLElementTypeSetter, LLScrollBox} from "../../../components";
import {parseUrl} from "../../../common/utils";


const FooterFeaturedLinks = ({links, hidePopover, user}) => (
    <LLScrollBox id="ll-footer__popover-content-scroll">
        <div className="ll-footer__popover-content">
            { links.map(link =>
                <LLElementTypeSetter
                    id={link.name}
                    key={link.name}
                    className="ll-footer__popover-item"
                    onClick={(e) => {
                        hidePopover();
                        parseUrl(link.url, user).someClick(e);
                    }}
                    {...parseUrl(link.url, user).elementAttributes}
                >

                    {link.text}
                </LLElementTypeSetter>
            )}
        </div>
    </LLScrollBox>
);

export default FooterFeaturedLinks;