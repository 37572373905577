import { getRoot, flow } from 'mobx-state-tree';

import llattempt from '../../../../../../common/utils/attempt';
import { branchConfig } from '../../../config';
import api from '../../../../../../common/api';


const branchFetches = (s) => ({
    sports: {
        fetchAndConvertDataForSports: flow(function* fetch({from, to} = {}) {
            /**** ~~ Fetch sports data ****
             * @param {date} from - timestamp (if undefined binds to now in back)
             * @param {date} to -  timestamp
             * @returns {object} res - {data:[{i:1, mc:720, o:100,oc:12},...],error:0}
             * @returns {object} res.data - like {i:1, mc:720, o:100,...}
             * @returns {integer} res.data.i - id
             * @returns {integer} res.data.mc - match count
             * @returns {integer} res.data.o - order
             * @returns {integer} res.data.oc - outright count
             * @returns {integer} res.error - is error is existing
             */
            let sportFetchParams = {
                to: to || branchConfig[getRoot(s).betting.branchId].to(),
            };

            if (from) {
                sportFetchParams.from = from;
            }

            //api.betting.getPopularTournaments(sportFetchParams)

            return llattempt(
                () =>
                    api.betting.getSports(sportFetchParams).then((res) => {
                        return {
                            ...res,
                            ...(!res?.data?.error &&
                                res?.data && {
                                    data: res.data.map(
                                        ({
                                            i,
                                            mc = null,
                                            o,
                                            oc = null,
                                            pid = null,
                                            n = null,
                                            sg,
                                            sag,
                                        }) => ({
                                            id: i.toString(),
                                            matchCount: mc,
                                            outrightCount: oc,
                                            parentId: pid,
                                            order: o,
                                            name: n,
                                        })
                                    ),
                                }),
                        };
                    }),
                {
                    withNotifier: false,
                    at: 'fetchDataForSports',
                    withParams: sportFetchParams,
                }
            );
        }),
    },
    categories: {
        fetchAndConvertDataForCategories: flow(function* fetch({ sportId, from, to }) {
            /**** ~~ Fetch sports data ****
             * @param {date} from - timestamp (if undefined binds to now in back)
             * @param {date} to -  timestamp
             * @returns {object} res - {data:[{i:1, mc:720, o:100,oc:12},...],error:0}
             * @returns {object} res.data - like {i:1, mc:720, o:100,...}
             * @returns {integer} res.data.i - id
             * @returns {integer} res.data.mc - match count
             * @returns {integer} res.data.o - order
             * @returns {integer} res.data.oc - outright count
             * @returns {integer} res.error - is error is exist
             */
            const categoryFetchParams = {
                to: to || branchConfig[getRoot(s).betting.branchId].to(),
                sportId: sportId,
            };

            if (from) {
                categoryFetchParams.from = from;
            }

            return llattempt(
                () =>
                    api.betting.getCategories(categoryFetchParams).then((res) => {
                        return {
                            ...res,
                            ...(!res.data?.error &&
                                res?.data && {
                                    data: res.data.map(({ i, mc, o, oc, pid, n }) => ({
                                        id: i.toString(),
                                        matchCount: mc,
                                        order: o,
                                        outrightCount: oc,
                                        parentId: pid,
                                        name: n,
                                    })),
                                }),
                        };
                    }),
                {
                    withNotifier: false,
                    at: 'fetchDataForCategory',
                    withParams: categoryFetchParams,
                }
            );
        }),
    },
    tournaments: {
        fetchAndConvertDataForTournaments: flow(function* fetch({ categoryId, from, to }) {
            /**** ~~ Fetch sports data ****
             * @param {date} from - timestamp (default as now in backend)
             * @param {date} to -  timestamp in seconds
             * @returns {object} res - {data:[{i:1, mc:720, o:100,oc:12},...],error:0}
             * @returns {object} res.data - like {i:1, mc:720, o:100,...}
             * @returns {integer} res.data.i - id
             * @returns {integer} res.data.mc - match count
             * @returns {integer} res.data.o - order
             * @returns {integer} res.data.oc - outright count
             * @returns {integer} res.error - is error exist
             */
            const tournamentFetchParams = {
                to: to || branchConfig[getRoot(s).betting.branchId].to(),
                categoryId: categoryId,
            };

            if (from) {
                tournamentFetchParams.from = from
            }

            return llattempt(
                () =>
                    api.betting.getTournaments(tournamentFetchParams).then((res) => ({
                        ...res,
                        ...(!res?.data?.error &&
                            res?.data && {
                                data: res.data.map(({ i, o, mc, n }) => ({
                                    id: i.toString(),
                                    order: o,
                                    matchCount: mc,
                                    name: n,
                                })),
                            }),
                    })),
                {
                    withNotifier: false,
                    at: 'fetchDataForTournaments',
                    withParams: tournamentFetchParams,
                }
            );
        }),
        fetchAndConvertDataForPopularTournaments: flow(function* fetch() {
            /**** ~~ Fetch sports data ****
             * @param {date} from - timestamp (default as now in backend)
             * @param {date} to -  timestamp in seconds
             * @returns {object} res - {data:[{i:1, mc:720, o:100,oc:12},...],error:0}
             * @returns {object} res.data - like {i:1, mc:720, o:100,...}
             * @returns {integer} res.data.i - id
             * @returns {integer} res.data.mc - match count
             * @returns {integer} res.data.o - order
             * @returns {integer} res.data.oc - outright count
             * @returns {integer} res.error - is error exist
             */
            const tournamentFetchParams = {
                to: branchConfig[getRoot(s).betting.branchId].to(),
            };

            return llattempt(
                () =>
                    api.betting.getPopularTournaments(tournamentFetchParams).then((res) => ({
                        ...res,
                        ...(!res?.data?.error &&
                            res?.data && {
                                data: res.data.map(({ i, mc, n, sid, cid }, index) => ({
                                    id: i.toString(),
                                    order: index,
                                    matchCount: mc,
                                    name: n,
                                    sportId: sid.toString(),
                                    categoryId: cid.toString(),
                                })),
                            }),
                    })),
                {
                    withNotifier: false,
                    at: 'fetchDataForPopularTournaments',
                    withParams: tournamentFetchParams,
                }
            );
        }),
    },
});

export default branchFetches;
