import React, {useRef, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import cx from 'classnames';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';

import {
    withCommon,
    WithPopover,
    LLScrollBox,
    ChangePasswordForm,
    ForgotPasswordForm,
    LoginForm,
    LLPreloader,
} from '../../components';
import PromoModal from '../../pages/promo/components/promo-modal';
import PaymentModal from '../../pages/user-profile/pages/payments/components/payment-modal';
import SuccessBetModal from '../../pages/sport/desktop/right-panel/components/success-bet-modal';
import ClearBetslipModal from '../../pages/sport/components/custom-bets/clear-bets-modal';

/***************** ~~ Represents modal ***************
 * @file - Represents React Component to render the modal
 *
 * @memberOf LLLayout
 *
 * @param {object} modal - store model, required
 * @param {boolean} isActive - is modal open indicator, required
 * @param {string} customClasses - additional custom classes
 * @param {string} currentKey - identifier of current modal inner content
 * @param {boolean} showCloseButton - define is rendering close button, required
 * @param {boolean} showFullscreenButton - define is rendering fullscreen button, required
 * @param {boolean} showHeader - define is rendering modal header, required
 * @param {string} title - modal title
 * @param {boolean} maskBackground - define is rendering modal mask, required
 * @param {string} onCloseGoTo - link for redirect after close modal
 * @param {object} activeGame - store model in modal with params of opened game
 * @param {string} externalLink - external link to open another website in modal
 * @param {string} customContent - custom html rendered in the modal content
 * @param {function} close - action to set modal isActive false, can receive callback function (closeCallback)
 *
 * @author S.Nakhodov, S.Tarnavski
 */

const LLModal = withCommon({
    store: ({store}) => ({
        mobileUserAgent: store.user.mobileUserAgent,
        viewSize: store.site.status.viewSize,
        setBackdropShown: store.site.status.setBackdropShown,
        setBackdropOnClick: store.site.status.setBackdropOnClick,
        setBackDropClosable: store.site.status.setBackDropClosable,
        setBackDropFullScreen: store.site.status.setBackDropFullScreen,
        setBackdropTransparent: store.site.status.setBackdropTransparent,
        /**** ~~ Modal render  ****/
        modal: store.modal,
        popup: store.site.popup,
        isActive: store.modal.isActive,
        customClasses: store.modal.customClasses,
        currentKey: store.modal.currentKey,
        customContent: store.modal.customContent,

        /**** ~~ Modal styling  ****/
        showCloseButton: store.modal.showCloseButton,
        showFullscreenButton: store.modal.showFullscreenButton,
        showHeader: store.modal.showHeader,
        title: store.modal.title,
        maskBackground: store.modal.maskBackground,
        drawer: store.drawer,

        /**** ~~ Modal functionality  ****/
        onCloseGoTo: store.modal.onCloseGoTo,
        closeOnOuterClick: store.modal.closeOnOuterClick,
        close: store.modal.close,

        /**** ~~ Open games  ****/
        activeGame: store.modal.activeGame,

        /**** ~~ Open external links  ****/
        externalLink: store.modal.externalLink,
    }),
    propTypes: {
        modal: PropTypes.shape({
            closeOnOuterClick: PropTypes.bool,
            onCloseGoTo: PropTypes.string,
            isActive: PropTypes.bool.isRequired,
            customClasses: PropTypes.string,
            currentKey: PropTypes.string,
            showCloseButton: PropTypes.bool,
            showFullscreenButton: PropTypes.bool,
            showHeader: PropTypes.bool,
            maskBackground: PropTypes.bool,
            title: PropTypes.any,
            externalLink: PropTypes.string,
            activeGame: PropTypes.object,
            close: PropTypes.func.isRequired,
        }).isRequired,
    },
    defaultName: 'LLModal',
    isObserver: true,
    isRouter: true,
})(({
        modal,
        modal: {
            isActive,
            customClasses,
            currentKey,
            showCloseButton,
            showFullscreenButton,
            showHeader,
            title,
            maskBackground,
            onCloseGoTo,
            activeGame,
            customContent,
            externalLink,
            closeOnOuterClick,
        },
        popup,
        mobileUserAgent,
        viewSize,
        drawer,
        setBackdropShown,
        setBackdropOnClick,
        setBackDropClosable,
        setBackDropFullScreen,
        setBackdropTransparent,
        history,
    }) => {
    const [loading, setLoading] = useState(true),
        [gameSize, setGameSize] = useState({
            height: '',
            width: '',
        });

    /**** ~~ Ref for modal keydown and click events  ****/
    const modalRef = useRef();

    let bananaDefender = true;

    /**** ~~ Create FullScreen agent ****/
    const fullScreenHandle = useFullScreenHandle();

    /*********************     *************************/
    /***** ~~ Modal events and listeners **************/
    /*********************     ***********************/
    const keydownHandler = useCallback((e) => {
        const {key, keyCode} = e;

        /**** ~~ Prevent events 'ESCAPE' ****/
        // eslint-disable-next-line eqeqeq
        if (keyCode == '27' || keyCode == '9') {
            e.stopImmediatePropagation();
            e.preventDefault();
        }

        /**** ~~ Escape handle ****/
        // eslint-disable-next-line eqeqeq
        if (key === 'Escape' || keyCode == '27') {
            if (closeOnOuterClick) {
                fullScreenHandle.active && fullScreenHandle.exit();
                modal.onCloseGoTo && history.push(modal.onCloseGoTo);
                modal.close();
            }
        }

        /**** ~~ Tab handle ****/
        // eslint-disable-next-line eqeqeq
        if (key === 'Tab' || keyCode == '9') {
            const findDom = (element, selector) => {
                return element ? Array.from(element.querySelectorAll(selector)) : [];
            };

            const getFocusableElements = (element) => {
                let focusableElements = findDom(
                    element,
                    `button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
            [href][clientHeight][clientWidth]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
            input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]), select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
            textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]), [tabIndex]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
            [contenteditable]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])`
                );

                let visibleFocusableElements = [];

                for (let focusableElement of focusableElements) {
                    if (
                        getComputedStyle(focusableElement).display !== 'none' &&
                        getComputedStyle(focusableElement).visibility !== 'hidden'
                    )
                        visibleFocusableElements.push(focusableElement);
                }

                return visibleFocusableElements;
            };

            const focusableElements = getFocusableElements(modalRef.current);

            if (!document.activeElement) {
                focusableElements.length && focusableElements[0].focus();
            } else {
                let focusedIndex = focusableElements.indexOf(document.activeElement);
                if (e.shiftKey) {
                    if (focusedIndex === -1 || focusedIndex === 0) {
                        focusableElements[focusableElements.length - 1].focus();
                    } else {
                        focusableElements[focusedIndex - 1].focus();
                    }
                } else {
                    if (
                        focusedIndex === -1 ||
                        focusedIndex === focusableElements.length - 1
                    ) {
                        focusableElements.length && focusableElements[0].focus();
                    } else {
                        focusableElements[focusedIndex + 1].focus();
                    }
                }
            }
        }
    }, []);

    const listener = useCallback((event) => {
        /**** ~~  Do nothing if clicking ref's element or inner elements ****/
        if (!modalRef.current || modalRef.current.contains(event.target)) {
            return;
        }
        if (modal.closeOnOuterClick) {
            fullScreenHandle.active && fullScreenHandle.exit();
            !popup.isVisible && modal.close(modal.onCloseGoTo && history.push(modal.onCloseGoTo));
        }
    }, []);

    //работает, но ломается, если выходить в фулскрин через кнопку модалки. Подумать...
    // const fullscreenchange = useCallback(() => {
    //     if ((window.screen.availHeight <= window.innerHeight) && !fullScreenHandle.active) {
    //         fullScreenHandle.enter();
    //     } else if ((window.screen.availHeight > window.innerHeight) && fullScreenHandle.active) {
    //         fullScreenHandle.exit();
    //     }
    // }, []);

    /**** ~~ Add event listeners on dialog enter ****/
    const onDialogEntered = () => {
        document.addEventListener('keydown', keydownHandler);
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        //window.addEventListener('resize', fullscreenchange);
    };

    /**** ~~ Remove event listeners on dialog exit ****/
    const onDialogExited = () => {
        document.removeEventListener('keydown', keydownHandler);
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
        //window.removeEventListener('resize', fullscreenchange);
    };

    /**** ~~ Set modal size for active casino, slot or virtual game ****/
    useEffect(() => {
        if (bananaDefender) {
            if (activeGame?.gameUrl) {
                setGameSize({
                    height: activeGame.gameHeight
                        ? activeGame.gameHeight + 'px'
                        : 'calc(80vh - 4.2rem)',
                    width: activeGame.gameWidth ? activeGame.gameWidth + 'px' : '60vw',
                });
            } else if (modal.isActive && currentKey === 'launch-game') {
                modal.close();
            }
        }
    }, [activeGame]);

    /**** ~~ Enter to fullscreen on opening external-link ****/
    useEffect(() => {
        if (bananaDefender) {
            if (maskBackground && isActive && (closeOnOuterClick || activeGame)) {
                setBackdropOnClick(() => {
                    if (!activeGame) {
                        closeOnOuterClick && modal.close();
                        if (drawer.isActive) {
                            setBackdropShown(true);
                            setBackdropOnClick(drawer.close);
                        }
                    }
                });
                if (activeGame) {
                    setBackdropTransparent(false);
                    setBackDropClosable(false);
                }
                setBackDropFullScreen(true);
            }
            if (!drawer.isActive && maskBackground && (isActive || closeOnOuterClick)) {
                setBackdropShown(isActive);
            }
            if (fullScreenHandle?.active) {
                fullScreenHandle.exit();
            }
        }
    }, [isActive]);

    useEffect(() => () => {
        bananaDefender = false
    }, [isActive]);

    /*********************     *************************/
    /************ Render modal components *************/
    /*********************     ***********************/

    /**** ~~ Modal game container ****/
    const renderGame = () => {
        return (
            <>
                {loading ? (
                    <LLPreloader className="open-game__load-indicator"/>
                ) : null}
                <div
                    id="open-game__container"
                    className="open-game__container"
                    style={{height: gameSize.height, width: gameSize.width}}
                >
                    {activeGame?.gameUrl && (
                        <iframe
                            frameBorder="0"
                            title="game-iframe"
                            src={activeGame.gameUrl}
                            className="open-game__container-iframe"
                            allowFullScreen
                            onLoad={() => setLoading(false)}
                            allow={activeGame?.providerId !== '370' ? "autoplay *; fullscreen *;" : "autoplay *; screen-wake-lock *; fullscreen *;"}
                            scrolling={(activeGame.gameUrl.includes('api/virtual') || activeGame.gameUrl.includes('providerId=210')) ? "yes" : "no"}
                            style={{
                                overflow: "hidden",
                                height: gameSize.height || '100%',
                                width: gameSize.width || '100%',
                            }}
                        />
                    )}
                </div>
            </>
        );
    };

    const renderCustomContent = () => (
        <div className="ll-modal__custom-content">{customContent}</div>
    );

    /**** ~~ Modal inner components ****/
    const components = {
        'change-password-form': <ChangePasswordForm/>,
        'forgot-password-form': <ForgotPasswordForm/>,
        'clear-betslip': <ClearBetslipModal/>,
        'login-form': <LoginForm/>,
        'launch-game': renderGame(),
        'external-link': (
            // TODO: add loading on iframe onload
            // <div className="ll-modal__external-link-wrp">
            //     <div className={
            //         `ll-modal__external-link-loading ${
            //             externalFrameLoading ? 'll-modal__external-link-loading--is-loading' : ''
            //         }`}>
            //         <LLPreloader height={50} width={50} />
            //     </div>
            <iframe
                src={externalLink}
                title="modal-iframe"
                // onLoad={() => setExternalFrameLoading(false)}
                className="ll-modal__external-link"
            />
        ),
        // </div>),
        'promo-modal': <PromoModal/>,
        payment: <PaymentModal/>,
        'custom-content': renderCustomContent(),
        'success-bet-modal': <SuccessBetModal/>,
    };

    const clickCloseButton = () => {
        fullScreenHandle.active && fullScreenHandle.exit();
        modal.close(onCloseGoTo && history.push(onCloseGoTo));
    };

    const renderModalContent = () =>
        isActive && (
            <>
                {showHeader ? (
                    <div
                        className={`ll-modal__header${
                            modal.isError ? '--error' : ''
                        }`}
                    >
                        <WithPopover>
                            <div
                                className="ll-modal__header-title"
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            />
                        </WithPopover>
                        {showFullscreenButton && !mobileUserAgent && (
                            <div
                                className={`ll-modal__button ll-modal__fullscreen-button ${
                                    showHeader
                                        ? ''
                                        : 'll-modal__fullscreen-button--external'
                                }`}
                            >
                                {fullScreenHandle.active ? (
                                    <i
                                        className="llg-compress ll-modal__fullscreen-button-icon"
                                        onClick={fullScreenHandle.exit}
                                    />
                                ) : (
                                    <i
                                        className="llg-expand ll-modal__fullscreen-button-icon"
                                        onClick={fullScreenHandle.enter}
                                    />
                                )}
                            </div>
                        )}
                        {showCloseButton && (
                            <div
                                className={`ll-modal__button ll-modal__close-button${
                                    showHeader
                                        ? ''
                                        : 'll-modal__close-button--external'
                                }`}
                            >
                                <i
                                    className="doruk-close ll-modal__close-button-icon"
                                    onClick={clickCloseButton}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="ll-modal__btns--external">
                        {showFullscreenButton && !mobileUserAgent && (
                            <div
                                className={`ll-modal__button ll-modal__fullscreen-button ${
                                    showHeader
                                        ? ''
                                        : 'll-modal__fullscreen-button--external'
                                }`}
                            >
                                {fullScreenHandle.active ? (
                                    <i
                                        className="llg-compress ll-modal__fullscreen-button-icon"
                                        onClick={fullScreenHandle.exit}
                                    />
                                ) : (
                                    <i
                                        className="llg-expand ll-modal__fullscreen-button-icon"
                                        onClick={fullScreenHandle.enter}
                                    />
                                )}
                            </div>
                        )}
                        {showCloseButton && (
                            <div
                                className={`ll-modal__button ll-modal__close-button ${
                                    showHeader
                                        ? ''
                                        : 'll-modal__close-button--external'
                                }`}
                            >
                                <i
                                    className="doruk-close ll-modal__close-button-icon"
                                    onClick={clickCloseButton}
                                />
                            </div>
                        )}
                    </div>
                )}
                <LLScrollBox
                    id="modal-scroll"
                    customClasses={
                        currentKey === 'external-link'
                            ? 'll-modal__scrollbox--external'
                            : 'll-modal__scrollbox--' + viewSize
                    } //todo: rename class
                    autoHeightMax={fullScreenHandle.active ? '100vh' : '90vh'}
                >
                    {components[currentKey]}
                </LLScrollBox>
            </>
        );

    /***************** ~~ Render ****************/
    return (
        <>
            <CSSTransition
                /* ll-modal className used to append css transition modifiers */
                classNames={`ll-modal`}
                timeout={400}
                in={isActive}
                onExited={onDialogExited}
                onEntered={onDialogEntered}
            >
                <div
                    className={cx(
                        'll-modal',
                        `ll-modal--${viewSize}`,
                        (isActive && showHeader) && 'll-modal--with-header',
                        isActive && customClasses,
                        isActive && 'll-modal--opened'
                    )}
                    id="ll-modal"
                    ref={modalRef}
                >
                    {showFullscreenButton ? (
                        <FullScreen handle={fullScreenHandle}>
                            {renderModalContent()}
                        </FullScreen>
                    ) : (
                        renderModalContent()
                    )}
                </div>
            </CSSTransition>
        </>
    );
});

export default LLModal;