import { flow, getRoot } from 'mobx-state-tree';

import llattempt from '../../../../../../common/utils/attempt';
import api from '../../../../../../common/api';

/*
##     ##    ###    ########  ######  ##     ##    ######## ######## ########  ######  ##     ## ########  ######
###   ###   ## ##      ##    ##    ## ##     ##    ##       ##          ##    ##    ## ##     ## ##       ##    ##
#### ####  ##   ##     ##    ##       ##     ##    ##       ##          ##    ##       ##     ## ##       ##
## ### ## ##     ##    ##    ##       #########    ######   ######      ##    ##       ######### ######    ######
##     ## #########    ##    ##       ##     ##    ##       ##          ##    ##       ##     ## ##             ##
##     ## ##     ##    ##    ##    ## ##     ##    ##       ##          ##    ##    ## ##     ## ##       ##    ##
##     ## ##     ##    ##     ######  ##     ##    ##       ########    ##     ######  ##     ## ########  ######
*/

const matchFetches = (s) => ({
    fetchDataForAllMarkets: flow(function* fetch() {
        const allMarketsParams = {
            matchId: s.id,
        };

        return llattempt(() => api.betting.getAllMarkets(allMarketsParams), {
            withNotifier: false,
            at: 'fetchDataForAllMarkets',
            withParams: allMarketsParams,
        });
    }),

    fetchDataForAllCustomMarkets: flow(function* fetch() {
        const odds = getRoot(s).user.coupons.customCouponsList;
        const oddsIdsArr = [];
        odds.map(odd => {
            oddsIdsArr.push(odd.id)
        })

        const allMarketsParams = {
            matchId: s.id,
            oddsId: oddsIdsArr
        };

        return llattempt(() => api.betting.getAllCustomMarkets(allMarketsParams), {
            withNotifier: false,
            at: 'fetchDataForAllCustomMarkets',
            withParams: allMarketsParams,
        });
    }),

    fillSesiForMatchPlayers: flow(function* fetch({ matchId }) {
        return yield api.betting.getMatchPlayers({ matchId });
    }),

    fetchDataForLiveAllMarkets: flow(function* fetch() {
        const allMarketsParams = {
            matchId: s.id,
        };

        return yield llattempt(() => api.betting.getLiveAllMarkets(allMarketsParams), {
            withNotifier: false,
            at: 'fetchDataForLiveAllMarkets',
            withParams: allMarketsParams,
        });
    }),

    fetchDataForLiveAllCustomMarkets: flow(function* fetch() {
        const odds = getRoot(s).user.coupons.customCouponsList;
        const oddsIdsArr = [];
        odds.map(odd => {
            oddsIdsArr.push(odd.id)
        })

        const allMarketsParams = {
            matchId: s.id,
            oddsId: oddsIdsArr
        };

        return llattempt(() => api.betting.getLiveAllCustomMarkets(allMarketsParams), {
            withNotifier: false,
            at: 'fetchDataForLiveAllCustomMarkets',
            withParams: allMarketsParams,
        });
    }),
});

export default matchFetches;
