import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { withCommon, WithDropdown } from '../../../components';
import {trans} from "../../../common/utils";
import CheckBox from "devextreme-react/check-box";

/**
 * @file Represents a Component for custom input field with necisarrily strange logic
 *
 * @param {object} props
 * @param {func} props.trans - function to translate text
 * @param {bool} props.fetching - for hide picker while data is processed from/to server
 * @param {array} props.selectedSystems - array of selected bet systems
 * @param {array} props.systems - array of all bet systems
 * @param {func} props.setSelectedSystems - selectedSystems setter
 * @param {int} props.betslipsCount - count of bet slips in coupon
 *
 * @author O.BIELOTIELOV
 */

const BetSystemPicker = withCommon({
    store: ({ store }) => ({
        fetching: store.user.coupons.fetching,
        selectedSystems: store.user.coupons.selectedSystems,
        systems: store.user.coupons.systems,
        setSelectedSystems: store.user.coupons.setSelectedSystems,
        betslipsCount: store.user.coupons.betslipsCount,
        couponCount: store.user.coupons.couponCount,
        viewSize: store.site.status.viewSize,
    }),
    isTrans: true,
    isObserver: true,
})(({
    trans,
    fetching,
    selectedSystems,
    systems,
    setSelectedSystems,
    betslipsCount,
    couponCount,
    isSystemNull,
    setSystemNull,
    viewSize,
}) => {
    const [isAll, setIsAll] = useState(false);
    const [systemPickerStatus, setSystemPickerStatus] = useState(false);

    let bananaDefender = true;
    /***************** ~~ Close panel on any ticket change ****************/
    useEffect(() => {
        if (fetching && bananaDefender) {
            setSystemPickerStatus(false);
        }
    }, [fetching]);

    /************** ~~ Auto checker for 'Select all' menu item ************/
    useEffect(() => {
        bananaDefender && setIsAll(selectedSystems?.length === systems?.length);
    }, [selectedSystems?.length, systems?.length]);

    useEffect(
        () => () => {
            bananaDefender = false;
        },
        []
    );

    const setAll = () => {
        const allArr = [];
        if (!isAll) {
            systems.map((sysItem) => allArr.push(sysItem.ticketsInCombo));
        }
        setSelectedSystems(allArr);
    };

    const addOrRemove = (arr, item) =>
        arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

    const systemsDropDown = () => {
        const tempSystems = [
            ...systems.map((sysItem, i) => ({
                element: (
                    <div key={i} className="coupons__bet-system-picker-item">
                        <CheckBox
                            value={selectedSystems.includes(sysItem.ticketsInCombo)}
                            text={`${sysItem.ticketsInCombo}/${couponCount} (${parseInt(
                                sysItem.betslips
                            )} ${trans('Bet slip')})`}
                        />
                    </div>
                ),
                code: sysItem.ticketsInCombo,
            })),
        ];
        if (systems.length > 1) {
            tempSystems.unshift({
                element: (
                    <div className="coupons__bet-system-picker-item">
                        {isAll ? trans('Deselect All') : trans('Select All')}
                    </div>
                ),
                code: 'all',
            });
        }
        return tempSystems;
    };

    const onDropdownItemClick = (item) => {
        if (item.code === 'all') {
            setAll();
        } else {
            setSelectedSystems(addOrRemove(selectedSystems, item.code));
        }
    };

    return (
        <WithDropdown
            dataSource={{ submenu: systemsDropDown() }}
            menuItemIsActive={(item) => selectedSystems.includes(item.code)}
            onMenuItemClick={onDropdownItemClick}
            customDropdownStatus={systemPickerStatus}
            customClasses={cx(
                'coupons__bet-system-picker',
                isSystemNull && !selectedSystems.length && 'coupons__bet-system-picker--nullable'
            )}
            menuClasses="coupons__bet-system-dropdown-menu"
            openBehavior="click"
            withAccordion={viewSize === 'desktop'}
            multiSelect={true}
            placement="top"
        >
            <button
                className="coupons__bet-system-picker-button"
                type="button"
                onClick={() => {
                    selectedSystems.length && setSystemNull(false);
                    setSystemPickerStatus(!systemPickerStatus);
                }}
            >
                <span>{trans('System')}: </span>
                <span className="coupons__bet-system-picker-button-text menu-item__dropdown-text">
                    {(betslipsCount === 0) ? `${trans('BET_SLIP_EMPTY')} ` : `${betslipsCount} ${trans('Bet slip')} `}
                </span>
            </button>
        </WithDropdown>
    );
});

export default BetSystemPicker;