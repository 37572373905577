import React, {useEffect, useState} from "react";

import {withCommon} from "../../../../components";
import {CreateBattleGamesList} from "./create-battle-games-list";
import BattleCard from "../battle-detail/battle-card";
import BattleLobby from "../battle-detail/battle-lobby";
import {CreateBattleMenu} from "./create-battle-menu";
import TutorialPopup from "../tutorials/tutorial-popup";
import SecondPopup from "../tutorials/second-popup";


export const CreateBattle = withCommon({
    store: ({store}) => ({
        activeBattle: store.slotsBattles.activeBattle,
        setSlotBattlesType: store.slotsBattles.setSlotBattlesType,
        tutorialStep: store.slotsBattles.tutorialStep,
        viewSize: store.site.status.viewSize,
        isLogged: store.user.isLogged,
    }),
    defaultName: "CreateBattle",
    isObserver: true
})(({activeBattle, setSlotBattlesType, tutorialStep, viewSize, isLogged}) => {

    const
        [gameChooseIsActive, setGameChooseIsActive] = useState(false),
        [selectedGame, setSelectedGame] = useState();

    useEffect(() => {
        if (!isLogged) {
            setSlotBattlesType('available');
        }
    }, [isLogged]);

    return (
        <div className={`create-battle create-battle--${viewSize}`}>
            <div className="battle-info__first-part">
                {(tutorialStep === 2) ? <TutorialPopup content={<SecondPopup/>} popupPosition={'top'} indicatorPosition={viewSize !== 'mobile' ? 'left-top' : 'bottom-center'}/> : null}
                <div className="create-battle-wrapper__left-part">
                    { gameChooseIsActive && viewSize === 'mobile' ?
                        <CreateBattleGamesList
                            {...{
                                gameChooseIsActive,
                                setGameChooseIsActive,
                                setSelectedGame
                            }}
                        />
                        :
                        <CreateBattleMenu
                            {...{
                                selectedGame,
                                setSelectedGame,
                                gameChooseIsActive,
                                setGameChooseIsActive
                            }}
                        />
                    }
                </div>
                { (gameChooseIsActive && viewSize !== 'mobile') ? (
                    <div className="create-battle-wrapper__middle-right-part">
                        <CreateBattleGamesList
                            {...{
                                gameChooseIsActive,
                                setGameChooseIsActive,
                                setSelectedGame
                            }}
                        />
                    </div>
                ) : (
                    <div className="create-battle-wrapper__middle-part">
                        { (viewSize === 'desktop' || !gameChooseIsActive) &&
                            <div className="slots-battles__battle-detail-block">
                                <BattleCard game={selectedGame} setGame={setSelectedGame} withoutButton={true} />
                            </div>
                        }
                        { activeBattle ?
                            <div className="slots-battles__battle-detail-block">
                                <BattleLobby/>
                            </div>
                            : null
                        }
                    </div>
                )}
            </div>
        </div>
    );
});
