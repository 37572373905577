import React, {Fragment, useState} from 'react';
import classNames from "classnames";
import {LiveMatchItemMobile} from "./live-match-item";


export const LiveTournamentItemMobile = (
    {
        branch,
        category,
        tournament,
        userCanCashout,
        isLogged,
        activeItems,
        siteName,
        viewSize,
    }
) => {
    const [isOpened, setOpened] = useState(true);

    return (<>
        <div className="livematches__category-name" onClick={() => {setOpened(v => !v)}}>
            <span className="livematches__category-expand-wrp" />
            <span>{category.name} / {tournament.name}</span>
            <span className="livematches__category-expand-wrp">
                <i className={classNames(
                    'doruk-mg-arrow-2',
                    'livematches__category-expand-icon',
                    isOpened && 'livematches__category-expand-icon--expanded'
                )} />
            </span>
        </div>
        <div className={classNames(
            'livematches__category-content',
            isOpened && 'livematches__category-content--expanded'
        )}>
            { tournament.listLiveMatches.map((match) =>
                <LiveMatchItemMobile key={match.id} {...{
                    match,
                    branch,
                    activeItems,
                    userCanCashout,
                    isLogged,
                    siteName,
                    viewSize,
                }} />
            )}
        </div>
    </>);
};