const passwordStrength = (password) => {
    if (password) {
        let strength = 0;
        if (/.*[a-z].*/.test(password)) {
            strength += 10;
        }
        if (/.*[A-Z].*/.test(password)) {
            strength += 20;
        }
        if (/.*[0-9].*/.test(password)) {
            strength += 20;
        }
        let pwdLenght = password.length - 3;
        if (pwdLenght > 5) {
            pwdLenght = 5;
        }
        if (pwdLenght > 0) {
            strength += pwdLenght * 10;
        }
        return strength;
    }
};

export default passwordStrength;
