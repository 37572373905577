import React, {useEffect, useRef, useState} from 'react';
import throttle from "lodash/throttle";

import {SlotsBattlesListItem} from './';
import api from '../../../common/api';
import {LLPreloader, LLScrollLoader, withCommon} from "../../../components";
import NoBattles from "./no-battle";

let isSlotsBattlesListMounted = true;


export const SlotsBattlesList = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
        userBattleId: store.user.userBattleId,
        isActive: store.site.status.isActive,
        whatWasChanged: store.user.battlePopup.whatWasChanged,
        slotBattlesType: store.slotsBattles.slotBattlesType,
        search: store.slotsBattles.slotBattlesSearch,
        provider: store.slotsBattles.slotBattlesChosenProvider,
        sortby: store.slotsBattles.slotBattlesChosenSortby,
        canPlaySlotBattles: store.user.canPlaySlotBattles,
        isLogged: store.user.isLogged,
        router: store.router,
        user: store.user,
    }),
    defaultName: 'SlotsBattlesList',
    isObserver: true,
})(({
        provider,
        sortby,
        search,
        filter,
        sort,
        viewSize,
        userBattleId,
        isActive,
        whatWasChanged,
        slotBattlesType,
        canPlaySlotBattles,
        isLogged,
        router,
        user,
        rooms,
        setRooms,
        setIsNoData,
    }) => {

    useEffect(() => () => {
        setRooms([]);
    }, []);

    const [error, setError] = useState(false),
        [loading, setLoading] = useState(true);

    const [skip, setSkip] = useState(0),
        [itemWidth, setItemWidth] = useState(viewSize === 'desktop' ? 395 : 350),
        [take, setTake] = useState(0),
        [isGotten, setGotten] = useState(false),
        [updateResponse, setUpdateResponse] = useState(),
        [allRoomsTaken, setAllRoomsTaken] = useState(false);

    const listRef = useRef(null);
    const siteIsActive = useRef(isActive);
    siteIsActive.current = isActive;

    useEffect(() => {
        setIsNoData(rooms?.length && !error);
    }, [rooms, error]);

    const setGottenData = (res, battlesType) => {
        if (res && res.success) {
            setError(false);
            if (((res.data?.data?.length || 0) < take) || (rooms.length && !res.data?.data?.length)) {
                setAllRoomsTaken(true);
            } else {
                setAllRoomsTaken(false);
            }
            res.data?.data?.length && res.data.data.map(room => {
                if (room.idt) room.idt = room.idt.replace(' ', 'T');
                if (room.sdt) room.sdt = room.sdt.replace(' ', 'T');
            })

            res.data?.data?.length && setRooms([...rooms, ...res.data?.data]);
            clearTimeout(window._roomsUpdateInterval);
            window._roomsUpdateInterval = setTimeout(() => updateBattleRooms(battlesType, rooms.length + (res.data?.data?.length || 0)), 5000);
            setSkip(skip + (res.data?.data?.length || 0));
        } else {
            setSkip(0);
            setError(true);
        }
        setLoading(false);
    };

    const gatherParams = (params) => {
        if (Object.keys(sort).length) params.sort = sort;
        if (sort.type === 'My battles') {
            params.sort = [{
                selector: sort.selector,
                direction: sort.direction,
            }];
        }
        if (Object.values(filter).length > 1) {
            params.filter = Object.values(filter).reduce((acc, param) => [...acc, param], []);
        } else {
            params.filter = Object.values(filter)[0];
        }
        return {params: JSON.stringify(params)};
    };

    const getBattleRooms = (isLoadingNeeded = true) => {
        isLoadingNeeded && setLoading(true);
        clearTimeout(window._roomsUpdateInterval);
        setGotten(true);

        const params = gatherParams({skip, take});
        const res = sort.type === 'My battles' ?
            api.games.getMyRooms(params)
            :
            api.games.getRooms(params);

        res.then(res =>
            setGottenData(res, slotBattlesType)
        ).catch(() => {
            setError(true);
            setLoading(false);
        })
    };

    const updateBattleRooms = (battlesType, roomsCount = rooms.length) => {
        if (isSlotsBattlesListMounted && battlesType === slotBattlesType && battlesType !== 'completed') {
            clearTimeout(window._roomsUpdateInterval);
            if (siteIsActive.current) {
                setGotten(false);

                let takeForUpdate = take;
                if (roomsCount >= take) {
                    if (roomsCount % take) {
                        takeForUpdate = roomsCount + (take - roomsCount % take);
                    } else {
                        takeForUpdate = roomsCount + take;
                    }
                }

                const params = gatherParams({skip: 0, take: takeForUpdate});
                const res = sort.type === 'My battles' ?
                    api.games.getMyRooms(params)
                    :
                    api.games.getRooms(params);

                res.then((res) => {
                    res.battlesType = battlesType;
                    if (res.data?.data?.length) {
                        res.data.data.map(room => {
                            if (room.idt) room.idt = room.idt.replace(' ', 'T');
                            if (room.sdt) room.sdt = room.sdt.replace(' ', 'T');
                        })
                    }
                    setUpdateResponse(res);
                }).catch(() => {
                    setError(true);
                    setLoading(false);
                });
            } else {
                window._roomsUpdateInterval = setTimeout(() => updateBattleRooms(battlesType, roomsCount), 5000);
            }
        }
    };

    const resetItemsInRow = throttle(() => {
        if (viewSize === 'mobile') {
            setTake(20);
        } else {
            setTake(Math.trunc(listRef.current.offsetWidth / itemWidth) * 5);
        }
    }, 300);

    const loadMoreGames = (forceTake = false) => {
        if (!allRoomsTaken || forceTake) {
            getBattleRooms(!forceTake);
        }
    };

    useEffect(() => {
        isSlotsBattlesListMounted = true;
        resetItemsInRow();
        window.addEventListener("resize", resetItemsInRow);
        return () => {
            window.removeEventListener("resize", resetItemsInRow);
            isSlotsBattlesListMounted = false;
            clearTimeout(window._roomsUpdateInterval);
            window._roomsUpdateInterval = null;
        }
    }, []);

    useEffect(() => {
        if (isSlotsBattlesListMounted && !isGotten && updateResponse?.battlesType === slotBattlesType) {
            if (updateResponse && updateResponse.success) {
                setError(false);
                setRooms(updateResponse.data?.data?.length ? [...updateResponse.data.data] : []);
                clearTimeout(window._roomsUpdateInterval);
                window._roomsUpdateInterval = setTimeout(() => updateBattleRooms(slotBattlesType, updateResponse.data?.data?.length || []), 5000);
            } else {
                setSkip(0);
                setError(true);
            }
        }
    }, [updateResponse]);

    useEffect(() => {
        if (isSlotsBattlesListMounted) {
            setRooms([]);
            setSkip(0);
            setAllRoomsTaken(false);
        }
    }, [JSON.stringify(filter), filter, JSON.stringify(sort), sort]);

    useEffect(() => {
        isSlotsBattlesListMounted && setItemWidth(viewSize === 'desktop' ? 290 : 180);
    }, [viewSize]);

    useEffect(() => {
        if (isSlotsBattlesListMounted && !rooms.length && take) {
            loadMoreGames();
        }
    }, [rooms]);

    useEffect(() => {
        if (isSlotsBattlesListMounted && rooms.length) {
            loadMoreGames(true);
        }
    }, [take]);

    useEffect(() => {
        if (isSlotsBattlesListMounted && rooms.length) {
            clearTimeout(window._roomsUpdateInterval);
            updateBattleRooms(slotBattlesType, updateResponse?.data?.data?.length || []);
        }
    }, [userBattleId, whatWasChanged]);

    useEffect(() => {
        if (isSlotsBattlesListMounted) {
            setLoading(true);
        }
    }, [slotBattlesType]);

    return (
        <div
            className="slots-battles__list-wrapper"
            ref={listRef}
        >
            {loading ?
                <div className="slots-battles__loading">
                    <LLPreloader/>
                </div>
                :
                rooms?.length && !error ?
                    <LLScrollLoader
                        pageSize={skip}
                        loadingCallback={() => {
                            loadMoreGames(true);
                        }}
                    >
                        <div className="slots-battles__game-list">
                            {rooms.map((room) =>
                                <SlotsBattlesListItem
                                    room={room}
                                    key={room.RoomUUID}
                                    updateBattleRooms={updateBattleRooms}
                                />)
                            }
                        </div>
                    </LLScrollLoader>
                    :
                    <NoBattles {...{
                        provider,
                        sortby,
                        search,
                        userBattleId,
                        whatWasChanged,
                        isActive,
                        canPlaySlotBattles,
                        isLogged,
                        router,
                        user,
                        viewSize
                    }}/>
            }
        </div>);
});
