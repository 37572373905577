import React, { useState, useEffect } from 'react';

import { withCommon, LLErrorVisualizer, LLPreloader, LLBtn } from '../../components';
import ErrorPage from '../error/error';
import api from '../../common/api';

const MailVerifyPage = withCommon({
    store: ({ store }) => ({
        viewSize: store.site.status.viewSize,
        isLogged: store.user.isLogged,
        siteName: store.site.siteName,
        modal: store.modal,
    }),
    isRouter: true,
    isTrans: true,
})(({ trans, viewSize, isLogged, history, siteName, modal }) => {
    const [btag, setBtag] = useState('0'),
        [isError, setIsError] = useState(false),
        [isFetching, setIsFetching] = useState(true),
        [serverError, setServerError] = useState(''),
        [customerId, setCustomerId] = useState('');

    const urlParams = new URLSearchParams(history.location.search);
    const code = urlParams.get('code');

    useEffect(() => {
        sendMailCode();
    }, []);

    const getIframeSrc = () => {
        switch (siteName) {
            case 'Bahissenin':
                return `http://wlbahissenin.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID${customerId}&mid=3`;
            case 'Betlima':
                return `http://wlbetlima.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=8`;
            case 'Ciprobet':
                return `http://wlciprobet.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=18`;
            case 'Efsanebahis':
                return `http://wlefsanebahis.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=23`;
            case 'Maksatbahis':
                return `http://wlmaksatbahis.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=28`;
            case 'Retrobet':
                return `http://wlretrobet.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=33`;
            case 'Show Bahis':
                return `http://wlshowbahis.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=43`;
            case 'Colonybet':
                return `http://wlcolonybet.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=53`;
            case 'BetPapel':
                return `http://wlbetpapel.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?PlayerID=${customerId}&mid=58`;
            default:
                return null;
        }
    };

    const renderSuccessContent = () => (
        <div>
            {btag !== '0' && <iframe style={{ display: 'none' }} src={getIframeSrc()} />}
            {trans('Your membership has approved')}
            <div className={'mail-verify__button-container'}>
                <LLBtn
                    customClasses={'mail-verify__button'}
                    onClick={() => {
                        history.push('/');
                        modal.close();
                    }}
                >
                    {trans('Ok')}
                </LLBtn>
            </div>
        </div>
    );

    const renderError = () => {
        if (!isLogged && code) {
            return (
                <LLErrorVisualizer
                    error={serverError ? serverError : 'CONNECTION_ISSUES_ERROR'}
                    btnText="Ok"
                    isSiteGlobal={true}
                />
            );
        } else {
            return <ErrorPage />;
        }
    };

    const sendMailCode = async () => {
        if (code) {
            try {
                let response = await api.user.verifyMail({
                    code: code,
                });
                // response = {
                //     success: true,
                //     data: {
                //         btag:'a',
                //         customerId: ''
                //     }
                // }
                if (!response.success) {
                    setIsError(true);
                    setServerError(response.data.error);
                } else {
                    setBtag(response.data.btag);
                    setCustomerId(response.data.customerId);
                    modal.setModal({
                        active: true,
                        maskBackground: true,
                        showHeader: true,
                        currentKey: 'custom-content',
                        title: `${trans('Welcome to')} ${siteName}`,
                        customContent: renderSuccessContent(),
                        onCloseGoTo: '/',
                    });
                }
            } catch (error) {
                setIsError(true);
                console.error(error);
            }
            setIsFetching(false);
        } else {
            setIsError(true);
            setIsFetching(false);
        }
    };

    return (
        <div className={`ll-page mail-verify--${viewSize}`}>
            {isFetching ? (
                <LLPreloader visible={isFetching} height={50} width={50} />
            ) : !isLogged && !isError ? null : (
                renderError()
            )}
        </div>
    );
});

export default MailVerifyPage;
