import {getRoot, types} from 'mobx-state-tree';
import {removeCookie} from "../../../../common/utils/cookie";


const BattlePopup = types
    .model('BattlePopup', {
        isActive: false,
        isHiding: false,
        roomStatus: types.maybeNull(types.string),
        isPlayed: types.maybeNull(types.boolean),
        userPrize: types.maybeNull(types.number),
        connectedPlayers: types.maybeNull(types.number),
        needPlayers: types.maybeNull(types.number),
        whatWasChanged: types.maybeNull(types.string),
    })
    .volatile((s) => ({
        userBattle: {},
    }))
    .actions((self) => ({
        set({
                isActive = false,
                isHiding = false,
                roomStatus,
                isPlayed = 0,
                inPlay = 0,
                userPrize = 0,
                connectedPlayers = 0,
                needPlayers = 0,
                userBattle,
            }) {
            self.isActive = isActive;
            self.isHiding = isHiding;
            self.needPlayers = needPlayers;
            self.connectedPlayers = connectedPlayers;
            self.isPlayed = !!isPlayed;
            self.inPlay = !!inPlay;
            self.userPrize = userPrize;
            self.roomStatus = roomStatus;
            self.userBattle = userBattle;
            self.whatWasChanged = null;
            if (roomStatus === 'canceled') {
                self.whatWasChanged = 'canceled';
            }
            if (roomStatus === 'started' && self.whatWasChanged !== 'started' && !isPlayed) self.whatWasChanged = 'starting';
            if (!!inPlay) self.whatWasChanged = 'userInPlay';
            if (!!isPlayed) self.whatWasChanged = 'userIsPlayed';
            if (roomStatus === 'completed') {
                self.whatWasChanged = 'completed';
            }
            if (roomStatus === 'invitation') {
                self.whatWasChanged = 'invitation';
            }
            if (roomStatus === 'invitations') {
                self.whatWasChanged = 'invitations';
            }
        },
        update({
                   roomStatus,
                   isPlayed = 0,
                   inPlay = 0,
                   userPrize = 0,
                   connectedPlayers = 0,
                   needPlayers = 0,
                   userBattle
               }) {
            if (self.isActive) {
                self.needPlayers = needPlayers;
                self.connectedPlayers = connectedPlayers;
                self.isPlayed = !!isPlayed;
                self.inPlay = !!inPlay;
                self.userPrize = userPrize;
                self.roomStatus = roomStatus;
                self.userBattle = userBattle;
                self.whatWasChanged = null;
                if (roomStatus === 'canceled') {
                    self.whatWasChanged = 'canceled';
                }
                if (roomStatus === 'started' && self.whatWasChanged !== 'started' && !isPlayed) self.whatWasChanged = 'starting';
                if (!!inPlay) self.whatWasChanged = 'userInPlay';
                if (!!isPlayed) self.whatWasChanged = 'userIsPlayed';
                if (roomStatus === 'completed') {
                    self.whatWasChanged = 'completed';
                }
                if (roomStatus === 'invitation') {
                    self.whatWasChanged = 'invitation';
                }
                if (roomStatus === 'invitations') {
                    self.whatWasChanged = 'invitations';
                }
            }
        },
        setWhatWasChanged(str) {
            self.whatWasChanged = str;
        },
        setHiding(flag) {
            self.isHiding = flag;
        },
        close() {
            self.isActive = false;
            self.prize = null;
            self.userPrize = null;
            self.connectedPlayers = null;
            self.needPlayers = null;
            self.userNames = [];
            self.userBattle = {};
            removeCookie('USER_BATTLE_ID');
            self.whatWasChanged = '';
            const user = getRoot(self).user;
            if (user.userBattleId) {
                user.setUserBattleId(null);
                user.setUserSentBattleInvite(false);
            } else {
                user.removeInvitation();
            }
            clearTimeout(window._userBattleUpdateInterval);
            window._userBattleUpdateInterval = null;
        },
    }));

export default BattlePopup;
