import React, {useEffect, useState} from 'react';
import Slider from "@mui/material/Slider";

import {LLSportBettingButtonWrapper} from "../../../../../components";


const SportUnderOverMarket = ({betRows, oddAppearance}) => {
    const
        [activeValue, setActiveValue] = useState(null),
        [marketValues, setMarketValues] = useState([]);

    useEffect(() => {
        if (betRows.length) {
            let isActiveValueExists = false;
            let balancedPareValueDiff = 10000;
            let balancedValue = 0;
            const valuesSet = [];
            betRows.forEach(row => {
                row.bets[0].specifier.split('&').forEach(sp => {
                    if (sp.includes('total=')) {
                        const spValue = sp.split('=')[1];
                        if (+spValue === activeValue) {
                            isActiveValueExists = true;
                        }
                        valuesSet.push({value: +spValue, label: spValue});

                        const betsPairValueDiff = Math.abs(row.bets[0]?.outcomeValue - row.bets[1]?.outcomeValue);
                        if (balancedPareValueDiff > betsPairValueDiff) {
                            balancedPareValueDiff = betsPairValueDiff;
                            balancedValue = +spValue;
                        }
                    }
                })
            });
            setMarketValues(valuesSet);
            if (!isActiveValueExists) {
                setActiveValue(balancedValue);
            }
        }
    }, [betRows]);

    const findBets = (specValue) => betRows.reduce((acc, row) => [...acc, ...row.bets], [])
            .filter(item => item.specifier.split('&')
                .filter(sp => !!(sp.includes('total=') && sp.split('=')[1] == specValue)).length);

        return (
            <div className="match-details__bets match-details__bets--under-over">
                {activeValue && findBets(activeValue)?.length ?
                    findBets(activeValue).map(bet => (
                        <LLSportBettingButtonWrapper
                            bet={bet}
                            key={bet.id}
                            columnCount={2}
                            className="match-details__bet"
                            oddAppearance={oddAppearance}
                        />
                    ))
                    : null
                }
                <div className="fancy-bets-slider-wrp">
                    <Slider
                        value={activeValue ?? null}
                        onChange={(event, newValue) => setActiveValue(newValue)}
                        min={marketValues[0]?.value}
                        max={marketValues[marketValues.length - 1]?.value}
                        step={null}
                        valueLabelDisplay="auto"
                        marks={marketValues}
                        classes={{
                            root: 'fancy-bets-slider',
                            markLabel: 'fancy-bets-slider__label',
                            rail: 'fancy-bets-slider__rail',
                        }}
                    />
                </div>
            </div>
        );
    };

    export default SportUnderOverMarket;