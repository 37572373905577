import React, {useState, useRef, useEffect, useContext} from 'react';

import { withCommon, LLInput } from '../../../components';
import {GamesContext} from "../slots-and-casinos";


const SlotsCasinosGamesSearch = withCommon({
    store: ({ store }) => ({
        viewSize: store.site.status.viewSize,
        slotsCasinoGames: store.slotsCasinoGames,
    }),
    isTrans: true,
    isObserver: true,
})(({ trans, slotsCasinoGames, isMobile }) => {
    const {gamesType, searchGames, resetFiltering, setSorting} = useContext(GamesContext) ?? {};
    const searchedText = slotsCasinoGames.presetFilters?.search;

    const [searchText, setSearchText] = useState(searchedText);

    const [isSearchClearable, setSearchClearable] = useState(!!searchedText?.length);

    const inputRef = useRef(null);

    let bananaDefender = true;

    useEffect(() => {
        if (bananaDefender) {
            setSearchText(searchedText);
            !searchedText?.length && setSearchClearable(!!searchedText?.length);
        }
    }, [searchedText]);

    useEffect(() => () => { bananaDefender = false }, []);

    const startSearch = () => {
        inputRef.current.instance.blur();
        searchGames(searchText);
        setSearchClearable(true);
    };

    return (
        <div className="games__search">
            <LLInput
                value={searchText}
                ref={inputRef}
                customClasses="games__search-input"
                placeholder={trans('Search Game')}
                id="games-search"
                name="games-search"
                stylingMode="outlined"
                onValueChanged={(e) => {
                    setSearchText(e.value);
                    setSearchClearable(false);
                }}
                onEnterKey={startSearch}
            />
            {!isSearchClearable ? (
                <i
                    className={`doruk-search-01 games__search-icon`}
                    onClick={() => searchText.length && startSearch()}
                />
            ) : (
                <i
                    className={`doruk-close games__search-icon`}
                    onClick={() => {
                        setSorting();
                        resetFiltering();
                        setSearchText('');
                        setSearchClearable(false);
                    }}
                />
            )}
        </div>
    );
});

export default SlotsCasinosGamesSearch;
