import {types, flow, getParent} from 'mobx-state-tree';
import {values, when} from 'mobx';
import {sortBy} from 'lodash';

import MarketItem from './market';
import {marketGroupActions, marketsFetches} from './matches-instances-actions';

const MarketGroupInstance = types
    .model('MarketGroupInstance', {
        id: types.identifierNumber /* i */,
        order: types.number /* o */,
        markets: types.map(MarketItem) /* m */,
        name: types.maybeNull(types.string),
    })
    .extend((s) => ({
        actions: {...marketsFetches(s), ...marketGroupActions(s)},
    }))
    .actions((s) => ({
        removeItems: flow(function* fetch() {
            yield when(
                () => !values(s.markets).find((market) => market.isWaitingUpdate)
            );
            clearTimeout(window.__marketsUpdater);
            Promise.all([
                values(s.markets).map((market) => {
                    market.removeItems();
                }),
            ]).then(() => {
                getParent(s, 2).deleteItem({id: s.id});
            });
        }),

        deleteItem({id}) {
            s.markets.delete(id);
        },
    }))
    .views((s) => ({

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                   View markets list                                  ##
        // ##                                                                                      ##
        // ##========================================================================================

        marketsList(marketsCount = 0) {
            let order = marketsCount ? (marketsCount - s.marketsListLength()) : 0;
            return sortBy(values(s.markets), ['order', 'name'], 'asc').reduce((acc, market) => [...acc, {market, viewOrder: order++}], []);
        },

        marketsListLength() {
            return s.markets.size;
        },
    }));

export default MarketGroupInstance;
