/**
 * function run onload if our image loaded else run onerror;
 * onload, onerror need to write in your component;
 * example - casinosTile;
 *
 * @param {string} imageUrl
 * @param {func} onload
 * @param {func} onerror
 */

const imageLoading = async (imageUrl) =>
    new Promise((resolve) => {
        let img = new Image();
        img.src = imageUrl;
        img.onload = () => resolve(img.height > 1);
        img.onerror = () => resolve(false);
    });

export default imageLoading;
