import { types as t } from 'mobx-state-tree';

import {vendorTextReplacer} from '../../../common/utils';
import { setCookie } from '../../../common/utils/cookie';


const Popup = t
    .model('Popup', {
        id: 0,
        message: t.maybeNull(t.string),
        messageType: t.maybeNull(t.string),
        name: t.maybeNull(t.string),
        popupType: t.maybeNull(t.string),
        updateTime: t.maybeNull(t.Date),
        url: t.maybeNull(t.string),
        isVisible: false,
    })
    .actions((s) => {
        return {
            clear() {
                s.id = 0;
                s.message = '';
                s.messageType = '';
                s.name = '';
                s.popupType = '';
                s.url = '';
                s.isVisible = false;
            },

            show() {
                s.isVisible = true;
            },

            hide() {
                s.isVisible = false;
                setCookie('PTS', Date.now());
            },

            showNew(data) {
                s.id = data.id;
                s.url = data.url ? vendorTextReplacer(data.url.toString()) : null;
                if (data?.message) {
                    let modifiedMessage;
                    modifiedMessage = vendorTextReplacer(data.message.toString());
                    modifiedMessage.replaceAll(/<(a href="(.*)")>/gm,
                        (href, link) => {
                            if (link.includes('http')) {
                                modifiedMessage = modifiedMessage.replace(link, (link + ' target="_blank"'))
                            }
                            s.url = null;
                        })
                    s.message = modifiedMessage;
                }
                s.messageType = data.messageType.toString();
                s.name = vendorTextReplacer(data.name.toString());
                s.popupType = data.popupType.toString();
                s.updateTime = data.udt ? new Date(data.udt.replace(' ', 'T') + '+03:00') : null;

                s.isVisible = true;
            },
        };
    });

export default Popup;
