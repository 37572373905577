import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import initStore from './store';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line no-extend-native
String.prototype.replaceBetween = function (start, end, what) {
    return this.substring(0, start) + what + this.substring(end);
};

const store = initStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
