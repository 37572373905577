import { getRoot, types } from 'mobx-state-tree';

let closeTimeOut;
let openTimeOut;

const Drawer = types
    .model('Drawer', {
        activePosition: 'left',
        currentKey: types.maybeNull(types.string),
        isActive: false,
    })
    .actions((self) => ({
        _clearKey() {
            self.currentKey = '';
        },

        open({ side = 'left', currentKey = '' }) {
            clearTimeout(openTimeOut);
            if (self.currentKey) {
                clearTimeout(closeTimeOut);
                self.close();
                openTimeOut = setTimeout(() => {
                    self.open({
                        side: side,
                        currentKey: currentKey,
                    });
                }, 350);
            } else if (side !== self.activePosition) {
                self.activePosition = side;
                openTimeOut = setTimeout(() => {
                    self.open({
                        side: side,
                        currentKey: currentKey,
                    });
                }, 10);
            } else {
                self.currentKey = currentKey;
                self.isActive = true;
            }
        },
        close() {
            self.isActive = false;
            const userBets = getRoot(self).user.userBets;
            if (self.currentKey === 'my-bets-drawer') {
                userBets.activeBets.setMounted(false);
                userBets.betHistory.setMounted(false);
                userBets.setStatusFilter('');
                self.currentKey = '';
            }
            if (self.currentKey === 'my-account') {
                userBets.betHistory.setMounted(false);
                userBets.setStatusFilter('');
                userBets.betHistory.setActiveBet(null);
                self.currentKey = '';
            }
            closeTimeOut = setTimeout(() => self._clearKey(), 300);
        },
    }));

export default Drawer;
