import React, {useState, useEffect, useRef} from "react";
import {inject, observer} from "mobx-react";
import {throttle} from "lodash";

import {LLScrollLoader, LLPreloader} from "../../../components";
import {llattempt, trans} from "../../../common/utils";
import api from "../../../common/api";
import {JackpotGroup} from "./jackpot-group";
import GamesRender from "./games-render";

import variables from "../../../styles/themes/theme/variables.scss";


let jackpotsDetailsInterval = null;

const SlotsCasinosGamesList = (
    {
        store,
        gamesType,
        currentView,
        filtering,
        activeGameMenuItem,
        setActiveGameMenuItem,
        activeSubSubMenuItem,
        setActiveSubSubMenuItem,
        activeJackpot,
    }
) => {
    const viewSize = store.site.status.viewSize,
        mobileUserAgent = store.user.mobileUserAgent,
        customerGroup = store.user.customerGroup,
        slotsCasinoGames = store.slotsCasinoGames,
        activeProvider = store.slotsCasinoGames.presetFilters.activeProvider,
        jackpotGroupsList = store.slotsCasinoGames.jackpotGroupsList,
        slotsFiltersLoaded = store.slotsCasinoGames.slotsFiltersLoaded,
        jackpotGroupsListByProvider = store.slotsCasinoGames.jackpotGroupsListByProvider,
        getJackpotDetails = store.slotsCasinoGames.getJackpotDetails,
        providerJackpots = store.slotsCasinoGames.providerJackpots;

    const [isGamesListReloading, setGamesListReloading] = useState(true);
    const [isFetching, setFetching] = useState(false); //fetch flag
    const [itemsInRow, setItemsInRow] = useState(0); //items in row
    const [loading, setLoading] = useState(true); //loading on scroll
    const [games, setGames] = useState([]); //received games
    const [loadedGamesCount, setLoadedGamesCount] = useState(0); //total received games count after render or change activeItem
    const [allGamesLoaded, setAllGamesLoaded] = useState(false); //stop load more games on scroll event if we received games count less than we asked

    const gameListRef = useRef();

    const calculateItemsInRow = () => (15 + parseInt(variables['css_sizeWidth_newThumbnail_' + viewSize]));

    /********************* Game getter **********************/
    const loadGames = (params) => {
        return llattempt(
            () =>
                api.games
                    .getGames({
                            platform: mobileUserAgent ? 'mobile' : 'desktop',
                            type: gamesType === 'slots' ? 'slot' : 'casino',
                            params: JSON.stringify(params),
                        }
                    ),
            {
                msg: 'Cant load games',
                at: 'SlotsCasinosGamesList.loadMoreGames',
                withParams: {
                    platform: mobileUserAgent ? 'mobile' : 'desktop',
                    type: gamesType === 'slots' ? 'slot' : 'casino',
                    params: params,
                },
                onError: () => {
                    setLoading(false);
                    setGamesListReloading(false);
                    setFetching(false);
                },
                withNotifier: false,
            }
        );
    }

    const loadMoreGames = (isRowMissingItems = true) => {
        if (!allGamesLoaded) {
            setFetching(true);
            let take = itemsInRow * 5; //game count for request

            if (isRowMissingItems && loadedGamesCount) {
                //needed to recalculate asked item count on resize event
                if (take > loadedGamesCount) {
                    take = take - loadedGamesCount;
                } else if (take < loadedGamesCount) {
                    take = itemsInRow - ((loadedGamesCount - take) % itemsInRow);
                } else {
                    take = 0;
                }
            }

            const params = {
                skip: loadedGamesCount,
                take: take,
                filter: !activeJackpot ? filtering.filter : [[...filtering.filter], ['jackpotId', '=', activeJackpot]],
                sort: filtering.sort,
                select: filtering.select
            };

            if (take !== 0) {
                setLoading(true);
                loadGames(params).then((result) => {
                    if (result.success) {
                        setGames((games) => games.concat(result.data.data || []));
                        setLoadedGamesCount(
                            loadedGamesCount + result.data.data.length
                        );
                        result.data.data.length < take &&
                        setAllGamesLoaded(true);
                    } else {
                        setAllGamesLoaded(true);
                    }
                    setLoading(false);
                    setGamesListReloading(false);
                    setFetching(false);
                });
            } else {
                setLoading(false);
                setGamesListReloading(false);
                setFetching(false);
            }
        }
    };

    const loadJackpotGames = () => {
        setFetching(true);
        let take = viewSize === 'mobile' ? itemsInRow : (itemsInRow - 2) * 2;

        if (jackpotGroupsList.length) {
            if (take !== 0) {
                const requests = [];
                setLoading(true);

                jackpotGroupsList.forEach(group => {
                    const params = {
                        skip: 0,
                        take: take,
                        filter: !activeJackpot ? [filtering.filter, ['jackpotId', '=', group.id]] : filtering.filter,
                        sort: filtering.sort,
                        select: filtering.select
                    };
                    requests.push(loadGames(params));
                });
                Promise.all(requests).then((res) => {
                    res.forEach((result) => {
                        if (result.success) {
                            setGames((games) => games.concat(result.data.data || []));
                        }
                    });
                    setLoading(false);
                    setGamesListReloading(false);
                    setFetching(false);
                })
            }
        } else {
            if (!jackpotGroupsListByProvider.length) {
                setLoading(false);
                setGamesListReloading(false);
            }
            setFetching(false);
        }
    };
    /*************** call games getter on render or change activeItem or resize window ****************/
    const resetItemsInRow = throttle(() => {
        setItemsInRow(Math.trunc((gameListRef.current.offsetWidth + 15) / calculateItemsInRow()));
    }, 300);

    useEffect(() => {
        window.addEventListener("resize", resetItemsInRow);
        return () => {
            window.removeEventListener("resize", resetItemsInRow);
            clearInterval(jackpotsDetailsInterval);
        }
    }, []);

    useEffect(() => {
        getJackpotDetails({jackpotId: activeJackpot});
        clearInterval(jackpotsDetailsInterval);
        jackpotsDetailsInterval = setInterval(() => {
            getJackpotDetails({jackpotId: activeJackpot})
        }, 10000);
    }, [activeJackpot]);
    /**************** ******************/

    /**************** reset state on change filter condition or change type or device type ******************/
    useEffect(() => {
        setGames([]);
        setLoadedGamesCount(0);
        setAllGamesLoaded(false);
        setGamesListReloading(true);
        resetItemsInRow();
    }, [gamesType, JSON.stringify(filtering.filter), JSON.stringify(filtering.sort), activeProvider, activeJackpot, JSON.stringify(currentView)]);
    /**************** ******************/

    useEffect(() => {
        if (!isFetching) {
            if (!currentView.includes('jackpots') || activeJackpot) {
                loadMoreGames(!activeJackpot);
            } else {
                setGames([]);
                loadJackpotGames();
            }
        }
    }, [itemsInRow, customerGroup, slotsFiltersLoaded, isGamesListReloading, jackpotGroupsListByProvider]);

    /**************** RENDER ******************/
    return (
        <div className="games__list"
             ref={gameListRef}
        >
            {(activeGameMenuItem || activeSubSubMenuItem) && (
                <div
                    className="game-list__back-drop"
                    onClick={() => {
                        setActiveGameMenuItem('');
                        setActiveSubSubMenuItem(null);
                    }}
                />
            )}
            {(isGamesListReloading) ? (
                <div className="games__list-reloader" >
                    <LLPreloader/>
                </div>
            ) : (
                <LLScrollLoader
                    pageSize={loadedGamesCount}
                    loadingCallback={() => {
                        loadMoreGames(false);
                    }}
                >
                    {(games.length && slotsCasinoGames[gamesType + 'FiltersLoaded']) ?
                        currentView.includes('jackpots') ?
                            jackpotGroupsList.map((group, i) => {
                                    if (!activeJackpot || activeJackpot == group.id) {
                                        return <JackpotGroup
                                            key={group.id}
                                            games={games}
                                            group={group}
                                            gamesType={gamesType}
                                            activeJackpot={activeJackpot}
                                            isGroupFirst={i === 0}
                                            jackpotGroupsListByProvider={jackpotGroupsListByProvider}
                                            takeItemsCount={viewSize === 'mobile' ? itemsInRow : (itemsInRow - 2) * 2}
                                        />
                                    }
                                }
                            )
                            :
                            <GamesRender
                                jackpotGroups={providerJackpots({name: activeProvider})}
                                list={games}
                                itemsInRow={itemsInRow}
                                gamesType={gamesType}
                                isFeatured={currentView.includes('featured')}
                            />
                        : (
                            <p className="games__list--empty">
                                {trans('No games matching these parameters')}
                            </p>
                        )}
                    {loading && <LLPreloader className="games__list-loader"/>}
                </LLScrollLoader>
            )}
        </div>
    );
};

export default inject('store')(observer(SlotsCasinosGamesList));
