import React from 'react';
import PropTypes from 'prop-types';

import { withCommon } from '../../../components';


const PromoPageWrp = withCommon({
    propTypes: {
        isEmpty: PropTypes.bool,
        children: PropTypes.element.isRequired,
    },
    defautProps: {
        isEmpty: true,
    },
    defaultName: 'PromoPageWrp',
    isTrans: true,
})(({ trans, isEmpty, children }) =>
    isEmpty ? (
        <div className="promo-content__cards--big">
            <div className="promo-content__item promo-content__item--empty">
                <div className="promo-content__inner">
                    <div className="promo-content__inner-icon-wrp">
                        <i className="llg-light" />
                    </div>

                    <h2 className="promo-content__inner-title--empty">
                        {trans('There is no bonus for you yet. Please try again later')}
                    </h2>
                </div>
            </div>
        </div>
    ) : (
        children
    )
);

export default PromoPageWrp;
