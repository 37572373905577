import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";

import {LLPreloader} from "../../../../components";
import {DrawerCloseButton} from "../../components";
import {formatNumber, trans} from '../../../../common/utils';


const MyLimits = ({ store}) => {
    useEffect(() => {
        store.user.limits.getLimits().then();
    }, []);

    const
        viewSize = store.site.status.viewSize,
        currency = store.user.currency,
        language = store.user.language,
        casinoList = store.user.limits.casinoList,
        loading = store.user.limits.isTableLoading;

    return (
        <div className={`my-limits my-limits--${viewSize} my-account__content-container`}>
            {viewSize === 'desktop' && (
                <h2 className="my-account__title">
                    <span className="my-account__title-text">{trans('limits')}</span>
                    <DrawerCloseButton />
                </h2>
            )}
            {loading ? (
                <div className="my-account__loader-box">
                    <LLPreloader />
                </div>
            ) : (
                <div className="my-account__content">
                    { casinoList.length ?
                        <div className="my-limits__content-wrp">
                            <div className="my-limits__content-box my-limits__content-box--rows">
                                <p className="my-limits__content-box-title my-limits__double-content">
                                    <span>{trans('Provider')}</span>
                                    <span>{trans('Maximum Stake')}</span>
                                </p>
                                <div className="my-limits__content-box-wrp">
                                    { casinoList.map((casinoItem, i) => (
                                        <div className="my-limits__content-row my-limits__double-content" key={i}>
                                            <p className="my-limits__provider-name">{casinoItem.brandName}</p>
                                            <p className="my-limits__provider-limit">
                                                <span>{formatNumber(casinoItem.limit, 0, language, currency)}</span>
                                                <i className="my-limits__provider-limit-info-icon doruk-about-us-01"/>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="my-limits__content-box my-limits__content-box--info">
                                <p className="my-limits__content-box-title">
                                    <i className="my-limits__content-box-title-icon doruk-about-us-01"/>
                                    <span>{trans('Detailed Information')}</span>
                                </p>
                                <div className="my-limits__content-box-wrp">
                                    <ul className="my-limits__content-box-info">
                                        {trans('MY_LIMITS_INFO').split('<br />').map((line, i) => (
                                            <li key={i}>{line}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        <p className="data-table--empty">{trans('NO_LIMITS_DATA')}</p>
                    }
                </div>
            )}
        </div>
    );
};

export default inject('store')(observer(MyLimits));