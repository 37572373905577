import React from 'react';
import {inject, observer} from 'mobx-react';

import LivePageViewPicker from '../../components/common/view-picker';
import {
    SportsRightPanelTabs,
    MiniBanners,
} from './components';
import {LLScrollBox} from "../../../../components";
import {trans} from '../../../../common/utils';
import {VAIXWidget} from "../../components";


const PrematchRightPanel = ({store, isLive}) => {
    const viewSize = store.site.status.viewSize,
        isLogged = store.user.isLogged;

    return viewSize === 'desktop' ? (
        <div className="js_coupons-section coupons__section">
            {isLive && <LivePageViewPicker/>}
            {isLogged ?
                <SportsRightPanelTabs/>
                :
                <div className="coupon-tabs">
                    <LLScrollBox id="prematch-coupons" customClasses="coupons__scrollbox">
                        <div className="coupons--desktop">
                            <div className="prematch__coupons--empty">
                                {trans('Choose to bet')}
                            </div>
                        </div>
                        <div className="prematch-widgets">
                            <VAIXWidget />
                            <MiniBanners/>
                        </div>
                    </LLScrollBox>
                </div>
            }
        </div>
    ) : null;
};

export default inject('store')(observer(PrematchRightPanel));