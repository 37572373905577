import { values } from 'mobx';
import { flow, getParent, types } from 'mobx-state-tree';
import { orderBy } from 'lodash';

import { formatDate, llattempt, trans } from '../../../../common/utils';
import {llNotifier} from "../../../../components";
import api from '../../../../common/api';


const WithdrawStatusItem = types.model('CasinoReportItem', {
    provider: types.string,
    amount: types.number,
    dateTime: types.Date,
    status: types.string,
    note: types.maybeNull(types.string),
});

const WithdrawStatus = types
    .model('WithdrawStatus', {
        withdrawReports: types.array(WithdrawStatusItem),
    })
    .actions((self) => ({
        _set(data) {
            self.withdrawReports = [];
            if (data?.length) {
                orderBy(data, ['idatetime'], ['desc']).map((report) =>
                    self.withdrawReports.push(
                        WithdrawStatusItem.create({
                            provider: report.Provider,
                            amount: report.Amount,
                            dateTime: new Date(report.idatetime.replace(' ', 'T') + '+03:00'),
                            status: trans(report.Status),
                            note: report.Note,
                        })
                    )
                );
            }
            self.fetchTime = Date.now();
            self.isTableLoading = false;
        },
        getWithdrawStatus: flow(function* fetch() {
            if ( self.checkFetchTime() ) {
                self.isTableLoading = true;
                const data = {
                    id: getParent(self).customerId,
                    start: formatDate(self.startDate, 'YYYYMMDD'),
                    end: formatDate(self.endDate, 'YYYYMMDD'),
                };

                yield llattempt(() =>
                    api.user.getWithdrawStatus(data).then((response) => {
                        if (!response?.success) {
                            self.resetFetchTimeAndLoading();
                            // Thing below needed to avoid double error notifier on logout
                            if (response.data?.error === 'AUTH_REQUIRED') {
                                return true;
                            } else {
                                llNotifier({
                                    message: response.data?.error ?? 'GENERAL_ERROR',
                                    type: 'error',
                                });
                            }
                        }

                        self._set(response.data);
                    }), {
                        msg: 'GENERAL_ERROR',
                        at: 'user.getWithdrawStatus',
                        withParams: data,
                        onError: () => {
                            self.resetFetchTimeAndLoading();
                        }}
                    );
            }
        }),
    }))
    .views((self) => ({
        get reports() {
            return values(self.withdrawReports);
        },
    }));

export default WithdrawStatus;
