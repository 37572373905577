import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import {CSSTransition} from "react-transition-group";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import {LLBurgerIcon, BetradarWidgets} from '../../../../components';
import {trans} from '../../../../common/utils';
import markets from './crutch/market-name';
import LiveWidgetsNav from './live-widget-nav';
import LiveStream from './streaming';


const LiveWidgets = ({
                         match,
                         isSticky = false,
                         viewSize,
                         isLogged = false,
                         children,
                         setLmtError,
                         lmtError,
                     }) => {
    const
        [type, setType] = useState('match.lmtPlus'),
        [mobileNavShowed, setMobileNavShowed] = useState(false),
        [mobileNavInfoShowed, setMobileNavInfoShowed] = useState(false);

    const handleClick = (event) => {
        setType(event.currentTarget.dataset.type);
    };

    useEffect(() => {
        setType('match.lmtPlus');
        setLmtError(false);
    }, [match]);

    return match ? (
        <>
            <Fade
                in={isSticky && match.lmt && !lmtError && type === 'match.lmtPlus'}
                timeout={500}
            >
                <div className="live-sticky-widget">
                    <BetradarWidgets
                        type={'match.lmtSticky'}
                        sportId={match.sportId}
                        matchId={match.id}
                    />
                </div>
            </Fade>
            <div className="live-widgets">
                <div className="live-widgets__nav-container">
                    {children}
                    {markets[match.sportId] || (match.isStreaming && isLogged) ? (
                        <>
                            <LiveWidgetsNav
                                type={type}
                                lmtOnly={!markets[match.sportId]}
                                withStream={match.isStreaming && isLogged}
                                onClick={handleClick}
                                customClasses={
                                    mobileNavShowed ? 'live-widgets__nav--shoved' : ''
                                }
                                setMobileNavInfoShowed={setMobileNavInfoShowed}
                            />
                            {viewSize === 'mobile' && (
                                <>
                                    <div
                                        className="live-widgets__nav-info"
                                        onClick={() => {
                                            setMobileNavInfoShowed(v => !v)
                                        }}
                                    >
                                        <span>{trans('LMT')}</span>
                                        <i className={`doruk-${mobileNavInfoShowed ? 'close' : 'about-us-01'} live-widgets__nav-info-icon`}/>
                                    </div>
                                    <LLBurgerIcon
                                        open={mobileNavShowed}
                                        onClick={() => setMobileNavShowed(v => !v)}
                                        customClasses="live-widgets__nav-burger"
                                    />
                                </>
                            )}
                        </>
                    ) : null}
                </div>
                <CSSTransition timeout={200} in={mobileNavInfoShowed} unmountOnExit>
                    <ClickAwayListener
                        onClickAway={(e) => {
                            setMobileNavInfoShowed(false);
                        }}
                        touchEvent={'onTouchStart'}
                    >
                    <div className="live-widgets__nav-info-text">
                        <p>{trans('LMT_INFO_TEXT')}</p>
                    </div>
                    </ClickAwayListener>
                </CSSTransition>
                {(type === 'streaming') ? (
                    <LiveStream/>
                ) : (
                    <BetradarWidgets
                        type={type}
                        sportId={match.sportId}
                        matchId={match.id}
                        market={markets[match.sportId]}
                        setLmtError={setLmtError}
                    />
                )}
            </div>
        </>
    ) : null;
};

LiveWidgets.defaultProps = {
    matchId: null,
    sportId: null,
    error: true,
    onError: null,
};

LiveWidgets.propTypes = {
    matchId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sportId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    error: PropTypes.bool,
    onError: PropTypes.func,
};

export default memo(LiveWidgets);
