import React from 'react';
import PropTypes from 'prop-types';
import Fade from "@mui/material/Fade";

import {LLBurgerIcon, LLProgressBar, withCommon} from '../../../../../components';
import {AccountReportItem} from './';

/**
 * @file Represents React Component to render Account Report boxes
 * @memberOf AccountHistory
 *
 * @param {object} config - modified with data from store site config
 * @param {number} casinoReportsSum - sum of values for casino reports
 * @param {boolean} reportLoading - show loading if we are waiting for account report request
 * @param {array} accordionExpanded - array of expanded panels for mobile reports item
 * @param {function} reportsExpandHandler - function to manage expand state of mobile reports panels
 * @param {boolean} mobileUserAgent -  flag is mobile layout
 * @param {('mobile'|'tablet'|'desktop')} viewSize - modifier for css classes
 *
 * @author A.Huskova
 */

const AccountReport = withCommon({
    store: ({store}) => ({
        config: store.modifiedConfig,
        casinoReportsSum: store.user.accountReport.casinoReportsSum,
        activeReport: store.user.accountReport.activeReport,
        accountReport: store.user.accountReport,
        mobileUserAgent: store.user.mobileUserAgent,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        config: PropTypes.shape({
            accountReport: PropTypes.objectOf(
                PropTypes.shape({
                    desktopTitle: PropTypes.string,
                    mobileTitle: PropTypes.string,
                    isLabelCapitalized: PropTypes.bool,
                    items: PropTypes.arrayOf(
                        PropTypes.shape({
                            label: PropTypes.string,
                            value: PropTypes.number.isRequired,
                        })
                    ),
                })
            ).isRequired,
        }).isRequired,
        casinoReportsSum: PropTypes.number,
        accordionExpanded: PropTypes.arrayOf(PropTypes.string),
    },
    defaultName: 'AccountReport',
    isObserver: true,
    isTrans: true,
})(({
    config,
    casinoReportsSum = 0,
    activeReport,
    accountReport,
    reportLoading,
    viewSize,
    trans,
}) => {
    const tempArr = [];

    return <>
        <div className={`account-report--${viewSize}${activeReport ? ' opened' : ''}`}>
            {Object.keys(config.accountReport).map((key) => {
                /**** ~~ Create menu items from config and store data for casino report ****/
                const itemProps = {
                    ...config.accountReport[key],
                    key,
                    itemId: key,
                    reportLoading,
                    items:
                        key === 'casino'
                            ? viewSize === 'desktop'
                                ? [
                                    {label: 'Net', value: casinoReportsSum},
                                    ...config.accountReport[key].items,
                                ]
                                : [
                                    ...config.accountReport[key].items,
                                    {label: 'Net', value: casinoReportsSum},
                                ]
                            : config.accountReport[key].items,
                };
                tempArr.push(itemProps);

                return viewSize === 'mobile' ? (
                    <div className={`account-report-item${activeReport=== key ? ' account-report-item--active' : ''}`}
                         onClick={() => accountReport.setActiveReport(key)}
                         key={key}
                    >
                        <p>{trans(itemProps.mobileTitle)}</p>
                        <LLBurgerIcon open={activeReport === key} />
                    </div>
                ) : (
                    <AccountReportItem {...itemProps} />
                );
            })}
            { reportLoading && viewSize === 'mobile' &&
                <LLProgressBar customClasses="account-report__progress-bar" />
            }
        </div>
        { (activeReport && viewSize === 'mobile') &&
            <Fade
                timeout={500}
                in={!!activeReport}
            >
                <div className="my-account-info-list">
                    <AccountReportItem {...tempArr.filter(item => item.key === activeReport)[0]} />
                </div>
            </Fade>
        }
    </>
});

export default AccountReport;