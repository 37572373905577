import { types as t } from 'mobx-state-tree';
import xor from 'lodash/xor';

const ActiveItems = t
    .model('ActiveItems', {
        branch: t.maybeNull(t.string),
        sports: t.array(t.string),
        categories: t.array(t.string),
        tournaments: t.array(t.string),
        matches: t.array(t.string),
        marketGroups: t.array(t.string),
        customMarketGroups: t.array(t.number),
    })
    .actions((s) => ({
        setActiveItem(ids, type) {
            if (type === 'matches') {
                s.marketGroups = [];
            }
            s[type] = ids;
        },
        parseUrl({ branchId, sportId, categoryId, tournamentId, matchId }) {
            s.branch = branchId;
            if (!matchId) {
                s.matches = [];
            } else {
                s.matches = [matchId];
            }
            if (!tournamentId) {
                s.tournaments = [];
            } else {
                s.tournaments = [tournamentId];
            }
            if (!categoryId) {
                s.categories = [];
            } else {
                s.categories = [
                    ...categoryId.split('-').reduce((a, id) => {
                        return id ? [...a, id] : a;
                    }, []),
                ];
            }
            if (!sportId) {
                s.sports = [];
            } else {
                s.sports = [
                    ...sportId.split('-').reduce((a, id) => {
                        return id ? [...a, id] : a;
                    }, []),
                ];
            }
        },
        toggleActiveMenuItem({ id, type }) {
            s[type] = xor(s[type], [id.toString()]);
        },
        setAllActiveItems({ sports, categories, tournaments, matches, marketGroups }) {
            s.sports = sports ?? [];
            s.categories = categories ?? [];
            s.tournaments = tournaments ?? [];
            s.matches = matches ?? [];
            s.marketGroups = marketGroups ?? [];
        },
    }))
    .views((s) => ({
        isActive({ id, type }) {
            return s[type].includes(id.toString());
        },
        get firstTournament() {
            return s.tournaments.length ? s.tournaments[0] : null;
        },
        get firstActiveSport() {
            return s.sports.length ? s.sports[0] : null;
        },
    }));

export default ActiveItems;
