import { types as t } from 'mobx-state-tree';

const BettingErrors = t.model('BettingErrors', {
    global: t.maybeNull(t.string),
    list: t.maybeNull(t.string),
    detail: t.maybeNull(t.string),
    search: t.maybeNull(t.string),
    title: t.maybeNull(t.string),
});

export default BettingErrors;
