import React from 'react';

import JackpotGroupDetailItem from "./jackpot-group-detail-item";
import {Link} from "react-router-dom";


export const JackpotGroupCard = ({group, index = 0}) => (group ?
        <div className="jackpot-group__card"
             style={{
                 backgroundImage: group.hasBackground ? `url(${group.background})`: '',
                 gridRow: `${(index*2 + 1)} / ${(index*2 + 3)}`
            }}
        >
            <Link to={`/slots/jackpots/${group.id}`}
                  className="jackpot-group__card-logo"
            >
                {group.bigImgLink ?
                    <img
                        className="jackpot-group__card-logo-image"
                        src={group.bigImgLink}
                        alt="slots-game"
                    />
                    :
                    <div className=" game__provider-without-img">
                        {group.name}
                    </div>
                }
            </Link>
            <div className="jackpot-group__info-box">
                <JackpotGroupDetailItem group={group} type="grand"/>
                <JackpotGroupDetailItem group={group} type="major"/>
                <div className="jackpot-group__small-jackpots">
                    <div className="jackpot-group__small-jackpots-item">
                        <JackpotGroupDetailItem group={group} type="minor"/>
                    </div>
                    <div className="jackpot-group__small-jackpots-item">
                        <JackpotGroupDetailItem group={group} type="mini"/>
                    </div>
                </div>
            </div>
        </div>
        : null
);