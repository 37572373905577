import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {
    CouponsPanelMobile,
    LLMenuItem,
    LLTextSlider,
    withCommon
} from '../../../components';
import config from '../../../static/themes/config';
import {formatNumber} from '../../../common/utils';
import getFeaturedBtnConfig from "../featured/getFeaturedBtnConfig";
import FooterFeaturedLinks from "../featured/featured-links";
import {NavLink} from "react-router-dom";

/**
 * Represents a react component, Footer on mobile devices.
 *
 * @param {boolean} isLogged - flag is user logged
 * @param {string} platform - viewSize
 * @param {boolean} withFooterLinks
 *
 * @author A.Green and S.Nakhodov
 */

const footerMobileResponsiveConfig = (platform, isLogged, withFooterLinks) => {
    if (!withFooterLinks) {
        return {
            slidesToShow: 3,
            responsive: [
                {
                    minSize: 520,
                    slidesToShow: platform === 'mobile' && isLogged ? 6 : 5,
                },
                {
                    minSize: 440,
                    slidesToShow: 5,
                },
                {
                    minSize: 370,
                    slidesToShow: isLogged ? 3 : 4,
                },
            ],
        };
    }
    return {
        slidesToShow: 2,
        responsive: [
            {
                minSize: 520,
                slidesToShow: platform === 'mobile' && isLogged ? 5 : 4,
            },
            {
                minSize: 440,
                slidesToShow: 4,
            },
            {
                minSize: 370,
                slidesToShow: isLogged ? 2 : 3,
            },
        ],
    }
}

const LLFooterMobile = withCommon({
    store: ({store}) => ({
        availableMoney: store.user.availableMoney,
        siteName: store.site.siteName,
        viewSize: store.site.status.viewSize,
        user: store.user,
        currency: store.user.currency,
        isLogged: store.user.isLogged,
        isBlocked: store.user.isBlocked,
        language: store.user.language,
        rewardPoints: store.user.rewardPoints,
        hasRewardSystem: store.user.hasRewardSystem,
        hidePopover: store.popover.hidePopover,
        setPopover: store.popover.setPopover,
        popoverClasses: store.popover.popoverClasses,
        popoverIsActive: store.popover.isActive,
        open: store.drawer.open,
        sportMountStatus: store.betting.sportMountStatus,
        footerLinks: store.site.footerBtns.items,
        siteStatus: store.site.status,
    }),
    PropTypes: {
        availableMoney: PropTypes.bool.isRequired,
        currency: PropTypes.string.isRequired,
        isLogged: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        open: PropTypes.func.isRequired,
    },
    defaultName: 'LLFooterMobile',
    isTrans: true,
})(({
        availableMoney,
        siteName,
        currency,
        isLogged,
        language,
        user,
        rewardPoints,
        hasRewardSystem,
        open,
        trans,
        isBlocked,
        sportMountStatus,
        viewSize,
        footerLinks,
        siteStatus,
        hidePopover,
        setPopover,
        popoverIsActive,
        popoverClasses
    }) => {
    const [activeMenu, setActiveMenu] = useState(null);

    const featured = getFeaturedBtnConfig({footerLinks, user});

    const footerItem = ({item, customClasses}) => (item.label === 'Money' && hasRewardSystem) ? (
        <NavLink
            key={item.label}
            to={item?.elementAttributes?.to}
            className={`menu-item--mobile ll-footer__item${
                item.accent ? 
                    ' ll-footer__item--accent' 
                    : ''
            } ${customClasses ?? ''} ll-footer__money-item`}
        >
            <div className="ll-footer__money-item-part">
                <i className={`${item.icon} menu-item__icon`}/>
                {formatNumber(
                    availableMoney,
                    2,
                    language,
                    currency
                )}
            </div>
            <div className="ll-footer__money-item-part">
                <i className={`doruk-bonuses-01 menu-item__icon`}/>
                {rewardPoints}
            </div>
        </NavLink>
        )
        : (
        <LLMenuItem
            item={item}
            id={item.label}
            key={item.label}
            customClasses={`ll-footer__item${item.accent ? ' ll-footer__item--accent' : ''} ${customClasses ?? ''}`}
            onClick={(e) => {
                setActiveMenu(item.label);
                if (item.featuredLinks?.length) {
                    siteStatus.setBackdrop({
                        backdropOnClick: hidePopover,
                        transparent: true,
                    });
                    setPopover({
                        anchorEl: e.currentTarget,
                        isActive: true,
                        popoverContent:
                            <FooterFeaturedLinks
                                links={item.featuredLinks}
                                hidePopover={hidePopover}
                                user={user}
                            />,
                        popoverClasses: 'll-footer__popover',
                        placement: 'top',
                        stayShovedByHover: true,
                    });
                }
                if (typeof item.onClick === 'function') {
                    item.onClick(e);
                }
                if (item.drawer) {
                    open({
                        side: item.drawer.side,
                        currentKey: item.drawer.key,
                    });
                }
            }}
            icon={item.icon ? <i className={`${item.icon} menu-item__icon`}/> : null}
            backgroundThumbnail={item.thumbnail}
            label={
                <>
                    {item.label === 'Money' ?
                        formatNumber(
                            availableMoney,
                            2,
                            language,
                            currency
                        )
                        : trans(item.label)
                    }
                </>
            }
        />
    );

    const menuItems = config(siteName).footerMobileMenu.filter(item => (
        (item.isLogged === isLogged || item.isLogged === undefined) && (!item.hideForBlocked || (item.hideForBlocked !== isBlocked))
    ));

    return (
        <>
            {sportMountStatus && <CouponsPanelMobile/>}
            <div className={"ll-footer--mobile"}>
                {footerLinks.length ? footerItem({
                    item: featured,
                    customClasses: `ll-footer__item--featured ${
                        (popoverIsActive && popoverClasses.includes('ll-footer__popover')) ? 'll-footer__item--active' : ''
                    }`,
                }) : null}
                <LLTextSlider
                    key={isLogged}
                    id="ll-footer-menu--mobile"
                    {...(footerMobileResponsiveConfig(viewSize, isLogged, footerLinks.length))}
                    customClasses={{slider: 'll-footer__slider--mobile'}}
                    activeItem={activeMenu}
                    viewSize={viewSize}
                >
                    {menuItems.map((item) =>
                        footerItem({item})
                    )}
                </LLTextSlider>
            </div>
        </>
    )
});

export default LLFooterMobile;
