import React, {Fragment} from "react";
import cx from "classnames";

import {llNotifier} from '../../../../components';


const FilterMenuList = ({id, setCurrentItem, openedItem, setOpenedItem, range, tab}) => {
    const filteredData = range?.getFilteredRangeInstances({instanceType: tab, id});

    if (range?.error) {
        return <div className={'filters-panel__error'}>{range.error}</div>;
    }

    return filteredData?.map((item) => {
        if (item.id) {
            const isSelected = item.isSelected;

            return (
                <Fragment key={item.id}>
                    <div
                        className={cx(
                            "filters-panel-menu__item",
                            id && 'filters-panel-menu__item--sub',
                            openedItem === item.id && 'filters-panel-menu__item--opened',
                            isSelected && "selected",
                            tab === 'tournaments' && "filters-panel-menu__item-tournament"
                        )}
                        onClick={() => {
                            if (tab !== 'tournaments') {
                                if (!item.isParent) {
                                    setCurrentItem(item.id);
                                    !id && setOpenedItem(null);
                                    range?.setActiveItem({
                                        instanceType: tab,
                                        id: item.id,
                                        name: item.name
                                    });
                                } else {
                                    setCurrentItem(null);
                                    range?.setActiveItem({
                                        instanceType: tab,
                                        isParent: true,
                                    });
                                    setOpenedItem((state) => state === item.id ? null : item.id);
                                }
                            }
                        }}
                    >
                        <div className="filters-panel-menu__item-content">
                            { tab === 'sports' &&
                                <i className={'filters-panel-menu__item-icon sport_icon_font-' + item.id}/>
                            }
                            <span className="filters-panel-menu__item-name">
                                {item.name}
                            </span>
                            <span className={cx(
                                "filters-panel-menu__item-count",
                            )}>
                                {item.matchCount}
                            </span>
                        </div>
                        <div
                            className="filters-panel__add-icon-wrp"
                            onClick={(e) => {
                                if (!item.isParent) {
                                    e.stopPropagation();
                                    const res = range?.toggleFilter({
                                        instanceType: tab,
                                        id: item.id,
                                        name: item.name
                                    });
                                    if (res?.error) {
                                        llNotifier({
                                            message: res.error,
                                            type: 'warning',
                                            options: {},
                                        });
                                    }
                                }
                            }}
                        >
                            {!item.isParent ?
                                <i className={cx(
                                    'filters-panel__add-icon',
                                    isSelected ? 'doruk-check' : 'doruk-plus',
                                )} />
                                :
                                <i className={cx(
                                    'filters-panel-menu__item-dropdown-arrow',
                                    'doruk-mg-arrow-2',
                                    openedItem === item.id && 'opened'
                                )} />
                            }
                        </div>
                    </div>
                    {openedItem === item.id &&
                        <FilterMenuList
                            id={item.id}
                            setCurrentItem={setCurrentItem}
                            setOpenedItem={setOpenedItem}
                            openedItem={openedItem}
                            range={range}
                            tab={tab}
                        />
                    }
                </Fragment>
            );
        }
    });
};

export default FilterMenuList;