import { languages } from '../../configs';

import { setCookie, getCookie } from '../utils/cookie';
import isDevMode from '../utils/is-devmode';

const trans = (val, lang) => {
    if (val) {
        const activeLanguage = lang || document.documentElement.lang || 'tr';
        let trans = val;
        if (languages.i18n[activeLanguage] !== undefined) {
            if (languages.i18n[activeLanguage][val] !== undefined) {
                trans = languages.i18n[activeLanguage][val];
            } else if (isDevMode()) {
                //collect empty translations:
                let q = [];
                if (getCookie('EMPTY_TRANS')) {
                    try {
                        q = JSON.parse(getCookie('EMPTY_TRANS'));
                    } catch (e) {
                        console.log('IOS is stupid!!!');
                    }
                }
                if (!q.includes(val)) {
                    q.push(val);
                }
                setCookie('EMPTY_TRANS', JSON.stringify(q));
            }
        }
        return trans;
    } else return null;
};

export default trans;
