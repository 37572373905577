export const tr = {
    /* CASINO / SLOTS */
    FUN_PLAY_BTN: 'Eğlencesine Oyna',
    REAL_PLAY_BTN: 'Para ile Oyna',

    /* PROVIDERS (ist from prod )*/
    'KALAMBA' : 'KALAMBA',
    'SPINOMENAL' : 'SPINOMENAL',
    'YGGDRASIL' : 'YGGDRASIL',
    'WAZDAN' : 'WAZDAN',
    'BTG' : 'BIGTIMEGAMING',
    'BOOONGO' : 'BOOONGO',
    'REDTIGER' : 'REDTIGER',
    'BLUEPRINT' : 'BLUEPRINT',
    'TRUELAB' : 'TRUELAB',
    'PUSH' : 'PUSH GAMING',
    'QUICKSPIN' : 'QUICKSPIN',
    'SOFTSWISS' : 'BGAMING',
    'BOOMING' : 'BOOMING GAMES',
    'TKICK' : 'THUNDERKICK',
    'NOLCITY' : 'NOLIMITCITY',
    'REDRAKE' : 'REDRAKE',
    'PRAGMPLAY' : 'PRAGMATIC PLAY',
    'SPRIBE' : 'SPRIBE',
    'ENDORPHINA' : 'ENDORPHINA',
    'MRSLOTTY' : 'MR SLOTTY',
    'AMATIC' : 'AMATIC',
    'BETSOFT' : 'BETSOFT',
    'EGT' : 'EGT',
    'HABANERO' : 'HABANERO',
    'ISOFTBET' : 'ISOFTBET',
    'NETENT' : 'NETENT',
    'PARIPLAY' : 'PARIPLAY',
    'PGSOFT' : 'PGSOFT',
    'PLAYSON' : 'PLAYSON',
    'RGAMING' : 'RELAX GAMING',
    'WORLDMATCH' : 'WORLDMATCH',
    '1X2LEAP' : '1X2',
    'HACKSAW' : 'HACKSAW',
    'PRAGMLIVE' : 'PRAGMATIC',
    '1LIVE' : '1LIVE',
    'EZUGI' : 'EZUGI',
    'EVOLUTION' : 'EVOLUTION',
    'VIVO' : 'VIVO GAMING',
    'ATMOSFERA' : 'ATMOSFERA',
    'LSTREAK' : 'LSTREAK',

    /* SAVE COUPONS ERROR CODES */
    //ususal
    SAVE_ERROR1: 'Kupon tutarı sıfır ya da sıfırdan küçük olamaz!',
    SAVE_ERROR11:
        'Günlük karşılaşma limitiniz dolmuştur. Limitiniz her gün 00:00 da yenilenmektedir!',
    SAVE_ERROR15: 'Aşağıdaki karşılaşmalar kapanmış ya da başlamış: #1',
    SAVE_ERROR17: 'Aşağıdaki karşılaşmalar kapanmış ya da durdurulmuş: #1',
    'SAVE_ERROR2': 'En az bir sistem seçmeniz gerekiyor!',
    'SAVE_ERROR3': 'Yöneticilerin kupon yapma yetkisi yoktur!',
    'SAVE_ERROR4': 'Kuponunuzda en az #1 karşılaşma olmalı!',
    'SAVE_ERROR5': 'Kombine kupon oynama yetkiniz bulunmamaktadır!',
    'SAVE_ERROR6': 'Maç önü bahis için yetkiniz bulunmamaktadır!',
    'SAVE_ERROR7': 'Uzun vadeli bahis yetkiniz bulunmamaktadır!',
    'SAVE_ERROR8': 'Canlı bahis oynama yetkiniz bulunmamaktadır!',
    'SAVE_ERROR9': 'Minimum kupon tutarı #1 olmalıdır!',
    'SAVE_ERROR10': 'Maksimum kupon tutarı #1 olmalıdır!',
    'SAVE_ERROR12': 'Yetersiz bakiye!',
    'SAVE_ERROR13': 'Bu kupon için maksimum yatırma limitiniz #1',
    'SAVE_ERROR14': 'Aşağıdaki karşılaşmalar için bahis limitiniz: #1 ',
    'SAVE_ERROR14_1': 'Kuponunuzdaki bir veya birden fazla seçim için bahis tipi limitiniz yetersizdir',
    'SAVE_ERROR14_2': '{E#1} için kalan limitiniz {E#2}',
    'SAVE_ERROR16': 'Aşağıdaki karşılaşmanın oranı değişmiş: #1 ',
    'SAVE_ERROR18': 'Kupon kaydı sırasında bir hata oluştu, lütfen tekrar deneyin!',
    'SAVE_ERROR19': 'Kuponunuzda bulunan karşılaşma(lar) kapandı veya durdu!',
    'SAVE_ERROR20': 'Lütfen kuponu yenileyin!',
    'SAVE_ERROR22': 'Kuponunuzda bulunan karşılaşma(lar) kapatıldı veya durduruldu: #1',
    'SAVE_ERROR23': 'Aşağıdaki karşılaşma(lar) başka karşılaşmalarla kombine edilemez: #1',
    'SAVE_ERROR24': 'Bu seçim(ler) yalnızca aynı turnuva altındaki diğer karşılaşmalar ile kombine edilebilir: #1',
    //mts
    MTS_PROXY_ERROR: 'LTS bağlantı hatası. Lütfen destek ile iletişime geçiniz!',
    MTS_DELETE_ERROR: 'Kupon silme hatası!',
    MTS_SAVE_ERROR1: 'Kuponunuzda bulunan karşılaşma(lar) kapandı veya durdu: #1 ',
    MTS_SAVE_ERROR2:
        'Kuponunuzda bulunan karşılaşmalara ait seçenekler kapandı veya durdu: #1 ',
    MTS_SAVE_ERROR3: 'Aşağıdaki oranlar güncellendi: #1 ',
    MTS_SAVE_ERROR4:
        'Kuponunuzda bir veya birden fazla ilişkili seçenek bulunmakta, lütfen kontrol edin ve yeniden deneyin!',
    MTS_SAVE_ERROR:
        'Kuponunuz kabul edilmemiştir. Lütfen canlı destek ile iletişime geçiniz ya da kısa bir süre sonra tekrar deneyiniz. Kod: #1',
    //custom
    ODDS_ERROR: 'Bu seçenek şu anda aktif değildir',
    PRE_CLOSED: 'Karşılaşma tamamlandı veya seçenek kaldırıldı!',
    LIVE_CLOSED: 'Karşılaşma tamamlandı veya seçenek kaldırıldı!',
    OUTRIGHT_CLOSED: 'Etkinlik tamamlandı veya seçenek kaldırıldı!',
    MAX_EVENTS_REACHED: 'Kupon başı maksimum karşılaşma sayısına ulaşıldı!',
    UNAVALABLE_FOR_COMBINE:
        'Eklemek istediğiniz karşılaşma, mevcut kupona dahil edilmeye uygun değildir!',
    UNAVALABLE_FOR_COMBINE2:
        'Uzun vadeli bahisler, diğer bahis tipleriyle kombine edilemez!',
    UNAVALABLE_FOR_COMBINE3: 'Bahis Sihirbazı tercihi başka karşılaşmalarla kombine edilemez!',
    EVENT_FINISHED: 'Karşılaşma tamamlandı veya seçenek kaldırıldı!',

    EVENT_NOT_FOUND: 'ETKİNLİK BULUNAMADI',
    EVENT_STARTED: 'Karşılaşma başladı veya kapatıldı!',

    // betbuilder
    BET_BUILDER_UNAVAILABLE_MARKET: 'Market Mevcut Değil!',
    BET_BUILDER_NO_DATA_FOR_EVENT: 'Bu karşılaşma için veri bulunamadı',
    'Request body missing or malformed': 'Bilinmeyen bir hata oluştu',
    'This combination is not possible, contradictory selections': 'Seçenek kombine edilemez. Birbiriyle çelişen bahisler!',

    /* DELETE COUPONS ERROR CODES */
    COUPONDELETE_ALREADY: 'Kupon zaten silinmiş!',
    COUPON_ALREADY_CLOSED: 'Kupon iptal talebiniz reddedildi',
    COUPON_ALREADY_DELETED: 'Kupon zaten silinmiş!',
    COUPONDELETE_PASSED: 'Kupon iptal süresi aşıldı. Kuponu silemezsiniz!',
    COUPONDELETE_LIVE: 'Canlı karşılaşma içeren kuponlar iptal edilemez!',
    COUPONDELETE_STARTED: 'Aşağıdaki karşılaşmalar başladı, kupon iptal edilemez: #1 ',

    /* FORMS ERRORS */
    WRONG_TR_ID: 'Hatalı veya eksik kimlik numarası',
    NAME_AND_SURNAME_ALREADY_REGISTERED: 'Bu bilgilerle kayıtlı bir hesap bulunmaktadır. Hesabın size ait olmadığını düşünüyorsanız lütfen canlı destek ile iletişime geçiniz.',
    PARAM_NOT_SET:
        'Bilinmeyen bir hata oluştu. Lütfen Canlı Yardım ile iletişime geçiniz',
    NAME_EMPTY: 'Adınızı giriniz',
    SURNAME_EMPTY: 'Soyadınızı giriniz',
    USERNAME_EMPTY: 'Kullanıcı Adı giriniz',
    EMAIL_EMPTY: 'Eposta giriniz',
    REPEAT_EMAIL_EMPTY: 'E-posta tekrarını yanlış girdiniz',
    EMAIL_NOT_MATCH: 'E-posta eşleşmiyor.',
    PASSWORD_EMPTY: 'Şifrenizi giriniz',
    REPEAT_PASSWORD_EMPTY: 'Şifreler eşleşmiyor',
    PASSWORD_SAME_AS_OLDPASSWORD: 'Yeni şifreniz mevcut şifreniz ile aynı olamaz',
    PASSWORD_NOT_MATCH: 'Şifreler eşleşmiyor.',
    SEX_EMPTY: 'Cinsiyet seçiniz',
    ID_EMPTY: 'Eksik kimlik numarası',
    'At least 8 characters' : 'Şifreniz en az 8 en fazla karakter',
    WRONG_DATE_FORMAT: 'Hatalı tarih',
    COUNTRY_EMPTY: 'Ülke Seçiniz',
    PHONE_EMPTY: 'Telefon numaranızı girin',
    PHONE_TOO_SHORT: 'Telefon numarası kısa',
    PHONE_WRONG_FORMAT: 'Hatalı telefon numarası',
    PHONE_ALREADY_EXISTS: 'Telefon numarası kullanılmaktadır',
    USERNAME_TOO_SHORT: 'Kullanıcı Adınız en az 6 karakterden oluşmalıdır',
    USERNAME_WRONG_FORMAT: 'Kullanıcı adı sadece harf, sayılardan oluşabilir.',
    WRONG_EMAIL: 'Yanlış e-posta adresi biçimi',
    USERNAME_ALREADY_EXISTS: 'Bu Kullanıcı Adı daha önceden alınmıştır',
    EMAIL_ALREADY_EXISTS: 'Bu E-posta adresi kullanılmaktadır',
    CURRENCY_EMPTY: 'Para Birimi Seçiniz',
    ID_NUMBER_WRONG_FORMAT:
        'Hatalı kimlik bilgileri: Kimlik Numarası, ad, soyad ve doğum tarihi bilgilerini tam olarak kimliğinizde yazdığı gibi giriniz.',
    ID_NUMBER_ALREADY_EXISTS: 'Bu kimlik numarası ile üyelik bulunmaktadır',
    PASSPORT_NUMBER_WRONG_FORMAT:
        'Hatalı pasaport numarası. Eğer pasaport numaranız doğru ve bu hatayı alıyorsanız lütfen canlı yardıma ulaşınız.',
    PASSPORT_NUMBER_EXISTS: 'Bu Pasaport numarası kullanılmaktadır',
    USERNAME_TOO_LONG: 'Kullanıcı adı çok uzun',
    NAME_WRONG_FORMAT: 'Ad/Soyad kısmında izin verilmeyen karakter bulunmaktadır',
    SURNAME_WRONG_FORMAT: 'Ad/Soyad kısmında izin verilmeyen karakter bulunmaktadır',
    CAPTCHA_ERROR: 'Güvenlik kodunu giriniz',
    PASSWORD_WRONG_FORMAT: 'Şifreniz çok kısa veya çok güçlü değil.',
    WRONG_GOOGLE_CODE: 'Yanlış kod',
    WRONG_CODE: 'Yanlış kod',
    WRONG_REPEATED: 'Şifreleriniz eslesmiyor',
    WRONG_OLD_PASSWORD: 'Hatali Eski Şifre',
    NEW_PASSWORD_EQUALS_OLD: 'Yeni şifre eski şifreden farklı olmalıdır',
    WRONG_CAPTCHA: 'Captcha doğrulamasını gerçekleştiriniz.',
    EMAIL_NOT_FOUND: 'E-Posta bulunamadi',
    WRONG_AUTH_DATA: 'Kullanıcı adı ya da şifre hatalı!',
    EMAIL_NOT_VERIFIED: 'E-Posta adresiniz doğrulanmamış!',
    TOO_MANY_RETRIES:
        '3 kere hatalı şifre denemesi yaptığınızdan dolayı hesabınız 15 dakika boyunca bloke olmuştur. Eğer şifrenizi hatırlamıyorsanız lütfen Şifremi Unuttum linkini kullanarak yönlendirmeleri takip ediniz.',
    FORM_REGISTRATION_REJECTED:
        'Üyelik başvurunuz kabul edilmemiştir. İlginiz için teşekkür ederiz.',
    FRAUD_DETECTED:
        'Üyelik başvurunuz kabul edilmemiştir. İlginiz için teşekkür ederiz.',
    LOGIN_MAILVERIFY: 'Mail adresiniz doğrulanmamış!',
    AUTH_TIME_EXPIRED: 'Kimlik doğrulama süresi doldu',
    WRONG_SMS_CODE: 'Hatalı güvenlik kodu',
    PERMISSION_DENIED: 'Giriş yapılamadı. Bilgilerinizi kontrol ediniz',
    IP_IS_BLACKLISTED:
        'Hesabiniz bloke olmustur. Lutfen 15 dakika sonra tekrar deneyiniz.',

    /* BONUS ERROR CODES */
    BONUS_NOT_FOUND: 'Seçmiş olduğunuz bonus kullanımda değildir',
    BONUS_USED: 'Bu Bonus için daha önce başvuruda bulundunuz',

    /* OTHER ERRORS (PAGES ERRORS) */
    Error: 'Hata',
    TECHNICAL_ISSUES_ERROR:
        'İşlem sırasında hata oluştu lütfen daha sonra tekrar deneyiniz',
    CONNECTION_ISSUES_ERROR:
        'İşlem sırasında hata oluştu lütfen daha sonra tekrar deneyiniz',
    EMPTY_BETS_ERROR: 'Bahis yapabileceğiniz maç bulunmamaktadır.',
    EMPTY_LIVE_BETS_ERROR: 'Bahis yapabileceğiniz canlı maç bulunmamaktadır.',
    ERROR_404: 'Sayfa bulunamadı',
    ERROR_404_HEAD: '404',
    GENERAL_ERROR: 'İşlem sırasında hata oluştu lütfen daha sonra tekrar deneyiniz',
    MAINTENANCE_ERROR: 'Lütfen daha sonra tekrar deneyin.\n\n' +
        'Siz değerli oyuncularımıza daha iyi hizmet verebilmek için sitelerimizde planlı bakım çalışması yapılmaktadır.\n\n' +
        'Öngörülen bitiş saati 08:00 olarak belirlenmiştir.\n\n' +
        'Çalışmalar öngörülen saatten önce ya da sonra bitebilir.\n\n' +
        'Anlayışınız için teşekkür ederiz.',
    NOT_FOUND: 'İşlem sırasında hata oluştu lütfen daha sonra tekrar deneyiniz',
    NOT_FOUND_QRCODE: 'İşlem sırasında hata oluştu lütfen daha sonra tekrar deneyiniz',
    LIVE_CALENDAR_ERROR: 'Canlı takvim oyunları listesi alınırken hata oluştu',
    USER_NOT_FOUND: 'Kullanıcı adı ya da şifre hatalı!',
    WRONG_PASSWORD: 'Kullanıcı adı ya da şifre hatalı!',
    WRONG_PASSWORD_QRCODE: 'Kullanıcı adı ya da şifre hatalı!',
    SUSPENDED:
        'Hesabınız inceleme altındadır. Endişe etmenizi gerektiren bir durum bulunmamaktadır. İnceleme tamamlanır tamamlanmaz hesap statünüz yeniden aktif duruma gelecektir. Bu sürede dilerseniz canlı destek hattımıza bağlanarak bilgi alabilirsiniz. Anlayışınız için teşekkür ederiz.',
    LOGIN_NEEDED: 'Lütfen giriş yapınız',

    /**** BETTING ****/
    MENU_ALL: 'Tümü',
    MENU_OUTRIGHTS: 'Şampiyonluk Bahisleri',
    MENU_OUTRIGHTS_20040: 'Sizin Oranlarınız',
    MENU_OUTRIGHTS_25500: 'Dunya Kupasi Ozel',
    PRE_LEFTMENU_STATISTICS_BTN: 'İstatistikler',
    PRE_LEFTMENU_LIVESCORES_BTN: 'Canlı Skorlar',
    PRE_TOP: 'Popüler karşılaşmalar',
    PRE_FAVORITE: 'En iyi karşılaşmalar',
    PRE_TITLE_SPORTS: 'Spor',
    /* SEARCH */
    SPORTS_SEARCH_NO_DATA_TEXT: 'Arama kriterlerine uygun sonuç bulunamadı',
    SPORTS_SEARCH_TECHNICAL_ISSUES_ERROR:
        'İşlem sırasında hata oluştu lütfen daha sonra tekrar deneyiniz',
    MATCH_TV_MODAL_HEADER: 'TV Yayını',
    MATCH_STATISTICS_MODAL_HEADER: 'İstatistikler',
    CALENDAR_NO_DATA_TEXT: 'Sonuç bulunamadı',
    /*** --------   */

    /* LANGUAGE */
    LANG_EN: 'English',
    LANG_TR: 'Türkçe',
    LANG_AA: 'عربي',

    /* sites dependence translations */
    HOME_SLOTS_TITLE_CIPROBET: 'PRAGMATIC SLOT OYUNLARIMIZI DENEDİNİZ Mİ ?',
    HOME_SLOTS_SUBTITLE_CIPROBET:
        'Aztec Gems, Vegas Magic ve daha birçoğu kazandırmak için sizi bekliyor!',
    BRANCH_PICKER_TITLE_DESKTOP: 'SPOR DALLARI',
    BRANCH_PICKER_TITLE_MOBILE: 'Saat Seçimi',
    USER_DISABLED : 'Bu oyunu oynamak için yetkiniz bulunmamaktadır. Lütfen canlı destek ile iletişime geçiniz.',

    /* Rest of trans */
    '1x2': '1x2',
    '1 Hour': '1 Saat',
    '12 Hour': '12 Saat',
    '3 Hour': '3 Saat',
    '6 Hour': '6 Saat',
    'A - Z': 'A - Z',
    'About Us': 'Hakkımızda',
    'Account report': 'Hesap Raporu',
    'account-history': 'Hesap Dökümü',
    'Account info': 'HESAP BİLGİLERİ',
    'Account information': 'HESAP BİLGİLERİ',
    Account: 'Hesap',
    'Account suspended': 'Hesabınız inceleme altındadır',
    ACTIVE_BETS_NO_DATA_TEXT: 'Açık bahisiniz bulunmamaktadır',
    Address: 'Adres',
    Affiliate: 'Ortaklık',
    All: 'Tümü',
    'All Markets': 'Tüm Marketler',
    Amount: 'Tutar',
    'At least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters required':
        'Şifreniz en az 1 büyük harf, 1 küçük harf, 1 rakam ve minimum 8 karakterden oluşmalıdır.',
    Available: 'Kullanılabilir',
    Aviator: 'Avıator',
    'Away team': 'Deplasman',
    Back: 'Geri',
    Badminton: 'Badminton',
    Balance: 'Bakiye',
    Baseball: 'Beyzbol',
    Basketball: 'Sanal NBA',
    'Bet Games': 'Bet on games',
    'Bet on games': 'Bet on games',
    'BET_DETAILS_NO_DATA': 'You have no details for this coupon',
    'BET_SLIP_EMPTY': 'Seçim Yapınız',
    'Bet slip': 'Kupon',
    'Bet slips': 'Kuponlar',
    Bets: 'Kuponum',
    'betting-history': 'Bahis Geçmişi',
    Bettings: 'Bahis',
    Bonus: 'Bonus',
    'Bonus Talep': 'Bonus Talep',
    'Bonus points': 'Bonus Puanları',
    'Bonus Rules': 'Kurallar',
    'Bonus/discount': 'Bonus',
    CABINET_WITHDRAW_STATUS_NO_DATA_TEXT: 'Bekleyen çekim talebiniz bulunmamaktadır',
    CABINET_BET_HISTORY_NO_DATA_TEXT: `Bu tarih aralığında kuponunuz bulunmamaktadır`,
    CABINET_ACCOUNT_HISTORY_NO_DATA_TEXT: `Bu tarih aralığında kuponunuz bulunmamaktadır`,
    CABINET_CASINO_REPORT_NO_DATA_TEXT: `Bu tarih aralığında casino oyununuz bulunmamaktadır`,
    Calendar: 'Canlı Maç Takvimi',
    Cancel: 'İptal',
    'casino-report': 'Casino Raporu',
    Casino: 'Casino',
    Casinos: 'Casino',
    Category: 'Kategori',
    'Change password': 'Şifre Değiştir',
    'Choose to bet': 'Bahis yapmak için seçim yapın',
    City: 'Şehir',
    'Clear Bet': 'Kuponu Temizle',
    'Click on error to fix corresponding field': 'Hatalı veya eksik bölümler',
    Close: 'Kapat',
    Code: 'Hata kodu',
    Combination: 'Birleşim',
    Commission: 'Bonuslar',
    'Home team': 'Ev Sahibi',
    'Congratulations!': 'Tebrikler',
    'Contact': 'İletişim',
    'Contact Information': 'İletişim Bilgilerimiz',
    'Contact Us': 'Bize Ulaşın',
    Country: 'Ülke',
    'Coupon number': 'Kupon №',
    Credit: 'Bonus',
    Currency: 'Para birimi',
    Date: 'Tarih',
    'Date of birth': 'Doğum Tarihi',
    'Date range': 'Tarih Aralığı',
    'Deposit Money': 'Para Yatır',
    'Deposit money': 'Para Yatır',
    'Deposit options': 'Para Yatırma seçenekleri',
    deposits: 'Para Yatır',
    deposit: 'Para Yatır',
    Delete: 'Sil',
    Deleted: 'Silinen',
    DELETE_COUPON_MODAL_TEXT: '%%id%% numaralı kuponu silmek istediğinize emin misiniz?',
    'Deselect All': 'Seçimi temizle',
    'Details': 'Detay',
    'Dog racing': 'Tazı Yarışı',
    'Don\'t have an account?': 'Henüz üye değil misin?',
    'E-Mail': 'E-Posta',
    'E-mail repeat': 'E-posta (Tekrar)',
    'Error placing bet': 'Hata',
    'Error 404': '404',
    Event: 'Karşılaşma',
    BET_DETAILS_EVENT: 'Karşılaşma',
    'F.A.Q.': 'S.S.S.',
    Featured: 'Öne çıkan',
    Female: 'Kadın',
    'Fill the form please': 'Lütfen Formu Doldurunuz',
    'Filter time': 'Saat Seçimi',
    'Filters': 'Filtre',
    'Follow us at social media': 'Bizi sosyal medyada takip edin',
    'Football (Asian cup)': 'Futbol (Asya Kupası)',
    'Football (World cup)': 'Futbol (Dünya Kupası)',
    'Football (Euro cup)': 'Futbol (Avrupa Kupası)',
    'Football (Nations cup)': 'Futbol (Uluslar Kupası)',
    'Football (Bundesliga)': 'Futbol (Bundesliga)',
    'Football (Champions cup)': 'Futbol (Şampiyonlar Kupası)',
    'Football (England League)': 'Futbol (İngiltere Ligi)',
    'Cricket': 'Kriket',
    Football: 'Avrupa Ligi',
    'Forgot Password': 'Şifremi unuttum',
    'Fortune wheel': 'Efsane Kazı Kazan',
    'Frequently asked questions': 'Sıkça Sorulan Sorular',
    Fullname: 'Ad Soyad',
    'Game Type': 'Tip',
    Gender: 'Cinsiyet',
    'Get Bonus': 'Bonus al',
    'google-auth': 'Google Authenticator',
    HOME_CASINO: 'Casino',
    HOME_SLOT: 'Slot',
    HOME_SLOT_TEXT: 'Yüzlerce slot oyunu ve en size özel bonuslar hazırladık!',
    HOME_SLOT_TITLE: 'EN GUZEL SLOT OYUNLARI',
    HOME_SPORTS_BTN: 'HEMEN OYNA',
    HOME_SPORTS_TEXT:
        'Tüm spor dallarında yepyeni bahis seçenekleriyle kazancınızı katlayın!',
    HOME_SPORTS_TITLE: 'ÇIĞIR AÇAN BAHİS SEÇENEKLERİ',
    Help: 'Yardım',
    Hide: 'Gizle',
    Homepage: 'Anasayfa',
    'Horse racing': 'At Yarışı',
    'I accept odds changes': 'Oran değişikliklerini kabul ediyorum',
    'ID/Passport number': 'TC Kimlik / Pasaport No',
    Id: 'TC Kimlik Numarası',
    Info: 'Bilgi',
    'Information Message': 'Bilgilendirme Mesajı',
    'Last 30 Days': 'Son 30 gün',
    'Last 7 Days': 'Son 7 gün',
    'Last Month': 'Geçen Ay',
    'Live Betting': 'Canlı Bahis',
    'Live Bingo': 'Canlı Tombala',
    'Live Casino': 'Canlı Casino',
    'Live Match': 'Canlı Bahis',
    'Live Support': 'Canlı Yardım',
    'Live betting': 'Canlı Bahisler',
    'Live games': 'Canlı Karşılaşmalar',
    'Live matches': 'Canlı Maçlar',
    'Live support': 'Canlı Destek',
    'List': 'Liste',
    Live: 'Canlı',
    Livegames: 'Livegames',
    'Livematch bets': 'Canlı Bahis',
    'Lobby': 'Oyun Lobisi',
    Logout: 'Çıkış',
    Lost: 'Kaybeden',
    Lottery: 'Loto',
    'Main menu': 'Ana Menü',
    Male: 'Erkek',
    'Maximum Payout': 'Maksimum Ödeme',
    'Maximum Stake': 'Maksimum Yatırma',
    'Detailed Information': 'Bilgi',
    'MY_LIMITS_INFO':
        'Rulet oyununda bir spin için maksimum bahis miktarı.<br /> Baccarat oyununda bir el için maksimum bahis miktarı.<br /> Blackjack oyununda tek box için insurance dahil maksimum bahis miktarı. (Double Down ve Split yapılması uygundur).<br /> Hold’em Poker oyununda Maksimum Bet miktarı. (Maksimum Ante = Limit / 2)',
    Medium: 'Orta',
    'Member Login': 'Üye Girişi',
    Menu: 'Menu',
    messages: 'Mesajlar',
    'Minimum 2 characters required': 'En az 2 karakter yazmanız gerekmektedir',
    'Minimum 3 characters required': 'En az 3 karakter yazmanız gerekmektedir',
    'Minimum 4 characters required': 'En az 4 karakter yazmanız gerekmektedir',
    'Minimize games list': 'Oyun Listesini Küçült',
    'Minimum Stake per betslip': 'Kupon başına yatırılabilecek en az tutar',
    'Minimum Stake': 'Minimum Yatırma',
    'My Account': 'Hesabım',
    'My Bets': 'Kuponlar',
    'My account': 'Hesabım',
    'my-info': 'Bilgilerim',
    'limits': 'Limitlerim',
    NOT18_ERROR:
        'Üyelik oluşturabilmeniz için 18 yaşının üzerinde olmanız gerekmektedir.',
    'Name contains disallowed characters':
        'Ad/Soyad kısmında izin verilmeyen karakter bulunmaktadır',
    Name: 'Ad',
    New: 'Yeni',
    Newest: 'En yeni',
    'New password': 'Yeni Şifre',
    Net: 'Toplam',
    'Next Live Events': 'Sıradaki Canlı Maçlar',
    Next: 'Sonraki',
    'No games matching these parameters': 'Sonuç bulunamadı',
    'No options': 'Seçenek Yok',
    OVER18TEXT:
        '18 Yaşın üzerinde olduğumu, {{LINK_START}}kural ve şartları{{LINK_END}} okuduğumu beyan ederim.',
    Odds: 'Oran',
    Other: 'Diğer',
    Ok: 'Tamam',
    Oldest: 'En eski',
    'Old password': 'Eski Şifre',
    'Option': 'Seçenek',
    'Outright bets': 'Şampiyonluk Bahisleri',
    'Page Total': 'Sayfa Toplamı',
    'Paid total': 'Kazanan',
    Paid: 'Kazanan',
    'Passport' : 'Pasaport',
    'Password Repeat': 'Şifre tekrarı',
    'Password strength': 'Şifre Güvenliği',
    Password: 'Şifre',
    'Password changed successfully': 'Şifre Değiştirildi',
    'Password Reset': 'Şifre Sıfırlama',
    perfect: 'Mükemmel',
    'Personal': 'Kişisel',
    'Personal info': 'KİŞİSEL BİLGİLER',
    'Phone number': 'Telefon numarası',
    Phone: 'Telefon',
    'Ping-Pong': 'Masa Tenisi',
    'Place bet': 'Bahis Yap',
    'Please accept agreement': '18 Yaşından büyük olduğunuzu onaylayınız',
    'Please enter a valid e-mail address': 'Lütfen geçerli bir E-posta adresi giriniz',
    'Please enter your passport number': 'Pasaport numaranızı girmeniz gerekmektedir',
    'Please make sure you entered your details correctly':
        'Hatalı kimlik bilgileri: Kimlik Numarası, ad, soyad ve doğum tarihi bilgilerini tam olarak kimliğinizde yazdığı gibi giriniz.',
    'Please, input your password to get QR code': 'Şifre giriniz',
    'Please, input Code from Google Authenticator': 'Google Authenticator Kodu',
    'Popular Games': 'Popüler Oyunlar',
    Popular: 'Popüler',
    'Possible payout': 'Maksimum Kazanç',
    'Pre Match': 'Normal Bahis',
    Prematch: 'Pre Match Bahis',
    'Promo page': 'BONUSLAR',
    Promo: 'Bonuslar',
    Promotions: 'Bonuslar',
    Providers: 'Oyun Sağlayıcı',
    Provider: 'Oyun Sağlayıcı',
    Ragby: 'Ragbi',
    'Registration successful. Please check your e-mail.':
        'Üye kaydı yapıldı. Lütfen E-postanızı kontrol ediniz.',
    'Requested data not exist': 'Karşılaşma aktif değil.',
    'Reset Password': 'Şifre yenileme',
    Result: 'Maç Sonucu',
    'Battle Result': 'Sonuç',
    Rules: 'Kurallar',
    'Return to homepage': 'Ana sayfaya geri dön',
    Returned: 'İade',
    'Repeat new password': 'Yeni Şifre (Tekrar)',
    'Scan QR code or enter a secret key from Google Authenticator':
        'QR kodunuzu taratın veya Google kodunuzu girin',
    SERVICE_UNAVAILABLE:
        "Bilgileriniz işlenirken bir hata oluştu - Bir kaç dakika sonra yeniden deneyin veya canlı yardım'a bağlanın",
    'Send again': 'Tekrar Gönder',
    'SMS already sent. You can send another after: {{TIME}} seconds.':
        'Tekrar kod almak için {{TIME}} saniye beklemeniz gerekmektedir.',
    SIGNUP_HINT_PASS_STRENGTH:
        'Hesabınızın güvenliği için Şifreniz en az 8 karakterden oluşmalı ve en az bir küçük harf, bir büyük harf ve bir de rakam içermelidir.',
    'Search Game': 'Oyun Ara',
    Search: 'Ara',
    'Select All': 'Hepsini Seç',
    'Selection': 'Seçim',
    'Selected Systems': 'Sistem',
    Shortcuts: 'Kısayollar',
    'Show all games': 'Tüm Karşılaşmaları Göster',
    'Sign up': 'Hesap aç',
    'Sign in': 'Giriş Yap',
    Slots: 'Slot',
    'Sorry, this game are not available': 'Karşılaşma aktif değil.',
    'Sort by': 'Sıralama',
    'Sport Betting': 'Spor Bahisleri',
    Sport: 'Spor',
    'Sports bets': 'Spor Bahisleri',
    Sports: 'Spor Bahisleri',
    'Stake total': 'Yatırılan',
    Stake: 'Yatırılan Tutar',
    Status: 'Durum',
    Strong: 'İyi',
    Submit: 'Tamam',
    Support: 'Destek',
    'Surname contains disallowed characters':
        'Ad/Soyad kısmında izin verilmeyen karakter bulunmaktadır',
    Surname: 'Soyad',
    System: 'Sistem',
    TVBET: 'TVBET',
    Tennis: 'Tenis',
    'There is no available deposit methods for your account.':
        'Hesabınız için şu anda müsait olan bir yatırım yöntemi bulunmamaktadır',
    'There is no available withdraw methods for your account.':
        'Hesabınız için şu anda müsait olan bir çekim yöntemi bulunmamaktadır',
    'There is no bonus for you yet. Please try again later': 'Şu anda bonus yok',
    'This Month': 'Bu Ay',
    'This field is required': '',
    Time: 'Zaman',
    Today: 'Bugün',
    Total: 'Genel Toplam',
    Tournament: 'Turnuva',
    'Transaction note': 'İşlem Notu',
    'Turn On': 'Açık',
    'Turn Off': 'Kapalı',
    'Type': 'Tip',
    'Install Google Authenticator': 'Google Authenticator Yükle',
    'User information': 'Kullanıcı Bilgileriniz',
    'Username can contain only Latin letters, numbers and underscores':
        'Kullanıcı adı sadece harf, sayılardan oluşabilir.',
    'Username must be at least 3 characters and maximum 17 characters':
        'Kullanıcı Adınız en az 3 karakterden oluşmalıdır',
    Username: 'Kullanıcı Adı',
    'View Games': 'Oyunları Gör',
    'Virtual Sports': 'Sanal Sporlar',
    Virtual: 'Sanal',
    Voleyball: 'Voleybol',
    Waiting: 'Bekleyen',
    weak: 'Zayıf',
    'We have received your bonus request': 'Bonus talebiniz bize ulaşmıştır.',
    'We have sent you link to change your password':
        'Şifrenizi degistirmek için size bir link gönderdik.',
    Welcome: 'Hoşgeldiniz',
    'Withdraw options': 'Para Çekme seçenekleri',
    'withdraw-status': 'Çekim Durumu',
    withdraws: 'Para Çek',
    Won: 'Kazanan',
    Yesterday: 'Dün',
    'Your bet slip has been deleted successfully.': 'Kuponunuz silinmiştir.',
    'You have been logged out': 'Sistemden çıkış yaptınız',
    'You have entered incorrect character in password':
        'Şifrenizde noktalama işareti kullanmayınız',
    'You have no account history records.': 'Hesap geçmişi bilgisi mevcut değil.',
    'You have no livematch limits': 'Kayıt Yok',
    'You have no outright limits': 'Kayıt Yok',
    'You have no prematch limits': 'Kayıt Yok',
    'You have no withdraw status records for the chosen period of time.':
        'Seçilen tarihler için herhangi bir çekim işlemi bilgisi mevcut değil.',
    'Your phone number should be started from "5"':
        'Telefon numaranız 5 ile başlamalıdır.',
    'TFA_ENABLED': 'İki aşamalı doğrulama başarılı bir şekilde aktifleştirildi.',
    'TFA_DISABLED': 'İki aşamalı doğrulama başarılı bir şekilde devre dışı bırakıldı.',
    'Your ticket has been accepted': 'Kuponunuz Kaydedildi',
    'Your ticket has been rejected': 'Kupon Kabul Edilmedi',
    'You will be redirected to main page in {{TIMER}} seconds': 'Ana sayfaya otomatik olarak yönlendiriliyorsunuz {{TIMER}}',
    'Z - A': 'Z - A',
    Warning: 'UYARI',
    'OPEN_GAME_ERROR_WITH_CODE': 'Bir şeyler yanlış gitti. Bir kaç dakika sonra yeniden deneyin veya hata kodu ile canlı desteğe başvurun. Hata Kodu : {{ERROR_CODE}}',
    'Welcome to': 'Hoşgeldiniz',
    'Your membership has approved': 'Üyeliğiniz onaylanmıştır.',

    'Search result for': 'Sonuç',
    at: 'Saat',
    odds: 'Oran',
    Payout: 'Ödeme',
    'Payout Total': 'Kazanılacak Tutar',
    Yes: 'Evet',
    No: 'Hayır',

    //widgets
    LMT: 'Maç Takip',
    BET_ASSISTANT: 'Bahis Asistanı',
    MATCH_PREVIEW: 'Maç Önü',
    HEAD_TO_HEAD: 'Başa Baş',
    Streaming: 'Canlı Yayın',

    LIVE_STREAMING_ERROR_1000: 'Kaynak bulunamadı.',
    LIVE_STREAMING_ERROR_1001: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_1002: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_2000: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_2001: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_2002: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_2003: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3000: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3001: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3002: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3003: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3004: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3005: 'Geçersiz yayın türü. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3006: 'Bu karşılaşma için yayın henüz aktif değil.',
    LIVE_STREAMING_ERROR_3007: 'Geçersiz yayın türü. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3008:
        'Cihaz yayını izlemek için uygun değil. Lütfen canlı destek ile iletişime geçiniz.',
    LIVE_STREAMING_ERROR_3009: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3010: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3011:
        'Yayına yasaklı bir konumdan erişmeye çalışıyorsunuz. ($ip$ - $countryCode$)',
    LIVE_STREAMING_ERROR_3012: 'Karşılaşma aktif değil.',
    LIVE_STREAMING_ERROR_3014:
        'Cihaz yayını izlemek için uygun değil. Lütfen canlı destek ile iletişime geçiniz.',
    LIVE_STREAMING_ERROR_3015: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',
    LIVE_STREAMING_ERROR_3023: 'Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.',

    USER_IS_BLOCKED: 'Hesabınız inceleme altındadır. Endişe etmenizi gerektiren bir durum bulunmamaktadır. İnceleme tamamlanır tamamlanmaz hesap statünüz yeniden aktif duruma gelecektir. Bu sürede dilerseniz canlı destek hattımıza bağlanarak bilgi alabilirsiniz. Anlayışınız için teşekkür ederiz',

    'Sizi Arayalım': 'Sizi Arayalım',

    BET_BUILDER_TEXT: 'Bahis Sihirbazı, karşılaşma içerisindeki bahis seçeneklerini, aynı kupon içerisinde birleştirerek kazancınızı arttırabileceğiniz yeni bir bahis türüdür.',
    'What is Bet Builder?': 'Bahis Sihirbazı Nedir?',
    'You need to pick at least one more selection to place your bet via Bet Builder' : 'Bahis Sihirbazı ile bahis yapabilmeniz için en az iki seçim yapmanız gerekmektedir',
    'Bet Builder': 'Bahis Sihirbazı',

    'BUTTON_PRINT' : "Print",

    'CAPTCHA_TEXT': 'Bu site Google reCAPTCHA ile korunmaktadir {{FIRST_LINK}}Gizlilik Politikası{{CLOSE_LINK}} ve {{SECOND_LINK}}Kullanım Şartları{{CLOSE_LINK}} uygula.',

    'Terms of Service': 'Kurallar',
    'Responsible Gaming': 'Responsible Gaming',//todo: add trans
    'Self Exclusion': 'Self Exclusion',//todo: add trans
    'Dispute Resolution': 'Dispute Resolution',//todo: add trans
    'AML / KYC': 'AML / KYC',//todo: add trans
    'Privacy': 'Privacy',//todo: add trans
    'User Accounts': 'User Accounts',//todo: add trans

    TOO_MUCH_SELECTIONS: 'Kupon başı maksimum seçenek sayısına ulaşıldı!',
    CLEAR_EXISTING_SELECTION: 'Bu seçeneği ekleyebilmek için, kuponunuzdaki diğer seçim(ler)i temizlemeniz gerekmektedir. Kabul ediyor musunuz?',

    'Terms & Conditions': 'Kural ve şartları',
    'Illegal arguments combination': 'Seçenek kombine edilemez. İlişkili bahisler!',
    'Illegal selection, more than one outcome per market line.': 'Seçenek kombine edilemez. Birbiriyle çelişen bahisler!',

    GETTING_ERROR_PROVIDERS_TYPES: 'Bir şeyler ters gitti.',

    'Bet Builder information': 'Bahis Sihirbazı Bilgilendirme',

    'Cashouted': 'Cash-out',
    'Are you sure to want Cashout this coupon?': 'Bu kuponu bozdurmak istediğinize emin misiniz',
    'Cashout value': 'Cash-out Miktarı',
    'Cash-out my money': 'Cash-out Miktarı',
    'Your bet has been successfully cashouted': 'Cash-out işlemi başarıyla gerçekleşti.',
    'activeBets': 'Açık Bahisler',
    'Delete coupon': 'Kuponu Sil',
    'See All Coupons': 'Tüm Kuponları Görüntüle',
    'TotalOdds': 'Toplam Oran',
    'CASHOUT_1': 'Bu kupon için Cash out mevcut değildir.',
    'CASHOUT_2': 'Bu kupon için Cash out mevcut değildir.',
    'CASHOUT_3': 'Bu kupon için Cash out mevcut değildir.',
    'CASHOUT_4': 'Bu kupon için Cash out mevcut değildir.',
    'CASHOUT_5': 'Cash-out mevcut değil',
    'CASHOUT_6': 'Cash-out tutarı değişti. Lütfen tekrar deneyiniz.',
    'CASHOUT_9': 'Fazla sayıda deneme. Lütfen bir süre bekleyin',

    'ALREADY_ACCOUNT': 'Zaten hesabınız var mı?',
    'Popular Tournaments': 'Popüler Turnuvalar',

    'Share': 'Paylaş',
    'Cashout possible': 'Cashout Mevcut',
    'Cashout not possible': 'Cashout Mevcut Değil',

    PREMATCH_FILTERS_PANEL_HEADER: 'Arama ve Filtreler',
    PREMATCH_FILTERS_PANEL_CHOOSE: 'Spor / Kategori / Turnuva Seçimi',
    PREMATCH_FILTERS_PANEL_CHOOSE_FILTER: 'Seçim',
    PREMATCH_FILTERS_PANEL_CHOOSER_HEADER: 'Toplam {{numberOfFilters}}',
    PREMATCH_FILTERS_PANEL_LOOKING_FOR: "Ara",
    PREMATCH_FILTERS_PANEL_SHOW_RESULT: 'Sonuçları Göster',
    PREMATCH_FILTERS_PANEL_CLEAR: 'Seçimleri Kaldır',
    PREMATCH_FILTERS_PANEL_LIMIT_REACHED: 'Daha fazla seçim yapamazsınız.',
    'Today_sport': '{{sportName}} Bugün',
    'Tomorrow_sport': '{{sportName}} Yarın',
    'Weekend_sport': '{{sportName}} Haftasonu',

    RANGED_EVENTS_NO_DATA_TEXT: 'Seçilen zaman aralığında karşılaşma bulunmamaktadır.',
    'All sports': 'Tüm sporlar',

    SLOT_BATTLE__ERROR_100301: 'Battle bulunamadı!',
    SLOT_BATTLE__ERROR_100302: 'Oyun başladığı için katılım gösteremezsiniz!',
    SLOT_BATTLE__ERROR_100303: 'Hatalı şifre!',
    SLOT_BATTLE__ERROR_100304: 'Başlamış oyununuz bulunmaktadır!',
    SLOT_BATTLE__ERROR_100305: 'Başlamış oyununuz bulunmaktadır!',
    SLOT_BATTLE__ERROR_100306: 'Battle dolu!',
    SLOT_BATTLE__ERROR_100307: 'Oynamak için yetkiniz bulunmamaktadır!',
    SLOT_BATTLE__ERROR_100308: 'Yetersiz bakiye!',
    SLOT_BATTLE__ERROR_100309: 'Oyun oluşturmak için izniniz bulunmamaktadır!',
    SLOT_BATTLE__ERROR_100310: 'Oyun bulunamadı!',
    SLOT_BATTLE__ERROR_100311: 'Limit aşımı!',
    SLOT_BATTLE__ERROR_100312: 'Hatalı para birimi!',
    SLOT_BATTLE__CONNECTION_ISSUES_ERROR: 'Bağlantı hatası, lütfen daha sonra tekrar deneyin',
    SLOT_BATTLE__GENERAL_ERROR: 'Bilinmeyen bir hata oluştu!',

    SLOT_BATTLE__POPUP_TITLE: 'Slot Battle Bilgilendirme',

    SLOT_BATTLE__WILL_START_IN_A_MOMENT: 'Battle kısa bir süre içerisinde başlayacak',
    SLOT_BATTLE__LEFT_TIME_TO_PLAY: 'Oynamak için kalan süre {{TIMER}}',
    SLOT_BATTLE__WAITING_FOR_PLAYERS: 'Diğer oyuncular bekleniyor {{TIMER}}',
    SLOT_BATTLE__WAITING_FOR_RESULTS: 'Sonuçlar bekleniyor',
    SLOT_BATTLE__BATTLE_ENDED: 'Battle tamamlandı',
    SLOT_BATTLE__YOU_WON: 'Kazandınız!',
    SLOT_BATTLE__CLICK_HERE_TO_OPEN_RESULTS: 'Sonuçları görmek için tıkla',
    SLOT_BATTLE__YOUR_GAME_ENDED_WAIT_FOR_OTHERS: 'Oyununuz tamamlandı, diğer oyuncuların sonuçları bekleniyor {{TIMER}}',
    SLOT_BATTLE__OPEN_BATTLE_DETAIL: 'Battle detayları için tıkla',
    SLOT_BATTLE__BATTLE_STARTED_CLICK_TO_PLAY: 'Oyun başladı, oyunu açmak için tıkla!',
    SLOT_BATTLE__BATTLE_CANCELED: 'Gereken sayıda katılım oluşmadı, battle iptal edildi.',
    SLOT_BATTLE__PLAYERS_IN_BATTLE: 'Oyuncu Sayısı {{PLRS_QTY}}', //(2/3 players in battle!)

    SLOT_BATTLE__DONT_SHOW_AGAIN: 'Bir daha gösterme',
    SLOT_BATTLE__HOW_ENABLE_INVITATION: 'Slot Battle davet bildirimlerini Hesabım>Bilgilerim bölümü üzerinden tekrar aktif edebilirsiniz.',
    SLOT_BATTLE__BATTLE_INVITATIONS_TEXT: 'Slot Battle\'lara davet edildiniz!',
    SLOT_BATTLE__POPUP_INVITATION_TITLE: 'Slot Battle Daveti',
    SLOT_BATTLE__POPUP_INVITATIONS_TITLE: 'Slot Battle Davetleri',
    SLOT_BATTLE__LEFT_TIME_TO_PLAY_INVITATION: '{{TIMER}}',

    SLOT_BATTLE__TUTORIAL_VIDEO_HEADER: 'Slot Battle\'a video eğitimi',
    SLOT_BATTLE__TUTORIAL_STEP_1_HEADER: 'Slot Battle\'a Hoşgeldiniz!',
    SLOT_BATTLE__TUTORIAL_STEP_4_HEADER: 'Slot ekranı / son adım',
    SLOT_BATTLE__TUTORIAL_STEP_1_TEXT: 'Slot Battle ile tanışmaya hazır mısın? Heyecanlı, kazançlı ve oyuncuların diğer kullanıcılarla kıyasıya mücadele edeceği bu arenaya hoş geldin.\n\n' +
        'Slot battle ile 2 veya daha fazla kullanıcıya karşı, kendi belirlediğin şartlarla, yarışmaya girmek ve kazanmak tamamen senin kontrolünde. Şimdi senin için ' +
        'hazırladığımız bu kısa öğreticiyi okuyarak, hemen bir oyun yaratabilir veya diğer oyuncular tarafından yaratılmış oyunlardan birine katılarak, slot battle ' +
        'serüvenine başlayabilirsin.',
    SLOT_BATTLE__TUTORIAL_STEP_2_TEXT: 'Lobide aktif olan oyunları, oyunlara ait detayları ve oyun bilgilerini görüntüleyebilir, “Oyuna Katıl” düğmesi yardımıyla seçim yapabilir ve Slot Battle ' +
        'heyecanına burada başlayabilirsin. Yine bu sayfada, “Devam Eden”, “Tamamlanan” oyunları bulabilir, sağda bulunan filtre yardımıyla eski oyunlarını ' +
        'görüntüleyebilirsin.',
    SLOT_BATTLE__TUTORIAL_STEP_3_TEXT: 'Favori oyununu seç, bonus satın alma tutarını ve oyuncu sayısını belirle !\n\n' +
        'Arkadaşlarınla oynamak istersen oyunu “Özel Oyun” olarak işaretleyebilir, ve oyun için oluşturulan eşsiz linki arkadaşlarınla paylaşabilirsin.\n\n' +
        'Oyunu yalnızca katılımcı sayısının tamamlanması durumunda oynamak istersen, “En az 2 oyuncu ile başla” kutusundaki işareti kaldırmayı unutma !!',
    SLOT_BATTLE__TUTORIAL_STEP_4_TEXT: 'Oyunu başarı ile tamamlaman ve sıralamaya girmen için belirtilen adımları hatasız olarak tamamlaman gerekir. Şimdi slot battle öğretini tamamladın, artık ' +
        'başlayabilirsin. İyi şanslar.',
    SLOT_BATTLE__TUTORIAL_STEP_4_TIP_1: 'Doğru bonus satın alım miktarını bulmak için bahis tutarını ayarlayın',
    SLOT_BATTLE__TUTORIAL_STEP_4_TIP_2: 'Seçili bahisle eşit olmak zorundadır',
    SLOT_BATTLE__TUTORIAL_STEP_4_BUY: 'Bonus Satın Al',
    SLOT_BATTLE__TUTORIAL_DONT_SHOW_AGAIN: 'Bir daha gösterme',
    SLOT_BATTLE__TUTORIAL_START_BUTTON: 'Başla',
    SLOT_BATTLE__TUTORIAL_SKIP_BUTTON: 'Geç',
    SLOT_BATTLE__TUTORIAL_GOT_IT: 'Anladım, Teşekkürler',
    SLOT_BATTLE_TUTORIAL__TITLE: 'SLOT SAVAŞI ÖĞRETİMİ',
    SLOT_BATTLE__TUTORIAL_START: 'Öğreticiyi Başlat',
    SLOT_BATTLE__FAQ: 'Slot battle, 2 veya daha fazla kullanıcının, seçili oyun içerisinde aynı bahis miktarı ile bonus satın alarak karşı karşıya geldikleri, eşsiz bir oyun modudur.\n\n' +
        'Her oyuncu, oyun şartları sağlandıktan sonra başlayan 2 dakikalık geri sayım süresinin içerisinde, bonus satın alma işlemini tamamlamak ve en fazla 10 dakika içerisinde bonus turunu tamamlamak zorundadır.\n\n' +
        'Oyun sonucunda en yüksek miktarı kazanan oyuncu; kendi kazancının yanı sıra, diğer katılımcıların ilgili oyundaki toplam kazançlarını da elde eder.\n\n' +
        'Kazanan oyuncuların kazançlarının eşit olması durumunda, toplam kazanç, kullanıcılar arasında eşit olarak paylaştırılacaktır.\n\n' +
        'Oyunu herhangi bir şekilde kötüye kullanan, diğer oyuncuların eğlenmesine ve oyunlarına engel olan ya da teşebbüste bulunan oyuncuların bakiyeleri üzerinde işlem yapılacaktır.\n\n' +
        'Oyun yaratma ve oyunlara katılma ile ilgili hazırlanmış öğreticiyi hemen başlatabilirsin. Oyuna başlamadan önce oyun kurallarını mutlaka okumalısın.',
    SLOT_BATTLE__BATTLE_RULES: 'Slot Battle Kuralları',
    SLOT_BATTLE__GO_TO_BATTLE_RULES: 'Kuralları Göster',

    SLOT_BATTLE__BACK_BUTTON_AVAILABLE: 'Lobi',
    SLOT_BATTLE__BACK_BUTTON_STARTED: 'Devam eden',
    SLOT_BATTLE__BACK_BUTTON_COMPLETED: 'Tamamlanan',

    'available': 'Lobi',
    'started': 'Devam Eden',
    'completed': 'Tamamlanan',

    'My battles': 'Oyunlarım',
    'Lowest bet': 'Küçükten büyüğe',
    'Highest bet': 'Büyükten küçüğe',

    SLOT_BATTLE__NO_DATA_TITLE: 'BİR SAVAŞTA ŞANSINIZI KONTROL EDİN',
    SLOT_BATTLE__ALREADY_IN_BATTLE: 'Zaten battle içerisindesiniz',
    SLOT_BATTLE__USER_CANT_PLAY: 'Slot Battle’a katılım gösteremezsiniz. Lütfen canlı destek ile iletişime geçiniz.',
    SLOT_BATTLE__CREATE: 'Slot Battle oluştur',
    SLOT_BATTLE__START_NOW: 'Şimdi başla',
    SLOT_BATTLE__OPEN_MY_BATTLE: 'Savaşımı aç',
    SLOT_BATTLE__LEAVE: 'Ayrıl',
    SLOT_BATTLE__JOIN: 'Katıl',

    SLOT_BATTLE__CHOOSE_FAV_GAME: 'En sevdiğiniz oyunu seçin',
    SLOT_BATTLE__SET_WAGERING_AMOUNT: 'Bahis tutarını ayarla',
    SLOT_BATTLE__SELECT_NUMBER_OF_PARTICIPANTS: 'Katılımcı sayısını seçin',
    SLOT_BATTLE__WIN_YOUR_MONEY: 'Paranı kazan',

    SLOT_BATTLE__BANNER_TEXT: 'Şansını Dene',

    SLOTS_BATTLE__AVAILABLE_NO_DATA_TEXT: 'Uygun battle bulunamadı. Hemen yeni battle yarat !',
    SLOTS_BATTLE__STARTED_NO_DATA_TEXT: 'Devam eden battle bulunmuyor.',
    SLOTS_BATTLE__COMPLETED_NO_DATA_TEXT: 'Tamamlanan Battle bulunamadı.',
    SLOTS_BATTLE__NO_DATA_TEXT_BY_THIS_PARAMS: 'Arama kriterlerine uygun battle bulunamadı',

    SLOT_BATTLE__BET: 'Bonus Buy', //Bonus Satın Alma miktarı to Bonus Buy for Turkish (Kean 17 august)
    SLOT_BATTLE__PLAYERS: 'Oyuncu Sayısı',
    SLOT_BATTLE__OWNER: 'Oluşturan',

    SLOT_BATTLE__GAME_NAME: 'Oyun Adı',
    SLOT_BATTLE__DETAILS: 'Battle Detayları',
    SLOT_BATTLE__INVITE: 'Davet Gönder',
    SLOT_BATTLE__URL_COPIED: 'Oyun linki başarıyla kopyalandı',
    SLOT_BATTLE__SHARE: 'Paylaş',

    SLOT_BATTLE__PLAYER: 'Oyuncu',
    SLOT_BATTLE__STATUS: 'Durum',
    SLOT_BATTLE__SCORE: 'Kazanç',
    SLOT_BATTLE__RESULT: 'Sonucu',

    SLOT_BATTLE__DISABLED_PLAY_BUTTON: 'Bu oyuna şu anda katılım gösteremezsiniz',
    SLOT_BATTLE__PLAY_BUTTON: 'Oyna',
    SLOT_BATTLE__NOT_AVAILABLE: 'Battle aktif değil',
    SLOT_BATTLE__ROW_STATUS_DONE: 'Tamamlandı',
    SLOT_BATTLE__ROW_STATUS_LEFT: 'Katılmadı',
    SLOT_BATTLE__ROW_STATUS_NO_RESULT: 'No result',
    SLOT_BATTLE__ROW_STATUS_IN_PLAY: 'Oynanıyor',
    SLOT_BATTLE__ROW_STATUS_WAIT: '-',

    SLOT_BATTLE__CHOOSE_GAME: 'Oyun Seç',
    SLOT_BATTLE__NO_BET_OPTION: 'Bahis miktarı bulunamadı',
    SLOT_BATTLE__NO_GAMES: 'Oyun bulunamadı',
    SLOT_BATTLE__START_NOT_FULL: "En az 2 oyuncu ile başla",
    SLOT_BATTLE__PRIVAT: 'Özel Battle',
    SLOT_BATTLE__CREATE_PASSWORD: 'Parola Oluştur',

    SLOT_BATTLE__RESULTS_TIMER: 'Sonuçlar hesaplanıyor {{TIMER}}',

    SLOT_BATTLE: 'Slot battle',

    'Coupon Status': 'Zaman',
    'Score': 'Skor',
    'I accept to receive slot-battles invitations': 'Slot Battle daveti almayı kabul ediyorum',
    'Random Game': 'Rastgele Seç',

    LMT_INFO_TEXT: 'Canlı bahis sayfasında gösterilen skor, süre ve diğer içerikler yalnızca referans amaçlıdır.',

    WIDGET_GAME__PLAY_BUTTON: "Oyna",

    'Favorite': 'Favori Oyunlarım',
    'Recently played': 'Son Oynadıklarım',

    YOUR_POPULAR_ACCUMULATORS: 'Sana Özel Kuponlar',
    POPULAR_ACCUMULATORS: 'Popüler Kuponlar',
    TOTAL_ODDS: 'Toplam Oran',
    ADD_TO_BETSLIP: 'Kupona Ekle',
    REMOVE_FROM_BETSLIP: 'Kupondan Kaldır',

    GRAND_JACKPOT: 'Grand Jackpot',
    MAJOR_JACKPOT: 'Major',
    MINOR_JACKPOT: 'Minor',
    MINI_JACKPOT: 'Mini',
    BACK_TO_JACKPOTS: 'Geri',
    SHOW_ALL_GAMES: 'Tümünü Göster',
    JACKPOT_TOP_WIN: 'En Yüksek Kazanç',
    JACKPOT_LAST_WIN: 'Son Kazanç',
    JACKPOT_TOTAL_WINS: 'Toplam Kazanç',

    BOT_DETECTED: 'Erişim izniniz bulunmamaktadır, lütfen destek ile iletişime geçiniz.',

    COUPON_PRESET_SAVE: 'Değişiklikleri Kaydet',
    COUPON_BET_LIMIT_TEXT: 'Bahisler için Maksimum Tutar',
    AMOUNT_PER_BET: 'Bahis Başına Tutar',
    FIRST_BET: '1. Tutar',
    SECOND_BET: '2. Tutar',
    THIRD_BET: '3. Tutar',
    SINGLE: 'Tekli',
    ACCUMULATED: 'Kombine',

    ONLY_SINGLE_ERROR_TEXT: 'Kuponunuzda bulunan bazı seçenekler kombine edilemez. Lütfen seçimlerinizi düzenleyin.',

    TOTAL_AMOUNT: 'Toplam Tutar',

    BET_BUILDER_COMMON_ERROR: 'Seçenek kombine edilemez. Birbiriyle çelişen bahisler!',

    GAME_HAS_NO_FUN_MODE: 'Bu oyunun Eğlencesine Oyna modu bulunmamaktadır.',
};
