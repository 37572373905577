import {reaction, values} from 'mobx';
import {types} from "mobx-state-tree";
import sortBy from "lodash/sortBy";

import FilteredRange from "./filtered-range";
import {EventsViews} from "../helpers";


const RANGES_MAX_SIZE = 5;

const FilteredRanges = types
    .model('FilteredRanges', {
        ranges: types.map(types.compose(FilteredRange, EventsViews)),
        active: types.maybeNull(types.string),
    })
    .actions((self) => {
        reaction(() => self.active, (id) => {
            values(self.ranges).forEach(range => {
                if (range.id !== id) {
                    range._clearActiveFilterItems();
                }
            })
        })

        return {
            _set(id) {
                if (self.ranges.size >= RANGES_MAX_SIZE) {
                    self._deleteOldestRange();
                }
                self.ranges.put({id});
            },
            _deleteOldestRange() {
                self.ranges.delete(sortBy(values(self.ranges), 'sportsFetchTime', 'asc')[0]?.id);
            },
            getRange({from = '', to}) {
                const id = from + '_' + to;
                if (!self.ranges.has(id)) {
                    self._set(id)
                }
                self.active = id;
            },
        }
    })
    .views((self) => ({
        get activeRange() {
            return self.ranges.get(self.active);
        },
    }));

export default FilteredRanges;