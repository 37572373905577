import React, { useRef, useState } from 'react';
import ValidationGroup from 'devextreme-react/validation-group';
import { RequiredRule, Validator } from 'devextreme-react/validator';

import { LLBtn2, LLInput, withCommon } from '../../../../../components';

const FormRequestPassword = withCommon({
    store: ({ store }) => ({
        getGoogleQrCode: store.user.getGoogleQrCode,
        isGoogleQrCodeLoading: store.user.isGoogleQrCodeLoading,
    }),
    defaultName: 'FormRequestPassword',
    isObserver: true,
    isTrans: true,
})(({ getGoogleQrCode, isGoogleQrCodeLoading, trans }) => {
    const [password, setPassword] = useState('');
    const passwordRef = useRef();

    const submitGetQrCodeCallback = () => {
        getGoogleQrCode({ password }).then((res) => !res.success && setPassword(''));
    };

    return (
        <ValidationGroup className="google-auth__form" ref={passwordRef}>
            <p className="google-auth__label">
                {trans('Please, input your password to get QR code')}
            </p>
            <LLInput
                customClasses="google-auth__input"
                disabled={isGoogleQrCodeLoading}
                maxLength={50}
                mode="password"
                id="google-auth__password"
                name="google-auth__password"
                value={password}
                showClearButton={true}
                onEnterKey={submitGetQrCodeCallback}
                onValueChanged={(e) => {
                    setPassword(e.value);
                }}
                stylingMode="outlined"
            >
                <Validator>
                    <RequiredRule message={trans('PASSWORD_EMPTY')} />
                </Validator>
            </LLInput>
            <LLBtn2
                onClick={() => {
                    if (passwordRef.current.instance.validate()?.isValid) {
                        submitGetQrCodeCallback();
                    }
                }}
                isLoading={isGoogleQrCodeLoading}
                customClasses="google-auth__submit-btn"
            >
                {trans('Submit')}
            </LLBtn2>
        </ValidationGroup>
    );
});

export default FormRequestPassword;
