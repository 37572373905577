import {types} from "mobx-state-tree";


const FooterBtn = types
    .model('', {
        name: types.string,
        text: types.string,
        url: types.string,
        thumbnail: types.maybeNull(types.string),
    });

const FooterBtnsConfig = types
    .model('FooterBtnsConfig', {
        items: types.array(FooterBtn),
    })
    .actions((self) => ({
        setConfig(data = []) {
            self.items = [];
            data.forEach(btn => {
                self.items.push(btn);
            })
        }
    }));

export default FooterBtnsConfig;
