import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import cx from "classnames";

import {
    LLFooterMobile,
    LLHeaderDesktop,
    LLHeaderMobile,
    LLContentFooter,
    LLFooterDesktop,
    LLModal,
    LLDrawer,
    LLPopover,
    LLPopoverCashout,
    LLBackdrop,
} from './';
import {
    LLMobileSetter,
    LLScrollBox,
    LLScrollUp,
    withCommon,
    LiveChatSupport,
    LLPopup,
    BattlePopup,
    llNotifier,
} from '../components';
import {LLHeaderMenu} from './header/header-desktop/components';

import config from "../static/themes/config";

import vars from '../styles/themes/theme/variables.scss';


const LLLayout = withCommon({
    store: ({store}) => ({
        drawerIsActive: store.drawer.isActive,
        branchId: store.betting.branchId,
        drawerActivePosition: store.drawer.activePosition,
        modal: store.modal,
        activeGame: store.modal.activeGame,
        setModal: store.modal.setModal,
        currentPage: store.router.currentPage,
        viewSize: store.site.status.viewSize,
        mobileUserAgent: store.user.mobileUserAgent,
        openLogin: store.user.openLogin,
        isLandscapeOrientation: store.site.status.isLandscapeOrientation,
        setLandscapeOrientation: store.site.status.setLandscapeOrientation,
        isBackdropShown: store.site.status.isBackdropShown,
        siteName: store.site.siteName,
        isBackDropFullScreen: store.site.status.isBackDropFullScreen,
        drawer: store.drawer,
        isBlocked: store.user.isBlocked,
        isLogged: store.user.isLogged,
        couponsList: store.user.coupons.list,
        popover: store.popover,
        popoverCashout: store.popoverCashout,
        isLiveChatOpen: store.liveChat.isLiveChatOpen,
        isSearchFilterPanelOpened: store.betting.isSearchFilterPanelOpened,
        sportMountStatus: store.betting.sportMountStatus,
    }),
    isObserver: true,
    isRouter: true,
    isTrans: true,
})((
    {
        children,
        viewSize,
        modal,
        activeGame,
        setModal,
        popover,
        popoverCashout,
        drawer,
        drawerIsActive,
        drawerActivePosition,
        isBlocked,
        isLogged,
        couponsList,
        history,
        isLiveChatOpen,
        isLandscapeOrientation,
        setLandscapeOrientation,
        isBackdropShown,
        isBackDropFullScreen,
        mobileUserAgent,
        currentPage,
        branchId,
        siteName,
        openLogin,
        isSearchFilterPanelOpened,
        sportMountStatus,
    }
) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    if (siteName === 'Danobet') {
        const range = document.createRange();
        document.head.appendChild(
            range.createContextualFragment(
                `<link href="https://fonts.googleapis.com/css2?family=Almarai&display=swap" rel="stylesheet">`
            )
        );
    }

    useEffect(() => {
        if (config(siteName).scripts && config(siteName).scripts.headerScript) {
            const range = document.createRange();
            document.head.appendChild(
                range.createContextualFragment(
                    config(siteName).scripts.headerScript
                )
            );
        }

        setLandscapeOrientation(
            window.matchMedia('(orientation: landscape)').matches
        );

        const changeOrientation = () => {
            const windowWidthBefore = window.innerWidth;
            setTimeout(() => {
                if (windowWidthBefore !== window.innerWidth) {
                    window.scrollTo(0, 1);
                    setLandscapeOrientation(
                        window.matchMedia('(orientation: landscape)').matches
                    );
                }
            }, 200);
        };

        window.addEventListener('orientationchange', changeOrientation);
    }, []);

    useEffect(() => {
        if (isLogged && isBlocked) {
            drawer.isActive && drawer.close();
            modal.isActive &&
            modal.close(
                modal.onCloseGoTo
                    ? history.push(isBlocked ? '/' : modal.onCloseGoTo)
                    : isBlocked
                        ? history.push('/')
                        : null
            );
        }
    }, [isBlocked]);

    useEffect(() => {
        if (!mobileUserAgent && activeGame) {
            if (!activeGame.mode && !isLogged) {
                openLogin({onCloseGoTo: activeGame.onCloseGoTo})
            } else {
                setTimeout(() => {
                    try {
                        setModal({
                            active: true,
                            currentKey: 'launch-game',
                            customClasses: `ll-game-modal`,
                            game: activeGame,
                            onCloseGoTo: activeGame.onCloseGoTo,
                            showCloseButton: true,
                            showFullscreenButton: true,
                            closeOnOuterClick: false,
                        });
                    } catch {
                        llNotifier({
                            message: 'Sorry, this game are not available',
                            type: 'error',
                        });
                    }
                }, 500)
            }
        }
    }, [activeGame, isLogged]);

    useEffect(() => {
        if (isLiveChatOpen && liveChatRef.current) {
            liveChatRef.current.props.openChat();
        }
        drawer.close();
    }, [isLiveChatOpen]);

    const liveChatRef = useRef(null);

    const renderPageInner = () => <div
        className={cx(`page-wrapper--${viewSize}`,
            isSearchFilterPanelOpened ? ' page-wrapper--with-search-filter-panel' : '',
            `page-wrapper--${(branchId === 'Live' && currentPage === 'sport') ? 'live' : currentPage || 'home-page'}`,
            (viewSize !== 'desktop' && couponsList?.length && sportMountStatus) ?
                'page-wrapper--with-coupons'
                : ''
        )}
    >
        {viewSize === 'desktop' && (
            <>
                <LLHeaderDesktop/>
                {scrollPosition >=
                vars.css_sizeHeight_header_upper_part_desktop && (
                    <LLHeaderMenu customClasses="ll-header__menu--sticky"/>
                )}
            </>
        )}
        {children}
        {viewSize === 'desktop' && <LLContentFooter/>}
    </div>;

    return (
        <>
            <ToastContainer/>
            <LiveChatSupport liveChatRef={liveChatRef}/>
            <div
                className={`ll-content-container--${viewSize}${(!mobileUserAgent && viewSize !== 'desktop') ? '--small-desktop' : ''} ll-content-container--${
                    isLandscapeOrientation ? 'landscape' : 'portrait'
                }`}
            >
                {viewSize !== 'desktop' && <LLHeaderMobile/>}
                {!mobileUserAgent ? <LLScrollBox
                        id="main-scroll"
                        onScroll={setScrollPosition}
                        customClasses="ll-content-container-scroll"
                    >
                        {renderPageInner()}
                    </LLScrollBox>
                    :
                    renderPageInner()
                }
                {viewSize === 'desktop' ? <LLFooterDesktop/> : <LLFooterMobile/>}
            </div>

            <LLScrollUp/>
            <LLPopup/>
            <BattlePopup/>

            {useMemo(() => <LLModal/>, [modal.isActive, modal.title])}
            {useMemo(() => (<LLPopover/>), [popover.isActive])}
            {useMemo(() => (<LLPopoverCashout/>), [popoverCashout.isActive])}
            {useMemo(() => <LLDrawer/>, [drawerIsActive, drawerActivePosition])}
            {useMemo(() => (isBackDropFullScreen && isBackdropShown) &&
                <LLBackdrop/>, [isBackDropFullScreen, isBackdropShown])}
            <LLMobileSetter />
        </>
    );
});

export default LLLayout;
