import React, { memo } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import { reaction } from 'mobx';

import { pagesConfig } from './../../configs';
import { withCommon } from '../../components/';
import RenderRouterComponent from './render-routed-component';
import { LLLayout } from '../../layout';


const syncHistoryWithStore = (history, store) => {
    store._setHistory(history);

    function isLocationEqual(locationA, locationB) {
        return (
            locationA &&
            locationB &&
            locationA.key &&
            locationB.key &&
            locationA.key === locationB.key
        );
    }

    // Handle update from history object
    const handleLocationChange = (location) => {
        if (
            store?.location?.pathname &&
            store?.location?.pathname !== location.pathname
        ) {
            store._updatePreviousLocation(store.location);
        }
        store._updateLocation(location);
    };

    const unsubscribeFromHistory = history.listen(handleLocationChange);

    const unsubscribeFromStoreLocation = reaction(
        () => store.location,
        (location) => {
            if (!isLocationEqual(history.location, location)) {
                history.replace({ ...location });
            }
        }
    );

    history.unsubscribe = () => {
        unsubscribeFromHistory();
        unsubscribeFromStoreLocation();
    };

    store._updateLocation(history.location);

    return history;
};

const LLRouter = withCommon({
    store: ({ store }) => ({
        siteName: store.site.siteName,
        siteUrl: store.site.siteUrl,
        router: store.router,
        language: store.user.language,
    }),
    isTrans: true,
})(
    memo(({ siteName, siteUrl, router, language }) => {
        const history = syncHistoryWithStore(createBrowserHistory(), router);

        return (
            <Router history={history}>
                <LLLayout>
                    <Switch>
                        {Object.keys(pagesConfig(siteName)).map((page, i) => {
                            const { url, exact, meta, allowedForBlocked, ...restProps } = pagesConfig(siteName)[page];
                            return (
                                <Route path={url} exact={!!exact} key={i}>
                                    <Helmet htmlAttributes={{ lang: language }}>
                                        <title>{`${siteName} | ${meta.title}`}</title>
                                        <link rel="canonical" href={siteUrl} />
                                    </Helmet>
                                    <RenderRouterComponent allowedForBlocked={allowedForBlocked} {...restProps} />
                                </Route>
                            );
                        })}
                    </Switch>
                </LLLayout>
            </Router>
        );
    })
);

export default LLRouter;
