import React from 'react';

import { LLPreloader } from '../../../components';
import { inject, observer } from 'mobx-react';

import LiveLeftPanel from '../desktop/left-panel/live-left-panel/live-left-panel';
import LiveMatchDetails from '../desktop/components/live-match-detail';
import LiveCalendarPage from '../components/calendar/calendar-page';

const SportTablet = ({ store }) => {
    const initialLoading = store.betting.initialLoading,
        liveMatches = store.betting.liveMatches;

    const renderActiveView = () => {
        return {
            menu: <LiveLeftPanel />,
            detail: <LiveMatchDetails />,
            calendar: <LiveCalendarPage />,
        }[liveMatches.isCalendar ? 'calendar' : liveMatches.activeLiveView];
    };

    const renderInitialLoading = () => {
        return (
            <div className="match-list__loading--initial">
                <LLPreloader height={50} width={50} />
            </div>
        );
    };

    return initialLoading || initialLoading === null
        ? renderInitialLoading()
        : renderActiveView();
};

export default inject('store')(observer(SportTablet));
