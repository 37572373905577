import React from 'react';

import { withCommon } from '../../../../components';

const DrawerCloseButton = withCommon({
    store: ({ store }) => ({
        close: store.drawer.close,
        viewSize: store.site.status.viewSize,
    }),
})(({ close, viewSize }) => (
    <i className={`${viewSize === 'desktop' ? 'desktop' : 'mobile'}-drawer__close-btn doruk-close`} onClick={close} />
));

export default DrawerCloseButton;
