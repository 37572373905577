import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PromoCards from './components/promo-cards';
import { withCommon, LLPreloader } from '../../components';

/**
 * @file Represents Promo page to render the list of available bonuses
 *
 * @param {function} getPromos - action that set list of available bonuses
 *
 * @author I.Mykhailychenko
 */

const PromoPage = withCommon({
    store: ({ store }) => ({
        getPromos: store.promos.getPromos,
    }),
    propTypes: {
        getPromos: PropTypes.func.isRequired,
    },
    defaultName: 'PromoPage',
    isObserver: true,
    isTrans: true,
})(({ trans, getPromos }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPromos().then(() => setLoading(false));
    }, []);

    return (
        <section className="ll-page promo-content">
            {loading ? (
                <div className="promo-content__loader">
                    <LLPreloader />
                </div>
            ) : (
                <>
                    <h2 className="promo-content__title">{trans('Promotions')}</h2>
                    <PromoCards />
                </>
            )}
        </section>
    );
});

export default PromoPage;
