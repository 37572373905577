import { values } from 'mobx';
import { flow, types } from 'mobx-state-tree';

import { llNotifier } from '../../../../components';
import { formatDate, llattempt } from '../../../../common/utils';
import api from '../../../../common/api';


const CasinoReportItem = types.model('CasinoReportItem', {
    provider: types.string,
    amount: types.number,
});

const CasinoReport = types
    .model('CasinoReport', {
        casinoReports: types.array(CasinoReportItem),
    })
    .actions((self) => ({
        _set(data) {
            self.casinoReports = [];
            if (data?.length) {
                data.map((report) => {
                    self.casinoReports.push(
                        CasinoReportItem.create({
                            provider: report.Provider,
                            amount: report.Amount,
                        })
                    );
                });
            }
            self.fetchTime = Date.now();
            self.isTableLoading = false;
        },
        getCasinoReport: flow(function* fetch() {
            if ( self.checkFetchTime() ) {
                self.isTableLoading = true;
                const data = {
                    start: formatDate(self.startDate, 'YYYYMMDD'),
                    end: formatDate(self.endDate, 'YYYYMMDD'),
                };
                yield llattempt(
                    () =>
                        api.user
                            .getCasinoReport(data)
                            .then((response) => {
                                if (response.success) {
                                    self._set(response.data);
                                } else {
                                    self.resetFetchTimeAndLoading();
                                    // Thing below needed to avoid double error notifier on logout
                                    if (response.data?.error === 'AUTH_REQUIRED') {
                                        return true;
                                    } else {
                                        llNotifier({
                                            message: response.data?.error ?? 'GENERAL_ERROR',
                                            type: 'error',
                                        });
                                    }
                                }
                            }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.deleteBet',
                        withParams: data,
                        onError: () => {
                            self.resetFetchTimeAndLoading();
                        },
                    }
                );
            }
        }),
    }))
    .views((self) => ({
        get reports() {
            return values(self.casinoReports);
        },
    }));

export default CasinoReport;
