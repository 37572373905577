import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

import {
    LLElementTypeSetter,
    LLScrollBox,
} from '../../../../components';
import LiveWidgets from '../../components/betradar-widgets/live-widgets';
import MarketGroupsSlider from '../../components/market-groups-slider/market-groups-slider';
import crutchDanoHideBets from '../../components/crutches/danoHideBets';
import trans from '../../../../common/utils/trans';

import config from '../../../../static/themes/config';
import {LiveScoreboard} from "../../components/common";


const LiveMatchDetails = ({store}) => {
        const mountedLiveMatchInstance = store.betting.liveMatches.mountedLiveMatchInstance,
            getScrollBox = store.scrollBoxes.getScrollBox,
            mobileUserAgent = store.user.mobileUserAgent,
            isLandscapeOrientation = store.site.status.isLandscapeOrientation,
            viewSize = store.site.status.viewSize,
            siteName = store.site.siteName,
            isLogged = store.user.isLogged,
            userCanCashout = store.user.canCashout,
            branchId = store.betting.branchId,
            activeItems = store.betting.activeItems;

        const [activeMarketGroup, setActiveMarketGroup] = useState(mountedLiveMatchInstance?.marketGroupsList()[0]?.id);
        const [isSticky, setIsSticky] = useState(false);
        const [lmtError, setLmtError] = useState(false);

        let bananaDefender = true;

        useEffect(() => {
            if (bananaDefender && mountedLiveMatchInstance) {
                setLmtError(false);
                setActiveMarketGroup(-1);
            }
        }, [mountedLiveMatchInstance]);

        const scrollHandler = () => {
            if (bananaDefender) {
                if ((!mobileUserAgent && getScrollBox(viewSize === 'desktop' ? 'live-detail-scroll' : 'main-scroll')?.scrollPosition > 600) || (mobileUserAgent && window.scrollY > 600)) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            }
        };

        useEffect(() => {
            if (bananaDefender) {
                mobileUserAgent && document.addEventListener('scroll', scrollHandler);
            }
        }, []);

        useEffect(() => {
            if (bananaDefender && activeMarketGroup && !activeItems.isActive({
                id: activeMarketGroup.toString(),
                type: 'marketGroups'
            })) {
                getScrollBox(viewSize === 'desktop' ? 'live-detail-scroll' : 'main-scroll') && getScrollBox(viewSize === 'desktop' ? 'live-detail-scroll' : 'main-scroll').scrollMethods.scrollToTop();
            }
        }, [mountedLiveMatchInstance?.marketGroupsList().length]);

        useEffect(() => {
            scrollHandler();
        }, [getScrollBox(viewSize === 'desktop' ? 'live-detail-scroll' : 'main-scroll')?.scrollPosition]);

        useEffect(() => () => {
            bananaDefender &&
            mountedLiveMatchInstance &&
            activeItems?.marketGroups?.length &&
            mountedLiveMatchInstance.marketGroups
                .get(parseInt(activeItems.marketGroups[0])) &&
            mountedLiveMatchInstance.marketGroups
                .get(parseInt(activeItems.marketGroups[0]))
                .setWaitingUpdate(false);
            bananaDefender = false;
        }, []);

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                   Render functions                                   ##
        // ##                                                                                      ##
        // ##========================================================================================

        const renderLiveDetails = () => {
            if (
                viewSize === 'tablet' &&
                isLandscapeOrientation &&
                mountedLiveMatchInstance.lmt
            ) {
                return (
                    <div className="live-match-details__landscape">
                        {!crutchDanoHideBets(siteName, mountedLiveMatchInstance) && <LLScrollBox
                            customClasses={'live-page__landscape-scrollbox'}
                        >
                            <div className="live-match-details">
                                <MarketGroupsSlider
                                    activeMarketGroup={activeMarketGroup}
                                    setActiveMarketGroup={setActiveMarketGroup}
                                    matchInstance={mountedLiveMatchInstance}
                                    userCanCashout={userCanCashout}
                                    isMarketGroupsNeeded={mountedLiveMatchInstance.isMarketGroupsNeeded}
                                />
                            </div>
                        </LLScrollBox>
                        }
                        <LLScrollBox
                            customClasses={'live-page__landscape-scrollbox'}
                        >
                            <div className="live-match-details__landscape-widgets">
                                {(!!config(siteName).siteConfig.withOutWidgets || !mountedLiveMatchInstance.lmt || lmtError) ? (
                                        <LiveScoreboard
                                            match={mountedLiveMatchInstance}
                                            viewSize={viewSize}
                                            matchType="live"
                                            matchTime={mountedLiveMatchInstance.matchTime}
                                            matchScore={mountedLiveMatchInstance.matchScore}
                                            statusName={mountedLiveMatchInstance.statusName}
                                        />
                                    ) :
                                    <LiveWidgets
                                        key={mountedLiveMatchInstance.id}
                                        match={mountedLiveMatchInstance}
                                        isSticky={isSticky}
                                        viewSize={viewSize}
                                        isLogged={isLogged}
                                        setLmtError={setLmtError}
                                        lmtError={lmtError}
                                    />}
                            </div>
                        </LLScrollBox>
                    </div>
                );
            } else {
                return (
                    <>
                        {(!!config(siteName).siteConfig.withOutWidgets || !mountedLiveMatchInstance.lmt || lmtError) ? (
                                <LiveScoreboard
                                    match={mountedLiveMatchInstance}
                                    viewSize={viewSize}
                                    matchType="live"
                                    matchTime={mountedLiveMatchInstance.matchTime}
                                    matchScore={mountedLiveMatchInstance.matchScore}
                                    statusName={mountedLiveMatchInstance.statusName}
                                />
                            ) :
                            <LiveWidgets
                                key={mountedLiveMatchInstance.id}
                                match={mountedLiveMatchInstance}
                                isSticky={isSticky}
                                viewSize={viewSize}
                                isLogged={isLogged}
                                setLmtError={setLmtError}
                                lmtError={lmtError}
                            />}
                        {!crutchDanoHideBets(siteName, mountedLiveMatchInstance) && <div className="live-match-details">
                            <MarketGroupsSlider
                                activeMarketGroup={activeMarketGroup}
                                setActiveMarketGroup={setActiveMarketGroup}
                                matchInstance={mountedLiveMatchInstance}
                                userCanCashout={userCanCashout}
                                isMarketGroupsNeeded={mountedLiveMatchInstance.isMarketGroupsNeeded}
                            />
                        </div>}
                    </>
                );
            }
        };

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                        Render                                        ##
        // ##                                                                                      ##
        // ##========================================================================================

        const renderSectionInner = () => <>
            <div className="live-details__header">
                {viewSize === 'tablet' && (
                    <>
                        <LLElementTypeSetter
                            to={mountedLiveMatchInstance.tournamentUrl(branchId)}
                            className="live-details__back-button"
                        >
                            <i className="live-details__back-button-icon llg-chevron-left"/>
                            <span>{trans('Back')}</span>
                        </LLElementTypeSetter>
                        <LLElementTypeSetter
                            onClick={() => {
                                store.betting.liveMatches.setCalendar(true);
                            }}
                            className="live-details__calendar-btn"
                        >
                            <i className="live-details__calendar-btn-icon doruk-live-calendar"/>
                            <span className="live-details__calendar-btn-text">
                                        {trans('Calendar')}
                                    </span>
                        </LLElementTypeSetter>
                    </>
                )}
            </div>
            {renderLiveDetails()}
        </>;

        return (
            mountedLiveMatchInstance ? (
                    <div className="live-detail__section" key={mountedLiveMatchInstance.id}>
                        {viewSize === 'desktop' ? <LLScrollBox
                                id="live-detail-scroll"
                                customClasses={'live-page__scrollbox'}
                            >
                                {renderSectionInner()}
                            </LLScrollBox>
                            : renderSectionInner()
                        }
                    </div>
                )
                : null
        );
    }
;
export default inject('store')(observer(LiveMatchDetails));
