import {types, flow, getParent, getRoot} from 'mobx-state-tree';

import {formatDate, llattempt} from "../../../../../common/utils";
import {llNotifier} from "../../../../../components";
import api from "../../../../../common/api";
import {TIMERS} from '../../../../config';


const BetHistory = types
    .model('BetHistory', {
        cashoutUpdatesFetchTime: types.maybeNull(types.number),
    })
    .actions((self) => {
        return {
            updateBetHistory: flow(function* fetch() {
                let error = null;
                if (
                    self.cashoutUpdatesFetchTime + TIMERS.betHistory.cashoutUpdateTime <= Date.now() &&
                    getRoot(self).user.canCashout && self.isRefreshedBehavior &&
                    self.isMounted && self.bets.size &&
                    getRoot(self).site.status.isActive &&
                    getRoot(self).user.userBets.statusFilter !== 'activeBets'
                ) {
                    return yield llattempt(
                        () =>
                            api.user.getActiveBets().then((response) => {
                                if (response.success) {
                                    self.update({data: response.data});

                                    clearTimeout(window.betHistoryUpdateTimeout);
                                    window.betHistoryUpdateTimeout = setTimeout(
                                        self.updateBetHistory,
                                        TIMERS.betHistory.cashoutUpdateTime
                                    );

                                    self.isErrorsLimitReached(null);
                                } else {
                                    self.resetFetchTimeAndLoading();
                                    clearTimeout(window.betHistoryUpdateTimeout);
                                    error = response.data?.error;

                                    // Thing below needed to avoid double error notifier on logout
                                    if (error === 'AUTH_REQUIRED') {
                                        return true;
                                    } else {
                                        self.isErrorsLimitReached(error);
                                    }
                                }
                                return true;
                            }),
                        {
                            msg: 'GENERAL_ERROR',
                            at: 'user.getActiveBets',
                            withNotifier: false,
                            onError: () => {
                                if (!self.isInitialLoad) {
                                    self.setError('GENERAL_ERROR');
                                }
                                if (self.isErrorsLimitReached('GENERAL_ERROR')) {
                                    self.resetFetchTimeAndLoading();
                                    clearTimeout(window.betHistoryUpdateTimeout);
                                }
                            },
                        }
                    );
                } else {
                    clearTimeout(window.betHistoryUpdateTimeout);
                    if (!self.betsError) {
                        window.betHistoryUpdateTimeout = setTimeout(
                            self.updateBetHistory,
                            TIMERS.betHistory.cashoutUpdateTime
                        );
                    }
                    return true;
                }
            }),
            getBetHistory: flow(function* fetch({needLoading} = {}) {
                if (
                    self.checkFetchTime() &&
                    getRoot(self).site.status.isActive
                ) {
                    self.setInitialLoad(needLoading ? false : self.isInitialLoad);
                    self.isTableLoading = !self.isInitialLoad;

                    if (!self.isInitialLoad) {
                        self.setActiveBet(null);
                        clearTimeout(window.betHistoryUpdateTimeout);
                    }

                    const data = {
                        id: getParent(self).customerId,
                        start: formatDate(self.startDate, 'YYYYMMDD'),
                        end: formatDate(self.endDate, 'YYYYMMDD'),
                    };

                    yield llattempt(
                        () =>
                            api.user.getBetHistory(data).then((response) => {
                                if (response.success) {
                                    self.set({ data: response.data });
                                    if ( response.data?.length && !self.isInitialLoad ) {
                                        self.setFetchTimeAndLoading({
                                            fetchTime: Date.now(),
                                        });
                                        self.updateBetHistory().then(() => {
                                            self.setFetchTimeAndLoading({
                                                loading: false,
                                            });
                                            self.setInitialLoad(true);
                                            if (self.activeBetId) {
                                                self.getBetHistoryDetails(!self.isInitialLoad);
                                            }
                                        });
                                    } else {
                                        self.setFetchTimeAndLoading({
                                            fetchTime: Date.now(),
                                            loading: false,
                                        });
                                        self.setInitialLoad(true);
                                    }

                                    clearTimeout(window.betHistoryTimeout);
                                    window.betHistoryTimeout = setTimeout(self.getBetHistory,
                                        TIMERS.myAccountFetchTime.betHistory);

                                    self.setError(null);
                                } else {
                                    self.resetFetchTimeAndLoading();
                                    self.setError(response.data?.error ?? 'GENERAL_ERROR');
                                    clearTimeout(window.betHistoryTimeout);
                                    // Thing below needed to avoid double error notifier on logout
                                    if (response.data?.error === 'AUTH_REQUIRED') {
                                        return true;
                                    } else {
                                        llNotifier({
                                            message: response.data?.error ?? 'GENERAL_ERROR',
                                            type: 'error',
                                        });
                                    }
                                }
                            }),
                        {
                            msg: 'GENERAL_ERROR',
                            at: 'user.getBetHistory',
                            withParams: data,
                            onError: () => {
                                clearTimeout(window.betHistoryTimeout);
                                self.setError('GENERAL_ERROR');
                                self.resetFetchTimeAndLoading();
                            },
                        }
                    );
                } else {
                    clearTimeout(window.betHistoryTimeout);
                    window.betHistoryTimeout = setTimeout(
                        self.getBetHistory,
                        TIMERS.myAccountFetchTime.betHistory);
                }
            }),
        }
    })

export default BetHistory;