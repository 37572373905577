import { flow } from 'mobx-state-tree';

import api from '../../../../../../common/api';
import llattempt from '../../../../../../common/utils/attempt';

/*
######## ######## ########  ######  ##     ## ########  ######
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ##          ##    ##       ##     ## ##       ##
######   ######      ##    ##       ######### ######    ######
##       ##          ##    ##       ##     ## ##             ##
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ########    ##     ######  ##     ## ########  ######
*/

const outrightFetches = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                        fetch sesi for top and favorite matches                       ##
    // ##                                                                                      ##
    // ##========================================================================================

    fetchAndConvertDataForOutrightBets: flow(function* fetch() {
        const outrightFetchParams = {
            matchId: s.matchId,
            marketId: s.marketId,
            specifiers: s.specifier,
        };

        return llattempt(
            () =>
                api.betting.getOutrightBets(outrightFetchParams).then((r) => {
                    return {
                        ...r,
                        ...(!r?.data?.error &&
                            r?.data &&
                            r.data.reduce(
                                (a, { id, v, n, sf, o }) => {
                                    return {
                                        data: [
                                            ...a.data,
                                            {
                                                id: id.toString(),
                                                value: v,
                                                specifier: sf,
                                                name: n,
                                                order: o,
                                            },
                                        ],
                                    };
                                },
                                { data: [] }
                            )),
                    };
                }),
            {
                withNotifier: false,
                at: 'fetchDataForOutright',
                withParams: outrightFetchParams,
            }
        );
    }),
});

export default outrightFetches;
