const crutchDanoHideBets = (siteName, match) => {
    const extraStatuses = [32,33,41,42];
    const penaltyStatuses = [34, 50];

    return siteName === 'Danobet' && match.sportId === '1'
        && (
            !penaltyStatuses.includes(match.statusId) &&
            (extraStatuses.includes(match.statusId) ? match.matchTimeMinute >= 115 : match.matchTimeMinute >= 86)
        )
}

export default crutchDanoHideBets;