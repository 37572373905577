import { reaction } from 'mobx';
import React, { useRef, useEffect, useState, memo } from 'react';

import { withCommon } from '../../';

const ScrollAnchor = withCommon({
    store: ({ store }) => ({
        getAsyncScroll: store.scrollBoxes.getAsyncScroll,
    }),
    isObserver: true,
})(({ id, getAsyncScroll, startPosition, children, repeat = false }) => {
    const ref = useRef(null);

    const [instance, setInstance] = useState(null);
    const [done, setDone] = useState(false);

    let unsubscribe = null;

    let bananaDefender = true;

    const bananaDefenderState = () => bananaDefender;

    useEffect(() => {
        getAsyncScroll(id).then(
            (element) => bananaDefenderState() && setInstance(element)
        );
    }, []);

    useEffect(() => {
        if (instance && bananaDefender) {
            unsubscribe = reaction(
                () => instance.scrollPosition,
                (scrollPosition) => {
                    const point = (ref.current?.offsetTop || 0) * startPosition;
                    const dif = scrollPosition >= point;
                    // logic for repeat prop. if repeat = true component trigger action all the time, else - trigger just once
                    setDone((prev) => (repeat ? dif : prev || dif));
                }
            );
        }
    }, [instance]);

    useEffect(
        () => () => {
            bananaDefender = false;
            unsubscribe && unsubscribe();
        },
        []
    );

    return <div ref={ref}>{children(done)}</div>;
});

export default memo(ScrollAnchor);
