import React from 'react';
import ProgressBar from 'devextreme-react/progress-bar';
import PropTypes from 'prop-types';

import { withCommon } from '../../index';
import { WithPopover } from '../../HOCs';

const PasswordStrengthBar = withCommon({
    store: ({ store }) => ({
        mobileUserAgent: store.user.mobileUserAgent,
    }),
    PropTypes: {
        form: PropTypes.shape({
            pwdStrength: PropTypes.number.isRequired,
        }),
        setForm: PropTypes.func.isRequired,
    },
    isTrans: true,
})(({ trans, mobileUserAgent, form, form: { pwdStrength }, setForm }) => {
    const passStrengthContainer = () => (
        <div
            className="password-strength__str"
            onMouseOver={() => {
                setForm({
                    ...form,
                    passTooltipShowed: true,
                });
            }}
            onMouseLeave={() => {
                setForm({
                    ...form,
                    passTooltipShowed: false,
                });
            }}
        >
            <div className="password-strength__texts">
                <span>{trans('weak')}</span>
                <span>{trans('Medium')}</span>
                <span>{trans('Strong')}</span>
                <span>{trans('perfect')}</span>
            </div>
            <ProgressBar
                className={`password-strength__pwd-bar password-strength__pwd-bar__${pwdStrength}-percent`}
                min={0}
                max={100}
                showStatus={false}
                value={pwdStrength}
            />
        </div>
    );

    return (
        <div className="password-strength">
            <div className="password-strength__label">{trans('Password strength')}</div>
            {mobileUserAgent ? (
                passStrengthContainer()
            ) : (
                <WithPopover
                    customClasses="password-strength__container"
                    popoverClasses="ll-popover__text"
                    popoverContent={<span>{trans('SIGNUP_HINT_PASS_STRENGTH')}</span>}
                    dropDownRole={true}
                >
                    {passStrengthContainer()}
                </WithPopover>
            )}
        </div>
    );
});

export default PasswordStrengthBar;
