import React from "react";
import {trans} from "../../../common/utils";
import {LLBtn2} from "../index";
import {withCommon} from "../../HOCs";

export const CashoutPopupContent = withCommon({
    store: ({store}) => ({
        userBets: store.user.userBets,
        activeBetsLoading: store.user.userBets.activeBets.couponWaitingLoading,
        betHistoryLoading: store.user.userBets.betHistory.couponWaitingLoading,
        hidePopover: store.popoverCashout.hidePopover
    }),
    isObserver: true
})(
    ({
         userBets,
         activeBetsLoading,
         betHistoryLoading,
         contentType,
         type,
         id,
         bet,
         hidePopover
     }) => {
        const loading = {
            activeBets: activeBetsLoading,
            betHistory: betHistoryLoading
        };

        if (contentType === "cashout") {
            return (
                <>
                    <div className="ll-popover-cashout_yes-no">
                        <div className="popup__text">
                            <p>{trans("Are you sure to want Cashout this coupon?")}</p>
                        </div>
                        <div className="popup__btns">
                            <LLBtn2
                                onClick={() =>
                                    !loading[type] &&
                                    userBets[type] &&
                                    userBets[type].getCashout({couponId: bet.id}).then(() => hidePopover())
                                }
                                isLoading={loading[type]}
                                customClasses={`popup-btn${loading[type] ? ' popup-btn--loading' : ''}`}
                            >
                                {trans("Yes")}
                            </LLBtn2>
                            <LLBtn2
                                onClick={() => !loading[type] && hidePopover()}
                                customClasses="popup-btn-outlined"
                            >
                                {trans("Cancel")}
                            </LLBtn2>
                        </div>
                    </div>
                </>
            );
        } else if (contentType === "delete") {
            return (
                <>
                    <div className="ll-popover-cashout_yes-no">
                        <p className="popup__text">
                            {trans("DELETE_COUPON_MODAL_TEXT").replace("%%id%%", id)}
                        </p>
                        <div className="popup__btns--delete">
                            <LLBtn2
                                onClick={() => {
                                    userBets[type] && userBets[type].deleteBet(id).then(() => hidePopover());
                                }}
                                customClasses="popup-btn"
                            >
                                {trans("Yes")}
                            </LLBtn2>
                            <LLBtn2
                                onClick={() => hidePopover()}
                                customClasses="popup-btn-outlined"
                            >
                                {trans("Cancel")}
                            </LLBtn2>
                        </div>
                    </div>
                </>
            );
        }
    }
);
