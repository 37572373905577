import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {withCommon} from '../../../components/HOCs';
import { LLHeaderMenu, LLHeaderUser, LLHeaderGuest, LanguagePicker } from './components';
import config from '../../../static/themes/config';

/**
 * @file Represents React Component to render Header Desktop
 * @memberOf LLLayout
 *
 * @param {func} isLogged - flag is user logged
 *
 * @author - A.Huskova, small fixes A.Green
 */

const LLHeaderDesktop = withCommon({
    store: ({ store }) => ({
        siteName: store.site.siteName,
        isLogged: store.user.isLogged,
    }),
    propTypes: {
        siteName: PropTypes.string,
        isLogged: PropTypes.bool,
    },
    isTrans: true,
    isObserver: true,
    isRouter: true,
})(({ isLogged, siteName }) => (
    <div className="ll-header--desktop">
        <div className="ll-header__top">
            <Link to="/" className="ll-header__logo" />

            <div className="ll-header__top-section">
                {config(siteName).siteConfig.headerTvButton ?
                    <a
                    className={`ll-header__button`}
                    href={config(siteName).siteConfig.headerTvButton.link}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <i className={`ll-header__button-icon active-bets__icon ${config(siteName).siteConfig.headerTvButton.icon}`} />
                </a>
                    : null
                }
                <LanguagePicker />
                {isLogged ? <LLHeaderUser /> : <LLHeaderGuest />}
            </div>
        </div>
        <LLHeaderMenu />
    </div>
));

export default LLHeaderDesktop;
