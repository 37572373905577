import { types, flow } from 'mobx-state-tree';
import { when } from 'mobx';

const ScrollBoxInstance = types
    .model('ScrollBoxInstance', {
        id: types.identifier,
    })
    .volatile(() => ({
        scrollPosition: 0,
        scrollMethods: {
            scrollTop: () => {},
            scrollLeft: () => {},
            scrollToTop: () => {},
            scrollToBottom: () => {},
            scrollToElement: () => {},
            scrollToLeft: () => {},
            scrollToRight: () => {},
            getScrollLeft: () => 0,
            getScrollTop: () => 0,
            getScrollWidth: () => 0,
            getScrollHeight: () => 0,
            getClientWidth: () => 0,
            getClientHeight: () => 0,
            getValues: () => 0,
        },
    }))
    .actions((self) => ({
        setScrollMethods(methods) {
            self.scrollMethods = methods;
        },
        setScrollPosition(position) {
            self.scrollPosition = position;
        },
    }));

const ScrollBoxes = types
    .model('ScrollBoxes', {
        scrollBoxes: types.map(ScrollBoxInstance),
    })
    .actions((self) => ({
        putScrollBox({ id, methods }) {
            self.scrollBoxes.put({ id });
            self.scrollBoxes.get(id).setScrollMethods(methods);
        },
        deleteScrollBox(id) {
            self.scrollBoxes.delete(id);
        },
        getAsyncScroll: flow(function* fetch(id) {
            yield when(() => self.scrollBoxes.get(id));
            return self.scrollBoxes.get(id);
        }),
    }))
    .views((self) => ({
        getScrollBox(id) {
            return self.scrollBoxes.get(id);
        },
    }));

export default ScrollBoxes;
