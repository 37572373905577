import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    withCommon,
    LLDateRangePicker,
    LLPreloader,
    LLTable,
} from '../../../../components';
import { DrawerCloseButton } from '../../components';

/**
 * @file Represents React Component to render page Withdraw Status of MyCabinet
 * @memberOf MyAccountPage
 *
 * @param {function} getWithdrawStatus - function to get data for withdraw status reports
 * @param {boolean} isTableLoading - show loading if we are waiting for request
 * @param {array} reports - data array for table of withdraw status reports
 * @param {object} storeItem - object in store for withdraw status reports (needed for date range picker)
 * @param {string} viewSize - flag what size of user window
 *
 * @author A.Huskova
 */

const WithdrawStatus = withCommon({
    store: ({ store }) => ({
        getWithdrawStatus: store.user.withdrawStatus.getWithdrawStatus,
        isTableLoading: store.user.withdrawStatus.isTableLoading,
        reports: store.user.withdrawStatus.reports,
        storeItem: store.user.withdrawStatus,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        getWithdrawStatus: PropTypes.func.isRequired,
        reports: PropTypes.array.isRequired,
        storeItem: PropTypes.object.isRequired,
    },
    defaultName: 'WithdrawStatus',
    isTrans: true,
    isObserver: true,
})(({ getWithdrawStatus, isTableLoading, reports = [], storeItem, viewSize, trans }) => {
    useEffect(() => {
        getWithdrawStatus();
    }, []);

    const withdrawStatusTableConfig = useMemo(
        () => ({
            mobileGridProps: {
                noDataText: 'CABINET_WITHDRAW_STATUS_NO_DATA_TEXT',
            },
            mobile: {
                columns: [
                    { key: 'provider', width: 80, title: trans('Provider'), alignment: 'left' },
                    {
                        key: 'amount',
                        title: trans('Amount'),
                        format: 'currency',
                        alignment: 'right',
                        width: 75,
                        allowHeaderFiltering: false,
                    },
                    {
                        key: 'dateTime',
                        title: trans('Date'),
                        format: 'dateTime',
                        alignment: 'right',
                        width: 115,
                        allowHeaderFiltering: false,
                    },
                    { key: 'status', title: trans('Status'), format: 'capitalize' },
                    { key: 'note', title: trans('Transaction note'), allowHeaderFiltering: false },
                ],
                headerFilter: {
                    visible: true,
                },
            },
            desktopGridProps: {
                onRowPrepared: (e) => {
                    if (e.data?.status)
                        e.rowElement.classList.add(
                            `table-row__withdraw-history--${e.data?.status}`
                        );
                },
                noDataText: 'CABINET_WITHDRAW_STATUS_NO_DATA_TEXT',
            },
            desktop: {
                columns: [
                    { key: 'provider', title: trans('Provider'), width: '20%', },
                    {
                        key: 'dateTime',
                        title: trans('Date'),
                        format: 'dateTime',
                        width: '15%',
                        allowHeaderFiltering: false,
                    },
                    {
                        key: 'amount',
                        title: trans('Amount'),
                        format: 'currency',
                        alignment: 'right',
                        width: '12%',
                        allowHeaderFiltering: false,
                    },
                    {
                        key: 'status',
                        title: trans('Status'),
                        format: 'capitalize',
                        width: '18%',
                    },
                    { key: 'note', title: trans('Transaction note'), allowHeaderFiltering: false },
                ],
                headerFilter: {
                    visible: true,
                },
            },
        }),
        []
    );

    return (
        <div className="withdraw-status my-account__content-container">
            {viewSize === 'desktop' && (
                <p className="my-account__title">
                    <span className="my-account__title-text">
                        {trans('withdraw-status')}
                    </span>
                    <DrawerCloseButton />
                </p>
            )}

            <div className="my-account__content">
                <div className="my-account__date-picker-wrapper">
                    <LLDateRangePicker
                        actionHandler={getWithdrawStatus}
                        storeItem={storeItem}
                    />
                </div>

                <div className="my-account__table-box">
                    {isTableLoading ? (
                        <div className="my-account__loader-box">
                            <LLPreloader />
                        </div>
                    ) : (
                        <LLTable
                            data={reports}
                            customConfig={withdrawStatusTableConfig}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default WithdrawStatus;
