import { getRoot, types as t } from 'mobx-state-tree';

const WithFlags = t
    .model('WithFlags', {
        lastFetchTime: t.maybeNull(t.Date),
        isWaitingUpdate: false,
        isFetching: false,
        initialFetching: true,
        dropDownFetching: true,
        unsuccessCount: 0,
    })
    .actions((s) => ({
        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                    Error setter                                      ##
        // ##                                                                                      ##
        // ##========================================================================================

        setWaitingUpdate(status) {
            s.isWaitingUpdate = status;
        },
        setFetching({ status, type }) {
            const betting = getRoot(s).betting;
            /* crunch to not show loading on lives */
            if (s.id ==='Live' && !betting.branch.initialFetching) {
                return;
            }
            if (status && type === 'initialFetching') {
                !betting.requestedDataLoading &&
                    betting.branch.id !== 'Live' &&
                    betting.setFetching({
                        type: 'isMenuDisabled',
                        status: true,
                    });
            } else {
                betting.setFetching({
                    type: 'isMenuDisabled',
                    status: false,
                });
            }
            s[type] = status;
        },
        setLastFetchTime(date = Date.now()) {
            s.lastFetchTime = date;
        },
        setUnsuccessCount(count) {
            s.unsuccessCount = count;
        },
    }));

export default WithFlags;
