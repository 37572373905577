import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import {SignUpMobile, SignUpDesktop, turkishId} from './components';
import {withCommon, llNotifier} from '../../index';
import config from '../../../static/themes/config';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


const stringToDate = (value) => {
    const tempArr = value.split('-');
    return new Date(new Date(new Date(new Date().setDate(tempArr[0])).setMonth(tempArr[1] - 1)).setYear(tempArr[2]));
};

const SignUpForm = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
        siteName: store.site.siteName,
        signup: store.user.signup,
        btag: store.user.btag,
        captchaV3: store.site.captchaV3,
    }),
    PropTypes: {
        history: PropTypes.object.isRequired,
        signup: PropTypes.func.isRequired,
    },
    isRouter: true,
    isObserver: true,
})(({history, signup, viewSize, siteName, captchaV3, btag}) => {
    const [form, setForm] = useState({
            username: '',
            email: '',
            emailRepeat: '',
            password: '',
            passwordRepeat: '',
            name: '',
            surname: '',
            gender: 'M',
            dateOfBirth: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            countryCode: 'TR',
            city: '',
            address: '',
            idNumber: '',
            currency: 'TRY',
            phone: '',
            overEighteen: false,
            overEighteenIndicator: null,
            signupFormValid: true,
            passTooltipShowed: false,
        }),
        [serverResponseErrors, setServerResponseErrors] = useState({}),
        [isSubmitFetching, setSubmitFetching] = useState(false);

    const formRef = useRef(false);

    const serverValidationCheck = (e, fieldName) => {
        if (
            fieldName === 'idNumber' &&
            form.countryCode === 'TR' &&
            !serverResponseErrors[fieldName]
        ) {
            return turkishId.validate(e.value);
        } else if (fieldName === 'dateOfBirth') {
            let now = new Date();
            let m = now.getMonth();
            now.setFullYear(now.getFullYear() - 18);
            // deal with today being 29 Feb
            if (m !== now.getMonth()) now.setDate(0);
            return serverResponseErrors.dateOfBirth?.length ? false : now >= stringToDate(e.value);
        } else {
            return !serverResponseErrors[fieldName];
        }
    };

    const clearServerError = (type) => {
        const tempObj = {...serverResponseErrors};
        if (tempObj[type]) {
            delete tempObj[type];
            setServerResponseErrors(tempObj);
        }
    };

    const {executeRecaptcha} = useGoogleReCaptcha();

    const checkCaptcha = async () => {
        captchaV3 ?
            executeRecaptcha().then((token) => {
                submitRegistrationForm(token);
            }) :
            submitRegistrationForm();
    };

    const submitRegistrationForm = async (token = null) => {
        setSubmitFetching(true);
        if (form.overEighteen) {
            let customerName = form.name;
            let customerSurname = form.surname;

            if (form.countryCode === 'TR') {
                customerName = customerName.toLocaleUpperCase('TR');
                customerSurname = customerSurname.toLocaleUpperCase('TR');
            } else {
                customerName = customerName.toUpperCase();
                customerSurname = customerSurname.toUpperCase();
            }

            const data = {
                username: form.username,
                password: form.password,
                email: form.email,
                phone: form.phone,
                name: customerName,
                surname: customerSurname,
                idNumber: form.idNumber,
                currency: form.currency,
                country: form.countryCode,
                city: form.city,
                addr: form.address,
                dateOfBirth:
                    form.dateOfBirth.getFullYear() +
                    ('0' + (form.dateOfBirth.getMonth() + 1)).slice(-2) +
                    ('0' + form.dateOfBirth.getDate()).slice(-2),
                sex: form.gender,
            };

            if (btag) data.btag = btag;
            if (token) data.token = token;

            signup({...data}).then((response) => {
                if (!response) {
                    llNotifier({
                        message: 'GENERAL_ERROR',
                        type: 'error',
                    });
                } else if (!response.success) {
                    let tmpObj = {};
                    Object.keys(response.data).map((item) => {
                        if (response.data[item] === 'WRONG_TR_ID') {
                            tmpObj.name = response.data[item];
                            tmpObj.surname = response.data[item];
                            tmpObj.dateOfBirth = response.data[item];
                        }
                        if (
                            [
                                'FORM_REGISTRATION_REJECTED',
                                'FRAUD_DETECTED',
                            ].includes(response.data[item])
                        ) {
                            //TODO: Тут нужен не нотифает а модалка и break
                            llNotifier({
                                message: response.data[item],
                                type: 'error',
                            });
                        }
                        if (response.data?.error === 'BOT_DETECTED') {
                            llNotifier({
                                message: 'BOT_DETECTED',
                                type: 'error',
                            });
                        }
                        if (response.data[item] === 'NAME_AND_SURNAME_ALREADY_REGISTERED') {
                            llNotifier({
                                message: 'NAME_AND_SURNAME_ALREADY_REGISTERED',
                                type: 'error',
                                withTranslation: true,
                            })
                        }
                        return true;
                    });
                    setServerResponseErrors(
                        Object.assign(response.data, tmpObj)
                    );

                    const errorsKeys = Object.keys(response.data);
                    if (
                        errorsKeys.includes('username') ||
                        errorsKeys.includes('email')
                    ) {
                        formRef.current.instance.validate();
                    }
                } else {
                    if (config(siteName).scripts && config(siteName).scripts.signupScript) {
                        const range = document.createRange();
                        document.head.appendChild(
                            range.createContextualFragment(
                                config(siteName).scripts.signupScript
                            )
                        );
                    }
                    llNotifier({
                        message:
                            'Registration successful. Please check your e-mail.',
                        type: 'success',
                    });
                    history.push('/');
                }
                setSubmitFetching(false);
            });
        } else {
            llNotifier({
                message: 'Please accept agreement',
                type: 'error',
            });
            setSubmitFetching(false);
        }
    };

    const signUpProps = {
        form: form,
        setForm: setForm,
        serverResponseErrors: serverResponseErrors,
        serverValidationCheck: serverValidationCheck,
        clearServerError: clearServerError,
        formRef: formRef,
        submitRegistrationForm: checkCaptcha,
        isSubmitFetching: isSubmitFetching,
        setSubmitFetching: setSubmitFetching,
    };

    return (
        <>
            <div className={`signup--${viewSize}`}>
                {viewSize === 'mobile' ? (
                    <SignUpMobile {...signUpProps} />
                ) : (
                    <SignUpDesktop {...signUpProps} />
                )}
            </div>
        </>
    );
});

export default SignUpForm;