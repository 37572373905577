import { getRoot } from 'mobx-state-tree';
import { values } from 'mobx';

import {trans} from '../../../../common/utils';

/*
 #######  ##     ## ######## ########  ####  ######   ##     ## ########  ######
##     ## ##     ##    ##    ##     ##  ##  ##    ##  ##     ##    ##    ##    ##
##     ## ##     ##    ##    ##     ##  ##  ##        ##     ##    ##    ##
##     ## ##     ##    ##    ########   ##  ##   #### #########    ##     ######
##     ## ##     ##    ##    ##   ##    ##  ##    ##  ##     ##    ##          ##
##     ## ##     ##    ##    ##    ##   ##  ##    ##  ##     ##    ##    ##    ##
 #######   #######     ##    ##     ## ####  ######   ##     ##    ##     ######
*/

const outrightsActions = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                           Put data for single tournament                             ##
    // ##                                                                                      ##
    // ##========================================================================================

    setOutrightsData(data) {
        const branch = getRoot(s).betting.branch;
        const incomingOutrightsIds = [];
        let outrightsCategoryId;
        data.map((item) => {
            if (!outrightsCategoryId) outrightsCategoryId = item.cid;
            const sport = branch?.sports.get(item.sid);
            const category = sport?.categories.get(item.cid);
            if (category && !category?.outrightsTournaments.get(item.t.i)) {
                const tournamentInstance = {
                    id: item.t.i.toString(),
                    name: item.t.i === 0 ? trans('Other') : item.t.n,
                    order: item.t.i === 0 ? 1 : item.t.o,
                    timeStart: new Date(item.ts.replace(' ', 'T') + '+03:00'),
                };
                category.putUpdateOutrightsData({ dataSource: [tournamentInstance] });
            }
            const tournament = category?.outrightsTournaments.get(item.t.i);
            const outright = {
                id: item.sf + item.mid + item.mktid,
                sportId: item.sid.toString(),
                categoryId: item.cid.toString(),
                oddsCount: item.oc,
                marketId: item.mktid.toString(),
                matchId: item.mid.toString(),
                specifier: item.sf,
                timeStart: new Date(item.ts.replace(' ', 'T') + '+03:00'),
                name: item.n,
                order: item.o,
                canCashout: !!item.coa,
                columnCount: item.dc > 3 ? 2 : item.dc,
            };
            incomingOutrightsIds.push(outright.id);
            if (!s.matches.has(outright.id)) {
                s.matches.put(outright);
            } else {
                s.matches.get(outright.id).update(outright);
            }
            tournament.setOutrightId(outright.id);
        });
        values(s.matches).map((outright) => {
            if (
                !incomingOutrightsIds.includes(outright.id) &&
                outright.categoryId === outrightsCategoryId
            )
                s.matches.delete(outright.id);
        });
    },
});

export default outrightsActions;
