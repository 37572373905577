import {types as t, getRoot, flow} from 'mobx-state-tree';

import {refreshTime, unsuccessCountLimit} from '../config';
import Outright from './instances/outright';
import outrightsActions from './outrights-actions';
import outrightFetches from './outrights-fetches';
import WithFlags from '../with-flags';

const Outrights = t
    .model('Outrights', {
        matches: t.map(t.compose(Outright, WithFlags)),
    })
    .extend((s) => ({
        actions: {
            ...outrightsActions(s),
            ...outrightFetches(s),
        },
    }))
    .actions((s) => ({
        deleteItem({id}) {
            s.matches.delete(id);
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Scenarios instructions                                ##
        // ##                                                                                      ##
        // ##========================================================================================
        unsuccessOutrightsInitializeInstruction({res} = {}) {
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            getRoot(s).betting.setError({type: 'global', error});
        },

        unsuccessOutrightsUpdateInstruction({sportId, categoryId, res, dropDownRequest} = {}) {
            s.setUnsuccessCount(s.unsuccessCount + 1);

            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            if (s.unsuccessCount >= unsuccessCountLimit) {
                getRoot(s).betting.setError({type: 'list', error});
            }

            s.setWaitingUpdate(false);
            s.setUpdateForOutrights({sportId, categoryId, dropDownRequest});
        },

        successOutrightsInitializeInstruction({
                                                  incomingOutrightsData,
                                                  categoryId,
                                                  sportId,
                                                  dropDownRequest,
                                              } = {}) {
            s.setUnsuccessCount(0);
            const betting = getRoot(s).betting;

            if (incomingOutrightsData.length === 0 && betting.branch.initialFetching) {
                betting.setError({type: 'global', error: 'GENERAL_ERROR'});
            } else {
                s.setOutrightsData(incomingOutrightsData);

                s.setWaitingUpdate(false);
                s.setUpdateForOutrights({sportId, categoryId, dropDownRequest});
            }
        },

        successOutrightsUpdateInstruction({
                                              incomingOutrightsData,
                                              categoryId,
                                              sportId,
                                              dropDownRequest,
                                          } = {}) {
            s.setUnsuccessCount(0);

            if (incomingOutrightsData.length === 0) {
                getRoot(s).betting.setError({type: 'list', error: 'EMPTY_BETS_ERROR'});
            } else {
                s.setOutrightsData(incomingOutrightsData);
            }

            s.setWaitingUpdate(false);
            s.setUpdateForOutrights({sportId, categoryId, dropDownRequest});
        },

        // ##========================================================================================

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                              Outright getter instruction                             ##
        // ##                                                                                      ##
        // ##========================================================================================

        initializeOutrightsInstruction: flow(function* fetch({
                                                                 sportId,
                                                                 categoryId,
                                                                 dropDownRequest = false,
                                                             } = {}) {
            initializeOutrightsInstruction: {
                const parentCategory = getRoot(s).betting.branch.activeCategory();

                if (dropDownRequest) {
                    s.setFetching({
                        type: 'dropDownFetching',
                        status: true,
                    });
                }

                if (s.check.categoryLastFetchTimeNotExpired) {
                    const timeLeft =
                        +new Date(
                            +parentCategory?.lastOutrightsFetchTime +
                            refreshTime.outrights
                        ) - Date.now();
                    s.setWaitingUpdate(false);
                    s.setUpdateForOutrights({sportId, categoryId, timeLeft, dropDownRequest});
                    break initializeOutrightsInstruction;
                }

                if (!dropDownRequest) {
                    parentCategory.setInitialOutrightsFetching(true);
                    s.setFetching({type: 'initialFetching', status: true});
                }

                const res = yield s.fetchAndConvertDataForOutrights({categoryId}) ||
                {};

                if (!res || !res.success) {
                    s.unsuccessOutrightsInitializeInstruction({
                        sportId,
                        categoryId,
                        res,
                    });
                    if (getRoot(s).betting.branch.initialFetching) {
                        throw new Error(res.error);
                    }
                } else {
                    s.successOutrightsInitializeInstruction({
                        incomingOutrightsData: res.data,
                        sportId,
                        categoryId,
                        dropDownRequest,
                    });
                }

                if (dropDownRequest) {
                    s.setFetching({
                        type: 'dropDownFetching',
                        status: false,
                    });
                }

                s.setFetching({type: 'initialFetching', status: false});
                parentCategory.setInitialOutrightsFetching(false);
                parentCategory.setLastOutrightsFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                             Outrights update instruction                             ##
        // ##                                                                                      ##
        // ##========================================================================================

        updateOutrightsInstruction: flow(function* fetch({sportId, categoryId, dropDownRequest}) {
            updateOutrightInstruction: {
                const activeCategory = getRoot(s).betting.branch.activeCategory();
                if (s.check.isOurtightUpdateCleanable) {
                    s.setWaitingUpdate(false);
                    clearTimeout(window.__outrightsUpdater);
                    break updateOutrightInstruction;
                }

                if (!s.check.canGetData) {
                    s.setWaitingUpdate(false);
                    s.setUpdateForOutrights({sportId, categoryId});
                    break updateOutrightInstruction;
                }

                if (dropDownRequest && !getRoot(s).betting.isBreadcrumbsNavigationDropdownOpen) {
                    break updateOutrightInstruction;
                }

                s.setFetching({type: 'isFetching', status: true});

                const res = {
                    ...(yield s.fetchAndConvertDataForOutrights({categoryId})),
                };

                if (!res || !res.success) {
                    s.unsuccessOutrightsUpdateInstruction({
                        sportId,
                        categoryId,
                        res,
                        dropDownRequest,
                    });
                } else {
                    s.successOutrightsUpdateInstruction({
                        incomingOutrightsData: res.data,
                        sportId,
                        categoryId,
                        dropDownRequest,
                    });
                }
                s.setFetching({type: 'isFetching', status: false});
                activeCategory.setLastOutrightsFetchTime();
            }
        }),

        setUpdateForOutrights({sportId, categoryId, timeLeft, dropDownRequest}) {
            const betting = getRoot(s).betting;
            if (
                !s.isWaitingUpdate &&
                !betting.bettingErrors.global &&
                !betting.bettingErrors.list &&
                !betting.bettingErrors.details
            ) {
                s.setWaitingUpdate(true);
                clearTimeout(window.__outrightsUpdater);
                window.__outrightsUpdater = setTimeout(
                    () => s.updateOutrightsInstruction({sportId, categoryId, dropDownRequest}),
                    timeLeft ?? refreshTime.outrights
                );
            }
        },
    }))
    .views((s) => ({
        get check() {
            const betting = getRoot(s).betting;

            return {
                canGetData:
                    getRoot(s)?.site?.status.isActive && betting.sportMountStatus,

                get categoryLastFetchTimeNotExpired() {
                    const category = getRoot(s).betting.branch.activeCategory();
                    return !!(
                        category &&
                        category.lastOutrightsFetchTime &&
                        Date.now() <
                        +new Date(
                            +category?.lastOutrightsFetchTime + refreshTime.outrights
                        )
                    );
                },

                isOurtightUpdateCleanable: !betting.activeItems.tournaments.includes(
                    'Outrights'
                ),
            };
        },
    }));

export default Outrights;
