import React, {useState} from 'react';
import cx from "classnames";

import SelectedFilterItem from "./selected-filter-item";
import FilteredPrematchesList from "../../mobile/components/filtered-prematches/filtered-prematches-list";
import {trans} from "../../../../common/utils";


const CurrentFilterResult = ({filter, withRemoveBtn, activeRange, viewSize}) => {
    const [isOpened, setOpened] = useState(true);
    return (<>
        <SelectedFilterItem
            item={filter}
            removeCallback={() => {
                const index = activeRange.selectedFiltersList.indexOf(filter);
                if (index === -1) {
                    activeRange.clearFilters();
                } else {
                    activeRange.removeFilter(index);
                }
                activeRange.getSelectedFiltersDataInstruction();
            }}
            withRemoveBtn={withRemoveBtn}
            isOpened={isOpened}
            setOpened={setOpened}
        />
        <div className={cx(
            'filters-panel__filters-result-content',
            isOpened && 'filters-panel__filters-result-content--opened',
        )}>
            { filter?.events.length ?
                <FilteredPrematchesList
                    matches={filter.groupedByDateEvents}
                    type="search"
                    viewSize={viewSize}
                />
                :
                <div className="filters-panel__filters-result-content--empty">
                    <p>{trans('No result')}</p>
                </div>
            }
        </div>
    </>);}

export default CurrentFilterResult;