import React from 'react';

import {ChangePasswordForm, withCommon} from '../../components';


const ChangePassword = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
    }),
    isObserver: true,
})(({
        viewSize,
    }) => {
    return (
        <div className={`ll-page change-password--${viewSize}`}>
            <ChangePasswordForm/>
        </div>
    );
});

export default ChangePassword;
