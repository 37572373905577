import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";

import {trans} from "../../../../common/utils";
import {LLBtn2} from "../../../../components";


const SecondPopup = ({
                        store,
                    }) => {

    const viewSize = store.site.status.viewSize,
        setTutorialStep = store.slotsBattles.setTutorialStep,
        isBackdropShown = store.site.status.isBackdropShown,
        setBackdropShown = store.site.status.setBackdropShown,
        setBackDropClosable = store.site.status.setBackDropClosable,
        setBackdropOnClick = store.site.status.setBackdropOnClick,
        setBackDropFullScreen = store.site.status.setBackDropFullScreen;

    useEffect(() => {
        if (!isBackdropShown) {
            setBackdropShown(true);
            setBackDropClosable(false);
            setBackdropOnClick(() => {});
            setBackDropFullScreen(true);
        }
    }, [isBackdropShown])

    useEffect(() => () => setBackdropShown(false), [])

    return (
        <div className={`battle-tutorial battle-tutorial--${viewSize}`}>
            <div className="battle-tutorial__content">
                <p>{trans('SLOT_BATTLE__TUTORIAL_STEP_3_TEXT')}</p>
            </div>
            <div className="battle-tutorial__buttons">
                <LLBtn2
                    onClick={() => {
                        setTutorialStep(3);
                    }}
                    customClasses="slots-battles__btn--accent"
                >
                    {trans("Ok")}
                </LLBtn2>
            </div>
        </div>
    );
};

export default inject('store')(observer(SecondPopup));