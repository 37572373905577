import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";

import {WithPopover} from '../../../../components';
import {formatNumber, trans} from '../../../../common/utils';


const LobbyPlayer = ({store, player}) => {
    const winner = store.slotsBattles.activeBattle.winner,
        battle = store.slotsBattles.activeBattle,
        language = store.user.language,
        battlePopup = store.user.battlePopup,
        userBattleId = store.user.userBattleId,
        currency = store.user.currency;

    useEffect(() => {
        if (battle?.room_status === 'completed' && battle?.RoomUUID === userBattleId) {
            battlePopup.close();
        }
    }, [battle?.room_status, battlePopup])

    const playerStatus = (player) => {
        if (battle?.room_status === 'completed') {
            if (!player.BetAmount) return 'SLOT_BATTLE__ROW_STATUS_LEFT';
            if (!player.result && player.Customer !== '---') return 'SLOT_BATTLE__ROW_STATUS_NO_RESULT';
        }
        if (player.IsPlayed) return 'SLOT_BATTLE__ROW_STATUS_DONE';
        if (player.InPlay) return 'SLOT_BATTLE__ROW_STATUS_IN_PLAY';
        return 'SLOT_BATTLE__ROW_STATUS_WAIT';
    }

    return <div className="battle-lobby__detail-row-wrp">
        <WithPopover popoverClasses="ll-popover__text">
            <div className="battle-lobby__detail-item battle-lobby__detail-row-name">
                {player.Customer}
            </div>
        </WithPopover>
        <div className="battle-lobby__detail-item battle-lobby__detail-row-status">
            {trans(playerStatus(player))}
        </div>
        <div
            className="battle-lobby__detail-item battle-lobby__detail-row-status"
            id={'score-' + player.CustomerID}
        >
            {(battle?.room_status === 'completed') ?
                winner?.Prize ?
                    formatNumber(player.result || 0, 2, language, currency)
                    :
                    '---'
                :
                <div className="ll-dots-loading"/>
            }
        </div>
        <div
            className='battle-lobby__detail-item battle-lobby__detail-row-result'
            id={'result-' + player.CustomerID}
        >
            {(battle?.room_status === 'completed') ?
                winner?.Prize ?
                    formatNumber(player.Prize || 0, 2, language, currency)
                    :
                    '---'
                :
                <div className="ll-dots-loading"/>}
        </div>
    </div>;
};

export default inject('store')(observer(LobbyPlayer));
