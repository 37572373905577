import React from 'react';
import ResponsiveBox from 'devextreme-react/responsive-box';

import { withCommon } from '../../../components/HOCs';

/**
 * Functional component to define mobile size and set viewSize in store
 *
 * @param {func} props.setCurrentWidth - store action that set width
 * @author S.TARNAVSKI, S.Nakhodov
 */

const LLMobileSetter = withCommon({
    store: ({ store }) => ({
        setCurrentWidth: store.site.status.setCurrentWidth,
    }),
    isObserver: true,
})(({ setCurrentWidth }) => {
    return <ResponsiveBox screenByWidth={setCurrentWidth} />;
});

export default LLMobileSetter;
