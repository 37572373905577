import { types, flow, getParent } from 'mobx-state-tree';

import api from '../../../../common/api';
import { llNotifier } from '../../../../components';
import { toCamel, llattempt } from '../../../../common/utils';


const CasinoLimit = types.model('CasinoLimit', {
    brandName: types.maybeNull(types.string),
    limit: types.maybeNull(types.number),
});

const Limits = types
    .model('Limits', {
        casinoList: types.array(CasinoLimit),
    })
    .actions((self) => ({
        _set(data) {
            self.casinoList = toCamel(data);
            self.fetchTime = Date.now();
            self.isTableLoading = false;
        },
        getLimits: flow(function* fetch() {
            if (self.checkFetchTime()) {
                self.isTableLoading = true;
                const parent = getParent(self);
                const data = {
                    id: parent.customerId,
                    lang: parent.language,
                };
                yield llattempt(
                    () =>
                        api.user.getMyLimits(data).then((response) => {
                            if (response.success) {
                                self._set(response.data);
                            } else {
                                self.resetFetchTimeAndLoading();
                                // Thing below needed to avoid double error notifier on logout
                                if (response.data?.error === 'AUTH_REQUIRED') {
                                    return true;
                                } else {
                                    llNotifier({
                                        message: response.data?.error ?? 'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                }
                            }
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.getLimits',
                        withParams: data,
                        onError: () => {
                            self.resetFetchTimeAndLoading();
                        },
                    }
                );
            }
        }),
    }));

export default Limits;
