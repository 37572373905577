import React from 'react';
import PropTypes from 'prop-types';

import { LLBtn2, LLElementTypeSetter, withCommon } from '../../../../../components';
import config from '../../../../../static/themes/config';
import {getS3ImageUrl} from "../../../../../common/utils";
import {urls} from "../../../../../configs";

/**
 * @file Represents React Component to render PaymentItem
 * @memberOf Payments
 *
 * @param {Object} item - store data of payment item
 * @param {string} [item.logoURL] - url for img of payment logo
 * @param {string} [item.name] - name of payment
 * @param {string} item.modifiedUrl - iframe url for payment
 * @param {('withdraws'|'deposits')} type - type of payment
 * @param {func} setModal - function to set data for modal in store and open modal
 * @param {('mobile'|'tablet'|'desktop')} viewSize - modifier for css classes
 *
 * @author A.Huskova
 */

const PaymentItem = withCommon({
    store: ({ store }) => ({
        setModal: store.modal.setModal,
        viewSize: store.site.status.viewSize,
        siteName: store.site.siteName,
    }),
    propTypes: {
        item: PropTypes.object.isRequired,
        setModal: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['deposit', 'withdraws']).isRequired,
    },
    defaultProps: { config },
    defaultName: 'PaymentItem',
    isTrans: true,
    isObserver: true,
})(({ viewSize, item, type, setModal, siteName, trans }) => (
    <div className="payment">
        <div className="payment__logo">
            <img
                className="payment__logo-img"
                src={getS3ImageUrl() + '/images/payment_methods/' + (type === 'withdraws' ? 'withdraw' : 'deposit') + '/' + item.logoURL}
                alt={item.name}
            />
        </div>
        <div className="payment__btns">
            <LLBtn2 customClasses={`payment__btn ll-btn--square--${viewSize === 'desktop' ? 'desktop' : 'mobile'}`}>
                <LLElementTypeSetter
                    href={item.modifiedUrl}
                    className="payment__btn-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="payment__btn-link-text">{trans(config(siteName).payments[type].name)}</span>
                    <span className="payment__btn-link-name">{item.name}</span>
                </LLElementTypeSetter>
            </LLBtn2>
            {item.description && (
                <LLBtn2
                    customClasses={`payment__btn payment__btn--info ll-btn--square-outlined--${viewSize === 'desktop' ? 'desktop' : 'mobile'}`}
                    onClick={() =>
                        setModal({
                            active: true,
                            currentKey: 'payment',
                            content: item,
                            maskBackground: true,
                            showCloseButton: true,
                            title: item.name,
                        })
                    }
                >
                    <LLElementTypeSetter>{trans('Info')}</LLElementTypeSetter>
                </LLBtn2>
            )}
        </div>
    </div>
));

export default PaymentItem;
