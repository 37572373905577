const hours = (hours) =>
    parseInt(
        new Date(new Date().setHours(new Date().getHours() + hours)).setSeconds(0) / 1000
    );

const allTo = () =>
    new Date(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setSeconds(0)
    );

export const branchConfig = {
    All: {
        routerId: '1',
        to: () => Math.floor(allTo().setDate(allTo().getDate() - 1) / 1000),
    },
    Today: {
        routerId: '2',
        to: () => parseInt(new Date().setHours(23, 59, 59, 999) / 1000),
    },
    '12 Hour': {
        routerId: '3',
        to: () => hours(12),
    },
    '3 Hour': {
        routerId: '4',
        to: () => hours(3),
    },
    '1 Hour': {
        routerId: '5',
        to: () => hours(1),
    },
    Live: {
        routerId: 'live',
    },
};

export const refreshTime = {
    rangedEventsConfig: 15 * 60000,
    sports: 5 * 60000,
    categories: 5 * 60000,
    tournaments: 5 * 60000,
    matchesList: 5 * 60000,
    marketGroups: 5 * 60000,
    markets: 5 * 60000,
    outrights: 5 * 60000,
    outrightBets: 5 * 60000,
    search: 5 * 60000,
    liveMatches: 5000,
    widgets: 5 * 60000,
};

export const unsuccessCountLimit = 3;
