import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import ValidationSummary from 'devextreme-react/validation-summary';
import CheckBox from 'devextreme-react/check-box';
import ValidationGroup from 'devextreme-react/validation-group';

import {FormFirstPart, FormSecondPart} from './index';
import {withCommon, LLBtn} from '../../../index';
import config from '../../../../static/themes/config';
import {DrawerCloseButton} from "../../../../pages/user-profile/components";

/**
 * @file Represents registration page;
 * @memberOf SignUp
 *
 * @param {string} captcha - 2nd captcha v2 key. Received from server and keep it in store
 * @param {func} clearServerError - clear errors in state
 * @param {object} form - data from all fields in form
 * @param {number} activeTab - active tab
 * @param {bool} firstPartValid - flag is first part of sign up form is valid
 * @param {bool} overEighteen - flag is user above 18 e.o.
 * @param {string} language - active user language
 * @param {func} formRef - ref on first or second part of the form
 * @param {object}serverResponseErrors - list of errors from server if server send errors
 * @param {func} serverValidationCheck - custom validation in brouser
 * @param {func} setForm - save data from fields to state
 * @param {func} submitRegistrationForm - check all fields on second part
 * @param {boolean} isSubmitFetching - flag for disable submit button
 * @param {func} setSubmitFetching - switch flag isSubmitFetching
 *
 * @author A.Green
 */

const SignUpDesktop = withCommon({
    store: ({store}) => ({
        siteName: store.site.siteName,
        language: store.user.language,
        viewSize: store.site.status.viewSize,
        setModal: store.modal.setModal,
        captchaV3: store.site.captchaV3,
        openLogin: store.user.openLogin,
        drawer: store.drawer,
        modal: store.modal,
    }),
    PropTypes: {
        clearServerError: PropTypes.func.isRequired,
        form: PropTypes.shape({
            activeTab: PropTypes.bool.isRequired,
            firstPartValid: PropTypes.bool.isRequired,
            overEighteen: PropTypes.bool.isRequired,
        }),
        language: PropTypes.string.isRequired,
        formRef: PropTypes.func.isRequired,
        serverResponseErrors: PropTypes.object.isRequired,
        serverValidationCheck: PropTypes.func.isRequired,
        setForm: PropTypes.func.isRequired,
        submitRegistrationForm: PropTypes.func.isRequired,
        isSubmitFetching: PropTypes.bool.isRequired,
        setSubmitFetching: PropTypes.func.isRequired,
    },
    isTrans: true,
})(
    ({
         siteName,
         clearServerError,
         form,
         setForm,
         language,
         formRef,
         serverResponseErrors,
         serverValidationCheck,
         submitRegistrationForm,
         isSubmitFetching,
         setSubmitFetching,
         viewSize,
         setModal,
         trans,
         drawer,
         captchaV3,
         openLogin,
}) => {
        const tmpArray = trans('OVER18TEXT').split('{{LINK_START}}');
        const tmpArray2 = tmpArray[1].split('{{LINK_END}}');

        const checkBoxRef = useRef(null);

        return (
            <>
                {viewSize === "desktop" ? (
                    <div className="signup-drawer__header">
                        <p>{trans('Registration form')}</p>
                        <DrawerCloseButton/>
                    </div>) : null}

                <div className="signup__container">

                    <div className="signup__form">
                        <div className="signup__form-header">
                            <p>{trans('Account info')}</p>
                            {viewSize !== "desktop" && (<DrawerCloseButton/>)}
                        </div>
                        <ValidationGroup ref={formRef}>
                            <FormFirstPart
                                form={form}
                                setForm={setForm}
                                serverResponseErrors={serverResponseErrors}
                                serverValidationCheck={serverValidationCheck}
                                clearServerError={clearServerError}
                            />

                            <div className="signup__form-header">{trans('Personal info')}</div>
                            <FormSecondPart
                                form={form}
                                setForm={setForm}
                                serverResponseErrors={serverResponseErrors}
                                serverValidationCheck={serverValidationCheck}
                                clearServerError={clearServerError}
                            />
                            <div className="signup__footer">
                                <div className="signup__footer-eighteen-box">
                                    <div className="signup__footer-eighteen">
                                        <div className="signup__footer-eighteen--check">
                                            <CheckBox
                                                ref={checkBoxRef}
                                                value={form.overEighteen}
                                                onValueChanged={(e) =>
                                                    setForm({
                                                        ...form,
                                                        overEighteen: e.value,
                                                        overEighteenIndicator: true,
                                                    })
                                                }
                                            />
                                        </div>
                                        <p>
                                            {tmpArray[0]}
                                            <span
                                                className="signup__footer-link"
                                                onClick={() => {
                                                    setModal({
                                                        active: true,
                                                        title: trans('Rules'),
                                                        externalLink: config(siteName).rulesLinks[language],
                                                        currentKey: 'external-link',
                                                        showCloseButton: true,
                                                        showFullscreenButton: true,
                                                        is100vh: true,
                                                    });
                                                }}
                                            >
                                            {tmpArray2[0]}
                                        </span>
                                            {tmpArray2[1]}
                                        </p>
                                    </div>
                                    { !form.overEighteenIndicator &&
                                        form.overEighteenIndicator !== null && (
                                            <div className="signup__footer-eighteen--error">
                                                {trans('NOT18_ERROR')}
                                            </div>
                                    )}
                                </div>
                                {!!captchaV3 && (
                                    <p className="form__captcha-text" dangerouslySetInnerHTML={{
                                        __html: trans('CAPTCHA_TEXT').replace('{{FIRST_LINK}}', `<a href="https://policies.google.com/privacy" class="form__captcha-link">`)
                                            .replace('{{SECOND_LINK}}', `<a href="https://policies.google.com/terms" class="form__captcha-link">`)
                                            .replace('{{CLOSE_LINK}}', `</a>`)
                                            .replace('{{CLOSE_LINK}}', `</a>`),
                                    }}
                                    />
                                )}
                                <LLBtn
                                    className={`signup__sumbit-btn ll-btn--accent`}
                                    useSubmitBehavior={false}
                                    disabled={isSubmitFetching}
                                    onClick={(e) => {
                                        if (!isSubmitFetching) {
                                            setSubmitFetching(true);
                                            let result = e.validationGroup.validate();
                                            if (result.isValid) {
                                                submitRegistrationForm();
                                            } else {
                                                setSubmitFetching(false);
                                            }
                                            if (!form.overEighteen) {
                                                setForm({
                                                    ...form,
                                                    overEighteenIndicator: false,
                                                });
                                            }
                                        }
                                    }}
                                >
                                    <div>
                                        <span>{trans('Submit')}</span>
                                    </div>
                                </LLBtn>
                            </div>
                            <div className="signup__errors">
                                <ValidationSummary/>
                                { !form.overEighteenIndicator &&
                                    form.overEighteenIndicator !== null && (
                                        <div className="dx-validationsummary dx-widget dx-collection"
                                            onClick={() => checkBoxRef.current.instance.focus()}
                                        >
                                            <div className="dx-item dx-validationsummary-item">
                                                <div className="dx-item-content dx-validationsummary-item-content">
                                                    {trans('NOT18_ERROR')}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </ValidationGroup>
                        <div className="signup__login">
                            <span>{trans('ALREADY_ACCOUNT')}</span>
                            <span
                                className="signup__login-btn"
                                onClick={() => {
                                    drawer.close();
                                    openLogin();
                                }}
                            >{trans('Login here')}</span>
                        </div>
                    </div>
                </div>
            </>);
    },
);

export default SignUpDesktop;
