import { getRoot, flow } from 'mobx-state-tree';

import api from '../../../../common/api';
import llattempt from '../../../../common/utils/attempt';
import { branchConfig } from '../config';

/*
######## ######## ########  ######  ##     ## ########  ######
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ##          ##    ##       ##     ## ##       ##
######   ######      ##    ##       ######### ######    ######
##       ##          ##    ##       ##     ## ##             ##
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ########    ##     ######  ##     ## ########  ######
*/

const outrightFetches = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                        fetch sesi for top and favorite matches                       ##
    // ##                                                                                      ##
    // ##========================================================================================

    fetchAndConvertDataForOutrights: flow(function* fetch({ categoryId }) {
        const outrightFetchParams = {
            categoryId: categoryId,
            to: branchConfig[getRoot(s).betting.branchId].to(),
        };

        return llattempt(
            () => api.betting.getOutrights(outrightFetchParams).then((r) => r),
            {
                withNotifier: false,
                at: 'fetchAndConvertDataForOutrights',
                withParams: outrightFetchParams,
            }
        );
    }),
});

export default outrightFetches;
