import {flow, getRoot, types} from 'mobx-state-tree';

import {llattempt} from "../../../../../common/utils";
import api from "../../../../../common/api";
import {TIMERS} from '../../../../config';


const ActiveBets = types
    .model('ActiveBets', {})
    .actions((self) => {
        return {
            getActiveBets: flow(function* fetch(isForceUpdate = false) {
                let error = null;
                if (self.isMounted || !self.isInitialLoad) {
                    if (
                        (self.checkFetchTime() || isForceUpdate) &&
                        getRoot(self).site.status.isActive &&
                        getRoot(self).user.userBets.statusFilter === 'activeBets' &&
                        (!getRoot(self).drawer.isActive || getRoot(self).site.status.viewSize !== 'desktop')
                    ) {
                        self.isTableLoading = !self.isInitialLoad;
                        yield llattempt(
                            () =>
                                api.user.getActiveBets().then((response) => {
                                    if (response.success) {
                                        self.set({data: response.data});

                                        if (self.getBets.length && !self.isInitialLoad) {
                                            self.setActiveBet(self.getBets[0].id);
                                        }

                                        if (self.activeBetId && self.isInitialLoad) {
                                            self.getBetHistoryDetails(!self.isInitialLoad);
                                        }

                                        self.setFetchTimeAndLoading({
                                            fetchTime: Date.now(),
                                            loading: false,
                                        });
                                        self.setInitialLoad(true);
                                        self.isErrorsLimitReached(null);
                                        const user = getRoot(self).user;
                                        user.setActiveBetsCount(response.data.length);
                                        isForceUpdate && user.setActiveBetsCountWasForceUpdated(true);
                                    } else {
                                        self.resetFetchTimeAndLoading();
                                        clearTimeout(window.activeBetsTimeout);
                                        error = response.data?.error;

                                        // Thing below needed to avoid double error notifier on logout
                                        if (error === 'AUTH_REQUIRED') {
                                            return true;
                                        } else {
                                            if (!self.isInitialLoad) {
                                                self.setError(error);
                                            }
                                        }
                                    }
                                }),
                            {
                                msg: 'GENERAL_ERROR',
                                at: 'user.getActiveBets',
                                withNotifier: false,
                                onError: () => {
                                    if (!self.isInitialLoad) {
                                        self.setError(error);
                                    }
                                    self.resetFetchTimeAndLoading();
                                },
                            }
                        );
                    }
                    clearTimeout(window.activeBetsTimeout);
                    if ((!self.isInitialLoad && !self.betsError) || !self.isErrorsLimitReached(error)) {
                        window.activeBetsTimeout = setTimeout(
                            self.getActiveBets,
                            getRoot(self).user.canCashout && self.isRefreshedBehavior ?
                                TIMERS.myAccountFetchTime.activeBetsNormalUpdate :
                                TIMERS.myAccountFetchTime.activeBets
                        );
                    }
                } else {
                    clearTimeout(window.activeBetsTimeout);
                }
            }),
        }
    })

export default ActiveBets;