import React, {useMemo} from 'react';
import cx from 'classnames';

import {trans} from "../../../../common/utils";
import {LLElementTypeSetter, LLTextSlider, WithDropdown} from '../../../../components';


const BranchPicker = (
    {mountedBranch, siteStatus, language, setPanelOpened, viewSize}
) => {
    const branchPickerConfig = useMemo(() => [
        {
            label: 'All',
            elementAttributes: {
                as: 'NavLink',
                to: '/sport/1',
                'data-branch-id': 1,
            },
            element: <>{trans('All')}</>,
        },
        {
            label: 'Today',
            elementAttributes: {
                as: 'NavLink',
                to: '/sport/2',
                'data-branch-id': 2,
            },
            element: <>{trans('Today')}</>,
        },
        {
            label: '12 Hour',
            elementAttributes: {
                as: 'NavLink',
                to: '/sport/3',
                'data-branch-id': 3,
            },
            element: <>{trans('12 Hour')}</>,
        },
        {
            label: '3 Hour',
            elementAttributes: {
                as: 'NavLink',
                to: '/sport/4',
                'data-branch-id': 4,
            },
            element: <>{trans('3 Hour')}</>,
        },
        {
            label: '1 Hour',
            elementAttributes: {
                as: 'NavLink',
                to: '/sport/5',
                'data-branch-id': 5,
            },
            element: <>{trans('1 Hour')}</>,
        },
    ], [language]);

    return (
        <div className={cx(
            'branch-picker',
            `branch-picker--${viewSize}`
        )}>
            {viewSize === 'mobile' ?
                <WithDropdown
                    dataSource={{submenu: branchPickerConfig,}}
                    openBehavior="click"
                    menuItemIsActive={ (branch) => branch.label === mountedBranch }
                    customClasses={`ll-branch-picker__dropdown-wrapper--mobile${mountedBranch !== 'All' ? ' ll-branch-picker__dropdown-wrapper--accent' : ''}`}
                    onMenuItemClick={ () => siteStatus.setBackdropShown(false) }
                    menuClasses="ll-branch-picker__dropdown"
                    delay={500}
                    placement="bottom-end"
                >
                    <span
                        onClick={() => {
                            siteStatus.setBackDropFullScreen(false);
                            siteStatus.setBackdropShown(true);
                        }}
                    >{trans('Filter time')}: {mountedBranch}</span>
                </WithDropdown>
                :
                <LLTextSlider
                    id="branch-picker-slider"
                    viewSize={viewSize}
                    withAbsoluteArrows={viewSize !== 'desktop'}
                    showArrows={viewSize === 'desktop'}
                    customClasses={{
                        slider: 'branch-picker-slider',
                    }}
                >
                    {branchPickerConfig.map(branch => (
                        <LLElementTypeSetter
                            key={branch.label}
                            className="branch-picker__branch-item"
                            {...branch.elementAttributes}
                            onClick={() => setPanelOpened(false)}
                        >
                            <span className="branch-picker__branch-item-label">
                                {branch.element}
                            </span>
                        </LLElementTypeSetter>
                    ))}
                </LLTextSlider>
            }
        </div>
    );
};

export default BranchPicker;

