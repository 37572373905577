import React, {useEffect, useState} from 'react';
import Swipe from 'react-easy-swipe';
import cx from 'classnames';

import cssVariables from '../../../styles/themes/theme/variables.scss';
import {throttle} from "lodash";


const LLTextSlider = (
    {
        id = 'root',
        children,
        viewSize,
        customClasses = {},
        activeItem,
        slidesToShow = null,
        responsive = null,
        isLandscapeOrientation,
        scrollToCenter = true,
        withAbsoluteArrows,
        showArrows = true,
    }) => {
    const [isOversized, setOversized] = useState(false),
        [resizeTrigger, setResizeTrigger] = useState(Date.now()),
        [slideMinWidth, setSlideMinWidth] = useState(null),
        [tempOffset, setTempOffset] = useState(0),
        [swipeDirection, setSwipeDirection] = useState('left'),
        [itemsSizes, setItemsSizes] = useState([]),
        [state, setState] = useState({
            offset: 0,
            isAnimation: true,
            currentSlide: 0,
            overflowWidth: 0,
            contentWidth: 0,
        });

    const smoothGap = viewSize === 'mobile' ? 20 :
        viewSize === 'tablet' ? 20 : 25;

    useEffect(() => {
        const temp = [{
            width: 0,
            offset: 0,
        }];
        let width = 0;
        let slidesToShowCount;

        const sliderWidth = document.querySelector(`.js_slider#${id}`).getBoundingClientRect().width;

        const overflowWidth = withAbsoluteArrows ?
            sliderWidth
            :
            (sliderWidth - cssVariables[`css_sizeWidth_slider-arrow_${viewSize}`] * 2);

        let responsiveObj;
        let slideWidth;

        if (slidesToShow || responsive) {
            if (responsive) {
                responsiveObj = responsive.find(breakPoint => breakPoint.minSize <= window.innerWidth);
                slidesToShowCount = responsiveObj?.slidesToShow ?? slidesToShow;
            }
            if ((slidesToShow && !responsiveObj) || (responsiveObj && !responsiveObj?.isFullWidth)) {
                slideWidth = overflowWidth / slidesToShowCount;
                setSlideMinWidth(slideWidth);
            }
        }

        document.getElementById(id).querySelectorAll('.js_slider__item').forEach(item => {
            const itemWidth = slideWidth ?? item.getBoundingClientRect().width;
            if (itemWidth !== 0) {
                temp.push({
                    width: itemWidth,
                    leftOffset: width,
                    offset: width + itemWidth,
                    id: item.firstElementChild?.id,
                });
                width += itemWidth;
            }
        })
        setItemsSizes(temp);
        if (Math.floor(temp[temp.length - 1].offset) > sliderWidth) {
            setOversized(true);
            setState({
                ...state,
                contentWidth: width,
                overflowWidth,
                isAnimation: true,
                lastSlide: temp.indexOf(temp.find(item => item.offset > width - overflowWidth))
            })
        } else {
            setOversized(false);
            !responsiveObj?.isFullWidth && setSlideMinWidth(sliderWidth / slidesToShowCount);
            setState({
                offset: 0,
                isAnimation: true,
                currentSlide: 0,
                overflowWidth: 0,
                contentWidth: 0,
            });
        }
    }, [children, resizeTrigger]);

    useEffect(() => {
        if (itemsSizes.length && isOversized && activeItem) {
            const activeSlide = itemsSizes.find(item => item.id == activeItem);
            if (activeSlide) {
                if (scrollToCenter) {
                    if (activeSlide.leftOffset + activeSlide.width / 2 < state.overflowWidth / 2) {
                        setState({
                            ...state,
                            offset: itemsSizes[0].offset,
                            currentSlide: 0,
                        });
                        setSwipeDirection('left');
                        return;
                    }
                    if (state.contentWidth - activeSlide.offset + smoothGap < state.overflowWidth / 2) {
                        setState({
                            ...state,
                            offset: itemsSizes[itemsSizes.length - 1].offset - state.overflowWidth,
                            currentSlide: itemsSizes.length - 1,
                        })
                        setSwipeDirection('right');
                        return;
                    }
                    setSwipeDirection('center');
                    setState({
                        ...state,
                        offset: activeSlide.leftOffset + activeSlide.width / 2 - state.overflowWidth / 2,
                        currentSlide: itemsSizes.indexOf(activeSlide),
                    });
                } else {
                    if (state.contentWidth - activeSlide.leftOffset > state.overflowWidth) {
                        setState({
                            ...state,
                            offset: activeSlide.leftOffset,
                            currentSlide: itemsSizes.indexOf(activeSlide) - 1,
                        });
                        setSwipeDirection('left');
                    } else {
                        setState({
                            ...state,
                            offset: itemsSizes[itemsSizes.length - 1].offset - state.overflowWidth,
                            currentSlide: itemsSizes.length - 1,
                        })
                        setSwipeDirection('right');
                    }
                }
            }
        }
    }, [activeItem, resizeTrigger]);

    const resizeCallback = throttle(() => {
        setResizeTrigger(Date.now());
    }, 1000);

    let bananaDefender = true;

    useEffect(() => {
        setSwipeDirection('left');
        window.addEventListener('resize', resizeCallback);

        return () => {
            window.removeEventListener('resize', resizeCallback);
            bananaDefender = false;
        }
    }, []);

    useEffect(() => {
        if (bananaDefender) {
            resizeCallback();
        }
    }, [isLandscapeOrientation]);

    const isSlideVisible = (i, slidesCount) => {
        const firstVisibleIndex = Math.round(state.offset / slideMinWidth);
        const visibleSlidesCount = Math.round(state.overflowWidth / slideMinWidth);
        const visibleRange = visibleSlidesCount + firstVisibleIndex;
        const lastVisibleIndex = slidesCount - visibleSlidesCount;
        return (
            i === firstVisibleIndex
            || (i > firstVisibleIndex && i < visibleRange)
            || (firstVisibleIndex > lastVisibleIndex && i > lastVisibleIndex && i < slidesCount)
        )
    };

    const handleSwipeStart = () => {
        setTempOffset(state.offset);
        setState({
            ...state,
            isAnimation: !!activeItem,
        });
    }

    const handleSwipeMove = (position) => {
        setState({
            ...state,
            offset: tempOffset - position.x,
        });
        return false;
    }

    const handleSwipeRight = () => {
        // If swipe before the first element
        if (state.offset < 0) {
            setState({
                ...state,
                offset: itemsSizes[0]?.offset,
                isAnimation: true,
                currentSlide: 0,
            });
            setSwipeDirection('left');
            return;
        }
        // Any other swipe
        for (let i = 0; i < itemsSizes.length + smoothGap; i++) {
            if (state?.offset < itemsSizes[i]?.offset) {
                setState({
                    ...state,
                    offset: itemsSizes[i - 1]?.offset,
                    isAnimation: true,
                    currentSlide: i - 1,
                });
                setSwipeDirection('left');
                break;
            }
        }
    }

    const handleSwipeLeft = () => {
        // If we swipe far away of the last element
        if (state.offset + state.overflowWidth > itemsSizes[itemsSizes.length - 1]?.offset) {
            setState({
                ...state,
                offset: itemsSizes[itemsSizes.length - 1]?.offset - state.overflowWidth,
                isAnimation: true,
                currentSlide: itemsSizes.length - 1,
            })
            setSwipeDirection('right')
            return;
        }
        // Any other swipe
        let currentSlide;
        itemsSizes.some((item, i) => {
            if (state.offset + state.overflowWidth < item.offset - smoothGap) {
                currentSlide = i;
                return true;
            }
        });
        setState({
            ...state,
            offset: itemsSizes[currentSlide]?.offset - state.overflowWidth,
            isAnimation: true,
            currentSlide,
        });
        setSwipeDirection('right');
    }

    const slideAdditionalStyle = slidesToShow ? {minWidth: slideMinWidth + 'px', maxWidth: slideMinWidth + 'px'} : {};

    const renderSliderContent = () => {
        return (
            <div className="slider__content js_slider__content"
                 style={{
                     transform: `translateX(${-state.offset}px)`,
                     transition: state.isAnimation ?
                         viewSize === 'desktop' ? 'transform .4s ease-in-out' : 'transform .2s ease-in-out'
                         : 'unset'
                 }}
            >
                {React.Children.map(children, (child, i) => {
                    return child ? <div
                            className={'js_slider__item slider__item'}
                            data-is-visible={isSlideVisible(i, children.length)}
                            style={slideAdditionalStyle}
                        >
                            {child}
                        </div>
                        : null;
                })}
            </div>
        );
    }

    return (
        <div className={cx(
            'slider js_slider',
            customClasses?.slider,
            viewSize && ('slider--' + viewSize),
            isOversized && 'slider--oversized',
            withAbsoluteArrows && 'slider--with-absolute-arrows'
        )} id={id}>
            {isOversized ?
                <>
                    {showArrows ?
                        <div onClick={() => {
                            if (state.currentSlide > 0) {
                                let currentSlide;
                                if (swipeDirection !== 'left') {
                                    itemsSizes.some((item, i) => {
                                        if (state.offset > item.leftOffset - smoothGap && state.offset < item.offset + smoothGap) {
                                            currentSlide = i - 1;
                                            return true;
                                        }
                                    });
                                } else {
                                    currentSlide = state.currentSlide - 1;
                                }
                                setState({
                                    ...state,
                                    offset: itemsSizes[currentSlide]?.offset,
                                    currentSlide,
                                });
                                setSwipeDirection('left');
                            }}}
                           className={cx(
                               'slider__arrow slider__arrow--left',
                               customClasses?.arrow,
                               state.currentSlide === 0 && 'slider__arrow--disable'
                           )}
                        >
                            <i className="slider__arrow-icon--left doruk-mg-arrow-2"/>
                        </div>
                        : null
                    }
                    <Swipe
                        onSwipeStart={handleSwipeStart}
                        onSwipeMove={handleSwipeMove}
                        onSwipeRight={handleSwipeRight}
                        onSwipeLeft={handleSwipeLeft}
                    >
                        {renderSliderContent()}
                    </Swipe>
                    {showArrows ?
                        <div onClick={() => {
                            if (state.currentSlide < itemsSizes.length - 1) {
                                let currentSlide;
                                if (swipeDirection !== 'right') {
                                    itemsSizes.some((item, i) => {
                                        if ((item.offset - state.offset) > state.overflowWidth + smoothGap) {
                                            currentSlide = i;
                                            return true;
                                        }
                                    });
                                } else {
                                    currentSlide = state.currentSlide + 1;
                                }
                                setState({
                                    ...state,
                                    offset: itemsSizes[currentSlide]?.offset - state.overflowWidth,
                                    currentSlide,
                                });
                                setSwipeDirection('right');
                            } else return null; }}
                            className={cx(
                            'slider__arrow slider__arrow--right',
                                customClasses?.arrow,
                                (state.currentSlide >= itemsSizes.length - 1) && 'slider__arrow--disable'
                            )}
                        >
                            <i className="slider__arrow-icon--right doruk-mg-arrow-2"/>
                        </div>
                        : null
                    }
                </>
                :
                renderSliderContent()
            }
        </div>
    );
};

export default LLTextSlider;