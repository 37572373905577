import { values } from 'mobx';
import { flow } from 'mobx-state-tree';

/*
########  #######  ##     ## ########  ##    ##    ###    ##     ## ######## ##    ## ########
   ##    ##     ## ##     ## ##     ## ###   ##   ## ##   ###   ### ##       ###   ##    ##
   ##    ##     ## ##     ## ##     ## ####  ##  ##   ##  #### #### ##       ####  ##    ##
   ##    ##     ## ##     ## ########  ## ## ## ##     ## ## ### ## ######   ## ## ##    ##
   ##    ##     ## ##     ## ##   ##   ##  #### ######### ##     ## ##       ##  ####    ##
   ##    ##     ## ##     ## ##    ##  ##   ### ##     ## ##     ## ##       ##   ###    ##
   ##     #######   #######  ##     ## ##    ## ##     ## ##     ## ######## ##    ##    ##

 ######   #######  ##     ## ##     ##    ###    ##    ## ########   ######
##    ## ##     ## ###   ### ###   ###   ## ##   ###   ## ##     ## ##    ##
##       ##     ## #### #### #### ####  ##   ##  ####  ## ##     ## ##
##       ##     ## ## ### ## ## ### ## ##     ## ## ## ## ##     ##  ######
##       ##     ## ##     ## ##     ## ######### ##  #### ##     ##       ##
##    ## ##     ## ##     ## ##     ## ##     ## ##   ### ##     ## ##    ##
 ######   #######  ##     ## ##     ## ##     ## ##    ## ########   ######
*/
const tournamentsActions = (s) => ({
    putUpdateData({ dataSource }) {
        dataSource.map((newTournament) => {
            if (!s.tournaments.has(newTournament.id)) {
                s.tournaments.put({ ...newTournament });
            } else {
                s.tournaments.get(newTournament.id).update(newTournament);
            }
        });
    },

    putUpdateOutrightsData({ dataSource }) {
        dataSource.map((newTournament) => {
            if (!s.outrightsTournaments.has(newTournament.id)) {
                s.outrightsTournaments.put({ ...newTournament });
            } else {
                s.outrightsTournaments.get(newTournament.id).update(newTournament);
            }
        });
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                          Compare tournaments and delete old                          ##
    // ##                                                                                      ##
    // ##========================================================================================

    deleteOldTournaments: flow(function* fetch({ tournaments, tournamentIds }) {
        if (tournaments) {
            //for pre and for removeItems pre and live
            values(s.tournaments)
                .filter(
                    ({ id }) =>
                        !tournaments.some(({ id: incomingId }) => incomingId === id)
                )
                .map((oldTournament) => {
                    oldTournament.removeItems();
                });
        } else if (tournamentIds) {
            //for live
            yield Promise.all([
                values(s.tournaments).map((tournament) => {
                    if (!tournamentIds.includes(tournament.id)) {
                        tournament.removeItems();
                    }
                }),
            ]);
        }
    }),
});

export default tournamentsActions;
