import React, {useEffect, useState, useRef} from 'react';

import {LLPreloader} from '../../components';
import {HomeCarousel} from './components';
import {resizeHandler} from '../../common/utils';
import {inject, observer} from "mobx-react";

let galleryStartTimeout1,
    galleryStartTimeout2,
    galleryStartTimeout3;

const HomePage = ({store}) => {
    const customerGroup = store.user.customerGroup,
        isLandscapeOrientation = store.site.status.isLandscapeOrientation,
        getHomeConfig = store.home.getHomeConfig,
        homeSlides = store.home.homeSlides,
        viewSize = store.site.status.viewSize,
        home = store.home,
        mobileUserAgent = store.user.mobileUserAgent;

    const [loading, setLoading] = useState(true);
    const [homeSlidesS1, setHomeSlidesS1] = useState([]);
    const [homeSlidesS2, setHomeSlidesS2] = useState([]);
    const [homeSlidesS3, setHomeSlidesS3] = useState([]);
    const [autoplay1, setAutoplay1] = useState(false);
    const [autoplay2, setAutoplay2] = useState(false);
    const [autoplay3, setAutoplay3] = useState(false);

    const refGallery1Desktop = useRef(null);
    const refGallery2Desktop = useRef(null);
    const refGallery3Desktop = useRef(null);
    const refGallery1Mobile = useRef(null);
    const refGallery2Mobile = useRef(null);
    const refGallery3Mobile = useRef(null);

    let bananaDefender = true;
    const isMounted = () => bananaDefender;

    /* Block to fix mobile 100vh IOs problem */
    useEffect(() => {
        (bananaDefender && mobileUserAgent) && resizeHandler();
    }, [isLandscapeOrientation]);

    useEffect(() => {
        if (mobileUserAgent && bananaDefender) {
            if (window.visualViewport) {
                window.visualViewport.addEventListener('resize', resizeHandler);
            } else {
                window.addEventListener('resize', resizeHandler)
            }
        }
        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', resizeHandler);
            } else {
                document.removeEventListener('resize', resizeHandler);
            }
        }
    }, []);

    useEffect(() => {
        if (bananaDefender) {
            home.resetFetchTimes();
            setLoading(true);
            getHomeConfig();
        }
    }, [customerGroup]);

    const turnOnGallery = (timeout, device, slideSetter, type, galleryRef, timerTilStart, autoplaySetter) => {
        clearTimeout(timeout);
        slideSetter(homeSlides({platform: device, type: type}));
        galleryStartTimeout1 = setTimeout(() => {
            if (isMounted() && homeSlides({platform: device, type: type}).length && galleryRef.current ) {
                autoplaySetter(true);
                galleryRef.current.slickPlay();
            }
        }, timerTilStart);
        setLoading(false);
    }

    useEffect(() => {
        if (!home.isLoading && bananaDefender) {
            if (viewSize === 'mobile') {
                turnOnGallery(galleryStartTimeout1,'Mobile', setHomeSlidesS1, 's1', refGallery1Mobile, 1000, setAutoplay1 );
                turnOnGallery(galleryStartTimeout2,'Mobile', setHomeSlidesS2, 's2', refGallery2Mobile, 4000, setAutoplay2 );
                turnOnGallery(galleryStartTimeout3,'Mobile', setHomeSlidesS3, 's3', refGallery3Mobile, 7000, setAutoplay3 );
            } else {
                turnOnGallery(galleryStartTimeout1,'Desktop', setHomeSlidesS1, 's1', refGallery1Desktop, 1000, setAutoplay1 );
                turnOnGallery(galleryStartTimeout2,'Desktop', setHomeSlidesS2, 's2', refGallery2Desktop, 4000, setAutoplay2 );
                turnOnGallery(galleryStartTimeout3,'Desktop', setHomeSlidesS3, 's3', refGallery3Desktop, 7000, setAutoplay3 );
            }
        }
    }, [home.isLoading, viewSize])

    useEffect(
        () => () => {
            bananaDefender = false;
        },
        []
    );

    const renderHomePage = () => {
        switch (viewSize) {
            case 'desktop':
            case 'tablet':
                return (
                    <>
                        <div className={'home-page__banners'}>
                            {!!homeSlidesS1.length
                                ?
                                <HomeCarousel
                                    homeSlides={homeSlidesS1}
                                    speed={1000}
                                    sliderRef={refGallery1Desktop}
                                    autoplay={autoplay1}
                                    className={'home-page__banner-slider'}
                                />
                                :
                                <div className={
                                    "home-page__banner-slide-default " +
                                    "home-page__banner-slide-default--main"}
                                />
                            }
                        </div>
                        <div className={'home-page__games'}>
                            {!!homeSlidesS2.length
                                ?
                                <HomeCarousel
                                    homeSlides={homeSlidesS2}
                                    speed={1000}
                                    sliderRef={refGallery2Desktop}
                                    autoplay={autoplay2}
                                    className={'home-page__slots-slider'}
                                />
                                :
                                <div className={
                                    "home-page__banner-slide-default " +
                                    "home-page__banner-slide-default--slots"}
                                />
                            }
                            {!!homeSlidesS3.length
                                ?
                                <HomeCarousel
                                    homeSlides={homeSlidesS3}
                                    speed={1000}
                                    sliderRef={refGallery3Desktop}
                                    autoplay={autoplay3}
                                    className={'home-page__casino-slider'}
                                />
                                :
                                <div className={
                                    "home-page__banner-slide-default " +
                                    "home-page__banner-slide-default--casino "}
                                />
                            }
                        </div>
                    </>
                );
            case 'mobile':
            default:
                return (
                    <>
                        <div className={'home-page__banners'}>
                            {!!homeSlidesS1.length
                                ?
                                <HomeCarousel
                                    homeSlides={homeSlidesS1}
                                    speed={1000}
                                    sliderRef={refGallery1Mobile}
                                    autoplay={autoplay1}
                                    className={'home-page__banner-slider'}
                                />
                                :
                                <div className={
                                    "home-page__banner-slide-default " +
                                    "home-page__banner-slide-default--main"}
                                />
                            }
                        </div>
                        <div className={'home-page__games'}>
                            {!!homeSlidesS2.length
                                ?
                                <HomeCarousel
                                    homeSlides={homeSlidesS2}
                                    speed={1000}
                                    sliderRef={refGallery2Mobile}
                                    className={'home-page__slots-slider'}
                                    withOutDescription={true}
                                    autoplay={autoplay2}
                                />
                                :
                                <div className={
                                    "home-page__banner-slide-default " +
                                    "home-page__banner-slide-default--slots"}
                                />
                            }
                            {!!homeSlidesS3.length
                                ?
                                <HomeCarousel
                                    homeSlides={homeSlidesS3}
                                    speed={1000}
                                    sliderRef={refGallery3Mobile}
                                    className={'home-page__casino-slider'}
                                    withOutDescription={true}
                                    autoplay={autoplay3}
                                />
                                :
                                <div className={
                                    "home-page__banner-slide-default " +
                                    "home-page__banner-slide-default--casino "}
                                />
                            }
                        </div>
                    </>
                );
        }
    };

    return (
        <div className={`ll-page home-page__container--${viewSize}`}>
            {home.isLoading ?
                <LLPreloader/>
                :
                !loading && renderHomePage()
            }
        </div>
    );
};

export default inject('store')(observer(HomePage));