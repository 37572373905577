import React, {useEffect, useRef} from 'react';
import cx from 'classnames';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';

import { withCommon } from '../../components';

/***************** ~~ Represents popover ***************
 * @file - Represents React Component to render the popover
 *
 * @memberOf LLLayout
 * @param {boolean} mobileUserAgent - flag is mobile layout
 * @param {boolean} isActive - flag to show or hide popover
 * @param {function} hidePopover - set isActive = false
 * @param {function} showPopover - set popover state
 * @param {object} anchorEl - DOM element needed for calculating popover position
 * @param {JSX.Element} popoverContent - it is popover content )))
 * @param {string} placement - popover position
 * @param {string} popoverClasses - classNames for custom styles
 *
 * @author S.Nakhodov
 */

const LLPopover = withCommon({
    store: ({ store }) => ({
        isActive: store.popover.isActive,
        setPopover: store.popover.setPopover,
        hidePopover: store.popover.hidePopover,
        anchorEl: store.popover.anchorEl,
        placement: store.popover.placement,
        popoverContent: store.popover.popoverContent,
        popoverClasses: store.popover.popoverClasses,
        offset: store.popover.offset,
        width: store.popover.width,
        stayShovedByHover: store.popover.stayShovedByHover,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        setPopover: PropTypes.func.isRequired,
        hidePopover: PropTypes.func.isRequired,
        anchorEl: PropTypes.object.isRequired,
        placement: PropTypes.string,
        popoverContent: PropTypes.object,
        popoverClasses: PropTypes.string,
        stayShovedByHover: PropTypes.bool,
    },
    defaultName: 'LLPopover',
    isObserver: true,
})(({
    isActive,
    setPopover,
    hidePopover,
    anchorEl,
    placement,
    popoverContent,
    offset,
    width,
    popoverClasses,
    stayShovedByHover,
    viewSize,
}) => {
    const propObj = {};
    if (stayShovedByHover) {
        propObj.onMouseEnter = () => {
            anchorEl.classList.add(`${anchorEl.classList[0]}--hover`);
            setPopover({
                anchorEl,
                isActive: !!popoverContent,
                popoverClasses,
                popoverContent,
                placement,
                stayShovedByHover,
            });
        };
        propObj.onMouseLeave = () => {
            anchorEl.classList.remove(`${anchorEl.classList[0]}--hover`);
            hidePopover();
        };
    }

    useEffect(() => {
        hidePopover();
    }, [viewSize]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                isActive && hidePopover();
            }}
            mouseEvent={'onMouseDown'}
            touchEvent={'onTouchStart'}
        >
            <Popper
                open={isActive && !!anchorEl}
                {...propObj}
                anchorEl={anchorEl}
                placement={placement}
                modifiers={offset ? [
                    {
                        name: 'offset',
                        options: {
                            offset
                        },
                    }
                ] : []}
                className={cx(
                    'll-popover',
                    `ll-popover--${viewSize}`,
                    popoverClasses
                )}
                sx={width ? { width: width + 'px' } : null}
            >
                <>{popoverContent}</>
            </Popper>
        </ClickAwayListener>
    );
});

export default LLPopover;