import {types, flow, getRoot} from 'mobx-state-tree';
import ReactGA from 'react-ga4';
import {reaction} from 'mobx';

import {removeCookie, getCookie, setCookie} from '../../../common/utils';
import api from '../../../common/api';
import webSocketApi from '../../../common/web-socket-api';
import {
    AccountDataWithFetchTimeTemplate,
    CommonInfo,
    Limits,
    Payments,
    WithdrawStatus,
    AccountReport,
    AccountHistory,
    CasinoReport,
    Coupons,
    UserBets,
    BattlePopup,
} from './models';
import {llNotifier} from '../../../components';
import {llattempt} from '../../../common/utils';
import {TIMERS} from '../../config';
import config from "../../../static/themes/config";


let REFRESH_DATA_ERROR_COUNT = 0;

let waitLoginData = false;

let refreshDataInterval;

let customerNotificationConnection = {};

const User = types
    .model('User', {
        customerId: types.maybeNull(types.integer),
        customerGroup: 'GUEST',
        sId: types.maybeNull(types.string),
        username: types.maybeNull(types.string),
        language: 'tr',
        currency: types.maybeNull(types.string),
        balance: types.maybeNull(types.number),
        credit: types.maybeNull(types.number),
        messageCount: types.maybeNull(types.integer),
        acceptChanges: types.maybeNull(types.boolean),
        isBlocked: types.maybeNull(types.boolean),
        mobileUserAgent: types.maybeNull(types.boolean), //true for mobiles and tablets
        rewardPoints: types.maybeNull(types.number),
        canCashout: true,
        googleQrCodeUserSecret: types.maybeNull(types.string),
        isGoogleAuthActive: false,
        isGoogleQrCodeLoading: false,
        isGoogleAuthSettingLoading: false,

        /**** ~~ Cabinet Data ****/
        activeAccountPage: types.maybeNull(types.string),
        info: types.optional(
            types.compose(CommonInfo, AccountDataWithFetchTimeTemplate).named('info'),
            {}
        ),
        deposit: types.optional(
            types.compose(Payments, AccountDataWithFetchTimeTemplate).named('deposit'),
            {}
        ),
        withdraws: types.optional(
            types.compose(Payments, AccountDataWithFetchTimeTemplate).named('withdraws'),
            {}
        ),
        withdrawStatus: types.optional(
            types
                .compose(WithdrawStatus, AccountDataWithFetchTimeTemplate)
                .named('withdrawStatus'),
            {}
        ),
        accountReport: types.optional(
            types
                .compose(AccountReport, AccountDataWithFetchTimeTemplate)
                .named('accountReport'),
            {}
        ),
        accountHistory: types.optional(
            types
                .compose(AccountHistory, AccountDataWithFetchTimeTemplate)
                .named('accountHistory'),
            {}
        ),
        casinoReport: types.optional(
            types
                .compose(CasinoReport, AccountDataWithFetchTimeTemplate)
                .named('casinoReport'),
            {}
        ),
        limits: types.optional(
            types.compose(Limits, AccountDataWithFetchTimeTemplate).named('limits'),
            {}
        ),

        coupons: types.optional(Coupons, {}),
        userBets: types.optional(UserBets, {}),
        couponsPanelView: 'coupons',
        activeBetsCount: types.maybeNull(types.number),
        activeBetsCountWasForceUpdated: false,

        btag: types.maybeNull(types.string),

        showInvitations: types.maybeNull(types.boolean),
        onBattleGroupChanel: types.maybeNull(types.boolean),
        userSentBattleInvite: types.maybeNull(types.boolean),
        userBattleId: types.maybeNull(types.string),
        battleInvitations: types.array(types.string),
        battlePopup: types.optional(BattlePopup, {}),
        canPlaySlotBattles: true,
        playingGameId: types.maybeNull(types.string),

        isOldMarketsView: false,
    })
    .actions((self) => {
        reaction(() => self.customerId, (id) => {
            if (!id) {
                if (self.notificationConnected) {
                    webSocketApi.disconnect(customerNotificationConnection);
                }
                self._setOnBattleGroupChanel(false);
            } else {
                self._setConnection();
            }
        })

        // reaction(() => getRoot(self)?.site?.status.isActive, (value) => {
        //     if (!value) {
        //         if (self.notificationConnected) {
        //             webSocketApi.disconnect(customerNotificationConnection);
        //         }
        //         self._setOnBattleGroupChanel(false);
        //     } else {
        //         self.customerId && self._setConnection();
        //     }
        // })

        reaction(() => self.showInvitations, (value) => {
            self.battleInvitations = [];
            if (self.notificationConnected && self.customerId) {
                if (!value) {
                    customerNotificationConnection.invoke("LeaveSlotBattleGroup", self.customerGroup).then(() => {
                        self._setOnBattleGroupChanel(false);
                    });
                } else {
                    customerNotificationConnection.invoke("JoinSlotBattleGroup", self.sId, self.customerGroup).then();
                }
            }
        })

        const battleInvitationCallback = (msg) => {
            self._setInvitation(msg.RoomId);
        }

        reaction(() => self.onBattleGroupChanel, (value) => {
            if (self.notificationConnected) {
                if (self.showInvitations && value) {
                    customerNotificationConnection.on("SlotBattleMessage", battleInvitationCallback);
                } else {
                    customerNotificationConnection.off("SlotBattleMessage", battleInvitationCallback);
                }
            }
        })

        return {
            //INNER ACTIONS
            _setGeneralUserData(data) {
                self.customerGroup = data?.group;
                self.language = data.language;
                document.documentElement.lang = data.language;
                self.mobileUserAgent = data.mobile;
            },
            _onWSError(err) {
                if (
                    !['localhost', '-dev', '-int', '-stg'].find(el => window.location.href.includes(el)) &&
                    config(getRoot(self).site.siteName).withGoogleAnalytics
                ) {
                    ReactGA.event({
                        category: 'WS_error_' + window.location.href,
                        action: 'error_connection_user_' + self.customerId,
                        label: err,
                        value: 1,
                    });
                }
            },
            _setConnection() {
                webSocketApi.connect(self.sId, () => self._setOnBattleGroupChanel(false), self._onWSError).then(connection => {
                    customerNotificationConnection = connection;
                    customerNotificationConnection.on("SystemMessage", (res) => {
                        if (res.Message.includes(self.customerGroup) && res.MessageType === "AddToGroup") {
                            self._setOnBattleGroupChanel(true);
                        }
                    });
                    self.showInvitations && customerNotificationConnection.invoke("JoinSlotBattleGroup", self.sId, self.customerGroup).then();
                    self.setUserSentBattleInvite(!!getCookie('INVITED'));
                });
            },
            _setOnBattleGroupChanel(flag) {
                self.onBattleGroupChanel = flag;
            },
            _setInvitation(battleId) {
                if (!self.userBattleId) {
                    self.battleInvitations = [...self.battleInvitations, battleId];
                    self.getUserBattleData(self.battleInvitations[0]);
                }
            },
            _set(data) {
                self.acceptChanges = !!data.AcceptChanges;
                self.balance = data.Balance;
                self.credit = data.Credit;
                self.currency = data.Currency.toString();
                self.customerId = data.CustomerID;
                self.isBlocked = !!data.IsBlocked;
                self.username = data.Username.toString();
                self.canCashout = !!(+data.canCashout);
                self.customerGroup = data.CustomerGroup;
                self.messageCount = data.messageCount;
                self.sId = data.SID.toString();
                self.canPlaySlotBattles = !!data.canSlotBattle;
                self.showInvitations = !!data.isSlotBattleInvitationEnabled;
                self.setActiveBetsCount(data.ActiveBets);
                if (data.hasOwnProperty('RewardPoints')) {
                    self.rewardPoints = data.RewardPoints;
                } else {
                    self.rewardPoints = null;
                }
                if (data.BattleRoomId) {
                    self.userBattleId = data.BattleRoomId;
                    setCookie('USER_BATTLE_ID', data.BattleRoomId);
                    self.getUserBattleData();
                }

                self.isOldMarketsView = getCookie('sportMarketOldView') === 'true';
            },
            _setRefreshedUserData(data) {
                self.messageCount = data.messageCount;
                self.balance = data.balance;
                self.credit = data.credit;
                self.setActiveBetsCount(data.activeBets, true);
                if (data.hasOwnProperty('RewardPoints')) {
                    self.rewardPoints = data.RewardPoints;
                } else {
                    self.rewardPoints = null;
                }
            },
            _setGoogleQrCodeData(data) {
                self.googleQrCodeUserSecret = data.secret || self.googleQrCodeUserSecret;
                self.isGoogleAuthActive = data.state;
            },
            _setGoogleQrCodeLoading(flag) {
                self.isGoogleQrCodeLoading = flag;
            },
            _setGoogleAuthSettingLoading(flag) {
                self.isGoogleAuthSettingLoading = flag;
            },
            _loginSuccessHandler(response) {
                self._clearBannersFetchTimes();
                self._set(response.data);
                self.coupons.getCouponsList();
                self.resetRefreshDataInterval();
            },
            _clearUserInfo() {
                self.acceptChanges = null;
                self.balance = null;
                self.credit = null;
                self.currency = null;
                self.isBlocked = null;
                self.username = null;
                self.canCashout = true;
                self.customerId = null;
                self.sId = null;
                self.messageCount = null;
                self.userBattleId = null;
                self.showInvitations = null;
                self.battleInvitations = [];

                self.info = {};
                self.accountHistory = {};
                self.deposit = {};
                self.withdraws = {};
                self.withdrawStatus = {};
                self.accountReport = {};
                self.userBets = {};
                self.casinoReport = {};
                self.limits = {};
                self.rewardPoints = null;

                self.customerGroup = getRoot(self).site.defaultCustomerGroup;
                self.coupons.clearCouponsInfo();
                self.activeBetsCount = null;
                self.canPlaySlotBattles = true;
                self.userSentBattleInvite = false;

                clearTimeout(window.activeBetsTimeout);
                clearTimeout(window.betHistoryTimeout);

                self.battlePopup.close();
            },
            _clearBannersFetchTimes() {
                getRoot(self).home.resetFetchTimes();
                getRoot(self).slotsCasinoGames.resetFetchTime();
                getRoot(self).slotsBattles.banners.setFetchTime(null);
            },

            //EXTERNAL ACTIONS

            setMarketViewType(flag) {
                self.isOldMarketsView = flag;
                setCookie('sportMarketOldView', flag);
            },
            setPlayingGameId(id = null) {
                self.playingGameId = id;
            },
            setShowInvitations(flag) {
                self.showInvitations = flag;
            },
            toggleShowInvitations: flow(function* fetch() {
                return yield llattempt(
                    () =>
                        api.user
                            .showInvitations({isSbInvitationEnabled: self.showInvitations ? 0 : 1})
                            .then((response) => {
                                if (response.success) {
                                    self.setShowInvitations(!self.showInvitations);
                                }
                            })
                            .catch(() => {
                            }),
                    {
                        at: 'user.showInvitations',
                        withParams: {},
                        withNotifier: false,
                    }
                );
            }),
            setUserSentBattleInvite(flag) {
                self.userSentBattleInvite = flag;
                if (!flag) {
                    removeCookie('INVITED');
                } else {
                    setCookie('INVITED', true);
                }
            },
            sendInvite() {
                customerNotificationConnection
                && customerNotificationConnection.invoke("SendSlotBattleMessage", self.sId, self.customerGroup, self.userBattleId, Date.now())
                    .then(() => {
                        self.setUserSentBattleInvite(true);
                        self.collectActivity({
                            area: 'slot-battle-invitation',
                            itemId: self.customerId,
                            interactTarget: self.userBattleId
                        });
                    });
            },
            removeInvitation() {
                self.battleInvitations = [...self.battleInvitations].slice(1);
                if (self.battleInvitations.length) {
                    self.getUserBattleData(self.battleInvitations[0]);
                }
            },
            getSessionInfo: flow(function* fetch() {
                yield llattempt(
                    () =>
                        api.user
                            .getSession()
                            .then((response) => {
                                self._setGeneralUserData(response.data);
                                if (response.data.user) {
                                    self._set(response.data.user);
                                    self.coupons.getCouponsList();
                                }
                            })
                            .catch(() => {
                                getRoot(self).site.setGlobalError(true);
                            }),
                    {
                        at: 'user.getSession',
                        withParams: {},
                        withNotifier: false,
                        onError: () => {
                            getRoot(self).site.setGlobalError(true);
                        },
                    }
                );
            }),
            setUserBattle(res, invitation = false) {
                const popupData = {isActive: true};
                if (res) {
                    if (res.players.length) {
                        if (res.room_status !== 'completed') {
                            if (!invitation || res.players.find(player => player.Customer === self.username)) {
                                self.setUserBattleId(res.RoomUUID);
                                setCookie('USER_BATTLE_ID', res.RoomUUID);
                            }
                            clearTimeout(window._userBattleUpdateInterval);
                            window._userBattleUpdateInterval = setTimeout(() => {
                                self.setUserBattleUpdate(res.RoomUUID, invitation);
                            }, TIMERS.refreshBattleDataFetchTime);
                        } else {
                            removeCookie('USER_BATTLE_ID');
                            clearTimeout(window._userBattleUpdateInterval);
                        }
                        if (invitation && res.room_status === 'available') {
                            popupData.roomStatus = self.battleInvitations.length > 1 ? 'invitations' : 'invitation';
                        } else {
                            popupData.roomStatus = res.room_status;
                        }
                        popupData.connectedPlayers = res.ConnectedPlayersQty;
                        popupData.needPlayers = res.PlayersQty;

                        res.players.map(player => {
                            if (player.Customer === self.username) {
                                popupData.isPlayed = player.IsPlayed;
                                popupData.inPlay = player.InPlay;
                                popupData.userPrize = player.Prize;
                            }
                        })

                        popupData.userBattle = res;
                    } else {
                        popupData.roomStatus = 'canceled';
                        clearTimeout(window._userBattleUpdateInterval);
                    }
                } else {
                    popupData.roomStatus = 'error';
                }
                (invitation || (self.battlePopup?.whatWasChanged && self.battlePopup.whatWasChanged.includes('invitation'))) && ['canceled', 'started', 'error', 'completed'].includes(popupData.roomStatus)
                    ? self.battlePopup.close()
                    : self.battlePopup.isActive
                        ? self.battlePopup.update(popupData)
                        : self.battlePopup.set(popupData);
            },
            setUserBattleUpdate(id, invitation = false) {
                if (self.isLogged) {
                    if (self.battlePopup.whatWasChanged !== 'waitActualUpdate' || !self.playingGameId) {
                        const slotsBattles = getRoot(self).slotsBattles;
                        if (getRoot(self)?.site?.status.isActive && id && id !== slotsBattles.activeBattle?.RoomUUID) {
                            slotsBattles.getBattleInfo({roomId: id}).then(res => self.setUserBattle(res, invitation));
                        }
                    } else {
                        clearTimeout(window._userBattleUpdateInterval);
                        window._userBattleUpdateInterval = setTimeout(() => {
                            self.setUserBattleUpdate(id, invitation);
                        }, TIMERS.refreshBattleDataFetchTime);
                    }
                }
            },
            getUserBattleData(roomId = null) {
                const id = self.userBattleId || roomId;
                if (self.isLogged && id) {
                    getRoot(self).slotsBattles.getBattleInfo({roomId: id}).then(res => {
                        if (res) {
                            self.setUserBattle(res, !!roomId)
                        }
                    });
                }
            },
            setUserBattleId(id) {
                if (id) {
                    self.battleInvitations = [];
                }
                self.userBattleId = id;
            },
            setCouponsPanelView(view) {
                self.couponsPanelView = view;
            },
            setBTag(btag) {
                self.btag = btag;
            },
            setCustomerGroup(group) {
                self.customerGroup = group;
            },
            toggleAcceptChanges() {
                self.acceptChanges = !self.acceptChanges;
            },
            setAccountActivePage(page) {
                self.activeAccountPage = page;
            },
            setLanguage: flow(function* fetch({language}) {
                yield llattempt(
                    () =>
                        api.config
                            .changeLanguage({lang: language})
                            .then((response) => {
                                if (response.success) {
                                    window.location.reload(); //too much data comes with languages filled from DB... so simply just reload all the app
                                } else {
                                    llNotifier({
                                        message: response.data?.error ?? 'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                }
                            }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.changeLanguage',
                        withParams: {lang: language},
                    }
                );
            }),
            setActiveBetsCountWasForceUpdated(flag) {
                self.activeBetsCountWasForceUpdated = flag;
            },
            setActiveBetsCount(count, needMessage = false) {
                if (count !== 'undefined' && count !== null) {
                    //if (needMessage && self.activeBetsCount && self.activeBetsCount > count) { //Каан сказал пока не делать это
                    //забрать короткую инфу по сыгранным бетсам для попапа (столько-то ваших ставок сыграло с таким-то результатом)
                    // if (есть данные) {
                    // getRoot(self).site.popup.showNew(
                    //     popup с результатами
                    // );
                    // }
                    //}
                    if (!self.activeBetsCountWasForceUpdated) {
                        self.activeBetsCount = count < 100 ? count : 99;
                    } else {
                        self.activeBetsCountWasForceUpdated = false;
                    }
                }
            },
            resetRefreshDataInterval() {
                //self.dataRefresh(); //раскомментить если надо сразу же при логине получать новые попапы
                clearInterval(refreshDataInterval);
                refreshDataInterval = null;
                if (!refreshDataInterval && !getRoot(self).site.status.isGlobalError) {
                    refreshDataInterval = setInterval(() => {
                        self.dataRefresh();
                    }, TIMERS.refreshDataFetchTime);
                }
            },
            login: flow(function* fetch(data) {
                waitLoginData = true;
                return yield llattempt(
                    () =>
                        api.user.login(data).then((response) => {
                            if (response.success && response.data) {
                                getRoot(self).site.getConfig().then(() => self._loginSuccessHandler(response));
                            }
                            waitLoginData = false;
                            return response;
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.login',
                        withParams: data,
                    }
                );
            }),
            logout: flow(function* fetch(requestNeeded = true) {
                self.resetRefreshDataInterval();
                removeCookie('USER_BATTLE_ID');
                const root = getRoot(self);
                const successLogoutHandler = () => {
                    root.drawer.close();
                    root.modal.close();
                    self._clearBannersFetchTimes();
                    self._clearUserInfo();
                };
                if (requestNeeded) {
                    yield llattempt(
                        () =>
                            api.user.logout().then((response) => {
                                if (!response.success) {
                                    llNotifier({
                                        message: response.data?.error ?? 'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                } else {
                                    getRoot(self).site.getConfig().then(() => {
                                        successLogoutHandler();
                                        const activeMatch = getRoot(self)?.router.prematchRouterMatchInstance;
                                        if (activeMatch && getRoot(self).betting.isCustomBetsView) {
                                            activeMatch.setCustomBetsFetching(true);
                                            activeMatch.setLastCustomMarketGroupsFetchTime(null);
                                            activeMatch.initializeAllMarketsInstruction().then(() => {
                                                activeMatch.setCustomBetsFetching(false);
                                            });
                                        }
                                    });
                                }
                            }),
                        {
                            withNotifier: false,
                            at: 'user.logout',
                            withParams: {},
                        }
                    );
                } else {
                    successLogoutHandler();
                }
            }),
            authGoogle: flow(function* fetch(data) {
                return yield llattempt(
                    () =>
                        api.user.authGoogle(data).then((response) => {
                            if (response.success) {
                                self._loginSuccessHandler(response);
                            }
                            return response;
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.authGoogle',
                        withParams: data,
                    }
                );
            }),
            authSms: flow(function* fetch(data) {
                return yield llattempt(
                    () =>
                        api.user.authSms(data).then((response) => {
                            if (response.success) {
                                self._loginSuccessHandler(response);
                            }
                            return response;
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.authSms',
                        withParams: data,
                    }
                );
            }),
            getGoogleQrCode: flow(function* fetch(data) {
                if (getRoot(self).user.customerId) {
                    self._setGoogleQrCodeLoading(true);
                    return yield llattempt(
                        () =>
                            api.user.googleQrCode(data).then((response) => {
                                if (!response.success) {
                                    llNotifier({
                                        message:
                                            response.data?.error + '_QRCODE' ||
                                            'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                } else {
                                    self._setGoogleQrCodeData(response.data);
                                }
                                self._setGoogleQrCodeLoading(false);
                                return response;
                            }),
                        {
                            onError: (self) => self._setGoogleQrCodeLoading(false),
                            msg: 'GENERAL_ERROR',
                            at: 'user.getGoogleQrCode',
                            withParams: data,
                        }
                    );
                } else {
                    llNotifier({
                        message: 'LOGIN_NEEDED',
                        type: 'error',
                    });
                }
            }),
            setGoogleAuth: flow(function* fetch(data) {
                if (getRoot(self).user.customerId) {
                    self._setGoogleAuthSettingLoading(true);
                    return yield llattempt(
                        () =>
                            api.user.googleAuth(data).then((response) => {
                                if (response.success) {
                                    self._setGoogleQrCodeData({state: data.active});
                                    if (data.active) {
                                        llNotifier({
                                            message: 'TFA_ENABLED',
                                            type: 'success',
                                        });
                                    } else {
                                        llNotifier({
                                            message: 'TFA_DISABLED',
                                            type: 'success',
                                        });
                                    }
                                } else {
                                    llNotifier({
                                        message: response.data?.error || 'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                }
                                self._setGoogleAuthSettingLoading(false);
                                return response;
                            }),
                        {
                            msg: 'GENERAL_ERROR',
                            at: 'user.getGoogleAuth',
                            withParams: data,
                            onError: () => self._setGoogleAuthSettingLoading(false),
                        }
                    );
                } else {
                    llNotifier({
                        message: 'LOGIN_NEEDED',
                        type: 'error',
                    });
                }
            }),
            forgetPassword: flow(function* fetch(data) {
                return yield llattempt(
                    () =>
                        api.user.forgetPassword(data).then((response) => {
                            return response;
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.forgetPassword',
                        withParams: data,
                    }
                );
            }),
            resetPassword: flow(function* fetch(data) {
                return yield llattempt(
                    () =>
                        api.user
                            .resetPassword({
                                code: data.code,
                                new: data.password,
                                repeat: data.passwordRepeat,
                            })
                            .then((response) => {
                                return response;
                            }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.resetPassword',
                        withParams: {
                            code: data.code,
                            new: data.password,
                            repeat: data.passwordRepeat,
                        },
                    }
                );
            }),
            changePassword: flow(function* fetch(data) {
                return yield llattempt(
                    () =>
                        api.user
                            .changePassword({
                                old: data.oldPassword,
                                new: data.password,
                                repeat: data.passwordRepeat,
                            })
                            .then((response) => {
                                return response;
                            }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.changePassword',
                        withParams: {
                            old: data.oldPassword,
                            new: data.password,
                            repeat: data.passwordRepeat,
                        },
                    }
                );
            }),
            signup: flow(function* fetch(data) {
                return yield llattempt(
                    () =>
                        api.user.signupData({...data}).then((response) => {
                            return response;
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.signup',
                        withParams: data,
                    }
                );
            }),
            resendSms: flow(function* fetch() {
                return yield llattempt(
                    () => api.user.smsResend().then((response) => response),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.resendSms',
                        withParams: {},
                    }
                );
            }),
            dataRefresh: flow(function* fetch() {
                if (!waitLoginData) {
                    if (getRoot(self)?.site?.status.isActive) {
                        const pts = parseInt(getCookie('PTS')); //get last popup timestamp;
                        const dataObj = {popup_time: pts ? pts : 0};
                        return yield llattempt(
                            () =>
                                api.user
                                    .refreshData(dataObj)
                                    .then((response) => {
                                        if (response.success && !waitLoginData) {
                                            REFRESH_DATA_ERROR_COUNT = 0;
                                            removeCookie('Refresh_Data_Error');
                                            if (response.data.activeBattleRooms) {
                                                getRoot(self).slotsBattles.setActiveBattleRoomsCount(response.data.activeBattleRooms);
                                            } else {
                                                getRoot(self).slotsBattles.setActiveBattleRoomsCount(null);
                                            }
                                            if (response.data.hasOwnProperty('balance')) {
                                                self._setRefreshedUserData(response.data);
                                            } else if (
                                                (response.data.hasOwnProperty('popup')
                                                    && response.data.popup.hasOwnProperty('error')
                                                    && response.data.popup.error === "GENERAL_ERROR")
                                                || !response.data.hasOwnProperty('popup')
                                            ) {
                                                getRoot(self).site.setGlobalError(true);
                                                self.resetRefreshDataInterval();
                                            } else if (self.isLogged) {
                                                llNotifier({
                                                    message: 'You have been logged out',
                                                    type: 'error',
                                                });
                                                self.logout(false);
                                            }
                                            if (response?.data?.popup?.length) {
                                                getRoot(self).site.popup.showNew(
                                                    response.data.popup[0]
                                                );
                                            }
                                        } else if (!response || !response.success) {
                                            REFRESH_DATA_ERROR_COUNT++;
                                            if (REFRESH_DATA_ERROR_COUNT >= 6) {
                                                if (!getCookie('Refresh_Data_Error')) {
                                                    setCookie('Refresh_Data_Error', true);
                                                    getCookie('Refresh_Data_Error') && window.location.reload();
                                                } else {
                                                    getRoot(self).site.setGlobalError(true);
                                                    self.resetRefreshDataInterval();
                                                }
                                            }
                                        }
                                    }),
                            {
                                at: 'user.dataRefresh',
                                withParams: {},
                                withNotifier: false,
                                onError: () => {
                                    REFRESH_DATA_ERROR_COUNT++;
                                    if (REFRESH_DATA_ERROR_COUNT >= 6) {
                                        getRoot(self).site.setGlobalError(true, null, 'CONNECTION_ISSUES_ERROR');
                                        self.resetRefreshDataInterval();
                                    }
                                }
                            }
                        );
                    } else {
                        self.resetRefreshDataInterval();
                    }
                }
            }),
            openLogin({onCloseGoTo = null} = {}) {
                getRoot(self).site.status.viewSize !== 'desktop'
                    ? getRoot(self).drawer.open({
                        side: 'left',
                        currentKey: 'login-form-drawer',
                    })
                    : getRoot(self).modal.setModal({
                        currentKey: 'login-form',
                        customClasses: 'll-login-form-modal',
                        showCloseButton: true,
                        onCloseGoTo: onCloseGoTo
                    });
            },
            collectActivity({area, itemId, interactTarget = null, type = 'click'}) {
                let value;
                try {
                    value = parseInt(itemId); //ReactGA.event.value should be a number
                } catch {
                    value = 0
                }
                if (
                    !['localhost', '-dev', '-int', '-stg'].find(el => window.location.href.includes(el)) &&
                    config(getRoot(self).site.siteName).withGoogleAnalytics
                ) {
                    ReactGA.event({
                        category: area + '_' + window.location.href,
                        action: type,
                        label: interactTarget,
                        value,
                        nonInteraction: type !== 'click'
                    });
                }
            },
        };
    })
    .views((self) => {
        return {
            get isLogged() {
                return !!self.customerId;
            },
            get notificationConnected() {
                return customerNotificationConnection.state === "Connected";
            },
            get availableMoney() {
                return self.credit + self.balance;
            },
            get hasRewardSystem() {
                return self.rewardPoints !== null;
            },
        };
    });

export default User;