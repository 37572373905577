import React, {useState, useEffect} from 'react';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {inject, observer} from 'mobx-react';
import cx from "classnames";

import {LLPreloader} from '../../../../components';
import {trans} from '../../../../common/utils';

import config from "../../../../static/themes/config";
import CustomMarketGroup from "../common/custom-market-group";


const CustomBetsBuilder = ({store, matchInstance}) => {
    const
        setCustomBetsView = store.betting.setCustomBetsView,
        activeItems = store.betting.activeItems,
        siteName = store.site.siteName,
        viewSize = store.site.status.viewSize,
        language = store.user.language,
        isOldView = store.user.isOldMarketsView,
        setOldView = store.user.setMarketViewType;

    const [customBetsMarketGroups, setCustomBetsMarketGroups] = useState([]);
    const [helpVisible, setHelpVisibility] = useState(false); //TODO: Тут initValue був !getCookie('CustomReadmeChecked'), я прибрав. Якщо цей функціонал потрібен - поверну

    const type = 'pre';

    useEffect(() => {
        setCustomBetsView(true);
        matchInstance.setCustomBetsFetching(true);
        matchInstance.initializeAllMarketsInstruction().then(() => {
            const newGroups = matchInstance.customMarketGroupsList();
            if (newGroups?.length) {
                setCustomBetsMarketGroups(newGroups);
                activeItems.setActiveItem([newGroups[0].id], 'customMarketGroups');
            }
            matchInstance.setCustomBetsFetching(false);
        });
        return () => {
            setCustomBetsView(false);
            if (!activeItems.marketGroups.length) {
                matchInstance.initializeAllMarketsInstruction();
            } else {
                matchInstance.setLastCustomMarketGroupsFetchTime(null);
            }
        }
    }, []);

    /***************** ~~ Render ****************/
    return (
        <div className="ll-custom-bets">
            {matchInstance?.customBetsFetching && <div className="ll-custom-bets__loading">
                <LLPreloader/>
            </div>}
            <ClickAwayListener onClickAway={() => helpVisible && setHelpVisibility(false)}>
                <div className={cx(
                    'll-custom-bets__about-info-container',
                    `ll-custom-bets__about-info-container--${viewSize}`)}>
                    <div className="ll-custom-bets__about-info-button" onClick={(e) => {
                        setHelpVisibility(!helpVisible)
                        e.stopPropagation()
                    }}>
                        <i className="ll-custom-bets__about-info-button-icon doruk-bb-icon"/> Info
                    </div>
                    <div className={cx(
                        'll-custom-bets__about-info-content-container',
                        'll-custom-bets__about-info-content-container--' + (helpVisible ? 'show' : 'hidden'))}>

                        <p className="ll-custom-bets__about-info-text">{trans('BET_BUILDER_TEXT')}</p>
                        <i className="ll-custom-bets__about-info-close doruk-close"
                           onClick={() => setHelpVisibility(false)}/>
                        <a href={config(siteName).betBuilderTermsLinks[language]}
                           target='_blank' rel='noopener noreferrer'
                           className="ll-custom-bets__about-terms-link"
                        >
                            {trans('Terms & Conditions')}
                        </a>
                    </div>
                </div>
            </ClickAwayListener>
            {!customBetsMarketGroups.length ?
                !matchInstance.customBetsFetching ?
                    <div className="ll-custom-bets__no-bets">
                        <p>{trans('EMPTY_BETS_ERROR')}</p>
                    </div>
                    : null
                :
                activeItems?.customMarketGroups?.length && matchInstance?.customMarketGroups.get(activeItems.customMarketGroups[0]) &&
                customBetsMarketGroups.map((marketGroup, i) =>
                    <CustomMarketGroup
                        key={marketGroup.id}
                        marketGroup={marketGroup}
                        marketGroupIndex={i}
                        matchInstance={matchInstance}
                        viewSize={viewSize}
                        isOldView={isOldView}
                        setOldView={setOldView}
                    />
                )
            }
        </div>
    )
};

export default inject('store')(observer(CustomBetsBuilder));