
export const betsUpdateTime = {
    activeBets: {
        itemFetchTime: 60 * 1000,
    },
    betHistory: {
        cashoutUpdateTime: 5 * 1000,
        itemFetchTime: 60 * 1000,
    },
}

export const myAccountFetchTime = {
    info: 60 * 60 * 1000,
    deposit: 15 * 60 * 1000,
    withdraws: 15 * 60 * 1000,
    withdrawStatus: 15 * 60 * 1000,
    accountReport: 15 * 60 * 1000,
    accountHistory: 15 * 60 * 1000,
    casinoReport: 15 * 60 * 1000,
    limits: 15 * 60 * 1000,
    activeBets: 60 * 1000,
    activeBetsNormalUpdate: 10 * 1000,
    betHistory: 60 * 1000,
}

export const refreshDataFetchTime = 10 * 1000;
export const refreshBattleDataFetchTime = 5 * 1000;