const configAboutUs = {
    Default: {
        en: [
            'This casino is one of the fastest growing betting website with their variety of sports, amount of the bets and live bets, championship bets, casino and amount of the live casino games.',
            'Your information and processes are safe with their continuously renewed highly standard security systems.',
            'You can reach us whenever you want with mobile or live support.',
        ],
        tr: [
            'This casino is one of the fastest growing betting website with their variety of sports, amount of the bets and live bets, championship bets, casino and amount of the live casino games.',
            'Your information and processes are safe with their continuously renewed highly standard security systems.',
            'You can reach us whenever you want with mobile or live support.',
        ],
    },
    Bahissenin: {
        en: [
            'Bahissenin is one of the fastest growing betting website with their variety of sports, amount of the bets and live bets, championship bets, casino and amount of the live casino games.',
            'Your information and processes are safe with their continuously renewed highly standard security systems.',
            'You can reach us whenever you want with mobile or live support.',
        ],
        tr: [
            'Bahissenin, sunduğu spor dalı çeşitliliği, bahis ve canlı bahis sayısı, şampiyonluk bahisleri, slot makineleri ve canlı casino masa sayısı olarak, bahis piyasasının en hızlı büyüyen şirketlerinden biridir.',
            'Kullanıcı dostu arayüzü, kullanışlı mobil servisi, geniş tahsilat ve ödeme seçenekleri ile sizlere en iyi hizmeti vermeyi amaçlamaktadır.',
            'Sürekli olarak yenilenen, en yüksek standartlardaki güvenlik sistemleri ile bilgi ve işlemleriniz güven altındadır.',
            'Canlı yardım ve cep telefonlarınızdan bile kullanabileceğiniz mesaj servislerimiz ile istediğiniz anda bize ulaşabilirsiniz.',
        ],
    },
    Betlima: {
        en: [
            'Betlima is founded and being operated by a professional team who is experienced in international betting industry since 2001.',
            'Betlima is operating in many countries with local partners and also operating online in Europe and Middle East.',
        ],
        tr: [
            'Betlima, 2001 yılından beri bahis pazarında uluslararası deneyime sahip olan profesyonel bir ekip tarafından kurulmuş ve işletilmektedir.',
            'Birçok ülkede yerel ortakları aracılığı ile bahis kabul noktaları açmış ve halen işletmekte olan Betlima, diğer taraftan online ortam üzerinden tüm Avrupa ve Orta Asya’daki müşterilerine hizmet vermektedir.',
        ],
    },
    Ciprobet: {
        en: [
            'Ciprobet is founded and being operated by a professional team who is experienced in international betting industry since 2001.',
            'Ciprobet is operating in many countries with local partners and also operating online in Europe and Middle East.',
        ],
        tr: [
            'Ciprobet, 2001 yılından beri bahis pazarında uluslararası deneyime sahip olan profesyonel bir ekip tarafından kurulmuş ve işletilmektedir.',
            'Birçok ülkede yerel ortakları aracılığı ile bahis kabul noktaları açmış ve halen işletmekte olan Ciprobet, diğer taraftan online ortam üzerinden tüm Avrupa ve Orta Asya’daki müşterilerine hizmet vermektedir.',
        ],
    },
    Colonybet: {
        en: [
            'ColonyBet is one of the fastest growing betting website with their variety of sports, amount of the bets and live bets, championship bets, casino and amount of the live casino games.',
            'Your information and processes are safe with their continuously renewed highly standard security systems.',
            'You can reach us whenever you want with mobile or live support.',
        ],
        tr: [
            'Colonybet, 10 yılı aşkın süredir uluslararası online platformda, 2014’ten itibaren ise Türkiye piyasasına girmiş profesyonel kadrosu ile siz değerli eğlence sevenlere en hızlı, güvenilir ve kaliteli hizmet vermeyi amaçlamış bir kuruluştur.',
            'Colonybet, kullanışlı ara yüzü ve mobil versiyonunun yanı sıra birçok spor dalında gerek bahis seçenekleri gerek canlı bahis sayısı ile geniş bir yelpazeye sahiptir.',
            'Yüzlerce farklı slot oyunu ve en eğlenceli canlı casino masaları ile kullanıcılarına bulundukları ortamdan, farklı platformlar aracılığı ile casino heyecanı ve eğlencesi sunmayı amaçlayan Colonybet, tüm sunduğu ürün ve hizmetleri Luckyland Gaming güvenilirliği ve ayrıcalığı ile siz kullanıcılarına ulaştırmaktadır.',
            '7/24 deneyimli canlı destek kadrosu ile dilediğiniz zaman iletişime geçebilir sorularınıza cevap bulabilirsiniz.',
        ],
    },
    Efsanebahis: {
        en: [
            'EfsaneBahis is one of the fastest growing betting website with their variety of sports, amount of the bets and live bets, championship bets, casino and amount of the live casino games.',
            'Your information and processes are safe with their continuously renewed highly standard security systems.',
            'You can reach us whenever you want with mobile or live support.',
        ],
        tr: [
            'EfsaneBahis, uluslararası oyun alanında yıllardır hizmet vermekte olan ekibiyle; spor bahislerindeki ve canlı casino oyunlarındaki çeşitliliği ile öne çıkan bir bahis firmasıdır.',
            "EfsaneBahis 2004 yılından beri işinin ehli yönetim kadrosuyla 2017'nin ikinci çeyreğinde Türkiye pazarında hizmete açılmış bulunmaktadır.",
            'Tamamen oyuncu dostu olarak tabir edilebilecek arayüzü ve hızlı mobil servisi ile hizmet vermektedir.',
            'EfsaneBahis güçlü finans gücü ve çeşitli ödeme çeşitleriyle oyuncularımıza bir çok alternatif vermekle birlikte yatırım ve çekim işlemlerini de kolaylaştırmaktadır.',
            'Sizlerin tavsiyeleri ile güncellenen, oyuncunun isteği doğrultusunda yenilenen ve kaliteli Teknik desteği ile bilgilerinizin güvenliğini sağlayan bir kuruluştur.',
            '7/24 Canlı Destek hattımızdan bilgi alabilir, isteğiniz doğrultusunda telefon ile de bağlantı kurabilirsiniz. Bizlere kolayca ulaşabilmeniz bizim için çok önemlidir.',
        ],
    },
    Maksatbahis: {
        en: [
            'MaksatBahis is one of the fastest growing betting website with their variety of sports, amount of the bets and live bets, championship bets, casino and amount of the live casino games.',
            'Your information and processes are safe with their continuously renewed highly standard security systems.',
            'You can reach us whenever you want with mobile or live support.',
        ],
        tr: [
            'MaksatBahis, sunduğu spor dalı çeşitliliği, bahis ve canlı bahis sayısı, şampiyonluk bahisleri, slot makineleri ve canlı casino masa sayısı olarak, bahis piyasasının en hızlı büyüyen şirketlerinden biridir.',
            'Kullanıcı dostu arayüzü, kullanışlı mobil servisi, geniş tahsilat ve ödeme seçenekleri ile sizlere en iyi hizmeti vermeyi amaçlamaktadır.',
            'Sürekli olarak yenilenen, en yüksek standartlardaki güvenlik sistemleri ile bilgi ve işlemleriniz güven altındadır.',
            'Canlı yardım ve cep telefonlarınızdan bile kullanabileceğiniz mesaj servislerimiz ile istediğiniz anda bize ulaşabilirsiniz.',
        ],
    },
    Retrobet: {
        en: [
            'RetroBet is one of the fastest growing betting website with their variety of sports, amount of the bets and live bets, championship bets, casino and amount of the live casino games.',
            'Your information and processes are safe with their continuously renewed highly standard security systems.',
            'You can reach us whenever you want with mobile or live support.',
        ],
        tr: [
            'RetroBet.com online gaming sektöründe uzun yıllar çalışmış deneyimli bir kadro ile hizmete başlayan, müşterilerine en iyi hizmeti sunmayı amaçlayan bahis ve eğlence platformudur.',
            'RetroBet; Spor Bahisleri, Canlı Bahisler, Online Casino Oyunları, Online Slot Oyunları, Online Poker başta olmak üzere birçok oyun seçenekleri ile hizmet sunmaktadır.',
            'RetroBet olarak üyelerimize evlerinin konforunda hizmet vermek için kullanışlı, adil, denetlenen ve güvenli bir ortamda yüksek kalitede oyun servisleri sunmaktayız. Bu vizyon altında hizmet veren',
            'Canlı Destek ekibimize 7/24 bağlanarak her türlü sorunuza cevap bulabilirsiniz.',
            'Aklınıza takılan her türlü soru veya sorununuz için info@retrobet.com adresine mail atabilirsiniz.',
        ],
    },
    'Show Bahis': {
        en: [
            'Show Bahis is founded and being operated by a professional team who is experienced in international betting industry since 2001.',
            'Show Bahis is operating in many countries with local partners and also operating online in Europe and Middle East.',
        ],
        tr: [
            'Show Bahis markası 18 yaş ve üzeri yetişkinlere çevrimiçi oyun hizmeti vermektedir.',
            'Show Bahis, sunduğu spor dalı çeşitliliği, bahis ve canlı bahis sayısı, şampiyonluk bahisleri, slot makineleri ve canlı casino masa sayısı olarak, bahis piyasasının en hızlı büyüyen şirketlerinden biridir.',
            'Show Bahis‘i diğer bahis firmalarından ayıran birçok özellik bulunmaktadır. Bunlar profesyonellik, doğruluk ve güvenilirliktir. Güvenilir ve kolay ödeme yöntemleri, işinin ehli Müşteri Destek Ekibimiz ve uluslararası tecrübeye sahip uzmanlardan oluşan kadromuz ile hizmet sunmaktayız. Canlı bahis ve spor bahislerinde rekabete dayanan oranlar, çeşitli oyunlar ile oynamaktan keyif alabileceğiniz Casino oyunlar sunulmaktadır.',
            'Tüm oyuncularımızın güvenliği bizim için önemlidir. Bunun için konusunda eğitim görmüş, uzman casino ve bahis çalışanları ile adil ve profesyonel bir oyun ortamını garantileriz.',
            'Show Bahis’ in misyonu şu şekilde özetlenebilir: “maksimum eğlence, maksimum kalite ve maksimum hizmet”. Canlı casinomuz, aynı zamanda hem canlı, hem etkileşimli hem de güvenli bir casino sunabilmek amacıyla en son teknolojiler kullanılarak geliştirildi. Oyuncularımızın evlerinin konforunu geride bırakmadan canlı krupiye ve oyuncularla gerçek bir casino tecrübesi yaşamalarını istiyoruz.',
            'Kullanıcı dostu ara yüzü, kullanışlı mobil servisi, geniş tahsilat ve ödeme seçenekleri ile sizlere en iyi hizmeti vermeyi amaçlamaktadır.',
            'Sürekli olarak yenilenen, en yüksek standartlardaki güvenlik sistemleri ile bilgi ve işlemleriniz güven altındadır.',
            'Canlı yardım ve cep telefonlarınızdan bile kullanabileceğiniz mesaj servislerimiz ile istediğiniz anda bize ulaşabilirsiniz. Her zaman en iyi hizmeti verme konusunda kararlıyız. Hizmetimiz şeffaftır ve oyuncular istedikleri an destek takımlarımızdan birine, günün 24 saati erişebilirler.',
        ],
    },
    BetPapel: {
        en: [
            'Betpapel is founded and being operated by a professional team who is experienced in international betting industry since 2001.',
            'Betpapel is operating in many countries with local partners and also operating online in Europe and Middle East.',
        ],
        tr: [
            'Betpapel, 2001 yılından beri bahis pazarında uluslararası deneyime sahip olan profesyonel bir ekip tarafından kurulmuş ve işletilmektedir.',
            'Birçok ülkede yerel ortakları aracılığı ile bahis kabul noktaları açmış ve halen işletmekte olan Betpapel, diğer taraftan online ortam üzerinden tüm Avrupa ve Orta Asya’daki müşterilerine hizmet vermektedir.',
        ],
    },
};

export default configAboutUs;
