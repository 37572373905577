import ciprobetConfig from './ciprobet/configs/config';
import efsanebahisConfig from './efsanebahis/configs/config';
import jumpoddsConfig from './jumpodds/configs/config';
import maksatbahisConfig from './maksatbahis/configs/config';
import bahisseninConfig from './bahissenin/configs/config';
import retrobetConfig from './retrobet/configs/config';
import colonybetConfig from './colonybet/configs/config';
import showbahisConfig from './showbahis/configs/config';
import betlimaConfig from './betlima/configs/config';
import danobetConfig from './danobet/configs/config';
import betaestasConfig from './betaestas/configs/config';

const configs = {
    ciprobet: ciprobetConfig,
    efsanebahis: efsanebahisConfig,
    jumpodds: jumpoddsConfig,
    maksatbahis: maksatbahisConfig,
    bahissenin: bahisseninConfig,
    retrobet: retrobetConfig,
    colonybet: colonybetConfig,
    'show bahis': showbahisConfig,
    betlima: betlimaConfig,
    danobet: danobetConfig,
    betaestas: betaestasConfig,
};

const config = (siteName) => configs[siteName.toLowerCase()] || {};

export default config;