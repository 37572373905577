const tr = {
    ABD: 'US',
    'ABD Uzak Adaları': 'UM',
    'ABD Virjin Adaları': 'VI',
    Afganistan: 'AF',
    Almanya: 'DE',
    'Amerikan Samoası': 'AS',
    Andorra: 'AD',
    Angola: 'AO',
    Anguilla: 'AI',
    Antarktika: 'AQ',
    'Antigua ve Barbuda': 'AG',
    Arjantin: 'AR',
    Arnavutluk: 'AL',
    Aruba: 'AW',
    Avustralya: 'AU',
    Avusturya: 'AT',
    Azerbaycan: 'AZ',
    Bahamalar: 'BS',
    Bahreyn: 'BH',
    Bangladeş: 'BD',
    Barbados: 'BB',
    'Batı Sahara': 'EH',
    Belize: 'BZ',
    Belçika: 'BE',
    Benin: 'BJ',
    Bermuda: 'BM',
    'Beyaz Rusya': 'BY',
    BAE: 'AE',
    İngiltere: 'GB',
    İskoçya: 'GB-SCT',
    Bolivya: 'BO',
    'Bosna Hersek': 'BA',
    Botsvana: 'BW',
    'Bouvet Adası': 'BV',
    Brezilya: 'BR',
    'Britanya Hint Okyanusu Toprakları': 'IO',
    'Britanya Virjin Adaları': 'VG',
    Brunei: 'BN',
    Bulgaristan: 'BG',
    'Burkina Faso': 'BF',
    Burundi: 'BI',
    Butan: 'BT',
    'Cape Verde': 'CV',
    'Cayman Adaları': 'KY',
    Cebelitarık: 'GI',
    Cezayir: 'DZ',
    'Christmas Adası': 'CX',
    Cibuti: 'DJ',
    'Cocos (Keeling) Adaları': 'CC',
    'Cook Adaları': 'CK',
    Curaçao: 'CW',
    Danimarka: 'DK',
    'Dominik Cumhuriyeti': 'DO',
    Dominika: 'DM',
    Ekvador: 'EC',
    'Ekvator Ginesi': 'GQ',
    'El Salvador': 'SV',
    Endonezya: 'ID',
    Eritre: 'ER',
    Ermenistan: 'AM',
    Estonya: 'EE',
    Etiyopya: 'ET',
    'Falkland Adaları': 'FK',
    'Faroe Adaları': 'FO',
    Fas: 'MA',
    Fiji: 'FJ',
    'Fildişi Sahili': 'CI',
    Filipinler: 'PH',
    Filistin: 'PS',
    Finlandiya: 'FI',
    Fransa: 'FR',
    'Fransız Guyanası': 'GF',
    'Fransız Güney Toprakları': 'TF',
    'Fransız Polinezyası': 'PF',
    Gabon: 'GA',
    Gambiya: 'GM',
    Gana: 'GH',
    Gine: 'GN',
    'Gine-Bissau': 'GW',
    Grenada: 'GD',
    Grönland: 'GL',
    Guadalupe: 'GP',
    Guam: 'GU',
    Guatemala: 'GT',
    Guernsey: 'GG',
    Guyana: 'GY',
    'Güney Afrika': 'ZA',
    'Güney Georgia ve Güney Sandwich Adaları': 'GS',
    'Güney Kore': 'KR',
    'Kıbrıs Rum Kesimi': 'CY',
    'Güney Sudan': 'SS',
    Gürcistan: 'GE',
    Haiti: 'HT',
    'Heard Adası ve McDonald Adaları': 'HM',
    Hindistan: 'IN',
    Hollanda: 'NL',
    Honduras: 'HN',
    Hırvatístan: 'HR',
    Irak: 'IQ',
    Jamaika: 'JM',
    Japonya: 'JP',
    Jersey: 'JE',
    Kamboçya: 'KH',
    Kamerun: 'CM',
    Kanada: 'CA',
    Karadağ: 'ME',
    'Karayip Hollanda': 'BQ',
    Katar: 'QA',
    Kazakistan: 'KZ',
    Kenya: 'KE',
    Kiribati: 'KI',
    Kolombiya: 'CO',
    Komorlar: 'KM',
    'Kongo - Brazavil': 'CG',
    'Kongo - Kinşasa': 'CD',
    Kosova: 'XK',
    'Kosta Rika': 'CR',
    Kuveyt: 'KW',
    'Kore Cumhuriyeti': 'KP',
    'Kuzey Mariana Adaları': 'MP',
    'Kuzey Irlanda': 'GB-NIR',
    Küba: 'CU',
    Kırgızistan: 'KG',
    Laos: 'LA',
    Lesoto: 'LS',
    Letonya: 'LV',
    Liberya: 'LR',
    Libya: 'LY',
    Liechtenstein: 'LI',
    Litvanya: 'LT',
    Lübnan: 'LB',
    Lüksemburg: 'LU',
    Macaristan: 'HU',
    Madagaskar: 'MG',
    Makedonya: 'MK',
    Malavi: 'MW',
    Maldivler: 'MV',
    Malezya: 'MY',
    Mali: 'ML',
    Malta: 'MT',
    'Man Adası': 'IM',
    'Marshall Adaları': 'MH',
    Martinik: 'MQ',
    Mauritius: 'MU',
    Mayotte: 'YT',
    Meksika: 'MX',
    Mikronezya: 'FM',
    Moldova: 'MD',
    Monako: 'MC',
    Montserrat: 'MS',
    Moritanya: 'MR',
    Mozambik: 'MZ',
    Moğolistan: 'MN',
    'Myanmar (Burma)': 'MM',
    Mısır: 'EG',
    Namibya: 'NA',
    Nauru: 'NR',
    Nepal: 'NP',
    Nijer: 'NE',
    Nijerya: 'NG',
    Nikaragua: 'NI',
    Niue: 'NU',
    'Norfolk Adası': 'NF',
    Norveç: 'NO',
    'Orta Afrika Cumhuriyeti': 'CF',
    Pakistan: 'PK',
    Palau: 'PW',
    Panama: 'PA',
    'Papua Yeni Gine': 'PG',
    Paraguay: 'PY',
    Peru: 'PE',
    'Pitcairn Adaları': 'PN',
    Polonya: 'PL',
    Portekiz: 'PT',
    'Porto Riko': 'PR',
    Romanya: 'RO',
    Ruanda: 'RW',
    Rusya: 'RU',
    Réunion: 'RE',
    'Saint Barthelemy': 'BL',
    'Saint Helena': 'SH',
    'Saint Kitts ve Nevis': 'KN',
    'Saint Lucia': 'LC',
    'Saint Martin': 'MF',
    'Saint Pierre ve Miquelon': 'PM',
    'Saint Vincent ve Grenadinler': 'VC',
    Samoa: 'WS',
    'San Marino': 'SM',
    Senegal: 'SN',
    Seyşeller: 'SC',
    'Sierra Leone': 'SL',
    Singapur: 'SG',
    'Sint Maarten': 'SX',
    Slovakya: 'SK',
    Slovenya: 'SI',
    'Solomon Adaları': 'SB',
    Somali: 'SO',
    'Sri Lanka': 'LK',
    Sudan: 'SD',
    Surinam: 'SR',
    Suriye: 'SY',
    'Suudi Arabistan': 'SA',
    'Svalbard ve Jan Mayen Adaları': 'SJ',
    Svaziland: 'SZ',
    'São Tomé ve Príncipe': 'ST',
    Sırbistan: 'RS',
    Tacikistan: 'TJ',
    Tanzanya: 'TZ',
    Tayland: 'TH',
    Tayvan: 'TW',
    'Timor-Leste': 'TL',
    Togo: 'TG',
    Tokelau: 'TK',
    Tonga: 'TO',
    'Trinidad ve Tobago': 'TT',
    Tunus: 'TN',
    'Turks ve Caicos Adaları': 'TC',
    Tuvalu: 'TV',
    Türkiye: 'TR',
    Türkmenistan: 'TM',
    Uganda: 'UG',
    Ukrayna: 'UA',
    Umman: 'OM',
    Uruguay: 'UY',
    Vanuatu: 'VU',
    Vatikan: 'VA',
    Venezuela: 'VE',
    Vietnam: 'VN',
    'Wallis ve Futuna Adaları': 'WF',
    Yemen: 'YE',
    'Yeni Kaledonya': 'NC',
    'Yeni Zelanda': 'NZ',
    Yunanistan: 'GR',
    Zambiya: 'ZM',
    Zimbabve: 'ZW',
    'Åland Adaları': 'AX',
    Çad: 'TD',
    'Çek Cumhuriyeti': 'CZ',
    Çin: 'CN',
    'Hong Kong': 'HK',
    'Çin Makao ÖYB': 'MO',
    Özbekistan: 'UZ',
    Ürdün: 'JO',
    İran: 'IR',
    İrlanda: 'IE',
    İspanya: 'ES',
    İsrail: 'IL',
    İsveç: 'SE',
    İsviçre: 'CH',
    İtalya: 'IT',
    İzlanda: 'IS',
    Şili: 'CL',
    //CUSTOM ADDED
    Uluslararası: 'INTERNATIONAL',
    'Uluslararası Kulüpler': 'INTERNATIONAL',
};

export default tr;

// "Wales": "GB-WLS",
