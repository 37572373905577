import React, { useEffect, useRef, useState } from 'react';

import { withCommon } from '../../HOCs';


const LLScrollLoader = withCommon({
    store: ({ store }) => ({
        mobileUserAgent: store.user.mobileUserAgent,
        currentScrollPosition: store.scrollBoxes.getScrollBox('main-scroll')?.scrollPosition,
    }),
})(({ children, pageSize, loadingCallback, currentScrollPosition, mobileUserAgent }) => {
    const [currentPageSize, setCurrentPageSize] = useState(0);
    const [reachScrollAnchor, setReachScrollAnchor] = useState(false);

    const ref = useRef();

    const scrollHandler = () => {
        const anchorPos = ref.current.getBoundingClientRect().top - window.innerHeight;
        if (anchorPos <= 0) {
            setReachScrollAnchor(true);
        } else {
            setReachScrollAnchor(false);
        }
    };

    useEffect(() => {
        scrollHandler();
    }, [currentScrollPosition]);

    useEffect(() => {
        if (reachScrollAnchor && currentPageSize !== pageSize) {
            loadingCallback();
            setCurrentPageSize(pageSize);
        }
    }, [reachScrollAnchor]);

    useEffect(() => {
        mobileUserAgent && document.addEventListener('scroll', scrollHandler);
        return () => document.removeEventListener('scroll', scrollHandler);
    }, []);

    return children ? (
        <>
            {children}
            <div ref={ref} className="ll-scroll-anchor" style={{alignSelf: 'flex-end'}} />
        </>
    ) : null;
});

export default LLScrollLoader;
