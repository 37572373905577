import React from 'react';
import Switch from '@mui/material/Switch';
import cx from 'classnames';

import {LiveSearch} from '../../components';


const MobileLiveFilters = ({
    liveMatches,
}) => {
    return (
        <div className="live-filters--mobile">
            <div className="live-filters__inner">
                <div
                    className={cx(
                        'js_sport-filters__search',
                        'live-filters__search',
                    )}
                >
                    <LiveSearch view="filters" isCalendarView={liveMatches.isCalendar} />
                </div>
                <div
                    className={cx(
                        'js_sport-filters__view',
                        'live-filters__switcher',
                    )}
                >
                    <i
                        className={`live-filters__switcher-item doruk-list ${
                            !liveMatches.isCalendar ? 
                                'live-filters__switcher-item--active' : ''
                        }`}
                    />
                    <Switch
                        classes={{
                            root: 'status-filter-switcher',
                            thumb: 'status-filter-switcher-thumb',
                            track: 'status-filter-switcher-track',
                            switchBase: 'status-filter-switcher-switch-base'
                        }}
                        checked={liveMatches.isCalendar}
                        onChange={(e) =>
                            liveMatches.setCalendar(!liveMatches.isCalendar)
                        }
                    />
                    <i
                        className={`live-filters__switcher-item doruk-calendar ${
                            liveMatches.isCalendar ? 
                                'live-filters__switcher-item--active' : ''
                        }`}
                    />
                </div>
            </div>
        </div>
    );
}

export default MobileLiveFilters;