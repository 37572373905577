import React from 'react';

import { trans } from '../../../../../common/utils';
import { LLSportBettingButtonWrapper } from '../../../../../components';
import crutchDanoHideBets from '../../../components/crutches/danoHideBets';

const renderOpenDetailBtn = ({ match }) => {
    const mainMarket = match.mainMarket();
    const betsList = mainMarket?.betsList ?? [];

    const detailsBtnClass =
        !betsList.length || !betsList.find((bet) => bet.outcomeValue > 1)
            ? 'live-left-menu__open-detail--options'
            : 'live-left-menu__open-detail';

    const detailsBtnContent = '+' + match.oddsCount;

    return <div className={detailsBtnClass}>{detailsBtnContent}</div>;
};

const BetsList = ({ match, siteName }) => {
    const mainMarket = match.mainMarket();
    const betsList = mainMarket?.betsList ?? [];

    if (
        ((!betsList.length || !betsList.find((bet) => bet.outcomeValue > 1)) &&
        !match.oddsCount) || crutchDanoHideBets(siteName, match)
    ) {
        return <div className="live-left-menu__no-options">{trans('No options')}</div>;
    }

    if (!betsList.length || !betsList.find((bet) => bet.outcomeValue > 1)) {
        return renderOpenDetailBtn({ match });
    }

    return (
        <>
            <div className="live-left-menu__bets">
                {mainMarket?.betsList.map((bet) => (
                    <LLSportBettingButtonWrapper
                        type={'live'}
                        viewType="overview"
                        bet={bet}
                        key={bet.id}
                        columnCount={mainMarket?.columnCount}
                        nameAppearance={false}
                        className="live-left-menu__bet"
                    />
                ))}
            </div>
            {renderOpenDetailBtn({ match })}
        </>
    );
};

export default BetsList;
