import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import { arSA, enUS, tr } from 'date-fns/locale';

import { withCommon } from '../../';

/**
 * @file Represents React Component to render Date Picker
 *
 * @param {string} icon - icon to show inside input field
 * @param {string} className - className of input field
 * @param {string} calendarClassName - className of calendar dropdown container
 * @param {boolean} withPortal - is show dropdown in portal
 * @param {function} onBlur - callback onBlur dropdown container
 *
 * @author A.Huskova
 */

const LLDatePicker = withCommon({
    store: ({ store }) => ({
        mobileUserAgent: store.user.mobileUserAgent,
        viewSize: store.site.status.viewSize,
        language: store.user.language,
    }),
    propTypes: {
        icon: PropTypes.string,
        className: PropTypes.string,
        calendarClassName: PropTypes.string,
        withPortal: PropTypes.bool,
        onBlur: PropTypes.func,
    },
    defaultName: 'LLDatePicker',
    isObserver: true,
})(({
    children,
    icon,
    className,
    calendarClassName,
    withPortal,
    onBlur,
    mobileUserAgent,
    viewSize,
    language,
    ...restProps
}) => {
    useEffect(() => {
        if (mobileUserAgent) {
            document.querySelectorAll('.react-datepicker__input-container input')
                .forEach(input => input.setAttribute('inputmode', 'none'))
        }
    }, []);

    const pickerConfig = {
        dateFormat: !!restProps ? restProps.dateFormat : 'dd-MM-yyyy',
        showMonthDropdown: true,
        showYearDropdown: true,
        dropdownMode: 'select',
        className: cx('ll-date-picker__input', className),
        calendarClassName: cx('ll-date-picker__picker', calendarClassName),

        /**
         * @crutch
         *
         * @desc - EventListener below because passive touch events can't be default prevented.
         *         Remove when authors of date-picker fix this problem in their lib
         * @author A.Huskova
         */
        onCalendarOpen: () => {
            if (withPortal) {
                document
                    .querySelector('.react-datepicker__portal')
                    .addEventListener(
                        'touchstart',
                        (e) => {
                            if (e.target.classList.contains('react-datepicker__portal')) {
                                e.stopPropagation();
                            }
                        },
                        { passive: false, once: true }
                    );
            }
        },
    };

    /**** ~~ Import date translates for different languages ****/
    const locale = {
        en: { name: 'en-GB', file: enUS },
        tr: { name: 'tr', file: tr },
        aa: { name: 'ar-SA', file: arSA },
    };
    registerLocale(locale[language].name, locale[language].file);

    return (
        <div className={`ll-date-picker--${viewSize === 'desktop' ? 'desktop' : 'mobile'}`}>
            {children}
            <DatePicker
                onClickOutside={onBlur}
                withPortal={withPortal}
                locale={locale[language].name}
                {...pickerConfig}
                {...restProps}
            />
            { !!icon &&
                <i className={`ll-date-picker__icon ${icon}`} />
            }
        </div>
    );
});

export default LLDatePicker;