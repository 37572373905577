import React from 'react';

import {withCommon} from '../../../../components';

const MobileAccountTitle = withCommon({
    store: ({store}) => ({
        open: store.drawer.open,
        cameFromDrawer: store.router.cameFromDrawer,
        goBack: store.router.goBack,
    }),
})(({open, name, cameFromDrawer, goBack}) => (
    <div className="my-account__title--mobile">
        <i className="my-account__title-back-icon doruk-mg-arrow-2" onClick={() => {
            if (!cameFromDrawer) {
                goBack();
            } else {
                open({
                    side: 'left',
                    currentKey: 'my-account-menu-drawer',
                });
            }
        }}/>
        <p>{name}</p>
    </div>
));

export default MobileAccountTitle;