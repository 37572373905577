/***************** ~~  ***************
 * @desc -  Uttil that used to provide data attributes with data need to devalopment
 * @param {object} props
 * @param {object} props.data - object of data attributes
 * @param {JSX.Element} props.component - component that shows only in dev env
 */

const devInfo = ({ data }) => {
    var currentEnvironment =
        typeof process.env.NODE_ENV == 'string'
            ? process.env.NODE_ENV.toLowerCase()
            : null;

    const defineDataAttributes = Object.keys(data).reduce((a, v) => {
        const dataElement = data[v];
        if (/data-/.test(v)) {
            return { ...a, [v]: dataElement };
        }
        return a;
    }, {});

    if (currentEnvironment === 'development') {
        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }

        const id = uuidv4();

        setTimeout(() => {
            var debugElement = document.querySelector(`[data-debug-id="${id}"]`);

            function createTooltip(tips) {
                function setAttributes(el, attrs) {
                    Object.keys(attrs).forEach((key) =>
                        el.setAttribute(key, attrs[key])
                    );
                }

                var newTextNode = document.createTextNode(tips);
                var title = document.createTextNode('Debug info ');

                var newListItem = document.createElement('pre');

                setAttributes(newListItem, {
                    style:
                        'position:absolute; top:100%; border: 1px solid blue; max-height:400px; z-index:999; background-color:rgba(32, 194, 14, 0.7); overflow:scroll; width:100%; color:black',
                    class: 'debug-tooltip',
                });

                newListItem.appendChild(title);
                newListItem.appendChild(newTextNode);

                debugElement.appendChild(newListItem);
            }

            function removeTooltip() {
                [...document.getElementsByClassName('debug-tooltip')].map((element) =>
                    element.remove()
                );
            }

            if (debugElement) {
                debugElement.onmouseenter = function (e) {
                    removeTooltip();
                    if (e.shiftKey === true) {
                        createTooltip(JSON.stringify({ ...data, id }, null, 2));
                    }
                };

                debugElement.onmouseleave = function () {
                    removeTooltip();
                };
            }
        }, 1000);

        return { ...defineDataAttributes, 'data-debug-id': id };
    } else {
        return {};
    }
};

export default devInfo;
