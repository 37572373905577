import { types, flow } from 'mobx-state-tree';

import api from '../../../common/api';
import { TIMERS } from '../../config';
import { toCamel } from '../../../common/utils';
import { llNotifier } from '../../../components';


const LobbyItem = types.model('LobbyItem', {
    backgroundColor: types.maybeNull(types.string),
    buttonText: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    labelText: types.maybeNull(types.string),
    lobbyId: types.maybeNull(types.number), 
    name: types.maybeNull(types.string),
    providerId: types.maybeNull(types.number),
    sortOrder: types.maybeNull(types.number),
    textColor: types.maybeNull(types.string),
}).volatile((self) => ({
    settings: null,
})).actions((self) => ({
    setSettings(data){
        self.settings = data;
    }
}));

const Lobbies = types
    .model('Lobbies', {
        items: types.array(LobbyItem),
        providerId: types.maybeNull(types.number),
        fetchTime: types.maybeNull(types.number),
        loading: true,
    })
    .actions((self) => ({
        _set(lobbyData) {
            self.items = [];
            lobbyData.sort((a, b) => a.sortOrder - b.sortOrder).map((item) => {
                const newLobby = LobbyItem.create({
                    backgroundColor: item.backgroundColor,
                    buttonText: item.buttonText,
                    image: item.image,
                    labelText: item.labelText,
                    lobbyId: item.lobbyId,
                    name: item.name,
                    providerId: item.providerId,
                    sortOrder: item.sortOrder,
                    textColor: item.textColor,
                });
                self.items.push(newLobby);
                newLobby.setSettings(item.settings ? JSON.parse(item.settings) : null);
            })
            self.fetchTime = Date.now();
        },
        setProviderId(providerId) {
            self.providerId = providerId;
        },
        getLobbies: flow(function* fetch() {
            // Stop request if 'fetchTime' exist (not null) and TIMEOUT do not left
            // 'fetchTime' will be create after first request for lobbies data
            if (self.fetchTime && self.fetchTime + TIMERS.lobbyFetchTimer < Date.now()) return;

            try {
                const res = yield api.games.getLobbies() || {};
                if (res.success) {
                    self._set(toCamel(res.data));
                }
            } catch (err) {
                self.fetchTime = null;
                llNotifier({
                    message: 'GENERAL_ERROR',
                    type: 'error',
                });
            } finally {
                self.loading = false;
            }
        }),
    }));

export default Lobbies;
