import { types } from "mobx-state-tree";

import {vendorTextReplacer} from '../../../common/utils';


const SocialIcon = types
    .model('SocialIcon', {
        url: types.string,
        imgDesktop: types.maybeNull(types.string),
        imgMobile: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
    })

const SocialIcons = types
    .model('SocialIcons', {
        lastFetchTime: types.maybeNull(types.Date),
        isFetching: true,
        icons: types.array(SocialIcon),
    })
    .actions((self) => {
        return {
            setIcons(data) {
                self.icons = [];
                if (data && Array.isArray(data)) {
                    data = data.sort((a, b) => a.order - b.order);
                    data.map((icon) => self.icons.push(
                        SocialIcon.create({
                            url: vendorTextReplacer(icon.RedirectUrl.toString()),
                            imgDesktop: vendorTextReplacer(icon.imgDesktop.toString()),
                            imgMobile: vendorTextReplacer(icon.imgMobile.toString()),
                            name: vendorTextReplacer(icon.name.toString()),
                        })
                    ))
                }
            }
        }
    })

export default SocialIcons;