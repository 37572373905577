import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {LLPreloader, WithPopover} from '../../../components';
import {getS3ImageUrl, imageLoading, trans} from '../../../common/utils';
import urls from '../../../configs/urls';


export const GameImgLoader = inject('store')(observer((
    {
        item,
        store,
        customClasses,
    }
) => {
    const [loading, setLoading] = useState(true);
    const [itemHasImg, setItemHasImg] = useState(false);
    const imgUrl = `${getS3ImageUrl()}/images/thumbnails/${item.thumbnail}`;

    let bananaDefender = true;
    const bananaDefenderCurrentState = () => bananaDefender;

    useEffect(() => {
        imageLoading(imgUrl).then((res) => {
            if (bananaDefenderCurrentState()) {
                setItemHasImg(res);
                setLoading(false);
            }
        });
        return () => bananaDefender = false;
    }, []);

    const provider = store.slotsBattles.getProvider('slotBattles', item.BrandName);

    return (
        <div className="game-img">
            {loading ? (
                    <div className="game-img__loading-wrp">
                        <LLPreloader className="game-img__loading" height={20} width={20}/>
                    </div>
                ) :
                <div style={{
                    backgroundImage: itemHasImg
                        ? `url(${imgUrl})`
                        : '',
                }}
                     className={cx(`game-img__wrp`, customClasses,
                         !itemHasImg && 'game-img__wrp--without-img'
                     )}
                >
                    {!itemHasImg && (provider?.hasBigImage ?
                        <>
                            <div className="game__provider-bg-without-img">
                                <div className="game__provider-with-img">
                                    <img
                                        className="game-img__logo"
                                        src={provider?.bigImgLink + ''}
                                        alt={item.BrandName}
                                    />
                                    <WithPopover popoverClasses="ll-popover__text">
                                        <span>{trans(item.GameName)}</span>
                                    </WithPopover>
                                </div>
                            </div>
                        </>
                        :
                        <div className="game__provider-bg-without-img">
                            <div className="game__provider-without-img">
                                <WithPopover popoverClasses="ll-popover__text">
                                    <span>{trans(item.BrandName)}</span>
                                </WithPopover>
                                <WithPopover customClasses="game__provider-game-name" popoverClasses="ll-popover__text">
                                    <span>{trans(item.GameName)}</span>
                                </WithPopover>
                            </div>

                        </div>)
                    }
                </div>
            }
        </div>
    );
}));
