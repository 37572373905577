import React from 'react';
import {inject, observer} from "mobx-react";

import {SearchSportsList} from "./search-sports-list";
import FilteredPrematchesList from "../../mobile/components/filtered-prematches/filtered-prematches-list";


const SearchResult = ({store}) => {
    const
        search = store.betting.branch.search,
        currentSearch = store.betting.branch.search.currentSearch,
        viewSize = store.site.status.viewSize;

    return (
        <div className="sport-search__result">
            { search.searchString ?
                <>
                    <SearchSportsList
                        search={search}
                        sports={search.sportsList}
                        activeSport={currentSearch?.activeSport}
                        viewSize={viewSize}
                    />
                    <FilteredPrematchesList
                        matches={search.sportMatchesList}
                        key={currentSearch?.activeSport}
                        viewSize={viewSize}
                        type="search"
                    />
                </>
                : null
            }
        </div>
    );
};

export default inject('store')(observer(SearchResult));