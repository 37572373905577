import { types as t, getRoot, types, flow, getParent } from 'mobx-state-tree';
import { values } from 'mobx';
import { sortBy, difference, orderBy } from 'lodash';

const Tournament = t
    .model('Tournament', {
        /** children id's for branch (top matches), category (all or outrights), tournament. **/
        matchesIds: t.array(t.string),
        liveMatchesIds: t.array(t.string),
        outrightsIds: t.array(t.string),
        matchesTopIds: types.array(types.string),
        matchesFavoriteIds: types.array(types.string),
        timeStart: types.maybeNull(types.Date), //only for outrights tournaments
        sportId: types.maybeNull(types.string),
        categoryId: types.maybeNull(types.string),
        countTotal: 0,
    })
    .actions((s) => ({
        deleteOldEvents: flow(function* fetch({ type, oldEvents }) {
            oldEvents.map((eventId) => {
                const eventType = getRoot(s).betting[type];
                if (eventType?.matches.size) {
                    const match = eventType.matches.get(eventId);
                    if (match) {
                        if (
                            !getRoot(s).betting.activeItems.isActive({
                                id: eventId,
                                type: 'matches',
                            })
                        ) {
                            eventType.deleteItem({id: eventId});
                        }
                    }
                }
            });
        }),
        removeItems: flow(function* fetch() {
            yield s.deleteOldEvents({
                type: 'liveMatches',
                oldEvents: s.liveMatchesIds,
            });
            getParent(s, 2).deleteItem(s.id);
        }),
        setCountTotal(countTotal) {
            s.countTotal = countTotal;
        },
        setEventsIds({ type, ids, countTotal = 0 }) {
            const oldEvents = difference([...s[`${type}Ids`]], ids);
            s[`${type}Ids`] = ids;
            s.deleteOldEvents({ type, oldEvents });
        },
        setOutrightId(id) {
            s.outrightsIds.push(id);
        },
    }))
    .views((s) => ({
        get listLiveMatches() {
            return orderBy(
                values(getRoot(s).betting.liveMatches.matches).filter((match) => {
                    return s.liveMatchesIds.includes(match.id);
                }),
                ['order', (dateObj) => new Date(dateObj.timeStart)]
            );
        },
        matchesGroupedByDate(type = 'matches') {
            const betting = getRoot(s).betting;
            let matches = values(
                betting[type === 'outrights' ? type : 'matches'].matches
            ).filter((match) =>
                s[`${type}Ids`].includes(match.id)
            );
            let matchesByDate = null;
            if (matches.length) {
                matches = sortBy(matches, [
                    (dateObj) => new Date(dateObj.timeStart),
                ]).map((match) => {
                    if (!matchesByDate) {
                        matchesByDate = {};
                    }
                    if (!matchesByDate[match.startDate]) {
                        matchesByDate[match.startDate] = [];
                    }
                    matchesByDate[match.startDate].push(match);
                });
            }
            return matchesByDate;
        },

        get outrigthsList() {
            const betting = getRoot(s).betting;
            let outrigths = values(betting.outrights.matches).filter((match) =>
                s.outrightsIds.includes(match.id)
            );

            if (outrigths.length) {
                outrigths = sortBy(outrigths, [
                    'order',
                    (dateObj) => new Date(dateObj.timeStart),
                    (item) => {
                        return item?.name?.toLowerCase();
                    },
                ]);
            }
            return outrigths;
        },
    }));

export default Tournament;
