import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

import {LLErrorVisualizer} from '../../../../components';
import LiveWidgets from '../../components/betradar-widgets/live-widgets';
import MarketGroupsSlider from '../../components/market-groups-slider/market-groups-slider';
import crutchDanoHideBets from '../../components/crutches/danoHideBets';

import config from '../../../../static/themes/config';
import {LiveScoreboard} from "../../components/common";
import MatchDetailsTitle from "./match-details-title";


const LLLiveMatchDetailMobile = ({store}) => {
    const match = store.betting.liveMatches.mountedLiveMatchInstance,
        mobileUserAgent = store.user.mobileUserAgent,
        clearError = store.betting.clearError,
        activeItems = store.betting.activeItems,
        viewSize = store.site.status.viewSize,
        siteName = store.site.siteName,
        isLogged = store.user.isLogged,
        userCanCashout = store.user.canCashout,
        getScrollBox = store.scrollBoxes.getScrollBox,
        globalError = store.betting.bettingErrors.global;

    const marketGroups = match && match.marketGroupsList();

    const [activeMarketGroup, setActiveMarketGroup] = useState(-1);
    const [isSticky, setIsSticky] = useState(false);
    const [lmtError, setLmtError] = useState(false);

    let bananaDefender = true;

    const scrollHandler = () => {
        if (bananaDefender) {
            if ((!mobileUserAgent && getScrollBox('main-scroll')?.scrollPosition > 600) || (mobileUserAgent && window.scrollY > 600)) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }
    };

    useEffect(() => {
        if (marketGroups?.length && bananaDefender) {
            const activeGroup = marketGroups.find((group) =>
                activeItems.isActive({
                    id: group.id,
                    type: 'marketGroups',
                })
            );
            if (!activeGroup || activeGroup.id === marketGroups[0]?.id) {
                activeItems.setActiveItem(
                    [marketGroups[0].id.toString()],
                    'marketGroups'
                );
            }
        }
    }, [match?.marketGroupsList?.length]);

    useEffect(() => {
        mobileUserAgent && document.addEventListener('scroll', scrollHandler);
    }, []);

    useEffect(
        () => () => {
            bananaDefender = false;
            clearError({type: 'detail'});
            activeItems.setActiveItem([], 'marketGroups');
            document.removeEventListener('scroll', scrollHandler);
        },
        []
    );

    useEffect(() => {
        scrollHandler();
    }, [getScrollBox('main-scroll')?.scrollPosition]);

    return globalError ? (
        <LLErrorVisualizer error={globalError}/>
    ) : match && (
        <div className="livematches match-details--mobile">
            {(!!config(siteName).siteConfig.withOutWidgets || !match.lmt || lmtError) ? (
                    <LiveScoreboard
                        match={match}
                        viewSize={viewSize}
                        matchType="live"
                        matchTime={match.matchTime}
                        matchScore={match.matchScore}
                        statusName={match.statusName}
                    >
                        <MatchDetailsTitle match={match}/>
                    </LiveScoreboard>
                ) :
                <LiveWidgets
                    match={match}
                    isSticky={isSticky}
                    viewSize={viewSize}
                    isLogged={isLogged}
                    setLmtError={setLmtError}
                    lmtError={lmtError}
                >
                    <MatchDetailsTitle match={match}/>
                </LiveWidgets>
            }
            {!crutchDanoHideBets(siteName, match) &&
                <MarketGroupsSlider
                    activeMarketGroup={activeMarketGroup}
                    setActiveMarketGroup={setActiveMarketGroup}
                    matchInstance={match}
                    userCanCashout={userCanCashout}
                    isMarketGroupsNeeded={match.isMarketGroupsNeeded}
                />
            }
        </div>
    );
};

export default inject('store')(observer(LLLiveMatchDetailMobile));
