import React from 'react';
import PropTypes from 'prop-types';
import { LLBtn2, withCommon } from '../../../../components';

/**
 * @file Represents React Component to render
 *
 * @param {function} deleteBet - requiest to api for deleting coupon
 * @param {object} modal - object in store for modal
 *
 * @author A.Huskova
 */

const BetDeleteModal = withCommon({
    store: ({ store }) => ({
        userBets: store.user.userBets,
        modal: store.modal,
    }),
    propTypes: {
        modal: PropTypes.object.isRequired,
    },
    defaultName: 'BetDeleteModal',
    isTrans: true,
    isObserver: true,
})(({ id, userBets, modal, type, trans }) => (
    <div className="ll-modal__yes-no">
        <p className="modal__text">
            {trans('DELETE_COUPON_MODAL_TEXT').replace('%%id%%', id)}
        </p>
        <div className="modal__btns">
            <LLBtn2
                onClick={() => {
                    userBets[type] && userBets[type].deleteBet(id);
                    modal.close();
                }}
            >
                {trans('Yes')}
            </LLBtn2>
            <LLBtn2 onClick={() => modal.close()} customClasses="ll-btn--outlined">
                {trans('Cancel')}
            </LLBtn2>
        </div>
    </div>
));

export default BetDeleteModal;
