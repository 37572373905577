import { getRoot, flow } from 'mobx-state-tree';

import api from '../../../../../common/api';
import llattempt from '../../../../../common/utils/attempt';
import { branchConfig } from '../../config';

const searchFetches = (s) => ({
    fetchAndConvertSearchData: flow(function* fetch({ searchString }) {
        const searchFetchParams = {
            search: searchString,
            to: branchConfig[getRoot(s).betting.branchId].to(),
        };

        return yield llattempt(() => api.betting.getSearch(searchFetchParams), {
            withNotifier: false,
            at: 'fetchAndConvertSearchData',
            withParams: searchFetchParams,
        }) || {};
    }),
});

export default searchFetches;
