import React from 'react';
import cx from "classnames";

import {API_PREFIX} from "../../../common/api/api-url-config";
import apiUrls from "../../../common/api/api-urls";
import LLWidgetHeader from "./components/widget-header";
import WidgetMultigames from "./components/widget-multigames";
import WidgetGame from "./components/widget-game";


const LLWidgetGameItem = ({
                              slide,
                              mobileUserAgent,
                              language,
                              isLogged,
                              openLogin,
                              viewSize,
                          }) => {
    const {
        mobileLink,
        desktopLink,
        type,
        games,
        gameImg,
    } = slide.settings;

    const elementAttributes = mobileUserAgent ? {
        href: `${API_PREFIX}${apiUrls.games.openGame}${mobileLink}`,
        target: "_blank",
        rel: "noopener noreferrer",
    } : {
        to: desktopLink,
    };


    return <div className={cx(
        'll-widget-item ll-widget-item--game js__activity-area-item',
        viewSize && `ll-widget-item--${viewSize}`,
    )}
                data-area-item-id={slide.slideId}>
        <div className="ll-widget-item__bg">
            <img className={cx("ll-widget-item__bg--image",
                type === "slot" && "ll-widget-item__bg--image-slot",
                type === "casino" && "ll-widget-item__bg--image-casino"
            )}
                 src={type === "slot" ? !!games && games.length ? games[Math.floor(Math.random() * games.length - 1)]?.gameImg : gameImg : null}
                 alt=""/>
        </div>

        <div className="ll-widget-item__content">
            <LLWidgetHeader slide={slide} type={type}/>
            {(games && games.length) ? <WidgetMultigames
                    {...{
                        slide,
                        language,
                        isLogged,
                        mobileUserAgent,
                        openLogin,
                    }}
                /> :
                <WidgetGame {...{
                    slide,
                    language,
                    elementAttributes,
                    isLogged,
                    mobileUserAgent,
                    openLogin,
                }}/>}
        </div>
    </div>;
};

export default LLWidgetGameItem;
