import React, { useEffect } from "react";

import { BetsList, withCommon } from "../../../../../components";


const ActiveBetsPanelDesktop = withCommon({
    store: ({ store }) => ({
        coupons: store.user.coupons,
        storeItem: store.user.userBets.activeBets,
        betsError: store.user.userBets.activeBets.betsError,
        activeBets: store.user.userBets.activeBets.getBets,
        activeBetId: store.user.userBets.activeBets.activeBetId,
        setStatusFilter: store.user.userBets.setStatusFilter,
        setMounted: store.user.userBets.activeBets.setMounted,
        loading: store.user.userBets.activeBets.isTableLoading,
        setModal: store.modal.setModal,
        drawerIsActive: store.drawer.isActive,
        language: store.user.language,
        currency: store.user.currency,
        canCashout: store.user.canCashout,
        mobileUserAgent: store.user.mobileUserAgent
    }),
    isObserver: true
})(({
        storeItem,
        activeBets,
        activeBetId,
        setStatusFilter,
        drawerIsActive,
        setMounted,
        loading,
        setModal,
        language,
        currency,
        canCashout,
        betsError,
        mobileUserAgent
    }) => {

    useEffect(() => {
        if (!drawerIsActive) {
            setStatusFilter("activeBets");
            if (activeBets.length) storeItem.setActiveBet(activeBets[0].id);
        }
    }, [drawerIsActive]);

    useEffect(() => () => {
        setMounted(false);
        storeItem.setActiveBet(null);
    }, []);

    return (
        <BetsList
            storeItem={storeItem}
            betsError={betsError}
            bets={activeBets}
            activeBetId={activeBetId}
            loading={loading}
            language={language}
            currency={currency}
            setModal={setModal}
            userCanCashout={canCashout}
            listType="activeBets"
            isMobile={mobileUserAgent}
        />
    );
});

export default ActiveBetsPanelDesktop;