import React, {useEffect, useState} from 'react';
import cx from "classnames";

import LLCouponListItem from "./coupon-list-item";
import {trans} from '../../../common/utils';
import {inject, observer} from "mobx-react";


const couponListTabsConfig = [
    {
        name: 'SINGLE',
        val: 'single',
    },
    {
        name: 'ACCUMULATED',
        val: 'accum',
    },
    {
        name: 'System',
        val: 'system'
    },
];

const CouponTabs = (
    {
        store,
        activeTab,
        setActiveTab,
        isMobile,
    }
) => {
    const list = store.user.coupons.list;
    const isSingleOnly = store.user.coupons.isSingleOnly;
    const [listLength, setListLength] = useState(list.length);

    const isTabsAvailable = () => {
        if (list.length < 2) {
            return {single: true, accum: false, system: false}
        } else {
            return {};
        }
    };

    useEffect(() => {
        if (isTabsAvailable().accum === false) {
            setActiveTab('single');
        } else if (
            (listLength === list.length)
            || (listLength === 1 && list.length === 2 && !isSingleOnly)
        ) {
            setActiveTab('accum');
        }
        setListLength(list.length);
    }, [list.length]);

    return (
        <>
            <div className="coupons__view-tabs">
                { couponListTabsConfig.map((tab) => {
                    const isTabEnabled = isTabsAvailable()[tab.val] !== false;
                    return <div
                        key={tab.val}
                        className={cx(
                            'coupons__view-tab',
                            activeTab === tab.val ? 'active' : '',
                            !isTabEnabled && 'disabled'
                        )}
                        onClick={() => { isTabEnabled && setActiveTab(tab.val) }}
                    >
                        {trans(tab.name)}
                    </div>
                })}
            </div>
            <div className={`coupons-list${isMobile ? '--mobile' : ''}`}>
                { list.map((coupon, index) => {
                    const isCouponUnique = activeTab !== 'single' &&
                            !!list.find((item, j) =>
                                (coupon.matchId === item.matchId && index !== j)
                            );

                    return <LLCouponListItem
                        key={coupon.id}
                        index={index}
                        coupon={coupon}
                        isSingleOnly={isCouponUnique}
                        isMultiSingle={activeTab === 'single' && list.length > 1}
                    />
                })}
                { (isSingleOnly && activeTab !== 'single') && <div
                    className={cx(
                        'coupons-list__event',
                        'only-single-error'
                    )}
                >
                    <div className="coupons-list__event-part">
                        <div className="only-single-error__icon-container">
                        <i className={cx(
                            'only-single-error__icon',
                            'doruk-icon-attention'
                        )}/>
                        </div>
                    </div>
                    <div
                        className="coupons-list__event-part coupons-list__event-part--main"
                    >
                        {trans('ONLY_SINGLE_ERROR_TEXT')}
                    </div>
                </div>}
            </div>
        </>
    );
};

export default inject('store')(observer(CouponTabs))