import {flow, getRoot, isAlive} from 'mobx-state-tree';

import {refreshTime, unsuccessCountLimit} from '../../config';
import {urls} from '../../../../../configs';
import {imageLoading} from '../../../../../common/utils';
import trans from '../../../../../common/utils/trans';

const singleInstuructions = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                Scenarios instructions                                ##
    // ##                                                                                      ##
    // ##========================================================================================

    /**
     * @desc - used when fatch data not valid used in initialize and update !
     * @property {string} categoryId
     * @property {string} tournamentId
     * @property {string} sportId
     * @property {initialMatchId} - match Id reqested by match details on reqested data loading
     * @property {'update' | 'initialize'} action - where bad incoming data was happens
     * @property {bool} success - is fetch was successfull
     *
     */
    unsuccessIncomingDataInitializeInstruction({res, branchId} = {}) {
        const error =
            res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                ? res.data?.note ?? res.data?.error
                : 'CONNECTION_ISSUES_ERROR';

        getRoot(s).betting.branchId === branchId && getRoot(s).betting.setError({
            type: 'global',
            error,
            title: res.data?.error === 'SERVICE_CLOSED' ? 'Information Message' : null
        });
    },

    unsuccessIncomingDataUpdateInstruction({
                                               res,
                                               tournamentId,
                                               categoryId,
                                               sportId,
                                               matchId,
                                               branchId
                                           } = {}) {
        s.setUnsuccessCount(s.unsuccessCount + 1);
        const betting = getRoot(s).betting;
        const error =
            res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                ? res.data?.note ?? res.data?.error
                : 'CONNECTION_ISSUES_ERROR';

        if ((s.unsuccessCount >= unsuccessCountLimit || res.data?.error === 'SERVICE_CLOSED') && betting.branchId === branchId) {
            if (res.data?.error === 'SERVICE_CLOSED') {
                betting.setError({
                    type: 'global',
                    error,
                    title: 'Information Message'
                });
            } else {
                betting.setError({type: 'list', error});
            }
        } else {
            s.setWaitingItem('singleMatch', null);
            s.setWaitingItem('singleTournament', null);
            s.defineUpdateFor({
                instance: 'Single',
                tournamentId,
                categoryId,
                sportId,
                matchId,
            });
        }
    },

    /**
     *
     * @property {object[]} incomingMatchData - data source
     * @property {string} tournamentId
     * @property {string} categoryId
     * @property {string} sportId,
     * @property {string}  initialMatchId - match id reqested by reqested data loading
     */

    successInitializeIncomingDataInstruction({
                                                 incomingMatchData,
                                                 tournamentId,
                                                 categoryId,
                                                 sportId,
                                                 matchId,
                                                 branchId,
                                                 dropDownRequest,
                                                 selfTournament,
                                             } = {}) {
        s.setUnsuccessCount(0);

        if (incomingMatchData.length === 0 && getRoot(s).betting.branchId === branchId) {
            getRoot(s).router.push('/sport/1');
            setTimeout(() => {
                getRoot(s).modal.setModal({
                    active: true,
                    maskBackground: true,
                    showHeader: true,
                    currentKey: 'custom-content',
                    title: trans('EVENT_NOT_FOUND'),
                    customContent: trans('EVENT_STARTED'),
                });
            }, 500);
        } else {
            s.setMatchesData({data: incomingMatchData, branchId, dropDownRequest});
            if (matchId) {
                const match = s.matches.get(matchId);
                const homeLogo =
                    urls.prematchCompetitorImg + match.competitor1.id + '.png';
                const awayLogo =
                    urls.prematchCompetitorImg + match.competitor2.id + '.png';
                Promise.all([imageLoading(homeLogo), imageLoading(awayLogo)]).then(
                    (res) => {
                        match.setTeamIconsStatus(res[0], res[1]);
                    }
                );
            }
        }
        s.setFetching({type: 'initialFetching', status: false});
        if (!matchId) {
            const activeTournament = getRoot(s).betting.branch.activeTournament();
            if (activeTournament) {
                activeTournament.setFetching({type: 'initialFetching', status: false});
            }
        }
        s.setWaitingItem('singleMatch', null);
        s.setWaitingItem('singleTournament', null);
        s.defineUpdateFor({
            instance: 'Single',
            tournamentId,
            categoryId,
            sportId,
            matchId,
            dropDownRequest,
            selfTournament,
        });
    },

    /**
     * @desc - desc function that executes when incoming data is valid
     * @property {object[]} incomingMatchData - data source
     * @property {string} tournamentId
     * @property {string} categoryId
     */

    successUpdateIncomingData({
                                  tournamentId,
                                  categoryId,
                                  sportId,
                                  matchId,
                                  incomingMatchData,
                                  branchId,
                                  dropDownRequest,
                                  selfTournament,
                              }) {
        s.setUnsuccessCount(0);

        if (incomingMatchData.length === 0 && getRoot(s).betting.branchId === branchId) {
            getRoot(s).router.push('/sport/1');
            setTimeout(() => {
                getRoot(s).modal.setModal({
                    active: true,
                    maskBackground: true,
                    showHeader: true,
                    currentKey: 'custom-content',
                    title: trans('EVENT_NOT_FOUND'),
                    customContent: trans('EVENT_STARTED'),
                });
            }, 500);
        } else {
            s.setMatchesData({data: incomingMatchData, branchId, dropDownRequest});
        }

        s.setFetching({type: 'isFetching', status: false});

        s.setWaitingItem('singleMatch', null);
        s.setWaitingItem('singleTournament', null);
        s.defineUpdateFor({
            instance: 'Single',
            tournamentId,
            categoryId,
            sportId,
            matchId,
            dropDownRequest,
            selfTournament,
        });
    },

    // ##========================================================================================

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                           instruction for single tournament                          ##
    // ##                                                                                      ##
    // ##========================================================================================

    /**
     * @desc - function to load single tournament matches
     * @property {string} tournamentId
     * @property {string} categoryId,
     * @property {string} sportId,
     * @property {string} matchId -  need to preload spcific match on match-details initial loading,
     */
    initializeGetVaixMatchInstruction: flow(function* fetch({matchId}) {
        const res = yield s.fetchAndConvertDataForSingleMatch({matchId});
        if (res.success) {
            s.setMatchesData({data: res.data, branchId: getRoot(s).betting.branch.id !== 'Live' ? getRoot(s).betting.branch.id : 'All', isFeatured: true});
        }
    }),

    initializeSingleInstruction: flow(function* fetch({
                                                          tournamentId,
                                                          categoryId,
                                                          sportId,
                                                          matchId,
                                                          dropDownRequest = false,
                                                          selfTournament,
                                                      }) {
        initializeSingleInstruction: {
            if (isAlive(s)) {
                s.clearUpdater();
                let activeTournament;
                if (selfTournament) {
                    activeTournament = selfTournament;
                } else if (getRoot(s).betting.activeItems.firstTournament === 'All') {
                    activeTournament = getRoot(s).betting.branch.activeCategory().tournaments.get(tournamentId);
                } else {
                    activeTournament = getRoot(s).betting.branch.activeTournament();
                }

                if (dropDownRequest) {
                    activeTournament.setFetching({
                        type: 'dropDownFetching',
                        status: true,
                    });
                }

                if (
                    (matchId && s.check.matchLastFetchTimeNotExpired(matchId)) ||
                    (!matchId && s.check.tournamentLastFetchTimeNotExpired({tournament: activeTournament}))
                ) {
                    let timeLeft;
                    if (matchId) {
                        timeLeft =
                            +new Date(
                                +s.matches.get(matchId).lastFetchTime +
                                refreshTime.matchesList
                            ) - Date.now();
                    } else {
                        timeLeft =
                            +new Date(
                                +activeTournament?.lastFetchTime +
                                refreshTime.matchesList
                            ) - Date.now();
                    }
                    s.defineUpdateFor({
                        instance: 'Single',
                        tournamentId,
                        categoryId,
                        sportId,
                        matchId,
                        timeLeft,
                        dropDownRequest,
                        selfTournament,
                    });

                    if (dropDownRequest) {
                        activeTournament.setFetching({
                            type: 'dropDownFetching',
                            status: false,
                        });
                    } else {
                        if (!matchId) {
                            activeTournament &&
                            activeTournament.setFetching({
                                type: 'initialFetching',
                                status: false,
                            });
                        }
                    }

                    break initializeSingleInstruction;
                }

                if (!dropDownRequest) {
                    s.setFetching({type: 'initialFetching', status: true});
                    if (!matchId) {
                        activeTournament &&
                        activeTournament.setFetching({
                            type: 'initialFetching',
                            status: true,
                        });
                    }
                }

                s.setWaitingItem(
                    matchId ? 'singleMatch' : 'singleTournament',
                    matchId ?? tournamentId
                );

                const branchId = getRoot(s).betting.branchId;

                const res = matchId
                    ? yield s.fetchAndConvertDataForSingleMatch({matchId})
                    : yield s.fetchAndConvertDataForSingle({tournamentId});

                if (!res || !res.success) {
                    s.unsuccessIncomingDataInitializeInstruction({res, branchId});
                    if (getRoot(s).betting.branch.initialFetching) {
                        throw new Error(res.error);
                    }
                } else {
                    s.successInitializeIncomingDataInstruction({
                        incomingMatchData: res.data,
                        tournamentId,
                        categoryId,
                        sportId,
                        matchId,
                        branchId,
                        dropDownRequest,
                        selfTournament,
                    });
                }

                if (dropDownRequest) {
                    activeTournament.setFetching({
                        type: 'dropDownFetching',
                        status: false,
                    });
                }

                !matchId && activeTournament && activeTournament.setLastFetchTime();
            }
        }
    }),

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                           Update data for single tournament                          ##
    // ##                                                                                      ##
    // ##========================================================================================

    updateForSingleInstruction: flow(function* fetch({
                                                         tournamentId,
                                                         categoryId,
                                                         sportId,
                                                         matchId,
                                                         dropDownRequest = false,
                                                         selfTournament,
                                                     }) {
        updateForSingleInstruction: {
            if (isAlive(s)) {
                const betting = getRoot(s).betting;
                const activeTournament = getRoot(s).betting.branch.activeTournament();

                if (!s.check.isMatchesUpdatable) {
                    s.isWaiting.clearAll();
                    s.defineUpdateFor({
                        instance: 'Single',
                        tournamentId,
                        categoryId,
                        sportId,
                        matchId,
                        dropDownRequest,
                        selfTournament,
                    });
                    break updateForSingleInstruction;
                }

                if (dropDownRequest && !betting.isBreadcrumbsNavigationDropdownOpen) {
                    break updateForSingleInstruction;
                }

                s.setFetching({type: 'isFetching', status: true});

                const branchId = betting.branchId;

                const res = (!betting.activeItems.matches.length || dropDownRequest)
                    ? yield s.fetchAndConvertDataForSingle({
                        tournamentId: tournamentId,
                    })
                    : yield s.fetchAndConvertDataForSingleMatch({
                        matchId:
                            matchId ||
                            betting.activeItems.matches[
                            betting.activeItems.matches.length - 1
                                ],
                    });

                if (!res || !res.success) {
                    s.unsuccessIncomingDataUpdateInstruction({
                        res,
                        tournamentId,
                        categoryId,
                        sportId,
                        matchId,
                        branchId,
                        dropDownRequest,
                        selfTournament,
                    });
                } else {
                    s.successUpdateIncomingData({
                        tournamentId,
                        categoryId,
                        sportId,
                        matchId,
                        incomingMatchData: res.data,
                        branchId,
                        dropDownRequest,
                        selfTournament,
                    });
                }
                !matchId && activeTournament && activeTournament.setLastFetchTime();
            }
        }
    }),
});

export default singleInstuructions;
