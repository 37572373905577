import React, {useEffect, useRef, useState} from "react";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {CSSTransition} from "react-transition-group";
import cx from "classnames";

import { LLScrollBox } from "../../";
import RenderMenu from './render-menu';

/**
 * @desc Represents a WithDropdown component.
 *
 * @author S.Nakhodov
 */

const WithDropdown =
    ({
         customClasses,
         menuClasses,
         dataSource = {
             submenu: []
         },
         placement = "bottom-start",
         openBehavior = "hover",  // Types: 'hover', 'click'
         delay = 0,
         children,
         disabled,
         menuItemIconPlacement = "start",
         menuItemIsActive = () => false,
         menuItemIsDisabled = () => false,
         onMenuItemClick,
         onClickAway,
         customDropdownStatus,
         withAccordion,
         multiSelect,
         withOutAwayListener = false,
         withDropdownIcon = true,
         disablePortal = true,
         customScrollClasses,
         popoverWidth,
    }) => {
        const [isActive, setActive] = useState(false);

        let bananaDefender = true;

        useEffect(() => {
            if (customDropdownStatus !== undefined && bananaDefender) {
                setActive(customDropdownStatus);
            }
        }, [customDropdownStatus]);

        useEffect(() => () => {
            bananaDefender = false;
        }, []);

        const ref = useRef(null);

        const toggle = () => {
            if (!isActive && dataSource.submenu?.length && !disabled) {
                setTimeout(() => {
                    setActive(true);
                }, delay);
            } else {
                if (!multiSelect) {
                    setActive(false);
                }
                if (typeof onClickAway === "function") {
                    onClickAway();
                }
            }
        };

        const propObj = {};
        if (openBehavior === "click") {
            propObj.onClick = toggle;
        }
        if (openBehavior === "hover") {
            propObj.onMouseEnter = () => {
                setTimeout(() => {
                    setActive(true);
                }, delay);
            };
            propObj.onMouseLeave = () => {
                setActive(false);
            };
        }
        if (openBehavior === "hybrid") {
            propObj.onClick = toggle;
            propObj.onMouseLeave = () => {
                setActive(false);
            };
        }

        const renderMenuProps = {
            menuItemIconPlacement,
            menuItemIsActive,
            menuItemIsDisabled,
            onMenuItemClick,
        };

        const subContent = () => (
            <>
                {children}
                {withDropdownIcon &&
                    <i
                        className={`dx-icon-spindown ll-dropdown-menu__icon${
                            isActive ? "--opened" : ""
                        }`}
                    />
                }
            </>
        );

        const renderChildren = () =>
            !withAccordion ? (
                subContent()
            ) : (
                <div className="ll-dropdown-menu__title-wrapper">{subContent()}</div>
            );

        const renderContent = () => <div
            ref={ref}
            className={cx(
                "ll-dropdown-wrapper",
                withAccordion && "ll-dropdown-wrapper--with-accordion",
                customClasses,
                `ll-dropdown-wrapper${isActive ? "--opened" : "--closed"}`,
                (disabled || !dataSource.submenu?.length) && 'll-dropdown-wrapper--disabled'
            )}
            {...propObj}
        >
            {renderChildren()}
            {!withAccordion ? (
                <Popper
                    open={isActive}
                    {...propObj}
                    anchorEl={ref.current}
                    placement={placement}
                    disablePortal={disablePortal}
                    sx={popoverWidth ? { width: popoverWidth + 'px' } : null}
                    className={cx("ll-dropdown-menu", menuClasses)}
                >
                    <LLScrollBox
                        customClasses={cx("ll-dropdown-scrollbox", customScrollClasses)}
                    >
                        <RenderMenu
                            disabled={disabled}
                            dataSource={dataSource}
                            {...renderMenuProps}
                        />
                    </LLScrollBox>
                </Popper>
            ) : (
                <CSSTransition timeout={200} in={isActive} unmountOnExit>
                    <div className={cx("ll-dropdown-menu", menuClasses)}>
                        <LLScrollBox
                            customClasses={cx("ll-dropdown-scrollbox", customScrollClasses)}
                        >
                            <RenderMenu
                                disabled={disabled}
                                dataSource={dataSource}
                                {...renderMenuProps}
                            />
                        </LLScrollBox>
                    </div>
                </CSSTransition>
            )}
        </div>;

        return (withOutAwayListener ? renderContent() :
                <ClickAwayListener
                    onClickAway={() => {
                        isActive && setActive(false);
                        if (typeof onClickAway === "function") {
                            onClickAway();
                        }
                    }}
                    mouseEvent={"onMouseDown"}
                    touchEvent={"onTouchStart"}
                >
                    {renderContent()}
                </ClickAwayListener>
        );
    };

export default WithDropdown;