import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';

import {
    LLSportBettingButtonWrapper,
    LLPreloader,
} from '../../../../components';
import {trans} from '../../../../common/utils';
import {LiveScoreboardWithBreadcrumbs} from "../../components";
import MatchDetailsTitle from "./match-details-title";


const LLOutrightsDetailsMobile = ({store, view}) => {
    const prematchRouterOutrightInstance = store.router.prematchRouterOutrightInstance,
        clearError = store.betting.clearError,
        isBreadcrumbsNavigationDropdownOpen = store.betting.isBreadcrumbsNavigationDropdownOpen,
        matchDetailError = store.betting.bettingErrors.detail,
        viewSize = store.site.status.viewSize,
        language = store.user.language;

    useEffect(
        () => () => {
            clearError({type: 'detail'});
        },
        []
    );

    const renderBets = () => {
        if (!prematchRouterOutrightInstance?.betsLength) {
            return <div> This outright bets is not available</div>;
        }

        return (
            <>
                <MatchDetailsTitle match={prematchRouterOutrightInstance}/>
                <LiveScoreboardWithBreadcrumbs
                    matchInstance={prematchRouterOutrightInstance}
                    viewSize={viewSize}
                    language={language}
                    type="Outrights"
                />

                {matchDetailError ? (
                    <div className="match-details__error">{trans(matchDetailError)}</div>
                ) : (
                    <div className={`outright-details__bets`}>
                        {prematchRouterOutrightInstance?.betsList.map((bet, i) => {
                            return (
                                <div className="outright-details__item" key={i}>
                                    <h3 className="ll-outright__title">{bet.name}</h3>
                                    <LLSportBettingButtonWrapper
                                        type={'outRight'}
                                        bet={bet}
                                        key={bet.id}
                                        columnCount={2}
                                        nameAppearance={false}
                                        className="outright-details__bet"
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="outright-details">
            <LLPreloader
                fullPage
                visible={view?.isLoading}
                height={50}
                width={50}
                timeout={300}
                className="ll-preloader__prematch-detail"
            />
            <div className="match-detail__wrapper">
                {isBreadcrumbsNavigationDropdownOpen ? <div
                    className="match-detail__back-drop"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                /> : null}
                {prematchRouterOutrightInstance?.betsLength && renderBets()}
            </div>
        </div>
    );
};

export default inject('store')(observer(LLOutrightsDetailsMobile));
