import React from "react";
import {Link} from "react-router-dom";
import {inject, observer} from "mobx-react";

import {LLPreloader} from "../../../../../components";
import FilteredPrematchesList from './filtered-prematches-list';
import { trans } from '../../../../../common/utils';


const MobileRangedEvents = ({store}) => {
    const
        matches = store.betting.rangedEvents.rangedEventsMatches,
        activeRangedEvent = store.betting.activeItems.sports.length ?
            store.betting.activeItems.sports[0] : null,
        tournamentsWithTopMatches = store.betting.branch
            .tournamentsWithTopFavoriteMatches('Top'),
        tournamentsWithFavoriteMatches = store.betting.branch
            .tournamentsWithTopFavoriteMatches('Favorite');

    const getTopsFavorites = ({tournaments, type}) => {
        return tournaments.reduce((acc, tournament) => {
            const matches = tournament.matchesGroupedByDate(type);
            acc.push({
                matches,
                categoryId: tournament.categoryId,
                categoryOrder: tournament.categoryOrder,
                nameOf: Object.values(matches).length && Object.values(matches)[0].length ? Object.values(matches)[0][0].nameOf : null,
                sportId: Object.values(matches).length && Object.values(matches)[0].length ? Object.values(matches)[0][0].sportId : null,
                sportOrder: tournament.sportOrder,
                tournamentOrder: tournament.tournamentOrder,
                tournamentId: tournament.id,
            });
            return acc;
        }, [])
    }

    return (
        <div className="ranged-events--mobile">
            <Link
                to={`/sport/${store.betting.branch.routerId}`}
                className="ranged-events__back-btn"
            >
                <i className="ranged-events__back-btn-icon doruk-mg-arrow-2" />
                <span className="ranged-events__back-btn-text">
                    { trans('All sports') }
                </span>
            </Link>
            { store.router.mobilePrematchDashboardView.isLoading ?
                <div className="prematch__loading--mobile">
                    <LLPreloader height={50} width={50} />
                </div>
                :
                activeRangedEvent === 'popular' ?
                    <div>
                        { !!tournamentsWithFavoriteMatches?.length && (
                            <>
                                <div className="ranged-events__tops-title">
                                    {trans('PRE_FAVORITE')}
                                </div>
                                <FilteredPrematchesList
                                    matches={getTopsFavorites({
                                        tournaments: tournamentsWithFavoriteMatches,
                                        type: 'matchesFavorite'
                                    })}
                                    activeRangedEvent={activeRangedEvent}
                                    type="rangedEvents"
                                    viewSize={store.site.status.viewSize}
                                />
                            </>
                        )}
                        {!!tournamentsWithTopMatches?.length && (
                            <>
                                <div className="ranged-events__tops-title">
                                    {trans('PRE_TOP')}
                                </div>
                                <FilteredPrematchesList
                                    matches={getTopsFavorites({
                                        tournaments: tournamentsWithTopMatches,
                                        type: 'matchesTop'
                                    })}
                                    activeRangedEvent={activeRangedEvent}
                                    viewSize={store.site.status.viewSize}
                                    type="rangedEvents"
                                />
                            </>
                        )}
                    </div>
                    :
                    <FilteredPrematchesList
                        matches={matches}
                        viewSize={store.site.status.viewSize}
                        type="rangedEvents"
                    />
            }
        </div>
    );
};

export default inject('store')(observer(MobileRangedEvents));