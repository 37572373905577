import React from 'react';
import { inject, observer } from 'mobx-react';

import { PrematchLeftMenu, LiveLeftMenu, PrematchRightPanel } from '.';
import { LLErrorVisualizer, LLPreloader } from '../../../components';
import {
    LLMatchesList,
    LLMatchDetails,
    LLOutrightDetails,
    LiveMatchDetails,
} from './components';
import LiveCalendarPage from '../components/calendar/calendar-page';


const SportDesktop = ({ store }) => {
    const prematchDashboardView = store.router.prematchDashboardView,
        liveMatches = store.betting.liveMatches,
        initialLoading = store.betting.initialLoading,
        matchListError = store.betting.bettingErrors.list;

    const renderInitialLoading = () => {
        return (
            <div className="prematch__loading--initial">
                <LLPreloader height={50} width={50} />
            </div>
        );
    };

    const renderWithSidePanels = (children) => {
        return (
            <>
                {liveMatches.isCalendar ? (
                    <LiveCalendarPage />
                ) : (
                    <>
                        {prematchDashboardView.component === 'Live' ? (
                            <LiveLeftMenu />
                        ) : (
                            <PrematchLeftMenu />
                        )}
                        {matchListError ? (
                            <LLErrorVisualizer error={matchListError} />
                        ) : prematchDashboardView.component === 'Live' ? (
                            <>{children}</>
                        ) : (
                            <div className="betting-dashboard">{children}</div>
                        )}
                    </>
                )}
                <PrematchRightPanel
                    isLive={prematchDashboardView.component === 'Live'}
                />
            </>
        );
    };
    const renderBettingDashboard = () => {
        return {
            LLMatchesList: renderWithSidePanels(
                <LLMatchesList view={prematchDashboardView} />
            ),
            LLMatchDetails: renderWithSidePanels(<LLMatchDetails />),
            LLOutrightDetails: renderWithSidePanels(
                <LLOutrightDetails view={prematchDashboardView} />
            ),
            Live: renderWithSidePanels(<LiveMatchDetails />),
        }[prematchDashboardView.component];
    };
    return initialLoading || initialLoading === null
        ? renderWithSidePanels(renderInitialLoading())
        : renderBettingDashboard();
};

export default inject('store')(observer(SportDesktop));
