import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withCommon } from '../../components';

import {
    NavigationMenuDrawer,
    LoginDrawer,
    MyAccountMenuDrawer,
    MyBetsDrawer,
    SupportMenuDrawer,
    SignupDrawer,
    MyAccountDrawer,
} from './components';


let previousViewSize = null;

const LLDrawer = withCommon({
    store: ({ store }) => ({
        activePosition: store.drawer.activePosition,
        close: store.drawer.close,
        currentKey: store.drawer.currentKey,
        isActive: store.drawer.isActive,
        modalIsActive: store.modal.isActive,
        viewSize: store.site.status.viewSize,
        setBackdropShown: store.site.status.setBackdropShown,
        setBackdropOnClick: store.site.status.setBackdropOnClick,
        setBackDropFullScreen: store.site.status.setBackDropFullScreen,
    }),
    PropTypes: {
        activePosition: PropTypes.string.isRequired,
        close: PropTypes.func.isRequired,
        currentKey: PropTypes.string,
        isActive: PropTypes.bool.isRequired,
    },
    defaultName: 'LLDrawer',
    isObserver: true,
    isRouter: true,
})(({
    close,
    currentKey,
    isActive,
    modalIsActive,
    activePosition,
    viewSize,
    setBackdropShown,
    setBackdropOnClick,
    setBackDropFullScreen,
}) => {
    const components = {
        'support-drawer': <SupportMenuDrawer />,
        'my-account-menu-drawer': <MyAccountMenuDrawer />,
        'login-form-drawer': <LoginDrawer />,
        'navigation-menu-drawer': <NavigationMenuDrawer />,
        'my-bets-drawer': <MyBetsDrawer />,
        'my-account': <MyAccountDrawer />,
        'signup-form-drawer': <SignupDrawer />,
    };

    useEffect(() => {
        !modalIsActive && setBackdropShown(isActive);
        if (isActive) {
            setBackDropFullScreen(true);
            setBackdropOnClick(close);
        }
    }, [isActive, modalIsActive]);

    useEffect(() => {
        if (
            (previousViewSize === 'tablet' && viewSize === 'desktop') ||
            (previousViewSize === 'desktop' && viewSize === 'tablet')
        ) {
            close();
        }
        previousViewSize = viewSize;
    }, [ viewSize ]);

    /***************** ~~ Render ****************/
    return (
        <div
            className={`ll-drawer ${viewSize} ll-drawer_${activePosition}${
                isActive ? `_open` : ''
            }`}
        >
            <div className="ll-drawer__inner">
                {components[currentKey]}
                {
                    viewSize !== 'desktop' ? (
                        <div className="mobile-fix" />
                    ) : null /* Для глюка со скроллом на мобилках */
                }
            </div>
        </div>
    );
});

export default LLDrawer;