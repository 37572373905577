import React, { useEffect, useState } from 'react';
import {
    CompareRule,
    CustomRule,
    PatternRule,
    RequiredRule,
    Validator,
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import PropTypes from 'prop-types';

import { LLInput, withCommon, llNotifier, LLBtn } from '../../index';
import { passwordStrength } from '../../../common/utils';
import { PasswordStrengthBar } from '../../../components';

/**
 * @file Represents Reset password form
 * @memberOf ResetPassword
 *
 * @param {object} user - user data
 * @param {func} resetPassword - send code and password to server
 * @param {boolean} mobileUserAgent - detect mobile version
 * @param {func} history - router history
 * @param {func} location - router location
 *
 * @author S.Hakhodov feat. A.Green
 */

const ResetPasswordForm = withCommon({
    store: ({ store }) => ({
        user: store.user,
        mobileUserAgent: store.user.mobileUserAgent,
    }),
    PropTypes: {
        user: PropTypes.shape({
            resetPassword: PropTypes.func.isRequired,
        }),
        history: PropTypes.func.isRequired,
        location: PropTypes.func.isRequired,
    },
    isRouter: true,
    isTrans: true,
    isObserver: true,
})(({ user: { resetPassword }, mobileUserAgent, trans, history, location }) => {
    const code = location.search;
    let interval;

    const [form, setForm] = useState({
            password: '',
            passwordRepeat: '',
            codeIsValid: false,
            pwdStrength: 0,
        }),
        [timer, setTimer] = useState(11),
        [serverResponseErrors, setServerResponseErrors] = useState({});

    useEffect(() => {
        if (timer <= 0) {
            clearInterval(interval);
            history.push('/');
        }
    }, [timer]);

    useEffect(() => {
        if (code.includes('code=')) {
            resetPassword({ code: code.split('=', code.length)[1] }).then((res) => {
                try {
                    if (res.success) {
                        setForm({
                            ...form,
                            codeIsValid: true,
                        });
                    } else {
                        interval = setInterval(() => {
                            setTimer((timer) => timer - 1);
                        }, 1000);
                        return () => clearInterval(interval);
                    }
                } catch {
                    llNotifier({
                        message: 'GENERAL_ERROR',
                        type: 'error',
                    });
                }
            });
        } else {
            history.push('/');
        }
    }, []);

    const serverValidationCheck = (fieldName) => {
        return !serverResponseErrors[fieldName];
    };

    const clearServerError = (type) => {
        const tempObj = { ...serverResponseErrors };
        if (tempObj[type]) {
            delete tempObj[type];
            setServerResponseErrors(tempObj);
        }
    };

    const submitForm = () => {
        resetPassword({
            code: code.split('=', code.length)[1],
            password: form.password,
            passwordRepeat: form.passwordRepeat,
        }).then((res) => {
            try {
                if (res.success) {
                    if (mobileUserAgent) {
                        history.push('/');
                    }
                    llNotifier({
                        message: 'Password changed successfully',
                        type: 'success',
                    });
                } else {
                    switch (res.data.error) {
                        case 'WRONG_REPEATED':
                            setServerResponseErrors({ passwordRepeat: res.data.error });
                            break;
                        case 'PASSWORD_EMPTY':
                        case 'PASSWORD_WRONG_FORMAT':
                            setServerResponseErrors({ password: res.data.error });
                            break;
                        default:
                            llNotifier({
                                message: res.data.error,
                                type: 'error',
                            });
                            break;
                    }
                }
            } catch {
                llNotifier({
                    message: 'GENERAL_ERROR',
                    type: 'error',
                });
            }
        });
    };

    const commonInputProps = {
        labelPosition: 'inside',
        showClearButton: true,
        stylingMode: 'outlined',
        maxLength: 100,
        mode: 'password',
    };

    return form.codeIsValid ? (
        <>
            <p className="reset-password__header">{trans('Fill the form please')}</p>
            <ValidationGroup className="reset-password__form-container">
                <div className="reset-password__fields-container">
                    <LLInput
                        {...commonInputProps}
                        label={trans('New password')}
                        value={form.password}
                        id="reset-password__new-password"
                        name="reset-password__new-password"
                        onValueChanged={(e) => {
                            setForm({
                                ...form,
                                password: e.value,
                                pwdStrength: passwordStrength(e.value),
                            });
                            clearServerError('password');
                        }}
                    >
                        <Validator>
                            <PatternRule
                                message={trans(
                                    'You have entered incorrect character in password'
                                )}
                                pattern={
                                    '^[a-zA-ZğüşöçİĞÜŞÖÇ0-9~!@#$%^*)(?_\\-\\\\.,\';<>:"/]*$'
                                }
                            />
                            <RequiredRule message={trans('PASSWORD_EMPTY')} />
                            <CustomRule
                                message={trans(
                                    'At least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters required'
                                )}
                                validationCallback={(e) =>
                                    passwordStrength(e.value) === 100
                                }
                            />
                            <CustomRule
                                message={trans(serverResponseErrors['password'])}
                                validationCallback={(e) =>
                                    serverValidationCheck(e, 'password')
                                }
                            />
                        </Validator>
                    </LLInput>
                    <LLInput
                        {...commonInputProps}
                        label={trans('Repeat new password')}
                        value={form.passwordRepeat}
                        id="reset-password__repeat-new-password"
                        name="reset-password__repeat-new-password"
                        onPaste={(e) => e.event.preventDefault()}
                        onValueChanged={(e) => {
                            setForm({
                                ...form,
                                passwordRepeat: e.value,
                            });
                            clearServerError('passwordRepeat');
                        }}
                    >
                        <Validator>
                            <RequiredRule message={trans('REPEAT_PASSWORD_EMPTY')} />
                            <CompareRule
                                message="WRONG_REPEATED"
                                comparisonTarget={() => form.password}
                            />
                            <CustomRule
                                message={trans(serverResponseErrors['passwordRepeat'])}
                                validationCallback={(e) =>
                                    serverValidationCheck(e, 'passwordRepeat')
                                }
                            />
                        </Validator>
                    </LLInput>
                    {!mobileUserAgent ? (
                        <LLBtn
                            className={`reset-password__submit-btn`}
                            useSubmitBehavior={false}
                            onClick={(e) => {
                                let result = e.validationGroup.validate();
                                if (result.isValid) {
                                    submitForm();
                                }
                            }}
                        >
                            <div>
                                <span>{trans('Submit')}</span>
                            </div>
                        </LLBtn>
                    ) : null}
                </div>

                <PasswordStrengthBar form={form} setForm={setForm} />

                {mobileUserAgent ? (
                    <LLBtn
                        className={`reset-password__submit-btn`}
                        useSubmitBehavior={false}
                        onClick={(e) => {
                            let result = e.validationGroup.validate();
                            if (result.isValid) {
                                submitForm();
                            }
                        }}
                    >
                        <div>
                            <span>{trans('Submit')}</span>
                        </div>
                    </LLBtn>
                ) : null}
            </ValidationGroup>
        </>
    ) : (
        <div className="reset-password__wrong-code">
            <p>{trans('WRONG_CODE')}</p>
            <p>
                {trans(
                    'You will be redirected to main page in {{TIMER}} seconds'
                ).replace('{{TIMER}}', timer)}
            </p>
        </div>
    );
});

export default ResetPasswordForm;
