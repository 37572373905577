import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Validator,
    RequiredRule,
    CustomRule,
} from 'devextreme-react/validator';
import RadioGroup from 'devextreme-react/radio-group';
import Autocomplete from 'devextreme-react/autocomplete';
import SelectBox from 'devextreme-react/select-box';

import {LLInput, LLDatePicker, withCommon} from '../../../index';
import {countryTelephoneCodes, currencySymbols, countryCodes} from '../../../../configs';

/**
 * @file Represents second part of SignUp form;
 * @memberOf SignUpDesktop, SignUpMobile
 *
 * @param {func} clearServerError - clear errors in state
 * @param {array} currencies - list of available currencies from server
 * @param {object} form - data from all fields in form
 * @param {string} name - name data
 * @param {string} surname - surname data
 * @param {string} gender - gender data
 * @param {string} countryCode - countryCode data
 * @param {string} city - city data
 * @param {string} address - address data
 * @param {string} idNumber - idNumber data
 * @param {string} phone - phone data
 * @param {boolean} mobileUserAgent - detect mobile version
 * @param {string} language - active user language
 * @param {object} serverResponseErrors - list of errors from server if server send errors
 * @param {func} serverValidationCheck - custom validation in brouser
 * @param {func} setForm - save data from field to state
 *
 * @author A.Green
 */

const FormSecondPart = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
        language: store.user.language,
        currencies: store.site.currencies,
    }),
    PropTypes: {
        clearServerError: PropTypes.func.isRequired,
        currencies: PropTypes.arrayOf(PropTypes.string.isRequired),
        form: PropTypes.shape({
            name: PropTypes.string.isRequired,
            surname: PropTypes.string.isRequired,
            gender: PropTypes.string.isRequired,
            countryCode: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
            idNumber: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
        }),
        language: PropTypes.string.isRequired,
        serverResponseErrors: PropTypes.object.isRequired,
        serverValidationCheck: PropTypes.func.isRequired,
        setForm: PropTypes.func.isRequired,
    },
    isTrans: true,
})(
    ({
         clearServerError,
         currencies,
         form,
         form: {name, surname, gender, countryCode, city, address, idNumber, phone},
         viewSize,
         language,
         serverResponseErrors,
         serverValidationCheck,
         setForm,
         trans,
    }) => {
        const
            [datePickerOpen, setDatePickerOpen] = useState(false),
            [countryInputValue, setCountryInputValue] = useState(Object.keys(countryCodes[language]).find((key) => countryCodes[language][key] === countryCode));
        const commonInputProps = {
            labelPosition: 'inside',
            showClearButton: true,
            stylingMode: 'outlined',
        };

        const dateOfBirthAndIdFields = <>
            <div className="signup__date-of-birth signup__field">
                <LLDatePicker
                    open={datePickerOpen}
                    selected={form.dateOfBirth}
                    onBlur={() => {
                        setDatePickerOpen(false);
                        clearServerError('dateOfBirth');
                    }}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            dateOfBirth: e,
                        });
                        clearServerError('dateOfBirth');
                        setDatePickerOpen(false);
                    }}
                    maxDate={
                        new Date(
                            new Date().setFullYear(new Date().getFullYear() - 18)
                        )
                    }
                    className="signup__date-of-birth"
                    language={language}
                    dateFormat="dd-MM-yyyy"
                    customInput={
                        <LLInput
                            id="sign-up__date-of-birth"
                            name="sign-up__date-of-birth"
                            onFocusIn={() => {
                                setDatePickerOpen(true);
                            }}
                        >
                            <Validator>
                                <RequiredRule
                                    message={trans('This field is required')}
                                />
                                <CustomRule
                                    message={trans(serverResponseErrors.dateOfBirth || 'NOT18_ERROR')}
                                    validationCallback={(e) =>
                                        serverValidationCheck(e, 'dateOfBirth')
                                    }
                                />
                            </Validator>
                        </LLInput>
                    }
                />
            </div>
            <LLInput
                {...commonInputProps}
                label={trans(countryCode === 'TR' ? 'Id' : 'Passport')}
                maxLength={countryCode === 'TR' ? 11 : 50}
                value={idNumber}
                customClasses="sign-up__id"
                id="sign-up__id"
                name="sign-up__id"
                //mask={countryCode === 'TR' ? '###########' : ''}
                // '#' - a digit, a space, "+" or "-" sign,
                // '0' - a digit,
                // '' - any value
                // TODO: доделать вырехзалку не цитфро из телефона в турции
                onValueChanged={(e) => {
                    setForm({
                        ...form,
                        idNumber: e.value,
                    });
                    clearServerError('idNumber');
                }}
            >
                <Validator>
                    <RequiredRule
                        message={trans(countryCode === 'TR' ? 'ID_EMPTY' : 'Please enter your passport number')}/>
                    <CustomRule
                        message={trans(serverResponseErrors.idNumber || 'WRONG_TR_ID')}
                        validationCallback={(e) =>
                            serverValidationCheck(e, 'idNumber')
                        }
                    />
                </Validator>
            </LLInput>
        </>;

        return (
            <div className="signup__part-two">
                <div className="signup__name-container">
                    <LLInput
                        {...commonInputProps}
                        customClasses={'signup__name'}
                        label={trans('Name')}
                        maxLength={50}
                        value={name}
                        id="sign-up__name"
                        name="sign-up__name"
                        onValueChanged={(e) => {
                            setForm({
                                ...form,
                                name: e.value,
                            });
                            clearServerError('name');
                        }}
                    >
                        <Validator>
                            <RequiredRule message={trans('NAME_EMPTY')}/>
                            <CustomRule
                                message={trans(serverResponseErrors['name'])}
                                validationCallback={(e) =>
                                    serverValidationCheck(e, 'name')
                                }
                            />
                        </Validator>
                    </LLInput>
                    <LLInput
                        {...commonInputProps}
                        customClasses={'signup__surname'}
                        label={trans('Surname')}
                        maxLength={50}
                        value={surname}
                        id="sign-up__surname"
                        name="sign-up__surname"
                        onValueChanged={(e) => {
                            setForm({
                                ...form,
                                surname: e.value,
                            });
                            clearServerError('surname');
                        }}
                    >
                        <Validator>
                            <RequiredRule message={trans('SURNAME_EMPTY')}/>
                            <CustomRule
                                message={trans(serverResponseErrors['surname'])}
                                validationCallback={(e) =>
                                    serverValidationCheck(e, 'surname')
                                }
                            />
                        </Validator>
                    </LLInput>
                </div>
                <div className="signup__gender signup__field">
                    <RadioGroup
                        items={['Male', 'Female']}
                        value={gender === 'M' ? 'Male' : 'Female'}
                        itemRender={(item) => trans(item)}
                        onValueChanged={(e) => {
                            setForm({
                                ...form,
                                gender: e.value === 'Male' ? 'M' : 'F',
                            });
                        }}
                    />
                </div>

                {viewSize === 'mobile' ? dateOfBirthAndIdFields : <div className="signup_double">{dateOfBirthAndIdFields}</div>}

                <div className="signup_double">
                    <div className="signup__country signup__field">
                        <div className="signup__country-picker-container">
                            <img
                                className="signup__country-flag"
                                src={`/country-flags/4x3/${
                                    countryCode && countryCode.toLowerCase()
                                }.svg`}
                                alt=""
                            />
                            <Autocomplete
                                className="signup__country-picker"
                                dataSource={Object.keys(countryCodes[language])}
                                value={countryInputValue}
                                onValueChanged={(e) => {
                                    setCountryInputValue(e.value)
                                    if (countryCodes[language][e.value]) {
                                        setForm({
                                            ...form,
                                            countryCode: countryCodes[language][e.value],
                                        })
                                    }
                                }}
                                stylingMode={'outlined'}
                                minSearchLength={1}
                                searchMode="startswith"
                                inputAttr={{
                                    class: 'signup__country-picker-input',
                                }}
                                itemRender={(e) => (
                                    <>
                                        <img
                                            className="signup__country-flag"
                                            src={`/country-flags/4x3/${countryCodes[
                                                language
                                                ][e].toLowerCase()}.svg`}
                                            alt=""
                                        />
                                        {e}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <LLInput
                        {...commonInputProps}
                        customClasses={"sign-up__city"}
                        label={trans('City')}
                        maxLength={50}
                        value={city}
                        id="sign-up__city"
                        name="sign-up__city"
                        onValueChanged={(e) =>
                            setForm({
                                ...form,
                                city: e.value,
                            })
                        }
                    />
                </div>
                <LLInput
                    {...commonInputProps}
                    label={trans('Address')}
                    maxLength={50}
                    value={address}
                    id="sign-up__address"
                    name="sign-up__address"
                    onValueChanged={(e) =>
                        setForm({
                            ...form,
                            address: e.value,
                        })
                    }
                />

                <div className="signup__currencies signup__field">
                    <div className="signup__currencies-container">
                        <div className="signup__currencies-symbol">
                            ({currencySymbols[form.currency]})
                        </div>
                        <SelectBox
                            items={currencies}
                            className="signup__currency-picker"
                            showDropDownButton={false}
                            value={form.currency}
                            onValueChanged={(e) =>
                                setForm({
                                    ...form,
                                    currency: e.value,
                                })
                            }
                            itemRender={(e) => (
                                <span>
                                    {currencySymbols[e]} {e}
                                </span>
                            )}
                        />
                    </div>
                </div>
                <LLInput
                    {...commonInputProps}
                    customClasses={'signup__phone'}
                    maxLength={countryCode === 'TR' ? 10 : 15}
                    value={phone}
                    id="sign-up__phone"
                    name="sign-up__phone"
                    adormentBefore={
                        <div className="signup__phone-flag-container">
                            <img
                                className="signup__phone-country-flag"
                                src={`/country-flags/4x3/${
                                    countryCode && countryCode.toLowerCase()
                                }.svg`}
                                alt=""
                            />
                            <span>{`${
                                countryCode && countryTelephoneCodes[countryCode]
                                    ? '+' + countryTelephoneCodes[countryCode][0]
                                    : ''
                            }`}</span>
                        </div>
                    }
                    // mode="tel"
                    // additionalInputAttr={{
                    //     type:"number"
                    // }}
                    // TODO: доделать вырехзалку не цитфро из телефона в турции
                    //mask={countryCode === 'TR' ? '#000 000 00 00' : ''}
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            phone: e.value,
                        });
                        clearServerError('phone');
                    }}
                >
                    <Validator>
                        <RequiredRule message={trans('PHONE_EMPTY')}/>
                        {form.countryCode === 'TR' ? (
                            <CustomRule
                                message={trans(
                                    'Your phone number should be started from "5"'
                                )}
                                validationCallback={(e) => e.value[0] === '5'}
                            />
                        ) : null}
                        {/* TODO: TEXT HERE */}
                        {/* {form.countryCode === 'TR' ? (
                                <StringLengthRule
                                    message={trans(
                                        'PLEASE FILL ALL DIGITS'
                                    )}
                                    min={10}
                                />
                        ) : null} */}
                        <CustomRule
                            message={trans(serverResponseErrors['phone'])}
                            validationCallback={(e) => serverValidationCheck(e, 'phone')}
                        />
                    </Validator>
                </LLInput>
            </div>
        );
    }
);

export default FormSecondPart;
