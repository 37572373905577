import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

import {BreadcrumbsDropdown} from "../index";
import {LiveScoreboard} from '../../components/common';


const LiveScoreboardWithBreadcrumbs = (
    {
        store: {
            betting: {
                setNavigationDropdown
            }
        },
        viewSize,
        matchInstance,
        language,
        type,
    }
) => {
    const [matchesDropdownShown, setMatchesDropdownShown] = useState(false);

    useEffect(() => {
        setTimeout(() => setNavigationDropdown(matchesDropdownShown), 200)
    }, [matchesDropdownShown]);

    return (
        <LiveScoreboard
            match={matchInstance}
            type={type}
            viewSize={viewSize}
            language={language}
            matchesDropdownShown={matchesDropdownShown}
        >
            <BreadcrumbsDropdown
                type={type}
                matchesDropdownShown={matchesDropdownShown}
                setMatchesDropdownShown={setMatchesDropdownShown}
                routerMatch={matchInstance}
            />
        </LiveScoreboard>
    );
};

export default inject('store')(observer(LiveScoreboardWithBreadcrumbs));