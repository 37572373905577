import {values} from 'mobx';
import {flow, getRoot, types} from "mobx-state-tree";
import {sortBy, xor} from 'lodash';

import api from "../../../common/api";
import llattempt from "../../../common/utils/attempt";


const WidgetSlideGame = types.model('WidgetSlideGame', {
    mobileLink: types.maybeNull(types.string),
    desktopLink: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    gameImg: types.maybeNull(types.string),
    gameId: types.maybeNull(types.string),
    gameName: types.maybeNull(types.string),
    thumbnail: types.maybeNull(types.string),
})

const WidgetSlide = types
    .model('WidgetSlide', {
        slideId: types.identifierNumber,
        sortOrder: types.number,
        typeId: types.number,
        eventId: types.maybeNull(types.number),
        marketGroupId: types.maybeNull(types.union(types.number, types.array(types.number))),
        marketId: types.maybeNull(types.union(types.number, types.array(types.string))),
        title: types.maybeNull(types.string),
        games: types.array(WidgetSlideGame),
    })
    .volatile((s) => ({
        settings: {},
    }))
    .actions((self) => ({
        update(data) {
            self.eventId = data.eventId;
            self.marketGroupId = data.marketGroupId;
            self.marketId = data.marketId;
        },
        setSettings(settings) {
            self.settings = settings;
        },
    }))
    .views((self) => ({
        get isBetBuilder() {
            return self.typeId === 4
        },
        get betBuilderOdds() {
            return self.settings.betBuilderOdds;
        },
        get isSportSlide() {
            return [1, 2, 3, 4, 5].includes(self.typeId);
        },
    }));

const LLWidget = types
    .model('LLWidget', {
        slides: types.map(WidgetSlide),
        fetchTime: types.maybeNull(types.number),
    })
    .actions((self) => ({
        _putSportSlideData(eventId, match, storedEvent, widgetId) {
            storedEvent.setCompetitorsLogo();
            let market, marketId, marketGroupId;
            marketGroupId = match.marketGroups[0]?.i;
            market = match.marketGroups[0]?.m[0];
            const slide = self.slides.get(widgetId);
            if (slide) {
                const tempObj = {
                    eventId: +eventId,
                    marketId: market?.i,
                };
                if (slide.isBetBuilder) {
                    tempObj.marketGroupId = Array.isArray(slide.marketGroupId) ? [...slide.marketGroupId] : [];
                    tempObj.marketId = slide.marketId || [];
                    if (market?.b && Array.isArray(market?.b)) {
                        market?.b.map(bet => {
                            marketId = market.i + '_' + bet.i;
                            if (!tempObj.marketGroupId.includes(marketGroupId)) {
                                tempObj.marketGroupId.push(marketGroupId);
                            }
                            if (!tempObj.marketId.includes(marketId)) {
                                tempObj.marketId.push(marketId);
                            }
                        })
                    }
                } else {
                    tempObj.marketGroupId = marketGroupId;
                }
                slide.update(tempObj);
            }
        },
        _putSlide(slide) {
            const widgetTitles = JSON.parse(slide.Settings)?.widgetTitle;
            const widgetGames = JSON.parse(slide.Settings)?.games;
            let widgetTitle;
            let defaultWidgetTitle;

            if (!!widgetTitles) {
                widgetTitle = widgetTitles[getRoot(self).user.language] || '';
                defaultWidgetTitle = widgetTitles[Object.keys(widgetTitles).find(key => widgetTitles[key].length > 0)] || '';
            }

            self.slides.put({
                slideId: slide.WidgetID,
                sortOrder: slide.SortOrder,
                typeId: slide.WidgetTypeId,
                title: widgetTitle ? widgetTitle : defaultWidgetTitle,
                games: widgetGames ? widgetGames : []
            });
            self.slides.get(slide.WidgetID).setSettings(JSON.parse(slide.Settings))
        },
        successInstruction({data} = {}) {
            self.slides.clear();
            data.forEach(item => {
                self._putSlide(item);
                if ([1, 2, 3, 4, 5].includes(item.WidgetTypeId)) {
                    getRoot(self).betting.matches.setMatchesData({
                        isFeatured: true,
                        data: item.data,
                        addFilteredMatchesIds: self._putSportSlideData,
                        isBetBuilder: item.WidgetTypeId === 4,
                        widgetId: item.WidgetID
                    })
                }
            })
            self.fetchTime = Date.now();
        },
        unsuccessInstruction({err} = {}) {
            console.log(err)
        },
        getWidgetItems: flow(function* fetch() {
            return llattempt(
                () => api.betting.getWidgetItems()
                    .then((res) => res),
                {
                    withNotifier: false,
                    at: 'getWidgetItems',
                }
            );
        }),
        placeCustomFeaturedBet(event) {
            const user = getRoot(self).user;
            if (user && !user.coupons.fetching) {
                if (!self.isCustomFeaturedActive(self.betBuilderBetIds(event))) {
                    user.coupons.add({
                        custom: true,
                        type: 'PreMatch',
                        oddsId: self.betBuilderBetIds(event),
                        source: 1,
                    });
                } else {
                    user.coupons.clear();
                }
            }
        },
    }))
    .views((self) => ({
        get widgetsList() {
            return sortBy(
                values(self.slides).reduce((acc, item) => {
                    const event = getRoot(self).betting.matches.matches.get(item.eventId);
                    if (!item.isSportSlide) {
                        if (getRoot(self).site.status.viewSize === 'desktop' && item.settings?.desktopLink) {
                            return [...acc, item];
                        }
                        if (getRoot(self).site.status.viewSize !== 'desktop' && item.settings?.mobileLink) {
                            return [...acc, item];
                        }
                        return acc;
                    } else {
                        if (event && (item.typeId !== 4 || item.betBuilderOdds)) {
                            return [
                                ...acc,
                                {
                                    ...event,
                                    matchUrl: event.matchUrl,
                                    isSportSlide: item.isSportSlide,
                                    typeId: item.typeId,
                                    slideId: item.slideId,
                                    sortOrder: item.sortOrder,
                                    betBuilderOdds: item.betBuilderOdds,
                                    featuredMarketId: item.marketId,
                                    featuredMarketGroupId: item.marketGroupId,
                                    title: item.title,
                                }
                            ];
                        } else {
                            return acc;
                        }
                    }
                }, []),
                ['sortOrder'], ['asc'],
            );
        },
        get isUpdateCleanable() {
            return !!getRoot(self).betting.activeItems.tournaments.length
        },
        featuredMarket(event) {
            return event.marketGroups
                .get(event.featuredMarketGroupId)?.markets
                .get(event.featuredMarketId);
        },
        betBuilderBets(event) {
            return event.featuredMarketGroupId.reduce((acc, mGroupId) => {
                const markets = event.featuredMarketId.reduce((acc, marketIdString) => {
                    const [marketId, betId] = marketIdString.split('_');
                    const market = event.customMarketGroups.get(mGroupId)?.markets.get(marketId);
                    return market?.bets.get(betId) ? [
                        ...acc,
                        {
                            market,
                            bet: market.bets.get(betId),
                        }
                    ] : acc;
                }, []);
                return [...acc, ...markets]
            }, []);
        },
        betBuilderBetIds(event) {
            return self.betBuilderBets(event).map(item => {
                return item.bet.id
            });
        },
        isCustomFeaturedActive(bets) {
            const couponListBetIds = getRoot(self).user.coupons.customCouponsList.map(item => item.id).sort();
            const betIds = bets.map(item => item.bet?.id).sort();
            return !xor(couponListBetIds, betIds).length;
        },
    }));

export default LLWidget;
