import React from 'react';
import { inject, observer } from 'mobx-react';

import { LLElementTypeSetter } from '../../../../components';
import { trans } from '../../../../common/utils';
import LiveCalendarData from './components/live-calendar-data/live-calendar-data';


const LiveCalendarPage = ({ store }) => {
    const viewSize = store.site.status.viewSize;

    return (
        <div className="live-calendar">
            {viewSize !== 'mobile' && (
                <div className="live-calendar__top-title-line">
                    {viewSize === 'tablet' && (
                        <LLElementTypeSetter
                            onClick={() => {
                                store.betting.liveMatches.setCalendar(false);
                            }}
                            className="live-calendar__back-button"
                        >
                            <i className="live-calendar__back-button-icon llg-chevron-left" />
                            <span>{trans('Back')}</span>
                        </LLElementTypeSetter>
                    )}
                    <h3 className="live-calendar__title">
                        <i className="live-calendar__title-icon llg-calendar" />
                        <span>{trans('Calendar')}</span>
                    </h3>
                </div>
            )}
            <LiveCalendarData />
        </div>
    );
};

export default inject('store')(observer(LiveCalendarPage));
