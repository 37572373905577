import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withCommon } from '../../../components';
import { LLHeaderMenu } from '../header-desktop/components';
import config from "../../../static/themes/config";


const LLHeaderMobile = withCommon({
    store: ({ store }) => ({
        siteName: store.site.siteName,
        open: store.drawer.open,
        isLogged: store.user.isLogged,
        activeBetsCount: store.user.activeBetsCount,
        openLogin: store.user.openLogin,
        setStatusFilter: store.user.userBets.setStatusFilter,
    }),
    PropTypes: {
        open: PropTypes.func.isRequired,
    },
    isObserver: true,
})(({ open, setStatusFilter, isLogged, openLogin, activeBetsCount, siteName }) => {

    let bananaDefender = true;

    useEffect(() => () => bananaDefender = false, []);

    useEffect(() => {
        if (activeBetsCount && bananaDefender) {
            const t = document.querySelector('.js_active-bets-count');
            t.classList.add('animated');
            setTimeout(() => {
                t.classList.remove('animated');
            }, 300);
        }
    }, [activeBetsCount]);

    return <div className="ll-header--mobile">
        <div className="ll-header__buttons-container">
            <div
                className="ll-header__menu-button"
                onClick={() =>
                    open({ side: 'left', currentKey: 'navigation-menu-drawer' })
                }
            >
                <i className="ll-header__button-icon llg-bars" />
            </div>

            <Link to="/" className="ll-header__logo" />

            <div className="ll-header__menu-button">
                {config(siteName)?.siteConfig.headerTvButton ?
                    <a
                        className={`ll-header__button`}
                        href={config(siteName).siteConfig.headerTvButton.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <i className={`ll-header__button-icon active-bets__icon ${config(siteName).siteConfig.headerTvButton.icon}`} />
                    </a>
                    : null
                }
                <div onClick={() => {
                    if (isLogged) {
                        setStatusFilter('activeBets');
                        open({side: 'right', currentKey: 'my-bets-drawer'});
                    } else {
                        openLogin()
                    }
                }}>
                <i className="ll-header__button-icon active-bets__icon doruk-active-bets">
                    {(activeBetsCount) ?
                        <div className="active-bets__icon-count js_active-bets-count">
                            {activeBetsCount}
                        </div>
                        : null
                    }
                </i>
                </div>
            </div>
        </div>
        <LLHeaderMenu customClasses="ll-header__menu-container" />
    </div>
});

export default LLHeaderMobile;
