import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withCommon, LLPreloader } from '../../../components';

/**
 * @file Represents React Component to render
 *
 * @param {string || object} customClasses - defince classNames
 * @param {bool} isLoading - flag, show or hide preloader
 * @param {number} id - button id
 * @param {JSX.Element} children - list of children elements
 *
 * @author
 */

const LLBtn2 = withCommon({
    defaultProps: {
        isLoading: false,
    },
    propTypes: {
        customClasses: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
        isLoading: PropTypes.bool,
        id: PropTypes.number,
    },
    defaultName: 'LLBtn2',
    isObserver: true,
})(({ customClasses, children, isLoading = false, id, customRef, ...restProps }) => (
    <button
        className={cx('ll-btn2', customClasses)}
        id={id}
        ref={customRef}
        {...restProps}
    >
        {isLoading && (
            <div className={cx('preloader-box', isLoading && 'active')}>
                <LLPreloader height={15} width={15} />
            </div>
        )}
        <div className={cx('ll-btn__content', !isLoading && 'active')}>{children}</div>
    </button>
));

export default LLBtn2;
