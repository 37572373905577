import { types as t, getParent, getRoot, flow } from 'mobx-state-tree';
import { values } from 'mobx';
import { sortBy } from 'lodash';

import Categories from './categories';
import BranchItem from './branch-item';
import WithFlags from '../../with-flags';
import { branchFetches, categoriesActions } from './branch-instances-actions';
import { refreshTime, unsuccessCountLimit } from '../../config';

/*
 ######  ########   #######  ########  ########  ######
##    ## ##     ## ##     ## ##     ##    ##    ##    ##
##       ##     ## ##     ## ##     ##    ##    ##
 ######  ########  ##     ## ########     ##     ######
      ## ##        ##     ## ##   ##      ##          ##
##    ## ##        ##     ## ##    ##     ##    ##    ##
 ######  ##         #######  ##     ##    ##     ######
*/

const Sports = t
    .model('Sports', {
        categories: t.map(t.compose(Categories, BranchItem, WithFlags)),
        isParent: t.maybeNull(t.boolean),
        parentId: t.maybeNull(t.integer),
        liveMatchesCount: t.maybeNull(t.integer),
    })
    .extend((s) => ({
        actions: { ...branchFetches(s).categories, ...categoriesActions(s) },
    }))
    .actions((s) => ({
        setMatchCount(count) {
            s.liveMatchesCount = count;
        },

        deleteItem(id) {
            s.categories.delete(id);
        },

        removeItems: flow(function* fetch() {
            yield s.deleteOldCategories({ categories: [] });
            getParent(s, 2).deleteItem(s.id);
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Scenarios instructions                                ##
        // ##                                                                                      ##
        // ##========================================================================================

        unsuccessCategoriesInitializeInstruction({ res } = {}) {
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            getRoot(s).betting.setError({ type: 'global', error });
        },

        unsuccessCategoriesUpdateInstruction({ parentSportId, res } = {}) {
            s.setUnsuccessCount(s.unsuccessCount + 1);

            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            if (s.unsuccessCount >= unsuccessCountLimit) {
                getRoot(s).betting.setError({ type: 'global', error: error });
            } else {
                s.setWaitingUpdate(false);
                s.setUpdateForCategories({ parentSportId });
            }
        },

        successCategoriesInitializeInstruction({
            parentSportId,
            incomingCategoriesData,
            requestedCategoryId,
        } = {}) {
            s.setUnsuccessCount(0);
            const betting = getRoot(s).betting;

            if (incomingCategoriesData.length === 0 && betting.branch.initialFetching) {
                betting.setError({ type: 'global', error: 'GENERAL_ERROR' });
            } else {
                s.putUpdateData({
                    dataSource: incomingCategoriesData,
                    requestedCategoryId,
                });
                s.setWaitingUpdate(false);
                s.setUpdateForCategories({ parentSportId });
            }
        },

        successCategoriesUpdateInstruction({
            parentSportId,
            incomingCategoriesData,
        } = {}) {
            if (!incomingCategoriesData.length) {
                s.setUnsuccessCount(s.unsuccessCount + 1);
                if (s.unsuccessCount >= unsuccessCountLimit) {
                    getRoot(s).betting.setError({
                        type: 'global',
                        error: 'GENERAL_ERROR',
                    });
                }
            } else {
                s.setUnsuccessCount(0);
            }

            s.putUpdateData({ dataSource: incomingCategoriesData });
            s.deleteOldCategories({ categories: incomingCategoriesData });

            s.setWaitingUpdate(false);
            s.setUpdateForCategories({ parentSportId });
        },

        // ##========================================================================================

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                              Categories getter instruction                             ##
        // ##                                                                                      ##
        // ##========================================================================================

        initializeCategoriesInstruction: flow(function* fetch({
            id: parentSportId,
            requestedCategoryId,
        } = {}) {
            initializeCategoriesInstruction: {
                if (
                    s.lastFetchTime &&
                    Date.now() < +new Date(+s.lastFetchTime + refreshTime.categories)
                ) {
                    const timeLeft =
                        +new Date(+s.lastFetchTime + refreshTime.sports) - Date.now();
                    s.setWaitingUpdate(false);
                    s.setUpdateForCategories({ parentSportId, timeLeft });
                    break initializeCategoriesInstruction;
                }

                s.setFetching({ type: 'initialFetching', status: true });

                const res = yield s.fetchAndConvertDataForCategories({
                    sportId: parentSportId,
                }) || {};

                if (!s.check.cantUpdateData) {
                    if (!res || !res.success) {
                        s.unsuccessCategoriesInitializeInstruction({ res });
                        if (getRoot(s).betting.branch.initialFetching) {
                            throw new Error(res.error);
                        }
                    } else {
                        s.successCategoriesInitializeInstruction({
                            parentSportId,
                            incomingCategoriesData: res.data,
                            requestedCategoryId,
                        });
                    }
                }
                s.setFetching({ type: 'initialFetching', status: false });

                s.setLastFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                              Update Categories instruction                             ##
        // ##                                                                                      ##
        // ##========================================================================================

        updateCategoriesInstruction: flow(function* fetch({ parentSportId }) {
            updateCategoriesInstruction: {
                if (s.check.isUpdateCleanable) {
                    s.setWaitingUpdate(false);
                    clearTimeout(window.__categoriesUpdater);
                    break updateCategoriesInstruction;
                }

                if (!s.check.canGetData) {
                    s.setWaitingUpdate(false);
                    s.setUpdateForCategories({ parentSportId });
                    break updateCategoriesInstruction;
                }

                s.setFetching({ type: 'isFetching', status: true });

                const res = {
                    ...(yield s.fetchAndConvertDataForCategories({
                        sportId: parentSportId,
                    })),
                };

                if (!s.check.cantUpdateData) {
                    if (!res || !res.success) {
                        s.unsuccessCategoriesUpdateInstruction({
                            parentSportId,
                            res,
                        });
                    } else {
                        s.successCategoriesUpdateInstruction({
                            parentSportId,
                            incomingCategoriesData: res.data,
                        });
                    }
                }
                s.setFetching({ type: 'isFetching', status: false });
                s.setLastFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                     Update setter                                    ##
        // ##                                                                                      ##
        // ##========================================================================================

        setUpdateForCategories({ parentSportId, timeLeft }) {
            if (!s.isWaitingUpdate && !getRoot(s).betting.bettingErrors.global) {
                s.setWaitingUpdate(true);
                clearTimeout(window.__categoriesUpdater);
                window.__categoriesUpdater = setTimeout(
                    () => s.updateCategoriesInstruction({ parentSportId }),
                    timeLeft ?? refreshTime.categories
                );
            }
        },
    }))
    .views((s) => ({
        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                 View sub sports list                                 ##
        // ##                                                                                      ##
        // ##========================================================================================

        get listSubSports() {
            return sortBy(
                values(getParent(s, 2).sports).reduce((a, subSport) => {
                    return subSport.parentId == s.id ? [...a, subSport] : a;
                }, []),
                [
                    'order',
                    (item) => {
                        return item?.name?.toLowerCase();
                    },
                ]
            );
        },

        get check() {
            const betting = getRoot(s).betting;

            return {
                get canGetData() {
                    return getRoot(s)?.site?.status.isActive && betting.sportMountStatus;
                },

                get cantUpdateData() {
                    return !getParent(s, 2);
                },

                isMenuExpandable: !betting?.requestedDataLoading,

                isMobile: getRoot(s)?.site?.status.viewSize === 'mobile',

                get isUpdateCleanable() {
                    let flag =
                        getParent(s, 2)?.id !== getRoot(s)?.betting.branchId ||
                        !getParent(s, 2) ||
                        (!betting.activeItems.isActive({ id: s.id, type: 'sports' }) &&
                            !betting.activeMenuItems.isActive({
                                id: s.id,
                                type: 'sports',
                            }));
                    if (!flag) {
                        return !!(this.isMobile
                            ? !!betting.activeItems.matches.length
                            : false);
                    }
                    return flag;
                },
            };
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                  View Categories list                                ##
        // ##                                                                                      ##
        // ##========================================================================================

        get categoriesList() {
            const language = getRoot(s).user.language;
            return sortBy(
                values(s.categories)
                    .reduce((a, category) => {
                        return category?.parentId ? a : [...a, category];
                    }, [])
                    .sort((a, b) =>
                        a.name
                            ?.toLowerCase()
                            .localeCompare(b.name?.toLowerCase(), language)
                    ),
                ['order']
            );
        },
    }));

export default Sports;
