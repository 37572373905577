export const en = {
    /* CASINO / SLOTS */
    FUN_PLAY_BTN: 'Play for fun',
    REAL_PLAY_BTN: 'Real play',

    /* PROVIDERS (ist from prod )*/
    'KALAMBA': 'KALAMBA',
    'SPINOMENAL': 'SPINOMENAL',
    'YGGDRASIL': 'YGGDRASIL',
    'WAZDAN': 'WAZDAN',
    'BTG': 'BIGTIMEGAMING',
    'BOOONGO': 'BOOONGO',
    'REDTIGER': 'REDTIGER',
    'BLUEPRINT': 'BLUEPRINT',
    'TRUELAB': 'TRUELAB',
    'PUSH': 'PUSH GAMING',
    'QUICKSPIN': 'QUICKSPIN',
    'SOFTSWISS': 'BGAMING',
    'BOOMING': 'BOOMING GAMES',
    'TKICK': 'THUNDERKICK',
    'NOLCITY': 'NOLIMITCITY',
    'REDRAKE': 'REDRAKE',
    'PRAGMPLAY': 'PRAGMATIC PLAY',
    'SPRIBE': 'SPRIBE',
    'ENDORPHINA': 'ENDORPHINA',
    'MRSLOTTY': 'MR SLOTTY',
    'AMATIC': 'AMATIC',
    'BETSOFT': 'BETSOFT',
    'EGT': 'EGT',
    'HABANERO': 'HABANERO',
    'ISOFTBET': 'ISOFTBET',
    'NETENT': 'NETENT',
    'PARIPLAY': 'PARIPLAY',
    'PGSOFT': 'PGSOFT',
    'PLAYSON': 'PLAYSON',
    'RGAMING': 'RELAX GAMING',
    'WORLDMATCH': 'WORLDMATCH',
    '1X2LEAP': '1X2',
    'HACKSAW': 'HACKSAW',
    'PRAGMLIVE': 'PRAGMATIC',
    '1LIVE': '1LIVE',
    'EZUGI': 'EZUGI',
    'EVOLUTION': 'EVOLUTION',
    'VIVO': 'VIVO GAMING',
    'ATMOSFERA': 'ATMOSFERA',
    'LSTREAK': 'LSTREAK',


    /* SAVE COUPONS ERROR CODES */
    //ususal
    SAVE_ERROR1: 'Stake can not be zero or smaller than zero!',
    'SAVE_ERROR2': 'You have to at least choose a system!',
    'SAVE_ERROR3': 'Administrators has no right to make coupons!',
    'SAVE_ERROR4': 'You must have at least #1 events in your coupon!',
    'SAVE_ERROR5': 'You have no right to make a combined coupons!',
    'SAVE_ERROR6': 'You have no right to make prematch coupons!',
    'SAVE_ERROR7': 'You have no right to make outright coupons!',
    'SAVE_ERROR8': 'You have no right to make live coupons!',
    'SAVE_ERROR9': 'Minimum stake must be #1 !',
    'SAVE_ERROR10': 'Maximum stake can be #1 !',
    SAVE_ERROR11:
        'You have reached your daily betslip limit. Limits reset everyday at 00:00!',
    'SAVE_ERROR12': 'Unsufficient balance!',
    'SAVE_ERROR13': 'Maximum stake for this coupon can be #1',
    'SAVE_ERROR13_1': 'Maximum stake for this coupon can be #1',
    'SAVE_ERROR14': 'Your betting limits for events below: #1 ',
    'SAVE_ERROR14_1': 'Coupon stake amount for one or more bet types are exceeded',
    'SAVE_ERROR14_2': 'Remaining limit for {E#1} is {E#2}',
    SAVE_ERROR15: 'Events below are closed or started: #1',
    SAVE_ERROR16: 'Odds below has changed: #1 ',
    SAVE_ERROR17: 'Events below are closed or stopped: #1',
    'SAVE_ERROR18': 'An error has occurred while saving coupon, please retry!',
    'SAVE_ERROR19': 'Events in your coupon has closed or stopped!',
    'SAVE_ERROR20': 'Please refresh your coupon!',
    'SAVE_ERROR22': 'Following Event(s) is suspended #1',
    'SAVE_ERROR23': 'Event bellow can’t be combined with other events: #1',
    'SAVE_ERROR24': 'This selection(s) can only be combined with events from same tournament: #1',
    //mts
    MTS_PROXY_ERROR: 'LTS connection error. Please contact support!',
    MTS_DELETE_ERROR: 'Coupon delete error!',
    MTS_SAVE_ERROR:
        'Your betslip is not accepted. Please contact with live support or try again in a couple of seconds. Code: #1',
    MTS_SAVE_ERROR1: 'Events below are closed or stopped: #1 ',
    MTS_SAVE_ERROR2: 'Selections belongs to events below are closed or stopped: #1 ',
    MTS_SAVE_ERROR3: 'Odds below has changed: #1 ',
    MTS_SAVE_ERROR4:
        "There're one or more related bets in your ticket, please check them and re-try!",
    //custom
    ODDS_ERROR: 'This selection currently not active',
    PRE_CLOSED: 'Event finished or option removed!',
    LIVE_CLOSED: 'Event finished or option removed!',
    OUTRIGHT_CLOSED: 'Event finished or option removed!',
    MAX_EVENTS_REACHED: 'You have reached maximum event count per coupon!',
    UNAVALABLE_FOR_COMBINE:
        'Event that you are trying to add is not suitable for combining with current coupon!',
    UNAVALABLE_FOR_COMBINE2: 'You can not combine outright with other types!',
    UNAVALABLE_FOR_COMBINE3: 'Bet Builder events can not be combined with other events!',
    EVENT_FINISHED: 'Event finished or option removed!',

    EVENT_NOT_FOUND: 'Event not found',
    EVENT_STARTED: 'Event started or not available!',

    // betbuilder
    BET_BUILDER_UNAVAILABLE_MARKET: 'The market is unavailable.',
    BET_BUILDER_NO_DATA_FOR_EVENT: 'No data for the event.',

    /* DELETE COUPONS ERROR CODES */
    COUPONDELETE_ALREADY: 'Betslip already cleared!',
    COUPON_ALREADY_CLOSED: 'Bet cancellation denied',
    COUPON_ALREADY_DELETED: 'Betslip already cleared!',
    COUPONDELETE_PASSED: 'This ticket can’t be cancelled. Time expired.',
    COUPONDELETE_LIVE: 'You can not delete coupons that contains live events!',
    COUPONDELETE_STARTED: 'Events below started, you can not delete coupon: #1 ',

    /* FORMS ERRORS */
    WRONG_TR_ID: 'Incorrect or missing Turkish ID',
    NAME_AND_SURNAME_ALREADY_REGISTERED: 'You already have an existing account. If you think this account does not belong to you, please contact live support.',
    PARAM_NOT_SET:
        'Some of the critical information was not received by server. Please, check entered info.',
    NAME_EMPTY: 'Please enter your name',
    SURNAME_EMPTY: 'Please enter your surname',
    USERNAME_EMPTY: 'Enter Username',
    EMAIL_EMPTY: 'Enter email',
    REPEAT_EMAIL_EMPTY: 'E-Mail repeat failed',
    EMAIL_NOT_MATCH: 'Email addresses does not match',
    PASSWORD_EMPTY: 'Please enter your password',
    REPEAT_PASSWORD_EMPTY: 'Passwords do not match',
    PASSWORD_SAME_AS_OLDPASSWORD: 'New password must be different than your current password',
    PASSWORD_NOT_MATCH: 'Passwords does not match',
    SEX_EMPTY: 'Choose Gender',
    ID_EMPTY: 'ID field empty',
    'At least 8 characters': 'At least 8 characters',
    WRONG_DATE_FORMAT: 'Incorrect date',
    COUNTRY_EMPTY: 'Choose Country',
    PHONE_EMPTY: 'Please enter your phone number',
    PHONE_TOO_SHORT: 'Phone number too short',
    PHONE_WRONG_FORMAT: 'Incorrect phone number',
    USERNAME_TOO_SHORT: 'Username must contain at least 3 characters',
    USERNAME_WRONG_FORMAT:
        'Username can contain only Latin letters, numbers and underscores',
    WRONG_EMAIL: 'Invalid E-mail address',
    USERNAME_ALREADY_EXISTS: 'Username exist',
    EMAIL_ALREADY_EXISTS: 'E-mail exists',
    PHONE_ALREADY_EXISTS: 'Phone exists',
    CURRENCY_EMPTY: 'Choose Currency',
    ID_NUMBER_WRONG_FORMAT:
        'ID field validates by your name, surname and year of birth. Please make sure you entered your details correctly.',
    ID_NUMBER_ALREADY_EXISTS: 'This ID number already in user with another account',
    PASSPORT_NUMBER_WRONG_FORMAT:
        'Incorrect passport number. If you entered it correctly and still got this error - please contact support.',
    PASSPORT_NUMBER_EXISTS: 'Passport already registered',
    USERNAME_TOO_LONG: 'Username too long',
    NAME_WRONG_FORMAT: 'Name contains disallowed characters',
    SURNAME_WRONG_FORMAT: 'Surname contains disallowed characters',
    CAPTCHA_ERROR: 'Enter CAPTCHA',
    PASSWORD_WRONG_FORMAT: 'Your password not strong enough or too short',
    WRONG_GOOGLE_CODE: 'Wrong code',
    WRONG_CODE: 'Wrong code',
    WRONG_REPEATED: 'Password does not match',
    WRONG_OLD_PASSWORD: 'Incorrect old password',
    NEW_PASSWORD_EQUALS_OLD: 'New password should be different from old password',
    WRONG_CAPTCHA: 'Please submit captcha',
    EMAIL_NOT_FOUND: 'E-mail not found',
    WRONG_AUTH_DATA: 'Wrong username or password!',
    EMAIL_NOT_VERIFIED: 'Please verify your E-Mail',
    TOO_MANY_RETRIES:
        'You have made 3 failed login attempts hence your account is blocked for 15 minutes. If you do not remember your password please follow the directions of the Forgot Password procedure.',
    FORM_REGISTRATION_REJECTED:
        'Your registration application is rejected. Thanks for your interest in Luckyland Gaming products.',
    FRAUD_DETECTED:
        'Your registration application is rejected. Thanks for your interest in Luckyland Gaming products.',
    LOGIN_MAILVERIFY: 'Mail address not verified!',
    AUTH_TIME_EXPIRED: 'Authentication time expired',
    WRONG_SMS_CODE: 'Wrong security code',
    PERMISSION_DENIED: 'Login failed. Check your information',
    IP_IS_BLACKLISTED:
        'Your account is suspended for 15 minutes. Please try again later.',

    /* BONUS ERROR CODES */
    BONUS_NOT_FOUND: 'The bonus you requested is expired or deleted by the website',
    BONUS_USED: 'You have already applied for this bonus',

    /* OTHER ERRORS (PAGES ERRORS) */
    Error: 'Error',
    TECHNICAL_ISSUES_ERROR:
        "Unplanned maintenance. Please try again later.",
    CONNECTION_ISSUES_ERROR:
        "Can't connect to server. Check your internet connection and try again",
    EMPTY_BETS_ERROR: 'No bets for this moment',
    EMPTY_LIVE_BETS_ERROR: 'No live bets for this moment',
    ERROR_404: 'Page not found',
    ERROR_404_HEAD: '404',
    GENERAL_ERROR: 'There was an error please try again later!',
    MAINTENANCE_ERROR: 'Please try again later.\n\n' +
        'In order to provide better service to you, our valuable players, our sites are undergoing planned maintenance.\n\n' +
        'The anticipated end time has been determined as 08:00.\n\n' +
        'Maintenance can be finished before or after the scheduled time.\n\n' +
        'Thank you for your understanding.',
    NOT_FOUND: 'There was an error please try again later!',
    NOT_FOUND_QRCODE: 'There was an error please try again later!',
    USER_NOT_FOUND: 'Wrong username or password!',
    WRONG_PASSWORD: 'Wrong username or password!',
    WRONG_PASSWORD_QRCODE: 'Wrong username or password!',
    SUSPENDED:
        'Your account is temporarily suspended. As soon as this process is completed your account will be re-activated. For further information you may contact our live support. Thank you for your understanding.',
    LOGIN_NEEDED: 'Please login',

    /**** BETTING ****/
    MENU_ALL: 'All',
    MENU_OUTRIGHTS: 'Champions Bets',
    MENU_OUTRIGHTS_20040: 'Your Odds',
    MENU_OUTRIGHTS_25500: 'World Cup Specials',
    PRE_LEFTMENU_STATISTICS_BTN: 'Statistics',
    PRE_LEFTMENU_LIVESCORES_BTN: 'Live Scores',
    PRE_TOP: 'Popular events',
    PRE_FAVORITE: 'Top events',
    PRE_TITLE_SPORTS: 'Sports',
    MATCH_TV_MODAL_HEADER: 'TV Broadcast',
    MATCH_STATISTICS_MODAL_HEADER: 'Match Statistics',
    /* SEARCH */
    SPORTS_SEARCH_NO_DATA_TEXT: 'No data found related to your search',
    SPORTS_SEARCH_TECHNICAL_ISSUES_ERROR: 'There was an error please try again later!',
    LIVE_CALENDAR_ERROR: 'Error getting live calendar games list',
    CALENDAR_NO_DATA_TEXT: 'No Result Found',
    /*** --------   */

    /* LANGUAGE */
    LANG_EN: 'English',
    LANG_TR: 'Türkçe',
    LANG_AA: 'عربي',

    /* sites dependence translations */
    HOME_SLOTS_TITLE_CIPROBET: 'Slot games',
    HOME_SLOTS_SUBTITLE_CIPROBET:
        'We have prepared hundreds of slot games and special bonuses for you!',
    BRANCH_PICKER_TITLE_DESKTOP: 'SPORTS BRANCHES',
    BRANCH_PICKER_TITLE_MOBILE: 'Filter time',
    USER_DISABLED: 'You do not have access to play this game. Please contact live support',

    /* Rest of trans */
    '1x2': '1x2',
    '1 Hour': '1 Hour',
    '12 Hour': '12 Hour',
    '3 Hour': '3 Hour',
    '6 Hour': '6 Hour',
    'A - Z': 'A - Z',
    'About Us': 'About Us',
    'Account report': 'Account report',
    'account-history': 'Account history',
    'Account info': 'Account info',
    'Account information': 'Account information',
    Account: 'Account',
    'Account suspended': '',
    ACTIVE_BETS_NO_DATA_TEXT: 'You have no open bets',
    Address: 'Address',
    Affiliate: 'Affiliate',
    All: 'All',
    'All Markets': 'All Markets',
    Amount: 'Amount',
    'At least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters required':
        'At least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters required',
    Available: 'Available',
    Aviator: 'aviator',
    'Away team': 'Away',
    Back: 'Back',
    Badminton: 'Badminton',
    Balance: 'Balance',
    Baseball: 'Baseball',
    Basketball: 'Virtual NBA',
    'Bet Games': 'Bet Games',
    'Bet on games': 'bet on games',
    'BET_DETAILS_NO_DATA': 'You have no details for this coupon',
    'BET_SLIP_EMPTY': 'Choose bet system',
    'Bet slip': 'Bet slip',
    'Bet slips': 'Bet slips',
    Bets: 'My Betslip',
    'betting-history': 'My Bets',
    Bettings: 'Bettings',
    Bonus: 'Bonus',
    'Bonus Talep': 'Bonus Talep',
    'Bonus points': 'Bonus points',
    'Bonus Rules': 'Bonus Rules',
    'Bonus/discount': 'Bonus/discount',
    CABINET_WITHDRAW_STATUS_NO_DATA_TEXT:
        'You have no withdraw status records for the chosen period of time',
    CABINET_BET_HISTORY_NO_DATA_TEXT: `You don't have bets for this period`,
    CABINET_ACCOUNT_HISTORY_NO_DATA_TEXT: `You have no account history records`,
    CABINET_CASINO_REPORT_NO_DATA_TEXT: `You have no casino report records`,
    Calendar: 'Calendar',
    Cancel: 'Cancel',
    'casino-report': 'Casino report',
    Casino: 'Casino',
    Casinos: 'Casinos',
    Category: 'Category',
    'Change password': 'Change Password',
    'Choose to bet': 'Choose to bet',
    City: 'City',
    'Clear Bet': 'Clear Bet',
    CLEAR_ALL_BETS: 'Do you want to clear all bets?',
    'Click on error to fix corresponding field':
        'Click on error to fix corresponding field',
    Close: 'Close',
    Code: 'Code',
    Combination: 'Combination',
    'Congratulations!': 'Congratulations!',
    'Contact': 'Contact',
    'Contact Information': 'Contact Information',
    'Contact Us': 'Contact Us',
    Country: 'Country',
    'Coupon number': '№',
    Credit: 'Credit',
    Currency: 'Currency',
    Date: 'Date',
    'Date of birth': 'Date of birth',
    'Date range': 'Date range',
    'Deposit Money': 'Deposit Money',
    'Deposit money': 'Deposit money',
    'Deposit options': 'Deposit options',
    deposits: 'Deposit',
    deposit: 'Deposit',
    'Details': 'Details',
    Delete: 'Delete',
    Deleted: 'Deleted',
    DELETE_COUPON_MODAL_TEXT: 'Are you sure to delete your betslip %%id%%?',
    'Deselect All': 'Deselect All',
    'Dog racing': 'Dog racing',
    'Don\'t have an account?': 'Don\'t have an account ?',
    'E-Mail': 'E-Mail',
    'E-mail repeat': 'E-mail repeat',
    'Error placing bet': 'Error placing bet',
    'Error 404': 'Error 404',
    Event: 'Event',
    BET_DETAILS_EVENT: 'Event',
    'F.A.Q.': 'F.A.Q.',
    Featured: 'Featured',
    Female: 'Female',
    'Fill the form please': 'Fill the form please',
    'Filter time': 'Filter time',
    'Filters': 'Filters',
    'Follow us at social media': 'Follow us at social media',
    'Football (Asian cup)': 'Football (Asian cup)',
    'Football (World cup)': 'Football (World cup)',
    'Football (Euro cup)': 'Football (Euro cup)',
    'Football (Nations cup)': 'Football (Nations cup)',
    'Football (Bundesliga)': 'Football (Bundesliga)',
    'Football (Champions cup)': 'Football (Champions cup)',
    'Football (England League)': 'Football (England League)',
    Football: 'Football',
    'Forgot Password': 'Forgot Password',
    'Fortune wheel': 'Efsane Kazı Kazan',
    'Frequently asked questions': 'Frequently asked questions',
    Fullname: 'Name Surname',
    'Game Type': 'Game Type',
    Gender: 'Gender',
    'Get Bonus': 'Get Bonus',
    'google-auth': 'Google Authenticator',
    HOME_CASINO: 'Casino',
    HOME_SLOT: 'Slot',
    HOME_SPORTS_BTN: 'Play now',
    HOME_SPORTS_TEXT:
        'Guarantee your winnings with hundreds of different betting options from Football to Basketball, Volleyball to Motor Sports.',
    HOME_SPORTS_TITLE: 'Thousands of sports bet',
    'Home team': 'Home',
    Help: 'Help',
    Hide: 'Hide',
    Homepage: 'homepage',
    'Horse racing': 'Horse racing',
    'I accept odds changes': 'I accept odds changes',
    'ID/Passport number': 'ID/Passport number',
    Id: 'ID',
    Info: 'Info',
    'Information Message': 'Information Message',
    'Last 30 Days': 'Last 30 Days',
    'Last 7 Days': 'Last 7 Days',
    'Last Month': 'Last Month',
    'Live Betting': 'Live Betting',
    'Live Bingo': 'Live Bingo',
    'Live Casino': 'Live Casino',
    'Live Match': 'Live Match',
    'Live Support': 'Live Support',
    'Live betting': 'Live betting',
    'Live games': 'Live games',
    'Live matches': 'Live matches',
    'Live support': 'Live support',
    'List': 'List',
    Live: 'live',
    Livegames: 'livegames',
    'Livematch bets': 'Livematch bets',
    'Lobby': 'Game Lobby',
    Logout: 'Logout',
    Lost: 'Lost',
    Lottery: 'lottery',
    'Main menu': 'Main Menu',
    Male: 'Male',
    'Maximum Payout': 'Maximum Payout',
    'Maximum Stake': 'Maximum Stake',
    'NO_LIMITS_DATA': 'You have no casino limits',
    'Detailed Information': 'Detailed Information',
    'MY_LIMITS_INFO':
        'Maximum stake amount for roulette.<br /> Maximum stake amount for Baccarat.<br /> Including insurance, maximum stake amount per hand in BlackJack. (Double down and Split available)<br /> Maximum stake amount for Hold’em. (Maximum Ante = Limit / 2)',
    Medium: 'Medium',
    'Member Login': 'Member Login',
    Menu: 'Menu',
    messages: 'Messages',
    'Minimum 2 characters required': 'Minimum 2 characters required',
    'Minimum 3 characters required': 'Minimum 3 characters required',
    'Minimum 4 characters required': 'Minimum 4 characters required',
    'Minimize games list': 'Minimize games list',
    'Minimum Stake per betslip': 'Minimum Stake per betslip',
    'Minimum Stake': 'Minimum Stake',
    'My Account': 'My Account',
    'My Bets': 'My Bets',
    'My account': 'My account',
    'my-info': 'My info',
    'limits': 'Casino limits',
    NOT18_ERROR: 'You need to be over 18 years old to register to our website.',
    'Name contains disallowed characters': 'Name contains disallowed characters',
    Name: 'Name',
    New: 'New',
    Newest: 'Newest',
    'New password': 'New password',
    Net: 'Net',
    'Next Live Events': 'Next Live Events',
    Next: 'Next',
    'No games matching these parameters': 'No games matching these parameters',
    'No options': 'No options',
    OVER18TEXT:
        "I hereby accept that, I'm over 18 years old and agree with the {{LINK_START}}Terms & Conditions.{{LINK_END}}",
    Odds: 'Odds',
    Other: 'Other',
    Ok: 'Ok',
    Oldest: 'Oldest',
    'Old password': 'Old password',
    'Option': 'Option',
    'Outright bets': 'Outright bets',
    'Page Total': 'Page Total',
    'Paid total': 'Paid total',
    Paid: 'Paid total',
    'Passport': 'Passport',
    'Password Repeat': 'Password Repeat',
    'Password strength': 'Password strength',
    Password: 'Password',
    'Password changed successfully': 'Password changed successfully',
    'Password Reset': 'Password Reset',
    perfect: 'Perfect',
    'Personal': 'Personal',
    'Personal info': 'Personal info',
    'Phone number': 'Phone number',
    Phone: 'Phone',
    'Ping-Pong': 'Ping-Pong',
    'Place bet': 'Place bet',
    'Please accept agreement': 'You did not confirm that you are over 18',
    'Please enter a valid e-mail address': 'Please enter a valid e-mail address',
    'Please enter your passport number': 'Please enter your passport number',
    'Please make sure you entered your details correctly':
        'ID field validates by your name, surname and year of birth. Please make sure you entered your details correctly.',
    'Please, input your password to get QR code':
        'Please, input your password to get QR code',
    'Please, input Code from Google Authenticator':
        'Please, input Code from Google Authenticator',
    'Popular Games': 'Popular Games',
    Popular: 'Popular',
    'Possible payout': 'Possible payout',
    'Pre Match': 'Pre Match',
    Prematch: 'Prematch',
    'Promo page': 'Promotions',
    Promo: 'promo',
    Promotions: 'promotions',
    Providers: 'Provider',
    Provider: 'Provider',
    Ragby: 'Ragby',
    'Repeat new password': 'Repeat new password',
    'Registration successful. Please check your e-mail.':
        'Registration successful. Please check your e-mail.',
    'Requested data not exist': 'Event not available',
    'Reset Password': 'Reset Password',
    Result: 'Result',
    'Return to homepage': 'Return to homepage',
    Returned: 'Returned',
    Rules: 'Rules',
    'Scan QR code or enter a secret key from Google Authenticator':
        'Scan QR code or enter a secret key from Google Authenticator',
    SERVICE_UNAVAILABLE:
        'Error in processing your data - please try again after a few minutes or contact support',
    'Send Again': 'Send Again',
    'SMS already sent. You can send another after: {{TIME}} seconds.':
        'SMS already sent. You can send another after: {{TIME}} seconds.',
    SIGNUP_HINT_PASS_STRENGTH:
        'Write your password. Please think about creating a safe password by mixing small and big characters with numbers. At least 8 characters long. Here you see how strong your chosen password is.',
    'Search Game': 'Search',
    Search: 'Search',
    'Select All': 'Select All',
    'Selection': 'Selection',
    'Selected Systems': 'Selected Systems',
    Shortcuts: 'Shortcuts',
    'Show all games': 'Show all games',
    'Sign up': 'Sign up',
    'Sign in': 'Login',
    Slots: 'Slots',
    SLOTS_BATTLE_STATUS_AVAILABLE: 'Available',
    SLOTS_BATTLE_STATUS_COMPLETED: 'Completed',
    SLOTS_BATTLE_STATUS_STARTED: 'Live',
    'Sorry, this game are not available': 'Sorry, this game are not available',
    'Sort by': 'Sort by',
    'Sport Betting': 'Sport Betting',
    Sport: 'sport',
    'Sports bets': 'Sports bets',
    Sports: 'Sports',
    'Stake total': 'Stake total',
    Stake: 'Stake',
    Status: 'Status',
    Strong: 'Strong',
    Submit: 'Submit',
    Support: 'Support',
    'Surname contains disallowed characters': 'Surname contains disallowed characters',
    Surname: 'Surname',
    System: 'System',
    TVBET: 'TVBET',
    Tennis: 'Tennis',
    'There is no available deposit methods for your account.':
        'There is no available deposit methods for your account.',
    'There is no available withdraw methods for your account.':
        'There is no available withdraw methods for your account.',
    'There is no bonus for you yet. Please try again later':
        'There is no bonus for you yet. Please try again later',
    'This Month': 'This Month',
    'This field is required': 'This field is required',
    Time: 'Time',
    Today: 'Today',
    Total: 'Total',
    Tournament: 'Tournament',
    'Transaction note': 'Transaction note',
    'Turn On': 'Turn on',
    'Turn Off': 'Turn off',
    'Type': 'Type',
    'Install Google Authenticator': 'Install Google Authenticator',
    'User information': 'User information',
    'Username can contain only Latin letters, numbers and underscores':
        'Username can contain only Latin letters, numbers and underscores',
    'Username must be at least 3 characters and maximum 17 characters':
        'Username must be at least 3 characters and maximum 17 characters',
    Username: 'Username',
    'View Games': 'View Games',
    'Virtual Sports': 'Virtual Sports',
    Virtual: 'virtual',
    Voleyball: 'Voleyball',
    Waiting: 'Pending',
    weak: 'Weak',
    'We have received your bonus request': 'We have received your bonus request',
    'We have sent you link to change your password':
        'We have sent you link to change your password',
    Welcome: 'Welcome',
    'Withdraw options': 'Withdraw options',
    'withdraw-status': 'Withdraw status',
    withdraws: 'Withdraw',
    Won: 'Won',
    Yesterday: 'Yesterday',
    'Your bet slip has been deleted successfully.':
        'Your bet slip has been deleted successfully.',
    'You have been logged out': 'You have been logged out',
    'You have entered incorrect character in password':
        'You have entered incorrect character in password',
    'You have no account history records.': 'You have no account history records.',
    'You have no livematch limits': 'You have no livematch limits',
    'You have no outright limits': 'You have no outright limits',
    'You have no prematch limits': 'You have no prematch limits',
    'You have no withdraw status records for the chosen period of time.': '',
    'Your phone number should be started from "5"':
        'Your phone number should be started from "5"',
    'TFA_ENABLED': 'You successfully enable two-factor authorization',
    'TFA_DISABLED': 'You successfully disable two-factor authorization',
    'Your ticket has been accepted': 'Bet Accepted',
    'Your ticket has been rejected': 'Bet Rejected',
    'You will be redirected to main page in {{TIMER}} seconds': 'You will be redirected to main page in {{TIMER}} seconds',
    'Z - A': 'Z - A',
    Warning: 'Warning',
    'OPEN_GAME_ERROR_WITH_CODE': 'Something went wrong. Please try again later or contact live support with error code. Error Code: {{ERROR_CODE}}',
    'Welcome to': 'Welcome to',
    'Your membership has approved': 'Your membership has approved',

    'Search result for': 'Search result for',
    at: 'at',
    odds: 'odds',
    Payout: 'Payout',
    'Payout Total': 'Payout Total',
    Yes: 'Yes',
    No: 'No',

    //widgets
    LMT: 'LMT',
    BET_ASSISTANT: 'Bet Assistant',
    MATCH_PREVIEW: 'Match Preview',
    HEAD_TO_HEAD: 'H2H',
    Streaming: 'Streaming',

    LIVE_STREAMING_ERROR_1000: 'The requested resource could not be found.',
    LIVE_STREAMING_ERROR_1001: 'The requested method is not allowed.',
    LIVE_STREAMING_ERROR_1002: 'The request failed due to an unexpected error.',
    LIVE_STREAMING_ERROR_2000: 'Authorization HTTP header is required.',
    LIVE_STREAMING_ERROR_2001: 'Invalid Authorization HTTP header format',
    LIVE_STREAMING_ERROR_2002: 'Invalid token.',
    LIVE_STREAMING_ERROR_2003: 'Invalid client.',
    LIVE_STREAMING_ERROR_3000: 'Error with GET parameters.',
    LIVE_STREAMING_ERROR_3001: 'Error with GET parameters.',
    LIVE_STREAMING_ERROR_3002: 'Error with GET parameters.',
    LIVE_STREAMING_ERROR_3003: 'Error with GET parameters.',
    LIVE_STREAMING_ERROR_3004: 'Error with parameters in the path.',
    LIVE_STREAMING_ERROR_3005: 'Error with parameters in the path. Invalid stream type.',
    LIVE_STREAMING_ERROR_3006: 'Stream not available.',
    LIVE_STREAMING_ERROR_3007:
        'The requested stream type is not available for the requested stream.',
    LIVE_STREAMING_ERROR_3008: 'Device category not booked.',
    LIVE_STREAMING_ERROR_3009:
        'Error with HTTP headers. IP must be sent in the X-Real-IP HTTP header.',
    LIVE_STREAMING_ERROR_3010:
        'Error with HTTP headers. The value passed in the X-Real-IP HTTP header is not a valid IP.',
    LIVE_STREAMING_ERROR_3011:
        'The country $countryCode$ associated to the IP $ip$ is not authorized for the requested stream.',
    LIVE_STREAMING_ERROR_3012: 'Event not available.',
    LIVE_STREAMING_ERROR_3014:
        'Error with GET parameters. The device category passed in the device_category parameter is not supported for the requested stream type.',
    LIVE_STREAMING_ERROR_3015: 'The IP ip is not authorized for the requested stream.',
    LIVE_STREAMING_ERROR_3023: 'Stream URL generation not allowed.',

    USER_IS_BLOCKED: 'Your account is temporarily suspended. As soon as this process is completed your account will be re-activated. For further information you may contact our live support. Thank you for your understanding.',

    'Sizi Arayalım': 'Sizi Arayalım',

    BET_BUILDER_TEXT: 'Bet Builder is new type of bet, which allows you to increase your winning by combining selections in the same event.',
    'What is Bet Builder?': 'What is Bet Builder?',
    'You need to pick at least one more selection to place your bet via Bet Builder': 'You need to pick at least one more selection to place your bet via Bet Builder',
    'Bet builder': 'Bet builder',

    'BUTTON_PRINT': "Print",

    'CAPTCHA_TEXT': 'This site is protected by reCAPTCHA and the Google {{FIRST_LINK}}Privacy Policy{{CLOSE_LINK}} and {{SECOND_LINK}}Terms of Service{{CLOSE_LINK}} apply.',

    'Terms of Service': 'Terms of Service',
    'Responsible Gaming': 'Responsible Gaming',
    'Self Exclusion': 'Self Exclusion',
    'Dispute Resolution': 'Dispute Resolution',
    'AML / KYC': 'AML / KYC',
    'Privacy': 'Privacy',
    'User Accounts': 'User Accounts',

    TOO_MUCH_SELECTIONS: 'You have reached maximum event count per coupon!',
    CLEAR_EXISTING_SELECTION: 'To add this selection you have to clear exisiting selection(s) in your betslip. Do you agree?',

    'Terms & Conditions': 'Terms & Conditions',
    'This combination is not possible, contradictory selections': 'This combination is not possible, contradictory selections!',
    'Illegal arguments combination': 'Combination is not allowed. Related contingency!',
    'Illegal selection, more than one outcome per market line.': 'This combination is not possible, contradictory selections!',

    GETTING_ERROR_PROVIDERS_TYPES: 'Oops. Something went wrong.',

    'Bet Builder information': 'Bet Builder information',

    'Cashouted': 'Cash-out',
    'Are you sure to want Cashout this coupon?': 'Cash-out this coupon?',
    'Cashout value': 'Cash-out value',
    'Cash-out my money': 'Cash-out',
    'Your bet has been successfully cashouted': 'Your bet has been successfully cashed out',
    'activeBets': 'Active bets',
    'Delete coupon': 'Delete coupon',
    'See All Coupons': 'See All Coupons',
    'TotalOdds': 'Total Odds',
    'CASHOUT_1': 'Cash out not available for this coupon',
    'CASHOUT_2': 'Cash out not available for this coupon',
    'CASHOUT_3': 'Cash out not available for this coupon',
    'CASHOUT_4': 'Cash out not available for this coupon',
    'CASHOUT_5': 'Cash-out not available',
    'CASHOUT_6': 'Cash-out amount has been changed. Please try again.',
    'CASHOUT_9': 'Too frequent attempt. Please wait a while',

    'ALREADY_ACCOUNT': 'Already have in account?',
    'Popular Tournaments': 'Popular Tournaments',

    'Share': 'Share',

    'Cashout possible': 'Cashout',
    'Cashout not possible': 'No Cashout',

    'Cricket': 'Cricket',

    PREMATCH_FILTERS_PANEL_HEADER: 'Search and Filters',
    PREMATCH_FILTERS_PANEL_CHOOSE: 'Select Sport/Category/Tournament',
    PREMATCH_FILTERS_PANEL_CHOOSE_FILTER: 'Selected',
    PREMATCH_FILTERS_PANEL_CHOOSER_HEADER: 'Total {{numberOfFilters}} for',
    PREMATCH_FILTERS_PANEL_LOOKING_FOR: "I am looking for...",
    PREMATCH_FILTERS_PANEL_SHOW_RESULT: 'Show Results',
    PREMATCH_FILTERS_PANEL_CLEAR: 'Clear Filter',
    PREMATCH_FILTERS_PANEL_LIMIT_REACHED: 'You have reached limit for selected filters',
    'Today_sport': 'Today\'s {{sportName}}',
    'Tomorrow_sport': 'Tomorrow\'s {{sportName}}',
    'Weekend_sport': 'Weekend\'s {{sportName}}',
    RANGED_EVENTS_NO_DATA_TEXT: 'No events for this sport for chosen time period',
    'All sports': 'All sports',

    SLOT_BATTLE__ERROR_100301: 'Battle not found!',
    SLOT_BATTLE__ERROR_100302: 'Battle already started!',
    SLOT_BATTLE__ERROR_100303: 'Wrong private key!',
    SLOT_BATTLE__ERROR_100304: 'You are already in a battle!',
    SLOT_BATTLE__ERROR_100305: 'You are already in a battle!',
    SLOT_BATTLE__ERROR_100306: 'Battle is full!',
    SLOT_BATTLE__ERROR_100307: 'You don’t have access to play slot battles!',
    SLOT_BATTLE__ERROR_100308: 'Insufficient funds!',
    SLOT_BATTLE__ERROR_100309: 'Can’t create a room!',
    SLOT_BATTLE__ERROR_100310: 'Room is unavailable!',
    SLOT_BATTLE__ERROR_100311: 'Limit exceeded!',
    SLOT_BATTLE__ERROR_100312: 'Wrong currency!',
    SLOT_BATTLE__CONNECTION_ISSUES_ERROR: 'Connection Error, please try again later',
    SLOT_BATTLE__GENERAL_ERROR: 'General Error!',

    SLOT_BATTLE__POPUP_TITLE: 'Slot Battles update',

    SLOT_BATTLE__WILL_START_IN_A_MOMENT: 'Battle will start in a moment',
    SLOT_BATTLE__LEFT_TIME_TO_PLAY: 'Start playing in {{TIMER}}',
    SLOT_BATTLE__WAITING_FOR_PLAYERS: 'Waiting for other players {{TIMER}}',
    SLOT_BATTLE__WAITING_FOR_RESULTS: 'Waiting for results',
    SLOT_BATTLE__BATTLE_ENDED: 'Battle ended',
    SLOT_BATTLE__YOU_WON: 'You won!',
    SLOT_BATTLE__CLICK_HERE_TO_OPEN_RESULTS: 'Click here to open results',
    SLOT_BATTLE__YOUR_GAME_ENDED_WAIT_FOR_OTHERS: 'Waiting for other players in {{TIMER}}',
    SLOT_BATTLE__OPEN_BATTLE_DETAIL: 'Click here to open battle',
    SLOT_BATTLE__BATTLE_STARTED_CLICK_TO_PLAY: 'Battle has started! Click here to open game!',
    SLOT_BATTLE__BATTLE_CANCELED: 'Players have not gathered. Your battle was canceled.',
    SLOT_BATTLE__PLAYERS_IN_BATTLE: '{{PLRS_QTY}} players in battle!', //(2/3 players in battle!)

    SLOT_BATTLE__DONT_SHOW_AGAIN: 'Don\'t show again',
    SLOT_BATTLE__HOW_ENABLE_INVITATION: 'You can enable Slot Battles Invitation in your user cabinet',
    SLOT_BATTLE__BATTLE_INVITATIONS_TEXT: 'You have been invited to the battles!',
    SLOT_BATTLE__POPUP_INVITATION_TITLE: 'Slot Battles invitation',
    SLOT_BATTLE__POPUP_INVITATIONS_TITLE: 'Slot Battles invitations',
    SLOT_BATTLE__LEFT_TIME_TO_PLAY_INVITATION: '{{TIMER}}',

    SLOT_BATTLE__TUTORIAL_VIDEO_HEADER: 'Slot battle video tutorial',
    SLOT_BATTLE__TUTORIAL_STEP_1_HEADER: 'Welcome to slot battle!',
    SLOT_BATTLE__TUTORIAL_STEP_4_HEADER: 'Game screen / Last step',
    SLOT_BATTLE__TUTORIAL_STEP_1_TEXT: 'Let us introduce our latest product “Slot Battle”. Thrilling slot experience meets with competition spirit to multiply the final prize.\n\nWith slot battle, you can challenge other players with preset conditions where every participant is equal and win rewards at the end of every single battle.',
    SLOT_BATTLE__TUTORIAL_STEP_2_TEXT: 'View active games on the lobby, join them and start your adventure here. You may also check your past games and all other battles from this section.',
    SLOT_BATTLE__TUTORIAL_STEP_3_TEXT: 'Choose your favourite game, set wagering amount and select number of participants.\n\n' +
        'If you would like you can also compete with your friends by checking “private battle” and sharing the unique link with the ones you want to play.',
    SLOT_BATTLE__TUTORIAL_STEP_4_TEXT: 'You have to complete all steps succesfully to qualify for a win. Tutorial is over, you are good to start. Wish you luck.',
    SLOT_BATTLE__TUTORIAL_STEP_4_TIP_1: 'Decrease/increase default bet according to your bet',
    SLOT_BATTLE__TUTORIAL_STEP_4_TIP_2: 'Should be equal to chosen bet',
    SLOT_BATTLE__TUTORIAL_STEP_4_BUY: 'Buy free spins',
    SLOT_BATTLE__TUTORIAL_DONT_SHOW_AGAIN: 'Don\'t show again',
    SLOT_BATTLE__TUTORIAL_START_BUTTON: 'Start',
    SLOT_BATTLE__TUTORIAL_SKIP_BUTTON: 'Skip',
    SLOT_BATTLE__TUTORIAL_GOT_IT: 'Got it',
    SLOT_BATTLE_TUTORIAL__TITLE: 'SLOT BATTLE TUTORIAL',
    SLOT_BATTLE__TUTORIAL_START: 'Start tutorial',
    SLOT_BATTLE__FAQ: 'Slot battle is a unique game mode where 2 or more players challenge each other by buying bonus in selected games.\n\n' +
        'Each participant must buy bonus in 2 minutes after starting time of the battle and complete bonus game in 10 minutes from starting time.\n\n' +
        'Participant who completed bonus buy with most winnings will claim other participants\' winnings.\n\n' +
        'If more than one player has equal amount of winnings, all winnings will be equally splitted to winners.\n\n' +
        'Any deliberate abuse/trolling within Slot Battles can result in action against your account and forfeiture of balance.\n\n' +
        'You may start your tutorial now. Please be sure that you read and acknowledge Slot Battle rules.',
    SLOT_BATTLE__BATTLE_RULES: 'Rules',
    SLOT_BATTLE__GO_TO_BATTLE_RULES: 'Go to Rules',

    SLOT_BATTLE__BACK_BUTTON_AVAILABLE: 'Available Slot Battles',
    SLOT_BATTLE__BACK_BUTTON_STARTED: 'Started Slot Battles',
    SLOT_BATTLE__BACK_BUTTON_COMPLETED: 'Completed Slot Battles',

    'available': 'available',
    'started': 'started',
    'completed': 'completed',

    'My battles': 'My battles',
    'Lowest bet': 'Lowest bet',
    'Highest bet': 'Highest bet',

    SLOT_BATTLE__NO_DATA_TITLE: 'CHECK YOUR LUCK IN A BATTLE',
    SLOT_BATTLE__ALREADY_IN_BATTLE: 'You already in battle',
    SLOT_BATTLE__USER_CANT_PLAY: 'You can\'t play slot battles. Please contact live support',
    SLOT_BATTLE__CREATE: 'Create slot battle',
    SLOT_BATTLE__START_NOW: 'Start now',
    SLOT_BATTLE__OPEN_MY_BATTLE: 'Open my battle',
    SLOT_BATTLE__LEAVE: 'Leave battle',
    SLOT_BATTLE__JOIN: 'Join',

    SLOT_BATTLE__CHOOSE_FAV_GAME: 'Choose your favorite game',
    SLOT_BATTLE__SET_WAGERING_AMOUNT: 'Set wagering amount',
    SLOT_BATTLE__SELECT_NUMBER_OF_PARTICIPANTS: 'Select number of participants',
    SLOT_BATTLE__WIN_YOUR_MONEY: 'Win your money',

    SLOT_BATTLE__BANNER_TEXT: 'Try your luck',

    SLOTS_BATTLE__AVAILABLE_NO_DATA_TEXT: 'No available battle. Create one now!',
    SLOTS_BATTLE__STARTED_NO_DATA_TEXT: 'No started battle found.',
    SLOTS_BATTLE__COMPLETED_NO_DATA_TEXT: 'No completed battle found.',
    SLOTS_BATTLE__NO_DATA_TEXT_BY_THIS_PARAMS: 'No battle found with preferred selections',

    SLOT_BATTLE__BET: 'Bonus Buy',
    SLOT_BATTLE__PLAYERS: 'Participants',
    SLOT_BATTLE__OWNER: 'Owner',

    SLOT_BATTLE__GAME_NAME: 'Game name',
    SLOT_BATTLE__DETAILS: 'Battle Details',
    SLOT_BATTLE__INVITE: 'Invite',
    SLOT_BATTLE__URL_COPIED: 'Battle URL copied to clipboard successfully',
    SLOT_BATTLE__SHARE: 'Share Battle',

    SLOT_BATTLE__PLAYER: 'Player',
    SLOT_BATTLE__STATUS: 'Status',
    SLOT_BATTLE__SCORE: 'Score',
    SLOT_BATTLE__RESULT: 'Result',

    SLOT_BATTLE__DISABLED_PLAY_BUTTON: 'You can’t play this game right now',
    SLOT_BATTLE__PLAY_BUTTON: 'Play',
    SLOT_BATTLE__NOT_AVAILABLE: 'Battle not available',
    SLOT_BATTLE__ROW_STATUS_DONE: 'Completed',
    SLOT_BATTLE__ROW_STATUS_LEFT: 'Left',
    SLOT_BATTLE__ROW_STATUS_NO_RESULT: 'No result',
    SLOT_BATTLE__ROW_STATUS_IN_PLAY: 'In play',
    SLOT_BATTLE__ROW_STATUS_WAIT: '-',

    SLOT_BATTLE__CHOOSE_GAME: 'Select Game',
    SLOT_BATTLE__NO_BET_OPTION: 'No option for this game',
    SLOT_BATTLE__NO_GAMES: 'No games',
    SLOT_BATTLE__START_NOT_FULL: "Start with at least 2 players",
    SLOT_BATTLE__PRIVAT: 'Private Battle',
    SLOT_BATTLE__CREATE_PASSWORD: 'Create password',

    SLOT_BATTLE__RESULTS_TIMER: 'Results will be shown in {{TIMER}}',

    SLOT_BATTLE: 'Slot battle',

    'Coupon Status': 'Status',
    'Score': 'Score',
    'Random Game': 'Random Game',

    LMT_INFO_TEXT: 'Live Match Trackers are only for informative purposes and can be delayed or show inaccurate information from time to time',

    WIDGET_GAME__PLAY_BUTTON: "Play",

    'Favorite': 'My Favorite Games',
    'Recently played': 'Recently Played',

    YOUR_POPULAR_ACCUMULATORS: 'Accumulators for You',
    POPULAR_ACCUMULATORS: 'Popular Accumulators',
    TOTAL_ODDS: 'Total Odds',
    ADD_TO_BETSLIP: 'Add to betslip',
    REMOVE_FROM_BETSLIP: 'Remove from betslip',

    GRAND_JACKPOT: 'Grand Jackpot',
    MAJOR_JACKPOT: 'Major',
    MINOR_JACKPOT: 'Minor',
    MINI_JACKPOT: 'Mini',
    BACK_TO_JACKPOTS: 'Back',
    SHOW_ALL_GAMES: 'Show all',
    JACKPOT_TOP_WIN: 'Top win',
    JACKPOT_LAST_WIN: 'Last win',
    JACKPOT_TOTAL_WINS: 'Total wins',

    BOT_DETECTED: 'Something wait wrong.  Please contact support.',

    COUPON_PRESET_SAVE: 'Save changes',
    COUPON_BET_LIMIT_TEXT: 'Max amount for these bets',
    AMOUNT_PER_BET: 'Amount per bet',
    FIRST_BET: 'First bet',
    SECOND_BET: 'Second bet',
    THIRD_BET: 'Third bet',
    SINGLE: 'Single',
    ACCUMULATED: 'Accumulated',

    ONLY_SINGLE_ERROR_TEXT: 'Your Accumulated is restricted as some of your selections can\'t be combined. Please continue with Single or remove conflicted bet / bets.',

    TOTAL_AMOUNT: 'Total Amount',

    BET_BUILDER_COMMON_ERROR: 'This combination is not possible, contradictory selections!',

    GAME_HAS_NO_FUN_MODE: 'This game has no fun mode',
};
