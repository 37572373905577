import isDevMode from '../utils/is-devmode';

export default class ApiClient {
    constructor({ prefix = 'api/v1' } = {}) {
        this.prefix = prefix;
        this.headers = {
            //'Content-Type': 'application/json' - почему-то так баговала часть методов, например категрии пре.
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }

    async post(url, data) {
        const response = await this.request(url, data, 'POST');
        // if (!isDevMode() && response.headers.get('x-old') === '1') {
        //     return window.location.reload();
        // } else {
            return response.json(); // parses JSON response into native JavaScript objects
        //}
    }


    request(url, data = {}, method = 'POST') {
        if (!isDevMode()) { //нельзя добавлять дополнительные хидеры с локалхоста
            this.headers['x-hash'] = process.env.REACT_APP_BUILD_HASH;
            //this.headers['x-referrer'] = window.location.pathname;
            //this.headers['x-log'] = encodeURIComponent(JSON.stringify(data)); //todo: test it (turkish symbols) before move to prod!!!!!!
        }
        // Default options are marked with *
        return fetch(this.prefix + url, {
            method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: this.headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
    }
}
