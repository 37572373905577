import React from 'react';

import {LLElementTypeSetter, withCommon} from '../../../components';


export const BackToAvailableBtn = withCommon({
    store: ({store}) => ({
        slotsBattles: store.slotsBattles,
        router: store.router,
    }),
    defaultName: 'BackToAvailableBtn',
    isTrans: true,
})(({slotsBattles, router, trans}) => {
    const prevPath = router.previousLocation?.pathname.split('/')[2];
    const text = ['started', 'completed'].includes(prevPath) ? prevPath : 'available';
    const back = (router.previousLocation && (
            router.previousLocation.pathname.includes('started')
            || router.previousLocation.pathname.includes('completed')
            || router.previousLocation.pathname.includes('available'))
    ) ?
        router.previousLocation.pathname
        : null;
    return (
        <LLElementTypeSetter
            className="slots-battles-back-btn"
            as="NavLink"
            to={back || "/slots-battles"}
            onClick={() => slotsBattles.setActiveBattle(null)}
        >
            <i className="slots-battles-back-btn__icon doruk-mg-arrow-2"/>
            <span>{trans('SLOT_BATTLE__BACK_BUTTON_' + text.toUpperCase())}</span>
        </LLElementTypeSetter>
    );
})