import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

/**
 * Returns a createElement() type based on the props of the Component.
 * Useful for calculating what type a component should render as.
 *
 * @param {function} Component A function or ReactClass.
 * @param {object} props A ReactElement props object
 * @returns {string|function} A ReactElement type
 */

const LLElementTypeSetter = inject('store')(
    observer((props) => {
        const { children, as, elementRef, store, ...restProps } = props;

        function getElementType(Component, props) {
            const { defaultProps = {} } = Component;

            // ----------------------------------------
            // user defined "as" element type

            if (props.as === 'NavLink') return NavLink;
            if (props.as && props.as !== defaultProps.as) return props.as;

            // ----------------------------------------

            if (props.href) return 'a';

            if (props.to) return Link;

            // ----------------------------------------
            // use defaultProp or 'div'

            return defaultProps.as || 'div';
        }

        if (restProps?.to) {
            const toRegex = new RegExp(/{{((\.*\w+\.*)*)}}\/?/);
            const to = JSON.stringify(restProps).match(toRegex);
            if (to) {
                restProps.to = restProps.to.replace(toRegex, function (p1, p2) {
                    return p2.split('.').reduce((a, v) => a[v], store);
                });
            }
        }

        const ElementType = getElementType(LLElementTypeSetter, props);

        return (
            <ElementType {...restProps} ref={elementRef}>
                {children}
            </ElementType>
        );
    })
);

export default React.forwardRef((props, ref) => {
    return <LLElementTypeSetter {...props} elementRef={ref} />;
});
