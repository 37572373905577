import React, {useEffect, useRef, useState} from 'react';
import {inject, observer} from "mobx-react";
import Swipe from 'react-easy-swipe';
import cx from 'classnames';

import {trans} from '../../../common/utils';
import LLProgressBar from '../progress-bar/progress-bar';

import ccsVars from '../../../styles/themes/theme/variables.scss';
import CouponsWrapper from "./coupons-wrapper";


let llCouponsPanelMobileVars = {
    tempOffset: 0,
    timeout: null,
};

const CouponsPanelMobile = ({store}) => {
    const isCustom = store.user.coupons.isCustom,
        list = store.user.coupons.list,
        drawerIsActive = store.drawer.isActive,
        viewSize = store.site.status.viewSize,
        setBackdropShown = store.site.status.setBackdropShown,
        setBackdropOnClick = store.site.status.setBackdropOnClick,
        setBackDropFullScreen = store.site.status.setBackDropFullScreen,
        isLandscapeOrientation = store.site.status.isLandscapeOrientation,
        fetching = store.user.coupons.fetching,
        initialLoading = store.betting.initialLoading,
        branchId = store.betting.branchId,
        state = store.betting.mobileTicketsPanelStatus,
        setState = store.betting.setMobileTicketsPanelStatus;

    const [listHeight, setListHeight] = useState(0),
        [transition, setTransition] = useState('unset');

    useEffect(() => {
        /**** ~~ Close backdrop if there is no coupons ****/
        if (!list.length) {
            setBackdropShown(false);
            setState('closed')
            return;
        }
        /**** ~~ Don't show backdrop if drawer is opening ****/
        !drawerIsActive && setBackdropShown(state === 'open');

        if (state === 'open') {
            setBackdropOnClick(() => {
                setState('closed');
                setListHeight(0);
            });
            setBackDropFullScreen(false);
        }
    }, [state, list.length]);

    /**** ~~ Close coupons if drawer is opened ****/
    useEffect(() => {
        if (drawerIsActive) {
            setTransition('all 0.5s ease-in-out');
            setState('closed');
        }
    }, [drawerIsActive]);

    const contentRef = useRef(null);
    const openIconRef = useRef(null);
    const openBtnRef = useRef(null);

    const isEventOnToggleBtnCheck = (e) => (
        openIconRef.current.isSameNode(e.target) ||
        openBtnRef.current.isSameNode(e.target));

    const toggleCouponsOnSwipe = (direction) => {
        setTransition('all 0.3s ease-in-out');
        if (direction === 'down') {
            setState('closed');
            setListHeight(0);
            return;
        }
        if (direction === 'up') {
            setState('open');
            setListHeight(contentRef.current.getBoundingClientRect().height);
        }
    }

    const handleSwipeStart = (e) => {
        e.stopPropagation();
        if (!isEventOnToggleBtnCheck(e)) {
            setTransition('unset');
            llCouponsPanelMobileVars.tempOffset = listHeight;
        }
    };

    const handleSwipeMove = (offset, e) => {
        e.stopPropagation();
        if (!isEventOnToggleBtnCheck(e)) {
            clearTimeout(llCouponsPanelMobileVars.timeout);
            setState('');
            const direction = offset.y < 0 ? 'up' : 'down';

            /**** ~~ Check if content fit in the max-height of coupons ****/
            if (
                contentRef.current.getBoundingClientRect().height > ccsVars['css_couponsList_maxHeight_' + viewSize] ?
                    listHeight > ccsVars['css_couponsList_maxHeight_' + viewSize] :
                    listHeight > contentRef.current.getBoundingClientRect().height
            ) {
                setListHeight(llCouponsPanelMobileVars.tempOffset - offset.y / 10);
            } else {
                setListHeight(llCouponsPanelMobileVars.tempOffset - offset.y);
            }

            llCouponsPanelMobileVars.timeout = setTimeout(() => toggleCouponsOnSwipe(direction), 200);
        }
    };

    const handleToggle = (e) => {
        e.stopPropagation();
        setTransition('all 0.5s ease-in-out');
        if (state === 'open') {
            setState('closed');
            setListHeight(0);
            return;
        }
        if (state === 'closed') {
            setState('open');
            setListHeight(contentRef.current.getBoundingClientRect().height);
        }
    };

    return list?.length || (fetching && !initialLoading) ? (
        <>
            <div
                className={cx(
                    'coupons',
                    (!list?.length && fetching) ?
                        'coupons--swipe--empty'
                        : 'coupons--swipe',
                    'coupons--' + viewSize,
                    branchId === 'Live' ? 'coupons--live' : 'coupons--pre',
                    state === 'open' && 'coupons--open'
                )}
            >
                <div
                    className={`coupons__container coupons__container--${viewSize}${isCustom ? ' coupons__container--custom-bets' : ''}`}>
                    {fetching && <LLProgressBar/>}
                    {!!list?.length && (
                        <>
                            <Swipe
                                onSwipeStart={handleSwipeStart}
                                onSwipeMove={handleSwipeMove}
                            >
                                <div
                                    className="coupons__container-top--whide"
                                >
                                    <div
                                        className="coupons__col"
                                    >
                                        <div className="coupons__bet">
                                            <div className="coupons__bet-counters">
                                            <span className="coupons__bet-value">
                                                {list.length}
                                            </span>
                                                <span
                                                    className="coupons__bet-counters-text">{!isCustom ? trans('Bets') : trans('Bet Builder')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="coupons__col">
                                        {!drawerIsActive && (
                                            <div
                                                ref={openBtnRef}
                                                onClick={handleToggle}
                                                className={
                                                    state === 'open'
                                                        ? 'coupons__bet-icon--open'
                                                        : 'coupons__bet-icon--close'
                                                }
                                            >
                                                <i className="coupons__close-icon doruk-mg-arrow-2" ref={openIconRef}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Swipe>

                            <div className={`coupons__scrollbox ${
                                'coupons__scrollbox--' + state
                            } ${isLandscapeOrientation ? 'coupons__scrollbox--landscape' : 'coupons__scrollbox--portrait'}`}
                                 style={{height: listHeight + 'px', transition: transition}}
                            >
                                <CouponsWrapper contentRef={contentRef}/>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    ) : null;
};

export default inject('store')(observer(CouponsPanelMobile));