import React from "react";
import {inject, observer} from "mobx-react";

import {trans} from "../../../../common/utils";
import {LLBtn2} from "../../../../components";
import BattleRules from "./rules";


const QuestionPopup = ({
                           store,
                           setQuestionsShowed
                       }) => {

    const setTutorialNeedsToBeShowed = store.slotsBattles.setTutorialNeedsToBeShowed,
        setTutorialStep = store.slotsBattles.setTutorialStep,
        viewSize = store.site.status.viewSize,
        isLogged = store.user.isLogged,
        openLogin = store.user.openLogin,
        setModal = store.modal.setModal,
        close = store.modal.close;

    const closeModal = () => {
        close();
        setQuestionsShowed(false);
    }

    return (
        <div className={`battle-questions battle-questions--${viewSize}`}>
            <div className="battle-questions__header">
                <div className="battle-questions__header-title">{trans("SLOT_BATTLE_TUTORIAL__TITLE")}</div>
                <div className="battle-questions__header-close"><i
                    className="doruk-close slots-battles-types-menu__close-icon"
                    onClick={closeModal}
                />
                </div>
            </div>
            <div className="battle-questions__content">
                <p>{trans('SLOT_BATTLE__FAQ')}</p>
            </div>
            <div className="battle-questions__buttons">
                <LLBtn2
                    onClick={() => {
                        if (isLogged) {
                            setTutorialStep(0);
                            setTutorialNeedsToBeShowed(true);
                            closeModal();
                        } else {
                            closeModal();
                            openLogin();
                        }
                    }}
                    customClasses="slots-battles__btn--accent"
                >
                    {trans("SLOT_BATTLE__TUTORIAL_START")}
                </LLBtn2>
                <LLBtn2
                    onClick={() => {
                        closeModal();
                        setModal({
                            active: true,
                            maskBackground: true,
                            title: trans("SLOT_BATTLE__BATTLE_RULES"),
                            showCloseButton: true,
                            currentKey: "custom-content",
                            customContent: <BattleRules/>,
                            customClasses: 'll-modal--battles-tutorial ll-modal--battles-rules'
                        })
                    }}
                    customClasses="slots-battles__btn--outlined"
                >
                    {trans("SLOT_BATTLE__GO_TO_BATTLE_RULES")}
                </LLBtn2>
            </div>
        </div>
    );
};

export default inject('store')(observer(QuestionPopup));
