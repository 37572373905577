import React, {useState, useRef, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import cx from 'classnames';

import {LLInput, WithDropdown, WithPopover, LLElementTypeSetter} from '../../../../components';
import {trans} from '../../../../common/utils';


let liveSearchTimeout = null;

const LiveSearch = (
    {
        store,
        view,
        isCalendarView,
    }
) => {
    const [searchText, setSearchText] = useState(''),
        [dropDownContent, setDropDownContent] = useState([]);

    const ref = useRef();
    const liveMatches = store.betting.liveMatches;

    useEffect(() => {
        setSearchText('');
        setDropDownContent([]);
    }, [isCalendarView]);

    const filterMatches = (searchText) => {
        if (searchText.length > 2) {
            const tempArr = [];
            liveMatches.matchList.map(match => {
                if (
                    (match.competitor1.name && match.competitor1.name.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                    (match.competitor2.name && match.competitor2.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                ) {
                    tempArr.push({
                        label: match.id,
                        element: (
                            <LLElementTypeSetter
                                as="NavLink"
                                to={match.matchUrl('Live')}
                                className="sport-search__dropdown-item"
                            >
                                <i className={'sport-search__item-icon sport_icon_font-' + match.sportId}/>
                                {
                                    view === 'menu' ?
                                        <WithPopover popoverClasses="ll-popover__text">
                                            <span>{match.competitor1.name + ' - ' + match.competitor2.name}</span>
                                        </WithPopover>
                                        :
                                        <span>{match.competitor1.name + ' - ' + match.competitor2.name}</span>
                                }
                            </LLElementTypeSetter>
                        ),
                    });
                }
            })
            setDropDownContent(tempArr);
        } else {
            setDropDownContent([]);
        }
    }

    const searchValueChangeHandler = (e) => {
        setSearchText(e.value);
        if (!liveMatches.isCalendar) {
            clearTimeout(liveSearchTimeout);
            liveSearchTimeout = setTimeout(() =>
                filterMatches(e.value), 1000);
        }
    }

    return (
        <div className={`sport-search sport-search--live sport-search--${view}`}>
            <WithDropdown
                dataSource={{
                    submenu: dropDownContent
                }}
                menuItemIsActive={() => false}
                onMenuItemClick={() => {
                    setSearchText('');
                    setDropDownContent([]);
                }}
                menuClasses={cx(
                    'sport-search__dropdown--live',
                    `sport-search__dropdown--${store.site.status.viewSize}`
                )}
                withAccordion={view === 'menu'}
                customActiveFlag={() => !!dropDownContent.length}
                withDropdownIcon={false}
                placement={'bottom-end'}
                disablePortal={false}
            >
                <LLInput
                    ref={ref}
                    customClasses="sport-search__container"
                    id="sport-search__input--live"
                    name="sport-search__input--live"
                    placeholder={trans("Search")}
                    value={searchText}
                    adormentAfter={(
                        store.betting.liveMatches.isCalendar ?
                            <div
                                className={`sport-search__icons${
                                    liveMatches.liveCalendarSearchString &&
                                    liveMatches.liveCalendarSearchString === searchText
                                        ? '--clear'
                                        :
                                        '--search'
                                }`}
                            >
                                <span
                                    className="sport-search__icon dx-icon-search icon"
                                    onClick={() => {
                                        if (searchText.length > 2) {
                                            liveMatches.setLiveCalendarSearchString(searchText);
                                            store.site.status.setBackdropShown(false);
                                        }
                                    }}
                                />
                                <span
                                    className="sport-search__icon dx-icon-remove icon"
                                    onClick={() => {
                                        setSearchText('')
                                        liveMatches.setLiveCalendarSearchString('');
                                        store.site.status.setBackdropShown(false);
                                    }}
                                />
                            </div>
                            :
                            <div
                                className={`${searchText.length ? 'sport-search__icons--clear'
                                    : 'sport-search__icons--search'
                                }`}
                            >
                                <span
                                    className="sport-search__icon dx-icon-remove icon"
                                    onClick={() => {
                                        setSearchText('');
                                        filterMatches('');
                                        if (view !== 'menu') {
                                            store.site.status.setBackdropShown(false);
                                        }
                                    }}
                                />
                                <span className="sport-search__icon dx-icon-search icon"/>
                            </div>
                    )}
                    valueChangeEvent="input"
                    onValueChanged={searchValueChangeHandler}
                />
            </WithDropdown>
        </div>
    );
}

export default inject('store')(observer(LiveSearch));