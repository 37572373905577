import React from 'react';
import Swipe from 'react-easy-swipe';
import {throttle} from "lodash";

import {withCommon} from "../../../components";
import {BetHistory} from '../../../pages/user-profile/pages';


const MyBetsDrawer = withCommon({
    store: ({store}) => ({
        close: store.drawer.close,
    }),
    defaultName: 'BetDrawerSwiper',
    isObserver: true,
})(({ close }) => {
    const handleSwipeMove = throttle((offset, e) => {
        if (offset.x > 0 && offset.x > 200) {
            close();
        }
    }, 100);

    return (
        <Swipe
            onSwipeMove={handleSwipeMove}
        >
            <div className="my-bets-drawer">
                <BetHistory/>
            </div>
        </Swipe>);
});

export default MyBetsDrawer;