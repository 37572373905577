import React from 'react';
import cx from 'classnames';


const SelectedFilterItem = ({item, removeCallback, withRemoveBtn= true, isOpened, setOpened}) => (
    <div className={cx(
        'filters-panel__selected-filter',
        !withRemoveBtn && 'filters-panel__selected-filter--collapsable'
    )}
         onClick={ withRemoveBtn ? () => {} :
             () => {setOpened((v) => !v)}
         }
    >
        <p className="filters-panel__selected-filter-name">{item.name}</p>
        { withRemoveBtn ?
            <i
                className="filters-panel__selected-filter-remove-btn doruk-close"
                onClick={removeCallback}
            />
            :
            <i
                className={cx(
                    'doruk-mg-arrow-2',
                    'filters-panel__selected-filter-arrow',
                    isOpened && 'filters-panel__selected-filter-arrow--opened',
                )}
            />
        }
    </div>
);

export default SelectedFilterItem;