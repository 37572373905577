import React from 'react';
import {inject, observer} from 'mobx-react';
import Fade from '@mui/material/Fade';

import {LLProgressBar, LLTextSlider} from '../../../../components';
import Markets from '../../components/common/markets';
import trans from '../../../../common/utils/trans';
import CustomBetsBuilder from '../../components/custom-bets/custom-bets-builder';


const MarketGroupsSlider = (
    {
        store,
        activeMarketGroup,
        setActiveMarketGroup,
        matchInstance,
        isMarketGroupsNeeded,
        userCanCashout,
        type = 'live',
    }
) => {
    const
        activeItems = store.betting.activeItems,
        isCustomBetsView = store.betting.isCustomBetsView,
        isBreadcrumbsNavigationDropdownOpen = store.betting.isBreadcrumbsNavigationDropdownOpen,
        isOldView = store.user.isOldMarketsView,
        setOldView = store.user.setMarketViewType,
        marketGroups = matchInstance?.marketGroupsList() ?? [],
        marketGroup = marketGroups[activeMarketGroup];

    const renderMarketGroupsTabs = (marketGroups) => (
        <LLTextSlider
            id={`${type}-market-groups__slider`}
            viewSize={store.site.status.viewSize}
            customClasses={{
                slider: `${type}-market-groups__slider ${
                    !!marketGroups.find(
                        (group) =>
                            group.initialFetching &&
                            activeItems.isActive({
                                id: group.id,
                                type: 'marketGroups',
                            })
                    )
                        ? 'market-groups__slider--disabled'
                        : ''
                }`,
                arrow: 'market-groups__slider-arrow',
            }}
            activeItem={activeMarketGroup}
        >
            {/* CUSTOMBETS */}
            {matchInstance.hasCustom ?
                <div className={`pre-match-details__market-group__custom-bets ${type}-match-details__market-group${
                    activeMarketGroup === -999
                        ? '--active'
                        : ''
                }`}
                     onClick={() => {
                         setActiveMarketGroup(-999);
                         activeItems.setActiveItem(
                             ['-999'],
                             'marketGroups'
                         );
                     }}
                     id={-999}
                >
                    {trans('Bet Builder')}
                </div>
                : null
            }
            {/* END OF CUSTOMBETS */}

            <div className={`${type}-match-details__market-group${
                activeMarketGroup === -1
                    ? '--active'
                    : ''
            }`}
                 onClick={() => {
                     setActiveMarketGroup(-1);
                     activeItems.setActiveItem(
                         ['-1'],
                         'marketGroups'
                     );
                     matchInstance.initializeAllMarketsInstruction();
                 }}
                 id={-1}
            >
                {trans('All Markets')}
            </div>


            {isMarketGroupsNeeded && marketGroups.map((marketGroup, i) => {
                return (
                    <div
                        className={`${type}-match-details__market-group${
                            activeMarketGroup === marketGroup.id && !marketGroup.initialFetching
                                ? '--active'
                                : ''
                        }`}
                        onClick={() => {
                            setActiveMarketGroup(marketGroup.id);
                            activeItems.setActiveItem(
                                [marketGroup.id.toString()],
                                'marketGroups'
                            );
                        }}
                        id={marketGroup.id}
                        key={i}
                    >
                        {marketGroup.name}
                        {marketGroup.initialFetching &&
                            activeItems.isActive({
                                id: marketGroup.id,
                                type: 'marketGroups',
                            }) && (
                                <LLProgressBar
                                    customClasses={`${type}-match-details__progress-bar`}
                                />
                            )}
                    </div>
                );
            })}
        </LLTextSlider>
    );

    const renderMarketsWithoutGroups = () => {
        let marketsCount = 0;
        return marketGroups.map((marketGroup) => {
            marketsCount = marketsCount + marketGroup.marketsListLength();
            return (
                <div
                    className="match-details__market-group"
                    data-market-group-id="all-markets"
                    key={marketGroup.id}
                >
                    <Markets
                        match={matchInstance}
                        marketGroup={marketGroup}
                        userCanCashout={userCanCashout}
                        viewSize={store.site.status.viewSize}
                        marketsCount={marketsCount}
                        isOldView={isOldView}
                        setOldView={setOldView}
                    />
                </div>
            )
        });
    };

    if (!!marketGroups.length && !!matchInstance.oddsCount) {
        return (
            <>
                {matchInstance?.initialFetching ?
                    <LLProgressBar
                        customClasses={`${type}-match-details__progress-bar`}
                    />
                    : <div className="match-detail__wrapper">
                        {(isBreadcrumbsNavigationDropdownOpen && store.site.status.viewSize === 'mobile') ?
                            <div
                                className="match-detail__back-drop"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            /> : null}
                        {renderMarketGroupsTabs(marketGroups)}
                        <Fade
                            in={isCustomBetsView || !marketGroup?.initialFetching || (activeMarketGroup === -1 && !matchInstance.isFetching)}>
                            <div>
                                {activeMarketGroup === -999
                                    ? <CustomBetsBuilder matchInstance={matchInstance} />
                                    : activeMarketGroup === -1 ? (isCustomBetsView || !matchInstance.isFetching)
                                            ? renderMarketsWithoutGroups() :
                                            <div className="match-details__all-markets-loading">
                                                <LLProgressBar customClasses="match-details__all-markets-progress-bar"/>
                                            </div> :
                                        <Markets
                                            match={matchInstance}
                                            marketGroup={matchInstance.marketGroups.get(activeMarketGroup)}
                                            sportId={matchInstance.sportId}
                                            userCanCashout={userCanCashout}
                                            viewSize={store.site.status.viewSize}
                                            isOldView={isOldView}
                                            setOldView={setOldView}
                                        />
                                }
                            </div>
                        </Fade>
                    </div>
                }
            </>
        );
    } else {
        return (
            <div className="match-details__empty-markets">
                {type === 'pre' ? trans('EMPTY_BETS_ERROR') : null}
            </div>
        );
    }
};
export default inject('store')(observer(MarketGroupsSlider));
