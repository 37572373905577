import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {
    withCommon,
    LLDateRangePicker,
    LLPreloader,
    LLTable,
} from '../../../../components';
import {AccountReport} from './components';
import {DrawerCloseButton} from '../../components';

/**
 * @file Represents React Component to render Account History Page of MyCabinet
 * @memberOf MyAccountPage
 *
 * @param {function} getAccountReport - function to get data for account reports
 * @param {function} getAccountHistory - function to get data for account history
 * @param {boolean} isTableLoading - show loading if we are waiting for account history request
 * @param {boolean} reportLoading - show loading if we are waiting for account report request
 * @param {array} accountHistory - data array for table of account history
 * @param {object} storeItem - object in store for account reports (needed for date range picker)
 * @param {boolean} mobileUserAgent - flag is mobile layout
 *
 * @author A.Huskova
 */

const AccountHistory = withCommon({
    store: ({store}) => ({
        getAccountReport: store.user.accountReport.getAccountReport,
        getAccountHistory: store.user.accountHistory.getAccountHistory,
        isTableLoading: store.user.accountHistory.isTableLoading,
        reportLoading: store.user.accountReport.isTableLoading,
        accountHistory: store.user.accountHistory.reports,
        storeItem: store.user.accountReport,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        getAccountReport: PropTypes.func.isRequired,
        getAccountHistory: PropTypes.func.isRequired,
        accountHistory: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        storeItem: PropTypes.object.isRequired,
    },
    defaultName: 'AccountHistory',
    isTrans: true,
    isObserver: true,
})(({
    getAccountReport,
    getAccountHistory,
    isTableLoading,
    reportLoading,
    accountHistory,
    trans,
    storeItem,
    viewSize,
}) => {
    useEffect(() => {
        getAccountReport();
        getAccountHistory();
    }, []);

    const accountHistoryTableConfig = {
        mobileGridProps: {
            noDataText: 'CABINET_ACCOUNT_HISTORY_NO_DATA_TEXT',
        },
        mobile: {
            columns: [
                {
                    key: 'dateTime',
                    name: 'date',
                    title: trans('Date'),
                    format: 'date',
                    width: 65,
                    allowHeaderFiltering: false,
                    sortOrder: "desc"
                },
                {
                    key: 'amount',
                    title: trans('Amount'),
                    format: 'currency',
                    alignment: 'right',
                    allowHeaderFiltering: false,
                    sortOrder: "asc"
                },
                {
                    key: 'balance',
                    title: trans('Balance'),
                    format: 'currency',
                    alignment: 'right',
                    allowHeaderFiltering: false,
                },
                {
                    key: 'dateTime',
                    name: 'time',
                    title: trans('Time'),
                    format: 'time',
                    width: 45,
                    allowHeaderFiltering: false,
                },
                {key: 'tableType', title: trans('Account')},
                {
                    key: 'note',
                    title: trans('Transaction note'),
                    allowHeaderFiltering: false,
                },
            ],
            totalColumnKey: 'amount',
            headerFilter: {
                visible: true,
            },
        },
        desktopGridProps: {
            onRowPrepared: (e) => {
                if (e.data?.amount > 0) {
                    e.rowElement.classList.add(
                        `table-row__account-history--positive`
                    );
                } else {
                    e.rowElement.classList.add(
                        `table-row__account-history--negative`
                    );
                }
            },
            noDataText: 'CABINET_ACCOUNT_HISTORY_NO_DATA_TEXT',
        },
        desktop: {
            columns: [
                {
                    key: 'dateTime',
                    name: 'date',
                    title: trans('Date'),
                    format: 'date',
                    width: '12%',
                    allowHeaderFiltering: false,
                    sortOrder: "desc"
                },
                {
                    key: 'dateTime',
                    name: 'time',
                    title: trans('Time'),
                    format: 'time',
                    width: '7%',
                    allowHeaderFiltering: false,
                },
                {
                    key: 'tableType',
                    width: '12%',
                    title: trans('Account'),
                    allowHeaderFiltering: true,
                },
                {
                    key: 'amount',
                    title: trans('Amount'),
                    format: 'currency',
                    alignment: 'right',
                    width: '10%',
                    allowHeaderFiltering: false,
                    sortOrder: "asc"
                },
                {
                    key: 'note',
                    title: trans('Transaction note'),
                    allowHeaderFiltering: false,
                },
                {
                    key: 'balance',
                    title: trans('Balance'),
                    format: 'currency',
                    width: '15%',
                    alignment: 'right',
                    allowHeaderFiltering: false,
                },
            ],
            totalColumnKey: 'amount',
            headerFilter: {
                visible: true,
            },
        },
    };

    return (
        <div className="account-history my-account__content-container">
            {viewSize === 'desktop' && (
                <p className="my-account__title">
                    <span className="my-account__title-text">
                        {trans('Account report')}
                    </span>
                    <DrawerCloseButton/>
                </p>
            )}
            <div className="my-account__content">
                {viewSize !== 'desktop' && (<div className="my-account-section">
                    <div className="my-account-section__title">
                        {trans('Account report')}
                    </div>
                </div>)}

                <div className="my-account__date-picker-wrapper">
                    <LLDateRangePicker
                        actionHandler={getAccountReport}
                        storeItem={storeItem}
                    />
                </div>

                <AccountReport reportLoading={reportLoading} />

                {viewSize !== 'desktop' ? (<div className="my-account-section--secondary">
                        <div className="my-account-section__title">
                            {trans('account-history')}
                        </div>
                    </div>)
                    : <p className="my-account__title">{trans('account-history')}</p>
                }

                <div className="my-account__table-box">
                    {isTableLoading ? (
                        <div className="my-account__loader-box">
                            <LLPreloader/>
                        </div>
                    ) : (
                        <LLTable
                            data={accountHistory}
                            customConfig={accountHistoryTableConfig}
                            name={'account-history'}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default AccountHistory;