import {
    betsUpdateTime,
    myAccountFetchTime,
    refreshDataFetchTime,
    refreshBattleDataFetchTime,
} from './user';

import {
    slotsCasinoFetchTimer,
    slotBattlesFetchTimer,
    slotBattlesBannerFetchTimer,
} from "./slots-casino";
import {miniBannersFetchTimer} from "./mini-banners";
import {promoFetchTimer} from "./promo";
import {homeFetchTimer} from "./home";
import {lobbyFetchTimer} from "./lobby";

const TIMERS = {
    ...betsUpdateTime,
    lobbyFetchTimer,
    myAccountFetchTime,
    slotsCasinoFetchTimer,
    slotBattlesFetchTimer,
    slotBattlesBannerFetchTimer,
    miniBannersFetchTimer,
    promoFetchTimer,
    homeFetchTimer,
    refreshDataFetchTime,
    refreshBattleDataFetchTime,
};

export default TIMERS;