import React from 'react';
import {Redirect} from 'react-router-dom';

import {LLBtn2, withCommon} from '../../components/';
import Image from '../../static/themes/theme/images/errors/login-needed.png';

const RenderRouterComponent = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
        isLogged: store.user.isLogged,
        isBlocked: store.user.isBlocked,
        openLogin: store.user.openLogin,
    }),
    isTrans: true,
})(
    ({
         Component,
         loginRequired,
         logoutRequired,
         allowedViewSizes,
         viewSize,
         isLogged,
         isBlocked,
         allowedForBlocked,
         openLogin,
         trans,
     }) => {
        const BlankPage = () => <div className={`page-blank--${viewSize}`}>
            <div className="page-blank__box">
                <p className="page-blank__text">{trans(isBlocked ? 'USER_IS_BLOCKED' : 'LOGIN_NEEDED')}</p>
                {!isBlocked && <LLBtn2 onClick={openLogin}>{trans('Sign in')}</LLBtn2>}
            </div>
            <img src={Image} alt="error" className="page-blank__img"/>
        </div>

        return (isBlocked && !allowedForBlocked) ? <BlankPage/>
            : ((logoutRequired && isLogged) || !allowedViewSizes.includes(viewSize)) ? (
                <Redirect to="/"/>
            ) : loginRequired && !isLogged ? (
                <BlankPage/>
            ) : (
                <Component/>
            );
    }
);

export default RenderRouterComponent;
