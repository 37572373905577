import React, {useState, useEffect, useRef} from 'react';
import {inject, observer} from "mobx-react";

import {LLPreloader} from '../../../components';
import {trans} from '../../../common/utils';
import CouponsWrapper from "./coupons-wrapper";


let previousListLength = 0;

const CouponsPanelDesktop = ({store}) => {
    const coupons = store.user.coupons,
        fetching = store.user.coupons.fetching,
        getScrollBox = store.scrollBoxes.getScrollBox,
        list = store.user.coupons.list;

    const [isMounted, setMounted] = useState(false);

    const contentRef = useRef(null);

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        }
    }, []);

    useEffect(() => {
        // ****  scrooll always check position of "place bet" button and always displays it on the screen //
        if (list.length > previousListLength && isMounted) {
            const rightBlockHeight = document.querySelector('.js_coupons-section')
                ?.clientHeight;

            const placeBetHeight = document.querySelector(
                '.coupons__submit-container'
            )?.clientHeight;
            getScrollBox('prematch-coupons') &&
            getScrollBox('prematch-coupons').scrollMethods.scrollToElement(
                '.coupons__submit-container',
                rightBlockHeight - placeBetHeight - 40
            );
        }
        previousListLength = list.length;
    }, [list.length]);

    useEffect(() => {
        if (coupons.coupons.size && isMounted) {
            const t = document.querySelector('.js_coupon-icon-count');
            t.classList.add('animated');
            setTimeout(() => {
                t.classList.remove('animated');
            }, 300);
        }
    }, [coupons.coupons.size]);

    return (
        <div className="coupons coupons--desktop">
            {fetching ? (
                <div className="coupons__loader">
                    <LLPreloader/>
                </div>
            ) : null}
            {list.length ? (
                <CouponsWrapper contentRef={contentRef} />
            ) : (
                <div className="prematch__coupons--empty">
                    {trans('Choose to bet')}
                </div>
            )}
        </div>
    );
};

export default inject('store')(observer(CouponsPanelDesktop));