import React from 'react';

import { withCommon, SignUpForm } from '../../../components';


const SignupDrawer = withCommon({
    store: ({ store }) => ({
        openDrawer: store.drawer.open,
        siteName: store.site.siteName,
    }),
    defaultName: 'SignupDrawer',
    isTrans: true,
    isObserver: true,
})(() => {
    return (
        <div className="signup-drawer">
            <SignUpForm />
        </div>
    );
});

export default SignupDrawer;
