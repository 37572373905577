import { getRoot, getType, types } from 'mobx-state-tree';

import {
    getMidnightDate
} from '../../../../common/utils';
import {TIMERS} from '../../../config';


const AccountDataWithFetchTimeTemplate = types
    .model('AccountDataWithFetchTimeTemplate', {
        fetchTime: types.maybeNull(types.number),
        startDate: getMidnightDate(new Date()),
        endDate: getMidnightDate(new Date()),
        isTableLoading: false,
    })
    .actions((self) => ({
        setDate({ startDate, endDate }) {
            if (startDate && self.startDate.getTime() !== startDate.getTime()) {
                self.startDate = startDate;
                self.fetchTime = null;
            }
            if (endDate && self.endDate.getTime() !== endDate.getTime()) {
                self.endDate = endDate;
                self.fetchTime = null;
            }
            if (self.modelName === 'betHistory') {
                self.statusFilter = 'All';
            }
        },
        setFetchTimeAndLoading({ fetchTime, loading}) {
            self.fetchTime = fetchTime || self.fetchTime;
            self.isTableLoading = loading ?? self.isTableLoading;
        },
        resetFetchTimeAndLoading() {
            self.fetchTime = null;
            self.isTableLoading = false;
        },
    }))
    .views((self) => ({
        get modelName() {
            return getType(self).name;
        },
        checkFetchTime() {
            const modelName = (
                getRoot(self).user.canCashout &&
                self.isRefreshedBehavior &&
                TIMERS.myAccountFetchTime[self.modelName + 'NormalUpdate']
            ) ?
                self.modelName + 'NormalUpdate' :
                self.modelName;

            if (self.fetchTime + TIMERS.myAccountFetchTime[modelName] <= Date.now()) {
                return true;
            }
        },
    }));

export default AccountDataWithFetchTimeTemplate;
