import {values} from 'mobx';
import {flow} from 'mobx-state-tree';

/*
########  #######  ##     ## ########  ##    ##    ###    ##     ## ######## ##    ## ########
   ##    ##     ## ##     ## ##     ## ###   ##   ## ##   ###   ### ##       ###   ##    ##
   ##    ##     ## ##     ## ##     ## ####  ##  ##   ##  #### #### ##       ####  ##    ##
   ##    ##     ## ##     ## ########  ## ## ## ##     ## ## ### ## ######   ## ## ##    ##
   ##    ##     ## ##     ## ##   ##   ##  #### ######### ##     ## ##       ##  ####    ##
   ##    ##     ## ##     ## ##    ##  ##   ### ##     ## ##     ## ##       ##   ###    ##
   ##     #######   #######  ##     ## ##    ## ##     ## ##     ## ######## ##    ##    ##

 ######   #######  ##     ## ##     ##    ###    ##    ## ########   ######
##    ## ##     ## ###   ### ###   ###   ## ##   ###   ## ##     ## ##    ##
##       ##     ## #### #### #### ####  ##   ##  ####  ## ##     ## ##
##       ##     ## ## ### ## ## ### ## ##     ## ## ## ## ##     ##  ######
##       ##     ## ##     ## ##     ## ######### ##  #### ##     ##       ##
##    ## ##     ## ##     ## ##     ## ##     ## ##   ### ##     ## ##    ##
 ######   #######  ##     ## ##     ## ##     ## ##    ## ########   ######
*/
const popularTournamentsActions = (s) => ({
    putUpdatePopularTournamentsData({tournaments}) {
        tournaments.map((newTournament) => {
            if (!s.popularTournaments.has(newTournament.id)) {
                s.popularTournaments.put({...newTournament});
            } else {
                s.popularTournaments.get(newTournament.id).update(newTournament);
            }
        });
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                          Compare tournaments and delete old                          ##
    // ##                                                                                      ##
    // ##========================================================================================

    deleteOldPopularTournaments: flow(function* fetch({tournaments}) {
        values(s.popularTournaments)
            .filter(
                ({id}) =>
                    !tournaments.some(({id: incomingId}) => incomingId === id)
            )
            .map((oldTournament) => {
                s.popularTournaments.delete(oldTournament.id);
            });
    }),
});

export default popularTournamentsActions;
