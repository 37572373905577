import React, {useMemo} from "react";
import {Link} from 'react-router-dom';
import cx from "classnames";

import {
    LLBtn2,
    LLPreloader,
    LLProgressBar,
    LLTable,
    WithPopoverCashout
} from "../../../components";
import {BetDeleteModal} from "../../../pages/user-profile/components";
import {CashoutModal} from "../../../pages/sport/desktop/right-panel/components";
import {betOddsFormat, formatDate, formatNumber, isCombinedMatch, trans} from "../../../common/utils";
import {CashoutPopupContent} from "./cashout-popup-content";


const BetsList = (
    {
        bets,
        storeItem,
        loading,
        activeBetId,
        noDataText,
        language,
        currency,
        setModal,
        betsError,
        userCanCashout,
        listType = "betHistory",
        isMobile
    }
) => {
    const BetBuilderDetails = ({matches}) => (
        <div className={cx("bets-list__match", matches[0].status)}>
            <p className="bets-list__match-row">
                <span className="bets-list__match-time">
                    <span>
                        {formatDate(matches[0].date, "DD.MM.YYYY, HH:mm", language)}
                    </span>
                    {matches[0].currentScore &&
                        <i title="Live Match"
                           className="doruk-live-match-01 coupons__live-indicator"
                        />
                    }
                </span>
                {matches[0].status &&
                    <span className="bets-list__status">
                        {trans(matches[0].status[0].toUpperCase() + matches[0].status.slice(1))}
                    </span>
                }
            </p>
            {matches[0].url ?
                <Link
                    className="bets-list__match-name"
                    to={matches[0].url}
                >
                    {matches[0].name}
                </Link>
                : <p className="bets-list__match-name">{matches[0].name}</p>
            }
            {matches[0].currentScore || matches[0].currentStatus ?
                <p className="bets-list__match-score">
                    <span>{matches[0].currentScore || null}</span>
                    <span> {matches[0].currentStatus || null}</span>
                </p>
                : null
            }
            <div className="bets-list__match-row--bet-builder">
                {matches.map((match) => (
                    <p className="bets-list__match-type--bet-builder" key={match.id}>
                        {`${match.oddsType} - ${match.oddsTypeSelection}`}
                    </p>
                ))}
            </div>
        </div>
    );

    const BetListMatch = ({match}) => {
        return <div
            className={cx("bets-list__group-item", match.status)}
            key={match.id + match.odds + match.oddsType}
        >
            {(match && isCombinedMatch(match.id)) ?
                <>
                    <p className="bets-list__match-row">
                        <span className="bets-list__match-time">
                            <span>
                                {formatDate(match.date, "DD.MM.YYYY HH:mm")}
                            </span>
                            {match.currentScore &&
                                <i title="Live Match"
                                   className="doruk-live-match-01 coupons__live-indicator"
                                />
                            }
                        </span>
                        {match.status &&
                            <span className="bets-list__status">
                                {trans(match.status[0].toUpperCase() + match.status.slice(1))}
                            </span>
                        }
                    </p>
                    <p className="bets-list__match-row">
                        {match.url ?
                            <Link
                                className="bets-list__match-name"
                                to={match.url}
                            >
                                {match.name}
                            </Link>
                            :
                            <p className="bets-list__match-name">{match.name}</p>
                        }
                        {match.currentScore || match.currentStatus ?
                            <p className="bets-list__match-score">
                                <span>{match.currentScore || null}</span>
                                <span> {match.currentStatus || null}</span>
                            </p>
                            : null
                        }
                        <span>{formatNumber(match.odds, 2, language)}</span>
                    </p>
                </>
                :
                <>
                    <p className="bets-list__group-item-info">
                        <span>
                            {formatDate(match.date, "DD.MM.YYYY, HH:mm", language)}
                            {match.currentScore &&
                                <i title="Live Match"
                                   className="doruk-live-match-01 coupons__live-indicator"
                                />
                            }
                        </span>
                        <span>
                            <span className="bets-list__group-item-match-key">{match.key}</span>
                            {match.status &&
                                <span className="bets-list__group-item-status">
                                    {trans(match.status[0].toUpperCase() + match.status.slice(1))}
                                </span>
                            }

                        </span>

                    </p>
                    <p className="bets-list__info-odd-type">
                        <span>{`${match.oddsTypeSelection} (${match.oddsType})`}</span>
                        <span>
                            {betOddsFormat(match.odds, language)}
                        </span>
                    </p>
                    {match.url ?
                        <Link
                            className="bets-list__match-name"
                            to={match.url}
                        >
                            {match.name}
                        </Link>
                        :
                        <p className="bets-list__match-name">{match.name}</p>
                    }
                    {match.currentScore || match.currentStatus ?
                        <p className={cx(
                            "bets-list__match-score",
                            match.className
                        )}>
                            <span>{match.currentScore || null}</span>
                            <span> {match.currentStatus || null}</span>
                        </p>
                        : null
                    }
                </>
            }
        </div>
    };

    const renderBetHistoryBetDetails = () => {
        const bet = storeItem.getBet(activeBetId);
        const groups = bet?.betDetailsGroups;
        const isSystemCoupon = groups.length > 1;
        return <>
            {!groups.length ?
                <div className="bets-list__match bets-list__match--no-data">
                    {trans("BET_DETAILS_NO_DATA")}
                </div>
                :
                <>
                    {(isSystemCoupon && bet?.betDetailsUniqueMatches.length) && (
                        bet?.betDetailsUniqueMatches.map((match) => <BetListMatch match={match} key={match.id}/>)
                    )}
                    {groups.map((group) => (
                        <div className="bets-list__group" key={group.key}>
                            {!group.sortedMatches.length ?
                                <div className="bets-list__group-item">
                                    {trans("BET_DETAILS_NO_DATA")}
                                </div>
                                :
                                !isSystemCoupon ?
                                    <div>
                                        {
                                            group.sortedMatches[0].customOdds > 0 ?
                                                <BetBuilderDetails matches={group.sortedMatches}/>
                                                :
                                                group.sortedMatches.map((match) => <BetListMatch match={match}/>)
                                        }
                                    </div>
                                    :
                                    <div className="bets-list__group-title">
                                        <p className="bets-list__group-info">
                                        <span className="bets-list__group-info-item">
                                            <span>{trans("Stake")}</span>: {
                                            formatNumber(group.sortedMatches[0].stake, 2, language, currency)
                                        }</span>
                                            <span className="bets-list__group-info-item">
                                            <span>{trans("Payout")}</span>: {
                                                formatNumber(group.sortedMatches[0].payout, 2, language, currency)
                                            }</span>
                                        </p>
                                        <p className={cx(
                                            'bets-list__group-combination',
                                            group.status
                                        )}>{group.key}</p>
                                    </div>
                            }
                        </div>)
                    )}</>
            }
        </>
    };

    const CashoutField = ({bet} = {}) => useMemo(() => formatNumber(bet.cashout, 2, language, currency), [bet.cashout]);

    const renderCashoutDeleteBtn = (bet) => {
        return (
            (bet.isCanBeDeleted || (userCanCashout && bet.cashout > 0.01)) &&
            <div className="bets-list__cashout-box">
                {bet.isCanBeDeleted ?
                    <>
                        {isMobile ? (
                            <>
                                <LLBtn2
                                    customClasses="bets-list__bet-btn bets-list__bet-btn--delete"
                                    disabled={bet.isDeleting}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setModal({
                                            active: true,
                                            maskBackground: true,
                                            showHeader: false,
                                            currentKey: "custom-content",
                                            customContent: <BetDeleteModal type={listType} id={bet.id}/>
                                        })
                                    }}
                                >
                                    <i className="bets-list__bet-btn-icon--delete doruk-delete-01"/>
                                </LLBtn2>
                            </>
                        ) : (
                            <WithPopoverCashout
                                style={{bottom: "8px"}}
                                popoverClasses="user-bets__popper-delete"
                                placement="top-end"
                                dropDownRole={true}
                                triggeredOnHover={false}
                                betId={bet.id}
                                listType={listType}
                                watch="delete"
                                popoverContent={
                                    <div className="user-bets__popper-delete-message">
                                        <CashoutPopupContent
                                            contentType={"delete"}
                                            type={listType}
                                            id={bet.id}
                                        />
                                    </div>
                                }
                            >
                                <LLBtn2
                                    customClasses="bets-list__bet-btn bets-list__bet-btn--delete"
                                    disabled={bet.isDeleting}
                                >
                                    <i className="bets-list__bet-btn-icon--delete doruk-delete-01"/>
                                </LLBtn2>
                            </WithPopoverCashout>)}
                    </>
                    :
                    (userCanCashout && (bet.cashout > 0.01)) ?
                        <>
                            {isMobile ? (
                                <>
                                    <LLBtn2
                                        customClasses="bets-list__bet-btn"
                                        isLoading={bet.cashoutLoding}
                                        disabled={bet.cashoutLoding}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            storeItem.checkCashout({id: bet.id}).then(() =>
                                                setModal({
                                                    active: true,
                                                    maskBackground: true,
                                                    showHeader: false,
                                                    currentKey: "custom-content",
                                                    customContent: <CashoutModal type={listType} bet={bet}/>
                                                }))
                                        }}
                                    >
                                        {storeItem.isRefreshedBehavior ?
                                            <CashoutField bet={bet}/>
                                            : trans("CashOut")
                                        }
                                    </LLBtn2>
                                </>
                            ) : (
                                <WithPopoverCashout
                                    style={{bottom: "8px"}}
                                    popoverClasses="user-bets__popper-cashout"
                                    placement="top-end"
                                    dropDownRole={true}
                                    triggeredOnHover={false}
                                    betId={bet.id}
                                    listType={listType}
                                    watch="cashout"
                                    popoverContent={
                                        <div className="user-bets__popper-cashout-message">
                                            <CashoutPopupContent
                                                contentType={"cashout"}
                                                type={listType}
                                                bet={bet}
                                            />
                                        </div>
                                    }
                                >
                                    <LLBtn2
                                        customClasses="bets-list__bet-btn"
                                        isLoading={bet.cashoutLoding}
                                        disabled={bet.cashoutLoding}
                                    >
                                        {storeItem.isRefreshedBehavior ?
                                            <CashoutField bet={bet}/>
                                            : trans("CashOut")
                                        }
                                    </LLBtn2>
                                </WithPopoverCashout>
                            )}
                        </>
                        :
                        null
                }
            </div>);
    };

    const betsListConfig = {
        mobileGridProps: {
            key: "id",
            repaintChangesOnly: true,
            rowComponent: (e) => {
                const bet = e.data.data;
                return (
                    <tbody>
                    <tr className={`bets-list__bet bets-list__bet--${
                        listType === "betHistory" ? "bet-history" : "active-bets"
                    } ${(activeBetId === bet.id) && !bet.isLoading ? "opened" : ""}`}
                    >
                        <td className="bets-list__bet-collapse"
                            onClick={() => storeItem.setActiveBet(bet.id)}
                        >
                            <div className="bets-list__bet-collapse-head">
                                <p className="bets-list__bet-title">
                                    <span>{`#${bet.id}`}</span>
                                    <span className="bets-list__bet-time">
                                        {formatDate(bet.dateTime, "DD.MM.YY HH:mm:SS")}
                                    </span>
                                </p>
                                <p className="bets-list__bet-collapse-arrow">
                                    {(activeBetId === bet.id) && !bet.isLoading ?
                                        <i className="doruk-close"/>
                                        :
                                        <i className="doruk-dots"/>
                                    }
                                </p>
                            </div>
                            <div className={`bets-list__bet-money bets-list__bet-money--${bet.statusEn}`}>
                                <div className="bets-list__bet-money-item bets-list__bet-money-item--with-status">
                                <span className="bets-list__bet-money-item-inner">
                                    <span className="bets-list__bet-money-item-title">
                                        {trans("Stake")}:
                                    </span>
                                    <span className="bets-list__bet-money-item-value">
                                        {formatNumber(bet.stake, 2, language, currency)}
                                    </span>
                                </span>
                                    {listType === "betHistory" &&
                                        <span className="bets-list__bet-status">
                                        {trans(bet.status)}
                                    </span>
                                    }
                                </div>
                                <div className="bets-list__bet-lower-part">
                                    <div className="bets-list__bet-left-lower-part">
                                        {(listType === "activeBets" && bet.totalOdds) ?
                                            <div className="bets-list__bet-money-item">
                                                <span className="bets-list__bet-money-item-title">
                                                    {trans("TotalOdds")}:
                                                </span>
                                                <span className="bets-list__bet-money-item-value">
                                                    {betOddsFormat(bet.totalOdds, language)}
                                                </span>
                                            </div>
                                            : null
                                        }
                                        <div className="bets-list__bet-money-item">
                                        <span className="bets-list__bet-money-item-title">
                                            {trans("Payout")}:
                                        </span>
                                            <span className="bets-list__bet-money-item-value">
                                            {formatNumber(bet.payout, 2, language, currency)}
                                        </span>
                                        </div>
                                        {bet.paid ?
                                            <div className="bets-list__bet-money-item">
                                            <span className="bets-list__bet-money-item-title">
                                                {trans("Paid")}:
                                            </span>
                                                <span className="bets-list__bet-money-item-value">
                                                {formatNumber(bet.paid, 2, language, currency)}
                                            </span>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    {renderCashoutDeleteBtn(bet)}
                                </div>
                            </div>
                            {bet.isLoading &&
                                <LLProgressBar customClasses="bets-list__bet-progress-bar"/>
                            }
                        </td>
                        <td className="bets-list__bet-collapse-content">
                            {(activeBetId === bet.id && bet.isOpened && !bet.isLoading) &&
                                (bet.betsDetailsError ?
                                    <div className="bets-list__match bets-list__match--no-data">
                                        {trans(bet.betsDetailsError)}
                                    </div>
                                    :
                                    renderBetHistoryBetDetails())
                            }
                        </td>
                    </tr>
                    </tbody>
                );
            },
            columnHidingEnabled: false,
            showColumnHeaders: false,
            showBorders: false
        },
        mobile: {
            id: "bets-list-table",
            scrollClass: "bets-list__scrollbox",
            customClasses: "bets-list__table"
        }
    };
    betsListConfig.desktopGridProps = betsListConfig.mobileGridProps;
    betsListConfig.desktop = betsListConfig.mobile;

    return (
        <div className={`bets-list ${listType === "betHistory" ? "bets-list--history" : ""}`}>
            {loading ?
                <div className="bets-list__loader-box">
                    <LLPreloader/>
                </div>
                :
                (bets.length && !betsError) ?
                    <LLTable
                        data={bets}
                        customConfig={betsListConfig}
                        size={5}
                        dependenciesForRerender={[activeBetId]}
                    />
                    :
                    <p className="bet-list__no-data-text">
                        {betsError || trans(
                            listType === "activeBets" ?
                                "ACTIVE_BETS_NO_DATA_TEXT"
                                :
                                noDataText
                        )}
                    </p>
            }
        </div>
    );
};

export default BetsList;