import React from 'react';
import cx from 'classnames';

import {withCommon} from "../../components";


const LLBackdrop = withCommon({
    store: ({ store }) => ({
        isBackdropShown: store.site.status.isBackdropShown,
        setBackdropShown: store.site.status.setBackdropShown,
        backdropOnClick: store.site.status.backdropOnClick,
        isBackDropClosable: store.site.status.isBackDropClosable,
        isBackDropTransparent: store.site.status.isBackDropTransparent,
        isFullScreen: store.site.status.isBackDropFullScreen,
    }),
    defaultName: 'LLBackdrop',
    isObserver: true,
})(({
    isBackdropShown,
    setBackdropShown,
    backdropOnClick,
    isBackDropClosable,
    isBackDropTransparent,
    isFullScreen,
}) => {

    return (
        <div
            className={cx(
                'll-backdrop',
                isBackdropShown && 'll-backdrop--open',
                isFullScreen && 'll-backdrop--full-screen',
                isBackDropTransparent && 'll-backdrop--transparent'
            )}
            onClick={(e) => {
                e.stopPropagation();
                isBackDropClosable && setBackdropShown(false);
                backdropOnClick();
            }}
        />
    );
});

export default LLBackdrop;