import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import {LLPreloader, withCommon} from '../../../../components';
import {formatNumber} from '../../../../common/utils';
import {DrawerCloseButton} from '../../components';
import CheckBox from "devextreme-react/check-box";

/**
 * @file Represents User Information page
 * @memberOf MyAccountPage
 *
 * @param {function} getUserInfo - action that set into store user information
 * @param {object} info - account information (address, city, country, dateOfBirth, email, idNumber, name, phone, surname)
 * @param {boolean} isInfoLoading - show loading if we are waiting for request
 * @param {number} account - account value
 * @param {number} credit - credit value
 * @param {string} available - available balance value
 * @param {string} username - username
 * @param {string} currency - user currency
 *
 * @author I.Mykhailychenko, A.Huskova - many fixes & mobile version
 */

const MyInfoPage = withCommon({
    store: ({store}) => ({
        router: store.router,
        info: store.user.info,
        isBlocked: store.user.isBlocked,
        isInfoLoading: store.user.info.isTableLoading,
        getUserInfo: store.user.info.getUserInfo,
        account: store.user.balance,
        credit: store.user.credit,
        available: store.user.availableMoney,
        username: store.user.username,
        currency: store.user.currency,
        language: store.user.language,
        showInvitations: store.user.showInvitations,
        toggleShowInvitations: store.user.toggleShowInvitations,
        rewardPoints: store.user.rewardPoints,
        hasRewardSystem: store.user.hasRewardSystem,
        mobileUserAgent: store.user.mobileUserAgent,
        setModal: store.modal.setModal,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        info: PropTypes.object.isRequired,
        getUserInfo: PropTypes.func.isRequired,
        setModal: PropTypes.func.isRequired,
    },
    defaultName: 'MyInfoPage',
    isObserver: true,
    isTrans: true,
})(({
        trans,
        isBlocked,
        getUserInfo,
        info,
        isInfoLoading,
        account,
        credit,
        available,
        currency = '',
        language,
        rewardPoints,
        hasRewardSystem,
        username,
        setModal,
        viewSize,
        showInvitations,
        toggleShowInvitations,
    }) => {
    const handleResetPassModal = () => {
        setModal({
            active: true,
            currentKey: 'change-password-form',
            title: trans('Change password'),
            customClasses: `modal-change-password modal-change-password--${viewSize}`,
            maskBackground: true,
            showHeader: true,
        });
    };

    const [isShowInvitationsLoading, setShowInvitationsLoading] = useState(false);

    useEffect(() => {
        getUserInfo().then();
    }, []);

    const UserInfoRow = ({title, value, onClickHandler, className}) => (
        <div className={`${viewSize === 'desktop' ? 'my-info__row' : 'my-account-info-list__item'}`}>
            <span
                className={`${viewSize === 'desktop' ? 'my-info__cell--title' : 'my-account-info-list__item-label'}`}>{trans(title)}</span>
            {viewSize === 'desktop' && <span className="my-info__divider">:</span>}
            <span
                className={`${viewSize === 'desktop' ? cx('my-info__cell--value', className) : 'my-account-info-list__item-value'}`}>
                <span>{value}</span>
                {(onClickHandler && viewSize !== 'desktop' && !isBlocked) ? <Link to={'/change-password'}>
                        <i className="my-info__change-pass doruk-edit"/>
                    </Link> :
                    (onClickHandler && !isBlocked) && (
                        <button
                            type="button"
                            className="my-info__cell-link"
                            onClick={onClickHandler}
                        >
                            {trans('Change password')}
                        </button>
                    )}
            </span>
        </div>
    );

    const UserInfo = () => (
        <div className={`my-info__wrapper--${viewSize}`}>
            <div className={`${viewSize === 'desktop' ? 'my-info__coll' : 'my-account-section'}`}>
                {viewSize === 'desktop' ? <h3 className="my-info__sub-title">
                        {trans('Account information')}
                    </h3>
                    : <div className="my-account-section__title">
                        {trans('Account information')}
                    </div>
                }
                <div className={`${viewSize === 'desktop' ? 'my-info__table' : 'my-account-info-list'}`}>
                    <UserInfoRow
                        title="Account"
                        className="align-right"
                        value={formatNumber(account, 2, language, currency)}
                    />
                    <UserInfoRow
                        title="Credit"
                        className="align-right"
                        value={formatNumber(credit, 2, language, currency)}
                    />
                    <UserInfoRow
                        title="Available"
                        className="align-right"
                        value={formatNumber(available, 2, language, currency)}
                    />
                    {hasRewardSystem ? (
                        <UserInfoRow
                            title="Bonus points"
                            className="align-right"
                            value={rewardPoints}
                        />
                    ) : null}
                </div>
            </div>

            <div className={`${viewSize === 'desktop' ? 'my-info__coll' : 'my-account-section'}`}>
                {viewSize === 'desktop' ? <h3 className="my-info__sub-title">
                        {trans('User information')}
                    </h3>
                    : <div className="my-account-section__title">
                        {trans('User information')}
                    </div>
                }
                <div className={`${viewSize === 'desktop' ? 'my-info__table' : 'my-account-info-list'}`}>
                    <UserInfoRow
                        withTrans={false}
                        title="Username"
                        value={username}
                    />
                    <UserInfoRow
                        withTrans={false}
                        title="Fullname"
                        value={`${info.name} ${info.surname}`}
                    />
                    <UserInfoRow title="E-Mail" value={info.email}/>
                    <UserInfoRow title="Date of birth" value={info.dateOfBirth}/>
                    <UserInfoRow title="Country" value={info.country}/>
                    <UserInfoRow
                        title="Address"
                        value={`${info.city} ${info.address}`}
                    />
                    <UserInfoRow title="ID/Passport number" value={info.idNumber}/>
                    <UserInfoRow title="Phone number" value={info.phone}/>
                    <UserInfoRow title="Currency" value={currency}/>
                    <UserInfoRow
                        withTrans={false}
                        title="Password"
                        value="********"
                        onClickHandler={handleResetPassModal}
                    />
                    <UserInfoRow
                        title='I accept to receive slot-battles invitations'
                        className="align-right"
                        value={<CheckBox
                            value={showInvitations}
                            onValueChange={() => {
                                setShowInvitationsLoading(true);
                                toggleShowInvitations().then(() => {setShowInvitationsLoading(false)});
                            }}
                        />}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className="my-info my-account__content-container">
            {viewSize === 'desktop' && <p className="my-account__title">
                <span className="my-account__title-text">
                    {trans('my-info')}
                </span>
                {viewSize === 'desktop' && <DrawerCloseButton/>}
            </p>}
            <div className="my-account__content">
                {(isInfoLoading || isShowInvitationsLoading) ? (
                    <div className="my-account__loader-box">
                        <LLPreloader/>
                    </div>
                ) : <UserInfo/>}
            </div>
            <div className="mobile-fix"/>
        </div>
    );
});

export default MyInfoPage;