import React from 'react';

import {LLElementTypeSetter} from "../index";


const RenderMenu = (props) => {
    const {
        menuItemIconPlacement,
        menuItemIsActive,
        menuItemIsDisabled,
        onMenuItemClick,
        disabled,
        dataSource,
    } = props;

    return !disabled ?
        <div className="ll-dropdown-menu__content">
            { dataSource.submenu.map((item, i) => (
                <LLElementTypeSetter
                    key={i}
                    className={`ll-dropdown-menu-item${
                        menuItemIsActive(item) ? "--active" : ""
                    }${
                        menuItemIsDisabled(item) ? "--disabled" : ""
                    }`}
                    onClick={() =>
                        (typeof onMenuItemClick === "function" && !menuItemIsDisabled(item)) && onMenuItemClick(item)
                    }
                    {...item.elementAttributes}
                >
                    {item.element ?? (
                        <>
                            { item.icon && menuItemIconPlacement === "start" && item.icon }
                            <span className="ll-dropdown-menu__text">{item.label}</span>
                            { item.icon && menuItemIconPlacement === "end" && item.icon }
                        </>
                    )}
                </LLElementTypeSetter>
            ))}
        </div>
        :
        null;
};

export default RenderMenu;