import React, {useEffect} from 'react';
import cx from 'classnames';

import {withCommon} from '../../../../../components';
import {LicenseIcons, SocialIcons} from '../../../../layout-components';

import config from "../../../../../static/themes/config";

/**
 * @desc Represents the markup for License Part of Footer of the Content
 *
 * @author A.Huskova
 */

const LLFooterLicense = withCommon({
    store: ({store}) => ({
        socialIcons: store.site.socialIcons.icons,
        modifiedConfig: store.modifiedConfig,
        siteName: store.site.siteName,
    }),
})(({
        modifiedConfig,
        siteName,
        socialIcons,
        customClasses,
    }) => {

    useEffect(() => {
        if (config(siteName).scripts?.footerLcQuality) {
            const range = document.createRange();
            document.head.appendChild(
                range.createContextualFragment(
                    config(siteName).scripts.footerLcQuality
                )
            );
        }
    }, []);

    return (
        <div className={cx(
            'footer-menu__section footer-menu__section--info',
            customClasses
        )}>
            {modifiedConfig.footerLicenceIcons?.length ?
                <div className="footer-license">
                    <LicenseIcons
                        licenseIcons={modifiedConfig.footerLicenceIcons}
                        classSuffix="--desktop"
                    />
                </div>
                : null
            }
            {
                /**** ~~ Render Social Icons Section ****/
                !!socialIcons?.length && (
                    <div className="footer-menu__social-icons-container">
                        <SocialIcons/>
                    </div>
                )
            }
            {modifiedConfig.pagcorLink ? <div className="footer-license-message">
                {window.location.hostname} is licensed and regulated by Philippine Amusement and Gaming Corporation Offshore Gaming License for Sports Betting & E-Gaming No: 18-0022.
            </div> : null}
            {config(siteName).scripts?.footerLcQuality ? <div className="footer-lcquality" id="lcqualitybadge"/> : null}
        </div>
    )
});

export default LLFooterLicense;