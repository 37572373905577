import { types as t } from 'mobx-state-tree';
import { xor } from 'lodash';

const ActiveMenuItems = t
    .model('ActiveMenuItems', {
        sports: t.array(t.string),
        categories: t.array(t.string),
        tournaments: t.array(t.string),
        matches: t.array(t.string),
    })
    .actions((s) => ({
        setActiveItem({ ids, type }) {
            s[type] = ids;
        },
        setAllActiveMenuItems({ sports = [], categories = [], tournaments = [] }) {
            s.sports = [...sports];
            s.categories = [...categories];
            s.tournaments = [...tournaments];
        },
        addActiveMenuItems({ sports = [], categories = [], tournaments = [] }) {
            s.sports = [...s.sports, ...sports];
            s.categories = [...s.categories, ...categories];
            s.tournaments = [...s.tournaments, ...tournaments];
        },
        toggleActiveMenuItem({ id, type }) {
            s[type] = xor(s[type], [id.toString()]);
        },
    }))
    .views((s) => ({
        isActive({ id, type }) {
            return !!id && s[type].includes(id.toString());
        },
        get firstTournament() {
            return s.tournaments.length ? s.tournaments[0] : null;
        },
        get firstCategory() {
            return s.categories.length ? s.categories[0] : null;
        },
    }));

export default ActiveMenuItems;
