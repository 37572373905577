import React from 'react';

import {withCommon, LoginForm} from '../../../components';
import config from '../../../static/themes/config';
import {DrawerCloseButton} from "../../../pages/user-profile/components";


const LoginDrawer = withCommon({
    store: ({store}) => ({
        openDrawer: store.drawer.open,
        siteName: store.site.siteName,
        openChat: store.liveChat.open,
    }),
    defaultName: 'LoginDrawer',
    isTrans: true,
    isObserver: true,
})(({trans, openDrawer, openChat, siteName}) => {
    return (
        <div className="login-drawer">
            <DrawerCloseButton/>
            <div className="login-drawer__logo"/>
            <LoginForm/>
            <div className="login-drawer-menu">
                {config(siteName).loginDrawerMenu.map((item, i) => {
                    const itemProps = {};
                    if (item.label === 'Help') {
                        itemProps.onClick = () =>
                            openDrawer({
                                side: 'right',
                                currentKey: 'support-drawer',
                            });
                    } else if (item.label === 'Live Support') {
                        itemProps.onClick = () => openChat();
                    }

                    return ((item.label !== 'Live Support' || config(siteName).siteConfig.appearanceRules.isLiveChat) &&
                        <div key={i} className="login-drawer-menu__item" {...itemProps}>
                            <div className="login-drawer-menu__item-name">
                                <i
                                    className={`${item.icon} login-drawer-menu__item-icon`}
                                />
                                <span className="login-drawer-menu__item-text">
                                    {trans(item.label)}
                                </span>
                            </div>
                            <i className="doruk-mg-arrow-2 login-drawer-menu__item-icon login-drawer-menu__item-arrow-icon"/>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default LoginDrawer;
