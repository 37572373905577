const setWidth = (width) => {
    let newWidth = '';
    if (width < 375) {
        newWidth = 'small-mobile';
    } else if (width < 768) {
        newWidth = 'mobile';
    } else if (width < 1366) {
        newWidth = 'tablet';
    } else {
        newWidth = 'desktop';
    }
    return newWidth;
};

export default setWidth;
