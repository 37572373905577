import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withCommon, LLElementTypeSetter } from '../../';

/**
 * Represents a Breadcrumbs component
 *
 * @param {Object[]} dataSource - data to render breadcrumbs
 * @param {string} dataSource.label - label of breadcrumbs item
 * @param {string} [dataSource.className] - classname of breadcrumbs item
 * @param {func} [dataSource.onClick] - onClick handler for breadcrumbs item
 * @param {string} [dataSource.icon] - icon of breadcrumbs item
 * @param {object} [dataSource.typeSetterProps] - props for LLElemTypeSetter if breadcrumbs item link or smth...
 * @param {string} [separatorIcon] - classname for icon if separator is icon
 * @param {string} [separatorSymbol='>'] - symbol if separator is symbol
 * @param {func} [onClick] - common onClick handler for all breadcrumbs items
 * @param {string} [customClasses]
 *
 * @author S.TARNAVSKI, A.HUSKOVA - ElementTypeSetter & оформление
 */

const LLBreadCrumbs = withCommon({
    defaultName: 'LLBreadCrumbs',
    propTypes: {
        dataSource: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.any,
                className: PropTypes.string,
                onClick: PropTypes.func,
                icon: PropTypes.string,
                typeSetterProps: PropTypes.object,
            })
        ).isRequired,
        separatorIcon: PropTypes.string,
        separatorSymbol: PropTypes.string,
        onClick: PropTypes.func,
        customClasses: PropTypes.string,
    },
})(({ dataSource, separatorIcon, separatorSymbol = '>', onClick, customClasses }) => {
    const menuItemClickHandle = (e, item, itemOnClick) => {
        itemOnClick && itemOnClick(e, item);
        onClick && onClick(e, item);
    };

    const renderSeparator = () =>
        separatorIcon ? (
            <i className={cx('ll-breadcrumbs__separator--icon', separatorIcon)} />
        ) : (
            <span className="ll-breadcrumbs__separator">{separatorSymbol}</span>
        );

    const renderMenuItem = (item, index) => (
        <React.Fragment key={index}>
            {index !== 0 && renderSeparator()}
            <LLElementTypeSetter
                className={cx('ll-breadcrumbs__menu-item', item.className)}
                onClick={(e) => menuItemClickHandle(e, item, item.onClick)}
                {...item.typeSetterProps}
            >
                {item.icon && <i className={`ll-breadcrumbs__menu-icon ${item.icon}`} />}
                {item.label}
            </LLElementTypeSetter>
        </React.Fragment>
    );

    return (
        <div className={cx('ll-breadcrumbs', customClasses)}>
            <div className="ll-breadcrumbs__content">
                {dataSource.map((item, i) => !item.disabled && renderMenuItem(item, i))}
            </div>
        </div>
    );
});

export default LLBreadCrumbs;
