import React, {useEffect, useState, useRef} from 'react';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import {inject, observer} from "mobx-react";

import {formatNumber, reverseFormatNumber, trans} from '../../../common/utils';
import {LLInput} from '../../../components';
import {PresetAmounts} from './preset-amounts';
import {currencySymbols} from '../../../configs';


const CouponsAmount = (
    {
        store,
        id,
        betAmount,
        maximumStake,
        setBetAmount,
        isMaxStakeExceeded,
        isMultiSingle,
        customLabel,
        isMainAmount = false,
    }
) => {
    const
        fetching = store.user.coupons.fetching,
        isAllMultiSingleHasMainAmount = store.user.coupons.isAllMultiSingleHasMainAmount,
        customerId = store.user.customerId,
        language = store.user.language,
        currency = store.user.currency;

    const [stringAmount, setStringAmount] = useState(
        formatNumber(betAmount, 2, language, '', language === 'aa')
    );
    const [isDummyInputShown, setDummyInputShown] = useState(isMainAmount && !isAllMultiSingleHasMainAmount);

    const [amountEmptyError, setAmountEmptyError] = useState(false);
    const [isMounted, setMounted] = useState(false);

    const transformAmount = (value) => {
        if (isMounted) {
            if (value.length) {
                const f = parseFloat(reverseFormatNumber(value)) || 0;
                setBetAmount(f);
                setAmountEmptyError(false);
                setStringAmount(formatNumber(f, 2, language, '', language === 'aa'));
            } else {
                setBetAmount(0);
                setStringAmount('');
            }
        }
    };

    const amountInputRef = useRef(null);

    useEffect(() => {
            if (isMounted) {
                transformAmount(formatNumber(betAmount, 2, language, '', language === 'aa'))
            } else {
                setMounted(true)
            }
            return () => {
                setMounted(false)
            }
        }
    , []);

    useEffect(() => {
        isMounted && setDummyInputShown(isMainAmount && isMultiSingle && !isAllMultiSingleHasMainAmount);
    }, [isMultiSingle, isAllMultiSingleHasMainAmount]);

    return (
        <div
            className={cx(
                'coupons__amount',
                (isMultiSingle && !isMainAmount) && 'coupons__amount--multi'
            )}
        >
            {((maximumStake || maximumStake == 0) && !isMultiSingle) ? (
                    <span className={cx(
                        isMaxStakeExceeded && 'amount-limit--error',
                        'coupons__amount-limit'
                    )}>
                        <i className={cx(
                            isMaxStakeExceeded && 'amount-limit--error',
                            'coupons__amount-limit-icon',
                            'doruk-icon-attention'
                        )}/>
                        {trans('COUPON_BET_LIMIT_TEXT') + ' - '}{formatNumber(maximumStake, 2, language, currency)}
                    </span>
                )
                : null
            }
            <div
                className={cx('coupons__amount-wrapper',
                    (isMaxStakeExceeded || amountEmptyError) && 'amount-wrapper--error',
                )}
                onClick={() => { amountInputRef?.current.instance.focus(); }}
            >
                <p className={cx(
                    'coupons__amount-label',
                    (isMultiSingle) && 'coupons__amount-label--multi'
                )}>
                    {trans(customLabel ?? 'Amount')}{isMultiSingle ? '' : ':'}
                </p>
                { isDummyInputShown &&
                    <div className="coupons__amount-input coupons__amount-input--dummy"
                         onClick={() => {
                             setDummyInputShown(false);
                             amountInputRef.current.instance.focus();
                         }}
                    />
                }
                <LLInput
                    ref={amountInputRef}
                    customClasses="coupons__amount-input"
                    id="coupons__amount-input"
                    name='coupon-amount-input'
                    inputAttr={{
                        id: id,
                        inputmode: 'numeric',
                        autocomplete: id,
                    }}
                    language={language}
                    disabled={fetching}
                    labelPosition="inside"
                    stylingMode="outlined"
                    value={stringAmount}
                    valueChangeEvent="change"
                    onValueChanged={(e) => {
                        transformAmount(e.value);
                    }}
                    onFocusIn={() => {
                        if (isMounted) {
                            setAmountEmptyError(false);
                            if (stringAmount === formatNumber(0, 2, language, '', language === 'aa')) {
                                transformAmount('');
                            }
                        }
                    }}
                    onFocusOut={() => {
                        if (stringAmount === '') {
                            transformAmount(formatNumber(0, 2, language, '', language === 'aa'));
                        }
                    }}
                    onKeyUp={debounce((e) => {
                        if (isMainAmount && !isMultiSingle && ['Backspace', 'Delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].includes(e.event.key)) {
                            const f = parseFloat(reverseFormatNumber(e.event.target.value)) || 0;
                            setBetAmount(f);
                        }
                    }, 500)}
                    onKeyDown={(e) => {
                        if (isMounted) {
                        amountInputRef.current.instance.element()?.closest('.coupons__amount-wrapper').classList.remove('amount-wrapper--error')
                        if (e.event.key === 'Enter') {
                            amountInputRef.current.instance.blur();
                        } else if (!['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].includes(e.event.key)) {
                            e.event.preventDefault();
                            return false;
                        }
                        }
                    }}
                />
                <span className="coupons__input-currency">
                    {currencySymbols[currency]}
                </span>
            </div>
            { isMainAmount ?
                <PresetAmounts
                    customerId={customerId}
                    transformAmount={transformAmount}
                    language={language}
                /> : null
            }
        </div>
    );
};

export default inject('store')(observer(CouponsAmount));