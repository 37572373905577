import React, { useState } from 'react';
import {inject, observer} from 'mobx-react';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';

import { LiveCalendarPage, } from '../../../components';
import {getSportIconsClassnames} from '../../../../../common/utils';
import config from '../../../../../static/themes/config';
import {LiveTournamentItemMobile} from './live-tournament-item';

import vars from "../../../../../styles/themes/theme/variables.scss";


const LiveMatchListMobile = ({store}) => {
    const liveMatches = store.betting.liveMatches,
        branch = store.betting.branch,
        activeItems = store.betting.activeItems,
        viewSize = store.site.status.viewSize,
        siteName = store.site.siteName,
        mobileUserAgent = store.user.mobileUserAgent,
        userCanCashout = store.user.canCashout,
        isLogged = store.user.isLogged;

    const sportsList = branch.sportsList ?? [];
    const indexActiveSport = activeItems.sports.length ? sportsList.indexOf(
        sportsList.find((item) => item.id === activeItems.sports[0])
    ) : 0;
    const [activeSportAccordion, setActiveSportAccordion] = useState(
        indexActiveSport !== -1 ? indexActiveSport : 0
    );

    const parentScroll = store.scrollBoxes.getScrollBox('main-scroll');

    const collapseAnimationSpeed = 100;

    const renderBetsList = () =>
        sportsList.map(
            (sport, i) =>
                sport.id && (
                    <div key={i} id={`sport-${sport.id}`}>
                        <div
                            className="livematches__sport-item"
                            onClick={() => {
                                const q = activeSportAccordion === i;
                                setActiveSportAccordion(null);
                                !q &&
                                setTimeout(() => {
                                    if (mobileUserAgent) {
                                        const sportElement = document.getElementById(`sport-${sport.id}`);
                                        if (sportElement) {
                                            setActiveSportAccordion(i);
                                            setTimeout(() => {
                                                const headerHeight = parseInt(vars['css_sizeHeight_contentHeader_' + viewSize])
                                                    + parseInt(vars['css_sizeHeight_contentHeaderMenu_' + viewSize]);
                                                const mobileHeightFix = mobileUserAgent ? 100 : 0;
                                                const newPosition = sportElement.getBoundingClientRect().top + window.scrollY - headerHeight - mobileHeightFix;
                                                window.scrollTo({top: newPosition, behavior: "smooth"});
                                            }, collapseAnimationSpeed);
                                        }
                                    } else {
                                        parentScroll.scrollMethods.scrollToElement(
                                            `#sport-${sport.id}`
                                        );
                                        setTimeout(() => {
                                            setActiveSportAccordion(i);
                                            setTimeout(() => {
                                                parentScroll.scrollMethods.scrollToElement(
                                                    `#sport-${sport.id}`
                                                );
                                            }, collapseAnimationSpeed);
                                        }, collapseAnimationSpeed);
                                    }
                                }, collapseAnimationSpeed);
                            }}
                        >
                            <div className="livematches__sport-name">
                                <i
                                    className={classNames(
                                        'menu-item__icon',
                                        getSportIconsClassnames(
                                            config(siteName).siteConfig.appearanceRules.sportIcons,
                                            store.site.status.viewSize,
                                            sport.id
                                        )
                                    )}
                                />
                                {sport.name}
                            </div>
                            <div className="livematches__sport-match-count">
                                {sport.liveMatchesCount}
                            </div>
                        </div>
                        <Collapse
                            in={activeSportAccordion === i}
                            timeout={collapseAnimationSpeed}
                        >
                            { sport.categoriesList.map((category) => (
                                category.tournamentsList.map((tournament, i) =>
                                    <LiveTournamentItemMobile key={i}  {...{
                                        branch,
                                        category,
                                        tournament,
                                        activeItems,
                                        siteName,
                                        viewSize,
                                        userCanCashout,
                                        isLogged,
                                    }} />
                            ))) }
                        </Collapse>
                    </div>
                )
        );

    return (
        <div className="livematches">
            <div className="livematches__list">
                {liveMatches.isCalendar ? <LiveCalendarPage/> : renderBetsList()}
            </div>
        </div>
    );
};

export default inject('store')(observer(LiveMatchListMobile));
