import React from 'react';

import { withCommon } from '../../../../components';
import { FormRequestPassword, GoogleAuthQRCodeForm } from './components/';
import { DrawerCloseButton } from '../../components';

const GoogleAuthenticator = withCommon({
    store: ({ store }) => ({
        siteName: store.site.siteName,
        googleQrCodeUserSecret: store.user.googleQrCodeUserSecret,
        viewSize: store.site.status.viewSize,
    }),
    defaultName: 'WithdrawStatus',
    isTrans: true,
})(({ googleQrCodeUserSecret, viewSize, trans }) => (
    <div className="google-auth my-account__content-container">
        {viewSize === 'desktop' && (<p className="my-account__title">
            <span className="my-account__title-text">
                {trans('google-auth')}
            </span>
            <DrawerCloseButton />
        </p>)}
        <div className="google-auth__content">
            {googleQrCodeUserSecret ? <GoogleAuthQRCodeForm /> : <FormRequestPassword />}
        </div>
    </div>
));

export default GoogleAuthenticator;
