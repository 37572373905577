import React, {useEffect, useRef, useState} from "react";

import {LLInput, withCommon} from "../../../../components";


export const SearchInput = withCommon({
    store: ({store}) => ({
        storeSearch: store.slotsBattles.slotBattlesSearch,
        setStoreSearch: store.slotsBattles.setSlotBattlesSearch,
        viewSize: store.site.status.viewSize,
    }),
    isTrans: true,
})(({
    storeSearch,
    setStoreSearch,
    openedItem,
    setOpenedItem = () => {},
    viewSize,
    trans
}) => {
    const [currentSearch, setCurrentSearch] = useState('');

    useEffect(() => {
        setCurrentSearch('');
    }, []);

    useEffect(() => () => {
        setStoreSearch('');
        setCurrentSearch('');
    }, []);

    return <>
        <LLInput
            customClasses="slots-battles__search-input js_battles-search"
            placeholder={trans('Search')}
            name="games-search"
            stylingMode="outlined"
            value={currentSearch}
            onValueChanged={(e) => {setCurrentSearch(e.value)}}
            onEnterKey={() => setStoreSearch(currentSearch)}
        />
        <i className={`${ (!storeSearch || viewSize === 'mobile') ?
                'doruk-search-01'
                :
                'doruk-close'} slots-battles__search-icon`
            }
            onClick={(e) => {
                if (viewSize === 'mobile') {
                    if (openedItem === 'search') {
                        e.stopPropagation();
                    }
                    setStoreSearch(currentSearch);
                    setOpenedItem('');
                } else {
                    if (!storeSearch) {
                        setStoreSearch(currentSearch);
                    } else {
                        setStoreSearch('');
                        setCurrentSearch('');
                    }
                }

           }}
        />
    </>
});