import React, { useState, forwardRef } from 'react';
import TextBox from 'devextreme-react/text-box';
import cx from 'classnames';

import packageJson from '../../../../package.json';

/*
    stylized labelPosition options are:
    'inside'
    'top'
    'left'
*/

const LLInput = forwardRef((props, ref) => {
    const {
        customClasses,
        label,
        labelPosition,
        onFocusIn = () => {},
        onFocusOut = () => {},
        valueChangeEvent = 'change keyup', // keyup, focusout
        inputAttr = {},
        name,
        language = '',
        adormentAfter,
        adormentBefore,
        additionalInputAttr = {},
        ...restProps
    } = props;
    const [isFocused, setFocused] = useState(false),
        classnames = cx(
            `ll-input ${isFocused ? 'll-input--focused' : 'll-input--blured'} ${
                restProps.value ? 'll-input--labeled-up' : ''
            }`,
            customClasses
        );

    return (
        <div className={classnames}>
            {label && (
                <label
                    className={`ll-input__label ${
                        labelPosition
                            ? 'll-input__label--' + labelPosition
                            : 'll-input__label--inside'
                    } `}
                >
                    {label}
                </label>
            )}
            <div
                className={`ll-input__text-box-container ${
                    labelPosition
                        ? 'll-input__text-box-container--' + labelPosition
                        : 'll-input__text-box-container--inside'
                }`}
            >
                {adormentBefore}
                <TextBox
                    ref={ref}
                    name={name}
                    inputAttr={{
                        autocomplete: `${name}__${packageJson.version}${language ? '__' + language : ''}`,
                        placeholder: '',
                        ...inputAttr,
                    }}
                    {...restProps}
                    className={`ll-input__textbox ${
                        labelPosition
                            ? 'll-input__textbox--' + labelPosition
                            : 'll-input__textbox--inside'
                    }`}
                    onFocusOut={() => {
                        onFocusOut();
                        setFocused(false);
                    }}
                    valueChangeEvent={valueChangeEvent}
                    onFocusIn={() => {
                        onFocusIn();
                        setFocused(true);
                    }}
                />
                { adormentAfter }
            </div>
        </div>
    );
});

export default LLInput;
