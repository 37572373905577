import React from 'react';
import cx from 'classnames';

import {withCommon, LLTextSlider} from '../../../components';

import MyAccountPage from '../../../pages/user-profile/user-profile';


const MyAccountDrawer = withCommon({
    store: ({store}) => ({
        setAccountActivePage: store.user.setAccountActivePage,
        activeAccountPage: store.user.activeAccountPage,
        closeDrawer: store.drawer.close,
        viewSize: store.site.status.viewSize,
        config: store.modifiedConfig,
        isBlocked: store.user.isBlocked,
    }),
    defaultName: 'MyAccountDrawer',
    isTrans: true,
})(({
    setAccountActivePage,
    activeAccountPage,
    config: {
        myAccountDesktopMenu,
        siteConfig: {
            permissionRules: { isPayments },
        },
    },
    isBlocked,
    viewSize,
    trans}) => {
    return (
        <div className="my-account-drawer">
            <LLTextSlider
                id="my-account-drawer__menu-list"
                viewSize={viewSize}
                customClasses={{
                    slider: 'my-account-drawer__menu-list',
                }}
                activeItem={activeAccountPage}
            >
                {myAccountDesktopMenu.map((item) => {
                    if (!item.disabled) {
                        if ((
                            item.name !== 'deposits' &&
                            item.name !== 'withdraws' &&
                            item.name !== 'withdraw-status') || isPayments
                        ) {
                            return (!isBlocked || item.visibleForBlockedUser) ?
                                <div
                                    key={item.link}
                                    className={cx(
                                        'my-account-drawer__menu-link',
                                        activeAccountPage === item.link && 'active'
                                    )}
                                    onClick={() => setAccountActivePage(item.link)}
                                    id={item.link}
                                >
                                    <i
                                        className={`my-account-drawer__menu-icon doruk-${item.icon}`}
                                    />
                                    <span>{trans(item.name)}</span>
                                </div>
                                : null
                        }
                    }
                })}
            </LLTextSlider>
            {isBlocked && <p className='my-account-drawer__blocked-user'>{trans('USER_IS_BLOCKED')}</p>}
            <MyAccountPage/>
        </div>
    );
});

export default MyAccountDrawer;
