import React from 'react';
import cx from 'classnames';

import {LLBtn2, LLPreloader, WithPopover} from '../../../../../components';
import {formatDate, formatNumber, trans} from '../../../../../common/utils';


const VaixWidgetItem = ({slide, language, collectActivity, user, couponsFetching, betInSlideCount}) => {
    return (
        <div className="vaix-widget-item">
            { slide.isLoading ?
                <>
                    {[...Array(betInSlideCount)].map((item, i) => (
                        <article className="vaix-widget-item__bet vaix-widget-item__bet--fake" key={i}>
                            <div className={`vaix-widget-item__match-icon vaix-widget-item__match-icon--fake`}>
                                <div className="vaix-widget-item__fake-content"></div>
                            </div>
                            <div className="vaix-widget-item__bet-name vaix-widget-item__bet-name--fake">
                                <div className="vaix-widget-item__fake-content"></div>
                            </div>
                            <div className="vaix-widget-item__bet-value vaix-widget-item__bet-value--fake">
                            </div>
                            <div className="vaix-widget-item__info-row vaix-widget-item__info-row--fake">
                                <div className="vaix-widget-item__fake-content"></div>
                            </div>
                            <div className="vaix-widget-item__info-row vaix-widget-item__info-row--fake">
                                <div className="vaix-widget-item__fake-content"></div>
                            </div>
                        </article>
                    ))}
                    <div className="vaix-widget-item__submit-box">
                        <div className="vaix-widget-item__combined-odd">
                            <p className="vaix-widget-item__combined-odd-label">

                            </p>
                            <p className="vaix-widget-item__combined-odd-value"></p>
                        </div>
                        <div className="vaix-widget-item__submit-btn vaix-widget-item__submit-btn--fake">
                        </div>
                    </div>
                </>
                :
                <>
                    {
                        slide?.bets.map((bet) => (
                            <article className="vaix-widget-item__bet" key={bet.oddsId}>
                                <i className={`vaix-widget-item__match-icon sport_icon_font-${bet.sportId}`}/>
                                <div className="vaix-widget-item__bet-name">
                                    <WithPopover>
                                        <span>{bet.oddsName}</span>
                                    </WithPopover>
                                </div>
                                <div className="vaix-widget-item__bet-value">
                                    {bet.renderOdd}
                                </div>
                                <div className="vaix-widget-item__info-row vaix-widget-item__market-name">
                                    <WithPopover>
                                        <span>{bet.marketName}</span>
                                    </WithPopover>
                                </div>
                                <div className="vaix-widget-item__info-row">
                                    <div className="vaix-widget-item__match-name">
                                        <WithPopover>
                                            <span>
                                                <span
                                                    className="team-name-holder"
                                                    dangerouslySetInnerHTML={{
                                                        __html: bet.competitor1,
                                                    }}
                                                />
                                                <span>&nbsp;-&nbsp;</span>
                                                <span
                                                    className="team-name-holder"
                                                    dangerouslySetInnerHTML={{
                                                        __html: bet.competitor2,
                                                    }}
                                                />
                                            </span>
                                        </WithPopover>
                                    </div>
                                    <div className="vaix-widget-item__date">
                                        {formatDate(bet.timeStart, "DD.MM.YY HH:mm", language)}
                                    </div>
                                </div>
                            </article>
                        ))
                    }
                    <div className="vaix-widget-item__submit-box">
                        <div className="vaix-widget-item__combined-odd">
                            <p className="vaix-widget-item__combined-odd-label">
                                {trans('TOTAL_ODDS')}:
                            </p>
                            <p className="vaix-widget-item__combined-odd-value">{formatNumber(
                                slide.value, 2, language
                            )}</p>
                        </div>
                        <LLBtn2
                            customClasses={cx("vaix-widget-item__submit-btn", slide.isSlideBetActive ? 'active' : '')}
                            onClick={() => {
                                if (!user.coupons.fetching) {
                                    if (slide.isSlideBetActive) {
                                        slide.removeBet()
                                    } else {
                                        collectActivity({
                                            area: "vaix-widget-slide",
                                            itemId: 1,
                                            interactTarget: slide.key
                                        })
                                        slide.placeBet()
                                    }
                                }
                            }}
                            disabled={couponsFetching}
                        >
                            {trans(slide.isSlideBetActive ? 'REMOVE_FROM_BETSLIP' : 'ADD_TO_BETSLIP')}
                        </LLBtn2>
                    </div>
                </>
            }
        </div>
    );
};

export default VaixWidgetItem;