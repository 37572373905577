import {types, destroy, getRoot, getParent} from 'mobx-state-tree';
import {formatNumber} from "../../../../../common/utils";
import React from "react";


const BettingItem = types
    .model('BettingItem', {
        id: types.identifierNumber /* i */,
        available: true /* a (active for only custom bets) */,
        outcomeTypeId: types.string /* c */,
        specifier: types.string /* sf */,
        outcomeValue: types.number /* v */,
        originalOutcomeValue: types.maybeNull(types.number) /* ov */,
        /**** ~~ Name of outcome that can be taken from api/sesi/outcome ****/
        baseName: types.maybeNull(types.string) /* on */,
        name: types.maybeNull(types.string) /* unmodified on */,
        order: types.number /* o */,
        betStatus: types.maybeNull(types.boolean) /* bs */,
        blink: types.maybeNull(types.string) /* bl */,
        isCustom: types.maybeNull(types.boolean),
    })
    .actions((s) => ({
        /**** ~~ Delete behavior ****/
        delete() {
            destroy(s);
        },

        /**** ~~ Update behavior ****/
        update({c, sf, v, ov, bl, bs, a = 1}) {
            s.outcomeTypeId = c.toString();
            if (bl !== undefined) {
                if (getParent(s, 4).initialFetching) {
                    s.blink = bl
                } else if (s.outcomeValue < v) {
                    s.blink = 'up'
                } else if (s.outcomeValue > v) {
                    s.blink = 'dn'
                } else {
                    s.blink = ''
                }
            }
            s.available = !!a;
            s.specifier = sf;
            s.outcomeValue = v;
            s.originalOutcomeValue = ov;
            s.betStatus = !!bs;
        },

        outcomeName() {
            if (s.name) {
                return s.name;
            } else {
                const parentMatch = getParent(s, 6);
                s.name = getRoot(s).getReplacer({
                    strToReplace: s.baseName,
                    dataSource: {
                        specifier: s.specifier,
                        activeMatch: {
                            ...parentMatch,
                            competitorName: parentMatch.competitorName,
                        },
                    },
                });
                return s.name;
            }
        },

        placeBet() {
            const type = getRoot(s).betting.branchId === 'Live' ? 'LiveMatch' : 'PreMatch';
            const user = getRoot(s).user;
            /* For normal bet we send selection */
            let newBet = s.id;
            if (!user.coupons.fetching) {
                let coupon;
                const coupons = getRoot(s)?.user?.coupons;
                if (s.isCustom) {
                    coupon = coupons.customCouponsList.find(item => item.id === s.id);
                } else {
                    coupon = coupons.basicCouponsList.find(item => item.id === s.id);
                }
                if (!coupon) {
                    user.coupons.add({
                        custom: s.isCustom,
                        type: type,
                        oddsId: newBet,
                        source: 1,
                    });
                } else {
                    coupon.remove();
                }
            }
        },
    }))
    /***************** ~~ Views ****************/
    .views((s) => ({
        /**** ~~ Prepared list of splitted by '=' specifiers in object key-value form ****/
        get specifiers() {
            let groupedSpecifiers = {};

            const specifiersParser = (sf) => {
                if (sf.includes('=')) {
                    let [macro, value] = sf.split('=');
                    let specifierOrder = 9999;

                    if (isNaN(value)) {
                        const colonCount = value.split(':').length - 1;
                        /* for variant markets with crazy specifiers like:
                        sr:winning_margin_no_draw:11+ 
                        pre:playerprops:26109942:1124727
                        */
                        if (colonCount > 1) {
                            const variantMarketSpecifierArray = value.split(':');
                            /* If correct format ( something:specifier:value ) */
                            if (variantMarketSpecifierArray[1] === 'playerprops') {
                                variantMarketSpecifierArray[1] = 'player';
                            }
                            specifiersParser(
                                `${variantMarketSpecifierArray[1]}=${
                                    variantMarketSpecifierArray[
                                    variantMarketSpecifierArray.length - 1
                                        ]
                                }`
                            );
                        } else if (colonCount === 1) {
                            const t = value.split(':');
                            //to sort outcomes like "hcp=2:0" by both digital values
                            if (!isNaN(t[0]) && !isNaN(t[1])) {
                                specifierOrder = parseInt(`${t[0]}00${t[1]}`);
                            }
                            //if contains some text like 'type=prematch' sort by value
                        } else {
                            specifierOrder = s.order * 1000 + s.outcomeValue;
                        }
                    } else {
                        //normal specifiers like total=2
                        specifierOrder = parseFloat(value);
                    }
                    groupedSpecifiers = {
                        ...groupedSpecifiers,
                        [macro]: {
                            value: value,
                            macro: macro,
                            specifier: s.specifier,
                            specifierText: sf,
                            specifierOrder: specifierOrder,
                        },
                    };
                }
            };

            /* For multi specifier odds like total=2&hcp=1 we process array divided by & */
            const specifierArray = s.specifier.split('&');

            specifierArray.map((sf) => specifiersParser(sf));

            return groupedSpecifiers;
        },

        get parentMatch() {
            return getParent(s, 6);
        },

        get roundedValue() {
            return Math.floor(s.outcomeValue * 100) / 100
        },

        get isLocked() {
            if (getRoot(s).betting.branchId === 'Live' && !s.isCustom) {
                return !s.betStatus;
            } else {
                return false;
            }
        },

        get renderOdd() {
            const isLive = getRoot(s).betting.branchId === 'Live';
            if (s.isLocked) {
                return <i className="llg-lock"/>;
            } else if (s.roundedValue > 1) {
                return formatNumber(
                    s.outcomeValue,
                    (s.outcomeValue >= 100 && isLive) ? 0 : 2,
                    getRoot(s).user.language
                );
            } else {
                return '—';
            }
        },

        get renderOriginalOdd() {
            if (s.originalOutcomeValue) {
                return formatNumber(
                    s.originalOutcomeValue, 2, getRoot(s).user.language
                );
            } else {
                return '';
            }
        },

        get isActive() {
            const coupons = getRoot(s)?.user?.coupons;
            if (coupons) {
                if (s.isCustom) {
                    return !!coupons.customCouponsList.find(item => item.id === s.id);
                } else {
                    return !!coupons.basicCouponsList.find(item => item.id === s.id);
                }
            } else {
                return false;
            }
        },
    }));

export default BettingItem;
