import React, { useRef, useState } from 'react';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';

import { LLBtn2, LLInput, withCommon } from '../../../../../components';
import cssVars from '../../../../../styles/themes/theme/variables.scss';


const GoogleAuthQRCodeForm = withCommon({
    store: ({ store }) => ({
        siteName: store.site.siteName,
        username: store.user.username,
        mobileUserAgent: store.user.mobileUserAgent,
        setGoogleAuth: store.user.setGoogleAuth,
        googleQrCodeUserSecret: store.user.googleQrCodeUserSecret,
        isGoogleAuthActive: store.user.isGoogleAuthActive,
        isGoogleAuthSettingLoading: store.user.isGoogleAuthSettingLoading,
        language: store.user.language,
    }),
    defaultName: 'FormRequestPassword',
    isTrans: true,
})(
    ({
        siteName,
        username,
        mobileUserAgent,
        setGoogleAuth,
        googleQrCodeUserSecret,
        isGoogleAuthActive,
        isGoogleAuthSettingLoading,
        language,
        trans,
    }) => {
        const [authCode, setAuthCode] = useState('');

        // QR Documentation: https://developers.google.com/chart/infographics/docs/qr_codes
        const partURIEncoded = encodeURIComponent(
            `otpauth://totp/${siteName}?secret=${googleQrCodeUserSecret}&issuer=${username}`
        );
        const imageQrLink = `https://quickchart.io/qr?width=${cssVars.css_sizeWidth_cabinetGoogleAuthImg}height=${cssVars.css_sizeHeight_cabinetGoogleAuthImg}&text=${partURIEncoded}`;

        const setGoogleAuthRequest = () =>
            codeInputRef.current.instance.validate()?.isValid &&
            setGoogleAuth({
                active: !isGoogleAuthActive,
                code: authCode,
            }).then(() => {
                codeTextboxRef.current.instance.reset();
                codeInputRef.current.instance.reset();
            });

        const codeInputRef = useRef(null),
            codeTextboxRef = useRef(null);

        return (
            <>
                {!isGoogleAuthActive ? (
                    mobileUserAgent ? (
                        <div className="google-auth__install-app">
                            <p className="google-auth__install-text">
                                {trans('Install Google Authenticator')}
                            </p>
                            <div className="google-auth__install-links-box">
                                <a
                                    className="google-auth__install-link google-auth__install-link--google"
                                    href={`https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=${
                                        language === 'aa' ? 'ar' : language
                                    }`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                                <a
                                    className="google-auth__install-link google-auth__install-link--apple"
                                    href={`https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DiOS&hl=${
                                        language === 'aa' ? 'ar' : language
                                    }&oco=0`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="google-auth__qr-img-container">
                            <p className="google-auth__label">
                                {trans(
                                    'Scan QR code or enter a secret key from Google Authenticator'
                                )}
                            </p>
                            <img
                                className="google-auth__img-qr"
                                src={imageQrLink}
                                alt="google QR code"
                            />
                        </div>
                    )
                ) : null}
                <ValidationGroup className="google-auth__form" ref={codeInputRef}>
                    <p className="google-auth__label">
                        {trans('Please, input Code from Google Authenticator')}
                    </p>
                    <LLInput
                        ref={codeTextboxRef}
                        customClasses="google-auth__input"
                        disabled={isGoogleAuthSettingLoading}
                        maxLength={50}
                        mode="text"
                        id="google-auth__code"
                        name="google-auth__code"
                        value={authCode}
                        showClearButton={true}
                        onEnterKey={setGoogleAuthRequest}
                        onValueChanged={(e) => setAuthCode(e.value)}
                        stylingMode="outlined"
                    >
                        <Validator>
                            <RequiredRule message={trans('This field is required')} />
                        </Validator>
                    </LLInput>
                    <LLBtn2
                        onClick={setGoogleAuthRequest}
                        customClasses="google-auth__submit-btn"
                        isLoading={isGoogleAuthSettingLoading}
                    >
                        {trans(isGoogleAuthActive ? 'Turn Off' : 'Turn On')}
                    </LLBtn2>
                </ValidationGroup>
            </>
        );
    }
);

export default GoogleAuthQRCodeForm;
