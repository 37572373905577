import React from 'react';
import {inject, observer} from 'mobx-react';

import {LLBtn} from '../../../../components';
import {trans} from '../../../../common/utils';


const ClearBetslipModal = ({store}) => {

    const modal = store.modal;
    const betInfo = store.modal.betInfo;
    const setNeedClearFetching = store.user.coupons.setNeedClearFetching;
    const clear = store.user.coupons.clear;
    const add = store.user.coupons.add;

    return <div className="ll-clear-bets-modal">
        <p className="ll-cear-bets-modal__text">{trans('CLEAR_EXISTING_SELECTION')}</p>
        <div className="ll-cear-bets-modal__footer">
            <LLBtn
                className={`ll-clear-bets-modal__yes-btn ll-btn--accent`}

                onClick={() => {
                    setNeedClearFetching(false);
                    clear(false).then(() => {
                        add(betInfo);
                        modal.close();
                    })
                }}
            >
                <div>
                    <span>{trans('Yes')}</span>
                </div>
            </LLBtn>
            <LLBtn
                className={`ll-clear-bets-modal__close-btn ll-btn--outlined`}
                onClick={() => {
                    modal.close();
                }}
            >
                {trans('Cancel')}
            </LLBtn>
        </div>
    </div>
};

export default inject('store')(observer(ClearBetslipModal));