import React, {useEffect} from 'react';

import {LLBtn2, LLMenuItem, withCommon} from '../../../components';
import {formatNumber} from '../../../common/utils';
import {DrawerCloseButton} from "../../../pages/user-profile/components";

/***************** ~~ Represents login drawer ***************
 * @file - Represents React Component to render the mobile login drawer
 *
 * @memberOf LLDrawer
 *
 * @param {function} closeDrawer - action to set drawer isActive false
 * @param {array} myAccountMenu - config param - array with rules for render account menu of Content
 * @param {boolean} isPayments - config param, block payments page
 * @param {object} drawer - store drawer, required
 * @param {function} open - action to set drawer isActive true, drawer side and inner content, required
 * @param {string} currency - user's currency code
 * @param {function} dataRefresh - receive new user data from server
 * @param {boolean} isBlocked - is user blocked
 * @param {string} language - user's currency code
 * @param {function} logout - function to logout user
 * @param {string} username - username
 *
 * @author S.Nakhodov feat. A.Green.
 */

const MyAccountMenuDrawer = withCommon({
    store: ({store}) => ({
        open: store.drawer.open,
        config: store.modifiedConfig,
        user: store.user,
        messageCount: store.user.messageCount,
    }),
    defaultName: 'MyAccountMenuDrawer',
    isTrans: true,
})((props = {}) => {
    const {
        open,
        config: {
            myAccountMenu,
            siteConfig: {
                permissionRules: {isPayments},
            },
        },
        trans,
        user,
        user: {currency, dataRefresh, isBlocked, language, logout, username},
    } = props;

    useEffect(() => {
        dataRefresh();
    }, []);

    const MenuItem = (item) => (
        <LLMenuItem
            item={item}
            key={item.name}
            customClasses="drawer-menu__item"
            onClick={() => {
                if (item.drawer) {
                    if (item.drawer.key === 'my-bets-drawer') {
                        user.userBets.setStatusFilter('All');
                    }
                    open({
                        side: item.drawer.side,
                        currentKey: item.drawer.key,
                    });
                }
            }}
            icon={<i className={`doruk-${item.icon} drawer-menu__item-icon`}/>}
            label={
                <>
                    <span className="my-account-menu-drawer__text">{trans(item.name)}</span>
                    <span className="my-account-menu-drawer__text my-account-menu-drawer__text--accent">
                        {item.storeLink && ' (' + props[item.storeLink] + ')'}
                    </span>
                </>
            }
        />
    );

    return (
        <div className="my-account-menu-drawer">
            <div className="ll-drawer__header--mobile">
                <div className="my-account-menu-drawer__info">
                    <i className="doruk-my-accont-01 my-account-menu-drawer__icon"/>
                    <span>{username} : {formatNumber(user.balance, 2, language, currency)}</span>
                </div>
                <DrawerCloseButton/>
            </div>
            <div className="drawer-menu">
                <div className="drawer-menu__items">
                    {isBlocked && <p className='my-account-menu-drawer__blocked-user'>{trans('USER_IS_BLOCKED')}</p>}
                    {myAccountMenu.map((item) => {
                        if (!item.disabled) {
                            if ((
                                item.name !== 'deposits' &&
                                item.name !== 'withdraws' &&
                                item.name !== 'withdraw-status') || isPayments
                            ) {
                                return isBlocked
                                    ? item.visibleForBlockedUser && MenuItem(item)
                                    : MenuItem(item);
                            }
                        }
                    })}
                </div>
            </div>
            <LLBtn2
                customClasses="ll-drawer__btn ll-btn--outlined"
                onClick={() => {
                    logout();
                }}
            >
                {trans('Logout')}
            </LLBtn2>
        </div>
    );
});

export default MyAccountMenuDrawer;
