import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {WithPopover} from "../../../../components";
import CurrentFilterResult from './current-filter-result';
import {trans} from "../../../../common/utils";


const FilterResult = ({store}) => {
    const activeRange = store.betting.filteredRanges.activeRange;

    const filtersList = [...activeRange?.selectedFiltersList];
    filtersList.unshift({
        id: '-1',
        label: trans('All')
    });

    const [currentFilter, setCurrentFilter] = useState(null);

    useEffect(() => {
        setCurrentFilter(
            activeRange?.selectedFiltersList.length > 1 ?
                filtersList[0]
                :
                activeRange?.selectedFiltersList[0]
        );
    }, [activeRange?.selectedFiltersList.length]);

    const isSingleFilterResults = activeRange?.selectedFiltersList?.length < 2;

    return <div className="filters-panel__filters-result">
        <div className="filters-panel__filters-result-header">
            <p className="filters-panel__filters-result-header-text">{trans('Filters')}</p>
            <span
                className="filters-panel__filters-result-clear-button"
                onClick={activeRange.clearFilters}
            >
                {trans('PREMATCH_FILTERS_PANEL_CLEAR')}
            </span>
        </div>
        { !isSingleFilterResults ?
            <div className="filters-panel__filters-result-tabs">
                {filtersList.map(filter =>
                    <div
                        key={filter.label}
                        className={`filters-panel__filters-result-tab${(currentFilter?.name === filter.name) ? ' active' : ''}`}
                        onClick={() => setCurrentFilter(filter)}
                    >
                        { store.site.status.viewSize === 'desktop' ?
                            <WithPopover popoverClasses="ll-popover__text">
                                <span>{filter.label}</span>
                            </WithPopover>
                            :
                            <span className="filters-panel__filters-result-tab-label">{filter.label}</span>
                        }
                    </div>
                )}
            </div>
            : null
        }
        <div className={cx(
            'filters-panel__filters-results',
            isSingleFilterResults && 'filters-panel__filters-results--single'
        )}>
            { currentFilter ?
                currentFilter.id === '-1' ?
                    activeRange?.selectedFiltersList.map(filter =>
                        <CurrentFilterResult
                            key={filter.label}
                            filter={filter}
                            withRemoveBtn={false}
                            activeRange={activeRange}
                            viewSize={store.site.status.viewSize}
                        />
                    )
                    :
                    <CurrentFilterResult
                        key={currentFilter.label}
                        filter={currentFilter}
                        activeRange={activeRange}
                        viewSize={store.site.status.viewSize}
                    />
                :
                null
            }
        </div>
    </div>
};

export default inject('store')(observer(FilterResult));