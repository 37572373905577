import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import withCommon from "./common-props";

/**
 * @desc Represents a WithPopover component.
 *
 * @param {boolean} mobileUserAgent - flag is mobile layout
 * @param {boolean} isActive - flag is popover opened
 * @param {function} setPopover - set popover props
 * @param {function} hidePopover - set isActive = false
 * @param {string} customClasses - classNames for custom styles this component
 * @param {object} popoverContent - it is popover content element)))
 * @param {string} popoverClasses - classNames for custom styles popover component
 * @param {object} children - children
 * @param {object} betId - bet id from store
 * @param {string} listType - active or history
 * @param {string} watch - cashout or delete
 *
 * @author S.Nakhodov & A.Levytsky
 */

const WithPopoverCashout = withCommon({
    store: ({store}) => ({
        mobileUserAgent: store.user.mobileUserAgent,
        setPopover: store.popoverCashout.setPopover,
    }),
    propTypes: {
        setPopover: PropTypes.func.isRequired,
        customClasses: PropTypes.string,
        popoverClasses: PropTypes.string,
        popoverContent: PropTypes.object,
        children: PropTypes.element.isRequired,
    },
    defaultName: "WithPopoverCashout",
    isObserver: true
})(
    ({
         mobileUserAgent,
         setPopover,
         customClasses,
         popoverClasses = "ll-popover__text",
         popoverContent,
         placement = "top",
         triggeredOnClick = true,
         dropDownRole = false,
         children,
         betId,
         listType,
         watch
     }) => {
        popoverContent = popoverContent ?? children;

        const propObj = {};
        if (mobileUserAgent || triggeredOnClick) {
            propObj.onClick = (e) => {
                e.stopPropagation();
                setPopover({
                    anchorEl: e.currentTarget,
                    isActive: !!popoverContent,
                    popoverClasses,
                    popoverContent,
                    placement,
                    stayShovedByHover: dropDownRole,
                    betId: betId,
                    listType: listType,
                    watch: watch
                });
            };
        }

        return (
            <div className={cx("ll-popover-wrapper", customClasses)} {...propObj}>
                {children}
            </div>
        );
    }
);

export default WithPopoverCashout;
