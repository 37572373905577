import { types } from 'mobx-state-tree';

import { AccountDataWithFetchTimeTemplate } from '../';
import {
    BetHistory,
    BetsTemplate,
    ActiveBets,
} from '.';


const UserBets = types
    .model('UserBets', {
        betHistory: types.optional(
            types.compose(
                BetHistory,
                BetsTemplate,
                AccountDataWithFetchTimeTemplate
        ).named('betHistory'), {}),
        activeBets: types.optional(
            types.compose(
                ActiveBets,
                BetsTemplate,
                AccountDataWithFetchTimeTemplate,
        ).named('activeBets'), {}),
        statusFilter: '',
    })
    .actions((self) => {
        return {
            setStatusFilter(status) {
                self.statusFilter = status;
                if (status === 'activeBets') {
                    self.activeBets.setMounted(true);
                    self.betHistory.setMounted(false);
                    self.activeBets.getActiveBets();
                } else if (status === 'All') {
                    self.betHistory.setMounted(true);
                    self.activeBets.setMounted(false);
                    self.betHistory.getBetHistory();
                }
            },
        }
    });

export default UserBets;