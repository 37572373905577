import React, {useEffect, useState} from 'react';
import Switch from "@mui/material/Switch";
import cx from 'classnames';

import SportUnderOverMarket from "../../mobile/components/markets/under-over-markets";
import SportScoreMarket from "../../mobile/components/markets/score-markets";
import {LLSportBettingButtonWrapper} from "../../../../components";
import {trans} from "../../../../common/utils";


const Market = (
    {
        match,
        market,
        userCanCashout,
        isOldView,
        setOldView,
        oddAppearance,
        viewOrder
    }
) => {
    const [isOpened, setOpened] = useState(true);

    useEffect(() => {
        setOpened(viewOrder < 10)
    }, [])

    let marketView = 'default';
    if ([199, 81, 98].includes(market.id) && market.betRowsList.length > 2) {
        marketView = 'scoreView';
    }
    if ([18, 19, 20, 68, 69, 70, 90, 91, 92, 225, 227, 228, 232, 236, 756, 757].includes(market.id) && market.betRowsList.length > 2) {
        marketView = 'underOverView';
    }

    const CashoutIcon = ({market}) => userCanCashout ?
        <i className={`doruk-cashout-01${
            market.canCashout ?
                ' market__icon--can-cashout'
                :
                ' market__icon--cant-cashout'
        }`} title={trans(market.canCashout ? 'Cashout possible' : 'Cashout not possible')} />
        : <span className="market__icon--can-cashout" />;

    return market.betRowsList.length ?
        <div
            className="match-details__market"
            data-market-id={market.id}
            key={market.id}
        >
            {
                <div
                    className="match-details__market-header"
                    onClick={() => setOpened(v => !v)}
                >
                    { marketView !== 'default' ?
                        <Switch
                            classes={{
                                root: 'status-filter-switcher match-details__view-switcher match-details__view-switcher-box',
                                thumb: 'status-filter-switcher-thumb match-details__view-switcher-thumb',
                                track: 'status-filter-switcher-track match-details__view-switcher-track',
                                switchBase: 'status-filter-switcher-switch-base match-details__view-switcher-switch-base',
                            }}
                            checked={!isOldView}
                            onChange={() => setOldView(!isOldView)}
                            onClick={e => e.stopPropagation()}
                        />
                        :
                        <span className="match-details__view-switcher-box" />
                    }
                    <div className="match-details__market-header-name">
                        <div dangerouslySetInnerHTML={{__html: market.name}} />
                    </div>
                    <span className="match-details__market-expand">
                        <CashoutIcon market={market} />
                        <i className={cx(
                            'match-details__market-expand-icon llg-chevron-right',
                            isOpened && 'match-details__market-expand-icon--expanded'
                        )}
                        />
                    </span>
                </div>
            }

            <div className={cx(
                'match-details__content',
                isOpened && 'match-details__content--opened'
            )}>
                { (!isOldView && marketView === 'underOverView') ?
                    <SportUnderOverMarket
                        betRows={market.betRowsList}
                        oddAppearance={oddAppearance}
                    /> : null
                }

                { (!isOldView && marketView === 'scoreView') ?
                    <SportScoreMarket
                        bets={market.betRowsList.reduce((acc, row) => {
                            return [...acc, ...row.bets.reduce((bets, bet) => {
                                if (bet && bet.outcomeValue) {
                                    return [...bets, bet];
                                } else {
                                    return bets;
                                }
                            }, [])]
                        }, [])}
                        oddAppearance={oddAppearance}
                        match={match}
                    /> : null
                }
                { (marketView === 'default' || isOldView) ?
                    market.betRowsList.map((row, i) =>
                        <div className={`match-details__bets`} key={i}>
                            { row.bets.map((bet) =>
                                bet && <LLSportBettingButtonWrapper
                                    bet={bet}
                                    key={bet.id}
                                    columnCount={row.columnCount}
                                    className="match-details__bet"
                                    oddAppearance={oddAppearance}
                                />
                            )}
                        </div>
                    ) : null
                }
            </div>
        </div> : null
};

export default Market;