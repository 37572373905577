import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';

import {
    LLElementTypeSetter,
    LLPreloader,
    WithPopover,
    LLProgressBar,
} from '../../../../components';
import { getSportIconsClassnames } from '../../../../common/utils';
import config from '../../../../static/themes/config';

const PrematchMenuDesktop = ({
    isLoading,
    activeItems,
    activeMenuItems,
    sports,
    renderSportsLink,
    renderCategoryLink,
    renderTournamentLink,
    activeItemsJSON,
    viewSize,
    store,
}) => {
    useEffect(() => {
        activeMenuItems.setAllActiveMenuItems({
            sports: activeItems.sports,
            categories: activeItems.categories,
            tournaments: activeItems.tournaments,
        });
    }, [activeItemsJSON]);

    const parentScroll = store.scrollBoxes.getScrollBox('prematch-left-panel');

    const sportItemWrapper = (sport) => {
        const menuItemProps = {
            as: activeItems.tournaments.length ? 'div' : 'NavLink',
            className: classNames(
                'menu-item',
                'menu-item--sport',
                {
                    'menu-item--active': activeMenuItems.isActive({
                        id: sport.id,
                        type: 'sports',
                    }),
                    'menu-item--loading': sport.initialFetching,
                },
            ),
        };
        if (!activeItems.tournaments.length) {
            menuItemProps.to = renderSportsLink({ sport });
            menuItemProps.onClick = () => {
                if (!activeMenuItems.isActive({ id: sport.id, type: 'sports' })) {
                    setTimeout(() => {
                        parentScroll.scrollMethods.scrollToElement(`.menu-item--sport[id="${sport.id}"]`);
                    }, 600);
                }
            };
        } else {
            menuItemProps.onClick = () => {
                if (!activeMenuItems.isActive({ id: sport.id, type: 'sports' })) {
                    sport.initializeCategoriesInstruction({ id: sport.id });
                    activeMenuItems.setAllActiveMenuItems({
                        sports: [sport.id],
                        categories: [],
                        tournaments: [],
                    });
                    setTimeout(() => {
                        parentScroll.scrollMethods.scrollToElement(`.menu-item--sport[id="${sport.id}"]`);
                    }, 600);
                } else {
                    activeMenuItems.setAllActiveMenuItems({
                        sports: [],
                        categories: [],
                        tournaments: [],
                    });
                }
            };
        }

        return (
            <Fragment key={sport.id}>
                <LLElementTypeSetter id={`${sport.id}`} {...menuItemProps}>
                    {sportItem(sport)}
                </LLElementTypeSetter>
                <Collapse
                    in={
                        activeMenuItems.isActive({ id: sport.id, type: 'sports' }) &&
                        (!sport.initialFetching || sport.isParent)
                    }
                >
                    <div>
                        {sport.isParent
                            ? sport.listSubSports?.map((subSport) =>
                                  subSportItemWrapper({
                                      parentSport: sport,
                                      sport: subSport,
                                  })
                              )
                            : sport.categoriesList?.map((category) =>
                                  categoryItemWrapper({
                                      sport,
                                      category,
                                  })
                              )}
                    </div>
                </Collapse>
            </Fragment>
        );
    };

    const subSportItemWrapper = ({ parentSport, sport }) => {
        const menuItemProps = {
            as: activeItems.tournaments.length ? 'div' : 'NavLink',
            className: classNames(
                'menu-item',
                'menu-item--sub-sport',
                {
                    'menu-item--active': activeMenuItems.isActive({
                        id: sport.id,
                        type: 'sports',
                    }),
                    'menu-item--loading': sport.initialFetching,
                },
            ),
        };
        if (!activeItems.tournaments.length) {
            menuItemProps.to = renderSportsLink({
                sport: parentSport,
                subSportId: sport.id,
            });
        } else {
            menuItemProps.onClick = () => {
                if (!activeMenuItems.isActive({ id: sport.id, type: 'sports' })) {
                    sport.initializeCategoriesInstruction({ id: sport.id });
                    activeMenuItems.setAllActiveMenuItems({
                        sports: [parentSport.id, sport.id],
                        categories: [],
                        tournaments: [],
                    });
                } else {
                    activeMenuItems.setAllActiveMenuItems({
                        sports: [parentSport.id],
                        categories: [],
                        tournaments: [],
                    });
                }
            };
        }

        return (
            <Fragment key={sport.id}>
                <LLElementTypeSetter {...menuItemProps} id={`${sport.id}`}>
                    {sportItem(sport)}
                </LLElementTypeSetter>
                <Collapse
                    in={
                        activeMenuItems.isActive({ id: sport.id, type: 'sports' }) &&
                        !sport.initialFetching
                    }
                >
                    <div>
                        {sport.categoriesList?.map((category) =>
                            categoryItemWrapper({
                                sport: parentSport,
                                subSportId: sport.id,
                                category,
                            })
                        )}
                    </div>
                </Collapse>
            </Fragment>
        );
    };

    const sportItem = (sport) => {
        return (
            <>
                <div className="menu-item__content">
                    <i
                        className={classNames(
                            'menu-item__icon',
                            getSportIconsClassnames(
                                config(store.site.siteName).siteConfig.appearanceRules.sportIcons,
                                viewSize,
                                sport.id
                            )
                        )}
                    />
                    <WithPopover
                        popoverContent={<span>{sport.name}</span>}
                        popoverClasses="ll-popover__text"
                    >
                        <span className="menu-item__text">{sport.name}</span>
                    </WithPopover>
                    <div className="menu-item__after">
                        {sport.matchCount} / {sport.outrightCount}
                    </div>
                    <i
                        className={classNames(
                            `menu-item__dropdown-icon${
                                activeMenuItems.isActive({ id: sport.id, type: 'sports' })
                                    ? '--active'
                                    : ''
                            }`,
                            'dx-icon-spindown'
                        )}
                    />
                </div>
                {sport.initialFetching &&
                    !sport.isParent &&
                    (activeItems.isActive({ type: 'sports', id: sport.id }) ||
                        activeMenuItems.isActive({ type: 'sports', id: sport.id })) && (
                        <LLProgressBar customClasses="menu-item__progress-bar" />
                    )}
            </>
        );
    };

    const categoryItemWrapper = ({ sport, subSportId, category }) => {
        const menuItemProps = {
            as: activeItems.tournaments.length ? 'div' : 'NavLink',
            className: classNames(
                'menu-item',
                'menu-item--category',
                {
                    'menu-item--active': activeMenuItems.isActive({
                        id: category.id,
                        type: 'categories',
                    }),
                    'menu-item--loading': sport.initialFetching,
                },
            ),
            'data-category-id': category.id,
        };
        if (!activeItems.tournaments.length) {
            menuItemProps.to = renderCategoryLink({ sport, subSportId, category });
        } else {
            menuItemProps.onClick = () => {
                const sports = [sport.id];
                subSportId && sports.push(subSportId);
                if (!activeMenuItems.isActive({ id: category.id, type: 'categories' })) {
                    category.initializeTournamentsInstruction({
                        id: category.id,
                    });
                    activeMenuItems.setAllActiveMenuItems({
                        sports: sports,
                        categories: [category.id],
                        tournaments: [],
                    });
                } else {
                    activeMenuItems.setAllActiveMenuItems({
                        sports: sports,
                        categories: [],
                        tournaments: [],
                    });
                }
            };
        }

        return (
            category?.id && (
                <Fragment key={category.id}>
                    <LLElementTypeSetter {...menuItemProps}>
                        {categoryItem(category)}
                    </LLElementTypeSetter>
                    <Collapse
                        in={
                            activeMenuItems.isActive({
                                id: category.id,
                                type: 'categories',
                            }) &&
                            (!category.initialFetching || category.isParent)
                        }
                    >
                        <div>
                            {category.isParent
                                ? category.listSubCategories?.map((subCategory) =>
                                      subCategoryItemWrapper({
                                          sport,
                                          subSportId,
                                          parentCategory: category,
                                          category: subCategory,
                                      })
                                  )
                                : category.tournamentsList?.map((tournament) =>
                                      tournamentItemWrapper({
                                          sport,
                                          subSportId,
                                          category,
                                          tournament: tournament,
                                      })
                                  )}
                        </div>
                    </Collapse>
                </Fragment>
            )
        );
    };

    const subCategoryItemWrapper = ({ sport, subSportId, parentCategory, category }) => {
        const menuItemProps = {
            as: activeItems.tournaments.length ? 'div' : 'NavLink',
            className: classNames(
                'menu-item',
                'menu-item--sub-category',
                {
                    'menu-item--active': activeMenuItems.isActive({
                        id: category.id,
                        type: 'categories',
                    }),
                    'menu-item--loading': sport.initialFetching,
                },
            ),
            'data-sub-category-id': category.id,
        };
        if (!activeItems.tournaments.length) {
            menuItemProps.to = renderCategoryLink({
                sport,
                subSportId,
                category: parentCategory,
                subCategoryId: category.id,
            });
        } else {
            menuItemProps.onClick = () => {
                const sports = [sport.id];
                subSportId && sports.push(subSportId);
                if (!activeMenuItems.isActive({ id: category.id, type: 'categories' })) {
                    category.initializeTournamentsInstruction({
                        id: category.id,
                    });
                    activeMenuItems.setAllActiveMenuItems({
                        sports: sports,
                        categories: [parentCategory.id, category.id],
                        tournaments: [],
                    });
                } else {
                    activeMenuItems.setAllActiveMenuItems({
                        sports: sports,
                        categories: [parentCategory.id],
                        tournaments: [],
                    });
                }
            };
        }

        return (
            category?.id && (
                <Fragment key={category.id}>
                    <LLElementTypeSetter {...menuItemProps}>
                        {categoryItem(category)}
                    </LLElementTypeSetter>
                    <Collapse
                        in={
                            activeMenuItems.isActive({
                                id: category.id,
                                type: 'categories',
                            }) && !category.initialFetching
                        }
                    >
                        <div>
                            {category.tournamentsList?.map((tournament) =>
                                tournamentItemWrapper({
                                    sport,
                                    subSportId,
                                    category: parentCategory,
                                    subCategoryId: category.id,
                                    tournament,
                                })
                            )}
                        </div>
                    </Collapse>
                </Fragment>
            )
        );
    };

    const categoryItem = (category) => {
        return (
            <>
                <div className="menu-item__content">
                    <WithPopover
                        popoverContent={<span>{category.name}</span>}
                        popoverClasses="ll-popover__text"
                    >
                        <span className="menu-item__text">{category.name}</span>
                    </WithPopover>
                    <div className="menu-item__after">
                        {category.matchCount} / {category.outrightCount}
                    </div>
                    <i
                        className={classNames(
                            `menu-item__dropdown-icon${
                                activeMenuItems.isActive({
                                    id: category.id,
                                    type: 'categories',
                                })
                                    ? '--active'
                                    : ''
                            }`,
                            'dx-icon-spindown'
                        )}
                    />
                </div>
                {category.initialFetching &&
                    !category.isParent &&
                    (activeItems.isActive({ type: 'categories', id: category.id }) ||
                        activeMenuItems.isActive({
                            type: 'categories',
                            id: category.id,
                        })) && <LLProgressBar customClasses="menu-item__progress-bar" />}
            </>
        );
    };

    const tournamentItemWrapper = ({
        sport,
        subSportId,
        category,
        subCategoryId,
        tournament,
    }) => {
        const categoryIsActive = subCategoryId
            ? activeItems.isActive({ id: subCategoryId, type: 'categories' })
            : activeItems.isActive({ id: category.id, type: 'categories' });

        const isActive =
            activeItems.isActive({ id: tournament.id, type: 'tournaments' }) &&
            categoryIsActive;

        return (
            <NavLink
                to={renderTournamentLink({
                    sport,
                    subSportId,
                    category,
                    subCategoryId,
                    tournamentId: tournament.id,
                })}
                className={classNames(
                    'menu-item',
                    'menu-item--tournament',
                    {
                        'menu-item--active': isActive,
                        'menu-item--loading': tournament.initialFetching,
                    },
                )}
                data-tournament-id={tournament.id}
                key={tournament.id}
            >
                {tournamentItem(tournament)}
            </NavLink>
        );
    };

    const tournamentItem = (tournament) => {
        return (
            <>
                <div className="menu-item__content">
                    <WithPopover
                        popoverContent={<span>{tournament.name}</span>}
                        popoverClasses="ll-popover__text"
                    >
                        <span className="menu-item__text">{tournament.name}</span>
                    </WithPopover>
                </div>
            </>
        );
    };

    return isLoading ? (
        <div className="menu__loading">
            <LLPreloader height={50} width={50} />
        </div>
    ) : (
        <>{sports.map(sportItemWrapper)}</>
    );
};

export default inject('store')(observer(PrematchMenuDesktop));
