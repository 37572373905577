import cx from "classnames";
import React from "react";

import {WithPopover} from "../../../HOCs";


const LLWidgetCompetitors = (props) => {
    const {event, isWinnerMarket, homeLogo, awayLogo} = props;

    return (
        <div className={cx(
            "ll-widget-item__event-name",
            isWinnerMarket && "ll-widget-item__event-name--winner"
        )}>
            {event.matchName ?
                <WithPopover>
                    <span>{event.matchName}</span>
                </WithPopover>
                :
                <>
                    <div className="ll-widget-item__event-name-box">
                        {event.homeTeamHasLogo ?
                            <img
                                className="ll-widget-item__competitor-logo"
                                src={`${homeLogo}`}
                                alt={event.competitor1.name}
                            />
                            :
                            <i className="ll-widget-item__competitor-logo--no-logo doruk-sport-default-icon"/>
                        }
                        <div className="ll-widget-item__competitor-name">
                            <WithPopover>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: event.competitor1.name,
                                        }}
                                    />
                            </WithPopover>
                        </div>
                    </div>
                    {!isWinnerMarket && <div className="ll-widget-item__competitor-derivative">

                    </div>}
                    <div
                        className={cx(
                            "ll-widget-item__event-name-box",
                            !isWinnerMarket && "ll-widget-item__event-name-box--right",
                        )}
                    >
                        {isWinnerMarket ? <>
                            {event.awayTeamHasLogo ?
                                <img
                                    className="ll-widget-item__competitor-logo"
                                    src={`${awayLogo}`}
                                    alt={event.competitor2.name}
                                />
                                :
                                <i className="ll-widget-item__competitor-logo--no-logo doruk-sport-default-icon"/>
                            }
                            <div className="ll-widget-item__competitor-name">
                                <WithPopover>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: event.competitor2.name,
                                            }}
                                        />
                                </WithPopover>
                            </div>
                        </> : <>
                            <div className="ll-widget-item__competitor-name">
                                <WithPopover>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: event.competitor2.name,
                                            }}
                                        />
                                </WithPopover>
                            </div>
                            {event.awayTeamHasLogo ?
                                <img
                                    className="ll-widget-item__competitor-logo"
                                    src={`${awayLogo}`}
                                    alt={event.competitor2.name}
                                />
                                :
                                <i className="ll-widget-item__competitor-logo--no-logo doruk-sport-default-icon"/>
                            }
                        </>}
                    </div>
                </>
            }
        </div>
    )
}

export default LLWidgetCompetitors;
