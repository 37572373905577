import React from 'react';
import cx from "classnames";
import {inject, observer} from "mobx-react";
import {Link} from 'react-router-dom';

import {betOddsFormat, formatNumber, isCombinedMatch, trans} from '../../../common/utils';
import CouponsAmount from './coupons-amount';
import {LLInput} from '../../../components';
import {currencySymbols} from "../../../configs";


const LLCouponListItem = (
    {
        store,
        index = 0,
        coupon,
        list = [],
        isMultiSingle,
        isSingleOnly
    }
) => {
    const
        branch = store.betting.branchId,
        language = store.user.language,
        isCustom = store.user.coupons.isCustom,
        clear = store.user.coupons.clear,
        currency = store.user.currency;

    const couponName = (coupon) =>
        isCombinedMatch(coupon.matchId)
            ? coupon.matchName
            : coupon.oddsTypeSelection;

    const ItemInner = () => (
        <>
            <div className="coupons-list__event-date">
                <span>{coupon.formatedTimeStart}</span>
            </div>
            <div className="coupons-list__event-info">
                    <span className="event-info__selected">
                        {coupon.matchType === 'LiveMatch' && (
                            <i
                                title={trans('Live Match')}
                                className="doruk-live-match-01 coupons__live-indicator"
                            />
                        )}
                        <span className="event-info__selected-name">{couponName(coupon)}</span>
                        {!isCombinedMatch(coupon.matchId) && (
                            <span className="coupons__event-value">
                                {betOddsFormat(coupon.odds, language)}
                            </span>
                        )}
                    </span>
            </div>
            {!isCombinedMatch(coupon.matchId) && (
                <>
                    <div className="coupons-list__event-type">
                        {coupon.oddsType}
                    </div>
                    <div className="coupons-list__event-info">
                        {coupon.matchType === 'OutRight' ? (
                            <div className='ll-coupon__name-with-odd--full'>
                                {coupon.matchName}
                            </div>
                        ) : (
                            <div className="event-info__match-name">
                                <span className="team-name-holder">{coupon.competitor1}</span>
                                <span>&nbsp;-&nbsp;</span>
                                <span className="team-name-holder">{coupon.competitor2}</span>
                            </div>
                        )}
                    </div>
                </>
            )}
            {isMultiSingle &&
                <div className="coupons-event-settings">
                    <CouponsAmount
                        betAmount={coupon.betAmount}
                        maximumStake={coupon.maximumStake}
                        setBetAmount={coupon.setBetAmount}
                        isMaxStakeExceeded={coupon.isMaxStakeExceeded}
                        isMultiSingle={isMultiSingle}
                    />
                    <div className="coupons__amount coupons__amount--multi">
                        <div className="coupons__amount-wrapper">
                            <p className="coupons__amount-label coupons__amount-label--multi">
                                {trans('Possible payout')}
                            </p>
                            <LLInput
                                customClasses="coupons__amount-input"
                                value={formatNumber(coupon.payout, 2, language, '', language === 'aa')}
                                disabled={true}
                            />
                            <span className="coupons__input-currency">
                                {currencySymbols[currency]}
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    );

    const CustomItemInner = () => (
        <>
            <div className="coupons-list__event-date">
                <span>{coupon.formatedTimeStart}</span>
            </div>
            <div className="event-info__selected">
                {coupon.competitor1} - {coupon.competitor2}
                {list.length > 1 ?
                    <span className="coupons__event-value">
                        {betOddsFormat(coupon.customOdds, language)}
                    </span>
                    : null}
            </div>
            <div className="event-info__details-title">
                {`${trans('Details')}:`}
            </div>
            <div className="event-info__custom-bets">
                {list.map((coupon, i) => (
                    <div key={i}
                         className={`event-info__custom-bet${(list.length > 1) ? ' event-info__custom-bet--with-dots' : ''}`}
                    >
                        <div className="event-info__custom-bet-selection">
                            {`${coupon.oddsType} - ${coupon.oddsTypeSelection}`}
                        </div>
                        {list.length > 1 && <div
                            className="event-info__custom-bet-selection-delete"
                            onClick={coupon.remove}
                        >
                            <i className="event-info__custom-bet-selection-delete-icon doruk-close-circle"/>
                        </div>}
                    </div>
                ))}
            </div>
        </>
    );

    return <div
        className={cx(
            'coupons-list__event',
            ((index + 1) % 2 === 0) ? 'coupons-list__event--even' : 'coupons-list__event--odd',
            isCombinedMatch(coupon.matchId) ? 'coupons-list__event--combined' : '',
            isCustom ? 'coupons-list__event--custom' : ''
        )}
        key={coupon.id}
    >
        <div className="coupons-list__event-background">
            <i className={`coupons-list__event-background-icon sport_icon_font-${coupon.sportId}`}/>
        </div>
        <div className="coupons-list__event-part">
            <button
                className="coupons-list__event-remove-btn"
                type="button"
                onClick={isCustom ? clear : coupon.remove}
            >
                <i className="doruk-close"/>
                {isSingleOnly && <i className={cx(
                    'only-single-error__icon',
                    'doruk-icon-attention'
                )}/>}
            </button>
        </div>
        <Link
            className="coupons-list__event-part coupons-list__event-part--main"
            to={`/sport/${coupon.matchType === 'LiveMatch' ? 'live' : '1'}/${coupon.sportId}/${coupon.categoryId}/${coupon.tournamentId}/${coupon.matchId}`}
        >
            {isCustom ? <CustomItemInner/> : <ItemInner/>}
        </Link>
    </div>
};

export default inject('store')(observer(LLCouponListItem));