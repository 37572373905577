import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import {
    LLScrollBox,
    LLSportBettingButtonWrapper,
    LLPreloader,
} from '../../../../components';
import { trans } from '../../../../common/utils';
import {LiveScoreboardWithBreadcrumbs} from '../../components';


const LLOutrightDetails = ({ store }) => {
    const prematchRouterOutrightInstance = store.router.prematchRouterOutrightInstance,
        matchDetailError = store.betting.bettingErrors.detail,
        viewSize = store.site.status.viewSize,
        language = store.user.language,
        clearError = store.betting.clearError;

    useEffect(
        () => () => {
            clearError({ type: 'detail' });
        },
        []
    );

    const renderBets = () => {
        if (!prematchRouterOutrightInstance?.betsLength) {
            return <div> This outright bets is not available</div>;
        }

        return (
            <>
                <LiveScoreboardWithBreadcrumbs
                    matchInstance={prematchRouterOutrightInstance}
                    viewSize={viewSize}
                    language={language}
                    type="Outrights"
                />

                {matchDetailError ? (
                    <div className="match-details__error">{trans(matchDetailError)}</div>
                ) : (
                    <div className={`match-details__bets`}>
                        {prematchRouterOutrightInstance?.betsList.map((bet) => {
                            return (
                                <LLSportBettingButtonWrapper
                                    type={'outRight'}
                                    bet={bet}
                                    key={bet.id}
                                    columnCount={prematchRouterOutrightInstance.columnCount || 2}
                                    className="outright-details__bet"
                                />
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    return (
        <LLScrollBox id="outright-detail">
            <div className="outright-details">
                <LLPreloader
                    fullPage
                    visible={prematchRouterOutrightInstance?.initialFetching}
                    height={50}
                    width={50}
                    timeout={300}
                    className="ll-preloader__prematch-detail"
                />
                {prematchRouterOutrightInstance?.betsLength && renderBets()}
            </div>
        </LLScrollBox>
    );
};

export default inject('store')(observer(LLOutrightDetails));
