import {keys, values} from 'mobx';
import {types as t, getRoot, flow} from 'mobx-state-tree';

import searchFetches from './search-fetches';
import {refreshTime} from '../../config';
import {trans} from '../../../../../common/utils';


const SearchSportItem = t
    .model('SearchSportItem', {
        id: t.identifier,
        matchIds: t.array(t.string),
    })
    .actions((s) => ({
        pushMatchIds(id) {
            s.matchIds.push(id);
        },
    }));

const SearchInstance = t
    .model('SearchInstance', {
        id: t.identifier,
        matchIds: t.array(t.string),
        sports: t.map(SearchSportItem),
        activeSport: t.maybeNull(t.string),
        fetchTime: t.maybeNull(t.Date),
    })
    .actions((s) => ({
        setFetchTime(date) {
            s.fetchTime = date;
        },
        setActiveSport(id) {
            s.activeSport = id;
        },
    }));

const Search = t
    .model('Search', {
        archive: t.map(SearchInstance),
        searchString: t.maybeNull(t.string),
        initialFetching: false,
    })
    .extend((s) => {
        return {
            actions: {
                ...searchFetches(s),
            },
        };
    })
    .actions((s) => ({
        clearSearch() {
            s.searchString = null;
            s.clearErrors();
        },

        clearErrors() {
            getRoot(s).betting.clearError({type: 'search'});
        },

        setActiveSport(id) {
            const currentSearch = s.archive.get(s.searchString);
            if (currentSearch) {
                currentSearch.setActiveSport(id);
            }
        },

        _setData({sportId, eventId, currentSearchItem}) {
            if (!currentSearchItem.sports.size) {
                currentSearchItem.sports.put({
                    id: '-999',
                    matchIds: [],
                });
                currentSearchItem.activeSport = '-999';
            }
            if (currentSearchItem.sports.has(sportId)) {
                currentSearchItem.sports.get(sportId).pushMatchIds(eventId);
            } else {
                currentSearchItem.sports.put({
                    id: sportId,
                    matchIds: [eventId],
                })
            }
            currentSearchItem.sports.get('-999').pushMatchIds(eventId);
        },

        unsuccessInstruction({err}) {
            getRoot(s).betting.setError({
                error: err ? err : 'SPORTS_SEARCH_TECHNICAL_ISSUES_ERROR',
                type: 'search',
            });
        },

        successInstruction({searchString, data}) {
            if (s.archive.size >= 5) {
                s.archive.delete(values(s.archive)[0]);
            }

            const currentSearchItem = s.archive.put({id: searchString, matchIds: []});

            if (Object.keys(data).length) {
                getRoot(s).betting.matches.setMatchesData({
                    data,
                    addFilteredMatchesIds: (eventId, match, storedEvent) => {
                        s._setData({sportId: storedEvent.sportId, eventId, currentSearchItem});
                        return currentSearchItem.matchIds.push(eventId);
                    },
                });
            }

            currentSearchItem.setFetchTime(new Date());
        },

        initializeSearch: flow(function* fetch({searchString}) {
            s.clearErrors();
            if (
                !s.archive.has(searchString) ||
                s.archive.get(searchString).fetchTime?.getTime() + refreshTime.search <
                Date.now()
            ) {
                s.initialFetching = true;

                const res = yield s.fetchAndConvertSearchData({
                    searchString,
                }) || {};

                if (res.success && !res.data?.error) {
                    s.successInstruction({searchString, data: res.data});
                } else {
                    s.unsuccessInstruction({searchString, err: res.data?.error});
                }
                s.initialFetching = false;
            }
            s.searchString = searchString;
        }),
    }))
    .views((s) => ({
        get sportsList() {
            const currentSearch = s.archive.get(s.searchString);
            if (currentSearch) {
                return keys(currentSearch.sports).reduce((acc, sportId) => (
                    [
                        ...acc,
                        {
                            sport: sportId === '-999' ?
                                {
                                    id: sportId,
                                    name: trans('All'),
                                }
                                :
                                getRoot(s).betting.branch.sports.get(sportId),
                            count: currentSearch.sports.get(sportId).matchIds.length,
                        },
                    ]
                ), []);
            } else {
                //     TODO: some error here
            }
        },

        get sportMatchesList() {
            const currentSearch = s.archive.get(s.searchString);
            return s.getGroupedByDateEvents(currentSearch?.sports.get(currentSearch.activeSport)?.matchIds || [], true);
        },

        get fetchTime() {
            return s.archive.get(s.searchString)?.fetchTime;
        },

        get currentSearch() {
            return s.archive.get(s.searchString);
        },
    }));

export default Search;
