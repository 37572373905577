import React, {useEffect, useState} from 'react';
import cx from 'classnames';

import {
    LLBtn2,
    withCommon,
    WithPopover,
    BattleCounter
} from '../../../components';
import {formatNumber, maskedUserName} from '../../../common/utils';
import {GameImgLoader} from './img-loader';
import {SlotBattlesPrivatePassForm} from "./private-pass-form";


export const SlotsBattlesListItem = withCommon({
    store: ({store}) => ({
        slotsBattles: store.slotsBattles,
        slotBattlesType: store.slotsBattles.slotBattlesType,
        language: store.user.language,
        currency: store.user.currency,
        userBattleId: store.user.userBattleId,
        isLogged: store.user.isLogged,
        openLogin: store.user.openLogin,
        username: store.user.username,
        canPlaySlotBattles: store.user.canPlaySlotBattles,
        viewSize: store.site.status.viewSize,
        router: store.router,
        whatWasChanged: store.user.battlePopup.whatWasChanged
    }),
    defaultName: 'SlotsBattlesListItem',
    isObserver: true,
    isTrans: true,
})(({
        room,
        slotsBattles,
        slotBattlesType,
        viewSize,
        language,
        currency,
        userBattleId,
        router,
        isLogged,
        openLogin,
        username,
        canPlaySlotBattles,
        updateBattleRooms,
        trans,
        whatWasChanged
    }) => {

    const [isLoading, setLoading] = useState(false);
    const [passForm, setPassForm] = useState(false);

    let isSlotsBattlesListItemMounted = true;

    useEffect(() => () => {
        isSlotsBattlesListItemMounted = false;
    }, []);

    const goToDetailsClickHandler = () => router.push(`/slots-battles/${room.RoomUUID}`);

    const JoinLeaveBtn = () => (
        <div className="slots-battles-game-item__btns"
             onClick={(e) => e.stopPropagation()}
        >
            {userBattleId !== room.RoomUUID ?
                <LLBtn2
                    customClasses="slots-battles-game-item__join-btn slots-battles__btn--accent"
                    isLoading={isLoading}
                    onClick={() => {
                        if (isLogged) {
                            if (room.IsPrivate) {
                                setPassForm(true);
                            } else {
                                setLoading(true);
                                try {
                                    slotsBattles.joinBattle({roomId: room.RoomUUID}).then(
                                        () => {
                                            isSlotsBattlesListItemMounted && setLoading(false);
                                            goToDetailsClickHandler();
                                        }
                                    );
                                } catch {
                                    setLoading(false);
                                }
                            }
                        } else {
                            openLogin();
                        }
                    }}
                    disabled={!!userBattleId || !canPlaySlotBattles || isLoading}
                    title={!canPlaySlotBattles ? trans('SLOT_BATTLE__USER_CANT_PLAY') : userBattleId ? trans('SLOT_BATTLE__ALREADY_IN_BATTLE') : ''}
                >
                    {trans('SLOT_BATTLE__JOIN')}
                </LLBtn2>
                :
                <LLBtn2
                    customClasses="slots-battles-game-item__leave-btn slots-battles__btn--main"
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                        clearTimeout(window._roomsUpdateInterval);
                        setLoading(true);
                        slotsBattles.leaveBattle(room.RoomUUID).then(
                            () => {
                                updateBattleRooms(slotBattlesType);
                                isSlotsBattlesListItemMounted && setLoading(false);
                            }
                        );
                    }}
                >
                    {trans('SLOT_BATTLE__LEAVE')}
                </LLBtn2>}
        </div>
    );

    return (
        <div
            onClick={goToDetailsClickHandler}
            className={cx(
                'slots-battles-game-item',
                `slots-battles-game-item--${viewSize}`,
                `slots-battles-game-item--${slotBattlesType}`
            )}
        >
            <div className="slots-battles-game-item__wrp">
                {room.IsPrivate ?
                    <i className={`llg-lock slots-battles-lock slots-battles-lock--${viewSize}`}/>
                    : null
                }
                <div className="slots-battles-game-item__img-section">
                    <div className="slots-battles-game-item__img-wrp">
                        <GameImgLoader item={room}/>
                    </div>
                </div>
                <div className="slots-battles-game-item__info-section">
                    {(slotBattlesType !== 'completed' && room.idt) &&
                        <div
                            className={cx("slots-battles-game-item__info-item",
                                "slots-battles-game-item__info-item--accent",
                                "slots-battles-game-item__info-item--played",
                                whatWasChanged === 'userIsPlayed' && "slots-battles-game-item__info-item--played",
                                viewSize !== 'desktop' && "slots-battles-game-item__info-item-status")}>
                            {viewSize === 'desktop' ?
                                <WithPopover popoverClasses="ll-popover__text">
                                    <span>
                                        <BattleCounter
                                            battle={room}
                                            startTime={room?.sdt ?? null}
                                            insertTime={room?.idt}
                                        />
                                    </span>
                                </WithPopover>
                                : <BattleCounter
                                    battle={room}
                                    startTime={room?.sdt ?? null}
                                    insertTime={room?.idt}
                                />
                            }
                        </div>
                    }
                    <div className="slots-battles-game-item__info">
                        <div className="slots-battles-game-item__info-item">
                            <WithPopover popoverClasses="ll-popover__text">
                                <span>{trans('SLOT_BATTLE__BET')}</span>
                            </ WithPopover>
                            <span className="slots-battles-game-item__info-item-value">
                            {formatNumber(room.Bet, 2, language, currency)}
                        </span>
                        </div>
                        <div className="slots-battles-game-item__info-item">
                            <span>{trans('SLOT_BATTLE__PLAYERS')}</span>
                            <span className="slots-battles-game-item__info-item-value">
                            {`${room.ConnectedPlayersQty}/${room.PlayersQty}`}
                        </span>
                        </div>
                        <div className="slots-battles-game-item__info-item">
                            <span>{trans('SLOT_BATTLE__OWNER')}</span>
                            <WithPopover popoverClasses="ll-popover__text">
                                <span>{room?.owner !== username ? maskedUserName(room?.owner) : room?.owner}</span>
                            </ WithPopover>
                        </div>
                    </div>
                    {(slotBattlesType === 'available') ? <JoinLeaveBtn/> : null}

                    {passForm ?
                        <SlotBattlesPrivatePassForm
                            setLoading={setLoading}
                            setPassForm={setPassForm}
                            joinBattle={slotsBattles.joinBattle}
                            roomId={room?.RoomUUID}
                        />
                        : null
                    }
                </div>
            </div>
        </div>
    );
});
