import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {withCommon, LLPreloader} from '../../';
import {imageLoading, getS3ImageUrl} from '../../../common/utils';
import GameButtonBuilder from './game-button-builder';
import {GamesContext} from "../../../pages/slots-and-casinos/slots-and-casinos";


/**
 * @desc Represents a slots or casino gameList component.
 * @memberOf SlotsCasinosGamesList
 *
 * @param {object} item - this game object
 * @param {('Slots'|'Casino')} gamesType - flag to render slots or casino page
 * @param { object } slotsCasinoGames - data from store with methods
 * @param {func} props.trans
 *
 * @author S.Nakhodov
 */

const GameItem = withCommon({
    store: ({store}) => ({
        slotsCasinoGames: store.slotsCasinoGames,
        viewSize: store.site.status.viewSize,
        isLogged: store.user.isLogged,
    }),
    propTypes: {
        item: PropTypes.object.isRequired,
        gamesType: PropTypes.oneOf(['slots', 'casino']).isRequired,
        slotsCasinoGames: PropTypes.object.isRequired,
        viewSize: PropTypes.string.isRequired,
    },
    defaultName: 'SlotsCasinosGamesItem',
    isObserver: true,
    isTrans: true,
})((
    {
        item,
        gamesType,
        slotsCasinoGames,
        trans,
        viewSize,
        isLogged,
    }
) => {
    const [loading, setLoading] = useState(true);
    const [itemHasImg, setItemHasImg] = useState(false);
    const [isFavoriteGame, setIsFavoriteGame] = useState(!!item.favoriteOrder);

    const {mode} = useContext(GamesContext) ?? {};

    const providerItem = slotsCasinoGames.getProvider(gamesType, item.brandName);

    item.thumbnail = item.thumbnail ?? ''; // TODO: check this line with Stas
    const imgUrl = `${getS3ImageUrl()}/images/thumbnails/${item.thumbnail}`;

    const handleFavoriteToggle = () => slotsCasinoGames.favoriteToggle({
        gamesType,
        item,
        isFavoriteGame,
        setIsFavoriteGame
    });

    let bananaDefender = true;
    const bananaDefenderCurrentState = () => bananaDefender;

    useEffect(() => {
        imageLoading(imgUrl).then((res) => {
            if (bananaDefenderCurrentState()) {
                setItemHasImg(res);
                setLoading(false);
            }
        });
        return () => bananaDefender = false;
    }, []);

    const renderPopupInner = () => {
        return <>
            {viewSize !== 'tablet' && (
                <>
                    {item.hasFunMode == 1 && (
                        <GameButtonBuilder
                            item={item}
                            mode={1}
                            customClasses="game__fun-button"
                            needButton={true}
                        >
                            <>{trans('FUN_PLAY_BTN')}</>
                        </GameButtonBuilder>
                    )}
                    <GameButtonBuilder
                        item={item}
                        mode={0}
                        customClasses="game__real-button"
                        needButton={true}
                    >
                        <>{trans('REAL_PLAY_BTN')}</>
                    </GameButtonBuilder>
                </>
            )}
        </>
    };

    const Statuses = () => (
        <div className="games__item-statuses">
            <div className="games__item-statuses-part">
                {item.jackpotId ? (providerItem && providerItem?.getJackpotGroup(item.jackpotId)?.imgLink) ? (
                        <img
                            className="game__item-jackpot-logo"
                            src={providerItem && providerItem?.getJackpotGroup(item.jackpotId)?.imgLink}
                            alt="slots-game"
                        />
                    ) : (
                        <div className="game__provider-without-img">
                            {providerItem && providerItem?.getJackpotGroup(item.jackpotId)?.name}
                        </div>
                    )
                    : !!item.isPromoted ?
                        <div className="games__item-status-icon games__item-status-icon--promoted">
                            <i className="doruk-fire"/>
                        </div>
                        : !!item.isPopular ? <div className="games-icon--exclusive-wrp">
                                <div className="games-icon--exclusive-label"
                                     data-content={trans('Exclusive')}
                                />
                            </div>
                            : null
                }
            </div>
            <div className="games__item-statuses-part">
                {item.jackpotId ?
                    !!item.isPromoted ?
                        <div className="games__item-status-icon games__item-status-icon--promoted">
                            <i className="doruk-fire"/>
                        </div>
                        : !!item.isPopular ? <div className="games-icon--exclusive-wrp">
                                <div className="games-icon--exclusive-label"
                                     data-content={trans('Exclusive')}
                                />
                            </div>
                            : null
                    : null
                }
                {isLogged &&
                    <div
                        className={`games__item-status-icon games__item-status-icon--favorite${!!isFavoriteGame ? ' filled' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleFavoriteToggle();
                        }}
                    >
                        <i className={`doruk-star-${!!isFavoriteGame ? 'fill' : 'line'}`}/>
                    </div>
                }
            </div>
        </div>
    );


    /************************* RENDER **************************/
    return (
        <div
            className="game__item-container"
            key={item.gameName}
            data-provider-id={item.providerId}
            data-game-id={item.gameId}
            data-last-launch={item.lastLaunchTime}
        >
            {loading ? (
                <div className="game__item-container--loading">
                    <LLPreloader className="game__loader" height={20} width={20}/>
                </div>
            ) : viewSize === 'desktop' || viewSize === 'tablet' ? (
                <>
                    <div
                        className="game__wrapper"
                        style={{backgroundImage: itemHasImg ? `url(${imgUrl})` : '',}}
                    >
                        <Statuses/>
                        {!itemHasImg && (
                            <>
                                <div className="games__item--without-image">
                                    {providerItem && providerItem.hasBigImage ? (
                                        <img
                                            className="game__item-logo"
                                            src={providerItem.bigImgLink}
                                            alt="slots-game"
                                        />
                                    ) : (
                                        <div className="game__provider-without-img">
                                            {trans(item.brandName)}
                                        </div>
                                    )}
                                </div>
                                <div className="game__game-name">
                                    <p className="game__game-name-text">{item.gameName}</p>
                                </div>
                            </>
                        )}
                    </div>

                    {viewSize === 'tablet' ?
                        <GameButtonBuilder
                            item={item}
                            mode={mode}
                            customClasses="game__item-popup"
                        >
                            {renderPopupInner()}
                        </GameButtonBuilder>
                        :
                        <div className="game__item-popup">
                            {renderPopupInner()}
                        </div>
                    }
                </>
            ) : (
                <GameButtonBuilder
                    item={item}
                    mode={mode}
                    customClasses="game__wrapper"
                    style={{
                        backgroundImage: itemHasImg
                            ? `url(${imgUrl})`
                            : '',
                    }}
                >
                    <Statuses/>
                    <>
                        {!itemHasImg && (
                            <div className="games__item--without-image">
                                {providerItem && providerItem.hasBigImage ? (
                                    <img
                                        className="game__item-logo"
                                        src={providerItem.bigImgLink}
                                        alt="slots-game"
                                    />
                                ) : (
                                    <div className="game__provider-without-img">
                                        {trans(item.brandName)}
                                    </div>
                                )}
                                <p className="game__game-name">
                                    {item.gameName}
                                </p>
                            </div>
                        )}
                    </>
                </GameButtonBuilder>
            )}
        </div>
    );
});

export default GameItem;
