import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { trans } from '../../../../common/utils';

const LivePageViewPicker = ({ store }) => {
    const setCalendar = store.betting.liveMatches.setCalendar,
        isCalendar = store.betting.liveMatches.isCalendar;

    const [activeButton, setActiveButton] = useState(0);

    useEffect(() => setActiveButton(isCalendar ? 1 : 0), [isCalendar]);

    const viewButtons = [
        {
            name: 'Live',
            icon: 'llg-list-alt',
        },
        {
            name: 'Calendar',
            icon: 'llg-calendar1',
        },
    ];

    return (
        <div className="livematches__view-buttons">
            {viewButtons.map((button, i) => (
                <div
                    key={i}
                    className={`livematches__view-button${
                        activeButton === i ? '--active' : ''
                    }`}
                    onClick={() => {
                        button.name === 'Calendar'
                            ? setCalendar(true)
                            : setCalendar(false);
                    }}
                >
                    <i className={`livematches__view-button-icon ${button.icon}`} />
                    <span className="livematches__view-button-text">
                        {trans(button.name)}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default inject('store')(observer(LivePageViewPicker));
