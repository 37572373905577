import {values} from 'mobx';
import {types} from 'mobx-state-tree';
import orderBy from 'lodash/orderBy';

import {trans} from '../../../../../common/utils';
import {mergeArraysWithoutDuplicates} from "../../../../../common/utils/arraysUtils";


const BetHistoryMatchItem = types.model('BetHistoryMatchItem', {
    id: types.identifier,
    rowChars: types.string,
    name: types.string,
    oddsTypeSelection: types.string,
    oddsType: types.string,
    date: types.Date,
    odds: types.number,
    customOdds: types.maybeNull(types.number),
    score: types.string,
    timeInfo: types.string,
    stake: types.maybeNull(types.number),
    paid: types.maybeNull(types.number),
    payout: types.maybeNull(types.number),
    type: types.enumeration(['PreMatch', 'OutRight', 'Outright', 'LiveMatch']),
    sportName: types.maybeNull(types.string),
    sportId: types.maybeNull(types.number),
    status: types.maybeNull(types.enumeration(['lost', 'won', 'returned'])),
    url: types.maybeNull(types.string),
    currentScore: types.maybeNull(types.string),
    currentStatus: types.maybeNull(types.string),
    className: types.maybeNull(types.string),
    isLive: false,
}).actions((self) => ({
    update(newMatchItem) {
        Object.entries(newMatchItem)
            .map((entry) => self[entry[0]] = entry[1]);
    },
    setAdditionalMatchParams({url, currentScore, currentStatus}) {
        self.url = url;
        if (self.currentScore !== currentScore) {
            self.oldScore = self.currentScore;
            self.currentScore = currentScore;
            self.className = 'blink-effect';
            setTimeout(() => self.setClassName(null), 3000);
        }
        if (self.currentStatus !== currentStatus) {
            self.oldStatus = self.currentStatus;
            self.currentStatus = currentStatus;
            if (!self.className) {
                self.className = 'blink-effect';
                setTimeout(() => self.setClassName(null), 3000);
            }
        }
    },
    setClassName(className) {
        self.className = className;
    },
})).views((self) => ({
    get matchId() {
        return self.customOdds > 0 ?
            self.id.split('_')[0] :
            self.id;
    },
}));

const BetHistoryMatchGroupItem = types.model('BetHistoryMatchGroupItem', {
    key: types.identifier,
    stake: types.number,
    paid: types.number,
    payout: types.number,
    status: types.maybeNull(types.enumeration(['won', 'lost', 'void'])),
    matches: types.optional(types.map(BetHistoryMatchItem), {}),
}).actions((self) => ({
    update(newMatchGroupItem, newMatchItem) {
        Object.entries(newMatchGroupItem)
            .map((entry) => self[entry[0]] = entry[1]);

        if (self.matches.has(newMatchItem.id)) {
            self.matches.get(newMatchItem.id).update(newMatchItem);
        } else {
            self.matches.put(newMatchItem);
        }
    },
})).views((self) => ({
    get sortedMatches() {
        return orderBy(values(self.matches), ['date'], ['asc']);
    },
}));

const BetHistoryItem = types
    .model('BetHistoryItem', {
        id: types.identifierNumber,
        dateTime: types.maybeNull(types.Date),
        stake: types.number,
        payout: types.number,
        paid: types.maybeNull(types.number),
        matchTotal: types.number,
        couponTotal: types.number,
        matchType: types.string,
        isAllowedToDeleteFromBack: types.boolean,
        status: types.maybeNull(types.string),
        statusEn: types.maybeNull(types.string),
        isCanBeDeleted: types.maybeNull(types.boolean),
        isOld: false,
        isLoading: false,
        totalOdds: types.maybeNull(types.number),
        cashout: types.maybeNull(types.number),
        cashoutLoding: false,
        matchGroups: types.map(BetHistoryMatchGroupItem),
        fetchTime: types.maybeNull(types.number),
        isOpened: false,
        betsDetailsError: types.maybeNull(types.string),
        isDeleting: false,
        wasCashouted: false,
    })
    .volatile((self) => ({
        matchesAdditionalProps: {},
    }))
    .actions((self) => ({
        update(data) {
            Object.entries(data)
                .map((entry) => self[entry[0]] = entry[1])
        },
        setCashouted(flag) {
            self.wasCashouted = flag;
        },
        setIsDeleting(flag) {
            self.isDeleting = flag;
        },
        setDetails(data) {
            data.map((match, i) => {
                const newMatchItem = {
                    id: match.CustomOdds > 0 ? (match.MatchID + '_' + i) : match.MatchID.toString(),
                    rowChars: match.RowChars,
                    name: match.Match,
                    oddsTypeSelection: match.OddsTypeSelection.toString(),
                    oddsType: match.OddsType,
                    date: new Date(match.MatchDate.replace(' ', 'T') + '+03:00'),
                    odds: match.Odds,
                    customOdds: match.CustomOdds,
                    score: match.MatchScore,
                    timeInfo: match.MatchTime,
                    type: match.MatchType,
                    sportName: match.SportName,
                    sportId: match.SportID,
                    status: self.setMatchStatus(match),
                    stake: match.Stake,
                    paid: match.Paid,
                    payout: match.MaxPayout,
                    currentScore: match.ActualMatchScore,
                    currentStatus: match.ActualShortStatus,
                    isLive: !!match.IsCurrentlyLive,
                };

                if (self.matchesAdditionalProps[match.MatchID]) {
                    newMatchItem.url = self.matchesAdditionalProps[match.MatchID].url;
                    newMatchItem.currentScore = self.matchesAdditionalProps[match.MatchID].score;
                    newMatchItem.currentStatus = self.matchesAdditionalProps[match.MatchID].status;
                }

                const newMatchGroupItem = {
                    key: match.RowChars,
                    stake: match.Stake,
                    paid: match.Paid,
                    payout: match.MaxPayout,
                };

                function getMatchGroupStatus(matchData, currentMatchGroup) {
                    const isGroupReturned = currentMatchGroup ?
                        values(currentMatchGroup.matches).every(match => match.odds === 1) && matchData.Odds === 1
                        : matchData.Odds === 1;

                    if (matchData.isSubWon && isGroupReturned) return 'void';
                    if (matchData.isSubWon) return 'won';
                    if (matchData.isSubLost) return 'lost';
                }

                const currentMatchGroup =  self.matchGroups.get(match.RowChars);
                if (currentMatchGroup) {
                    if (newMatchItem.customOdds > 0) {
                        currentMatchGroup.matches.put(newMatchItem);
                    } else {
                        newMatchGroupItem.status = getMatchGroupStatus(match, currentMatchGroup);
                        currentMatchGroup.update(
                            newMatchGroupItem,
                            newMatchItem,
                        );
                    }
                } else {
                    newMatchGroupItem.status = getMatchGroupStatus(match);
                    self.matchGroups.put({
                        ...newMatchGroupItem,
                    });
                    self.matchGroups.get(newMatchGroupItem.key).matches.put(newMatchItem);
                }
            });
            self.fetchTime = Date.now();
        },
        setStatus(betData) {
            if (betData.isDeleted === 1) {
                if (betData.isCashout === 1) {
                    self.statusEn = 'cashouted';
                    self.status = trans('Cashouted');
                } else {
                    self.statusEn = 'deleted';
                    self.status = trans('Deleted');
                }
            } else if (betData.CouponWaiting > 0) {
                self.statusEn = 'waiting';
                self.status = trans('Waiting');
            } else {
                if (betData.CouponLost > 0) {
                    self.statusEn = 'lost';
                    self.status = trans('Lost');
                }
                if (betData.CouponWon > 0) {
                    self.statusEn = 'won';
                    self.status = trans('Won');
                }
            }
        },
        setMatchesAdditionalProps({url, matchId, score, status}) {
            self.matchesAdditionalProps[matchId] = {url, score, status};
            values(self.matchGroups).map((group) => {
                const match = group.matches.get(matchId);
                if (match) {
                    match.setAdditionalMatchParams({url, currentScore: score, currentStatus: status})
                }
            });
        },
        setBetsDetailsError(error) {
            self.betsDetailsError = error;
        },
        setCanBeDeleted() {
            self.isCanBeDeleted =
                self.statusEn === 'waiting'
                && ['PreMatch', 'OutRight'].includes(self.matchType)
                && self.isAllowedToDeleteFromBack;
        },
        setOld(flag) {
            self.isOld = flag;
        },
        setMatchStatus(match) {
            if (match.isReturn === 1) return 'returned';
            if (match.isLost === 1) return 'lost';
            if (match.isWon === 1) return 'won';
        },
        setLoading(flag) {
            self.isLoading = flag;
        },
        setCashoutLoading(flag) {
            self.cashoutLoding = flag;
        },
        setCashout(val) {
            self.cashout = val;
        },
        setOpened(flag) {
            self.isOpened = flag;
        }
    }))
    .views((self) => ({
        get betDetailsGroups() {
            return values(self.matchGroups);
        },
        get betDetailsUniqueMatches() {
            const arraysIntersection = (array1, array2) => array1.filter(elem1 => {
                return !!array2.filter(elem2 => elem2.id === elem1.id).length;
            })
            let uniqueMatches = [];
            const uniqueKeys = values(self.matchGroups).reduce((acc, group) => {
                const tempKeyArr = group.key.split(',');
                return mergeArraysWithoutDuplicates(acc, tempKeyArr);
            }, []);
            uniqueKeys.map(key => {
                const keyGroups = values(self.matchGroups).filter(group => {
                    return group.key.includes(key);
                });
                let uniqueMatch = null;
                let prevKeyMatches = [];
                keyGroups.map((keyGroup, i) => {
                    if (i > 0) {
                        uniqueMatch = arraysIntersection(uniqueMatch, keyGroup.sortedMatches);
                    } else {
                        uniqueMatch = keyGroup.sortedMatches;
                    }
                });
                if (uniqueMatch.length) {
                    uniqueMatches = [
                        ...uniqueMatches,
                        { ...uniqueMatch[0], key }
                    ];
                }
            })
            return uniqueMatches;
        },
        get betDetailsMatches() {
            let result = [];
            values(self.matchGroups).map((group) => {
                result = [...result, ...group.sortedMatches];
            });
            return result;
        },
        get isBetHasLiveMatch() {
            return !!self.betDetailsMatches.filter(match =>
                match.currentScore || match.currentStatus
            ).length;
        }
    }));

export default BetHistoryItem;