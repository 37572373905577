import { llNotifier } from '../../../components';
import {errorLogger} from "../index";

/***************** ~~ Error handle hoc ***************
 * @desc - Reports about errors to rollbar
 * @param {func} attempt - func to attempt for errors
 * @param {string} msg - error message
 * @param {string} msgType - error type (for notifier)
 * @param {boolean} withTranslation - flag to translate or not msg (for notifier)
 * @param {boolean} withNotifier - flag for showing notifier
 * @param {object} withParams - error data params object
 * @param {func} onError - func what need's to be executed on error
 *
 * @author S.Nakhodov
 */

const llattempt = (
    attempt,
    {
        msg = 'Error',
        msgType = 'error',
        withTranslation = true,
        withNotifier = true,
        withParams = {},
        onError = () => {},
    } = {}
) => {
    /**** ~~ Handle error ****/
    return (async () => {
        try {
            return await attempt();
        } catch (error) {
            errorLogger({errorObj: {error: msg, params: withParams}});

            withNotifier &&
                llNotifier({
                    message: msg,
                    type: msgType,
                    withTranslation: withTranslation,
                });
            console.error(error);
            onError();
            return { error };
        }
    })();
};

export default llattempt;
