import { getRoot, flow } from 'mobx-state-tree';

import api from '../../../../../common/api';
import { llattempt } from '../../../../../common/utils';
import { branchConfig } from '../../config';

/*
######## ######## ########  ######  ##     ## ########  ######
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ##          ##    ##       ##     ## ##       ##
######   ######      ##    ##       ######### ######    ######
##       ##          ##    ##       ##     ## ##             ##
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ########    ##     ######  ##     ## ########  ######
*/

const fetchCommands = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                           fetch data for single tournaments                          ##
    // ##                                                                                      ##
    // ##========================================================================================

    fetchAndConvertDataForSingle: flow(function* fetch({ tournamentId }) {
        const params = {
            to: branchConfig[getRoot(s).betting.branchId].to(),
            tournamentId: tournamentId,
        };

        return yield llattempt(() => api.betting.getMatches(params), {
            withNotifier: false,
            at: 'fetchAndConvertDataForSingle',
            withParams: params,
        }) || {};
    }),

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                  Fetch data for all tournaments at specific category                 ##
    // ##                                                                                      ##
    // ##========================================================================================

    fetchAndConvertDataForAll: flow(function* fetch({ categoryId }) {
        const params = {
            to: branchConfig[getRoot(s).betting.branchId].to(),
            categoryId: categoryId,
        };

        return yield llattempt(() => api.betting.getCategoryMatches(params), {
            withNotifier: false,
            at: 'fetchAndConvertDataForAll',
            withParams: params,
        }) || {};
    }),

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                            Fetch data for top tournaments                            ##
    // ##                                                                                      ##
    // ##========================================================================================

    fetchAndConvertDataForTopAndFavorite: flow(function* fetch() {
        const params = { to: branchConfig[getRoot(s).betting.branchId].to() };

        const topMatches = llattempt(() => api.betting.getTopMatches(params), {
            withNotifier: false,
            at: 'fetchAndConvertDataForTopAndFavorite',
            withParams: params,
        });

        const favoriteMatches = llattempt(() => api.betting.getFavortieMatches(params), {
            withNotifier: false,
            at: 'fetchAndConvertDataForTopAndFavorite',
            withParams: params,
        });

        return yield Promise.all([topMatches, favoriteMatches])
            .then(([topMatches, favoriteMatches]) => {
                return {
                    topMatches: topMatches.data,
                    favoriteMatches: favoriteMatches.data,
                    success: !!(topMatches?.success && favoriteMatches?.success),
                };
            })
            .catch((res) => console.log(res));
    }),

    fetchAndConvertDataForSingleMatch: flow(function* fetch({ matchId }) {
        const params = { matchId };

        return yield llattempt(() => api.betting.getMatch(params), {
            withNotifier: false,
            at: 'fetchAndConvertDataForSingleMatch',
            withParams: params,
        }) || {};
    }),
});

export default fetchCommands;
