import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {trans} from '../../../../common/utils';
import {LLScrollSlider} from '../../../../components';
import VaixWidgetItem from './components/widget-item';


const VAIXWidget = ({store}) => {
    const
        vaixWidget = store.widgets.vaixWidget,
        isVaixEnabled = store.site.isVaixEnabled,
        slides = store.widgets.vaixWidget.widgetsList,
        isError = store.widgets.vaixWidget.isError,
        language = store.user.language,
        isLogged = store.user.isLogged,
        user = store.user,
        couponsFetching = store.user.coupons.fetching,
        collectActivity = store.user.collectActivity,
        viewSize = store.site.status.viewSize,
        isLandscapeOrientation = store.site.status.isLandscapeOrientation,
        getRecommended = store.widgets.initializeInstruction,
        setActiveState = store.widgets.vaixWidget.setActiveState;

    useEffect(() => {
        setActiveState(true);
        isVaixEnabled && getRecommended({type: 'vaix'});
    }, [isLogged]);

    useEffect(() => () => {
        setActiveState(false);
    }, []);

    return (isError || !isVaixEnabled) ? null : (
        <div className={cx(
            'vaix-widget',
            `vaix-widget--${viewSize}`
        )}>
            <div className="vaix-widget__title">
                <span>{trans(isLogged ? 'YOUR_POPULAR_ACCUMULATORS' : 'POPULAR_ACCUMULATORS')}</span>
            </div>
            <LLScrollSlider
                viewSize={viewSize}
                isLandscapeOrientation={isLandscapeOrientation}
            >
                { slides.map((slide, i) =>
                    <VaixWidgetItem {...{
                        slide,
                        language,
                        collectActivity,
                        user,
                        couponsFetching,
                        betInSlideCount: vaixWidget.betInSlideCount,
                    }} key={slide.key} />
                )}
            </LLScrollSlider>
        </div>);
}

export default inject('store')(observer(VAIXWidget));