import { flow, types as t } from 'mobx-state-tree';
import { values } from 'mobx';

import {llattempt, urlCleaner} from '../../../common/utils';
import api from '../../../common/api';

import {TIMERS} from '../../config';


const MiniBanner = t.model('MiniBanner', {
    id: t.identifierNumber,
    image: t.maybeNull(t.string),
    name: t.maybeNull(t.string),
    url: t.maybeNull(t.string),
    description: t.maybeNull(t.string),
});

const MiniBanners = t
    .model('MiniBanners', {
        lastFetchTime: t.maybeNull(t.Date),
        isFetching: true,
        banners: t.array(MiniBanner),
    })
    .actions((s) => {
        return {
            _set(data) {
                s.banners = data.map(item => {
                    item.image = item.image ? item.image.toString() : '';
                    item.name = item.name ? item.name.toString() : '';
                    item.url = urlCleaner(item.url);
                    item.description = item.description ? item.description.toString() : '';
                    return item;
                });
                s.lastFetchTime = Date.now();
                s._setFetching(false);
            },
            _setFetching(status) {
                s.isFetching = status;
            },

            getMiniBanners: flow(function* fetch() {
                if (
                    !s.lastFetchTime ||
                    Date.now() > +new Date(+s.lastFetchTime + TIMERS.miniBannersFetchTimer)
                ) {
                    s._setFetching(true);
                    yield llattempt(
                        () =>
                            api.config.getMiniBanners().then((response) => {
                                if (response.success) {
                                    s._set(response.data);
                                } else {
                                    s._setFetching(false);
                                    // наверно можно на ошибке с баннерами ничего не делать
                                }
                            }),
                        {
                            withNotifier: false,
                            at: 'SiteConfig.getMiniBanners',
                            withParams: {},
                            onError: () => {
                                s._setFetching(false);
                                // наверно можно на ошибке с баннерами ничего не делать
                            },
                        }
                    );
                }
            }),
        };
    })
    .views((self) => {
        return {
            get list() {
                return values(self.banners);
            },
        };
    });

export default MiniBanners;
