import React from 'react';
import cx from 'classnames';

/**
 * @file Represents progress bar component
 *
 * @memberof components
 *
 * @param {Object | string[] | string} customClasses - build classes
 * @param {string | number} value - value of loader
 * @param {('interminate' | 'determinate')} mode - difine appearance of loadier
 *
 * @author S.TARNAVSKI - write this component ʕ•́ᴥ•̀ʔっ
 */

const LLProgressBar = (props) => {
    const { customClasses, value = null, mode = 'indeterminate' } = props;

    const renderDeterminate = () => {
        return (
            <div className={cx('ll-progress-bar--determinate', value === 100 ? 'full' : '', customClasses)}>
                <div
                    className="ll-progress-bar__value"
                    style={{
                        width: value + '%',
                        display: 'block',
                    }}
                />
            </div>
        );
    };

    const renderIndeterminate = () => {
        return (
            <div className={cx('ll-progress-bar--indeterminate', customClasses)}>
                <div className="ll-progress-bar__indeterminate-container">
                    <div className="ll-progress-bar__value" />
                </div>
            </div>
        );
    };

    const defineMode = () => {
        if (mode === 'determinate') {
            return renderDeterminate();
        }
        if (mode === 'indeterminate') {
            return renderIndeterminate();
        }
        return null;
    };

    return defineMode();
};

export default LLProgressBar;
