import React from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import LLBettingButton from './betting-button';


const LLSportBettingButtonWrapper = ({
                             store,
                             columnCount,
                             nameAppearance = true,
                             oddAppearance,
                             bet,
                             className,
                         }) => {
    const
        searchString = store.betting.branch.search.searchString,
        isCustomBetsView = store.betting.isCustomBetsView;

    const betName = bet.parentMatch?.matchName ?? bet.name;

    const generateName = () =>
        searchString ?
            betName?.replace(
                new RegExp(searchString, 'gi'),
                "<span class='event__search-highlite'>$&</span>"
            )
            :
            betName;

    return (
        <LLBettingButton
            columnCount={columnCount}
            oddAppearance={oddAppearance}
            nameAppearance={nameAppearance}
            betName={generateName()}
            bet={bet}
            isCustomBetsView={isCustomBetsView}
            className={className}
            customClasses={cx(
                {
                    [`${className}--custom-unavailable`]: !bet.available && !bet.isActive,
                    [`${className}--dn`]:
                        bet.blink === 'dn' && bet.outcomeValue > 1 && bet.betStatus,
                    [`${className}--up`]:
                        bet.blink === 'up' && bet.outcomeValue > 1 && bet.betStatus,
                }
            )}
        />
    );
};

export default inject('store')(observer(LLSportBettingButtonWrapper));