import React from 'react';

import { withCommon, ResetPasswordForm } from '../../components';

/**
 * @file Represents Reset Password page
 *
 * @param {function} history - router history
 * @param {boolean} mobileUserAgent - flag is mobile layout
 * @param {Object} user - user data
 * @param {boolean} user.isLogged - flag is user logged
 *
 * @author S.Hakhodov feat. A.Green
 */

const ResetPassword = withCommon({
    store: ({ store }) => ({
        viewSize: store.site.status.viewSize,
    }),
    isRouter: true,
    isObserver: true,
})(({ viewSize }) => {
    return (
        <div className={`ll-page reset-password--${viewSize}`}>
            <ResetPasswordForm />
        </div>
    );
});

export default ResetPassword;
