import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import CheckBox from 'devextreme-react/check-box';
import cx from "classnames";

import {LLBtn, LLPreloader, LLBtn2} from '../../../components';
import {formatNumber, trans} from "../../../common/utils";
import CouponsAmount from './coupons-amount';
import BetSystemPicker from './system-picker';
import CouponList from './coupon-list';


const CouponsWrapper = ({store, contentRef}) => {
    const
        acceptChanges = store.user.acceptChanges,
        coupons = store.user.coupons,
        isSingleOnly = store.user.coupons.isSingleOnly,
        checkOkStatus = store.user.coupons.checkOkStatus,
        isCustom = store.user.coupons.isCustom,
        language = store.user.language,
        currency = store.user.currency,
        fetching = store.user.coupons.fetching,
        list = store.user.coupons.list,
        modal = store.modal,
        rate = store.user.coupons.rate,
        payout = store.user.coupons.payout,
        payoutfetching = store.user.coupons.payoutfetching,
        betAmount = store.user.coupons.betAmount,
        totalCouponsAmount = store.user.coupons.totalCouponsAmount,
        totalCouponsPayout = store.user.coupons.totalCouponsPayout,
        maximumStake = store.user.coupons.maximumStake,
        setBetAmount = store.user.coupons.setBetAmount,
        isMaxStakeExceeded = store.user.coupons.isMaxStakeExceeded,
        toggleAcceptChanges = store.user.toggleAcceptChanges;

    const
        [isCouponOk, setCouponOk] = useState(true),
        [activeCouponListTab, setActiveCouponListTab] = useState('single'),
        [isSystemNull, setSystemNull] = useState(false),
        [isMounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        }
    }, []);

    const isMultiSingle = activeCouponListTab === 'single' && list.length > 1 && !isCustom;

    useEffect(() => {
        if (isMounted) {
            setCouponOk(checkOkStatus(isMultiSingle));
        }
    }, [checkOkStatus(isMultiSingle), activeCouponListTab, list.length]);

    const checkNullableFormInputs = () => {
        const elements = contentRef?.current.elements && contentRef?.current.elements["coupon-amount-input"];
        const check = (input) => {
            if (+input.value === 0 && (!isMultiSingle || !input.id)) {
                input.closest('.coupons__amount-wrapper').classList.add('amount-wrapper--error')
            }
        };
        if (elements?.length) {
            elements.forEach(elem => check(elem))
        } else if (!!elements) {
            check(elements);
        }
    };

    const placeBet = () => {
        if (checkOkStatus(isMultiSingle)) {
            modal.setModal({
                active: true,
                currentKey: 'custom-content',
                closeOnOuterClick: false,
                customContent: <div/>,
                customClasses: 'empty-modal__backdrop',
                showHeader: false,
            });
            const request = isMultiSingle ? coupons.saveSingle() : coupons.save();
            request.then((response) => {
                if (response.success) {
                    modal.setModal({
                        active: true,
                        maskBackground: true,
                        showHeader: true,
                        currentKey: 'success-bet-modal',
                        title: trans('Info'),
                        customClasses: 'll-modal--success-bet-modal',
                        showCloseButton: true,
                    });
                } else if (response) {
                    modal.setModal({
                        active: true,
                        maskBackground: true,
                        showHeader: true,
                        currentKey: 'custom-content',
                        title: trans('Error placing bet'),
                        customContent: <div
                            dangerouslySetInnerHTML={{
                                __html: coupons.betErrorHandler(
                                    response,
                                    trans(response?.data?.error)
                                ),
                            }}
                        />,
                    });
                }
            });
        } else {
            checkNullableFormInputs();
            setCouponOk(false);
            coupons.selectedSystems?.length === 0 && setSystemNull(true);
        }
    };

    return (
        <form className="coupons__wrapper" ref={contentRef}
              onSubmit={(e) => {
                  e.preventDefault();
              }}
        >
            <CouponList
                list={list}
                isCustom={isCustom}
                activeTab={activeCouponListTab}
                setActiveTab={setActiveCouponListTab}
                isSingleOnly={!isMultiSingle && isSingleOnly}
            />
            <div className="coupons__settings">
                <CouponsAmount
                    id={`coupons__amount-input-field${Date.now()}`}
                    betAmount={betAmount}
                    maximumStake={maximumStake}
                    setBetAmount={setBetAmount}
                    isMaxStakeExceeded={(isMaxStakeExceeded && activeCouponListTab !== 'single')}
                    withPresets={true}
                    customLabel={isMultiSingle ? 'AMOUNT_PER_BET' : null}
                    isMultiSingle={isMultiSingle}
                    isMainAmount={true}
                />

                {(!isCustom && activeCouponListTab === 'system') && (
                    <BetSystemPicker
                        isSystemNull={isSystemNull}
                        setSystemNull={setSystemNull}
                    />
                )}
                {(isCustom && (list.length < 2)) ?
                    <p className="coupons__custom-warning">
                        {trans('You need to pick at least one more selection to place your bet via Bet Builder')}
                    </p>
                    : null}
                {isMultiSingle ?
                    <div className="coupons__amount-row--total">
                        <div className="coupons__total-key">{`${trans('TOTAL_AMOUNT')}:`}</div>
                        <div className="coupons__total-value">
                            {formatNumber(totalCouponsAmount, 2, language)}
                        </div>
                    </div>
                    : null
                }
                {(!isMultiSingle && !isCustom) ? <div className="coupons__total-row--total">
                    <div className="coupons__total-key">{`${trans('TotalOdds')}:`}</div>
                    <div className="coupons__total-value">
                        {formatNumber(rate, 2, language)}
                    </div>
                </div> : null}
                {(!isCustom || list.length > 1) ? <div className="coupons__amount-row--total">
                    <div className="coupons__amount-key">{`${trans('Possible payout')}:`}</div>
                    <div className="coupons__amount-value">
                        {payoutfetching ? (
                            <LLPreloader
                                height={10}
                                width={10}
                            />
                        ) : (
                            isMultiSingle ? formatNumber(totalCouponsPayout, 2, language, currency) : payout
                        )}
                    </div>
                </div> : null}
                {!isCustom ? <div className="coupons__checkbox">
                    <CheckBox
                        value={acceptChanges}
                        onValueChanged={toggleAcceptChanges}
                        text={trans('I accept odds changes')}
                    />
                </div> : null}

                <div className="coupons__submit-container">
                    <div
                        className={
                            'coupons__submit-wrp ' +
                            (!isCouponOk
                                ? 'coupons__submit-wrp--error'
                                : '')
                        }
                    >
                        <LLBtn
                            disabled={
                                !isCouponOk
                                || (isMaxStakeExceeded && activeCouponListTab !== 'single')
                                || (isCustom && (list.length < 2))
                            }
                            customClasses={cx(
                                'coupons__accept-btn',
                                'll-btn--accent'
                            )}
                            onClick={placeBet}
                        >
                            {trans('Place bet')}
                        </LLBtn>
                    </div>
                    <LLBtn2
                        customClasses={cx('coupons__detete-btn', 'll-btn--outlined')}
                        type="button"
                        onClick={coupons.clear}
                        disabled={fetching}
                    >
                        <i className="doruk-delete-01"/>
                    </LLBtn2>
                </div>
            </div>
        </form>
    );
};

export default inject('store')(observer(CouponsWrapper));