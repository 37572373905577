import {types, flow, getRoot} from 'mobx-state-tree';
import {values} from 'mobx';
import orderBy from 'lodash/orderBy';

import {llNotifier} from '../../../components/elements';
import {getS3ImageUrl, imageLoading, llattempt, trans} from '../../../common/utils';
import {TIMERS} from '../../config';
import api from '../../../common/api';
import urls from '../../../configs/urls';


let prizeCollectingInterval;

const Provider = types.model('Provider', {
    name: types.identifier,
    type: types.string,
    bigImgLink: types.maybeNull(types.string),
    imgLink: types.maybeNull(types.string),
    hasBigImage: false,
    hasImage: false,
    link: types.maybeNull(types.string),
    order: types.number,
});

const BattleItemPlayer = types.model('BattleItemPlayer', {
    CustomerID: types.identifierNumber,
    Customer: types.string,
    IsPlayed: types.boolean,
    InPlay: types.boolean,
    BetAmount: types.maybeNull(types.number),
    Prize: types.maybeNull(types.number),
    result: types.maybeNull(types.number),
}).actions((self) => ({
    setResult(val) {
        self.result = val;
    },
    setPrize(val) {
        self.Prize = val;
    },
    setName(name) {
        self.Customer = name;
    }
}));

const BattleItem = types.model('BattleItem', {
    RoomUUID: types.identifier,
    room_status: types.string,
    IsPrivate: types.boolean,
    idt: types.string,
    sdt: types.maybeNull(types.string),
    BrandName: types.maybeNull(types.string),
    ProviderID: types.maybeNull(types.number),
    GameID: types.maybeNull(types.number),
    GameName: types.maybeNull(types.string),
    thumbnail: types.maybeNull(types.string),
    Bet: types.number,
    PlayersQty: types.number,
    ConnectedPlayersQty: types.number,
    PlayedPlayersQty: types.number,
    owner: types.maybeNull(types.string),
    icon: types.maybeNull(types.string),
    playersForView: types.map(BattleItemPlayer),
    hideBattleCounter: false,
    prizeCollectingTimer: types.maybeNull(types.number),
    battleBecomeCompleted: false,
}).volatile((s) => ({
    players: [],
})).actions((self) => ({
    setHideBattleCounter(flag) {
        self.hideBattleCounter = flag;
    },
    updateBattle(data) {
        self.battleBecomeCompleted = (data.room_status === 'completed');
        self.room_status = data.room_status;
        self.sdt = data.sdt;
        self.Bet = data.Bet;
        self.ConnectedPlayersQty = data.ConnectedPlayersQty;
        self.PlayedPlayersQty = data.PlayedPlayersQty;
        self.players = data.players.reduce((acc, item) => {
            if (!item.result) item.result = 0;
            return [...acc, item]
        }, []);
        if (self.battleBecomeCompleted) {
            self.startPrizeCollectingTimer();
            data.players = orderBy(data.players, 'result', 'desc');
            self.playersForView.clear();
            data.players.slice(5).map(player => {
                const currentPlayer = self.playersForView.get(player.CustomerID);
                if (currentPlayer) {
                    currentPlayer.IsPlayed = !!player.IsPlayed;
                    currentPlayer.InPlay = !!player.InPlay;
                    currentPlayer.Customer = '---';
                    currentPlayer.BetAmount = player.BetAmount;
                    currentPlayer.Prize = player.Prize || 0;
                    currentPlayer.result = player.result || 0;
                    currentPlayer.isPrizeCollected = true;
                }
            })
            orderBy(data.players.slice(0, 5), 'result', 'desc').map((player, i) => {
                setTimeout(() => {
                    self.setPlayersForViewItem({
                        CustomerID: player.CustomerID,
                        Customer: '---',
                        IsPlayed: !!player.IsPlayed,
                        InPlay: !!player.InPlay,
                        BetAmount: player.BetAmount,
                        Prize: 0,
                        result: 0,
                    })
                }, (i + 2) * 300);
            })
        } else {
            const user = getRoot(self).user;
            data.players.map(player => {
                if ((player.Customer === user.username && player.IsPlayed) && data.players.find(player => !player.IsPlayed)) {
                    user.battlePopup.setWhatWasChanged("userIsPlayed");
                    self.hideBattleCounter = false;
                }
                const currentPlayer = self.playersForView.get(player.CustomerID);
                if (currentPlayer) {
                    currentPlayer.IsPlayed = !!player.IsPlayed;
                    currentPlayer.InPlay = !!player.InPlay;
                    currentPlayer.BetAmount = player.BetAmount;
                    currentPlayer.Prize = 0;
                    currentPlayer.result = 0;
                } else {
                    self.setPlayersForViewItem({
                        CustomerID: player.CustomerID,
                        Customer: player.Customer,
                        IsPlayed: !!player.IsPlayed,
                        InPlay: !!player.InPlay,
                        BetAmount: player.BetAmount,
                        Prize: 0,
                        result: 0,
                    })
                }
            })
        }
    },
    setPlayers(players) {
        self.players = players;
    },
    setPlayersForViewItem(player) {
        self.playersForView.put({
            CustomerID: player.CustomerID,
            Customer: player.Customer,
            IsPlayed: !!player.IsPlayed,
            InPlay: !!player.InPlay,
            BetAmount: player.BetAmount,
            Prize: 0,
            result: 0,
        })
    },
    setPlayersRealName() {
        self.players.map(player => {
            self.playersForView.get(player.CustomerID).setName(player.Customer)
        })
    },
    setActiveBattlePlayersPrize(playerIndex) {
        const player = self.playersList[playerIndex];
        const playerScore = self.players.find(item => item.CustomerID === player.CustomerID)?.result;

        const setNewResult = (player, prize, result) => setTimeout(() => {
            let delta = playerScore / 90;
            player.setResult(result + delta);
            self.winner.setPrize(prize + delta);
            if (result <= (playerScore - delta)) {
                setNewResult(player, prize + delta, result + delta);
            } else {
                player.setResult(playerScore);
                if (playerIndex > 0) {
                    self.setActiveBattlePlayersPrize(playerIndex - 1);
                } else {
                    self.winner.setPrize(self.players.find(item => item.CustomerID === self.winner.CustomerID)?.Prize);
                    setTimeout(() => self.setPlayersRealName(), 1000);
                }
            }
        }, 50);

        setNewResult(player, self.winner.Prize, player.result);
    },
    setPrizeCollectingTimer(val) {
        self.prizeCollectingTimer = val;
    },
    startPrizeCollectingTimer() {
        self.prizeCollectingTimer = 5;
        prizeCollectingInterval = setInterval(() => {
            if (self.prizeCollectingTimer > 1) {
                self.setPrizeCollectingTimer(self.prizeCollectingTimer - 1);
            } else {
                self.setPrizeCollectingTimer(0);
                clearInterval(prizeCollectingInterval);
                self.setActiveBattlePlayersPrize(self.players.length > 5 ? 4 : self.players.length - 1);
            }
        }, 1000);
    },
    setIcon(url) {
        self.icon = url;
    },
})).views((self) => ({
    get playersList() {
        if (self.room_status === 'completed') {
            return orderBy(self.players, 'result', 'desc').reduce((acc, item) => {
                const player = self.playersForView.get(item.CustomerID);
                if (player) {
                    return [
                        ...acc,
                        {
                            ...player,
                            setResult: player.setResult,
                        }
                    ]
                } else {
                    return acc;
                }
            }, []);
        } else {
            return values(self.playersForView).reduce((acc, player) => [
                ...acc,
                player
            ], []);
        }
    },
    get winner() {
        const playerId = self.players.find(player => !!player.Prize && player)?.CustomerID;
        return self.playersForView.get(playerId);
    },
}));

const SlotBattlesBannerItem = types
    .model('SlotBattlesBannerItem', {
        image: types.string,
        url: types.string,
        hasImg: false,
    }).actions((self) => ({
        checkImg: flow(function* fetch() {
            imageLoading(self.image).then((res) => {
                res && self.setHasImg(true);
            });
        }),
        setHasImg(flag) {
            self.hasImg = flag;
        },
    }))

const SlotBattlesBanners = types
    .model('SlotBattlesBanners', {
        mobile: types.maybeNull(SlotBattlesBannerItem),
        desktop: types.maybeNull(SlotBattlesBannerItem),
        fetchTime: types.maybeNull(types.number),
        loading: true,
    }).actions((self) => ({
        setLoading(flag) {
            self.loading = flag;
        },
        setFetchTime(time) {
            self.fetchTime = time;
        },
        clearBanners() {
            self.desktop = null;
            self.mobile = null;
        },
    })).views((self) => ({
        get banner() {
            return getRoot(self).site.status.viewSize === 'mobile' ? self.mobile : self.desktop;
        },
    }))

const SlotsBattles = types
    .model('SlotsBattles', {
        slotBattlesProvider: types.map(Provider),
        slotBattlesFetchTime: types.maybeNull(types.number),
        slotBattlesType: 'available', //  'available', 'started', 'completed', 'create'
        slotBattlesSearch: '',
        slotBattlesChosenProvider: '',
        slotBattlesChosenSortby: '',
        slotBattlesBattleInfoLoading: true,
        slotBattlesGlobalError: false,
        activeBattle: types.maybeNull(BattleItem),
        banners: types.optional(SlotBattlesBanners, {}),
        tutorialStep: 0,
        tutorialOnCloseGoTo: types.maybeNull(types.string),
        tutorialNeedsToBeShowed: false,
        activeBattleRoomsCount: types.maybeNull(types.number),
    })
    .actions((self) => {
        return {
            //INNER ACTIONS
            _addProvider: flow(function* fetch({item, url, type}) {
                const newObj = {};

                newObj.name = item.BrandName;
                newObj.type = 'provider';
                newObj.link = `/${url}/provider=${item.BrandName}`;
                newObj.order = item.BrandOrder || item.SortOrder;

                newObj.bigImgLink = `${getS3ImageUrl()}/images/brands/logo-big/${item[`logo_big`]}`;
                newObj.imgLink = `${getS3ImageUrl()}/images/brands/logo-small/${item[`logo_small`]}`;

                yield llattempt(
                    () =>
                        Promise.all([
                            imageLoading(newObj.imgLink),
                            imageLoading(newObj.bigImgLink),
                        ]).then((res) => {
                            newObj.hasImage = res[0];
                            newObj.hasBigImage = res[1];
                            self._setProvider({item: newObj, type});
                        }),
                    {
                        at: 'check for provider img',
                        withParams: {
                            imgLink: newObj.imgLink,
                            bigImgLink: newObj.bigImgLink,
                        },
                        withNotifier: false,
                    }
                );
            }),
            _setSlotBattlesGlobalError(flag) {
                self.slotBattlesGlobalError = flag;
            },
            _setFiltersLoaded(flag, gamesType) {
                self[gamesType + 'FiltersLoaded'] = flag;
            },
            _setProvider({item, type}) {
                self[type + 'Provider'].set(item.name, item);
            },
            _setSlotBattlesFetchTime(time) {
                self.slotBattlesFetchTime = time;
            },
            _setActiveBattle(data) {
                self.activeBattle = BattleItem.create({
                    RoomUUID: data.RoomUUID,
                    room_status: data.room_status,
                    IsPrivate: !!data.IsPrivate,
                    idt: data.idt,
                    sdt: data.sdt,
                    BrandName: data.BrandName,
                    ProviderID: data.ProviderID,
                    GameID: data.GameID,
                    GameName: data.GameName,
                    thumbnail: data.thumbnail,
                    Bet: data.Bet,
                    PlayersQty: data.PlayersQty,
                    ConnectedPlayersQty: data.ConnectedPlayersQty,
                    PlayedPlayersQty: data.PlayedPlayersQty,
                    owner: data.owner,
                });
                self.activeBattle.setPlayers(data.players.reduce((acc, item) => {
                    if (!item.result) item.result = 0;
                    return [...acc, item]
                }, []));
                data.players.map(player => {
                    self.activeBattle.playersForView.put({
                        CustomerID: player.CustomerID,
                        Customer: player.Customer,
                        IsPlayed: !!player.IsPlayed,
                        InPlay: !!player.InPlay,
                        BetAmount: player.BetAmount,
                        Prize: data.room_status === 'completed' ? player.Prize : 0,
                        result: data.room_status === 'completed' ? player.result : 0,
                    });
                })
            },
            _setBattleInfoLoading(flag) {
                self.slotBattlesBattleInfoLoading = flag;
            },

            //EXTERNAL ACTIONS
            setActiveBattleRoomsCount(count) {
                self.activeBattleRoomsCount = count;
            },
            getSlotBattlesFilter: flow(function* fetch() {
                if (
                    !self.slotBattlesFetchTime ||
                    (new Date() - self.slotBattlesFetchTime > TIMERS.slotBattlesFetchTimer)
                ) {
                    self._setSlotBattlesGlobalError(false);
                    self.slotBattlesFetchTime = null;
                    const response = yield llattempt(
                        () => api.games.getBrands(),
                        {
                            msg: trans('SLOT_BATTLE__CONNECTION_ISSUES_ERROR'),
                            at: 'games.getBrands',
                            withParams: {},
                            onError: () => {
                                self._setSlotBattlesGlobalError(true);
                            }
                        });
                    if (response.success) {
                        self.slotBattlesProvider.clear();

                        const arrayAddProviderPromises = [];
                        response.data.map((item) => arrayAddProviderPromises
                            .push(self._addProvider({item, url: 'slots', type: 'slotBattles'}))
                        )
                        Promise.all(arrayAddProviderPromises).then(() => {
                            self._setSlotBattlesFetchTime(Date.now());
                        });
                    } else {
                        self._setSlotBattlesGlobalError(true);
                    }
                }
            }),
            getSlotBattlesBanner: flow(function* fetch() {
                if (
                    !self.banners.fetchTime ||
                    (Date.now() - self.banners.fetchTime > TIMERS.slotBattlesBannerFetchTimer)
                ) {
                    self.banners.setLoading(true);
                    self.banners.clearBanners();
                    const response = yield llattempt(
                        () => api.games.getBattlesBanner(),
                        {
                            at: 'games.getBattlesBanner',
                            withNotifier: false,
                        }
                    );
                    if (response.success) {
                        const arrayOfBannerPromises = [];
                        response.data.map(banner => {
                            banner.type.replace(/battle_(.*)/, (p1, type) => {
                                if (['desktop', 'mobile'].includes(type)) {
                                    self.banners[type] = {
                                        image: getS3ImageUrl() + '/images/banners/' + getRoot(self).user.customerGroup + '/' + banner.image,
                                        url: banner.url,
                                    };
                                    arrayOfBannerPromises
                                        .push(self.banners[type].checkImg())
                                }
                            })
                        });
                        Promise.all(arrayOfBannerPromises).then(() => {
                            self.banners.setFetchTime(Date.now());
                            self.banners.setLoading(false);
                        });
                    } else {
                        self.banners.setFetchTime(null);
                        self.banners.setLoading(false);
                    }
                }
            }),
            createBattle: flow(function* fetch(params) {
                const response = yield llattempt(
                    () => api.games.createRoom(params),
                    {
                        msg: trans('SLOT_BATTLE__CONNECTION_ISSUES_ERROR'),
                        at: 'games.createRoom',
                        withParams: params,
                    }
                );
                if (response.success) {
                    getRoot(self).user.setUserSentBattleInvite(false);
                    getRoot(self).router.push(`/slots-battles/${response.data.RoomUUID}`);
                    if (response.data.idt) response.data.idt = response.data.idt.replace(' ', 'T');
                    if (response.data.sdt) response.data.sdt = response.data.sdt.replace(' ', 'T');
                    getRoot(self).user.setUserBattle(response.data);
                } else {
                    llNotifier({
                        message: response.data?.error ?
                            trans('SLOT_BATTLE__ERROR_' + response.data?.error)
                            :
                            trans('SLOT_BATTLE__GENERAL_ERROR'),
                        type: 'warn',
                        options: {autoClose: 50000},
                    });
                }
            }),
            joinBattle: flow(function* fetch(params) {
                const response = yield llattempt(
                    () => api.games.joinRoom(params),
                    {
                        msg: trans('SLOT_BATTLE__CONNECTION_ISSUES_ERROR'),
                        at: 'games.joinRoom',
                        withParams: params,
                    }
                );
                if (response.success) {
                    if (response.data.idt) response.data.idt = response.data.idt.replace(' ', 'T');
                    if (response.data.sdt) response.data.sdt = response.data.sdt.replace(' ', 'T');
                    getRoot(self).user.setUserBattle(response.data);
                    return true;
                } else {
                    llNotifier({
                        message: response.data?.error ?
                            trans('SLOT_BATTLE__ERROR_' + response.data?.error)
                            :
                            trans('SLOT_BATTLE__GENERAL_ERROR'),
                        type: 'warn',
                        options: {autoClose: 50000},
                    });
                    return false;
                }
            }),
            leaveBattle: flow(function* fetch(roomId) {
                const params = {roomId}
                const response = yield llattempt(
                    () => api.games.leaveRoom(params),
                    {
                        msg: trans('SLOT_BATTLE__CONNECTION_ISSUES_ERROR'),
                        at: 'games.leaveRoom',
                        withParams: params,
                    }
                );
                if (response.success) {
                    const user = getRoot(self).user;
                    user.battlePopup?.close();
                    user.setUserSentBattleInvite(false);
                    clearTimeout(window._activeBattleUpdateInterval);
                    self.setActiveBattle(null);
                    getRoot(self).router.push('/slots-battles');
                    return true;
                } else {
                    llNotifier({
                        message: response.data?.error ?
                            trans('SLOT_BATTLE__ERROR_' + response.data?.error)
                            :
                            trans('SLOT_BATTLE__GENERAL_ERROR'),
                        type: 'warn',
                        options: {autoClose: 50000},
                    });
                    return false;
                }
            }),
            getBattleInfo: flow(function* fetch(params) {
                const response = yield llattempt(
                    () => api.games.roomInfo(params),
                    {
                        msg: trans('SLOT_BATTLE__CONNECTION_ISSUES_ERROR'),
                        at: 'games.roomInfo',
                        withParams: {},
                    }
                );
                if (response.success) {
                    if (response.data.idt) response.data.idt = response.data.idt.replace(' ', 'T');
                    if (response.data.sdt) response.data.sdt = response.data.sdt.replace(' ', 'T');
                    return response.data; //не менять ретурн, он нужен так же для setUserBattleUpdate
                }
            }),
            updateActiveBattle() {
                const user = getRoot(self).user;
                if (self.activeBattle?.players?.length) {
                    clearTimeout(window._activeBattleUpdateInterval);
                    window._activeBattleUpdateInterval = setTimeout(() => {
                        if (getRoot(self)?.site?.status.isActive && !getRoot(self).user.playingGameId) {
                            self.activeBattle.RoomUUID && self.getBattleInfo({roomId: self.activeBattle.RoomUUID}).then(res => {
                                if (res && (self.activeBattle.RoomUUID === user.userBattleId)) user.setUserBattle(res);
                                if (res?.players?.length) {
                                    self.activeBattle?.updateBattle(res);
                                    if (res.room_status !== 'completed') {
                                        self.updateActiveBattle();
                                    } else {
                                        user.battlePopup.close();
                                        clearTimeout(window._activeBattleUpdateInterval);
                                    }
                                } else {
                                    self.setActiveBattle(null);
                                    clearTimeout(window._activeBattleUpdateInterval);
                                    user.userBattleId && user.battlePopup.setWhatWasChanged('canceled');
                                    getRoot(self).router.push(`/slots-battles/available`);
                                }
                            });
                        } else {
                            self.updateActiveBattle();
                        }
                    }, 5000);
                }
            },
            setActiveBattle(id) {
                if (id) {
                    if (!self.activeBattle || self.activeBattle.RoomUUID !== id) {
                        // check proper response
                        self.slotBattlesBattleInfoLoading = true;
                        self.getBattleInfo({roomId: id}).then(res => {
                            if (res?.RoomUUID) {
                                self._setActiveBattle(res);
                            }
                            self._setBattleInfoLoading(false);
                            return !!res?.success;
                        });
                    }
                } else {
                    self.activeBattle = null;
                    return false;
                }
            },
            setSlotBattlesType(type) {
                self.slotBattlesType = type;
            },
            setSlotBattlesSearch(str) {
                self.slotBattlesSearch = str;
            },
            setSlotBattlesProvider(str) {
                self.slotBattlesChosenProvider = str;
            },
            setSlotBattlesSortby(str) {
                self.slotBattlesChosenSortby = str;
            },
            setTutorialStep(step) {
                self.tutorialStep = step;
            },
            setTutorialOnCloseGoTo(link) {
                self.tutorialOnCloseGoTo = link;
            },
            setTutorialNeedsToBeShowed(flag) {
                self.tutorialNeedsToBeShowed = flag;
            },
        };
    })
    .views((self) => ({
        getProvider(type, id) {
            return self[type + 'Provider']?.get(id) ?? {};
        },
        get slotBattlesProviderList() {
            return orderBy(values(self.slotBattlesProvider), ['order', 'name'], 'asc');
        },
        get presetFilters() {
            const filters = {
                currentView: self.gamesViewType,
                activeProvider: '',
                activeType: '',
                search: '',
            }
            if (self.gamesFilterByType) {
                switch (self.gamesViewType) {
                    case 'gameType':
                        filters.currentView = self.gamesViewType;
                        filters.activeType = self.gamesFilterByType;
                        return filters;
                    case 'provider':
                        filters.currentView = self.gamesViewType;
                        filters.activeProvider = self.gamesFilterByType;
                        return filters;
                    case 'search':
                        filters.currentView = '';
                        filters.search = self.gamesFilterByType;
                        return filters;
                    default:
                        return filters;
                }
            } else {
                return filters;
            }
        },
    }));

export default SlotsBattles;
