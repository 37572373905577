import React, { Fragment, useState } from 'react';
import { inject, observer } from 'mobx-react';

import {
    LLErrorVisualizer,
    LLMenuAccordion,
    BetradarWidgets,
    LLElementTypeSetter,
} from '../../../../components';
import CommonMatchListItem from '../../components/common/common-match-item';


const LLMatchListMobile = ({ store, view }) => {
    const branchActiveTournament = store.betting.branch.activeTournament,
        branchActiveCategory = store.betting.branch.activeCategory,
        matches = store.betting.matches,
        matchListError = store.betting.bettingErrors.list,
        activeItems = store.betting.activeItems;

    const [activeAccordion, setActiveAccordion] = useState(0);

    const activeCategory = branchActiveCategory();

    const renderTournamentHeader = (tournamentName, matchesCount) => {
        return (
            <div className="ll-tournament-detail__name">
                <div className="ll-tournament-detail__text">{tournamentName}</div>
                <div className="ll-tournament-detail__matches-count">{matchesCount}</div>
            </div>
        );
    };

    const renderSingleTournamentMatches = () => {
        const activeTournament = branchActiveTournament();
        const singleTournamentMatches = activeTournament?.matchesGroupedByDate();
        if (!singleTournamentMatches) return null;
        let matchesCount = activeTournament.matchesIds.length;
        const singleMatchInstance = Object.values(singleTournamentMatches)[0][0];

        return (
            <>
                <LLElementTypeSetter
                    className="menu__board"
                    as="NavLink"
                    to={`/sport/${activeItems.branch}/${singleMatchInstance.sportId}/${singleMatchInstance?.categoryId}`}
                >
                    <i className="menu__board-back llg-chevron-left" />
                    <div className="menu__breadcrumbs">
                        <div className="menu__breadcrumbs-title">
                            {singleMatchInstance.nameOf('sport')}
                        </div>
                        <div className="menu__breadcrumbs-text">
                            {singleMatchInstance.nameOf('category')}
                        </div>
                    </div>
                    <div className="menu-item__after">
                        <span className="menu-item__after-pre-count">
                            {activeCategory?.matchCount}
                        </span>
                        <span className="menu-item__after-out-count">
                            {activeCategory?.outrightCount}
                        </span>
                    </div>
                </LLElementTypeSetter>
                <div className="ll-tournament-detail__accordion">
                    {renderTournamentHeader(activeTournament.name, matchesCount)}
                    {Object.keys(singleTournamentMatches).map((date, i) => (
                        <Fragment key={i}>
                            <div className="ll-tournament__accordion-head">
                                <span className="ll-tournament__accordion-date-group">
                                    {date}
                                </span>
                            </div>

                            {singleTournamentMatches[date].map((match, i) => (
                                <CommonMatchListItem key={i} match={match} type="list" />
                            ))}
                        </Fragment>
                    ))}
                    {singleMatchInstance.tournamentId && !matchListError && (
                        <BetradarWidgets
                            className="ll-tournament-widgets"
                            tournamentId={singleMatchInstance.tournamentId}
                            type="season.liveTable"
                        />
                    )}
                </div>
            </>
        );
    };

    const renderAllTournamentMatches = () => {
        const allTournaments = activeCategory.allTournaments;
        if (!allTournaments.length) return null;
        const firstMatch = matches.matches.get(allTournaments[0].matchesIds[0]);
        return (
            <>
                <LLElementTypeSetter
                    className="menu__board"
                    as="NavLink"
                    to={`/sport/${activeItems.branch}/${firstMatch?.sportId}/${firstMatch?.categoryId}`}
                >
                    <i className="menu__board-back llg-chevron-left" />
                    <div className="menu__breadcrumbs">
                        <div className="menu__breadcrumbs-title">
                            {firstMatch?.nameOf('sport')}
                        </div>
                        <div className="menu__breadcrumbs-text">
                            {firstMatch?.nameOf('category')}
                        </div>
                    </div>
                    <div className="menu-item__after">
                        <span className="menu-item__after-pre-count">
                            {activeCategory?.matchCount}
                        </span>
                        <span className="menu-item__after-out-count">
                            {activeCategory?.outrightCount}
                        </span>
                    </div>
                </LLElementTypeSetter>
                {allTournaments.map((tournament, i) => {
                    let matchesCount = tournament.matchesIds.length;
                    const tournamentMatches = tournament.matchesGroupedByDate() ?? {};

                    return (
                        <LLMenuAccordion
                            titleTemplate={renderTournamentHeader(
                                tournament.name,
                                matchesCount
                            )}
                            key={i}
                            customClasses="ll-tournament-detail__accordion"
                            expanded={i === activeAccordion}
                            onClick={() => setActiveAccordion(i)}
                        >
                            {Object.keys(tournamentMatches).map((date, i) => (
                                <Fragment key={i}>
                                    <div className="ll-tournament__accordion-head">
                                        <span className="ll-tournament__accordion-date-group">
                                            {date}
                                        </span>
                                    </div>

                                    {tournamentMatches[date].map((match, i) => (
                                        <CommonMatchListItem key={i} match={match} type="list" />
                                    ))}
                                </Fragment>
                            ))}
                        </LLMenuAccordion>
                    );
                })}
            </>
        );
    };

    return matchListError ? (
        <LLErrorVisualizer error={matchListError} />
    ) : (
        {
            singleMatches: renderSingleTournamentMatches,
            allMatches: renderAllTournamentMatches,
        }[view]()
    );
};

export default inject('store')(observer(LLMatchListMobile));
