import {getRoot, flow, types} from 'mobx-state-tree';
import {sortBy} from 'lodash';
import {values} from 'mobx';

import api from '../../../common/api';
import {vendorTextReplacer, urlCleaner} from '../../../common/utils';

import {TIMERS} from '../../config';

let i = 0;


const SliderItem = types
    .model('SliderItem', {
        id: types.identifierNumber,
        image: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        url: types.maybeNull(types.string),
        sliderType: types.string,
        description: types.maybeNull(types.string),
    });

const HomeConfig = types
    .model('HomeConfig', {
        sliderMobile: types.map(SliderItem),
        sliderDesktop: types.map(SliderItem),
        fetchTime: types.maybeNull(types.Date),
        isLoading: true,
    })
    .actions((self) => ({
        _set(data) {
            self.sliderMobile.clear();
            self.sliderDesktop.clear();
            data.slider?.length && data.slider.map((item) => {
                item.name = vendorTextReplacer(item.name.toString());
                item.url = urlCleaner(item.url);
                item.image = urlCleaner(item.image);
                item.description = vendorTextReplacer(item.description.toString());
                const typeArray = item.type.split('_');
                item.sliderType = typeArray[2];

                self['slider' + typeArray[1][0].toUpperCase() + typeArray[1].slice(1)].put(item);
            });

            self.fetchTime = new Date();
            self.isLoading = false;
        },
        getHomeConfig: flow(function* fetch() {
            try {
                if (new Date() - self.fetchTime > TIMERS.homeFetchTimer) {
                    self.isLoading = true;
                    const config = yield api.home.getHomeConfig();
                    self._set(config.data);
                }
            } catch (error) {
                self.setGlobalError(true, error);
                self.isLoading = false;
            }
        }),
        setGlobalError(errorStatus, errorEvent = null) {
            getRoot(self).site.status.setGlobalError(errorStatus);
            errorStatus && console.error('Website general error', errorEvent);
        },
        resetFetchTimes() {
            self.fetchTime = null;
            self.fetchTimeDesktop = null;
            self.fetchTimeMobile = null;
        },
    }))
    .views((self) => ({
        homeSlides({platform, type}) {
            if (getRoot(self).user.customerGroup !== "GUEST") {
                return sortBy(values(self['slider' + platform]), 'order').filter(item => item.sliderType === type);
            } else {
                return []
            }
        },
    }));

export default HomeConfig;
