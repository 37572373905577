import React from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';
import { CSSTransition } from 'react-transition-group';

/**
 * @desc Loading spiner
 *
 * @param {object} props - whole list of props you can find here https://js.devexpress.com/Demos/WidgetsGallery/Demo/LoadPanel/Overview/React/Light/
 *
 * @author I.Mykhailychenko
 */

const LLPreloader = ({ fullPage, visible, className, timeout = 500, ...rest }) => {
    return fullPage ? (
        <CSSTransition
            classNames={`ll-preloader`}
            timeout={timeout}
            in={visible}
            unmountOnExit
        >
            <div className={`ll-preloader${className ? ' ' + className : ''}`}>
                <LoadIndicator {...rest} />
            </div>
        </CSSTransition>
    ) : (
        <LoadIndicator elementAttr={{ class: className }} {...rest} />
    );
};

export default LLPreloader;
