import React from 'react';
import cx from 'classnames';

import {LLTextSlider} from '../../../../components';


export const SearchSportsList = ({search, sports, activeSport, viewSize}) => {
    return (
        <div className="sport-search__sports-list">
            <LLTextSlider
                id="sport-search__sports-list-slider"
                viewSize={viewSize}
                activeItem={activeSport}
                withAbsoluteArrows={viewSize !== 'desktop'}
                showArrows={viewSize === 'desktop'}
                customClasses={{
                    slider: 'sport-search__sports-list-slider',
                }}
            >
                {sports.map(({sport, count}) =>
                    <div
                        className={cx(
                            'sport-search__sport-item',
                            activeSport === sport.id && 'sport-search__sport-item--active'
                        )}
                        onClick={() => search.setActiveSport(sport.id)}
                        id={sport.id}
                        key={sport.id}
                    >
                        { sport.id !== '-999' &&
                            <i className={`sport-search__sport-icon sport_icon_font-${sport.id}`} />
                        }
                        <span>{sport.name}</span>
                        <span className="sport-search__sport-count">
                            {count}
                        </span>
                    </div>)}
            </LLTextSlider>
        </div>
    );
};