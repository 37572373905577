import {types as t, flow, getRoot} from 'mobx-state-tree';

import Branch from './branch/branch'; /*  Model represents single branch */
import Matches from './matches/matches'; /* Model represents matches data*/
import LiveMatches from './matches/live-matches'; /* Model represents live matches data*/
import {branchConfig, refreshTime} from './config'; /* Variables for global usage */
import Outrights from './outrights/outrights'; /* Model represents outrights data */
import {isBase64} from '../../../common/utils';
import {BettingErrors, ActiveItems, ActiveMenuItems, EventsViews} from './helpers';
import WithFlags from './with-flags';
import RangedEvents from './ranged-events';
import FilteredRanges from "./filtered-ranges/filtered-ranges";
import {reaction} from "mobx";

/**
 * @file Represents a betting model
 *
 * @memberof RootStore
 *
 */

const Betting = t
    .model('Betting', {
        /* Array of all branches */
        branches: t.map(t.compose(Branch, WithFlags)),

        activeItems: t.optional(ActiveItems, {
            sports: [],
            categories: [],
            tournaments: [],
            matches: [],
            marketGroups: [],
            customMarketGroups: [],
        }),

        activeMenuItems: t.optional(ActiveMenuItems, {}),

        /* Define all active instances (sports, categories, tournaments, etc...) */
        sportMountStatus: false,

        /* Array of all matches */
        matches: t.optional(t.compose(Matches, WithFlags), {}),
        liveMatches: t.optional(t.compose(LiveMatches, WithFlags), {
            activeLiveView: 'menu',
        }),
        outrights: t.optional(t.compose(Outrights, WithFlags), {}),
        rangedEvents: t.optional(t.compose(RangedEvents, EventsViews), {}),
        filteredRanges: t.optional(FilteredRanges, {}),

        /* Define is error exists and its text */
        bettingErrors: t.optional(BettingErrors, {}),

        /* Define loading status for betting instances */
        initialLoading: true,
        requestedDataLoading: t.maybeNull(t.boolean),

        isMenuDisabled: t.maybeNull(t.boolean),

        marketGroupsTabsView: true,

        isCustomBetsView: false,

        isBreadcrumbsNavigationDropdownOpen: false,

        isSearchFilterPanelOpened: false,

        mobileTicketsPanelStatus: 'closed',
    })
    .actions((s) => {
        reaction(() => s.branchId, () => {
            s.setSearchFilterPanelOpened(false);
        })
        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                 requested data loader                                ##
        // ##                                                                                      ##
        // ##========================================================================================

        /**
         * @desc - This action will load requested data
         * @property {object} requestedData - all data requested by router
         * @property {string} requestedData.branchId - id of requested branch
         * @property {string} requestedData.sportId - id or requested sport
         * @property {string} requestedData.categoryId - id of requested category
         * @property {string} requestedData.tournamentId - id of reqesetd tournament
         * @property {boolean} takerequestedDataFromRouter - if true function will take all reqested data from existing router location pathname
         */

        return {
            loadrequestedData: flow(function* fetch({
                                                        requestedData = {},
                                                        initialLoading = false,
                                                        takerequestedDataFromRouter = false,
                                                    } = {}) {
                loadrequestedData: {
                    if (takerequestedDataFromRouter) {
                        requestedData = {
                            ...getRoot(s).router.prematchPathParams,
                            ...requestedData,
                        };
                    }

                    /**** ~~ Sets flag that render loading for components ****/
                    initialLoading &&
                    s.branch.setFetching({
                        type: 'initialFetching',
                        status: true,
                    });
                    s.clearAllUpdaters();

                    /**** ~~ Begin requested data loading ****/
                    s.setFetching({
                        type: 'requestedDataLoading',
                        status: true,
                    });

                    const sportIds = requestedData?.sportId?.split('-');
                    const categoryIds = requestedData?.categoryId?.split('-');

                    if (s.branch.id === 'Live') {
                        s.liveMatches.initializeLiveMatches().then(() => {
                            if (!s.bettingErrors.global && getRoot(s).betting.branchId === 'Live') {
                                let match;
                                if (requestedData?.matchId && parseInt(requestedData?.matchId)) {
                                    match = s.liveMatches.matches.get(requestedData.matchId);
                                }
                                if (match) {
                                    match.initializeAllMarketsInstruction();
                                }
                            }
                        });
                        break loadrequestedData;
                    }

                    let rangedEventsFetch;

                    const fetchSport = s.branch.initializeSportInstruction({
                        requestedSportId: requestedData.sportId,
                    });

                    /***************** ~~ Fetch sports data ****************/

                    const fetchPopularTournaments = s.branch.initializePopularTournamentsInstruction;

                    /***************** ~~ Fetch ranged events config ****************/

                    let fetchRangedEventsConfig = null;
                    if (!s.rangedEvents.fetchTime || Date.now() - s.rangedEvents.fetchTime > refreshTime.rangedEventsConfig) {
                        fetchRangedEventsConfig = s.rangedEvents.getConfig;
                    }

                    /***************** ~~ Fetch featured events data ****************/

                    let widgetRequest = null;
                    if (!requestedData?.matchId) {
                        widgetRequest = getRoot(s).widgets.initializeInstruction({type: 'll'});
                    }

                    /***************** ~~ Fetch categorys of requested sport ****************/
                    let requestedCategoryGetter;

                    if (requestedData?.sportId && parseInt(requestedData?.sportId)) {
                        /**** ~~ Create Empty instance for requested sport (last one if subsport) *****/
                        if (sportIds[sportIds.length - 1]) {
                            if (!s.branch.sports.has(sportIds[sportIds.length - 1])) {
                                s.branch.putUpdateData({
                                    dataSource: [
                                        {id: sportIds[sportIds.length - 1], order: 0},
                                    ],
                                });
                            }

                            /**** ~~  Fetch categories data of sport ****/
                            requestedCategoryGetter =
                                /**** ~~ Get category of requested sport ****/
                                s.branch.sports.get(sportIds[sportIds.length - 1])
                                    .initializeCategoriesInstruction;
                        }
                    }

                    /***************** ~~ Fetch tournaments of requested category ****************/
                    let requestedTournamentGetter;

                    if (
                        requestedData?.categoryId &&
                        parseInt(requestedData?.categoryId) &&
                        requestedData?.sportId &&
                        parseInt(requestedData?.sportId)
                    ) {
                        const sport = s.branch.sports.get(sportIds[sportIds.length - 1]);
                        /**** ~~ Create Empty instance of requested category ****/
                        if (!sport.categories.has(categoryIds[categoryIds.length - 1])) {
                            sport.putUpdateData({
                                dataSource: [
                                    {id: categoryIds[categoryIds.length - 1], order: 0},
                                ],
                            });
                        }

                        /**** ~~ Get tournaments of requested category ****/
                        requestedTournamentGetter = sport.categories.get(
                            categoryIds[categoryIds.length - 1]
                        ).initializeTournamentsInstruction;
                    }

                    /***************** ~~ Fetch matches for requested tournament ****************/
                    let requestedTournamentMatchesGetter;
                    let requestedTournamentMatchesData;

                    if (requestedData.tournamentId !== 'Outrights') {
                        if (!requestedData.tournamentId && (requestedData.sportId === 'popular' || (!s.rangedEvents.config.has(requestedData.sportId) && getRoot(s).site.status.viewSize !== 'mobile'))) {
                            requestedTournamentMatchesGetter =
                                s.matches.initializeTopAndFavoriteInstruction;
                        }

                        if (requestedData.tournamentId) {
                            requestedTournamentMatchesGetter =
                                s.matches.initializeSingleInstruction;
                            requestedTournamentMatchesData = {
                                tournamentId: requestedData.tournamentId,
                                categoryId: categoryIds[categoryIds.length - 1],
                                sportId: requestedData.sportId,
                                matchId: requestedData.matchId,
                            };
                        }

                        if (requestedData.tournamentId === 'All' && !requestedData.matchId) {
                            requestedTournamentMatchesGetter =
                                s.matches.initializeAllInstruction;
                            requestedTournamentMatchesData = {
                                sportId: requestedData.sportId,
                                categoryId: categoryIds[categoryIds.length - 1],
                            };
                        }
                    }

                    if (
                        requestedData.tournamentId === 'Outrights' &&
                        !requestedData.matchId
                    ) {
                        requestedTournamentMatchesGetter =
                            s.outrights.initializeOutrightsInstruction;
                        requestedTournamentMatchesData = {
                            sportId: requestedData.sportId,
                            categoryId: categoryIds[categoryIds.length - 1],
                            requestedByRouterId: requestedData?.matchId
                                ? atob(requestedData.matchId)
                                : null,
                        };
                    }

                    if (
                        requestedData.tournamentId === 'Outrights' &&
                        requestedData?.matchId
                    ) {
                        requestedTournamentMatchesGetter =
                            s.outrights.initializeOutrightsInstruction;
                        requestedTournamentMatchesData = {
                            sportId: requestedData.sportId,
                            categoryId: categoryIds[categoryIds.length - 1],
                        };
                    }

                    /***************** ~~ Fetch match details for requested match ****************/

                    if (
                        requestedData?.matchId &&
                        requestedData?.tournamentId !== 'Outrights'
                    ) {
                        /**** ~~ Create Empty instance of requested match ****/
                        if (!s.matches.matches.has(requestedData.matchId)) {
                            s.matches.setMatch({
                                id: requestedData.matchId,
                                competitor1: 1,
                                competitor2: 1,
                                timeStart: '',
                                oddsCount: 1,
                                order: 1,
                                tv: false,
                                categoryId: requestedData.categoryId,
                                tournamentId: requestedData.tournamentId,
                                canCashout: false,
                            });
                        }
                    }

                    /***************** ~~ Resolve check and data set ***************
                     * @desc - Check is all fetches was resolved
                     */
                    yield Promise.all([
                        fetchSport,
                        fetchPopularTournaments(),
                        fetchRangedEventsConfig && fetchRangedEventsConfig(),
                        widgetRequest && widgetRequest,
                        requestedCategoryGetter &&
                        requestedCategoryGetter({
                            id: sportIds[sportIds.length - 1],
                            requestedCategoryId: requestedData.categoryId,
                        }),
                        requestedTournamentGetter &&
                        requestedTournamentGetter({
                            id: categoryIds[categoryIds.length - 1],
                            requestedData: requestedData,
                        }),
                        requestedTournamentMatchesGetter &&
                        requestedTournamentMatchesGetter(requestedTournamentMatchesData),
                    ])
                        .then(() => {
                            if (
                                requestedData?.matchId &&
                                requestedData?.tournamentId !== 'Outrights'
                            ) {
                                const match = s.matches.matches.get(requestedData.matchId);
                                if (match.sportId) {
                                    match.initializeAllMarketsInstruction().then(() => {
                                        s.setFetching({
                                            type: 'requestedDataLoading',
                                            status: false,
                                        });
                                        s.branch.setFetching({
                                            type: 'initialFetching',
                                            status: false,
                                        });
                                        s.setInitialLoading(false);
                                    });
                                }
                            } else if (
                                isBase64(requestedData.matchId) &&
                                s.outrights.matches.has(isBase64(requestedData.matchId))
                            ) {
                                s.outrights.matches
                                    .get(isBase64(requestedData.matchId))
                                    .initializeOutrightBetsInstruction()
                                    .then(() => {
                                        s.setFetching({
                                            type: 'requestedDataLoading',
                                            status: false,
                                        });
                                        s.branch.setFetching({
                                            type: 'initialFetching',
                                            status: false,
                                        });
                                        s.setInitialLoading(false);
                                    });
                            } else {
                                s.branch.setFetching({
                                    type: 'initialFetching',
                                    status: false,
                                });
                                s.setInitialLoading(false);

                                if (s.rangedEvents.config.has(requestedData.sportId)) {
                                    rangedEventsFetch = s.rangedEvents.initializeInstruction();
                                }
                                if (rangedEventsFetch) {
                                    rangedEventsFetch.then(() => {
                                            s.setFetching({
                                                type: 'requestedDataLoading',
                                                status: false,
                                            })
                                        }
                                    );
                                } else {
                                    s.setFetching({
                                        type: 'requestedDataLoading',
                                        status: false,
                                    });
                                }
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                            s.setError({
                                type: 'global',
                                error: initialLoading
                                    ? 'GENERAL_ERROR'
                                    : 'CONNECTION_ISSUES_ERROR',
                            });
                        });
                }
            }),

            // ##========================================================================================
            // ##                                                                                      ##
            // ##                      Works on mount / unmount bettings page                          ##
            // ##                                                                                      ##
            // ##========================================================================================

            setNavigationDropdown(status = false) {
                s.isBreadcrumbsNavigationDropdownOpen = status;
            },

            // ##========================================================================================
            // ##                                                                                      ##
            // ##                      Works on mount / unmount bettings page                          ##
            // ##                                                                                      ##
            // ##========================================================================================

            mountSportPage(status = false) {
                s.sportMountStatus = status;
                if (!status) {
                    s.clearAllErrors();
                }
            },

            // ##========================================================================================
            // ##                                                                                      ##
            // ##                                   Error setters / cleaners                           ##
            // ##                                                                                      ##
            // ##========================================================================================

            setError({type, error, title = 'Error'}) {
                if (error === 'GENERAL_ERROR' && s.initialLoading && s.branchId !== 'Live') {
                    error = 'ERROR_404';
                }
                if (!error || typeof error !== 'string') error = 'GENERAL_ERROR';
                if (!s.bettingErrors[type]) {
                    s.bettingErrors[type] = error;
                    s.bettingErrors.title = title;
                }
            },

            clearError({type}) {
                s.bettingErrors[type] = null;
            },

            clearAllErrors(exception) {
                Object.keys(s.bettingErrors).map(
                    (key) => key !== exception && (s.bettingErrors[key] = null)
                );
            },

            // ##========================================================================================
            // ##                                                                                      ##
            // ##                       Loading flags cleaners / setters                              ##
            // ##                                                                                      ##
            // ##========================================================================================

            setFetching({type, status}) {
                s[type] = status;
            },

            setInitialLoading(status) {
                s.initialLoading = status;
            },

            // ##========================================================================================
            // ##                                                                                      ##
            // ##                                   Updaters cleaner                                   ##
            // ##                                                                                      ##
            // ##========================================================================================

            clearAllUpdaters() {
                clearTimeout(window.__sportsUpdater);
                clearTimeout(window.__categoriesUpdater);
                clearTimeout(window.__tournamentsUpdater);
                clearTimeout(window.__matchesUpdater);
                clearTimeout(window.__marketGroupsUpdater);
                clearTimeout(window.__marketsUpdater);
                clearTimeout(window.__outrightsUpdater);
                clearTimeout(window.__outrightBetsUpdater);
                clearTimeout(window.__liveMatchesUpdate);
            },

            // ##========================================================================================
            // ##                                                                                      ##
            // ##                                     Branch setter                                    ##
            // ##                                                                                      ##
            // ##========================================================================================

            setBranch(branch) {
                if (!s.branches.has(branch)) {
                    s.branches.set(branch, {id: branch});
                }
            },

            setCustomBetsView(status) {
                s.isCustomBetsView = status;
            },

            setMobileTicketsPanelStatus(status) {
                s.mobileTicketsPanelStatus = status;
            },

            setSearchFilterPanelOpened(flag) {
                s.isSearchFilterPanelOpened = flag;
            }
        }
    })
    .views((s) => ({
        get branch() {
            /***************** View current branch ****************
             * @desc - get current branch from branches
             */
            return s.branches.get(s.branchId);
        },

        get branchId() {
            return (
                Object.keys(branchConfig).find(
                    (branch) => branchConfig[branch].routerId === s.activeItems.branch
                ) ?? 'All'
            );
        },

        get activeItemsJSON() {
            return JSON.stringify(s.activeItems);
        },
    }));

export default Betting;