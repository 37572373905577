import React from  'react';

import {
    LLElementTypeSetter,
    LLScrollBox,
} from "../../../../../components";
import {formatDate} from "../../../../../common/utils";
import {languages} from "../../../../../configs";


const BreadcrumbsDropdownContent = (
        {
            matchesDropdownShown,
            activeMenuItems,
            dropdownItems,
            branch,
            viewSize,
            language,
        }
    ) => {

    const eventCount = (tournament) => (
        <span className="prematch-breadcrumbs-dropdown__match-count">
            { tournament.id === 'All' ?
                branch.activeCategory().matchCount
                : tournament.id === 'Outrights' ?
                    branch.activeCategory().outrightCount
                    :
                    tournament.matchCount
            }
        </span>
    );

    return (
        matchesDropdownShown && dropdownItems.length && (
            viewSize === 'mobile' ?
                (branch.activeCategory().tournaments.get(activeMenuItems.firstTournament)
                    ? !branch.activeCategory().tournaments.get(activeMenuItems.firstTournament)?.dropDownFetching : dropdownItems.length)
                :
                !branch.activeTournament()?.dropDownFetching
        ) ? (

            <div className="prematch-breadcrumbs-dropdown__content">
                <LLScrollBox
                    id="prematch-breadcrumbs-matches"
                    stayOnScroll={false}
                >
                    {dropdownItems.map(item => {
                        switch (item.type) {
                            case 'match' :
                                return (
                                    <LLElementTypeSetter
                                        onClick={item.data.onClick}
                                        className="prematch-breadcrumbs-dropdown__match-item prematch-breadcrumbs-dropdown__match-item--match"
                                        key={item.data.match?.id}
                                    >
                                        { item.data.isOutright ?
                                            <span className="prematch-breadcrumbs-dropdown__match-name">{item.data.match.name}</span>
                                            :
                                            <span className="prematch-breadcrumbs-dropdown__match-name">
                                                <span className="team-name-holder">{item.data.match.competitor1?.name}</span>
                                                <span>&nbsp;-&nbsp;</span>
                                                <span className="team-name-holder">{item.data.match.competitor2?.name}</span>
                                            </span>
                                        }
                                        <span className="prematch-breadcrumbs-dropdown__match-date">
                                            {formatDate(
                                                new Date(item.data.match.timeStart),
                                                'HH:mm',
                                                language
                                            )}
                                        </span>
                                    </LLElementTypeSetter>
                                );
                            case 'tournamentBack' :
                                return (
                                    <LLElementTypeSetter
                                        onClick={item.data.onClick}
                                        className="prematch-breadcrumbs-dropdown__match-item prematch-breadcrumbs-dropdown__match-item--tournament"
                                        key={'tournamentBack'}
                                    >
                                        <span className="prematch-breadcrumbs-dropdown__match-name">
                                            <span className="prematch-breadcrumbs-dropdown__tournament-name">
                                                <i className="prematch-breadcrumbs-dropdown__tournament-icon llg-chevron-left"/>
                                                <span>{item.data.linkTournament?.name}</span>
                                            </span>
                                            { eventCount(item.data.linkTournament) }
                                        </span>
                                    </LLElementTypeSetter>
                                );
                            case 'tournament' :
                                return (
                                    <LLElementTypeSetter
                                        onClick={item.data.onClick}
                                        className="prematch-breadcrumbs-dropdown__match-item prematch-breadcrumbs-dropdown__match-item--tournament"
                                        key={item.data.tournament?.id}
                                    >
                                    <span className="prematch-breadcrumbs-dropdown__match-name">
                                        <span>-&nbsp;{item.data.tournament?.name}</span>
                                        { eventCount(item.data.tournament) }
                                    </span>
                                    </LLElementTypeSetter>
                                );
                            case 'date' :
                                return (
                                    <LLElementTypeSetter
                                        className="prematch-breadcrumbs-dropdown__match-item prematch-breadcrumbs-dropdown__match-item--date"
                                        key={item.data.key + ' ' + item.data.date}
                                    >
                                        <span>
                                            {formatDate(
                                                new Date(item.data.date),
                                                'DD month YYYY, weekday',
                                                language,
                                                ['today', 'tomorrow']
                                            )}
                                        </span>
                                    </LLElementTypeSetter>
                                );
                            case 'breadcrumbs' :
                                return ( item.data ?
                                        <LLElementTypeSetter
                                            className="prematch-breadcrumbs-dropdown__match-item prematch-breadcrumbs-dropdown__match-item--breadcrumbs"
                                            key={item.data.sportId + item.data.categoryId + (item.data.tournamentId ?? item.data.id)}
                                        >
                                            <span>{item.data.nameOf('tournament')}</span>
                                        </LLElementTypeSetter> : null
                                );
                            default :
                                return null;
                        }

                    })}
                </LLScrollBox>
            </div>) : null
    )
    ;
}


export default BreadcrumbsDropdownContent;