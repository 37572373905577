import React, {useEffect, useState} from "react";
import cx from "classnames";

import {WithDropdown, withCommon, WithPopover, LLBurgerIcon} from '../../../components';
import {trans} from '../../../common/utils';
import {BattlesSearch} from './filter-sort-search/search';
import {
    SLOT_BATTLES_SORTBY_FILTERS,
    SLOT_BATTLES_GAMES_SORTBY_FILTERS,
} from '../config';
import VideoModal from "./tutorials/video-modal";


export const FilterSortSearch = withCommon({
    store: ({store}) => ({
        slotBattlesProviderList: store.slotsBattles.slotBattlesProviderList,
        slotBattlesType: store.slotsBattles.slotBattlesType,
        provider: store.slotsBattles.slotBattlesChosenProvider,
        setProvider: store.slotsBattles.setSlotBattlesProvider,
        sortby: store.slotsBattles.slotBattlesChosenSortby,
        setSortby: store.slotsBattles.setSlotBattlesSortby,
        viewSize: store.site.status.viewSize,
        siteStatus: store.site.status,
        drawerIsActive: store.drawer.isActive,
        isLogged: store.user.isLogged,
        setModal: store.modal.setModal
    }),
    isTrans: true,
})(({
        slotBattlesProviderList,
        slotBattlesType,
        provider,
        setProvider,
        sortby,
        setSortby,
        type = 'menu',
        children,
        viewSize,
        siteStatus,
        drawerIsActive,
        isLogged,
        setModal
    }) => {

    const [openedItem, setOpenedItem] = useState('');

    const OpenVideoTutorial = () => {
        setModal({
            active: true,
            maskBackground: true,
            title: trans("SLOT_BATTLE__TUTORIAL_VIDEO_HEADER"),
            showCloseButton: true,
            currentKey: "custom-content",
            closeOnOuterClick: false,
            customContent: <VideoModal/>,
            customClasses: 'll-modal--battles-tutorial ll-modal--battles-tutorial--video-modal'
        })
    };

    useEffect(() => {
        if (viewSize === 'mobile') {
            if (openedItem === 'search') {
                siteStatus.setBackdropOnClick(() => setOpenedItem(''));
                siteStatus.setBackDropFullScreen(false);
                siteStatus.setBackdropShown(true);
            } else {
                siteStatus.setBackdropShown(false);
            }
        }

    }, [openedItem]);

    useEffect(() => {
        if (drawerIsActive) {
            setOpenedItem('');
        }
    }, [drawerIsActive])

    useEffect(() => () => siteStatus.setBackdropShown(false), []);

    const dropDownProvider = (options) =>
        options.map((item) => ({
            element: (
                <div
                    className="slots-battles__dropdown-item"
                    onClick={() => {
                        setProvider(item.name === provider ? '' : item.name)
                    }}
                >
                    {trans(item.name)}
                </div>
            ),
            code: item.name,
        }));

    const dropDownSortby = () =>
        (slotBattlesType !== 'create' ?
                Object.keys(SLOT_BATTLES_SORTBY_FILTERS)
                :
                Object.keys(SLOT_BATTLES_GAMES_SORTBY_FILTERS)
        ).map((item) => (item === 'My battles' && !isLogged) ? {} : ({
            element: (
                <div
                    className="slots-battles__dropdown-item"
                    onClick={() => setSortby(item === sortby ? '' : item)}
                >
                    {trans(item)}
                </div>
            ),
            code: item,
        }));

    return (
        <div className={cx(
            'slots-battles__filters-search',
            `slots-battles__filters-search--${viewSize}`
        )}>
            <div
                className={cx(
                    "filter-btn-search__item",
                    "filter-btn-search__item--" + viewSize,
                    openedItem === 'filters' ? "open" : ""
                )}
            >
                {(viewSize === 'mobile' && type === 'menu') &&
                    <div className="filter-btn-search__icon-wrp">
                        <LLBurgerIcon
                            open={openedItem === 'filters'}
                            onClick={() => setOpenedItem(
                                openedItem.length ? '' : 'filters'
                            )}
                            customClasses="filter-icon"
                        />
                    </div>
                }
                {<div className={cx(
                    'slots-battles__filters-wrp',
                    'slots-battles__filters-wrp--' + type
                )}>
                    {slotBattlesProviderList?.length > 1 &&
                        <div className="slots-battles-filter slots-battles-dropdown">
                            <WithDropdown
                                dataSource={{
                                    submenu: dropDownProvider(slotBattlesProviderList),
                                }}
                                openBehavior={'click'}
                                menuItemIsActive={(item) => item.code === provider}
                                customClasses={`slots-battles-dropdown__wrapper`}
                                menuClasses="slots-battles-dropdown__content"
                                customScrollClasses={'slots-battles-dropdown__scrollbox'}
                            >
                                <WithPopover popoverClasses="ll-popover__text">
                                    <span>{trans(provider || 'Provider')}</span>
                                </WithPopover>
                            </WithDropdown>
                        </div>
                    }
                    <div className="slots-battles-filter slots-battles-dropdown">
                        <WithDropdown
                            dataSource={{
                                submenu: dropDownSortby(),
                            }}
                            openBehavior={'click'}
                            menuItemIsActive={(item) => item.code === sortby}
                            customClasses={`slots-battles-dropdown__wrapper`}
                            menuClasses="slots-battles-dropdown__content"
                            customScrollClasses={'slots-battles-dropdown__scrollbox'}
                        >
                            <WithPopover popoverClasses="ll-popover__text">
                                <span>{trans(sortby) || trans(`Sort by`)}</span>
                            </WithPopover>
                        </WithDropdown>
                    </div>
                </div>}
            </div>
            {children}
            <BattlesSearch openedItem={openedItem}
                           setOpenedItem={setOpenedItem}/> {/*//TODO: Remove this block when video tutorial is available*/}
            {/*{(viewSize === "mobile" || viewSize === 'tablet') && <> //TODO: Turn on when video tutorial is available*/}
            {/*<div className="slots-battles-search-dummy"/>*/}
            {/*    <div className="slots-battles-types-menu__questions slots-battles-types-menu__questions--mobile"*/}
            {/*         onClick={() => {*/}
            {/*             OpenVideoTutorial();*/}
            {/*         }}>*/}
            {/*        <i className={`doruk-${viewSize === 'desktop' ? 'streaming-icon' : 'streaming-icon'} slots-battles-types-menu__questions-icon`}/>*/}
            {/*    </div>*/}
            {/*</>*/}
            {/*}*/}
        </div>
    );
});
