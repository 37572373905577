import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { LLPreloader } from '../../../../components';

const TvModal = ({ store, matchId }) => {
    const getChannels = store.betting.matches.getChannels,
        matches = store.betting.matches.getMatchesTV;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getChannels({ matchId }).then(() => setLoading(false));
    }, [matchId]);

    return loading ? (
        <LLPreloader />
    ) : (
        <div className="match-tv-modal">
            <ul className="match-tv-modal__list">
                {matches.length
                    ? matches.map(({ Name }) => (
                          <li key={Name} className="match-tv-modal__item">
                              <i className="llg-tv" />
                              <span>{Name}</span>
                          </li>
                      ))
                    : null}
            </ul>
        </div>
    );
};

export default inject('store')(observer(TvModal));
