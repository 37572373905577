import React from 'react';
import {observer} from 'mobx-react';

import Market from './market';


const Markets = ({match, marketGroup, userCanCashout, oddAppearance, viewSize, marketsCount = 0, isOldView, setOldView}) => {

    const renderMarkets = (marketGroup) => {
        const marketsArr = marketGroup.marketsList(marketsCount);
        return marketsArr.length ? marketsArr.map((item, j) => {
            if (item.market.specifierBasedMarkets.length) {
                return item.market.specifierBasedMarkets.map((market, i) => {
                    return <Market
                        key={j * 100 + i}
                        { ...{
                            match,
                            market,
                            userCanCashout,
                            viewSize,
                            isOldView,
                            setOldView,
                            oddAppearance,
                            viewOrder: item.viewOrder
                        }}
                    />
                })
            } else {
                    return <Market
                        key={j}
                        { ...{
                            match,
                            market: item.market,
                            userCanCashout,
                            viewSize,
                            isOldView,
                            setOldView,
                            oddAppearance,
                            viewOrder: item.viewOrder
                        }}
                    />
                }
        }) : null
    }

    return marketGroup ? renderMarkets(marketGroup) : null;
};

export default observer(Markets);
