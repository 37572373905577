import React from 'react';
import { observer, inject } from 'mobx-react';

import PrematchMenuDesktop from './desktop-menu';
import PrematchMenuMobile from './mobile-menu';

const PrematchMenu = ({ store, view }) => {
    const activeItems = store.betting.activeItems,
        initialFetching = store.betting.branch.initialFetching,
        activeItemsJSON = store.betting.activeItemsJSON,
        activeMenuItems = store.betting.activeMenuItems,
        branchId = store.betting.branch.routerId,
        branch = store.betting.branch,
        sportsList = store.betting.branch.sportsList,
        user = store.user,
        viewSize = store.site.status.viewSize,
        siteName = store.site.siteName;

    const renderSportsLink = ({ sport, subSportId, reverseLogic = true }) => {
        const link = subSportId
            ? `/sport/${branchId}/${sport.id}${sport.isParent ? '-' : ''}`
            : `/sport/${branchId}`;
        const id = subSportId ?? `/${sport.id}${sport.isParent ? '-' : ''}`;
        return !activeItems.isActive({ id: subSportId ?? sport.id, type: 'sports' }) ||
            !reverseLogic
            ? `${link}${id}`
            : link;
    };

    const renderCategoryLink = ({
        sport,
        subSportId,
        category,
        subCategoryId,
        reverseLogic = true,
    }) => {
        const link = subCategoryId
            ? `${renderSportsLink({
                  sport,
                  subSportId,
                  reverseLogic: false,
              })}/${category?.id}${category?.isParent ? '-' : ''}`
            : renderSportsLink({ sport, subSportId, reverseLogic: false });
        const id = subCategoryId ?? `/${category.id}${category.isParent ? '-' : ''}`;
        return !activeItems.isActive({
            id: subCategoryId ?? category.id,
            type: 'categories',
        }) || !reverseLogic
            ? `${link}${id}`
            : link;
    };

    const renderTournamentLink = ({
        sport,
        subSportId,
        category,
        subCategoryId,
        tournamentId,
    }) => {
        const link = `${renderCategoryLink({
            sport,
            subSportId,
            category,
            subCategoryId,
            reverseLogic: false,
        })}`;
        return (!activeItems.isActive({ id: tournamentId, type: 'tournaments' }) ||
            !(subCategoryId
                ? activeItems.isActive({ id: subCategoryId, type: 'categories' })
                : activeItems.isActive({ id: category.id, type: 'categories' })) || !!activeItems.matches.length)
            ? `${link}/${tournamentId}`
            : link;
    };

    return viewSize === 'mobile' ? (
        <PrematchMenuMobile
            view={view}
            renderSportsLink={renderSportsLink}
            renderCategoryLink={renderCategoryLink}
            renderTournamentLink={renderTournamentLink}
            activeItems={activeItems}
            branch={branch}
            viewSize={viewSize}
            siteName={siteName}
            user={user}
        />
    ) : (
        <PrematchMenuDesktop
            isLoading={initialFetching}
            renderSportsLink={renderSportsLink}
            renderCategoryLink={renderCategoryLink}
            renderTournamentLink={renderTournamentLink}
            activeItems={activeItems}
            activeMenuItems={activeMenuItems}
            activeItemsJSON={activeItemsJSON}
            sports={sportsList}
            viewSize={viewSize}
        />
    );
};

export default inject(['store'])(observer(PrematchMenu));
