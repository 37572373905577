import React from 'react';
import cx from 'classnames';

import { withCommon, LLMenuItem, LLElementTypeSetter } from '../../../components';
import {DrawerCloseButton} from "../../../pages/user-profile/components";
import {LanguagePicker} from "../../header/header-desktop/components";
import { getGameElemAttributes } from '../../../common/utils';


const NavigationMenuDrawer = withCommon({
    store: ({store}) => ({
        open: store.drawer.open,
        modifiedConfig: store.modifiedConfig,
        mobileUserAgent: store.user.mobileUserAgent,
        viewSize: store.site.status.viewSize,
    }),
    defaultName: 'NavigationMenuDrawer',
    isTrans: true,
})(({open, trans, modifiedConfig, mobileUserAgent, viewSize}) => (<>
        <div className="ll-drawer__header--mobile">
            <LanguagePicker/>
            <div>{trans('Main menu')}</div>
            <DrawerCloseButton/>
        </div>
        <div className="drawer-menu">
            <div className="drawer-menu__items">
                {modifiedConfig.navigationDrawerMenu.map((item) => {
                    if (!item.disabled)
                        return (
                            <LLMenuItem
                                item={item}
                                key={item.label ?? item.name}
                                customClasses="drawer-menu__item"
                                onClick={() => {
                                    if (item.drawer) {
                                        open({
                                            side: item.drawer.side,
                                            currentKey: item.drawer.key,
                                        });
                                    }
                                }}
                                icon={<i className={`${item.icon} drawer-menu__item-icon`}/>}
                                label={trans(item.label)}
                                submenu={item.subItems?.length ? (
                                    item.subItems.map((subItem, i) => {
                                        const elemAttributes = subItem.link ?
                                            { to: subItem.link } : (
                                                (subItem.providerId && subItem.gameId) ?
                                                    getGameElemAttributes({
                                                        mobileUserAgent,
                                                        providerId: subItem.providerId,
                                                        gameId: subItem.gameId,
                                                        mode: 0,
                                                    }) : {}
                                            );

                                        return (
                                            <LLElementTypeSetter
                                                {...elemAttributes}
                                                className={cx(
                                                    `menu-item--${viewSize}`,
                                                    'drawer-menu__item'
                                                )}
                                                key={i}
                                            >
                                                <span className="menu-item__label">
                                                    {subItem.name}
                                                </span>
                                            </LLElementTypeSetter>
                                        );
                                    })
                                ) : undefined}
                                isAccordionSubmenu={true}
                            />
                        );
                })}
            </div>
        </div>
    </>
));

export default NavigationMenuDrawer;
