import { types } from 'mobx-state-tree';
import { getRoot } from 'mobx-state-tree';

const LLLiveChat = types
    .model('GlobalConfig', {
        isLiveChatOpen: types.boolean,
        isLoaded: types.boolean,
    })
    .actions((self) => {
        return {
            open() {
                let params = [
                    { name: 'Müşteri ID', value: 'guest' },
                    { name: 'Kullanıcı Adı', value: '' },
                    { name: 'Para Birimi', value: '' },
                    { name: 'Bayi', value: '' },
                ];

                if (getRoot(self).user.isLogged) {
                    const root = getRoot(self);
                    params = [
                        { name: 'Müşteri ID', value: root.user.customerId },
                        { name: 'Kullanıcı Adı', value: root.user.username },
                        { name: 'Para Birimi', value: root.user.currency },
                        { name: 'Bayi', value: root.user.customerGroup },
                    ];
                }

                if (self.isLoaded) {
                    window.LC_API.set_custom_variables(params);
                    window.LC_API.open_chat_window();
                }

                self.isLiveChatOpen = true;
            },
            setLoaded() {
                self.isLoaded = true;
            },
        };
    });

export default LLLiveChat;
