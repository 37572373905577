import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Image from '../../static/themes/theme/images/errors/404.png';
import { LLBtn } from '../../components';
import withCommon from '../../components/HOCs/common-props';

/**
 * @file Represents Error page
 *
 * @param {string} name - error type
 *
 * @author A.Green
 */

const ErrorPage = withCommon({
    propTypes: {
        name: PropTypes.string,
        trans: PropTypes.func,
    },
    defaultName: 'ErrorPage',
    isTrans: true,
})(({ trans, name = 'error404' }) => {
    const objErrorConfig = {
        error: {
            title: 'Error',
            text: 'There is an Error',
        },
        error404: {
            title: 'ERROR_404_HEAD',
            text: 'ERROR_404',
        },
        suspended: {
            title: 'Account suspended',
            text: 'SUSPENDED',
        },
        serverError: {
            title: 'Error',
            text: 'GENERAL_ERROR',
        },
    };

    return (
        <div className="ll-page error">
            <img src={Image} alt="error" />
            <h3 className="error__title">{trans(objErrorConfig[name].title)}</h3>
            <p className="error__text">{trans(objErrorConfig[name].text)}</p>
            <Link to={'/'} className="error__link">
                <LLBtn customClasses="ll-btn__link" text={trans('Return to homepage')} />
            </Link>
        </div>
    );
});

export default ErrorPage;
