import { flow, types, getRoot } from 'mobx-state-tree';

import api from '../../../common/api';
import {
    FooterBtnsConfig,
    MiniBanners,
    Popup,
    SiteStatuses,
    SocialIcons,
    CrashGamesConfig,
} from './';
import { llattempt } from '../../../common/utils';


const SlotBattlesConfig = types
    .model('SlotBattlesConfig', {
        slotBattlesDisabled: false,
        timeToStart: types.maybeNull(types.number),
        timeToPlay: types.maybeNull(types.number),
        timeToWait: types.maybeNull(types.number),
    });

const SiteConfig = types
    .model('SiteConfig', {
        languages: types.maybeNull(types.array(types.string)), //Array of possible language codes
        siteName: types.string, //Site name (for Titles)
        defaultCustomerGroup: types.maybeNull(types.string), //Site name (for Titles)
        email: types.maybeNull(types.string), //site email
        liveChat: types.maybeNull(types.integer), //live chat id
        isVaixEnabled: false,
        bonusesCount: types.integer, //count of promos
        siteUrl: types.string, //For rel=canonical
        registration: types.boolean, //Is registration is on or off on website
        captchaV3: types.maybeNull(types.string), //2nd captcha v3 key
        currencies: types.maybeNull(types.array(types.string)), //array of currencies
        status: SiteStatuses, //For app internal flags
        miniBanners: MiniBanners, //for advertising on pre/live pages.
        popup: Popup, //for pupup message (store only last one)
        socialIcons: SocialIcons,
        slotBattlesConfig: types.optional(SlotBattlesConfig, {}),
        footerBtns: types.optional(FooterBtnsConfig, {}),
        crashGames: types.optional(CrashGamesConfig, {}),
    })
    .actions((self) => ({
        _set(data) {
            if (!!data.isMaintenance) {
                self.status.setGlobalError(true, 'MAINTENANCE_ERROR');
                return;
            }
            self.languages = data.languages;
            self.siteName = data.siteName;
            self.siteUrl = data.siteUrl;
            self.registration = data.registration;
            self.captchaV3 = data.captcha_v3 || null;
            self.currencies = data.currencies;
            self.email = data.email;
            self.isVaixEnabled = !!data.isVaixEnabled;
            self.liveChat = data.liveChat || null;
            self.defaultCustomerGroup = data.group;
            if (data.slotBattle?.activeBattleRooms) {
                getRoot(self).slotsBattles.setActiveBattleRoomsCount(data.slotBattle.activeBattleRooms);
            } else {
                getRoot(self).slotsBattles.setActiveBattleRoomsCount(null);
            }
            self.slotBattlesConfig = {
                slotBattlesDisabled: !data.slotBattle?.canSlotBattle,
                timeToStart: data.slotBattle?.time.start,
                timeToPlay: data.slotBattle?.time.play,
                timeToWait: data.slotBattle?.time.winWait,
            };
            self.bonusesCount = parseInt(data.bonusesCount) || 0;
            if (self.socialIcons === 'GENERAL_ERROR') {
                self.status.setGlobalError(true);
            } else {
                self.socialIcons.setIcons(data.socialIcons);
            }
            getRoot(self).user.setCustomerGroup(data.group);
            if (data.footerFeatured && data.footerFeatured !== 'GENERAL_ERROR') {
                self.footerBtns.setConfig(data.footerFeatured);
            }

            if (data.crashGames && data.crashGames !== 'GENERAL_ERROR') {
                self.crashGames.setGames(data.crashGames);
            } else {
                self.crashGames.setMenuItemDisabled(true);
            }
        },

        getConfig: flow(function* fetch() {
            yield llattempt(
                () =>
                    api.config.get().then((response) => {
                        if (response.success) {
                            self._set(response.data);
                        } else {
                            self.status.setGlobalError(true, 'GENERAL_ERROR');
                        }
                    }),
                {
                    withNotifier: false,
                    at: 'SiteConfig.getConfig',
                    withParams: {},
                    onError: () => {
                        self.status.setGlobalError(true, 'GENERAL_ERROR');
                    },
                }
            );
        }),

        setGlobalError(errorStatus, errorEvent = null, errorText) {
            self.status.setGlobalError(errorStatus, errorText);
            errorStatus && console.error('Website general error', errorEvent);
        },
    }));

export default SiteConfig;
