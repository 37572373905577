import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { withCommon } from '../../../components';
import PromoButtons from './promo-buttons';
import PromoPageWrp from './promo-page-wrp';
import {getS3ImageUrl} from "../../../common/utils";
import {urls} from "../../../configs";


/**
 * @desc Represents React Component to render the cards list
 * @memberOf PromoPage
 *
 * @param {array} promos - list of available bonuses
 * @param {boolean} mobileUserAgent - detect mobile version
 *
 * @author I.Mykhailychenko
 */

const PromoCards = withCommon({
    store: ({ store }) => ({
        promos: store.promos.list,
        mobileUserAgent: store.user.mobileUserAgent,
        customerGroup: store.user.customerGroup,
    }),
    propTypes: {
        promos: PropTypes.arrayOf(
            PropTypes.shape({
                descr: PropTypes.string,
                shortDescr: PropTypes.string,
                icon: PropTypes.string,
                id: PropTypes.number.isRequired,
                idt: PropTypes.string.isRequired,
                imgDesktop: PropTypes.string.isRequired,
                imgMobile: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                ord: PropTypes.number,
                rules: PropTypes.string,
            }).isRequired
        ).isRequired,
    },
    defaultProps: {
        promos: [
            {
                descr: '',
                rules: '',
                shortDescr: '',
                icon: '',
                ord: 1,
            },
        ],
    },
    defaultName: 'PromoCards',
    isObserver: true,
})(({ promos, mobileUserAgent, customerGroup }) => {
    return (
        <PromoPageWrp isEmpty={!promos?.length}>
            <ul className="promo-content__cards" >
                {promos.map((card) => {
                    const cardImage = mobileUserAgent ? card.imgMobile : card.imgDesktop;
                    return (
                    <li key={card.id} className={'promo-content__item'}>
                        <div
                            className={
                                card.icon
                                    ? 'promo-content__inner'
                                    : 'promo-content__inner--empty'
                            }
                        >
                            {card.icon ? (
                                <div className="promo-content__inner-icon-wrp">
                                    <i className={cx(card.icon)} />
                                </div>
                            ) : null}

                            <div
                                className={
                                    card.shortDescr
                                        ? 'promo-content__inner-title'
                                        : 'promo-content__inner-title--empty'
                                }
                                dangerouslySetInnerHTML={{ __html: card.name }}
                            />

                            <img
                                className="promo-content__inner-img"
                                src={getS3ImageUrl() + '/images/bonuses/' + customerGroup + (!mobileUserAgent ? '/desktop/' : '/mobile/') + cardImage}
                                alt=""
                            />

                            {card.shortDescr ? (
                                <div className="promo-content__inner-descr"
                                   dangerouslySetInnerHTML={{ __html: card.shortDescr }}
                                />
                            ) : null}
                        </div>

                        <PromoButtons id={card.id} rules={card.rules} isRequestEnabled={card.isRequestEnabled} />
                    </li>
                )})}
            </ul>
        </PromoPageWrp>
    );
});

export default PromoCards;
