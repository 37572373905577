import Base from '../base';
import apiUrls from '../api-urls';

export default class ConfigAPI extends Base {
    async get(data) {
        return this.apiClient.post(apiUrls.global.config, data);
    }

    async getMiniBanners(data) {
        return this.apiClient.post(apiUrls.global.miniBanners, data);
    }

    async changeLanguage(data) {
        return this.apiClient.post(apiUrls.global.language, data);
    }
}
