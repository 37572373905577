import React, {useEffect, useRef, useState, useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import Switch from '@mui/material/Switch';
import cx from 'classnames';


import {
    LLDateRangePicker,
    LLTable,
    LLBtn2,
    BetsList,
    LLPreloader,
    WithDropdown,
    WithPopoverCashout,
    WithPopover,
} from "../../../../components";
import {BetDeleteModal, DrawerCloseButton} from '../../components';
import urls from '../../../../configs/urls';
import {formatNumber, isCombinedMatch} from "../../../../common/utils";
import {CashoutModal} from '../../../../pages/sport/desktop/right-panel/components';
import trans from '../../../../common/utils/trans';
import {CashoutPopupContent} from '../../../../components/elements/bets-list/cashout-popup-content';
import {Link} from "react-router-dom";

/**
 * @file Represents React Component to render Betting History Page of MyCabinet
 * @memberOf MyAccountPage
 *
 * @param {function} setModal - function to open modal
 * @param {function} getBetHistory - function to get data for bet history
 * @param {function} getBets - data array of bet history reports objects
 * @param {array} betsStatuses - array of bets statuses for the status filter
 * @param {boolean} isAnyBetCanBeDeleted - description
 * @param {object} storeItem - object in store for bet history (needed for date range picker)
 * @param {('mobile'|'tablet'|'desktop')} viewSize - modifier for css classes
 * @param {string} language - current language
 *
 * @author A.Huskova
 */

const BetHistory = ({store}) => {
    const setModal = store.modal.setModal,
        getBetHistory = store.user.userBets.betHistory.getBetHistory,
        statusFilter = store.user.userBets.statusFilter,
        setStatusFilter = store.user.userBets.setStatusFilter,
        betsStatuses = store.user.userBets.betHistory.betsStatuses,
        betsType = statusFilter === 'activeBets' ? 'activeBets' : 'betHistory',
        isTableLoading = store.user.userBets[betsType].isTableLoading,
        getBets = store.user.userBets[betsType].getBets,
        storeItem = store.user.userBets[betsType],
        activeBetId = store.user.userBets[betsType].activeBetId,
        dateRange = store.user.userBets.betHistory.dateRange,
        viewSize = store.site.status.viewSize,
        language = store.user.language,
        currency = store.user.currency,
        userCanCashout = store.user.canCashout,
        mobileUserAgent = store.user.mobileUserAgent;

    useEffect(() => {
        if (viewSize === 'desktop') {
            setStatusFilter('All');
            storeItem.setActiveBet(null);
        }
        return () => {
            storeItem.setActiveBet(null)
        };
    }, []);

    const [isAnyBetCanBeDeleted, setAnyBetCanBeDeleted] = useState(false);

    /**
     * @crutch
     *
     * @desc - function below check click,
     *         if it was a click on the row or inner children of the row,
     *         because DevExtreme reverts normal event bubbling :'(
     * @author A.Huskova
     */
    const checkEventTarget = (target, targetClassname, currentTargetClassnames) => {
        if (target) {
            if (target.className.includes(targetClassname)) {
                return true;
            } else if (target.className.includes(currentTargetClassnames)) {
                return false;
            } else {
                return checkEventTarget(
                    target.parentElement,
                    targetClassname,
                    currentTargetClassnames
                );
            }
        } else {
            console.error('error with nesting');
        }
    };

    const noDataText = 'CABINET_BET_HISTORY_NO_DATA_TEXT';
    const detailsRef = useRef(null);

    const betHistoryDetailItemTableConfig = {
        desktopGridProps: {
            repaintChangesOnly: true,
            ref: detailsRef,
            paging: {
                enabled: true,
                pageSize: 100,
            },
            noDataText: 'BET_DETAILS_NO_DATA',
            onContentReady: (e) => {
                if (e.component.getDataSource()?.items()?.length) {
                    const match = e.component.getDataSource()?.items()[0];

                    if (match?.customOdds > 0) {
                        e.component.columnOption('odds', 'visible', false);
                        document.querySelector('#bet-history-detail-table')
                            .classList.add('bet-history-detail-table--with-summary');
                    }

                    if (!storeItem.activeBet.isBetHasLiveMatch) {
                        e.component.columnOption('currentScore', 'visible', false);
                        e.component.columnOption('currentStatus', 'visible', false);
                    }

                    if (match && isCombinedMatch(match.matchId)) {
                        e.component.columnOption('oddsType', 'visible', false);
                        e.component.columnOption('oddsTypeSelection', 'visible', false);
                    }
                }
            },
            onRowPrepared: (e) => {
                if (e.data?.status) {
                    e.rowElement.classList.add(
                        `table-row__bet-detail--${e.data?.status}`
                    );
                }

                if (e.data?.type === 'LiveMatch') {
                    if (e.component.columnOption('type', 'width') !== 130) {
                        e.component.columnOption('type', 'width', 130);
                    }
                }
            },
        },
        desktop: {
            id: 'bet-history-detail-table',
            noPagination: true,
            customTotal: (data) => data.length && data[0]?.customOdds > 0 ? {
                title: 'Odds',
                calcCustom: data.length ? data[0]?.customOdds : 0,
            } : null,
            columns: [
                {
                    key: 'rowChars',
                    title: trans('Row'),
                    sortOrder: 'asc',
                    width: 50,
                    visible: false,
                },
                {
                    key: 'date',
                    title: trans('Date'),
                    format: 'dateTimeWithoutYear',
                    width: 110,
                    sortOrder: 'asc',
                },
                {
                    key: 'type',
                    title: trans('Type'),
                    width: 50,
                    render: (options) => <WithPopover
                        popoverClasses="ll-popover__text"
                        dropDownRole={true}
                        popoverContent={<span>
                            {trans(options.value === 'PreMatch' ?
                                'Pre Match'
                                :
                                options.value === 'LiveMatch' ?
                                    'Live Match'
                                    :
                                    options.value === 'OutRight' ?
                                        'MENU_OUTRIGHTS'
                                        :
                                        ''
                            )}
                    </span>}
                    >
                        <p>{(options.value === 'PreMatch' || options.value === 'Combined') ?
                            'PM'
                            :
                            options.value === 'LiveMatch' ?
                                `LM ${options.row.data.timeInfo} ${options.row.data.score ? ' / ' + options.row.data.score : ''}`
                                :
                                options.value === 'OutRight' ? 'O'
                                    :
                                    ''}
                        </p>
                    </WithPopover>
                },
                {
                    key: 'sportId',
                    width: 60,
                    title: trans('Sport'),
                    render: (options) => <WithPopover
                        popoverClasses="ll-popover__text"
                        dropDownRole={true}
                        popoverContent={<span>{options.row.data?.sportName}</span>}
                    >
                        <i className={`sport_icon_font-${options.value}`}></i>
                    </WithPopover>
                },
                {
                    key: 'name',
                    minWidth: 150,
                    title: trans('BET_DETAILS_EVENT'),
                    render: (options) => (
                        options?.data?.url ?
                            <Link
                                to={options?.data?.url}
                                className="data-table__cell--link"
                            >
                                <span>{options?.data?.name}</span>
                            </Link>
                            :
                            <span>{options?.data?.name}</span>
                    )
                },
                {
                    key: 'currentScore',
                    title: trans('Score'),
                    width: 80,
                    render: (options) => (
                        <span className={options?.data?.className}>
                            {(options?.data?.isLive) ?
                                <i title="Live Match"
                                   className="doruk-live-match-01 coupons__live-indicator"
                                />
                                : null
                            }
                            {options?.data?.currentScore}
                        </span>
                    )
                },
                {
                    key: 'currentStatus',
                    title: trans('Coupon Status'),
                    width: 70,
                    render: (options) => (
                        <span className={options?.data?.className}>
                            {options?.data?.currentStatus}
                        </span>
                    )
                },
                {key: 'oddsType', title: trans('Option')},
                {key: 'oddsTypeSelection', title: trans('Selection')},
                {key: 'odds', title: trans('Odds'), width: 70, format: 'odds'},
                {
                    key: 'matchId',
                    title: trans('Result'),
                    width: 60,
                    render: (options) => (
                        <p
                            className="data-table__cell--bet-result-link"
                            onClick={() =>
                                setModal({
                                    active: true,
                                    showHeader: true,
                                    currentKey: 'external-link',
                                    showCloseButton: true,
                                    showFullscreenButton: true,
                                    externalLink: `${urls.prematchStatistics}${language}/match/${options.value}`,
                                    title: `<p>
                                        <span>${
                                        options?.data && options?.data?.name
                                            ? options.data.name
                                            : ''
                                    }</span>
                                        <span>${trans('MATCH_STATISTICS_MODAL_HEADER')}</span>
                                    </p>`,
                                })
                            }
                        >
                            <i className="doruk-result"/>
                        </p>
                    ),
                },
            ],
        },
    };

    const betHistoryTableConfig = useMemo(() => ({
        desktopGridProps: {
            keyExpr: "id",
            noDataText,
            repaintChangesOnly: true,

            /**
             * @crutch
             * @desc - Expand/collapse row and showing details info about bet
             * @author A.Huskova
             */
            onRowClick: (e) => {
                const isDeleteBtnPressed =
                    checkEventTarget(
                        e.event.target,
                        'js_bet-history__delete-cell',
                        'table-row--clickable'
                    );
                const isCashoutBtnPressed =
                    checkEventTarget(
                        e.event.target,
                        'js_bet-history__cashout-cell',
                        'table-row--clickable'
                    );
                if (
                    !e.event.currentTarget?.className.includes('dx-master-detail-row')
                    && !(isDeleteBtnPressed || isCashoutBtnPressed)
                ) {
                    if (e.component.isRowExpanded(e.data.id)) {
                        e.component.collapseRow(e.data.id);
                        storeItem.setActiveBet(null);
                    } else {
                        e.component.collapseAll(-1);
                        const res = storeItem.setActiveBet(e.data.id);
                        const expand = (id) => {
                            if (storeItem.activeBetId) {
                                e.component.selectRowsByIndexes(
                                    e.component.getRowIndexByKey(id)
                                );
                                e.component.expandRow(id);
                            }
                        };
                        if (res) {
                            res.then(() => expand(e.data.id));
                        } else {
                            expand(e.data.id);
                        }
                    }
                }
            },
            onRowPrepared: (e) => {
                e.rowElement.classList.add('table-row--clickable');
                if (e.data?.status)
                    e.rowElement.classList.add(
                        `table-row__bet--${e.data?.statusEn}`
                    );
            },
            onContentReady: (e) => {
                setAnyBetCanBeDeleted(
                    e.component.getVisibleRows()
                        .some((item) => item.data?.isCanBeDeleted || item.data?.cashout)
                )
            }
        },
        desktop: {
            columns: [
                {
                    key: 'id',
                    title: trans('Coupon number'),
                    width: 110,
                    allowHeaderFiltering: false,
                },
                {
                    key: 'matchTotal',
                    title: trans('BET_DETAILS_EVENT'),
                    width: 140,
                    allowHeaderFiltering: !storeItem.activeBetId,
                    render: (options) =>
                        options.row.data.couponTotal > 1
                            ? `${options.value} (${options.row.data.couponTotal})`
                            : options.value,
                },
                {
                    key: 'stake',
                    title: trans('Stake'),
                    format: 'currency',
                    width: 150,
                    allowHeaderFiltering: false,
                },
                {
                    key: 'payout',
                    title: trans('Payout'),
                    format: 'currency',
                    width: 150,
                    allowHeaderFiltering: false,
                },
                {
                    key: 'paid',
                    title: trans('Paid'),
                    format: 'currency',
                    width: 150,
                    allowHeaderFiltering: false,
                },
                {
                    key: 'dateTime',
                    title: trans('Date'),
                    format: 'dateTimeWithSeconds',
                    allowHeaderFiltering: false,
                },
                {
                    key: 'status',
                    title: trans('Status'),
                    format: 'capitalize',
                    width: 120,
                    allowHeaderFiltering: !storeItem.activeBetId,
                },
                {
                    key: 'isCanBeDeleted',
                    title: ' ',
                    allowHeaderFiltering: false,
                    width: 120,
                    cssClass: 'bet-history__action-cell',
                    visible: isAnyBetCanBeDeleted,
                    render: (options) => {
                        const currentBet = storeItem.getBet(options.row.data.id);
                        if (currentBet?.isCanBeDeleted || currentBet?.cashout) {
                            return currentBet.isCanBeDeleted ? (<>
                                    {mobileUserAgent ? (<div
                                        className="js_bet-history__delete-cell"
                                        onClick={(e) => {
                                            setModal({
                                                active: true,
                                                maskBackground: true,
                                                showHeader: false,
                                                currentKey: "custom-content",
                                                customContent: (
                                                    <BetDeleteModal
                                                        type="betHistory"
                                                        id={options.row.data.id}
                                                    />
                                                )
                                            });
                                        }}
                                    >
                                        <i className="doruk-delete-01 bet-delete-icon"/>
                                    </div>) : (
                                        <WithPopoverCashout
                                            style={{bottom: "8px"}}
                                            popoverClasses="user-bets__popper-delete bet-history__popper-delete"
                                            placement="top-end"
                                            dropDownRole={true}
                                            triggeredOnHover={false}
                                            betId={currentBet.id}
                                            listType="betHistory"
                                            watch="delete"
                                            popoverContent={
                                                <div className="user-bets__popper-delete-message">
                                                    <CashoutPopupContent contentType={"delete"}
                                                                         type="betHistory"
                                                                         id={options.row.data.id}/>
                                                </div>
                                            }
                                        >
                                            <div
                                                className="js_bet-history__delete-cell bet-history__delete-cell"
                                            >
                                                <i className="doruk-delete-01 bet-delete-icon"/>
                                            </div>
                                        </WithPopoverCashout>)
                                    }
                                </>)
                                :
                                currentBet?.cashout ? (<>
                                    {mobileUserAgent ? (
                                        <LLBtn2
                                        className="js_bet-history__cashout-cell ll-btn2 bet-history__cashout-cell-btn"
                                        isLoading={currentBet?.cashoutLoding}
                                        onClick={() =>
                                            storeItem.checkCashout({id: options.row.data.id}).then(() =>
                                                setModal({
                                                    active: true,
                                                    maskBackground: true,
                                                    showHeader: false,
                                                    currentKey: "custom-content",
                                                    customContent:
                                                        <CashoutModal
                                                            type="betHistory"
                                                            bet={storeItem.getBet(options.row.data.id)}
                                                        />
                                                }))
                                        }>
                                            <span>
                                                {storeItem.isRefreshedBehavior ?
                                                    formatNumber(currentBet?.cashout, 2, language, currency)
                                                    : trans('CashOut')
                                                }
                                            </span>
                                        </LLBtn2>) : (
                                            <WithPopoverCashout
                                                style={{bottom: "8px"}}
                                                popoverClasses="user-bets__popper-cashout  bet-history__popper-cashout"
                                                placement="top-end"
                                                dropDownRole={true}
                                                triggeredOnHover={false}
                                                betId={currentBet.id}
                                                listType={"betHistory"}
                                                watch="cashout"
                                                popoverContent={
                                                    <div className="user-bets__popper-cashout-message">
                                                        <CashoutPopupContent
                                                            contentType={"cashout"}
                                                            type={"betHistory"}
                                                            bet={storeItem.getBet(options.row.data.id)}
                                                        />
                                                    </div>
                                                }
                                            >
                                                <LLBtn2
                                                    className="js_bet-history__cashout-cell ll-btn2 bet-history__cashout-cell-btn"
                                                    isLoading={currentBet?.cashoutLoding}
                                                >
                                                    <span>
                                                        {storeItem.isRefreshedBehavior ?
                                                            formatNumber(currentBet?.cashout, 2, language, currency)
                                                            : trans("CashOut")
                                                        }
                                                    </span>
                                                </LLBtn2></WithPopoverCashout>)
                                        }</>)
                                    :
                                    null
                        }
                    },
                },
            ],
            headerFilter: {
                visible: true,
            },
            buildSubItem: () => {
                const isSystemCoupon = storeItem.activeBet?.betDetailsGroups?.length > 1;
                if (isSystemCoupon) {
                    betHistoryDetailItemTableConfig.desktop.columns[0] =
                        {key: 'key', title: trans('Row'), sortOrder: 'asc', width: 50};
                }
                return storeItem.activeBet?.betsDetailsError ?
                    <div className="data-table--empty">
                        {trans(storeItem.activeBet?.betsDetailsError)}
                    </div>
                    :
                    <div className="bet-history-detail">
                        <LLTable
                            data={isSystemCoupon ?
                                storeItem.activeBet?.betDetailsUniqueMatches
                                :
                                storeItem.activeBet?.betDetailsMatches
                            }
                            customConfig={betHistoryDetailItemTableConfig}
                            name="bet-details"
                        />
                        <div className="bet-history-detail__info">
                            {!storeItem.activeBet?.betDetailsMatches[0].customOdds &&
                                storeItem.activeBet?.betDetailsGroups.map(group => (
                                    <p className="bet-history-detail__info-row" key={group.key}>
                                        {isSystemCoupon &&
                                            <span className="bet-history-detail__info-td">
                                                <span>{trans('Combination')}: </span>
                                                <span className={cx(
                                                    'bet-history-detail__info-combination',
                                                    group.status
                                                )}>{group.key}</span>
                                            </span>
                                        }
                                        <span className="bet-history-detail__info-td">
                                            {trans("Stake")}: {
                                            formatNumber(group.sortedMatches[0].stake, 2, language, currency)
                                        }
                                        </span>
                                        <span className="bet-history-detail__info-td">
                                            {trans("Payout")}: {
                                            formatNumber(group.sortedMatches[0].payout, 2, language, currency)
                                        }
                                        </span>
                                    </p>
                                ))
                            }
                        </div>
                    </div>;
            },
        },
    }), [storeItem.activeBet, statusFilter, isAnyBetCanBeDeleted]);

    return (
        <div className={`bet-history--${viewSize} my-account__content-container`}>
            {viewSize === 'desktop' && (
                <p className="my-account__title">
                    <span className="my-account__title-text">
                        {trans('betting-history')}
                    </span>
                    <DrawerCloseButton/>
                </p>
            )}

            {viewSize === 'desktop' &&
                <div className="my-account__date-picker-wrapper">
                    <LLDateRangePicker
                        actionHandler={() => getBetHistory({needLoading: true})}
                        storeItem={storeItem}
                        mobileDateRange={dateRange}
                    />
                </div>
            }

            {viewSize !== "desktop" ? (
                <>
                    <div className="ll-drawer__header--mobile">
                        <span
                            className={`bet-history__status-active-bets${statusFilter !== "activeBets" ? "--disabled" : ""}`}>{trans("activeBets")}</span>
                        <Switch
                            classes={{
                                root: "status-filter-switcher",
                                thumb: "status-filter-switcher-thumb",
                                track: "status-filter-switcher-track",
                                switchBase: "status-filter-switcher-switch-base"
                            }}
                            checked={statusFilter !== "activeBets"}
                            onChange={() => {
                                setStatusFilter(statusFilter !== "activeBets" ? "activeBets" : "All");
                                storeItem.setActiveBet(null);
                            }}
                        />
                        <WithDropdown
                            dataSource={{
                                submenu: [...betsStatuses]
                            }}
                            openBehavior={statusFilter !== "activeBets" ? "click" : ""}
                            menuItemIsActive={(item) =>
                                item.code === statusFilter
                            }
                            onMenuItemClick={(item) => {
                                setStatusFilter(item.code);
                            }}
                            menuItemIsDisabled={(item) => !item.isActive}
                            customClasses={`bet-history__status-filter${statusFilter === "activeBets" ? "--disabled" : ""}`}
                            menuClasses="bet-history__status-filter-dropdown"
                        >
                            <div className="ll-dropdown-menu-item">
                                {betsStatuses.find(status => status.code !== "All" && status.code === statusFilter)?.label ?? trans("My Bets")}
                            </div>
                        </WithDropdown>
                        <DrawerCloseButton/>
                    </div>
                    {statusFilter !== "activeBets" &&
                        <div className="bet-history__date-picker-wrapper">
                            <LLDateRangePicker
                                actionHandler={() => getBetHistory({needLoading: true})}
                                storeItem={storeItem}
                                mobileDateRange={dateRange}
                            />
                        </div>
                    }
                    <BetsList
                        storeItem={storeItem}
                        betsError={storeItem.betsError}
                        bets={getBets}
                        activeBetId={activeBetId}
                        listType={betsType}
                        noDataText={noDataText}
                        loading={isTableLoading}
                        language={language}
                        currency={currency}
                        userCanCashout={userCanCashout}
                        setModal={setModal}
                        isMobile={mobileUserAgent}
                    />
                </>
            ) : (
                <div className="my-account__table-box">
                    {isTableLoading ?
                        <div className="my-account__loader-box">
                            <LLPreloader/>
                        </div> :
                        storeItem.betsError ?
                            <div className="data-table--empty">
                                {trans(storeItem.betsError)}
                            </div>
                            :
                            <LLTable
                                data={getBets}
                                customConfig={betHistoryTableConfig}
                                dependenciesForRerender={[isAnyBetCanBeDeleted, storeItem.activeBetId]}
                                name="bet-history"
                            />
                    }
                </div>
            )}
        </div>
    );
};

export default inject('store')(observer(BetHistory));