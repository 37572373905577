import React, {useContext, useEffect} from 'react';
import cx from 'classnames';

import {LLElementTypeSetter} from '../../../../components';
import {trans} from '../../../../common/utils';
import SlotsCasinosGamesSearch from "../games-search";
import {GamesContext} from '../../slots-and-casinos';
import config from "../../../../static/themes/config";
import {API_PREFIX} from "../../../../common/api/api-url-config";
import apiUrls from "../../../../common/api/api-urls";


const SlotCasinoMobileMenu = (
    {
        siteName,
        isMobileSubSubMenuVisible,
        setMobileSubSubMenuVisible,
        createMenuItemConfig,
        activeProvider,
        isItemActive,
        currentView,
        activeType,
        slotsCasinoGames,
        collectActivity,
        isLogged,
        jackpotGroupsList
    }
) => {
    const {
        mode,
        setMode,
        filtering,
        setSorting,
        activeGameMenuItem,
        setActiveGameMenuItem,
        activeSubSubMenuItem,
        setActiveSubSubMenuItem,
        router,
        gamesType,
    } = useContext(GamesContext) ?? {};

    useEffect(() => {
        setActiveGameMenuItem('');
        setActiveSubSubMenuItem('');
    }, [router.location.pathname]);

    const renderMobileUpperMenuItem = (item) => {
        const itemConfig = createMenuItemConfig(item);

        if (!isLogged && ["recently-played", "favorite"].includes(item.type)) {
            return null;
        }

        if (!jackpotGroupsList.length && ["jackpots"].includes(item.type)) {
            return null;
        }

        const isItemDisabled = (item.type === 'gameType' &&
            !slotsCasinoGames.providerHasSubGameGroups({
                type: gamesType,
                provider: activeProvider
            })) || ((currentView.includes('popular') || currentView.includes('new')) && item.type === 'sortBy');

        return (
            <LLElementTypeSetter
                key={item.type}
                className={cx(
                    "games-menu__submenu-item",
                    !!(Array.isArray(item.submenu) && item.submenu
                        .find(subItem => JSON.stringify(subItem.sort) ===
                            JSON.stringify(filtering.sort))) || currentView.includes(item.type) ? 'active' : '',
                    isItemDisabled ? 'games-menu__submenu-item--disabled' : '',
                )}
                {...itemConfig.itemAttributes}
                as={item.as}
                onClick={() => {
                    if (!isItemDisabled) {
                        if (!item.as) {
                            setActiveSubSubMenuItem(item);
                            setMobileSubSubMenuVisible(true);
                        } else {
                            setActiveSubSubMenuItem('');
                        }
                    }
                }}
            >
                <i className={`${item.icon} games-menu__submenu-item-icon`}/>
                <span className="games-menu__submenu-item-label">
                    {trans(item.name)}
                </span>
            </LLElementTypeSetter>
        );
    }

    const renderMobileSubSubMenu = (item) => {
        const itemConfig = createMenuItemConfig(item);
        return (
            <>
                <div className="games-menu__submenu-header">
                    <i className={`${item.icon} games-menu__submenu-item-icon`}/>
                    <span className="games-menu__submenu-item-label">
                        {trans(item.name)}
                    </span>
                    <i className="doruk-close games-menu__submenu-header-close-btn"
                       onClick={() => {
                           setActiveSubSubMenuItem('');
                           setMobileSubSubMenuVisible(false);
                       }}
                    />
                </div>
                <div className="games-menu__submenu-inner-wrp games-menu__submenu-content">
                    {
                        itemConfig.submenu?.map(item => {
                            const elementAttributes = item.elementAttributes ?? {};
                            return (
                                <LLElementTypeSetter
                                    key={item.name}
                                    className={cx(
                                        "games-menu__submenu-item",
                                        isItemActive({item, activeProvider, currentSorting: filtering.sort}) ?
                                            'active' : ''
                                    )}
                                    {...elementAttributes}
                                    onClick={() => {
                                        if (JSON.stringify(item.sort) === JSON.stringify(filtering.sort)) {
                                            setSorting();
                                        } else {
                                            setSorting(item.sort);
                                        }
                                        setActiveGameMenuItem('');
                                        setActiveSubSubMenuItem('');
                                        setMobileSubSubMenuVisible(false);
                                    }}
                                >
                                    {!!item.icon &&
                                        <i className={`${item.icon} games-menu__submenu-item-icon`}/>
                                    }
                                    {item.hasImage ?
                                        <img
                                            className="games-menu__submenu-item-img"
                                            src={item.imgLink}
                                            alt={item.name}
                                        />
                                        :
                                        !item.icon ?
                                            <i className="llg-question slots-casino-menu__provider-without-img"/>
                                            : null
                                    }
                                    <span className="games-menu__submenu-item-label">
                                        {trans(item.name)}
                                    </span>
                                </LLElementTypeSetter>
                            );
                        })
                    }
                </div>
            </>
        )
    }

    const headerItems = currentView.reduce((acc, item) => {
        if (['gameType', 'provider', 'search'].includes(item)) {
            return acc;
        } else {
            return [...acc, item];
        }
    }, [])

    const getHeaderItemMarkup = (item) => {
        const itemConf = config(siteName).slotsCasinoMobileMenu.find(menuItem => menuItem.type.toLowerCase() === item);
        return (itemConf ? <>
                <i className={`${itemConf.icon} games-menu__filter-header-icon`}/>
                <span className="">
                    {trans(itemConf.name)}
                </span>
            </> : null
        );
    }

    const getActiveSorting = () => {
        const sortConfig = config(siteName).slotsCasinoMobileMenu.find(menuItem => menuItem.type === 'sortBy');
        const activeSort = sortConfig.submenu.find(
            (subItem) =>
                JSON.stringify(subItem.sort) ===
                JSON.stringify(filtering.sort)
        );
        return (activeSort ? <>
                <i className={`${activeSort.icon} games-menu__filter-header-icon`}/>
                <span className="">
                    {trans(activeSort.name)}
                </span>
            </> : null
        );
    };

    return (
        <div className="games-menu--mobile">
            <div className="games-menu__inner-box">
                <SlotsCasinosGamesSearch
                    isMobile={true}
                />
                { gamesType === 'slots' &&
                    <div
                        className={cx("games-menu__btn games-menu__btn--mode", !mode && "games-menu__btn--checked")}
                        onClick={() => setMode((v) => +!v)}
                    >
                        <i className="llg-money"/>
                        {!mode ?
                            <span className="games-menu__btn-text">{trans('REAL_PLAY_BTN')}</span>
                            :
                            <span className="games-menu__btn-text">{trans('FUN_PLAY_BTN')}</span>
                        }
                    </div>
                }
                <LLElementTypeSetter
                    className="games-menu__btn"
                    href={`${API_PREFIX}${apiUrls.games.random}?type=${gamesType === 'slots' ? 'slot' : 'casino'}&demo=0`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        collectActivity({area: `${gamesType}-random-button`, itemId: 1, interactTarget: 'random-button'});
                    }}
                >
                    <i className="doruk-random-arrows"/>
                    <span>{trans('Random Game')}</span>
                </LLElementTypeSetter>
                <div className={cx(
                    "games-menu__btn",
                    !!activeGameMenuItem && "games-menu__btn--active"
                )}
                     onClick={() => {
                         if (activeGameMenuItem) {
                             setActiveGameMenuItem('');
                             setMobileSubSubMenuVisible(false);
                         } else {
                             setActiveGameMenuItem('filters');
                         }
                     }}
                >
                    <i className="doruk-filters"/>
                    <span>{trans('Filters')}</span>
                </div>
            </div>
            { ( headerItems.length || activeType || activeProvider || getActiveSorting() ) ?
                <div className="games-menu__filter-header">
                    {headerItems.length ?
                        headerItems.map(item => (
                            <span className="games-menu__filter-header-item" key={item}>
                            {getHeaderItemMarkup(item)}
                        </span>
                        ))
                        : null
                    }
                    {activeType ?
                        <span className="games-menu__filter-header-item">{activeType}</span>
                        : null
                    }
                    {activeProvider ?
                        <span className="games-menu__filter-header-item">{trans(activeProvider)}</span>
                        : null
                    }
                    {getActiveSorting()}
                </div>
                : null
            }
            { (activeGameMenuItem === 'filters' && !isMobileSubSubMenuVisible) &&
                <div className="games-menu__submenu-box">
                    <div className="games-menu__submenu-inner-wrp">
                        {
                            config(siteName).slotsCasinoMobileMenu.map(
                                (item) => renderMobileUpperMenuItem(item)
                            )
                        }
                    </div>
                </div>
            }
            {isMobileSubSubMenuVisible && activeSubSubMenuItem &&
                <div className="games-menu__submenu-box">
                    {renderMobileSubSubMenu(activeSubSubMenuItem)}
                </div>
            }
        </div>
    );
};

export default SlotCasinoMobileMenu;