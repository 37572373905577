import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withCommon, LLPreloader } from '../../../../components';
import PaymentItem from './components/payment-item';
import config from '../../../../static/themes/config';
import { DrawerCloseButton } from '../../components';

/**
 * @file Represents React Component to render container with payment methods
 * @memberOf MyAccountPage
 *
 * @param {Object} payments - store data of payment item
 * @param {array} [user.deposits.payments] - store data for deposits
 * @param {array} [user.withdraws.payments] - store data for withdraws
 * @param {function} [user.deposits.getPayments] - function to get request data for deposits
 * @param {boolean} [user.deposits.isTableLoading] - show loading if we are waiting for deposits request
 * @param {boolean} mobileUserAgent - flag is mobile layout
 * @param {('mobile'|'tablet'|'desktop')} viewSize - modifier for css classes
 *
 * @author A.Huskova
 */

const Payments = withCommon({
    store: ({ store }) => ({
        user: store.user,
        isTableLoadingDeposits: store.user.deposit.isTableLoading,
        isTableLoadingWithdraws: store.user.withdraws.isTableLoading,
        goBack: store.router.goBack,
        pathname: store.router.location.pathname,
        viewSize: store.site.status.viewSize,
        siteName: store.site.siteName,
    }),
    propTypes: {
        goBack: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['deposit', 'withdraws']),
        user: PropTypes.shape({
            deposits: PropTypes.shape({
                payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
                getPayments: PropTypes.func.isRequired,
            }),
            withdraws: PropTypes.shape({
                payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
                getPayments: PropTypes.func.isRequired,
            }),
        }),
    },
    defaultName: 'Payments',
    isTrans: true,
    isObserver: true,
})(({ siteName, viewSize, user, trans, type, pathname }) => {

    useEffect(() => {
        user[type].getPayments(config(siteName).payments[type].getDataFunctionName).then();
    }, [type]);

    return (
        <div className="payments">
            {(viewSize === 'desktop' && pathname !== '/account/deposit') && (
                <p className="my-account__title">
                    <span className="my-account__title-text">
                        {trans(config(siteName).payments[type].name)}
                    </span>
                    <DrawerCloseButton />
                </p>
            )}
            {user[type].isTableLoading ? (
                <div className="my-account__loader-box">
                    <LLPreloader />
                </div>
            ) : (
                <div
                    className={`my-account__content payments__content--${
                        viewSize === 'desktop' ? 'desktop' : 'mobile'
                    }`}
                >
                    {user[type].payments.length ? (
                        user[type].payments.map((item) => (
                            <PaymentItem key={item.id} item={item} type={type} />
                        ))
                    ) : (
                        <p className="payments__no-data">
                            {trans(config(siteName).payments[type].noDataMessage)}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
});

export default Payments;
