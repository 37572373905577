import {values} from 'mobx';
import {flow, getParent, getRoot, getType, types} from 'mobx-state-tree';
import orderBy from 'lodash/orderBy';

import api from '../../../../../common/api';
import {llattempt, trans} from '../../../../../common/utils';
import {llNotifier} from '../../../../../components';
import {TIMERS} from '../../../../config';
import {BetHistoryItem} from './';


window.activeBetsTimeout = null;
window.betHistoryTimeout = null;
window.betHistoryUpdateTimeout = null;
window.betDetailsTimeout = null;

let ERRORS_COUNT_LIMIT = 3;

const BetsTemplate = types
    .model('BetsTemplate', {
        activeBetId: types.maybeNull(types.number),
        bets: types.map(BetHistoryItem),
        dateRange: 0,
        isMounted: false,
        isInitialLoad: false,
        isRefreshedBehavior: true,
        couponWaitingLoading: false,
        errorsCount: 0,
        betsError: types.maybeNull(types.string),
    })
    .volatile((self) => ({
        statuses: [{label: trans('All'), code: 'All', isActive: true},
            {label: trans('Cashouted'), code: 'cashouted', isActive: false},
            {label: trans('Deleted'), code: 'deleted', isActive: false},
            {label: trans('Waiting'), code: 'waiting', isActive: false},
            {label: trans('Lost'), code: 'lost', isActive: false},
            {label: trans('Won'), code: 'won', isActive: false}],
    }))
    .actions((self) => ({
        set({data}) {
            self.bets.forEach((bet) => bet.setOld(true));
            self.statuses.forEach((status) => status.isActive = status.code === 'All');

            data.map(betData => {
                const betDataObject = {
                    id: betData.CouponID,
                    dateTime: new Date(betData.CouponDateTime?.replace(' ', 'T') + '+03:00'),
                    stake: betData.StakeTotal,
                    payout: betData.PayoutTotal,
                    paid: betData.PaidTotal,
                    matchTotal: betData.MatchTotal,
                    couponTotal: betData.CouponTotal,
                    matchType: betData.MatchType,
                    totalOdds: betData.TotalOdds,
                    isAllowedToDeleteFromBack: !!+betData.isAllowedToDelete,
                    isLoading: false,
                    isOld: false,
                    isOpened: self.activeBetId === betData.CouponID,
                };

                let currentBet = self.bets.get(betData.CouponID);

                if (currentBet?.wasCashouted) {
                    betDataObject.cashout = null;
                } else if (typeof betData.CashOut !== 'undefined' && betData.CashOut !== null) {
                    betDataObject.cashout = Math.floor((betData.CashOut) * 100) / 100;
                } else if (betData.CouponWaiting === 0 || betData.isCashout === 1 || betData.isDeleted === 1) {
                    betDataObject.cashout = null;
                }

                if (self.bets.has(betData.CouponID)) {
                    self.bets.get(betData.CouponID).update(betDataObject);
                } else {
                    currentBet = self.bets.put(betDataObject);
                }

                currentBet.setStatus(betData);

                if (betData.jsarr) {
                    self.setMatchesAdditionalProps({data: JSON.parse(betData.jsarr), currentBet});
                }

                const status = self.statuses.find(status => status.code === currentBet.statusEn);
                if (status) status.isActive = true;

                currentBet.setCanBeDeleted();
            });

            self.bets.forEach((bet) => {
                if (bet.isOld) {
                    if (self.activeBetId === bet.id) {
                        self.setActiveBet(null);
                    }
                    self.bets.delete(bet.id);
                }
            });
        },
        update({data}) {
            data.map(betData => {
                const currentBet = self.bets.get(betData.CouponID);
                if (currentBet) {
                    currentBet.update({
                        stake: betData.StakeTotal,
                        payout: betData.PayoutTotal,
                        paid: betData.PaidTotal ?? 0,
                        totalOdds: betData.TotalOdds,
                        cashout: (betData.CashOut && !currentBet.wasCashouted) ?
                            Math.floor(betData.CashOut * 100) / 100
                            : null,
                        isAllowedToDeleteFromBack: !!+betData.isAllowedToDelete,
                    });

                    if (betData.jsarr) {
                        self.setMatchesAdditionalProps({data: JSON.parse(betData.jsarr), currentBet});
                    }
                }
            });
        },
        setMatchesAdditionalProps({data, currentBet}) {
            data.map((match) => {
                if (match.IsCurrentlyPre) {
                    currentBet.setMatchesAdditionalProps({url: `/sport/1/${match.sid}/${match.cid}/${match.tid}/${match.mid}`, matchId: match.mid})
                }
                if (match.IsCurrentlyLive === true) {
                    currentBet.setMatchesAdditionalProps({url:`/sport/live/${match.sid}/${match.cid}/${match.tid}/${match.mid}`, matchId: match.mid, score: match.ActualMatchScore, status: match.ActualShortStatus})
                    //    Don't remove else if here, because flag can be undefined
                } else if (match.IsCurrentlyLive === false) {
                    currentBet.setMatchesAdditionalProps({url: null, matchId: match.mid, score: null, status: null})
                }
            })
        },
        setActiveBet(betId) {
            if (self.activeBetId === betId || !betId) {
                self.activeBetId = null;
                self.getBet(betId) && self.getBet(betId).setOpened(false);
            } else {
                self.activeBetId = betId;
                self.getBet(betId) && self.getBet(betId).setOpened(true);

                if (getRoot(self).site.status.viewSize !== 'desktop') {
                    self.getBetHistoryDetails(true).then();
                } else {
                    return self.getBetHistoryDetails(true);
                }
            }
        },
        setRange(range) {
            self.dateRange = range;
        },
        setDate({startDate, endDate}) {
            if (startDate) self.startDate = startDate;
            if (endDate) self.endDate = endDate;
            self.fetchTime = null;
        },
        setInitialLoad(flag) {
            self.isInitialLoad = flag;
        },
        setError(error = 'TICKETS_UPDATE_ERROR') {
            self.betsError = error;
        },
        isErrorsLimitReached(error) {
            if (!error) {
                self.betsError = null;
                self.errorsCount = 0;
            } else {
                self.errorsCount = self.errorsCount++;
            }
            if (self.errorsCount >= ERRORS_COUNT_LIMIT) {
                self.setError(error);
                return true;
            }
            return false;
        },
        setMounted(flag) {
            if (!flag) {
                clearTimeout(window[getType(self).name + 'Timeout']);
            }
            self.isMounted = flag;
        },
        setCouponWaitingLoading(flag) {
            self.couponWaitingLoading = flag;
        },
        getBetHistoryDetails: flow(function* fetch(needDetailsLoading) {
            const betId = self.activeBetId;
            if (betId && getRoot(self).site?.status.isActive) {
                const deltaTime = (Date.now() - self.bets.get(betId)?.fetchTime);
                const initRequest = !self.bets.get(betId)?.fetchTime;
                if ( deltaTime > TIMERS[getType(self).name].itemFetchTime) {
                    clearTimeout(window.betDetailsTimeout);
                    if (needDetailsLoading) {
                        self.bets.get(betId).setLoading(true);
                    }
                    const data = {
                        couponId: betId,
                    };
                    yield llattempt(
                        () =>
                            api.user.getBetHistoryDetails(data).then((response) => {
                                if (response.success) {
                                    self.bets.get(betId).setBetsDetailsError(null);
                                    self.bets.get(betId).setDetails(response.data);
                                } else {
                                    // Thing below needed to avoid double error notifier on logout
                                    if (response.data?.error !== 'AUTH_REQUIRED') {
                                        self.bets.get(betId).setBetsDetailsError(response.data?.error)
                                    }
                                }
                                self.bets.get(betId).setLoading(false);
                            }),
                        {
                            msg: 'GENERAL_ERROR',
                            at: 'user.getBetHistoryDetails',
                            withParams: data,
                            withNotifier: false,
                            onError: () => {
                                self.bets.get(betId).setBetsDetailsError('GENERAL_ERROR');
                                self.bets.get(betId).setLoading(false);
                            },
                        }
                    );
                }
                clearTimeout(window.betDetailsTimeout);
                window.betDetailsTimeout = setTimeout(
                    self.getBetHistoryDetails,
                    initRequest ?
                        TIMERS[getType(self).name].itemFetchTime :
                        (TIMERS[getType(self).name].itemFetchTime - deltaTime)
                )
            }
        }),
        deleteBet: flow(function* fetch(betId) {
            const bet = self.bets.get(betId);
            bet.setIsDeleting(true);
            yield llattempt(
                () =>
                    api.user
                        .deleteBet({couponId: betId})
                        .then((response) => {
                            if (response.success) {
                                llNotifier({
                                    message: 'Your bet slip has been deleted successfully.',
                                    type: 'success',
                                });
                                self.resetFetchTimeAndLoading();
                                if (getType(self).name === 'activeBets') {
                                    getParent(self, 1).betHistory.resetFetchTimeAndLoading();
                                    self.getActiveBets(true);
                                }
                                if (getType(self).name === 'betHistory') {
                                    getParent(self, 1).activeBets.resetFetchTimeAndLoading();
                                    self.getBetHistory();
                                }
                            } else {
                                const errorMsg = response.data?.error === 'MTS_DELETE_ERROR1' ? {
                                    message: response.data.msg,
                                    type: 'error',
                                    withTranslation: false,
                                } : {
                                    message: response.data?.error ?? 'GENERAL_ERROR',
                                    type: 'error',
                                }
                                llNotifier(errorMsg);
                            }
                            bet.setIsDeleting(false);
                        }),
                {
                    msg: 'GENERAL_ERROR',
                    at: 'user.deleteBet',
                    withParams: {
                        couponId: betId,
                    },
                    onError: () => {bet.setIsDeleting(false);}
                }
            );
        }),
        checkCashout: flow(function* fetch({id}) {
            const currentBet = self.bets.get(id);
            currentBet.setCashoutLoading(true);
            return yield llattempt(
                () =>
                    api.user.cashoutCheck({couponId: id}).then((response) => {
                        if (response.success && response.data && response.data.length) {
                            currentBet.setCashout(
                                Math.floor(response.data[0].CashOut * 100) / 100
                            );
                            currentBet.setCashoutLoading(false);
                            return true;
                        } else {
                            currentBet.setCashoutLoading(false);
                            llNotifier({
                                message: response.data?.error ?? 'GENERAL_ERROR',
                                type: 'error',
                            });
                        }
                    }),
                {
                    msg: 'GENERAL_ERROR',
                    at: 'user.checkCashout',
                    withParams: {couponId: self.id},
                    onError: () => {
                        currentBet.setCashoutLoading(false);
                    },
                }
            );
        }),
        getCashout: flow(function* fetch({couponId}) {
            const currentBet = self.bets.get(couponId);
            if (currentBet?.cashout) {
                const params = {
                    couponId,
                    amount: currentBet.cashout,
                };
                currentBet.setCashoutLoading(true);
                self.setCouponWaitingLoading(true);
                yield llattempt(
                    () =>
                        api.user.getCashout(params).then((response) => {
                            if (response.success) {
                                const bet = self.bets.get(couponId);
                                if (bet) {
                                    bet.setCashouted(true);
                                }
                                self.resetFetchTimeAndLoading();
                                if (getType(self).name === 'betHistory') {
                                    getParent(self, 1).activeBets.resetFetchTimeAndLoading();
                                    self.getBetHistory();
                                }
                                getRoot(self).modal.close();
                                llNotifier({
                                    message: trans('Your bet has been successfully cashouted'),
                                    type: 'success',
                                });
                            } else {
                                llNotifier({
                                    message: response.data?.error ?? 'GENERAL_ERROR',
                                    type: 'error',
                                });
                            }
                            if (getType(self).name === 'activeBets') {
                                getParent(self, 1).betHistory.resetFetchTimeAndLoading();
                                self.getActiveBets(true).then(() => {
                                    currentBet.setCashoutLoading(false);
                                    self.setCouponWaitingLoading(false);
                                });
                            } else {
                                currentBet.setCashoutLoading(false);
                                self.setCouponWaitingLoading(false);
                            }
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.getActiveBets',
                        withParams: params,
                        onError: () => {
                            currentBet.setCashoutLoading(false);
                            self.setCouponWaitingLoading(false);
                        },
                    }
                );
            } else {
                //    Some Error Here
                llNotifier({
                    message: 'GENERAL_ERROR',
                    type: 'error',
                });
            }
        }),
    }))
    .views((self) => ({
        get activeBet() {
            return self.bets.get(self.activeBetId);
        },
        getBet(id) {
            return self.bets.get(id);
        },
        get getBets() {
            return (
                (getRoot(self).user.userBets.statusFilter === 'All' || getRoot(self).user.userBets.statusFilter === 'activeBets')
                    ? orderBy(values(self.bets), ['dateTime'], ['desc'])
                    : orderBy(
                        values(self.bets).filter((bet) =>
                            bet.statusEn === getRoot(self).user.userBets.statusFilter
                        ), ['dateTime'], ['desc'])
            ).map((item) => {
                return {
                    ...item,
                    globalLoading: self.isTableLoading,
                };
            });
        },
        get betsStatuses() {
            return self.statuses;
        },
    }));

export default BetsTemplate;