import React, {useState, useEffect} from 'react';

import {withCommon} from '../../HOCs';


const LLScrollUp = withCommon({
    store: ({store}) => ({
        coupons: store.user.coupons,
        sportMountStatus: store.betting.sportMountStatus,
        mobileUserAgent: store.user.mobileUserAgent,
        list: store.user.coupons.list,
        mobileTicketsPanelStatus: store.betting.mobileTicketsPanelStatus,
        currentScroll: store.scrollBoxes.getScrollBox('main-scroll'),
        currentScrollPosition: store.scrollBoxes.getScrollBox('main-scroll')
            ?.scrollPosition,
    }),
})(({
        currentScroll,
        currentScrollPosition,
        list,
        alternativeScrollBox = null,
        mobileUserAgent,
        sportMountStatus,
        mobileTicketsPanelStatus
    }) => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollHandler = () => {
        if ((!mobileUserAgent && (currentScrollPosition > 1000 || alternativeScrollBox?.scrollPosition > 1000))
            || (mobileUserAgent && window.scrollY > 1000)) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        scrollHandler();
    }, [currentScrollPosition, alternativeScrollBox?.scrollPosition]);

    useEffect(() => {
        mobileUserAgent && document.addEventListener('scroll', scrollHandler);
        return () => document.removeEventListener('scroll', scrollHandler);
    }, []);

    return (
        (isVisible && (mobileTicketsPanelStatus !== 'open')) ? (
                <div
                    className={`scroll-to-top${
                        (sportMountStatus && list.length === 1)
                            ? '--shifted'
                            : (sportMountStatus && list.length > 1)
                                ? '--semi-shifted'
                                : ''
                    }`}
                    onClick={() => {
                        if (mobileUserAgent) {
                            window.scrollTo({top: 0, behavior: "smooth"});
                        } else {
                            alternativeScrollBox ? alternativeScrollBox.scrollMethods.scrollToTop() : currentScroll.scrollMethods.scrollToTop()
                        }
                    }}
                >
                    <i className="doruk-mg-arrow-2 scroll-up__icon"/>
                </div>
            )
            : null
    )
});

export default LLScrollUp;
