import { values } from 'mobx';
import { getParent, flow, types } from 'mobx-state-tree';

import { formatDate, llattempt } from '../../../../common/utils';
import api from '../../../../common/api';
import { llNotifier } from '../../../../components';

const AccountReportSports = types
    .model('AccountReportSports', {
        stake: 0,
        paid: 0,
        net: 0,
    })
    .actions((self) => ({
        set(data) {
            self.stake = data.stake;
            self.paid = data.paid;
            self.net = data.paid - data.stake;
        },
    }));

const AccountReportCasinoItem = types.model('AccountReportCasinoItem', {
    label: types.string,
    value: types.number,
});

const AccountReportBonus = types
    .model('AccountReportBonus', {
        bonus: 0,
        commission: 0,
        net: 0,
    })
    .actions((self) => ({
        set(data) {
            self.bonus = data.bonus;
            self.commission = data.commission;
            self.net = data.bonus - data.commission;
        },
    }));

const AccountReport = types
    .model('AccountReport', {
        sports: types.optional(AccountReportSports, {}),
        casino: types.array(AccountReportCasinoItem),
        bonus: types.optional(AccountReportBonus, {}),
        activeReport: types.maybeNull(types.string),
    })
    .actions((self) => ({
        _set(data) {
            self.sports.set(data.sport);

            self.casino = [];
            data.casino.map((casinoItem) =>
                self.casino.push(
                    AccountReportCasinoItem.create({
                        label: casinoItem.Provider,
                        value: casinoItem.Amount,
                    })
                )
            );

            self.bonus.set(data.bonus);
            self.fetchTime = Date.now();
            self.isTableLoading = false;
        },
        getAccountReport: flow(function* fetch() {
            if (
                self.checkFetchTime()
            ) {
                self.isTableLoading = true;
                const data = {
                    id: getParent(self).customerId,
                    start: formatDate(self.startDate, 'YYYYMMDD'),
                    end: formatDate(self.endDate, 'YYYYMMDD'),
                };
                yield llattempt(
                    () =>
                        api.user.getAccountReport(data).then((response) => {
                            if (response.success) {
                                self._set(response.data);
                            } else {
                                self.resetFetchTimeAndLoading();
                                // Thing below needed to avoid double error notifier on logout
                                if (response.data?.error === 'AUTH_REQUIRED') {
                                    return true;
                                } else {
                                    llNotifier({
                                        message: response.data?.error ?? 'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                }
                            }
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.getAccountReport',
                        withParams: data,
                        onError: () => {
                            self.resetFetchTimeAndLoading();
                        },
                    }
                );
            }
        }),
        setActiveReport(name) {
            if (self.activeReport === name) {
                self.activeReport = null;
            } else {
                self.activeReport = name;
                self.getAccountReport();
            }
        }
    }))
    .views((self) => ({
        get casinoReports() {
            return values(self.casino);
        },
        get casinoReportsSum() {
            return values(self.casino).reduce((sum, item) => sum + item.value, 0);
        },
    }));

export default AccountReport;
