import {parseUrl} from "../../../common/utils";


const getFeaturedBtnConfig = ({footerLinks, user}) => {
    const btn = {
        label: 'Featured',
        icon: 'doruk-fire',
    };
    if (footerLinks.length === 1) {
        const parsedUrl = parseUrl(footerLinks[0]?.url, user);
        btn.label = footerLinks[0]?.text;
        btn.elementAttributes = parsedUrl.elementAttributes;
        btn.onClick = parsedUrl.someClick;
        btn.thumbnail = footerLinks[0]?.thumbnail;
    } else {
        btn.featuredLinks = footerLinks;
        btn.elementAttributes = null;
    }

    return btn;
}

export default getFeaturedBtnConfig;
