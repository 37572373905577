import React from "react";

import trans from "../../../common/utils/trans";
import {LLBtn2, WithPopover} from "../../../components";

const NoBattles = ({
                       provider,
                       sortby,
                       search,
                       userBattleId,
                       whatWasChanged,
                       isActive,
                       canPlaySlotBattles,
                       isLogged,
                       router,
                       user,
                       viewSize
                   }) => {
    return (
        <div className="games-no-data games-no-data--nobattle">
            <p className="games-no-data__title games-no-data__text">
                {trans((provider || sortby || search) ? 'SLOTS_BATTLE__NO_DATA_TEXT_BY_THIS_PARAMS' : "SLOT_BATTLE__NO_DATA_TITLE")}
            </p>
            <LLBtn2
                customClasses="slots-battles__btn--accent slots-battles__create-btn"
                onClick={() => {
                    if (isLogged) {
                        if (!!userBattleId) {
                            router.push(`/slots-battles/${userBattleId}`);
                        } else {
                            router.push('/slots-battles/create');
                        }
                    } else {
                        user.openLogin();
                    }
                }}
                disabled={(whatWasChanged === "canceled" && isActive) || !canPlaySlotBattles}
                title={!canPlaySlotBattles ? 'You can\'t play slot battles. Please contact live support' : userBattleId ? trans('SLOT_BATTLE__ALREADY_IN_BATTLE') : ''}
            >
                {!!(userBattleId && whatWasChanged !== 'canceled') ? trans("SLOT_BATTLE__OPEN_MY_BATTLE") : trans("SLOT_BATTLE__START_NOW")}
            </LLBtn2>
            <div className="games-no-data__steps">
                <div className="games-no-data__step">
                    <div className="step-icon">
                        <i className="doruk-slot"/>
                    </div>
                    <div className="step-arrow">
                        <i className="doruk-mg-arrow-2"/>
                    </div>
                    <div className="step-text">
                        <span>{trans("SLOT_BATTLE__CHOOSE_FAV_GAME")}</span>
                    </div>

                </div>
                <div className="games-no-data__step">
                    <div className="step-icon">
                        <i className="doruk-banknote"/>
                    </div>
                    <div className="step-arrow">
                        <i className="doruk-mg-arrow-2"/>
                    </div>
                    <div className="step-text">
                        <span>{trans("SLOT_BATTLE__SET_WAGERING_AMOUNT")}</span>
                    </div>
                </div>
                <div className="games-no-data__step">
                    <div className="step-icon">
                        <i className="doruk-add-players"/>
                    </div>
                    {viewSize !== 'mobile' && <div className="step-arrow">
                        <i className="doruk-mg-arrow-2"/>
                    </div>}
                    <div className="step-text">
                        <span>{trans("SLOT_BATTLE__SELECT_NUMBER_OF_PARTICIPANTS")}</span>
                    </div>
                </div>
                {viewSize !== 'mobile' &&
                    <div className="games-no-data__step">
                        <div className="step-icon">
                            <i className="doruk-profit"/>
                        </div>
                        <div className="step-text">
                            <span>{trans("SLOT_BATTLE__WIN_YOUR_MONEY")}</span>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default NoBattles;
