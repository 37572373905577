import React from 'react';

import { LLBtn2, LLElementTypeSetter, withCommon } from '../../../../../components';
import config from '../../../../../static/themes/config';

/**
 * @file Represents Payment Content rendered in Modal
 *
 * @param {Object[]} paymentItem - payment data for render
 * @param {string} paymentItem.modifiedUrl - iframe url for payment
 * @param {string} [paymentItem.name] - name of payment
 * @param {string} [paymentItem.description] - description of payment
 *
 * @author A.HUSKOVA
 */

const PaymentModal = withCommon({
    store: ({ store }) => ({
        paymentItem: store.modal.content,
        viewSize: store.site.status.viewSize,
        siteName: store.site.siteName,
    }),
    defaultName: 'PaymentModal',
    isTrans: true,
    isObserver: true,
})(({ paymentItem, viewSize, siteName, trans }) =>
    paymentItem ? (
        <div className={`payment-modal payment-modal--${viewSize}`}>
            <div
                className="payment-modal__description"
                dangerouslySetInnerHTML={{ __html: paymentItem.description }}
            />
            <div className="payment-modal__btn-wrapper">
                <LLBtn2 customClasses="ll-btn--full-rounded">
                    <LLElementTypeSetter
                        href={paymentItem.modifiedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {trans(config(siteName).payments[paymentItem.paymentType].name)}
                    </LLElementTypeSetter>
                </LLBtn2>
            </div>
        </div>
    ) : null
);

export default PaymentModal;
