import Base from '../base';
import apiUrls from '../api-urls';

export default class BettingAPI extends Base {
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                    Prematches api                                    ##
    // ##                                                                                      ##
    // ##========================================================================================

    getSports(params) {
        return this.apiClient.post(apiUrls.betting.sports, params);
    }

    getSportsRangedByDate(params) {
        return this.apiClient.post(apiUrls.betting.rangedByDateSports, params);
    }

    getWidgetItems(params) {
        return this.apiClient.post(apiUrls.betting.getWidget, params);
    }

    getCategories(params) {
        return this.apiClient.post(apiUrls.betting.categories, params);
    }

    getTournaments(params) {
        return this.apiClient.post(apiUrls.betting.tournaments, params);
    }

    getPopularTournaments(params) {
        return this.apiClient.post(apiUrls.betting.popularTournaments, params);
    }

    getMatches(params) {
        return this.apiClient.post(apiUrls.betting.matches, params);
    }

    getAllCustomMarkets(param) {
        return this.apiClient.post(apiUrls.betting.allCustomMarkets, param);
    }

    getAllMarkets(param) {
        return this.apiClient.post(apiUrls.betting.allMarkets, param);
    }

    getMarkets(param) {
        return this.apiClient.post(apiUrls.betting.markets, param);
    }

    getCustomMarkets(param) {
        return this.apiClient.post(apiUrls.betting.customMarkets, param);
    }

    getTopMatches(param) {
        return this.apiClient.post(apiUrls.betting.topMatches, param);
    }

    getFavortieMatches(param) {
        return this.apiClient.post(apiUrls.betting.favortieMatches, param);
    }

    getSportMatches(param) {
        return this.apiClient.post(apiUrls.betting.sportMatches, param);
    }

    getCategoryMatches(param) {
        return this.apiClient.post(apiUrls.betting.categoryMatches, param);
    }

    getMatch(param) {
        return this.apiClient.post(apiUrls.betting.match, param);
    }

    getMatchTV(param) {
        return this.apiClient.post(apiUrls.betting.matchTV, param);
    }

    /*************************************************************
     * getOutrights
     * @desc Outrights getter
     * @param {object} param - post params
     * @property {integer} param.categoryId
     * @property {Date} param.to - timestamp
     */

    async getOutrights(param) {
        return this.apiClient.post(apiUrls.betting.outrights, param);
    }

    /*************************************************************
     * getOutrightBets
     * @desc Outright bets getter
     * @param {object} param - post params
     * @property {integer} param.matchId
     * @property {integer} param.marketId
     * @property {string} param.specifiers
     */

    getOutrightBets(param) {
        return this.apiClient.post(apiUrls.betting.outrightBets, param);
    }

    /*************************************************************
     * getMatchPlayers
     * @desc Match details players getter
     * @param {object} param - post params
     * @property {string} param.matchId - id of reqested match
     */

    getMatchPlayers(param) {
        return this.apiClient.post(apiUrls.betting.matchPlayers, param);
    }

    /*************************************************************
     * getSearch
     * @desc Search for competitor that contains search string
     * @param {object} param - post params
     * @property {string} param.search - competitor text to search
     */

    getSearch(param) {
        return this.apiClient.post(apiUrls.betting.search, param);
    }

    getCoupons(param) {
        return this.apiClient.post(apiUrls.betting.coupons.list, param);
    }

    addCoupon(param) {
        return this.apiClient.post(apiUrls.betting.coupons.add, param);
    }

    addVaix(param) {
        return this.apiClient.post(apiUrls.betting.coupons.addVaix, param);
    }

    clearCoupons(param) {
        return this.apiClient.post(apiUrls.betting.coupons.clear, param);
    }

    removeCoupon(param) {
        return this.apiClient.post(apiUrls.betting.coupons.remove, param);
    }

    removeVaix(param) {
        return this.apiClient.post(apiUrls.betting.coupons.removeVaix, param);
    }

    getPayout(param) {
        return this.apiClient.post(apiUrls.betting.coupons.payout, param);
    }

    getCouponPayout(param) {
        return this.apiClient.post(apiUrls.betting.coupons.payoutSingle, param);
    }

    saveCoupons(param) {
        return this.apiClient.post(apiUrls.betting.coupons.save, param);
    }

    saveCouponsSingle(param) {
        return this.apiClient.post(apiUrls.betting.coupons.saveSingle, param);
    }

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                   Live betting api                                   ##
    // ##                                                                                      ##
    // ##========================================================================================

    /*************************************************************
     * Get live sport matches
     * @desc Specific sport matches for live matches page
     */

    getLiveSportMatches(param) {
        return this.apiClient.post(apiUrls.betting.sportMatches, param);
    }

    /*************************************************************
     * Get all live matches
     * @desc All matches for live matches page
     */

    getLiveMatches(params) {
        return this.apiClient.post(apiUrls.betting.liveMatches, params);
    }

    /*************************************************************
     * Get all live matches
     * @desc All matches for live matches page
     */

    getLiveAllMarkets(params) {
        return this.apiClient.post(apiUrls.betting.liveAllMarkets, params);
    }

    getLiveAllCustomMarkets(param) {
        return this.apiClient.post(apiUrls.betting.liveAllCustomMarkets, param);
    }

    /*************************************************************
     * Get all live matches
     * @desc All matches for live matches page
     */

    getLiveMarkets(params) {
        return this.apiClient.post(apiUrls.betting.liveMarkets, params);
    }

    getLiveCalendar(params) {
        return this.apiClient.post(apiUrls.betting.liveCalendar, params);
    }

    getStream(params) {
        return this.apiClient.post(apiUrls.betting.liveStream, params);
    }

    getRecommendation(params) {
        return this.apiClient.post(apiUrls.betting.vaix.getRecommendation, params);
    }

    sendVaixStats(params) {
        return this.apiClient.post(apiUrls.betting.vaix.sendStats, params);
    }
}
