import { flow, getRoot } from 'mobx-state-tree';

import llattempt from '../../../../../../common/utils/attempt';
import api from '../../../../../../common/api';

/*
##     ##    ###    ########  ##    ## ######## ########     ######   ########   #######  ##     ## ########
###   ###   ## ##   ##     ## ##   ##  ##          ##       ##    ##  ##     ## ##     ## ##     ## ##     ##
#### ####  ##   ##  ##     ## ##  ##   ##          ##       ##        ##     ## ##     ## ##     ## ##     ##
## ### ## ##     ## ########  #####    ######      ##       ##   #### ########  ##     ## ##     ## ########
##     ## ######### ##   ##   ##  ##   ##          ##       ##    ##  ##   ##   ##     ## ##     ## ##
##     ## ##     ## ##    ##  ##   ##  ##          ##       ##    ##  ##    ##  ##     ## ##     ## ##
##     ## ##     ## ##     ## ##    ## ########    ##        ######   ##     ##  #######   #######  ##

######## ######## ########  ######  ##     ## ########  ######
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ##          ##    ##       ##     ## ##       ##
######   ######      ##    ##       ######### ######    ######
##       ##          ##    ##       ##     ## ##             ##
##       ##          ##    ##    ## ##     ## ##       ##    ##
##       ########    ##     ######  ##     ## ########  ######
*/

const marketsFetches = (s) => ({
    fillSesiForMatchPlayers: flow(function* fetch({ matchId }) {
        return yield api.betting.getMatchPlayers({ matchId });
    }),

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                  Markets data fetch                                  ##
    // ##                                                                                      ##
    // ##========================================================================================

    fetchDataForMarkets: flow(function* fetch({ matchId }) {
        const marketGroupsParams = {
            matchId: matchId,
        };

        return yield llattempt(
            () =>
                api.betting.getMarkets({
                    matchId: matchId,
                    marketGroupId: s.id,
                }),
            {
                withNotifier: false,
                at: 'fetchDataForMarkets',
                withParams: marketGroupsParams,
            }
        );
    }),

    fetchDataForCustomMarkets: flow(function* fetch({ matchId }) {
        const marketGroupsParams = {
            matchId: matchId,
        };

        return yield llattempt(
            () => {
                const odds = getRoot(s).user.coupons.customCouponsList;
                const oddsIdsArr = [];
                odds.map(odd => {
                    oddsIdsArr.push(odd.id)
                })
                return api.betting.getCustomMarkets({
                    oddsId: oddsIdsArr, 
                    matchId: matchId,
                    marketGroupId: s.id,
                })},
            {
                withNotifier: false,
                at: 'fetchDataForCustomMarkets',
                withParams: marketGroupsParams,
            }
        );
    }),

    fetchDataForAllMarkets: flow(function* fetch({ matchId }) {
        const marketGroupsParams = {
            matchId: matchId,
        };

        return yield llattempt(
            () =>
                api.betting.getAllMarkets({
                    matchId: matchId,
                }),
            {
                withNotifier: false,
                at: 'fetchDataForAllMarkets',
                withParams: marketGroupsParams,
            }
        );
    }),

    fetchDataForLiveMarkets: flow(function* fetch({ matchId, marketGroupId }) {
        const marketGroupsParams = {
            matchId,
            marketGroupId,
        };

        return yield llattempt(() => api.betting.getLiveMarkets(marketGroupsParams), {
            withNotifier: false,
            at: 'fetchDataForLiveMarkets',
            withParams: marketGroupsParams,
        });
    }),
});

export default marketsFetches;
