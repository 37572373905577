import React from 'react';
import ValidationGroup from 'devextreme-react/validation-group';
import CheckBox from 'devextreme-react/check-box';
import PropTypes from 'prop-types';

import {FormFirstPart, FormSecondPart} from './index';
import {withCommon, LLBtn} from '../../../index';
import config from '../../../../static/themes/config';
import {DrawerCloseButton} from "../../../../pages/user-profile/components";

const SignUpMobile = withCommon({
    store: ({store}) => ({
        language: store.user.language,
        siteName: store.site.siteName,
        captchaV3: store.site.captchaV3,
        setModal: store.modal.setModal,
        openLogin: store.user.openLogin,
        mobileUserAgent: store.user.mobileUserAgent,
        drawer: store.drawer,
    }),
    PropTypes: {
        clearServerError: PropTypes.func.isRequired,
        form: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        formRef: PropTypes.func.isRequired,
        serverResponseErrors: PropTypes.object.isRequired,
        serverValidationCheck: PropTypes.func.isRequired,
        setForm: PropTypes.func.isRequired,
        submitRegistrationForm: PropTypes.func.isRequired,
        isSubmitFetching: PropTypes.bool.isRequired,
        setSubmitFetching: PropTypes.func.isRequired,
    },
    isTrans: true,
})(
    ({
         siteName,
         setModal,
         openLogin,
         captchaV3,
         clearServerError,
         mobileUserAgent,
         form,
         language,
         formRef,
         serverResponseErrors,
         serverValidationCheck,
         setForm,
         submitRegistrationForm,
         isSubmitFetching,
         setSubmitFetching,
         trans,
         drawer,
     }) => {
        const tmpArray = trans('OVER18TEXT').split('{{LINK_START}}');
        const tmpArray2 = tmpArray[1].split('{{LINK_END}}');

        return (
            <div className="signup__container">
                <div className="signup__form">
                    <div className="signup__header--mobile">
                        {trans('Account information')}
                        <DrawerCloseButton/>
                    </div>
                    <ValidationGroup ref={formRef}>
                        <FormFirstPart
                            form={form}
                            setForm={setForm}
                            clearServerError={clearServerError}
                            serverResponseErrors={serverResponseErrors}
                            serverValidationCheck={serverValidationCheck}
                        />
                        <div className="signup__header--mobile">{trans('Account information')}</div>
                        <FormSecondPart
                            form={form}
                            setForm={setForm}
                            clearServerError={clearServerError}
                            serverResponseErrors={serverResponseErrors}
                            serverValidationCheck={serverValidationCheck}
                        />
                        <div className="signup__footer">
                            <div className="signup__footer-eighteen-box">
                                <div className="signup__footer-eighteen">
                                    <div className="signup__footer-eighteen--check">
                                        <CheckBox
                                            value={form.overEighteen}
                                            onValueChanged={(e) =>
                                                setForm({
                                                    ...form,
                                                    overEighteen: e.value,
                                                    overEighteenIndicator: true,
                                                })
                                            }
                                        />
                                    </div>
                                    <p>
                                        {tmpArray[0]}
                                        <span
                                            className="signup__footer-link"
                                            onClick={() => {
                                                setModal({
                                                    active: true,
                                                    title: trans('Rules'),
                                                    customContent: <iframe
                                                        src={config(siteName).rulesLinks[language]}
                                                        title="modal-iframe"
                                                        className='ll-modal__external-mobile'/>,
                                                    currentKey: 'custom-content',
                                                    customClasses: 'll-modal__external-mobile-wrp',
                                                    showCloseButton: true,
                                                    showFullscreenButton: true,
                                                    is100vh: true,
                                                });
                                            }}
                                        >
                                        {tmpArray2[0]}
                                    </span>
                                        {tmpArray2[1]}
                                    </p>
                                </div>
                                {!form.overEighteenIndicator &&
                                    form.overEighteenIndicator !== null && (
                                        <div className="signup__footer-eighteen--error">
                                            {trans('NOT18_ERROR')}
                                        </div>
                                    )}
                            </div>
                            {(!!captchaV3) && (
                                <p className="form__captcha-text" dangerouslySetInnerHTML={{
                                    __html: trans('CAPTCHA_TEXT').replace('{{FIRST_LINK}}', `<a href="https://policies.google.com/privacy" class="form__captcha-link">`)
                                        .replace('{{SECOND_LINK}}', `<a href="https://policies.google.com/terms" class="form__captcha-link">`)
                                        .replace('{{CLOSE_LINK}}', `</a>`)
                                        .replace('{{CLOSE_LINK}}', `</a>`)
                                }}
                                />
                            )}
                            <LLBtn
                                className={`signup__sumbit-btn ll-btn${
                                    !mobileUserAgent ? '--accent' : ''
                                }`}
                                useSubmitBehavior={false}
                                disabled={isSubmitFetching}
                                onClick={(e) => {
                                    if (!isSubmitFetching) {
                                        setSubmitFetching(true);
                                        let result = e.validationGroup.validate();
                                        if (result.isValid && form.overEighteen) {
                                            submitRegistrationForm();
                                        }
                                        if (!form.overEighteen) {
                                            setForm({
                                                ...form,
                                                overEighteenIndicator: false,
                                            });
                                        }
                                    }
                                }}
                            >
                                <div>
                                    <span>{trans('Submit')}</span>
                                </div>
                            </LLBtn>
                        </div>
                    </ValidationGroup>
                    <div className="signup__login">
                        <span>{trans('ALREADY_ACCOUNT')}</span>
                        <span
                            className="signup__login-btn"
                            onClick={() => {
                                drawer.close();
                                openLogin();
                            }}
                        >{trans('Login here')}</span>
                    </div>
                </div>
            </div>
        );
    }
);

export default SignUpMobile;