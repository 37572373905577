import React, {Fragment} from 'react';

import {trans} from "../../../../../common/utils";
import {LLBreadCrumbs} from "../../../../../components";
import CommonMatchListItem from "../../../components/common/common-match-item";


const FilteredPrematchesList = ({matches, type, activeRangedEvent, viewSize}) => {
    const noDataText = {
        rangedEvents: 'RANGED_EVENTS_NO_DATA_TEXT',
        search: 'SPORTS_SEARCH_NO_DATA_TEXT'
    };

    const getBreadCrumbs = (tournament) => {
        const breadCrumbsOptions = [
            {
                label: tournament.nameOf('category'),
                value: 'category',
            },
            {
                label: tournament.nameOf('tournament'),
                value: 'tournament',
            },
        ];
        if (activeRangedEvent === 'popular' && type === 'rangedEvents') {
            breadCrumbsOptions.unshift({
                label: <i className={`filtered-matches-list__breadcrumbs-sport-icon sport_icon_font-${tournament.sportId}`} />,
                value: 'sport',
            });
        }
        return breadCrumbsOptions;
    }

    return (
        <div className={`filtered-matches-list--${viewSize}`}>
            { matches.length ? (
                matches.map((tournament) => {
                    return (
                        <Fragment key={tournament.tournamentId}>
                            <div className="filtered-matches-list__breadcrumbs-wrp">
                                { typeof tournament.nameOf === 'function' &&
                                    <LLBreadCrumbs
                                        dataSource={getBreadCrumbs(tournament)}
                                        separatorSymbol=" / "
                                        customClasses="filtered-matches-list__breadcrumbs"
                                    />
                                }
                            </div>
                            { Object.keys(tournament.matches).map((matchStartDate) => {
                                const matchesByDate = tournament.matches[matchStartDate];
                                return ( matchesByDate.length ?
                                    <Fragment key={matchStartDate}>
                                        { matchesByDate.map((match) => (
                                            <CommonMatchListItem
                                                key={match.id}
                                                match={match}
                                                matchStartDate={matchStartDate}
                                                type="filtered"
                                            />
                                        ))}
                                    </Fragment> : null
                                );
                            }) }
                        </Fragment>
                    );
                })
            ) : (
                <p className="filtered-matches-list__no-data-text">
                    {trans(noDataText[type])}
                </p>
            )}
        </div>
    );
};

export default FilteredPrematchesList;