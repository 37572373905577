export const SLOT_BATTLES_SORTBY_FILTERS = {
    'My battles': [],
    'A - Z': [
        {
            selector: 'GameName',
            desc: false,
        },
        {selector: "idt", desc: true},
    ],
    'Z - A': [
        {
            selector: 'GameName',
            desc: true,
        },
        {selector: "idt", desc: true},
    ],
    'Oldest': [{
        selector: 'idt',
        desc: false,
    }],
    'Newest': [{
        selector: 'idt',
        desc: true,
    }],
    'Lowest bet': [
        {
            selector: 'Bet',
            desc: false,
        },
        {selector: "idt", desc: true},
    ],
    'Highest bet': [
        {
            selector: 'Bet',
            desc: true,
        },
        {selector: "idt", desc: true},
    ],
};

export const SLOT_BATTLES_GAMES_SORTBY_FILTERS = {
    'A - Z': [
        {
            selector: 'GameName',
            desc: false,
        },
        {selector: "idt", desc: true},
    ],
    'Z - A': [
        {
            selector: 'GameName',
            desc: true,
        },
        {selector: "idt", desc: true},
    ],
    'Oldest': [{
        selector: 'idt',
        desc: false,
    }],
    'Newest': [{
        selector: 'idt',
        desc: true,
    }],
};