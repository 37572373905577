import React from 'react';
import PropTypes from 'prop-types';
import Button from 'devextreme-react/button';
import cx from 'classnames';

/**
 * Represents a react component.
 *
 * @param {object} props - component properties
 * @param {(string | object | string[])} props.customClasses - build classnames
 */

const LLBtn = ({ customClasses, content, children, ...restProps }) => {
    const classnames = cx('ll-btn', customClasses);
    return (
        <Button {...restProps} elementAttr={{ class: classnames }}>
            {children}
        </Button>
    );
};

LLBtn.propTypes = {
    customClasses: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

export default LLBtn;
