import React from 'react';
import cx from 'classnames';

import {withCommon} from '../../../../components';


const LiveWidgetsNav = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
    }),
    isTrans: true,
    isObserver: true,
    isRouter: true,
})(
    ({
         viewSize,
         type,
         onClick,
         lmtOnly = false,
         withStream = false,
         setMobileNavInfoShowed = () => {},
         customClasses,
         trans,
     }) => {
        return (
            <ul className={cx(`live-widgets__nav--${viewSize}`, customClasses)}>
                {withStream && (
                    <li
                        className={cx(
                            'live-widgets__nav-item',
                            type === 'streaming' && 'live-widgets__nav-item--active'
                        )}
                        data-type="streaming"
                        onClick={onClick}
                    >
                        <i className="live-widgets__nav-icon doruk-live-match-01"/>
                        <span className="live-widgets__nav-text" >{trans('Streaming')}</span>
                    </li>
                )}
                <li
                    className={cx(
                        'live-widgets__nav-item',
                        type === 'match.lmtPlus' && 'live-widgets__nav-item--active'
                    )}
                    data-type="match.lmtPlus"
                    onClick={onClick}
                >
                    { viewSize !== 'mobile' &&
                        <i
                            className="live-widgets__nav-info doruk-about-us-01"
                            onClick={() => setMobileNavInfoShowed(v => !v)}
                        />
                    }
                    <span>
                        <i className="live-widgets__nav-icon doruk-widget-lmt"/>
                        <span className="live-widgets__nav-text" >{trans('LMT')}</span>
                    </span>
                </li>
                {!lmtOnly && (
                    <>
                        <li className={cx(
                            'live-widgets__nav-item',
                            type === 'betAssist.standalone' &&
                            'live-widgets__nav-item--active'
                        )}
                            data-type="betAssist.standalone"
                            onClick={onClick}
                        >
                            <i className="live-widgets__nav-icon doruk-widget-bet-assist"/>
                            <span className="live-widgets__nav-text" >{trans('BET_ASSISTANT')}</span>
                        </li>
                        <li className={cx(
                            'live-widgets__nav-item',
                            type === 'match.preview' &&
                            'live-widgets__nav-item--active'
                        )}
                            data-type="match.preview"
                            onClick={onClick}
                        >
                            <i className="live-widgets__nav-icon doruk-widget-preview"/>
                            <span className="live-widgets__nav-text" >{trans('MATCH_PREVIEW')}</span>
                        </li>
                        <li className={cx(
                            'live-widgets__nav-item',
                            type === 'headToHead.standalone' &&
                            'live-widgets__nav-item--active'
                        )}
                            data-type="headToHead.standalone"
                            onClick={onClick}
                        >
                            <i className="live-widgets__nav-icon doruk-widget-h2h"/>
                            <span className="live-widgets__nav-text" >{trans('HEAD_TO_HEAD')}</span>
                        </li>
                    </>
                )}

            </ul>
        );
    }
);

export default LiveWidgetsNav;
