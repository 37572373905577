import React, {useState, useRef, useEffect} from 'react';
import {CSSTransition} from 'react-transition-group';
import {Validator, RequiredRule} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import PropTypes from 'prop-types';

import config from '../../../static/themes/config';


import {withCommon, LLInput, LLBtn} from '../..';

let interval;

const LoginForm = withCommon({
    store: ({store}) => ({
        user: store.user,
        viewSize: store.site.status.viewSize,
        siteName: store.site.siteName,
        modal: store.modal,
        drawer: store.drawer,
    }),
    PropTypes: {
        user: PropTypes.object.isRequired,
        modal: PropTypes.object.isRequired,
        drawer: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    },
    isTrans: true,
    isObserver: true,
    isRouter: true,
})(
    ({
         user: {resendSms, login, authGoogle, authSms},
         viewSize,
         siteName,
         trans,
         modal,
         drawer,
         history,
     }) => {
        const [loading, setLoading] = useState(false),
            [username, setUsername] = useState(''),
            [password, setPassword] = useState(''),
            [googleAuthFlag, setGoogleAuthFlag] = useState(false),
            [googleCode, setGoogleCode] = useState(''),
            [smsAuthFlag, setSmsAuthFlag] = useState(false),
            [smsCode, setSmsCode] = useState(''),
            [phoneNumber, setPhoneNumber] = useState(''),
            [timeToNewSms, setTimeToNewSms] = useState(0),
            [error, setError] = useState('');

        const formRef = useRef();

        let bananaDefender = true;

        const errorsCleaner = () => {
            setSmsAuthFlag(false);
            setGoogleAuthFlag(false);
            setError('');
        };

        useEffect(() => {
            const loginInput = document.querySelector('#login__username input');
            loginInput && loginInput.focus();
        }, []);

        useEffect(() => {
            if (timeToNewSms < 1 && bananaDefender) {
                clearInterval(interval);
            }
        }, [timeToNewSms]);

        useEffect(
            () => () => {
                clearInterval(interval);
                interval = null;
                bananaDefender = false;
            },
            []
        );

        const setTimer = () => {
            interval = setInterval(() => {
                setTimeToNewSms((timeToNewSms) => timeToNewSms - 1);
            }, 1000);
        };

        const sendSms = () => {
            setLoading(true);
            setSmsCode('');
            setError('');
            resendSms().then((res) => {
                if (res) {
                    if (res.success) {
                        setPhoneNumber(res.data.phone);
                        setTimeToNewSms(res.data.ttl);
                        setTimer();
                    } else {
                        setError(trans(res.data.error));
                    }
                } else {
                    setError('Error. Try again later');
                }
                setLoading(false);
            });
        };

        const sendData = async () => {
            const formValidation = formRef.current.instance.validate();
            if (formValidation.isValid) {
                setLoading(true);
                login({username, password}).then(({success, data}) => {
                    setLoading(false);
                    if (success) {
                        viewSize !== 'desktop' ? drawer.close() : modal.close();
                    } else if (data) {
                        if (data.error === 'GOOGLE_AUTH_REQUIRED') {
                            setGoogleAuthFlag(true);
                        } else if (data.error === 'SMS_AUTH_REQUIRED') {
                            setSmsAuthFlag(true);
                            setPhoneNumber(data.phone);
                            setTimeToNewSms(data.ttl);
                            setTimer();
                        } else {
                            setError(trans(data.error));
                        }
                    } else {
                        setError('GENERAL_ERROR');
                    }
                });
            }
        };

        const sendAuthCode = () => {
            setLoading(true);

            const auth = (res) => {
                if (res.success) {
                    setLoading(false);
                    viewSize !== 'desktop' ? drawer.close() : modal.close();
                } else {
                    setError(trans(res.data.error));
                    setLoading(false);
                }
            };

            googleAuthFlag
                ? authGoogle({code: googleCode}).then((res) => {
                    auth(res);
                })
                : authSms({code: smsCode}).then((res) => {
                    auth(res);
                });
        };

        return (
            <ValidationGroup ref={formRef} className={`login-form--${viewSize}`}>
                <LLInput
                    customClasses="login__input--name"
                    label={trans('Username')}
                    labelPosition={viewSize !== 'desktop' ? 'inside' : 'top'}
                    maxLength={50}
                    value={username}
                    disabled={loading}
                    showClearButton={false}
                    onValueChanged={(e) => {
                        errorsCleaner();
                        setUsername(e.value);
                    }}
                    id="login__username"
                    name="login__username"
                    stylingMode="outlined"
                    onEnterKey={sendData}
                >
                    <Validator>
                        <RequiredRule message={trans('USERNAME_EMPTY')}/>
                    </Validator>
                </LLInput>

                <LLInput
                    label={trans('Password')}
                    labelPosition={viewSize !== 'desktop' ? 'inside' : 'top'}
                    disabled={loading}
                    maxLength={50}
                    value={password}
                    mode="password"
                    showClearButton={false}
                    onValueChanged={(e) => {
                        errorsCleaner();
                        setPassword(e.value);
                    }}
                    id="login__password"
                    name="login__password"
                    stylingMode="outlined"
                    onEnterKey={sendData}
                >
                    <Validator>
                        <RequiredRule message={trans('PASSWORD_EMPTY')}/>
                    </Validator>
                </LLInput>

                {(googleAuthFlag || smsAuthFlag) && (
                    <CSSTransition timeout={400}>
                        <>
                            <LLInput
                                label={trans(
                                    googleAuthFlag
                                        ? 'Code from Google Authenticator'
                                        : 'Sms Code'
                                )}
                                labelPosition="top"
                                maxLength={6}
                                value={googleAuthFlag ? googleCode : smsCode}
                                disabled={loading}
                                showClearButton={true}
                                onValueChanged={(e) => {
                                    setError('');
                                    googleAuthFlag
                                        ? setGoogleCode(e.value)
                                        : setSmsCode(e.value);
                                }}
                                id="login__add-code"
                                name="login__add-code"
                                stylingMode="outlined"
                                onEnterKey={sendAuthCode}
                            >
                                <Validator>
                                    <RequiredRule message={trans('WRONG_CODE')}/>
                                </Validator>
                            </LLInput>
                            {smsAuthFlag ? (
                                timeToNewSms > 0 ? (
                                    <p className="login-form__sms-auth-text">
                                        <span>
                                            {phoneNumber + ' '}
                                            {trans(
                                                'SMS already sent. You can send another after: {{TIME}} seconds.'
                                            ).replace('{{TIME}}', timeToNewSms)}
                                        </span>
                                    </p>
                                ) : (
                                    <LLBtn
                                        className="login-form__sms-auth-btn ll-btn--accent"
                                        onClick={() => {
                                            sendSms();
                                        }}
                                        disabled={loading}
                                    >
                                        <>
                                            <span>{trans('Send Again')}</span>
                                        </>
                                    </LLBtn>
                                )
                            ) : null}
                        </>
                    </CSSTransition>
                )}

                {error ? (
                    <CSSTransition timeout={400}>
                        <div className="login-form__error">{error}</div>
                    </CSSTransition>
                ) : null}

                <div className="login-form__footer">
                    {config(siteName).siteConfig.permissionRules.isForgotPassword ? <div
                            className="login-form__forgot-pwd-btn"
                            onClick={() => {
                                if (viewSize !== 'desktop') {
                                    drawer.close();
                                    history.push('/forgot-password');
                                } else {
                                    drawer.close();
                                    modal.setModal({
                                        active: true,
                                        currentKey: 'forgot-password-form',
                                        customClasses: 'll-forgot-password-modal',
                                        title: trans('Password Reset'),
                                        maskBackground: true,
                                        showHeader: true,
                                    });
                                }
                            }}
                        >
                            {trans('Forgot Password')}
                        </div>
                        : null
                    }
                    <LLBtn
                        className={`${viewSize !== 'desktop' ? 'll-drawer__btn' : 'login-form__login-btn ll-btn--accent'}`}
                        onClick={(e) => {
                            let result = e.validationGroup.validate();
                            if (result.isValid) {
                                smsCode.length || googleCode.length
                                    ? sendAuthCode()
                                    : sendData();
                            }
                        }}
                        disabled={loading}
                    >
                        <div>
                            <span>{trans('Sign in')}</span>
                        </div>
                    </LLBtn>
                    {config(siteName).siteConfig.permissionRules.isForgotPassword ? <div className="login-form__signup-text">
                        <p>{trans('Don\'t have an account?')} <span
                            className="login-form__signup-link"
                            onClick={() => {
                                viewSize === 'desktop' && modal.close();
                                drawer.open({
                                    side: viewSize !== 'desktop' ? 'left' : 'right',
                                    currentKey: 'signup-form-drawer',
                                })
                            }}>
                                {trans('Sign up')}
                            </span>
                        </p>
                    </div>
                        : null}
                </div>
            </ValidationGroup>
        )
    });

export default LoginForm;
