import React from 'react';
import { inject, observer } from 'mobx-react';

import { formatDate, trans } from '../../../../../common/utils';
import FilteredPrematchesList from "./filtered-prematches-list";


const SearchResult = ({ store }) => {
    const searchString = store.betting.branch.search.searchString,
        search = store.betting.branch.search;

    const searchResult = [...search.groupedSearchResult];

    return (
        <div className="prematch-menu__search-results">
            <div className="prematch-menu__search-title">
                <p>
                    <span>{trans('Search result for')}:</span>
                    <span className="prematch-menu__search-string">{searchString}</span>
                </p>

                <p className="prematch-menu__search-time">
                </p>
            </div>
            <FilteredPrematchesList
                matches={searchResult}
                viewSize={store.site.status.viewSize}
                type="search"
            />
        </div>
    );
};

export default inject('store')(observer(SearchResult));
