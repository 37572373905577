import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import cx from 'classnames';

import { formatNumber } from '../../../../../common/utils';
import { LLScrollBox, LLPreloader, withCommon } from '../../../../../components';

/**
 * @file Represents React Component to render desktop report item
 * @memberOf AccountReport
 *
 * @param {boolean} reportLoading - show loading if we are waiting for account report request
 * @param {string} desktopTitle - title of desktop report item
 * @param {boolean} isLabelsCapitalized - is label for report line needed to be capitalize
 * @param {array} items - array of data to render lines in report items
 * @param {string} currency - current user currency
 * @param {string} language - current language
 *
 * @author A.Huskova
 */

const AccountReportItem = withCommon({
    store: ({ store }) => ({
        currency: store.user.currency,
        language: store.user.language,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        desktopTitle: PropTypes.string,
        isLabelsCapitalized: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.number,
            })
        ),
    },
    defaultName: 'AccountReportDesktopItem',
    isTrans: true,
    isObserver: true,
})(
    ({
        reportLoading,
        desktopTitle,
        isLabelsCapitalized,
        items,
        itemId,
        viewSize,
        currency,
        language,
        trans,
    }) => {
        const [isOpened, setOpened] = useState(false);

        const ref = useRef(null);

        const renderItems = (items) => {
            return items.map((item, i) => (
                <p
                    key={i}
                    className={cx(
                        'my-account-info-list__item',
                        itemId === 'casino' && i === items.length - 1 && 'net'
                    )}
                >
                    <span
                        className={cx(
                            'my-account-info-list__item-label',
                            isLabelsCapitalized &&
                                'my-account-info-list__item-label--capitalized'
                        )}
                    >
                        {trans(item.label)}
                    </span>
                    { viewSize === 'desktop' &&
                        <span className="my-account-info-item__line-divider">:</span>
                    }
                    <span
                        className={cx(
                            'my-account-info-list__item-value',
                            item.value < 0 && 'my-account-info-list__item-value--negative'
                        )}
                    >
                        {formatNumber(item.value, 2, language, currency)}
                    </span>
                </p>
            ));
        };

        const isContentOverflowed = itemId === 'casino' && items.length > 3;

        return (
            <div className="account-report-item">
                { viewSize !== 'mobile' &&
                    <p className="my-account-info-item__title">{trans(desktopTitle)}</p>
                }
                { reportLoading && viewSize !== 'mobile' ? (
                    <div className="my-account-info-item__loader-wrapper">
                        <LLPreloader />
                    </div>
                ) : (itemId === 'casino' && viewSize !== 'mobile') ? (
                    <ClickAwayListener
                        onClickAway={() => setOpened(false)}
                        mouseEvent={'onMouseDown'}
                        touchEvent={'onTouchStart'}
                    >
                        <div
                            ref={ref}
                            className={cx(
                                'my-account-info-item__content--overflow',
                                isOpened && 'expanded'
                            )}
                        >
                            {isOpened ? (
                                <LLScrollBox id="cabinet-casino-report">
                                    {renderItems(items)}
                                </LLScrollBox>
                            ) : (
                                renderItems(items.slice(0, 3))
                            )}
                            {isContentOverflowed && (
                                <div
                                    className="my-account-info-item__expand-panel"
                                    onClick={() => setOpened(!isOpened)}
                                >
                                    <i
                                        className={cx(
                                            'my-account-info-item__expand-panel--icon',
                                            'doruk-mg-arrow-2',
                                            isOpened && 'expanded'
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </ClickAwayListener>
                ) : (
                    renderItems(items)
                )}
            </div>
        );
    }
);

export default AccountReportItem;
