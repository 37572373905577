import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import cx from 'classnames';
import ClickAwayListener from "@mui/material/ClickAwayListener";

import {getSportIconsClassnames, trans} from '../../../../common/utils';
import config from '../../../../static/themes/config';
import BreadcrumbsDropdownContent from './components/dropdown-content';
import {Link} from "react-router-dom";


const BreadcrumbsDropdown = (
    {
        store,
        routerMatch,
        type = "Prematch",
        matchesDropdownShown,
        setMatchesDropdownShown,
    }
) => {
    const
        [dropdownItems, setDropdownItems] = useState([]),
        [initFetching, setInitFetching] = useState(false);

    const
        language = store.user.language,
        branch = store.betting.branch,
        branchId = store.betting.branchId,
        siteName = store.site.siteName,
        viewSize = store.site.status.viewSize,
        getTournamentMatches = store.betting.matches.initializeSingleInstruction,
        getAllTournamentMatches = store.betting.matches.initializeAllInstruction,
        getOutrightsMatches = store.betting.outrights.initializeOutrightsInstruction,
        activeMenuItems = store.betting.activeMenuItems,
        activeItems = store.betting.activeItems;

    routerMatch = routerMatch ?? store.router.prematchRouterMatchInstance;

    let bananaDefender = true;

    useEffect(() => () => {
        bananaDefender = false;
    }, []);

    const getFirstMatch = (tournament) => {
        return Object.values(tournament).length && Object.values(tournament)[0].length ?
            Object.values(tournament)[0][0]
            : null;
    }

    const formatListOfMatches = (tournamentId) => {
        const matches = (tournamentId === 'All' ?
            branch.activeCategory()?.allMatches
            :
            tournamentId === 'Outrights' || (type === "Outrights" && !tournamentId) ?
                branch.activeCategory()?.allOutrightMatches
                :
                [branch.activeCategory()?.tournaments.get(tournamentId)?.matchesGroupedByDate()]) || [];

        return matches.reduce((acc1, tournament, index) => {
            if (tournament && typeof tournament === 'object') {
                const acc2 = Object.keys(tournament).reduce((acc2, date) => {
                    const acc3 = tournament[date].reduce((acc3, match) => ([...acc3, {
                        type: 'match',
                        data: {
                            match,
                            isOutright: tournamentId === 'Outrights' || (viewSize !== 'mobile' && type === "Outrights"),
                            onClick: () => {
                                setMatchesDropdownShown(false);
                                store.popover.hidePopover();
                                store.router.push(match.matchUrl(branchId, true));
                            },
                        },
                    }]), []);
                    return [...acc2, {
                        type: 'date',
                        data: {
                            date: tournament[date].length ? tournament[date][0]?.timeStart : null,
                            key: index,
                        },
                    }, ...acc3];
                }, []);
                return [...acc1, {
                    type: 'breadcrumbs',
                    data: matches.length > 1 ? getFirstMatch(tournament) : null
                }, ...acc2];
            } else {
                return [];
            }
        }, []);
    };

    const tournamentOnClickHandler = ({tournament = {}, routerMatch} = {}) => {
        const params = {
            categoryId: routerMatch.categoryId,
            sportId: routerMatch.sportId,
            dropDownRequest: true,
        };
        if (tournament.id === 'All' || (
            viewSize === 'mobile' && !tournament.id &&
            store.betting.activeItems.firstTournament === 'All'
        )) {
            return getAllTournamentMatches(params);
        }
        if (tournament.id === 'Outrights' || (type === "Outrights" && !tournament.id)) {
            return getOutrightsMatches(params);
        }
        return getTournamentMatches({
            ...params,
            tournamentId: tournament.id || routerMatch.tournamentId,
            selfTournament: tournament.id ? tournament : null,
        });
    }

    useEffect(() => {
        if (bananaDefender && (
            activeMenuItems.firstTournament || activeMenuItems.firstCategory
        )) {
            if (viewSize === 'mobile') {
                if (activeMenuItems.firstTournament) {
                    let linkTournament;
                    switch (activeMenuItems.firstTournament) {
                        case 'All':
                            linkTournament = {id: 'All', name: trans('MENU_ALL')};
                            break;
                        case 'Outrights':
                            /**
                             * @crutch
                             *
                             * @desc - CK-671
                             *         Custom outrights name for sport 20040
                             *         and sport 25500
                             * @author S.Nakhodov
                             */
                            linkTournament = {
                                id: 'Outrights',
                                name: trans(`MENU_OUTRIGHTS${['25500', '20040'].includes(activeItems.sports[0])
                                    ? `_${activeItems.sports[0]}`
                                    : ''}`)
                            };
                            break;
                        default:
                            linkTournament =
                                branch.activeCategory().tournaments.get(activeMenuItems.firstTournament);
                    }

                    setDropdownItems([{
                        type: 'tournamentBack',
                        data: {
                            linkTournament,
                            onClick: (e) => {
                                e.stopPropagation();
                                activeMenuItems.setAllActiveMenuItems({
                                    categories: [branch.activeCategory().id],
                                });
                                const category = branch.activeCategory();
                                category.initializeTournamentsInstruction({id: category.id});
                            },
                        },
                    },
                        ...(formatListOfMatches(activeMenuItems.firstTournament) || [])
                    ]);
                    return true;
                }

                if (activeMenuItems.firstCategory) {
                    setDropdownItems([...branch.activeCategory().tournamentsList.reduce((acc, tournament) => [
                        ...acc,
                        {
                            type: 'tournament',
                            data: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    tournamentOnClickHandler({
                                        tournament,
                                        routerMatch,
                                    }).then(() => {
                                        activeMenuItems.setAllActiveMenuItems({
                                            categories: [],
                                            tournaments: [tournament.id],
                                        });
                                    })
                                },
                                tournament,
                            },
                        }
                    ], [])]);
                    return true;
                }
            } else {
                setDropdownItems([
                    ...(formatListOfMatches(routerMatch.tournamentId) || [])
                ]);
            }
        }
    }, [
        activeMenuItems.firstCategory,
        activeMenuItems.firstTournament,
        branch.activeCategory().tournaments.get(activeMenuItems.firstTournament)?.dropDownFetching,
        branch.activeTournament()?.dropDownFetching,
        initFetching
    ]);

    return (
        <ClickAwayListener
            onClickAway={() => { matchesDropdownShown && setMatchesDropdownShown(false) }}
            mouseEvent={'onMouseDown'}
            touchEvent={'onTouchStart'}
        >
            <div>
                { viewSize !== 'mobile' && (
                    <div className="prematch__breadcrumbs prematch__breadcrumbs--list">
                        <i
                            className={cx(
                                'prematch__breadcrumbs-sport-icon',
                                routerMatch && getSportIconsClassnames(
                                    config(siteName).siteConfig.appearanceRules.sportIcons,
                                    viewSize,
                                    routerMatch.sportId
                                )
                            )}
                        />
                        <div className="prematch__breadcrumbs-content">
                            <div className="prematch__breadcrumbs-text">
                                {routerMatch.nameOf('sport')}
                            </div>
                            <span className="prematch__breadcrumbs-separator">&gt;</span>
                            <div className="prematch__breadcrumbs-text">
                                <Link className="prematch__breadcrumbs-text-link" to={routerMatch.categoryUrl() + '/All'}>{routerMatch.nameOf('category')}</Link>
                            </div>
                            <span className="prematch__breadcrumbs-separator">&gt;</span>
                            <div className="prematch-breadcrumbs-dropdown__link   ll-breadcrumbs__menu-item--link prematch__breadcrumbs-text">
                                <span>{routerMatch.name ?? routerMatch.nameOf('tournament')}</span>
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className={cx(
                        'menu__board',
                        'll-breadcrumbs',
                        'prematch-breadcrumbs-dropdown',
                        `prematch-breadcrumbs-dropdown--${viewSize}`
                    )}
                    onClick={() => {
                        if (!matchesDropdownShown) {
                            setInitFetching(true);
                            tournamentOnClickHandler({
                                tournament:
                                    store.betting.activeItems.firstTournament !== 'All' ?
                                        branch.activeTournament() ?? branch.activeCategory().tournaments.get(routerMatch.tournamentId)
                                        : {},
                                routerMatch,
                            }).then(() => {
                                activeMenuItems.setActiveItem({
                                    type: 'tournaments',
                                    ids: [type === "Outrights" ? "Outrights" :
                                        store.betting.activeItems.firstTournament
                                    ]
                                });
                                setInitFetching(false);
                                setMatchesDropdownShown(true);
                            });
                        } else {
                            setMatchesDropdownShown(false);
                        }
                    }}
                >
                    <BreadcrumbsDropdownContent
                        {...{
                            matchesDropdownShown,
                            activeMenuItems,
                            dropdownItems,
                            branch,
                            viewSize,
                            language,
                        }}
                    />
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default inject('store')(observer(BreadcrumbsDropdown));