import { getRoot, types } from 'mobx-state-tree';

import { isBase64 } from '../../../common/utils';
import { pagesConfig } from '../../../configs';
import { branchConfig } from '../betting/config';
import { API_PREFIX } from "../../../common/api/api-url-config";
import apiUrls from "../../../common/api/api-urls";
import config from "../../../static/themes/config";


/**
 * @desc api docs https://github.com/ReactTraining/history/tree/master/docs
 */

const RouterModel = types
    .model('RouterModel', {
        location: types.optional(types.frozen()),
        previousLocation: types.optional(types.frozen()),
        currentPage: types.maybeNull(types.string),
        action: types.optional(types.string, ''),
        cameFromDrawer: false,
    })
    .actions((s) => {
        // "volatile" state to store history instance from "history" library
        let history;

        return {
            _scrollTop(newPage) {
                if (
                    s.currentPage !== newPage &&
                    getRoot(s)?.scrollBoxes.getScrollBox('main-scroll')
                ) {
                    getRoot(s)
                        .scrollBoxes.getScrollBox('main-scroll')
                        .scrollMethods.scrollToTop();
                }
            },
            _updateLocation(newState) {
                s.cameFromDrawer = getRoot(s).drawer.isActive;
                getRoot(s).site.status.setBackDropFullScreen(false);
                getRoot(s).site.status.setBackdropShown(false);
                getRoot(s).modal.close();
                getRoot(s).drawer.close();

                s._scrollTop(newState?.pathname.split('/')[1]);

                const newPage = newState?.pathname.split('/')[1];

                const currentPage = pagesConfig(getRoot(s).site.siteName) ? Object.values(pagesConfig(getRoot(s).site.siteName)).find(
                    (item) => item.url?.split('/')[1] === newPage
                ) : {};

                if (!currentPage?.loginRequired || getRoot(s).user.isLogged) {
                    s._scrollTop(newPage);
                } else if (
                    currentPage?.allowedViewSizes.includes(
                        getRoot(s).site.status.viewSize
                    )
                ) {
                    setTimeout(() => getRoot(s).user.openLogin(), 500);
                }

                s.currentPage = newPage;
                s.location = newState;
                s.action = history.action;

                if (s.location.search.includes('launch=') && !getRoot(s).user.mobileUserAgent) {
                    const params = s.location.search.substring(s.location.search.indexOf('launch=') + 7, s.location.search.length);
                    if (params === 'virtual') {
                        getRoot(s).modal.setGame({
                            gameId: 'bundesliga',
                            gameUrl: `${API_PREFIX}${apiUrls.virtual.play}?id=vfb`,
                        });
                    } else if (params === 'random') {
                        getRoot(s).modal.setGame({
                            gameId: 'random',
                            gameUrl: `${API_PREFIX}${apiUrls.games.random}?type=${(s.location.pathname.includes('casino') ? "casino" : "slot")}&demo=0`,
                            mode: 0,
                            onCloseGoTo: s.location.pathname
                        })
                        getRoot(s).user.setPlayingGameId('random');
                    } else {
                        const paramsArr = params.split('-');
                        const mode = paramsArr[0];
                        const providerId = paramsArr[1];
                        const gameId = paramsArr[2];
                        const gameHeight = paramsArr[3];
                        const gameWidth = paramsArr[4];
                        getRoot(s).modal.setGame({
                            gameId: gameId,
                            gameUrl: `${API_PREFIX}${apiUrls.games.openGame}?providerId=${providerId}&gameId=${gameId}&demo=${mode}&height=${gameHeight}&width=${gameWidth}`,
                            mode: mode,
                            gameHeight: gameHeight,
                            gameWidth: gameWidth,
                            onCloseGoTo: s.location.pathname,
                            providerId: providerId
                        })
                        if (gameId) getRoot(s).user.setPlayingGameId(gameId);
                    }
                } else {
                    getRoot(s).modal.removeActiveGame();
                    getRoot(s).user.setPlayingGameId(null);
                }

                if (s.location.pathname.includes('slots-battles')) {
                    if (getRoot(s).site.slotBattlesConfig.slotBattlesDisabled) {
                        s.push('/');
                    }
                    let gameId = s.location.pathname.split('/')[2];
                    if (gameId && gameId.includes('?launch=')) {
                        gameId = gameId.substring(0, gameId.indexOf('?launch='))
                    }
                    if (gameId === 'create') {
                        const user = getRoot(s).user;
                        if (!user.isLogged || user.userBattleId || !user.canPlaySlotBattles) {
                            s.push('/slots-battles/available');
                        } else {
                            getRoot(s).slotsBattles.setActiveBattle(null);
                            getRoot(s).slotsBattles.setSlotBattlesType('create');
                        }
                    } else if (['available', 'started', 'completed'].includes(gameId)) {
                        getRoot(s).slotsBattles.setActiveBattle(null);
                        getRoot(s).slotsBattles.setSlotBattlesType(gameId);
                    } else if (gameId) {
                        getRoot(s).slotsBattles.setActiveBattle(gameId);
                    } else {
                        if (!['available', 'started', 'completed'].includes(gameId)) {
                            getRoot(s).slotsBattles.setSlotBattlesType('available');
                        }
                        getRoot(s).slotsBattles.setActiveBattle(null);
                    }
                    return true;
                } else {
                    getRoot(s).slotsBattles.setActiveBattle(null);
                    getRoot(s).slotsBattles.setSlotBattlesType('available');
                }

                if (s.location.pathname.includes('slots') || s.location.pathname.includes('casino')) {
                    const viewType = s.gamesPathParams.viewType ?? '';
                    const viewTypeArray = [];
                    const viewValueArray = [];
                    if (viewType.includes('.')) {
                        const viewTypesArray = viewType.split('.');
                        viewTypesArray.map(type => {
                            viewTypeArray.push(type.split('=')[0]);
                            viewValueArray.push(type.split('=')[1]);
                        })
                    } else if (viewType.includes('=')) {
                        viewTypeArray.push(viewType.split('=')[0]);
                        viewValueArray.push(viewType.split('=')[1]);
                    } else if (viewType) {
                        viewTypeArray.push(viewType)
                    } else {
                        viewTypeArray.push('featured')
                    }
                    getRoot(s).slotsCasinoGames.setViewType(viewTypeArray, viewValueArray);
                }

                if (s.location.pathname.includes('lobby')) {
                    const searchParams = s.location.search.split('=');
                    const providerId = Number(searchParams[1]);
                    const isProviderIdExist = searchParams && searchParams[0].includes('providerId');
                    getRoot(s).lobbies.setProviderId(isProviderIdExist ? providerId : null);
                }

                /**** ~~ Set branch requested by router or branch-picker  ****/
                const branchNameById = Object.keys(branchConfig).find(
                    (item) =>
                        branchConfig[item].routerId === s.prematchPathParams.branchId
                );
                getRoot(s).betting.setBranch(branchNameById ? branchNameById : 'All');
                getRoot(s).betting.liveMatches.setCalendar(false);

                if (s.prematchPathParams.branchId) {
                    //if we on sport page
                    const betting = getRoot(s).betting;

                    if (s.prematchPathParams.branchId === 'live') {
                        !s.prematchPathParams.sportId && betting.clearAllErrors();
                        betting.liveMatches.setActiveLiveView(
                            s.prematchPathParams.matchId ? 'detail' : 'menu'
                        );
                        if (s.prematchPathParams.sportId && s.prematchPathParams.matchId) {
                            betting.activeMenuItems.addActiveMenuItems({
                                sports: [s.prematchPathParams.sportId]
                            })
                        }
                    }

                    if (!s.prematchPathParams.sportId) {
                        let exception;
                        // let allowedView;
                        // if (getRoot(s).site.status.viewSize === 'mobile') {
                        //     allowedView =
                        //         s.mobilePrematchDashboardView.view === '' &&
                        //         s.prematchPathParams.branchId !== 'live';
                        // } else {
                        //     allowedView = ['topFavoriteMatches', ''].includes(
                        //         s.prematchDashboardView.view
                        //     );
                        // }
                        // if (allowedView && betting.bettingErrors.global) {
                        //     todo: подумать, нужно ли это и как понимать, что у меня сломалось на обновлении страницы без выбранного турнамента
                        //     exception = 'global';
                        // }
                        betting.clearAllErrors(exception);
                    } else {
                        betting.clearError({ type: 'list' });
                    }

                    betting.activeItems.parseUrl(s.prematchPathParams);
                }
            },

            _updatePreviousLocation(oldState) {
                s.previousLocation = oldState;
            },

            _setHistory(initialHistory) {
                history = initialHistory;
            },

            push() {
                history.push(...arguments);
            },

            replace(location) {
                history.replace(...arguments);
            },

            go(n) {
                history.go(n);
            },

            goBack(url = '/') {
                if (s.previousLocation?.pathname) {
                    history.goBack();
                } else {
                    history.push(url ? url : '/');
                }
            },

            goForward() {
                history.goForward();
            },

            block() {
                history.block(...arguments);
            },
            clearLaunchFromURL() {
                if (s.location && s.location.search !== '') {
                    s.replace({
                        ...s.location,
                        search: ''
                    });
                }
            },
        };
    })
    .views((s) => ({
        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                   Prematch getters                                   ##
        // ##                                                                                      ##
        // ##========================================================================================

        get previousPrematchPathParams() {
            let urlArray = [];
            if (s.previousLocation?.pathname) {
                urlArray = s.previousLocation.pathname.split('/');
                urlArray = urlArray.slice(2);
            }
            const [branchId, sportId, categoryId, tournamentId, matchId] = [...urlArray];
            return { branchId, sportId, categoryId, tournamentId, matchId };
        },

        get prematchPathParams() {
            let urlArray = s.location.pathname.split('/');
            urlArray = urlArray.slice(2);
            const [branchId, sportId, categoryId, tournamentId, matchId, matchQueryParam] = [...urlArray];

            return { branchId, sportId, categoryId, tournamentId, matchId, matchQueryParam };
        },

        get gamesPathParams() {
            let urlArray = s.location.pathname.split('/');
            urlArray = urlArray.slice(1);
            const [type, viewType, jackpotId] = [...urlArray];

            return { type, viewType, jackpotId };
        },

        get prematchRouterMatchInstance() {
            if (s.prematchPathParams?.matchId) {
                return getRoot(s).betting.matches.matches.get(
                    s.prematchPathParams?.matchId
                );
            }
            return null;
        },

        get livematchRouterMatchInstance() {
            if (s.prematchPathParams?.matchId) {
                return getRoot(s).betting.liveMatches.matches.get(
                    s.prematchPathParams?.matchId
                );
            }
            return null;
        },

        get prematchRouterOutrightInstance() {
            if (
                s.prematchPathParams?.tournamentId === 'Outrights' &&
                s.prematchPathParams?.matchId
            ) {
                return getRoot(s).betting.outrights.matches.get(
                    isBase64(s.prematchPathParams.matchId)
                );
            }
            return null;
        },

        get prematchDashboardView() {
            const { tournamentId, matchId, sportId } = s.prematchPathParams;

            const betting = getRoot(s).betting;

            if (s.prematchPathParams.branchId === 'live') {
                return {
                    component: 'Live',
                    view: '',
                    isLoading: betting.branch.initialFetching,
                };
            }

            if (betting.rangedEvents.rangedEventsConfig[sportId] && sportId !== 'popular') {
                return {
                    component: 'LLMatchesList',
                    view: 'rangedEvents',
                    isLoading: betting.rangedEvents.events.get(sportId)?.initialFetching ?? true,
                };
            }

            if (tournamentId === 'Outrights' && matchId) {
                return {
                    component: 'LLOutrightDetails',
                };
            }

            if (!matchId) {
                // ##========================================================================================
                // ##                                                                                      ##
                // ##                                  MatchList variaton                                  ##
                // ##                                                                                      ##
                // ##========================================================================================
                if (tournamentId === 'Outrights') {
                    return {
                        component: 'LLMatchesList',
                        view: 'outrights',
                        isLoading: betting.outrights.initialFetching,
                    };
                }

                if (tournamentId === 'All' && !matchId) {
                    return {
                        component: 'LLMatchesList',
                        view: 'allTournamentsMatches',
                        isLoading: betting.matches.initialFetching,
                    };
                }

                if (tournamentId) {
                    return {
                        component: 'LLMatchesList',
                        view: 'singleTournamentMatches',
                        isLoading: betting.matches.initialFetching,
                    };
                }
                if (!tournamentId) {
                    return {
                        component: 'LLMatchesList',
                        view: 'topFavoriteMatches',
                        isLoading: betting.branch.initialFetching,
                    };
                }
            }

            // ##========================================================================================
            // ##                                                                                      ##
            // ##                                     Match Details                                    ##
            // ##                                                                                      ##
            // ##========================================================================================
            if (tournamentId && matchId) {
                return {
                    component: 'LLMatchDetails',
                    view: '',
                    isLoading: betting.matches.matches.get(matchId)?.initialFetching,
                };
            }

            return null;
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                       Difine what to show at mobile prematches                       ##
        // ##                                                                                      ##
        // ##========================================================================================

        get mobilePrematchDashboardView() {
            const { sportId, categoryId, tournamentId, matchId } = s.prematchPathParams;
            const betting = getRoot(s).betting;

            if (sportId) {
                var sportIds = sportId.split('-');
                var sport = betting.branch.sports.get(sportIds[sportIds.length - 1]);
            }
            if (categoryId) {
                var categoryIds = categoryId.split('-');
                var category = sport?.categories.get(
                    categoryIds[categoryIds.length - 1]
                );
            }
            if (tournamentId) {
                var tournament = category?.tournaments.get(tournamentId);
                var matches = betting.matches;
                var liveMatches = betting.liveMatches;
                var outrights = betting.outrights;
            }
            if (matchId) {
                var liveMatch = liveMatches.matches.get(matchId);
                var match = matches.matches.get(matchId);
                var outright = outrights.matches.get(isBase64(matchId));
            }

            if (s.prematchPathParams.branchId === 'live') {
                if (!matchId) {
                    return {
                        component: 'LiveList',
                        view: '',
                        isLoading: betting.branch.initialFetching,
                    };
                } else {
                    return {
                        component:
                            liveMatch?.initialFetching && betting.branch.initialFetching
                                ? 'LiveList'
                                : 'LiveDetail',
                        view: '',
                        isLoading: betting.branch.initialFetching || !category,
                    };
                }
            }

            if (betting.rangedEvents.rangedEventsConfig[sportId]) {
                if (sportId === 'popular') {
                    return {
                        component: 'LLMenuMobile',
                        view: 'rangedEvents',
                        isLoading: betting.matches.initialFetching,
                    };
                } else {
                    return {
                        component: 'LLMenuMobile',
                        view: 'rangedEvents',
                        isLoading: betting.rangedEvents.events.get(sportId)?.initialFetching ?? true,
                    };
                }
            }

            if (tournamentId === 'Outrights') {
                if (matchId) {
                    return {
                        component: !outright?.initialFetching
                            ? 'LLOutrightsDetailsMobile'
                            : 'LLOutrightListMobile',
                        view: !outright?.initialFetching ? '' : 'outrights',
                        isLoading: betting.branch.initialFetching,
                    };
                } else {
                    return {
                        component: !outrights?.initialFetching
                            ? 'LLOutrightListMobile'
                            : 'LLMenuMobile',
                        view: !outrights?.initialFetching ? 'outrights' : 'tournaments',
                        isLoading: betting.branch.initialFetching,
                    };
                }
            }

            if (tournamentId === 'All' && !matchId) {
                return {
                    component: !category?.initialAllMatchesFetching
                        ? 'LLMatchListMobile'
                        : 'LLMenuMobile',
                    view: !category?.initialAllMatchesFetching
                        ? 'allMatches'
                        : 'tournaments',
                    isLoading: betting.branch.initialFetching,
                };
            }

            if (!matchId && tournamentId) {
                return {
                    component: !tournament?.initialFetching
                        ? 'LLMatchListMobile'
                        : 'LLMenuMobile',
                    view: !tournament?.initialFetching ? 'singleMatches' : 'tournaments',
                    isLoading:
                        betting.branch.initialFetching ||
                        (s.previousPrematchPathParams?.matchId &&
                            matches?.initialFetching),
                };
            }

            if (tournamentId && matchId) {
                return {
                    component: !match?.initialFetching
                        ? 'LLMatchDetailsMobile'
                        : 'LLMatchListMobile',
                    view: !match?.initialFetching
                        ? ''
                        : tournamentId === 'All'
                            ? 'allMatches'
                            : 'singleMatches',
                    isLoading: (betting.branch.initialFetching || match?.initialFetching),
                };
            }

            if (categoryId) {
                if (categoryIds[categoryIds.length - 1] || !categoryId.includes('-')) {
                    return {
                        component: 'LLMenuMobile',
                        view: !category?.initialFetching
                            ? 'tournaments'
                            : categoryId.includes('-')
                                ? 'subCategories'
                                : 'categories',
                        isLoading: betting.branch.initialFetching,
                    };
                } else {
                    return {
                        component: 'LLMenuMobile',
                        view: 'subCategories',
                        isLoading: betting.branch.initialFetching,
                    };
                }
            }

            if (sportId) {
                if (sportIds[sportIds.length - 1] || !sportId.includes('-')) {
                    return {
                        component: 'LLMenuMobile',
                        view: !sport?.initialFetching
                            ? 'categories'
                            : sportId.includes('-')
                                ? 'subSports'
                                : 'sports',
                        isLoading: betting.branch.initialFetching,
                    };
                } else {
                    return {
                        component: 'LLMenuMobile',
                        view: 'subSports',
                        isLoading: betting.branch.initialFetching,
                    };
                }
            }

            if (!sportId) {
                return {
                    component: 'LLMenuMobile',
                    view: 'sports',
                    isLoading: betting.branch.initialFetching,
                };
            }

            return null;
        },

        pageParams(page) {
            return s.location.pathname.replace('/' + page + '/', '');
        },
    }));

export default RouterModel;