import { types, flow, getRoot } from 'mobx-state-tree';
import { values } from 'mobx';

import {vendorTextReplacer} from '../../../common/utils';
import { llNotifier } from '../../../components';
import api from '../../../common/api';

import {TIMERS} from '../../config';


const PromoItem = types.model('PromoItem', {
    descr: types.maybeNull(types.string),
    id: types.identifierNumber,
    idt: types.string,
    imgDesktop: types.maybeNull(types.string),
    imgMobile: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    rules: types.maybeNull(types.string),
    isRequestEnabled: types.maybeNull(types.boolean),
});

const Promos = types
    .model('Promos', {
        items: types.array(PromoItem),
        promoId: types.maybeNull(types.number),
        fetchTime: types.maybeNull(types.number),
        modalType: types.maybeNull(types.enumeration('Modal', ['descr', 'rules'])),
    })
    .actions((self) => ({
        _set(promoData) {
            self.items = [];
            promoData.map((item) => self.items.push(
                PromoItem.create({
                    descr: item.descr ? vendorTextReplacer(item.descr.toString()) : null,
                    id: item.id,
                    idt: item.idt,
                    imgDesktop: item.imgDesktop ? vendorTextReplacer(item.imgDesktop.toString()) : null,
                    imgMobile: item.imgMobile ? vendorTextReplacer(item.imgMobile.toString()) : null,
                    name: item.name ? vendorTextReplacer(item.name.toString()) : null,
                    rules: item.rules ? vendorTextReplacer(item.rules.toString()) : null,
                    isRequestEnabled: !!item.isRequestEnabled,
                })
            ))
            self.fetchTime = Date.now();
        },

        openModal(promoId, modalType) {
            const setModal = getRoot(self).modal.setModal;

            self.promoId = promoId;
            self.modalType = modalType;

            setModal({
                active: true,
                currentKey: 'promo-modal',
                maskBackground: true,
                showCloseButton: true,
            });
        },

        getPromos: flow(function* fetch() {
            // Stop request if 'fetchTime' exist (not null) and TIMEOUT do not left
            // 'fetchTime' will be create after first request for promos data
            if (self.fetchTime && self.fetchTime + TIMERS.promoFetchTimer < Date.now()) return;

            try {
                const res = yield api.promo.getPromosData() || {};
                if (res.success) {
                    self._set(res.data);
                }
            } catch (err) {
                self.fetchTime = null;
                llNotifier({
                    message: 'GENERAL_ERROR',
                    type: 'error',
                });
            }
        }),

        sendPromo: flow(function* fetch(bonusId) {
            const root = getRoot(self);

            if (!root.user.isLogged) {
                root.modal.close();
                root.user.openLogin();
                return;
            }

            try {
                const res = yield api.promo.sendPromo({ bonusId }) || {};
                if (!res.success) {
                    llNotifier({
                        message: res.data?.error,
                        type: 'error',
                    });
                } else {
                    llNotifier({
                        message: 'We have received your bonus request',
                        type: 'success',
                    });
                }
            } catch (err) {
                llNotifier({
                    message: 'GENERAL_ERROR',
                    type: 'error',
                });
            }
        }),
    }))
    .views((self) => ({
        get list() {
            return values(self.items);
        },

        get modalContent() {
            return self.items.find((promo) => promo.id === self.promoId);
        },
    }));

export default Promos;
