import { flow, getRoot, isAlive } from 'mobx-state-tree';

import { refreshTime, unsuccessCountLimit } from '../../config';

const topFavortieInstructions = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                Scenarios instructions                                ##
    // ##                                                                                      ##
    // ##========================================================================================

    unsuccessTopAndFavoriteInitializeInstruction({ res, branchId } = {}) {
        const error =
            res && !res.success && res.topMatches?.error && res.topMatches?.error !== 'NOT_FOUND'
                ? res.topMatches?.note ?? res.topMatches?.error
                : 'CONNECTION_ISSUES_ERROR';

        getRoot(s).betting.branchId === branchId && getRoot(s).betting.setError({
            type: 'global',
            error,
            title: res.topMatches?.error === 'SERVICE_CLOSED' ? 'Information Message' : null
        });
    },

    unsuccessTopAndFavoriteUpdateInstruction({ res, branchId } = {}) {
        s.setUnsuccessCount(s.unsuccessCount + 1);
        const betting = getRoot(s).betting;
        const error =
            res && !res.success && res.topMatches?.error && res.topMatches?.error !== 'NOT_FOUND'
                ? res.topMatches?.note ?? res.topMatches?.error
                : 'CONNECTION_ISSUES_ERROR';

        if ((s.unsuccessCount >= unsuccessCountLimit || res.topMatches?.error === 'SERVICE_CLOSED') && betting.branchId === branchId) {
            if (res.topMatches?.error === 'SERVICE_CLOSED') {
                betting.setError({
                    type: 'global',
                    error,
                    title: 'Information Message'
                });
            } else {
                betting.setError({type: 'list', error});
            }
        }

        s.setWaitingItem('topAndFavorites', false);
        s.defineUpdateFor({ instance: 'TopAndFavorite' });
    },

    // ---

    successTopAndFavoriteInitializeInstruction({ topMatches, favoriteMatches, branchId } = {}) {
        s.setUnsuccessCount(0);

        s.setMatchesData({ data: topMatches, isTop: true, branchId });
        s.setMatchesData({ data: favoriteMatches, isFavorite: true, branchId });

        s.setFetching({ type: 'initialFetching', status: false });

        s.setWaitingItem('topAndFavorites', false);
        s.defineUpdateFor({ instance: 'TopAndFavorite' });
    },

    // ---

    successTopAndFavoriteUpdateInstruction({ topMatches, favoriteMatches, branchId } = {}) {
        s.setUnsuccessCount(0);

        if (!Object.keys(topMatches).length) {
            getRoot(s).betting.setError({ type: 'list', error: 'EMPTY_BETS_ERROR' });
        }

        s.setMatchesData({ data: topMatches, isTop: true, branchId });
        s.setMatchesData({ data: favoriteMatches, isFavorite: true, branchId });

        s.setFetching({ type: 'isFetching', status: false });

        s.setWaitingItem('topAndFavorites', false);
        s.defineUpdateFor({ instance: 'TopAndFavorite' });
    },

    // ##========================================================================================

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                     Data getter for top and favorite tournaments                     ##
    // ##                                                                                      ##
    // ##========================================================================================

    initializeTopAndFavoriteInstruction: flow(function* fetch() {
        initializeTopAndFavoriteInstruction: {
            if (isAlive(s)) {
                s.clearUpdater();

                if (s.check.isMobile &&
                    getRoot(s).betting.activeItems.sports.length &&
                    getRoot(s).betting.activeItems.sports[0] !== 'popular'
                ) {
                    s.setWaitingItem('topAndFavorites', false);
                    clearTimeout(window.__matchesUpdater);
                    break initializeTopAndFavoriteInstruction;
                }
                const branch = getRoot(s).betting.branch;
                if (
                    branch.topAndFavoritesLastFetchTime &&
                    Date.now() <
                        +new Date(
                            +branch.topAndFavoritesLastFetchTime + refreshTime.matchesList
                        )
                ) {
                    const timeLeft =
                        +new Date(+branch.topAndFavoritesLastFetchTime + refreshTime.sports) -
                        Date.now();
                    s.setWaitingUpdate(false);
                    s.defineUpdateFor({ instance: 'TopAndFavorite', timeLeft });
                    break initializeTopAndFavoriteInstruction;
                }
                s.clearUpdater();
                s.setFetching({ type: 'initialFetching', status: true });
                s.setWaitingItem('topAndFavorites', true);

                const branchId = getRoot(s).betting.branchId;

                const res = {
                    ...(yield s.fetchAndConvertDataForTopAndFavorite()),
                };

                if (!s.check.isTopAndFavoriteUpdateCleanable) {
                    if (!res || !res.success || !Object.keys(res.topMatches).length) {
                        s.unsuccessTopAndFavoriteInitializeInstruction({ res, branchId });
                    } else {
                        s.successTopAndFavoriteInitializeInstruction({
                            topMatches: res.topMatches,
                            favoriteMatches: res.favoriteMatches,
                            branchId
                        });
                    }
                } else {
                    s.setFetching({ type: 'initialFetching', status: false });
                }
                branch.setTopAndFavoritesLastFetchTime();
            }
        }
    }),

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                            Update data for top tournaments                           ##
    // ##                                                                                      ##
    // ##========================================================================================

    updateForTopAndFavoriteInstruction: flow(function* fetch() {
        updateForTopAndFavoriteInstruction: {
            if (s.check.isTopAndFavoriteUpdateCleanable) {
                s.setWaitingItem('topAndFavorites', false);
                clearTimeout(window.__matchesUpdater);
                break updateForTopAndFavoriteInstruction;
            }

            if (!s.check.isMatchesUpdatable) {
                s.setWaitingItem('topAndFavorites', false);
                s.defineUpdateFor({ instance: 'TopAndFavorite' });
                break updateForTopAndFavoriteInstruction;
            }

            s.setFetching({ type: 'isFetching', status: true });

            const branch = getRoot(s).betting.branch;

            let res = {
                ...(yield s.fetchAndConvertDataForTopAndFavorite()),
            };

            if (!s.check.isTopAndFavoriteUpdateCleanable) {
                if (!res || !res.success) {
                    s.unsuccessTopAndFavoriteUpdateInstruction({ res, branchId: branch.id });
                } else {
                    s.successTopAndFavoriteUpdateInstruction({
                        topMatches: res.topMatches,
                        favoriteMatches: res.favoriteMatches,
                        branchId: branch.id,
                    });
                }
            }
            branch.setTopAndFavoritesLastFetchTime();
        }
    }),
});

export default topFavortieInstructions;
