import {flow, getRoot, isAlive} from 'mobx-state-tree';

import {refreshTime, unsuccessCountLimit} from '../../config';
import trans from '../../../../../common/utils/trans';

const allInstructions = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                Scenarios instructions                                ##
    // ##                                                                                      ##
    // ##========================================================================================

    unsuccessAllInitializeInstruction({res, branchId} = {}) {
        const error =
            res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                ? res.data?.note ?? res.data?.error
                : 'CONNECTION_ISSUES_ERROR';

        getRoot(s).betting.branchId === branchId && getRoot(s).betting.setError({
            type: 'global',
            error,
            title: res.data?.error === 'SERVICE_CLOSED' ? 'Information Message' : null
        });
    },

    unsuccessAllUpdateInstruction({res, sportId, categoryId, branchId} = {}) {
        s.setUnsuccessCount(s.unsuccessCount + 1);
        const betting = getRoot(s).betting;
        const error =
            res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                ? res.data?.note ?? res.data?.error
                : 'CONNECTION_ISSUES_ERROR';

        if ((s.unsuccessCount >= unsuccessCountLimit || res.data?.error === 'SERVICE_CLOSED') && betting.branchId === branchId) {
            if (res.data?.error === 'SERVICE_CLOSED') {
                betting.setError({
                    type: 'global',
                    error,
                    title: 'Information Message'
                });
            } else {
                betting.setError({type: 'list', error});
            }
        } else {
            s.setWaitingItem('all', null);
            s.defineUpdateFor({
                instance: 'All',
                categoryId,
                sportId,
            });
        }
    },

    successAllInitializeInstruction({sportId, categoryId, incomingMatchData, branchId, dropDownRequest}) {
        s.setUnsuccessCount(0);
        const betting = getRoot(s).betting;

        if (incomingMatchData.length === 0 && betting.branch.initialFetching && getRoot(s).betting.branchId === branchId) {
            setTimeout(() => {
                getRoot(s).modal.setModal({
                    active: true,
                    maskBackground: true,
                    showHeader: true,
                    currentKey: 'custom-content',
                    title: trans('EVENT_NOT_FOUND'),
                    customContent: trans('EVENT_STARTED'),
                });
            }, 500);
        } else {
            s.setMatchesData({data: incomingMatchData, branchId, dropDownRequest});

            s.setWaitingItem('all', null);
            s.defineUpdateFor({
                instance: 'All',
                categoryId,
                sportId,
                dropDownRequest,
            });
        }
    },

    successAllUpdateInstruction({sportId, categoryId, incomingMatchData, branchId, dropDownRequest}) {
        s.setUnsuccessCount(0);

        if (incomingMatchData.length === 0 && getRoot(s).betting.branchId === branchId) {
            getRoot(s).betting.setError({type: 'list', error: 'EMPTY_BETS_ERROR'});
        } else {
            s.setMatchesData({data: incomingMatchData, branchId, dropDownRequest});
        }

        s.setWaitingItem('all', null);
        s.defineUpdateFor({
            instance: 'All',
            categoryId,
            sportId,
            dropDownRequest,
        });
    },

    // ##========================================================================================

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                  Data getter for all tournament of specific category                 ##
    // ##                                                                                      ##
    // ##========================================================================================

    initializeAllInstruction: flow(function* fetch({sportId, categoryId, dropDownRequest= false}) {
        initializeAllInstruction: {
            if (isAlive(s)) {
                s.clearUpdater();
                const activeCategory = getRoot(s).betting.branch.activeCategory();

                if (s.check.categoryLastFetchTimeNotExpired) {
                    const timeLeft =
                        +new Date(
                            +activeCategory?.lastAllMatchesFetchTime +
                            refreshTime.matchesList
                        ) - Date.now();
                    s.setWaitingItem('all', null);
                    s.defineUpdateFor({
                        instance: 'All',
                        categoryId,
                        sportId,
                        timeLeft,
                        dropDownRequest,
                    });
                    break initializeAllInstruction;
                }

                activeCategory && activeCategory.setInitialAllMatchesFetching(true);
                s.setFetching({type: 'initialFetching', status: true});
                s.setWaitingItem('all', categoryId);

                const branchId = getRoot(s).betting.branchId;

                const res = {
                    ...(yield s.fetchAndConvertDataForAll({
                        categoryId: categoryId,
                    })),
                };

                if (!res || !res.success) {
                    s.unsuccessAllInitializeInstruction({
                        res,
                        categoryId,
                        sportId,
                        branchId
                    });
                    if (getRoot(s).betting.branch.initialFetching && getRoot(s).betting.branchId === branchId) {
                        throw new Error(res.error);
                    }
                } else {
                    s.successAllInitializeInstruction({
                        categoryId,
                        sportId,
                        incomingMatchData: res.data,
                        branchId,
                        dropDownRequest
                    });
                }

                s.setFetching({type: 'initialFetching', status: false});
                if (activeCategory) {
                    activeCategory.setInitialAllMatchesFetching(false);
                    activeCategory.setLastAllMatchesFetchTime();
                }
            }
        }
    }),

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                            Update data for all tournaments                           ##
    // ##                                                                                      ##
    // ##========================================================================================

    updateForAllInstruction: flow(function* fetch({sportId, categoryId, dropDownRequest= false}) {
        updateForAllInstruction: {
            if (isAlive(s)) {
                const activeCategory = getRoot(s).betting.branch.activeCategory();

                if (s.check.isAllUpdateCleanable) {
                    s.setWaitingItem('all', null);
                    break updateForAllInstruction;
                }
                if (!s.check.isMatchesUpdatable) {
                    s.setWaitingItem('all', null);
                    s.defineUpdateFor({
                        instance: 'All',
                        categoryId,
                        sportId,
                        dropDownRequest
                    });
                    break updateForAllInstruction;
                }

                s.setFetching({type: 'isFetching', status: true});

                const branchId = getRoot(s).betting.branchId;

                const res = {
                    ...(yield s.fetchAndConvertDataForAll({
                        categoryId: categoryId,
                    })),
                };

                if (!res || !res.success) {
                    s.unsuccessAllUpdateInstruction({
                        res,
                        sportId,
                        categoryId,
                        branchId,
                        dropDownRequest
                    });
                } else {
                    s.successAllUpdateInstruction({
                        sportId,
                        categoryId,
                        incomingMatchData: res.data,
                        branchId,
                        dropDownRequest
                    });
                }
                s.setFetching({type: 'isFetching', status: false});
                activeCategory && activeCategory.setLastAllMatchesFetchTime();
            }
        }
    }),
});

export default allInstructions;
