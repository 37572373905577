import React from 'react';
import {inject, observer} from "mobx-react";
import { NavLink } from 'react-router-dom';
import {sortBy} from "lodash";
import cx from 'classnames';

import { trans } from '../../../../common/utils';
import {
    LLTextSlider
} from '../../../../components';


const RangedEventsPicker = ({ store, store: {
    betting: {
        activeItems: { firstActiveSport },
        rangedEvents: { rangedEventsConfig },
    },
    user: {
        mobileUserAgent
    }
} }) => {
    const viewSize = store.site.status.viewSize;
    const activeItem = firstActiveSport || (viewSize !== 'mobile' ? 'popular' : '');

    return Object.values(rangedEventsConfig).length > 1 ? (
        <div className={cx(
            'ranged-events-picker',
            `ranged-events-picker--${viewSize}`
        )}>
            <LLTextSlider
                id="ranged-events-picker-slider"
                viewSize={viewSize}
                withAbsoluteArrows={true}
                showArrows={!mobileUserAgent}
                customClasses={{
                    slider: 'ranged-events-picker__slider',
                    arrow: 'ranged-events-picker__slider-arrow',
                }}
                activeItem={activeItem}
            >
                { sortBy(Object.values(rangedEventsConfig), 'order', 'asc')
                    .map(event => (
                        <NavLink
                            className={cx(
                                'ranged-events-picker__slider-item',
                                activeItem === event.id || (
                                    viewSize !== 'mobile' &&
                                    store.router.prematchDashboardView.view === event.view
                                ) ? 'ranged-events-picker__slider-item--active' : ''
                            )}
                            to={`/sport/1/${event.url[viewSize]}`}
                            id={event.id}
                            key={event.id}
                        >
                            {trans(event.name)}
                        </NavLink>
                    ))}
            </LLTextSlider>
        </div>
    ) : (<></>);
};

export default  inject('store')(observer(RangedEventsPicker));
