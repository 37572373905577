import { types, flow } from 'mobx-state-tree';

import { llNotifier } from '../../../../components';
import api from '../../../../common/api';
import { llattempt } from '../../../../common/utils';

const Info = types
    .model('Info', {
        fetchTime: types.maybeNull(types.number),
        address: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        dateOfBirth: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        idNumber: types.maybeNull(types.string), // it's passport
        name: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        surname: types.maybeNull(types.string),
    })
    .actions((self) => ({
        _set(data) {
            self.address = data.CustomerAddress ? data.CustomerAddress.toString() : '';
            self.city = data.CustomerCity ? data.CustomerCity.toString() : '';
            self.country = data.CustomerCountry ? data.CustomerCountry.toString() : '';
            self.dateOfBirth = data.CustomerDateOfBirth ? data.CustomerDateOfBirth.toString() : '';
            self.email = data.CustomerEMail ? data.CustomerEMail.toString() : '';
            self.idNumber = data.CustomerIDNumber ? data.CustomerIDNumber.toString() : '';
            self.name = data.CustomerName ? data.CustomerName.toString() : '';
            self.phone = data.CustomerPhone ? data.CustomerPhone.toString() : '';
            self.surname = data.CustomerSurname ? data.CustomerSurname.toString() : '';
            self.fetchTime = Date.now();
            self.isTableLoading = false;
        },
        getUserInfo: flow(function* fetch() {
            if (self.checkFetchTime()) {
                self.isTableLoading = true;
                yield llattempt(
                    () =>
                        api.user.getMyInfo().then((response) => {
                            if (response.success) {
                                self._set(response.data);
                            } else {
                                self.resetFetchTimeAndLoading();
                                // Thing below needed to avoid double error notifier on logout
                                if (response.data?.error === 'AUTH_REQUIRED') {
                                    return true;
                                } else {
                                    llNotifier({
                                        message: response.data?.error ?? 'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                }
                            }
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.getUserInfo',
                        withParams: {},
                        onError: () => {
                            self.resetFetchTimeAndLoading();
                        },
                    }
                );
            }
        }),
    }));

export default Info;
