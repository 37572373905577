const configUi = {
    Default: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow:
                'You can write us a mail if you could not find an answer for your question in our pages.',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You can reach us by mail if you want to know any other information about us.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Bahissenin: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow:
                'You can write us a mail if you could not find an answer for your question in our pages.',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You can reach us by mail if you want to know any other information about us.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Betaestas: {
        //unique
        showSupportBlock: false,
        en: {
            contactAnswersRow: '',
            contactEmailRow: '',
            emailBlock:
                'You may contact us by using message service for information and support.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow: '',
            contactEmailRow: '',
            emailBlock:
                'Tüm soru ve sorunlarınız için bizimle mesaj paneli üzerinden iletişime geçebilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Betlima: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow:
                'You can write us a mail if you could not find an answer for your question in our pages.',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You can reach us by mail if you want to know any other information about us.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Ciprobet: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow:
                'You can write us a mail if you could not find an answer for your question in our pages.',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You can reach us by mail if you want to know any other information about us.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Colonybet: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow:
                'You can write us a mail if you could not find an answer for your question in our pages.',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You can reach us by mail if you want to know any other information about us.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Efsanebahis: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow:
                'You can write us a mail if you could not find an answer for your question in our pages.',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You can reach us by mail if you want to know any other information about us.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Maksatbahis: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow:
                'You can write us a mail if you could not find an answer for your question in our pages.',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You can reach us by mail if you want to know any other information about us.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Jumpodds: {
        // unique
        showSupportBlock: false,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow: '',
            emailBlock:
                'You may contact us by using message service for information and support.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            emailBlock:
                'Tüm soru ve sorunlarınız için bizimle mesaj paneli üzerinden iletişime geçebilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Luckyman: {
        // unique
        showSupportBlock: false,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow: '',
            emailBlock:
                'You may contact us by using message service for information and support.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            emailBlock:
                'Tüm soru ve sorunlarınız için bizimle mesaj paneli üzerinden iletişime geçebilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    Retrobet: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow: '',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You may contact us by using message service for information and support.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Tüm soru ve sorunlarınız için bizimle mesaj paneli üzerinden iletişime geçebilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    'Show Bahis': {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow: '',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You may contact us by using message service for information and support.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Tüm soru ve sorunlarınız için bizimle mesaj paneli üzerinden iletişime geçebilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
    BetPapel: {
        showSupportBlock: true,
        en: {
            contactAnswersRow:
                'You can reach to answers of your questions from our Frequently Asked Questions, Help and Rules pages.',
            contactEmailRow: '',
            liveSupportBlock: 'Our live support will be available 7/24.',
            emailBlock:
                'You may contact us by using message service for information and support.',
            notForgetRow:
                'Do not forget that our live support is working carefully on your questions and complains. Please do not send any messages twice if its about the same question.',
        },
        tr: {
            contactAnswersRow:
                'Yardım, Kurallar ve Sıkça Sorulan Sorular sayfalarımızda merak ettiğiniz konular ile ilgili bilgilere ulaşabilirsiniz.',
            contactEmailRow: '',
            liveSupportBlock: 'Canlı yardım bölümümüz 7/24 hizmet vermektedir.',
            emailBlock:
                'Hizmetlerimiz ile ilgili olarak daha ayrıntılı bilgi almak isterseniz, bizlere E-posta yolu ile ulaşabilirsiniz.',
            notForgetRow:
                'Müşteri hizmetlerimizin, şikayet ve taleplerinizi olabildiğince çabuk ve dikkatli bir şekilde ele aldığını unutmayınız. Aynı konu ile ilgili birden fazla talep göndermeyiniz.',
        },
    },
};

export default configUi;
