import {languages} from "../../configs";

/**
 * @file Function that return reformated given data
 *
 * @param {Date } date - date to format
 * @param {string} template - template of formating
 * @return {string}
 *
 * @author A.Huskova
 */

const DAYS_TRANSES = {
    en: {
        today: 'today',
        yesterday: 'yesterday',
        tomorrow: 'tomorrow',
    },
    tr: {
        today: 'Bugün',
        yesterday: 'Dün',
        tomorrow: 'Yarın',
    },
    aa: {
        today: 'اليوم',
        yesterday: 'أمس',
        tomorrow: 'غداً',
    },
};

function isDate(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export function formatDate(date, template = 'DDMMYYYY HH:mm:SS', locale, replaceWithExpressions) {
    if (!date) return '';
    if (typeof date === 'string') {
        if (isDate(date)) {
            date = new Date(date);
        } else {
            return '';
        }
    }

    if (replaceWithExpressions?.length) {
        if (replaceWithExpressions.includes('today')) {
            if (date.toDateString() === new Date().toDateString()) {
                return DAYS_TRANSES[locale].today;
            }
        }
        if (replaceWithExpressions.includes('tomorrow')) {
            if (
                date.toDateString() ===
                new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()
            ) {
                return DAYS_TRANSES[locale].tomorrow;
            }
        }
        if (replaceWithExpressions.includes('yesterday')) {
            if (
                date.toDateString() ===
                new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
            ) {
                return DAYS_TRANSES[locale].yesterday;
            }
        }
    }

    let result = template;
    const regex = new RegExp(/(Y+)|(M+)|(D+)|(H+)|(mm)|(S+)/g);
    /**
     *  @desc Variants of template
     *
     *  'YYYY'    - return full year in four digits ['1984']
     *  'YY'      - return year in two digits ['84']
     *  'MM'      - return month in two digits ['09']
     *  'M'       - return month in one digit ['9' | '12']
     *  'DD'      - return day in two digit ['03']
     *  'D'       - return day in one digit ['3' | '31']
     *  'HH'      - return hours in two digits ['19']
     *  'mm'      - return minutes in two digits ['53']
     *  'm'       - return minutes in one digit ['5']
     *  'SS'      - return seconds in two digits ['53']
     *  'month'   - return name of the month in locale language
     *  'weekday' - return name of the weekday in locale language
     */
    template.replace(regex, (match) => {
        let substring;
        switch (match) {
            case 'YYYY':
                substring = date.getFullYear();
                break;
            case 'YY':
                substring = date.getFullYear().toString().slice(-2);
                break;
            case 'MM':
                substring = date.getMonth() + 1;
                substring = substring < 10 ? '0' + substring : substring;
                break;
            case 'M':
                substring = date.getMonth() + 1;
                break;
            case 'DD':
                substring = date.getDate();
                substring = substring < 10 ? '0' + substring : substring;
                break;
            case 'D':
                substring = date.getDate();
                break;
            case 'HH':
                substring = date.getHours();
                substring = substring < 10 ? '0' + substring : substring;
                break;
            case 'mm':
                substring = date.getMinutes();
                substring = substring < 10 ? '0' + substring : substring;
                break;
            case 'm':
                substring = date.getMinutes();
                break;
            case 'SS':
                const secs = date.getSeconds();
                substring = (secs < 10 ? '0' : '') + secs;
                break;

            default:
                break;
        }
        result = result.replace(match, substring);
    });
    result = result.replace('month', date.toLocaleString(languages.date[locale], { month: 'long' }));
    result = result.replace('weekday', date.toLocaleString(languages.date[locale], { weekday: 'long' }));

    return result;
}

export function getMidnightDate(date) {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
}
export function getEndOfDate(date) {
    return new Date(new Date(date).setHours(23, 59, 59, 999));
}
