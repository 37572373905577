import React, { useState, useEffect } from 'react';

import { withCommon } from '../../../../components';
import { DrawerCloseButton } from '../../components';
import { llattempt } from '../../../../common/utils';
import api from '../../../../common/api';

/**
 * @file Represents Messages page
 * @memberOf MyAccountPage
 *
 * @param {function} loadMessages - function to fetch and load messages from the server
 * @param {function} markAsRead - function to mark a message as read
 * @param {array} messages - list of messages (MessageText, MessageSubject, idatetime, isRead)
 * @param {string} viewSize - screen size (mobile, tablet, desktop) used to apply responsive styles
 * @param {function} trans - translation function for localizing text
 * @param {function} llattempt - utility function for handling API calls with error management
 *
 * @author N.Khrystiuk - main implementation
 */

const MessagesPage = withCommon({
    store: ({ store }) => ({
        decrementMessageCount: store.user.decrementMessageCount,
        viewSize: store.site.status.viewSize,
    }),
    isTrans: true,
})(({ decrementMessageCount, viewSize, trans }) => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        loadMessages();
    }, []);

    // Function to load messages using llattempt
    const loadMessages = () => {
        llattempt(
            () =>
                api.user.getMessages().then((result) => {
                    if (result.success) {
                        setMessages(result.data);
                    }
                }),
            {
                msg: 'Error loading messages',
                at: 'MessagesPage.loadMessages',
                withParams: {},
                onError: () => {
                    setIsLoading(false);
                },
                withNotifier: false,
            }
        );
    };

    // Function to mark a message as read using llattempt
    const markAsRead = (messageId) => {
        llattempt(
            () =>
                api.user.updateMessage({ id: messageId }).then(() => {
                    decrementMessageCount();
                    loadMessages();
                }),
            {
                msg: 'Error updating message status',
                at: 'MessagesPage.markAsRead',
                withParams: { id: messageId },
                onError: () => {
                    // Handle the error if needed
                },
                withNotifier: false,
            }
        );
    };

    return (
        <div className={`messages messages--${viewSize} my-account__content-container`}>
            {viewSize === 'desktop' && (
                <p className="my-account__title">
                    <span className="my-account__title-text">{trans('messages')}</span>
                    <DrawerCloseButton />
                </p>
            )}
            <div className="message-list">
                {messages
                    .slice()
                    .reverse()
                    .map((msg, index) => (
                        <div
                            key={index}
                            className={`message-item ${
                                msg.isRead === 0 ? 'message-unread' : ''
                            }`}
                            onMouseEnter={() => {
                                if (msg.isRead === 0) {
                                    markAsRead(msg.id);
                                }
                            }}
                        >
                            <div className="message-text">{msg.MessageText}</div>
                            <div className="message-date">
                                {new Date(msg.idatetime).toLocaleString()}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
});

export default MessagesPage;
