import {getRoot, flow} from 'mobx-state-tree';
import {values} from 'mobx';

import {urls} from '../../../../../../configs';
import {imageLoading} from '../../../../../../common/utils';

/*
##     ##    ###    ########  ######  ##     ##     ######   #######  ##     ## ##     ##    ###    ##    ## ########   ######
###   ###   ## ##      ##    ##    ## ##     ##    ##    ## ##     ## ###   ### ###   ###   ## ##   ###   ## ##     ## ##    ##
#### ####  ##   ##     ##    ##       ##     ##    ##       ##     ## #### #### #### ####  ##   ##  ####  ## ##     ## ##
## ### ## ##     ##    ##    ##       #########    ##       ##     ## ## ### ## ## ### ## ##     ## ## ## ## ##     ##  ######
##     ## #########    ##    ##       ##     ##    ##       ##     ## ##     ## ##     ## ######### ##  #### ##     ##       ##
##     ## ##     ##    ##    ##    ## ##     ##    ##    ## ##     ## ##     ## ##     ## ##     ## ##   ### ##     ## ##    ##
##     ## ##     ##    ##     ######  ##     ##     ######   #######  ##     ## ##     ## ##     ## ##    ## ########   ######
*/

const matchCommands = (s) => ({
    setTeamIconsStatus(homeIconStatus, awayIconStatus) {
        s.homeTeamHasLogo = homeIconStatus;
        s.awayTeamHasLogo = awayIconStatus;
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                           Put or update s market groups                              ##
    // ##                                                                                      ##
    // ##========================================================================================

    putUpdateMarketGroups({dataSource = [], isCustom = false, isFeatured = false}) {
        dataSource.map(({i, o, m, n}) => {
            if (i) {
                const name = getRoot(s).getReplacer({
                    strToReplace: n,
                    dataSource: {activeMatch: s},
                });

                const marketGroups = isCustom ? s.customMarketGroups : s.marketGroups;

                if (!marketGroups.has(i)) {
                    /**** ~~ Fills market groups ****/

                    marketGroups.put({
                        id: i,
                        order: o,
                        name: name,
                    });

                    const marketGroup = marketGroups.get(i);
                    /**** ~~ Set markets to market group ****/
                    m && marketGroup.putUpdateMarkets({dataSource: m, isFeatured, isCustom});
                    !isFeatured && marketGroup.setFetching({
                        type: 'initialFetching',
                        status: false,
                    })
                } else {
                    const marketGroup = marketGroups.get(i);
                    /**** ~~ Update market group if it already exists ****/
                    marketGroup.update({o, m, n: name}, isFeatured, isCustom);
                    !isFeatured && marketGroup.setFetching({
                        type: 'initialFetching',
                        status: false,
                    })
                }
            }
        });
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                  Self match updater                                  ##
    // ##                                                                                      ##
    // ##========================================================================================

    update(
        {
            competitor1,
            competitor2,
            timeStart,
            oddsCount,
            tv,
            order,
            marketGroups,
            categoryId,
            sportId,
            tournamentId,
            roundInfo,
            matchScore,
            matchTime,
            shortStatusName,
            periodLength,
            lmt,
            statusName,
            info,
            isStreaming,
            streamingId,
            hasCustom,
            countTotal,
        },
        isCustom,
        isFeatured,
    ) {
        s.competitor1 = competitor1;
        s.competitor2 = competitor2;
        s.timeStart = timeStart;
        s.oddsCount = oddsCount;
        s.tv = tv;
        s.countTotal = countTotal;
        tournamentId && (s.tournamentId = tournamentId);
        categoryId && (s.categoryId = categoryId);
        sportId && (s.sportId = sportId);

        (marketGroups.length && !isFeatured) && (s.mainMarketGroupId = marketGroups[0].i);
        (marketGroups.length && !isFeatured) && (s.mainMarketId = marketGroups[0].m[0].i);
        roundInfo && (s.roundInfo = roundInfo);

        s.order = order;
        s.matchScore = matchScore;
        s.matchTime = matchTime ? matchTime.toString() : '';
        s.shortStatusName = shortStatusName;
        s.periodLength = periodLength;
        s.lmt = lmt;
        s.statusName = statusName;
        s.info = info ? JSON.parse(info) : [];
        s.isStreaming = isStreaming;
        s.streamingId = streamingId;
        if (hasCustom) s.hasCustom = hasCustom;

        if (!getRoot(s).betting.activeItems.isActive({
            //need's for preventing conflicts between matches data and details data
            id: s.id,
            type: 'matches',
            })
            ||
            (marketGroups[0].i && !getRoot(s).betting.activeItems.isActive({
                id: marketGroups[0].i,
                type: 'marketGroups',
            }))
        ) {
            if (marketGroups[0]?.m[0]?.b) {
                s.putUpdateMarketGroups({dataSource: marketGroups, isCustom, isFeatured});
            } else if (marketGroups[0]?.m[0]) {
                const marketGroup = s.marketGroups.get(marketGroups[0].i);
                const market = marketGroup?.markets.get(marketGroups[0].m[0].i);
                if (market) {
                    market.removeItems();
                }
            }
        }
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                               Old market groups deleter                              ##
    // ##                                                                                      ##
    // ##========================================================================================

    deleteOldMarketGroups: flow(function* fetch({incomingMarketGroups, isCustom}) {
        const marketGroups = isCustom ? s.customMarketGroups : s.marketGroups;
        yield Promise.all([
            values(marketGroups)
                .filter(({id}) => !incomingMarketGroups.some(({i}) => i === id))
                .map((oldMarketGroup) => {
                    if (
                        getRoot(s).betting.activeItems.isActive({
                            id: oldMarketGroup.id,
                            type: 'marketGroups',
                        })
                    ) {
                        oldMarketGroup.removeItems();
                    } else {
                        marketGroups.delete(oldMarketGroup.id);
                    }
                }),
        ]);
    }),

    setCompetitorsLogo() {
        const homeLogo = urls.prematchCompetitorImg + s.competitor1.id + '.png';
        const awayLogo = urls.prematchCompetitorImg + s.competitor2.id + '.png';
        Promise.all([imageLoading(homeLogo), imageLoading(awayLogo)]).then((res) => {
            s.setTeamIconsStatus(res[0], res[1]);
        });
    },
});

export default matchCommands;
