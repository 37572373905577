import {flow, getRoot, types} from "mobx-state-tree";

import {refreshTime} from "../betting/config";
import LLWidget from "./ll-widget";
import VaixWidget from "./vaix-widget";


const Widgets = types
    .model('Widgets', {
        llWidget: types.optional(LLWidget, {}),
        vaixWidget: types.optional(VaixWidget, {}),
    })
    .actions((self) => ({
        initializeInstruction: flow(function* fetch({type}){ //type - ll or vaix
            initializeInstruction: {
                if (
                    self[type + 'Widget'].fetchTime &&
                    Date.now() < +new Date(self[type + 'Widget'].fetchTime + refreshTime.widgets)
                ) {
                    const timeLeft = self[type + 'Widget'].fetchTime + refreshTime.widgets - Date.now();
                    self.setUpdateForWidgetItems({type, timeLeft});
                    break initializeInstruction;
                }

                self[type + 'Widget'].getWidgetItems({isInitial: true}).then((res) => {
                    if (res.success && !res.data?.error) {
                        self[type + 'Widget'].successInstruction({data: res.data});
                        self.setUpdateForWidgetItems({type});
                    } else {
                        self[type + 'Widget'].unsuccessInstruction({err: res.data?.error});
                    }
                });
            }
        }),
        updateInstruction: flow(function* fetch({type}) {
            updateInstruction: {
                if (!self.isUpdatable) {
                    self.setUpdateForWidgetItems({type});
                    break updateInstruction;
                }

                if (self[type + 'Widget'].isUpdateCleanable) {
                    break updateInstruction;
                }

                self[type + 'Widget'].getWidgetItems().then((res) => {
                    if (res.success && !res.data?.error) {
                        self[type + 'Widget'].successInstruction({data: res.data});
                        self.setUpdateForWidgetItems({type});
                    } else {
                        self[type + 'Widget'].unsuccessInstruction({err: res.data?.error});
                    }
                });
            }
        }),
        setUpdateForWidgetItems({timeLeft, type}) {
            clearTimeout(window['__' + type + 'WidgetItemsUpdater']);
            window['__' + type + 'WidgetItemsUpdater'] = setTimeout(
                () => self.updateInstruction({type}),
                timeLeft ?? refreshTime.widgets
            );
        },
    }))
    .views((self) => ({
        get isUpdatable() {
            return getRoot(self).site?.status.isActive;
        },
    }));

export default Widgets;