import React from 'react';
import { inject, observer } from 'mobx-react';
import Slide from '@mui/material/Slide';

import { LLElementTypeSetter } from '../../../components';
import { formatDate, parseUrl } from '../../../common/utils';


const ICONS_FOR_TYPES = {
    new: {
        type: <i className="doruk-new-main-1" />,
        message: <i className="doruk-new-hot-2" />,
    },
    warning: {
        type: <i className="doruk-error-danger" />,
        message: <i className="doruk-error-warning" />,
    },
    bonus: {
        type: <i className="doruk-bonus-main" />,
        message: <i className="doruk-success-bitcoin-1" />,
    },
    success: {
        type: <i className="doruk-success-money-2" />,
        message: <i className="doruk-success-crown-1" />,
    },
    info: {
        type: <i className="doruk-info-2" />,
        message: <i className="doruk-popup-message-info-1" />,
    },
};


const LLPopup = ({ store }) => {
    const popup = store.site.popup;
    const user = store.user;
    const linkAttr = parseUrl(popup.url, user);

    return (
        <Slide direction="up" in={popup.isVisible} mountOnEnter unmountOnExit>
            <LLElementTypeSetter
                className={`ll-popup js-main-popup ll-popup--${popup.popupType} ll-popup--${
                    store.site.status.viewSize
                } ${popup.url?.length ? 'll-popup--link' : ''}`}
                {...linkAttr.elementAttributes}
                onClick={linkAttr.someClick}
            >
                <div className="ll-popup__type">
                    {ICONS_FOR_TYPES[popup.popupType]?.type}
                </div>
                <div className="ll-popup__inner">
                    <div className="ll-popup__header">
                        <div className="ll-popup__header-text">
                            <span>{popup.name}</span>
                            <span
                                className={`ll-popup__header-icon ll-popup__header-icon--${popup.messageType}`}
                            >
                                {ICONS_FOR_TYPES[popup.messageType]?.message}
                            </span>
                        </div>
                        <div className="ll-popup__header-close"
                             onClick={(e) => {
                                 e.preventDefault();
                                 popup.hide();
                             }}
                        >
                            <i
                                className="doruk-close ll-popup__close-button-icon"
                            />
                        </div>
                    </div>
                    { popup?.message ?
                        <div className="ll-popup__message"
                             dangerouslySetInnerHTML={{
                                 __html: popup?.message,
                             }}
                        /> : null
                    }
                    <div className="ll-popup__time">
                        {formatDate(
                            popup.updateTime,
                            'HH:mm DD.MM.YYYY',
                            store.user.language
                        )}
                    </div>
                </div>
            </LLElementTypeSetter>
        </Slide>
    );
};

export default inject('store')(observer(LLPopup));
