import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {LLBettingButton, WithDropdown, WithPopover} from '../../';
import {formatNumber} from "../../../common/utils";


const BetBuilderBetsContent = ({bets, isOverflow, setBetsIsOverflowed, betsIsOverflowed, ...rest}) => {
    useEffect(() => {
        if (typeof setBetsIsOverflowed === 'function') {
            setBetsIsOverflowed(isOverflow);
        }
    }, [isOverflow]);

    return (<>
            <div {...rest}>
                { bets.map(item => {
                    if (item.market.specifierBasedMarkets?.length) {
                        item.market = item.market.specifierBasedMarkets.find(
                            market => market.betsList.find(bet => bet.id === item.bet?.id)
                        );
                    }
                    return (
                        <p className="ll-widget-item__bets-item--bet-builder" key={item.market.id + item.bet?.id}>
                            {item.market?.name} - {item.bet?.name}
                        </p>
                    )}
                )}

            </div>
            {bets.length > 3 && isOverflow === undefined &&
                <i onClick={() => rest.onClick()} className="ll-widget-item__bets-item-cross--opened doruk-close"/>}
        </>
    );
}

const LLWidgetSportBets =
    ({
         store,
         bets,
         columnCount,
         viewSize,
         withDropdown,
         isCombined,
         betBuilderOdds,
         isBetBuilder,
         dropdownWidth,
         dropdownRef,
         event,
     }) => {
        const
            [activeBet, setActiveBet] = useState(bets?.length ? bets[0] : null),
            [betsIsOverflowed, setBetsIsOverflowed] = useState(false);


        const widget = store.widgets.llWidget;
        const language = store.user.language;

        const getDropdownSrc = () => bets.map(bet => ({
            label: bet.id,
            element: (
                <p className="ll-widget-item-dropdown-content__bet">
                <span className="ll-widget-item-dropdown-content__bet-name">
                    {bet.name}
                </span>
                    <span className="ll-widget-item-dropdown-content__bet-value">
                    {bet.renderOdd}
                </span>
                </p>
            )
        }));

        const betBuilderBet = isBetBuilder ? {
            id: bets[0].bet?.id,
            outcomeTypeId: '',
            name: bets[0].bet?.name,
            roundedValue: Math.floor(betBuilderOdds * 100) / 100,
            placeBet: () => {
                widget.placeCustomFeaturedBet(event)
            },
            isActive: widget.isCustomFeaturedActive(bets),
            available: true,
            renderOdd: formatNumber(betBuilderOdds, 2, language)
        } : {};


        return isBetBuilder ?
            <div className={`ll-widget-item__bets ll-widget-item__bets--${viewSize} ll-widget-item__bets--bet-builder`}>
                <div ref={dropdownRef} className={cx(
                    'll-widget-item__bets-content--bet-builder',
                    betsIsOverflowed && 'll-widget-item__bets-content--bet-builder--overflowed'
                )}
                >
                    <WithPopover
                        customClasses="js_ll-widget-item__bet-builder-popover"
                        triggeredOnHover={false}
                        withNewProps={true}
                        placement="bottom-start"
                        popoverContent={
                            <BetBuilderBetsContent
                                onClick={() => {
                                    if (viewSize !== 'desktop') {
                                        store.site.status.setBackdropShown(false);
                                    }
                                    store.popover.hidePopover();
                                }}
                                bets={bets}
                            />
                        }
                        popoverClasses="ll-widget-item-overflowed-content"
                        offset={[viewSize === 'desktop' ? -20 : -40, viewSize === 'desktop' ? -67 : -67]}
                        popoverWidth={viewSize === 'desktop' ? dropdownWidth - 20 : dropdownWidth - 10}
                    >
                        <BetBuilderBetsContent
                            bets={bets}
                            onClick={() => {
                                if (viewSize !== 'desktop' && betsIsOverflowed) {
                                    store.site.status.setBackdropOnClick(() => store.popover.hidePopover);
                                    store.site.status.setBackdropShown(true);
                                }
                            }}
                            setBetsIsOverflowed={setBetsIsOverflowed}
                            betsIsOverflowed={betsIsOverflowed}
                        />
                    </WithPopover>
                    {!!betsIsOverflowed &&
                        <p className="ll-widget-item__bets-item--bet-builder-dots"
                           onClick={(e) => {
                               e.currentTarget?.previousSibling?.click();
                           }}
                        >
                            <i className="doruk-dots"/>
                        </p>}
                </div>
                <LLBettingButton
                    columnCount={withDropdown ? 0 : null}
                    withPopoverOnMobile={true}
                    bet={betBuilderBet}
                    nameAppearance={false}
                    className="ll-widget-item__bet--with-dropdown"
                />
            </div>
            :
            withDropdown ? (
                <div className="ll-widget-item__bets ll-widget-item__bets--with-dropdown">
                    <div ref={dropdownRef} className="ll-widget-item__bets-box--with-dropdown">
                        <WithDropdown
                            dataSource={{
                                submenu: getDropdownSrc(),
                            }}
                            menuItemIsActive={(item) => item?.label === activeBet?.id}
                            onMenuItemClick={(item) => setActiveBet(bets.find((bet) => bet.id === item.label))}
                            openBehavior="click"
                            customClasses="ll-widget-item__dropdown"
                            menuClasses={cx(
                                "ll-widget-item-dropdown-content",
                                `ll-widget-item-dropdown-content--${viewSize}`
                            )}
                            disablePortal={false}
                            popoverWidth={dropdownWidth}
                            // popoverWidth={viewSize === 'mobile' ? dropdownWidth - (dropdownWidth * (17.08 / 100)) : dropdownWidth}
                        >
                            <WithPopover>
                                <span>{activeBet?.name}</span>
                            </WithPopover>
                        </WithDropdown>
                    </div>
                    {!!activeBet &&
                        <LLBettingButton
                            columnCount={withDropdown ? 0 : null}
                            withPopoverOnMobile={true}
                            bet={activeBet}
                            nameAppearance={false}
                            className="ll-widget-item__bet--with-dropdown"
                        />
                    }
                </div>
            ) : (
                <div className={cx(
                    'll-widget-item__bets',
                    'll-widget-item__bets--main-bets',
                    'll-widget-item__bets--combined'
                )}
                >
                    {bets.map((bet) => (
                        <LLBettingButton
                            bet={bet}
                            key={bet.id}
                            betName={null}
                            withPopoverOnMobile={true}
                            nameAppearance={false}
                            className="ll-widget-item__bet"
                            customClasses={cx(isCombined && 'll-widget-item__bet--combined')}
                            columnCount={columnCount}
                        />
                    ))}
                </div>
            );
    };

export default inject('store')(observer(LLWidgetSportBets));
