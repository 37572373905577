import React from 'react';
import PropTypes from 'prop-types';

import {withCommon, LLElementTypeSetter} from '../../../../../components';
import {pagesConfig} from '../../../../../configs';

/**
 * @file Represents React Component to render the menu for desktop content-footer
 * @memberOf LLContentFooter
 *
 * @param {function} setModal - action of modal store instance that set type and content of modal
 * @param {string} language - property of user store instance that defined chosen lang for user
 * @param {object} config - local front-end config for the site
 * @param {array} footerContentMenu - config param - array with rules for render footer menu of Content
 * @param {boolean} isSocialIcons - config param - flag to define if there is social icons on site at all
 * @param {array} socialIcons - config param - array with rules for render social icons
 *
 * @author A.Huskova
 */

const LLFooterMenu = withCommon({
    store: ({store}) => ({
        siteName: store.site.siteName,
        setModal: store.modal.setModal,
        language: store.user.language,
        config: store.modifiedConfig,
        isLogged: store.user.isLogged,
        openLogin: store.user.openLogin,
        socialIcons: store.site.socialIcons.icons,
    }),
    propTypes: {
        config: PropTypes.shape({
            footerContentMenu: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    links: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string.isRequired,
                        })
                    ),
                })
            ),
        }).isRequired,
        language: PropTypes.string.isRequired,
        setModal: PropTypes.func.isRequired,
    },
    defaultName: 'LLFooterMenu',
    isTrans: true,
    isObserver: true,
})(({
        config: {
            footerContentMenu,
        },
        language,
        setModal,
        isLogged,
        openLogin,
        customClasses,
        siteName,
        trans,
    }) => (<>{
    /**** ~~ Render Menu Links Section ****/
    footerContentMenu && footerContentMenu.map((menu, i) => (
        <div className={`footer-menu__section footer-menu__section--menu ${
            i < footerContentMenu.length / 2 ? 'left' : 'right'
        } ${customClasses}`} key={i}>
            <div className="footer-menu__section-title">
                {menu.skipTranslation ? menu.title : trans(menu.title)}
            </div>
            {menu.links.map((menuItem, j) => (
                <LLElementTypeSetter
                    key={j}
                    {...menuItem.elementAttributes}
                    className="footer-menu__item"
                    onClick={(e) => {
                        if (
                            Object.values(pagesConfig(siteName)).find(
                                (page) =>
                                    page.url ===
                                    menuItem.elementAttributes?.to
                            )?.onlyFor === 'logged' &&
                            !isLogged
                        ) {
                            e.preventDefault();
                            openLogin();
                        } else {
                            menuItem.iframe &&
                            setModal({
                                active: true,
                                externalLink: menuItem.needLang
                                    ? menuItem.iframe[language]
                                    : menuItem.iframe,
                                currentKey: 'external-link',
                                customClasses:
                                    'external-link luckyland-link',
                                showCloseButton: true,
                                showFullscreenButton: true,
                            });
                        }
                    }}
                >
                    {trans(menuItem.name)}
                </LLElementTypeSetter>
            ))}
        </div>
    ))}</>)
);

export default LLFooterMenu;