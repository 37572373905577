import en from './en';
import tr from './tr';
import aa from './aa';

const countryCodes = {
    en: en,
    tr: tr,
    aa: aa,
};

export default countryCodes;
