import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import cx from "classnames";

import {getMidnightDate, getEndOfDate, trans, formatDate} from "../../../../common/utils";
import {LLProgressBar} from "../../../../components";
import SelectedFilterItem from "./selected-filter-item";
import FilterMenuList from "./filter-menu-list";
import LLDateRangePicker from "../../../../components/elements/date-range-picker/date-range-picker";

import {FILTERS_MAX_SIZE} from '../../../../store/models/betting/filtered-ranges/filtered-range';


const filtersPanelData = [
    {
        value: 'sports',
        title: 'Sport',
    },
    {
        value: 'categories',
        title: 'Category',
    },
    {
        value: 'tournaments',
        title: 'Tournament',
    },
];

const FiltersPanel = (
    {store}
) => {
    const [tab, setTab] = useState('sports');
    const [currentItem, setCurrentItem] = useState(null);
    const [openedItem, setOpenedItem] = useState(null);
    const [showFilter, setShowFilter] = useState(false);

    const
        activeRange = store.betting.filteredRanges.activeRange,
        getRange = store.betting.filteredRanges.getRange;

    const
        [filterFromDate, setFilterFromDate] = useState(new Date()),
        [filterToDate, setFilterToDate] = useState(new Date());

    useEffect(() => {
        let from = Math.floor(+getMidnightDate(filterFromDate) / 1000);
        let to = Math.floor(+getEndOfDate(filterToDate) / 1000);
        if (+getMidnightDate(filterFromDate) === +getMidnightDate(new Date())) {
            getRange({to});
        } else {
            getRange({from, to});
        }
    }, [+filterFromDate, +filterToDate]);

    useEffect(() => {
        const currentActiveItem = activeRange?.getItem({instanceType: tab, id: currentItem});
        if (currentActiveItem && !currentActiveItem.initialFetching) {
            setCurrentItem(null);
            setTab(tab === 'sports' ? 'categories' : 'tournaments');
        }
    }, [activeRange?.getItem({instanceType: tab, id: currentItem})?.initialFetching]);

    const filterPanelToggle = () => {
        if (showFilter) {
            setTab('sports');
            setShowFilter(false);
        } else {
            activeRange?.getFilterDataInstruction({instanceType: 'sports'}).then(() => setShowFilter(true));
        }
    };

    const setNewDateRange = ({startDate, endDate}) => {
        if (startDate) {
            setFilterFromDate(startDate);
        }
        if (endDate) {
            setFilterToDate(endDate);
        }
    }

    return (
        <>
            <p className="filters-panel__item-label">
                <span>
                    {trans('PREMATCH_FILTERS_PANEL_CHOOSER_HEADER')
                        .replace('{{numberOfFilters}}', `${
                            activeRange?.selectedFiltersList.length
                        }/${FILTERS_MAX_SIZE}`)
                    }
                </span>
                <span> {formatDate(filterFromDate, 'DD.MM.YY')}</span>
                {(+filterFromDate !== +filterToDate) ?
                    <> - <span>{formatDate(filterToDate, 'DD.MM.YY')}</span></>
                    : ''
                }
            </p>
            <div className="filters-panel__filters-date">
                <LLDateRangePicker
                    storeItem={{
                        setNewDateRange: setNewDateRange,
                        startDate: filterFromDate,
                        endDate: filterToDate,
                    }}
                    withoutRanges={true}
                    withSubmitButton={false}
                    customClassnames="ll-date-range-picker--sport-filters"
                    isFutureDates={true}
                    forcedSetter={true}
                    withoutPortal={store.site.status.viewSize === 'desktop'}
                />
            </div>
            <div
                onClick={() => {
                    if (!activeRange?.isMaxFiltersCountReached) {
                        filterPanelToggle();
                    }
                }}
                className={`filters-panel__add-filter-button ${
                    activeRange?.isMaxFiltersCountReached ? 
                        'filters-panel__add-filter-button--disabled' : ''
                }`}
            >
                <div className="filters-panel__add-filter-button-name">
                    <i className="filters-panel__add-filter-button-icon doruk-sport-default-icon"/>
                    <span>{trans('PREMATCH_FILTERS_PANEL_CHOOSE')}</span>
                </div>
                <i className={'filters-panel__add-icon doruk-plus'}/>
            </div>
            { !showFilter && activeRange?.selectedFiltersList.map((item, i) => (
                <SelectedFilterItem
                    key={item.name}
                    item={item}
                    removeCallback={
                        () => activeRange.removeFilter(i)
                    }
                />
            ))}
            { activeRange?.selectedFiltersList.length ? <div
                onClick={activeRange.getSelectedFiltersDataInstruction}
                className="filters-panel__filters-submit-btn"
            >
                {trans('PREMATCH_FILTERS_PANEL_SHOW_RESULT')}
            </div> : null}
            { showFilter &&
                <div className="filters-panel-menu">
                    <div className="filters-panel-menu__header">
                        <p>
                            <span>{trans('PREMATCH_FILTERS_PANEL_CHOOSE_FILTER')} </span>
                            <span>{`${activeRange?.selectedFiltersList.length}/${FILTERS_MAX_SIZE}`}</span>
                            <span> {formatDate(filterFromDate, 'DD.MM.YY')}</span>
                            {(+filterFromDate !== +filterToDate) ?
                                <> - <span>{formatDate(filterToDate, 'DD.MM.YY')}</span></>
                                : ''
                            }
                        </p>
                        <i
                            className="filters-panel-menu__header-close-icon doruk-close"
                            onClick={filterPanelToggle}
                        />
                    </div>
                    <div className="filters-panel-menu__tabs">
                        {filtersPanelData.map(item => {
                            const currentActiveItemId = activeRange[item.value === 'categories' ? 'sports' : 'categories'].activeItem?.id;
                            const isClickable = (item.value === 'sports' || (!activeRange[item.value].isTabDisabled && currentActiveItemId));

                            return <div
                                key={item.value}
                                className={cx(
                                    "filters-panel-menu__tabs-item",
                                    item.value === tab &&
                                    "active",
                                    !isClickable && "disabled"
                                )}
                                onClick={() => {
                                    isClickable && setTab(item.value)
                                }}
                            >
                                <p className="filters-panel-menu__tabs-item-text">{trans(item.title)}</p>
                                {(activeRange.getItem({
                                        instanceType: tab,
                                        id: currentItem
                                    })?.initialFetching && activeRange.filteredInstanceChildName[tab] === item.value) &&
                                    <LLProgressBar customClasses="filters-panel-menu__tabs-item-loader"/>
                                }
                            </div>
                        })}
                    </div>
                    <div className="filters-panel-menu__tabs-content">
                        <FilterMenuList
                            id={currentItem}
                            setCurrentItem={setCurrentItem}
                            setOpenedItem={setOpenedItem}
                            openedItem={openedItem}
                            range={activeRange}
                            tab={tab}
                        />
                    </div>
                    <div className="filters-panel-menu__submit-btn-wrp">
                        <div className={cx(
                                'filters-panel__filters-submit-btn',
                                !activeRange?.selectedFiltersList.length && 'filters-panel__filters-submit-btn--disabled'
                            )}
                            onClick={
                                activeRange?.selectedFiltersList.length ?
                                    activeRange.getSelectedFiltersDataInstruction
                                    : () => {}
                            }
                        >
                            {trans('PREMATCH_FILTERS_PANEL_SHOW_RESULT')}
                        </div>
                    </div>
                </div>
            }
        </>
    )
};
export default inject('store')(observer(FiltersPanel));

