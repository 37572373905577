import Base from '../base';
import apiUrls from '../api-urls';

export default class PromoAPI extends Base {
    async getPromosData(params) {
        return this.apiClient.post(apiUrls.promo.getPromo, params);
    }
    async sendPromo(data) {
        return this.apiClient.post(apiUrls.promo.sendPromo, data);
    }
}
