import React, {useEffect} from "react";
import cx from 'classnames';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';

import {withCommon} from '../../components';

/***************** ~~ Represents popover ***************
 * @file - Represents React Component to render the popover
 *
 * @memberOf LLLayout
 * @param {boolean} mobileUserAgent - flag is mobile layout
 * @param {boolean} isActive - flag to show or hide popover
 * @param {function} hidePopover - set isActive = false
 * @param {function} showPopover - set popover state
 * @param {object} anchorEl - DOM element needed for calculating popover position
 * @param {JSX.Element} popoverContent - it is popover content )))
 * @param {string} placement - popover position
 * @param {string} popoverClasses - classNames for custom styles
 *
 * @author S.Nakhodov
 */

const LLPopoverCashout = withCommon({
    store: ({store}) => ({
        isLogged: store.user.isLogged,
        isActive: store.popoverCashout.isActive,
        setPopover: store.popoverCashout.setPopover,
        hidePopover: store.popoverCashout.hidePopover,
        anchorEl: store.popoverCashout.anchorEl,
        placement: store.popoverCashout.placement,
        popoverContent: store.popoverCashout.popoverContent,
        popoverClasses: store.popoverCashout.popoverClasses,
        stayShovedByHover: store.popoverCashout.stayShovedByHover,
    }),
    propTypes: {
        setPopover: PropTypes.func.isRequired,
        hidePopover: PropTypes.func.isRequired,
        anchorEl: PropTypes.object.isRequired,
        placement: PropTypes.string,
        popoverContent: PropTypes.object,
        popoverClasses: PropTypes.string,
        stayShovedByHover: PropTypes.bool,
    },
    defaultName: 'LLPopover',
    isObserver: true,
})(
    ({
         isLogged,
         isActive,
         setPopover,
         hidePopover,
         anchorEl,
         placement,
         popoverContent,
         popoverClasses,
         stayShovedByHover,
     }) => {

        useEffect(() => {
            !isLogged && hidePopover();
        }, [isLogged]);

        const propObj = {};
        if (stayShovedByHover) {
            propObj.onMouseEnter = () => {
                anchorEl.classList.add(`${anchorEl.classList[0]}--hover`);
                setPopover({
                    anchorEl,
                    isActive: !!popoverContent,
                    popoverClasses,
                    popoverContent,
                    placement,
                    stayShovedByHover,
                });
            };
            propObj.onMouseLeave = () => {
                anchorEl.classList.remove(`${anchorEl.classList[0]}--hover`);
                hidePopover();
            };
        }


        return (
            <ClickAwayListener
                onClickAway={() => {
                    isActive && hidePopover();
                }}
                mouseEvent={'onMouseDown'}
                touchEvent={'onTouchStart'}
            >
                <Popper
                    open={isActive}
                    {...propObj}
                    anchorEl={anchorEl}
                    placement={placement}
                    className={cx('ll-popover-cashout', popoverClasses)}
                >
                    <>{popoverContent}</>
                </Popper>
            </ClickAwayListener>
        );
    }
);

export default LLPopoverCashout;
