import React, { useEffect } from 'react';

import {
    withCommon,
    LLDateRangePicker,
    LLPreloader,
    LLTable,
} from '../../../../components';
import { DrawerCloseButton } from '../../components';

/**
 * @file Represents React Component to render
 * @memberOf MyAccountPage
 *
 * @param {type} name - description
 *
 * @author A.Huskova
 */

const CasinoReport = withCommon({
    store: ({ store }) => ({
        getCasinoReport: store.user.casinoReport.getCasinoReport,
        isTableLoading: store.user.casinoReport.isTableLoading,
        casinoReports: store.user.casinoReport.reports,
        storeItem: store.user.casinoReport,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {},
    defaultProps: {},
    defaultName: 'CasinoReport',
    isTrans: true,
    isObserver: true,
})(({ getCasinoReport, isTableLoading, casinoReports, storeItem, viewSize, trans }) => {
    const getReports = (isFetchNotificationShown) => {
        getCasinoReport(isFetchNotificationShown).then();
    };

    useEffect(() => {
        getReports();
    }, []);

    const casinoReportTableConfig = {
        mobileGridProps: {
            noDataText: 'CABINET_CASINO_REPORT_NO_DATA_TEXT',
        },
        mobile: {
            columns: [
                { key: 'provider', title: trans('Provider') },
                {
                    key: 'amount',
                    title: trans('Amount'),
                    format: 'currency',
                    alignment: 'right',
                },
            ],
            totalColumnKey: 'amount',
        },
        desktopGridProps: {
            noDataText: 'CABINET_CASINO_REPORT_NO_DATA_TEXT',
        },
        desktop: {
            columns: [
                { key: 'provider', title: 'provider' },
                { key: 'amount', title: 'amount', format: 'currency' },
            ],
            totalColumnKey: 'amount',
        },
    };

    return (
        <div className="casino-report my-account__content-container">
            {viewSize === 'desktop' && (
                <p className="my-account__title">
                    <span className="my-account__title-text">
                        {trans('casino-report')}
                    </span>
                    <DrawerCloseButton />
                </p>
            )}
            <div className="my-account__content">
                <div className="my-account__date-picker-wrapper">
                    <LLDateRangePicker
                        actionHandler={getReports}
                        storeItem={storeItem}
                    />
                </div>

                <div className="my-account__table-box">
                    {isTableLoading ? (
                        <div className="my-account__loader-box">
                            <LLPreloader />
                        </div>
                    ) : (
                        <LLTable
                            data={casinoReports}
                            customConfig={casinoReportTableConfig}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default CasinoReport;
