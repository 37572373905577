import {reaction} from 'mobx';
import {flow, getRoot, getParent, getPathParts, types} from "mobx-state-tree";

import {refreshTime} from "../config";
import api from "../../../../common/api";
import {trans} from "../../../../common/utils";


export const FILTERS_MAX_SIZE = 5;

const FilterItem = types
    .model('FilterItem', {
        id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
    });

const SelectedFilter = types
    .model('SelectedFilter', {
        sports: types.maybeNull(FilterItem),
        categories: types.maybeNull(FilterItem),
        tournaments: types.maybeNull(FilterItem),
        events: types.array(types.string),
    })
    .actions((self) => ({
        setSelectedItem({instanceType, id, name}) {
            self[instanceType] = {id, name};
        },
    }))
    .views((self) => ({
        get groupedByDateEvents() { //return array of objects for selected filter with proper events
            return getParent(self, 2).getGroupedByDateEvents([...self.events], true)
        },
    }));

const FilteredRangeItem = types
    .model('FilteredRangeItem', {
        id: types.identifier,
        matchCount: types.maybeNull(types.number),
        lastFetchTime: types.maybeNull(types.Date),
        initialFetching: true,
    })
    .actions((self) => ({
        setFetchTime(date) {
            self.lastFetchTime = date;
        },
        setFetching(flag) {
            self.initialFetching = flag;
        },
        setMatchIds(ids) {
            self.matchIds = ids;
        },
    }))
    .views((self) => ({
        get isSelected() {
            const range = getParent(self, 3);
            const pathArr = getPathParts(getParent(self, 2));
            const instanceType = pathArr[pathArr.length - 1];
            return range.isThatInstanceSelected({instanceType, id: self.id});
        },
    }));

const FilteredRangeInstance = types
    .model('FilteredRangeInstance', {
        items: types.map(FilteredRangeItem),
        activeItem: types.maybeNull(FilterItem),
        error: types.maybeNull(types.string),
        isTabDisabled: true,
    })
    .actions((self) => ({
        setItems(data) {
            data.forEach(item =>
                item.matchCount && self.items.put({id: item.id, matchCount: item.matchCount})
            );
        },
        setActiveItem({id, name}) {
            self.activeItem = !id ? null : {id, name};
        },
        setTabDisable(flag) {
            self.isTabDisabled = flag;
        },
        setError(error = null) {
            self.error = error;
        },
    }));

const FilteredRange = types
    .model('FilteredRange', {
        id: types.identifier,
        sports: types.optional(FilteredRangeInstance, {}),
        categories: types.optional(FilteredRangeInstance, {}),
        tournaments: types.optional(FilteredRangeInstance, {}),
        selectedFilters: types.array(SelectedFilter),
        fetchTime: types.maybeNull(types.number),
        initialFetching: true,
        searchFiltersFetching: false,
        searchFiltersResult: false,
    })
    .actions((self) => {
        reaction(() => self.selectedFilters.length, (length) => {
            if (!length) {
                self.clearResults();
            }
        })

        return {
            _successInstruction({instanceType, data}) {
                if (data.length) {
                    self[instanceType].setError();
                    self[instanceType].setItems(data);
                } else {
                    self[instanceType].setError('FILTERED_EVENTS_EMPTY_DATA');
                }
            },
            _unsuccessInstruction({instanceType, err}) {
                self[instanceType].setError(err ? err : 'FILTERED_EVENTS_TECHNICAL_ISSUES_ERROR');
            },
            _setInitialFetching(flag) {
                self.initialFetching = flag;
            },
            _setSearchFiltersFetching(flag) {
                self.searchFiltersFetching = flag;
            },
            _setSearchFiltersResult(flag) {
                self.searchFiltersResult = flag;
            },
            _setFetchTime() {
                self.fetchTime = Date.now();
            },
            _pushEvent(id, match) {
                self.selectedFilters.forEach(filter => {
                    const currentFilter =
                        filter.sports?.id === match.sportId &&
                        (!filter.categories?.id || filter.categories?.id === match.categoryId) &&
                        (!filter.tournaments?.id || filter.tournaments?.id === match.tournamentId);

                    if (currentFilter) {
                        filter.events.push(id);
                    }
                })
            },
            _clearEvents() {
                self.selectedFilters.forEach(filter => {
                    filter.events = [];
                })
            },
            _clearActiveFilterItems() {
                self.sports.setActiveItem({});
                self.categories.setActiveItem({});
                self.tournaments.setActiveItem({});
            },
            setActiveItem({instanceType, id, name, isParent = false}) {
                if (instanceType !== 'tournaments') {
                    self.tournaments.setActiveItem({});
                }
                if (instanceType === 'sports') {
                    self.categories.setActiveItem({});
                }
                self[instanceType].setActiveItem({id, name});
                self.getFilterDataInstruction({
                    instanceType: isParent ? instanceType : self.filteredInstanceChildName[instanceType],
                    id
                });
            },
            toggleFilter({instanceType, id, name}) {
                if (!self.isThatInstanceSelected({instanceType, id})) {
                    if (self.selectedFilters.length >= FILTERS_MAX_SIZE) {
                        return {error: `${trans('PREMATCH_FILTERS_PANEL_LIMIT_REACHED')} (${FILTERS_MAX_SIZE})`};
                    }
                    if (self[instanceType].activeItem?.id !== id) {
                        const childInstance = self[self.filteredInstanceChildName[instanceType]];
                        if (childInstance) {
                            childInstance.setTabDisable(true);
                            const grandchildInstance = self[self.filteredInstanceChildName[self.filteredInstanceChildName[instanceType]]];
                            grandchildInstance && grandchildInstance.setTabDisable(true);
                        }
                    }
                    self.selectedFilters.push(SelectedFilter.create());
                    self.selectedFilters[self.selectedFilters.length - 1]
                        .setSelectedItem({instanceType, id, name});
                    const instances = [];
                    if (instanceType === 'categories' || instanceType === 'tournaments') instances.push('sports');
                    if (instanceType === 'tournaments') instances.push('categories');
                    instances.forEach(key => {
                        const item = self[key].activeItem;
                        if (item) {
                            self.selectedFilters[self.selectedFilters.length - 1]
                                .setSelectedItem({instanceType: key, id: item.id, name: item.name});
                        }
                    });
                } else {
                    self.removeFilter(self.getSelectedFilterIndex({instanceType, id}));
                }
            },
            removeFilter(index) {
                self.selectedFilters = self.selectedFilters.filter((item, i) => i !== index);
            },
            clearFilters() {
                self.selectedFilters = [];
            },
            clearResults() {
                self._clearActiveFilterItems();
                self._setSearchFiltersResult(false);
            },
            clearAll() {
                self.clearResults();
                self.clearFilters();
                self._clearActiveFilterItems();
            },
            getFilterDataInstruction: flow(function* fetch({instanceType, id}) {
                getFilterDataInstruction: {
                    if (
                        instanceType === 'sports' &&
                        self.fetchTime &&
                        Date.now() < +new Date(+self.fetchTime + refreshTime[instanceType])
                    ) {
                        self._setSearchFiltersFetching(false);
                        self[instanceType].setTabDisable(false);
                        break getFilterDataInstruction;
                    }
                    self._setSearchFiltersFetching(true);

                    const sportId = self.sports.activeItem?.id;

                    if (
                        instanceType === 'categories' &&
                        self.sports.items.get(id).lastFetchTime &&
                        Date.now() < +new Date(+self.sports.items.get(id).lastFetchTime + refreshTime[instanceType])
                    ) {
                        self._setSearchFiltersFetching(false);
                        self[instanceType].setTabDisable(false);
                        break getFilterDataInstruction;
                    }

                    const categoryId = self.categories.activeItem?.id;

                    const branch = getRoot(self).betting.branches.get('All'),
                        from = self.id.split('_')[0],
                        to = self.id.split('_')[1];

                    const sport = branch.sports.get(sportId);

                    switch (instanceType) {
                        case 'sports':
                            const res = yield branch.fetchAndConvertDataForSports({from, to}) || {};
                            if (res && res.success) {
                                branch.putUpdateData({dataSource: res.data});
                                self._successInstruction({instanceType, data: res.data});
                            } else {
                                self._unsuccessInstruction({instanceType, err: res?.data?.msg});
                            }
                            self._setInitialFetching(false);
                            self._setFetchTime(Date.now());
                            self[instanceType].setTabDisable(false);
                            break;
                        case 'categories':
                            if (sport) {
                                const res = yield sport.fetchAndConvertDataForCategories({
                                    sportId,
                                    from,
                                    to
                                }) || {};
                                if (res && res.success) {
                                    sport.putUpdateData({dataSource: res.data});
                                    self._successInstruction({instanceType, data: res.data});
                                } else {
                                    self._unsuccessInstruction({instanceType, err: res?.data?.msg});
                                }
                                self.sports.items.get(sportId).setFetching(false);
                                self.sports.items.get(sportId).setFetchTime(Date.now());
                                self[instanceType].setTabDisable(false);
                            }
                            break;
                        case 'tournaments':
                        default:
                            if (sport) {
                                const category = sport.categories.get(categoryId);
                                if (category) {
                                    const res = yield category.fetchAndConvertDataForTournaments({
                                        categoryId,
                                        from,
                                        to
                                    }) || {};
                                    if (res && res.success) {
                                        category.putUpdateData({dataSource: res.data});
                                        self._successInstruction({instanceType, data: res.data});
                                    } else {
                                        self._unsuccessInstruction({instanceType, err: res?.data?.msg});
                                    }
                                }
                                self.categories.items.get(categoryId).setFetching(false);
                                self.categories.items.get(categoryId).setFetchTime(Date.now());
                                self[instanceType].setTabDisable(false);
                            }
                            break;
                    }
                    self._setSearchFiltersFetching(false);
                }
            }),
            getSelectedFiltersDataInstruction: flow(function* fetch() { // collect requests for each selected filter by its type and resolve all off it setting event ids array
                self._setSearchFiltersFetching(true);
                const from = self.id.split('_')[0],
                    to = self.id.split('_')[1];

                const requests = [];

                const requestParams = {to};
                if (from) {
                    requestParams.from = from;
                }

                self.selectedFilters.forEach(filter => {
                    const sportId = filter.sports.id;
                    const categoryId = filter.categories?.id;
                    const tournamentId = filter.tournaments?.id;

                    if (!categoryId) {
                        requests.push(api.betting.getSportMatches({sportId, ...requestParams}));
                    } else if (!tournamentId) {
                        requests.push(api.betting.getCategoryMatches({categoryId, ...requestParams}))
                    } else {
                        requests.push(api.betting.getMatches({tournamentId, ...requestParams}))
                    }
                })

                if (requests.length) {
                    Promise.all(requests).then(responses => {
                        self._clearEvents();
                        responses.forEach(res => {
                            if (res && res.success) {
                                getRoot(self).betting.matches.setMatchesData({
                                    data: res.data,
                                    isRanged: true,
                                    addFilteredMatchesIds: (id, p1, match) =>
                                        self._pushEvent(id, match),
                                });
                            }
                        })
                        self._setSearchFiltersFetching(false);
                        self._setSearchFiltersResult(true);
                    })
                } else {
                    self._setSearchFiltersFetching(false);
                }
            }),
        }
    })
    .views((self) => ({ //return array of instances from branch All filtered by this range instance ids and replace matchCount for each item
        getFilteredRangeInstances({instanceType, id}) {
            const branch = getRoot(self).betting.branches.get('All');
            const sport = branch.sports.get(id || self.sports.activeItem?.id);
            const childInstanceType = id ? self.filteredInstanceChildName[instanceType] : null;
            let list = [];
            switch (childInstanceType || instanceType) {
                case 'sports':
                    list = branch.sportsList;
                    break;
                case 'categories':
                    if (sport?.isParent) {
                        list = sport.listSubSports;
                    } else if (sport) {
                        list = sport.categoriesList;
                    }
                    break;
                case 'tournaments':
                default:
                    if (sport) {
                        const category = sport.categories.get(self.categories.activeItem?.id);
                        if (category?.isParent) {
                            list = category.listSubCategories;
                        } else if (category) {
                            list = category.clearedTournamentsList;
                        }
                    }
                    break;
            }

            return list.reduce((a, item) => {
                const filteredItem = self[instanceType].items.get(item.id);
                if (filteredItem) {
                    return [...a, {
                        ...item,
                        matchCount: filteredItem.matchCount,
                        isSelected: filteredItem.isSelected,
                    }];
                } else {
                    return a;
                }
            }, []);
        },
        getItem({instanceType, id}) {
            return self[instanceType].items.get(id);
        },
        isThatInstanceSelected({instanceType, id}) {
            return !!self.selectedFilters.find(filter => !!(filter[instanceType]?.id === id && !filter[self.filteredInstanceChildName[instanceType]]?.id));
        },
        getSelectedFilterIndex({instanceType, id}) {
            return self.selectedFilters.findIndex(filter => !!(filter[instanceType]?.id === id && !filter[self.filteredInstanceChildName[instanceType]]?.id));
        },
        get selectedFiltersList() {
            const list = [];
            self.selectedFilters.forEach((filter, i) => {
                let name = filter.sports?.name;
                let nameLastPart = filter.sports?.name;
                if (filter.categories?.name) {
                    name += ' / ' + filter.categories.name;
                    nameLastPart = filter.categories.name;
                }
                if (filter.tournaments?.name) {
                    name += ' / ' + filter.tournaments.name;
                    nameLastPart = filter.tournaments.name;
                }
                list.push({
                    name,
                    label: nameLastPart,
                    sportId: filter.sports?.id,
                    categoryId: filter.categories?.id,
                    tournamentId: filter.tournaments?.id,
                    events: filter.events,
                    groupedByDateEvents: filter.groupedByDateEvents,
                });
            })
            return list;
        },
        get filteredInstanceChildName() {
            return {
                sports: 'categories',
                categories: 'tournaments',
            }
        },
        get isMaxFiltersCountReached() {
            return self.selectedFilters.length >= FILTERS_MAX_SIZE;
        }
    }));

export default FilteredRange;