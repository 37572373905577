import React, {Fragment} from "react";
import {observer} from "mobx-react";
import cx from 'classnames';

import {GameItem} from "../../../components";
import {toCamel} from "../../../common/utils";
import {JackpotGroupCard} from "./jackpot-group-card";

const GamesRender = (
    {
        gamesType,
        list,
        jackpotGroups,
        itemsInRow,
        isFeatured
    }
) => {
    const getGroupByItemIndex = (i) => {
        const itemsWithCard = (itemsInRow - 2) * 2;
        if (jackpotGroups.length && (i % itemsWithCard === 0)) {
            const groupIndex = i / itemsWithCard;
            return jackpotGroups[groupIndex] ? {group: jackpotGroups[groupIndex], groupIndex} : null;
        } else {
            return null;
        }
    }

    /**************** RENDER ******************/
    return (
        <div className="game-list__group">
            <div className={cx("game-list__group-wrapper", isFeatured && 'game-list__group-wrapper--featured')}>
                {list.map((item, i) => {
                    item = toCamel(item);
                    const groupByItemIndex = getGroupByItemIndex(i);
                    return (
                        <Fragment key={item.iD}>
                            { groupByItemIndex ?
                                <JackpotGroupCard group={groupByItemIndex.group} index={groupByItemIndex.groupIndex} />
                                : null
                            }
                            <div className={`games__item`}>
                                <GameItem item={item} gamesType={gamesType}/>
                            </div>
                        </Fragment>
                    )
                })}
            </div>
        </div>
    );
};

export default observer(GamesRender);
