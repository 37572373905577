import React from 'react';


const LiveMatchSoccerAdditionalData = ({data, isFirstInList = true}) => {
    const {
        hr: homeRed = 0,
        hy: homeYellow = 0,
        hc: homeCorner = 0,
        ar: awayRed = 0,
        ay: awayYellow = 0,
        ac: awayCorner = 0,
    } = data[0] || {};

    return (
        <>
            <div className="live-match__info-item">
                {isFirstInList && (
                    <span className="live-match__info-header-item">
                        <i className="doruk-live-corner live-match__info-header-icon" />
                    </span>
                )}
                <span>{homeCorner}</span>
                <span>{awayCorner}</span>
            </div>
            <div className="live-match__info-item">
                {isFirstInList && (
                    <span className="live-match__info-header-item">
                        <span className="live-match__competitor-card live-match__competitor-card-yellow"/>
                    </span>
                )}
                <span>{homeYellow}</span>
                <span>{awayYellow}</span>
            </div>
            <div className="live-match__info-item">
                {isFirstInList && (
                    <span className="live-match__info-header-item">
                        <span className="live-match__competitor-card live-match__competitor-card-red"/>
                    </span>
                )}
                <span>{homeRed}</span>
                <span>{awayRed}</span>
            </div>
        </>
    );
};

const LiveMatchBasketballAdditionalData = ({data, isFirstInList = true}) => {
    let arrayOfIndexes = [1, 2, 3, 4];
    if (data && data[0] && data[0]?.t === 2) {
        arrayOfIndexes = [3, 1, 4, 2];
    }
    return (
        <>
            { arrayOfIndexes.map((index, i) => {
                const period = data.find(item => item.n === index);
                return (
                    <div className="live-match__info-item" key={index}>
                        { isFirstInList && (
                            <span className="live-match__info-header-item live-match__info-header-item--quarter">
                                {i + 1}
                            </span>
                        )}
                        <span>{period?.hs ?? '-'}</span>
                        <span>{period?.aw ?? '-'}</span>
                    </div>
                )
            })}
        </>
    );
}
export const LiveMatchAdditionalInfo = ({info, sportId, isFirstInList}) => {
    if (sportId === '1') return <LiveMatchSoccerAdditionalData data={info} isFirstInList={isFirstInList} />;
    if (sportId === '2') return <LiveMatchBasketballAdditionalData data={info} isFirstInList={isFirstInList} />;
};