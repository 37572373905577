import React from 'react';
import {Link} from "react-router-dom";

import {toCamel, trans} from "../../../common/utils";
import {GameItem, WithDropdown, WithPopover} from "../../../components";
import {JackpotGroupCard} from './jackpot-group-card';


export const JackpotGroup = (
    {
        group,
        games,
        gamesType,
        activeJackpot,
        isGroupFirst,
        jackpotGroupsListByProvider,
        takeItemsCount,
    }
) => {
    const dropDownItems = (options) => {
        const res = [];
        const brandsArr = [];
        options.forEach((item, i) => {
            let currentBrand = '';
            if (!brandsArr.includes(item.brand)) {
                brandsArr.push(item.brand);
                currentBrand = item.brand;
            } else {
                currentBrand = null;
            }
            // TODO: active item here
            if (i === 0) {
                res.push({
                    element: (
                        <Link to={`/slots/jackpots`}
                              className="jackpot-group__dropdown-item jackpot-group__dropdown-item--link"
                        >
                            {trans("ALL")}
                        </Link>
                    ),
                    code: 'all',
                });
            }
            if (currentBrand) {
                res.push({
                    element: (
                        <p className=" jackpot-group__dropdown-item jackpot-group__dropdown-item--brand">
                            {currentBrand}
                        </p>
                    ),
                    code: currentBrand,
                })
            }
            res.push({
                element: (
                    <Link to={`/slots/jackpots${activeJackpot === item.id ? '' : '/' + item.id}`}
                          className="jackpot-group__dropdown-item jackpot-group__dropdown-item--link"
                    >
                        {item.name}
                    </Link>
                ),
                code: item.name,
            });
        })
        return res;
    };

    return games.length ? (
            <div className="game-list__group">
                <div className="jackpot-group__header">
                    <div className="jackpot-group__header-wrapper">
                        {(isGroupFirst || activeJackpot) && <div className="jackpot-group__dropdown">
                            <WithDropdown
                                dataSource={{
                                    submenu: dropDownItems(jackpotGroupsListByProvider),
                                }}
                                openBehavior={'click'}
                                customClasses={`jackpot-group__dropdown-wrapper`}
                                menuClasses="jackpot-group__dropdown-content"
                                customScrollClasses={'jackpot-group__dropdown-scrollbox'}
                            >
                                <WithPopover popoverClasses="ll-popover__text">
                                    <span>{activeJackpot ? group.name : trans('ALL')}</span>
                                </WithPopover>
                            </WithDropdown>
                        </div>}
                        <p className="jackpot-group__header-label">{group.brand}:
                            <span>{group.name} ({group.gamesCount})</span>
                        </p>
                        <Link to={`/slots/jackpots${activeJackpot ? '' : '/' + group.id}`}
                              className="jackpot-group__header-link">
                            {((group.gamesCount > takeItemsCount) || activeJackpot) && trans(activeJackpot ? 'BACK_TO_JACKPOTS' : 'SHOW_ALL_GAMES')}
                        </Link>
                    </div>
                </div>
                <div className="game-list__group-wrapper">
                    <JackpotGroupCard group={group}/>

                    {games.map((item) => {
                        item = toCamel(item);
                        if (item.jackpotId === group.id) {
                            return (
                                <div className={`games__item`}
                                     key={item.iD}>
                                    <GameItem item={item} gamesType={gamesType}/>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        )
        : null;
};