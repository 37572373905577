import React from "react";

import {formatNumber, trans} from "../../../common/utils";
import {BattleCounter, LLBtn2} from "../index";


const BattlePopupMessage = ({
                                popup,
                                userBattleId,
                                showInvitations,
                                language,
                                router,
                                isShowInvitationsLoading,
                                setShowInvitationsLoading,
                                toggleShowInvitations
                            }) => {

    const insertDateTime = new Date(popup?.userBattle?.idt);
    const currentTime = new Date();
    const time = Math.round((currentTime - insertDateTime) / 1000);

    const InvitationButtons = ({roomId}) => {
        return <div className="ll-popup__bottom-buttons">
            <LLBtn2
                customClasses="slots-battles__btn--accent"
                onClick={() => {
                    popup.close();
                    router.push(roomId ? `/slots-battles/${roomId}` : '/slots-battles');
                }}
            >
                {trans("SLOT_BATTLE__JOIN")}
            </LLBtn2>
            <LLBtn2
                customClasses="slots-battles__btn--outlined"
                isLoading={isShowInvitationsLoading}
                onClick={() => {
                    setShowInvitationsLoading(true);
                    toggleShowInvitations().then(() => {
                        setShowInvitationsLoading(false)
                    });
                }}
            >
                {trans("SLOT_BATTLE__DONT_SHOW_AGAIN")}
            </LLBtn2>
        </div>
    };

    const InvitationHideMessage = () => {
        return <div className="ll-popup__message">
            <p className="ll-popup__message-enable-invitation">
                {trans("SLOT_BATTLE__HOW_ENABLE_INVITATION")}
            </p>
        </div>
    };

    switch (popup.whatWasChanged) {
        case 'error':
            return <div className="ll-popup__message">
                {trans("SLOT_BATTLE__CONNECTION_ISSUES_ERROR")}
            </div>
        case 'completed':
            return <div className="ll-popup__message">
                {trans("SLOT_BATTLE__BATTLE_ENDED")} {trans(popup.userPrize ? 'SLOT_BATTLE__YOU_WON' : '')}
                <p className="ll-popup__bottom-message-text">{trans('SLOT_BATTLE__CLICK_HERE_TO_OPEN_RESULTS')}</p>
            </div>
        case 'userIsPlayed':
            return <div className="ll-popup__message">
                    <span className="ll-popup__timer">
                        <BattleCounter
                            battle={popup?.userBattle}
                            startTime={popup?.userBattle?.sdt ?? null}
                            insertTime={popup?.userBattle?.idt}
                            isPopup={true}
                        />
                    </span>
                <p className="ll-popup__bottom-message-text">{trans('SLOT_BATTLE__OPEN_BATTLE_DETAIL')}</p>
            </div>
        case 'starting':
            return <div className="ll-popup__message">
                <p className="ll-popup__bottom-message-text">{trans("SLOT_BATTLE__BATTLE_STARTED_CLICK_TO_PLAY")}</p>
                {!router.location.pathname.includes(userBattleId) ? <span className="ll-popup__timer">
                        <BattleCounter
                            battle={popup?.userBattle}
                            startTime={popup?.userBattle?.sdt ?? null}
                            insertTime={popup?.userBattle?.idt}
                            isPopup={true}
                        />
                    </span>
                    : null
                }
            </div>
        case 'started':
            return <div className="ll-popup__message">
                <p className="ll-popup__message-text">{trans("SLOT_BATTLE__BATTLE_STARTED_CLICK_TO_PLAY")}</p>
                {!router.location.pathname.includes(userBattleId) ? <span className="ll-popup__timer">
                        <BattleCounter
                            battle={popup?.userBattle}
                            startTime={popup?.userBattle?.sdt ?? null}
                            insertTime={popup?.userBattle?.idt}
                            isPopup={true}
                        />
                    </span>
                    : null
                }
            </div>
        case 'canceled':
            return <div className="ll-popup__message">
                {trans("SLOT_BATTLE__BATTLE_CANCELED")}
            </div>
        case 'invitations':
            return showInvitations ? <div className="ll-popup__message">
                    <div className="ll-popup__message-text">
                        {trans("SLOT_BATTLE__BATTLE_INVITATIONS_TEXT")}
                    </div>
                    <InvitationButtons/>
                </div>
                : <InvitationHideMessage/>;
        case 'invitation':
            return showInvitations ? <>
                    <div className="ll-popup__battle-info">
                        {time > 0 ?
                            <>
                                <div className="ll-popup__battle-info-item">
                                    <i className="ll-popup__icon doruk-players"/>
                                    {popup.connectedPlayers + '/' + popup.needPlayers}
                                </div>
                                <div className="ll-popup__battle-info-item">
                                    <i className="ll-popup__icon doruk-money-01"/>
                                    {formatNumber(popup?.userBattle?.Bet, 0, language, popup?.userBattle?.Currency)}
                                </div>
                            </>
                            : null
                        }
                        <div className="ll-popup__battle-info-item">
                            {time > 0 && <i className="ll-popup__icon doruk-time"/>}
                            <BattleCounter
                                battle={popup?.userBattle}
                                startTime={popup?.userBattle?.sdt ?? null}
                                insertTime={popup?.userBattle?.idt}
                                isPopup={true}
                            />
                        </div>
                    </div>
                    <InvitationButtons roomId={popup?.userBattle?.RoomUUID}/>
                </>
                : <InvitationHideMessage/>;
        default:
            return <div className="ll-popup__message">
                {popup.connectedPlayers ? <>
                        <p className="ll-popup__message-text">
                        <span>{
                            trans("SLOT_BATTLE__PLAYERS_IN_BATTLE")
                                .replace('{{PLRS_QTY}}', popup.connectedPlayers + '/' + popup.needPlayers)
                        }</span>
                        </p>
                        <span className="ll-popup__timer">
                                <BattleCounter
                                    battle={popup?.userBattle}
                                    startTime={popup?.userBattle?.sdt ?? null}
                                    insertTime={popup?.userBattle?.idt}
                                    isPopup={true}
                                />
                            </span>
                        <p className="ll-popup__bottom-message-text">{trans('SLOT_BATTLE__OPEN_BATTLE_DETAIL')}</p>
                    </>
                    : null
                }
            </div>
    }
};

export default BattlePopupMessage;
