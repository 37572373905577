import React from 'react';
import cx from 'classnames';

import {LLBtn2, withCommon} from '../../../../../components';
import {formatNumber} from "../../../../../common/utils";


const CashoutModal = withCommon({
    store: ({store}) => ({
        userBets: store.user.userBets,
        activeBetsLoading: store.user.userBets.activeBets.couponWaitingLoading,
        betHistoryLoading: store.user.userBets.betHistory.couponWaitingLoading,
        modal: store.modal,
        language: store.user.language,
        currency: store.user.currency,
    }),
    defaultName: 'CashoutModal',
    isTrans: true,
    isObserver: true,
})(({bet, userBets, type, modal, trans, language, currency, activeBetsLoading, betHistoryLoading}) => {
    const loading = {
        activeBets: activeBetsLoading,
        betHistory: betHistoryLoading,
    };
    return (
        <div className="cashout-modal ll-modal__yes-no">
            <div className="modal__text">
                <p>{trans('Are you sure to want Cashout this coupon?')}</p>
                <p><span>{trans('Cashout value')}:</span>
                    <span>{
                        userBets[type]?.getBet(bet.id)?.cashout ?
                            formatNumber(userBets[type]?.getBet(bet.id)?.cashout, 2, language, currency)
                            : 0
                    }</span>
                </p>
            </div>
            <div className="modal__btns">
                <LLBtn2
                    onClick={() => !loading[type] && userBets[type]?.getCashout({couponId: bet.id})}
                    isLoading={loading[type]}
                    customClasses={cx(
                        'cashout-modal__btn',
                        loading[type] && 'cashout-modal__btn--disabled ll-btn--outlined'
                    )}
                >
                    {trans('Yes')}
                </LLBtn2>
                <LLBtn2 onClick={() => !loading[type] && modal.close()}
                        customClasses={cx(
                            'll-btn--outlined',
                            'cashout-modal__btn',
                            loading[type] && 'cashout-modal__btn--disabled'
                        )}
                >
                    {trans('Cancel')}
                </LLBtn2>
            </div>
        </div>
    )
});

export default CashoutModal;