import React, {useState, useEffect} from 'react';

import {LLBtn2, withCommon, WithPopover} from '../../../../components';
import {formatNumber} from '../../../../common/utils';
import config from '../../../../static/themes/config';

/**
 * @file Represents React Component to render Header to authenticated user
 * @memberOf MyAccountPage
 *
 * @param {string} availableMoney - user money
 * @param {number} customerId - user id
 * @param {string} language - user active language
 * @param {func} logout - logout user
 * @param {number} messageCount - users new message count
 * @param {number} sId - session id
 * @param {string} username - user username
 *
 * @author A.Green - small fixes
 */

const LLHeaderUser = withCommon({
    store: ({store}) => ({
        siteName: store.site.siteName,
        availableMoney: store.user.availableMoney,
        language: store.user.language,
        logout: store.user.logout,
        messageCount: store.user.messageCount,
        username: store.user.username,
        setAccountActivePage: store.user.setAccountActivePage,
        openDrawer: store.drawer.open,
        currency: store.user.currency,
        rewardPoints: store.user.rewardPoints,
        hasRewardSystem: store.user.hasRewardSystem,
        isBlocked: store.user.isBlocked,
    }),
    isTrans: true,
    isObserver: true,
})(
    ({
         siteName,
         availableMoney,
         language,
         logout,
         messageCount,
         setAccountActivePage,
         trans,
         username,
         currency,
         openDrawer,
         rewardPoints,
         hasRewardSystem,
         isBlocked,
     }) => {
        const [balanceShowed, setBalanceShowed] = useState(true);
        const [balanceWidth, setBalanceWidth] = useState(80); //rem

        useEffect(() => {
            if (balanceShowed) {
                setBalanceblockWidth(true);
            }
        }, [availableMoney]);

        useEffect(() => {
            setBalanceblockWidth(balanceShowed);
        }, [hasRewardSystem]);

        const setBalanceblockWidth = (open) => {
            const balanceText =
                `${formatNumber(
                    +availableMoney,
                    2,
                    language
                )} ${currency}${hasRewardSystem ? 
                    (' | ' + rewardPoints) 
                    : ''}`;
            if (open) {
                setBalanceWidth(balanceText.length * 10);
            } else {
                setBalanceWidth(80);
            }
        };

        return (
            <div className="ll-header__top-section-user">
                <LLBtn2
                    customClasses="ll-header-btn ll-header-btn--user-name"
                    onClick={() => {
                        setAccountActivePage('my-info');
                        openDrawer({
                            side: 'right',
                            currentKey: 'my-account',
                        });
                    }}
                >
                    <WithPopover popoverClasses="ll-popover__text">
                        <div className="ll-header-btn__text">{username}</div>
                    </WithPopover>
                </LLBtn2>
                {(!isBlocked && config(siteName).siteConfig.permissionRules.isPayments) && (<LLBtn2
                    customClasses="ll-header-btn ll-header-btn--accent"
                    onClick={() => {
                        setAccountActivePage('deposit');
                        openDrawer({
                            side: 'right',
                            currentKey: 'my-account',
                        });
                    }}
                >
                    <span className="ll-header-btn__text">{trans('deposits')}</span>
                </LLBtn2>)}
                <LLBtn2
                    customClasses="ll-header-btn ll-header__btn-messages"
                    onClick={() => {
                        setAccountActivePage('messages');
                        openDrawer({
                            side: 'right',
                            currentKey: 'my-account',
                        });
                    }}
                >
                    <div className="ll-header__user-messages" title={trans('Messages')}>
                        {!!messageCount && (
                            <div className="ll-header__messages-counter">
                                {messageCount}
                            </div>
                        )}
                        <i className="doruk-ma-mssages-01 ll-header__messages-icon"/>
                    </div>
                </LLBtn2>
                <LLBtn2
                    customClasses="ll-header-btn ll-header-btn--secondary"
                    onClick={() => {
                        setAccountActivePage('betting-history');
                        openDrawer({
                            side: 'right',
                            currentKey: 'my-account',
                        });
                    }}
                >
                    <span className="ll-header-btn__text">
                        {trans('betting-history')}
                    </span>
                </LLBtn2>
                <LLBtn2
                    key={rewardPoints}
                    customClasses="ll-header-btn"
                    onClick={() => {
                        setBalanceblockWidth(!balanceShowed);
                        setBalanceShowed(!balanceShowed);
                    }}
                >
                    <div
                        className={`ll-header__balance${
                            balanceShowed ? '-visible' : '-hidden'
                        }`}
                        style={{width: balanceWidth + 'px'}}
                    >
                        {balanceShowed ? (
                            <>
                                <span>{`${formatNumber(
                                    +availableMoney,
                                    2,
                                    language
                                )} ${currency}`}</span>
                                <i className="llg-eye-slash ll-header__balance-icon"/>
                                {(hasRewardSystem) ? (
                                    <>
                                        <span className="ll-header__balance--divider"/>
                                        <span>{rewardPoints}</span>
                                        <i className="doruk-bonuses-01 ll-header__bonus-icon"/>
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <span className="ll-header__balance-stars">
                                    ********
                                </span>
                                <i className="llg-eye ll-header__balance-icon"/>
                            </>
                        )}
                    </div>
                </LLBtn2>
                <LLBtn2
                    customClasses="ll-header-btn ll-header__sign-out-btn"
                    onClick={() => logout()}
                >
                    <span>{trans('Logout')}</span>
                </LLBtn2>
            </div>
        );
    }
);

export default LLHeaderUser;
