import React, {useState} from "react";
import CheckBox from "devextreme-react/check-box";
import cx from "classnames";
import orderBy from "lodash/orderBy";
import {CSSTransition} from "react-transition-group";
import {CustomRule, Validator} from "devextreme-react/validator";

import {LLBtn2, LLInput, withCommon, WithDropdown, WithPopover} from "../../../../components";
import {formatNumber, trans} from "../../../../common/utils";


export const CreateBattleMenu = withCommon({
    store: ({store}) => ({
        createBattle: store.slotsBattles.createBattle,
        currency: store.user.currency,
        language: store.user.language,
        viewSize: store.site.status.viewSize,
    }),
    defaultName: "CreateBattle",
    isObserver: true
})(({
        createBattle,
        currency,
        language,
        viewSize,
        selectedGame,
        setSelectedGame,
        gameChooseIsActive,
        setGameChooseIsActive
    }) => {

    const [isPrivateBattle, setIsPrivateBattle] = useState(false);
    const [canStartPartial, setStartPartial] = useState(true);
    const [privateBattlePass, setPrivateBattlePass] = useState('');

    const dropDownItems = ({data = [], onClick}) => {
        if (!data.length || (data[0]?.Bet && !data.find(item => item.Currency === currency))) {
            return [{
                element: (
                    <div
                        className="slots-battles__dropdown-item"
                    >
                        {trans('SLOT_BATTLE__NO_BET_OPTION')}
                    </div>
                ),
            }]
        }
        if (data[0]?.Bet) {
            data = orderBy(data, 'Bet', 'asc');
        }
        return data.map((item) => ({
            element: (
                <div
                    className="slots-battles__dropdown-item"
                    onClick={() => onClick(item.Bet ? item.Bet.toString() : item.value.toString())}
                >
                    {(item.Bet && item.Currency === currency) ?
                        formatNumber(item.Bet, 2, language, currency)
                        :
                        item.value
                    }
                </div>
            ),
            code: item
        }))
    };

    const createBattleBtnHandler = () => {
        const props = {
            players: selectedGame?.PlayersQty,
            gameId: selectedGame.ID,
            bet: selectedGame?.Bet,
            hasIncompleteStart: canStartPartial,
        };
        if (privateBattlePass) {
            props.privatePWD = privateBattlePass;
        }
        createBattle(props)
    };

    const isCreateBattleBtnActive = (
        selectedGame?.Bet && selectedGame?.PlayersQty &&
        (!isPrivateBattle || privateBattlePass.length)
    );

    return (
        <div className="create-battle-menu">
            <LLBtn2
                type="button"
                customClasses={cx(
                    "create-battle-menu__form-item",
                    gameChooseIsActive && "create-battle-menu__form-item--active",
                    selectedGame && "create-battle-menu__form-item--chosen"
                )}
                onClick={() => setGameChooseIsActive(!gameChooseIsActive)}
            >
                <>
                    <WithPopover popoverClasses="ll-popover__text">
                        <span>{selectedGame ? selectedGame.GameName : trans("SLOT_BATTLE__CHOOSE_GAME")}</span>
                    </WithPopover>
                    <i className="doruk-triangle create-battle-menu__form-item-icon"/>
                </>
            </LLBtn2>
            <div className="slots-battles-dropdown">
                <WithDropdown
                    dataSource={{
                        submenu: dropDownItems({
                            data: selectedGame?.jbet ? JSON.parse(selectedGame.jbet).reduce((a, i) => i.Currency === currency ? [...a, i] : a, []) : [],
                            onClick: (bet) => setSelectedGame({...selectedGame, Bet: bet})
                        })
                    }}
                    openBehavior={"click"}
                    menuItemIsActive={(item) => item.code === selectedGame?.Bet}
                    customClasses={cx(
                        "slots-battles-dropdown__wrapper",
                        selectedGame?.Bet && 'll-dropdown-wrapper--chosen'
                    )}
                    menuClasses="slots-battles-dropdown__content"
                    placement={"bottom-start"}
                    customScrollClasses={"slots-battles-dropdown__scrollbox"}
                    disabled={!selectedGame}
                >
                    {selectedGame?.Bet ? formatNumber(+selectedGame.Bet, 2, language, currency) : trans(`SLOT_BATTLE__BET`)}
                </WithDropdown>
            </div>
            <div className="slots-battles-dropdown">
                <WithDropdown
                    dataSource={{
                        submenu: dropDownItems({
                            data: optionPlayersCount,
                            onClick: (qty) => setSelectedGame({...selectedGame, PlayersQty: qty}),
                        })
                    }}
                    openBehavior={"click"}
                    menuItemIsActive={(item) => item.code === selectedGame?.PlayersQty}
                    customClasses={cx(
                        'slots-battles-dropdown__wrapper',
                        selectedGame?.PlayersQty && 'll-dropdown-wrapper--chosen'
                    )}
                    menuClasses="slots-battles-dropdown__content"
                    placement={"bottom-start"}
                    customScrollClasses={"slots-battles-dropdown__scrollbox"}
                    disabled={!selectedGame}
                >
                    {selectedGame?.PlayersQty ? selectedGame?.PlayersQty : trans(`SLOT_BATTLE__PLAYERS`)}
                </WithDropdown>
            </div>
            <CheckBox
                elementAttr={{class: `slots-battles-checkbox slots-battles-checkbox--${viewSize} slots-battles-checkbox__start-partial`}}
                value={canStartPartial}
                onValueChanged={() => setStartPartial(!canStartPartial)}
                text={trans("SLOT_BATTLE__START_NOT_FULL")}
            />
            <CheckBox
                elementAttr={{class: `slots-battles-checkbox slots-battles-checkbox--${viewSize}`}}
                value={isPrivateBattle}
                onValueChanged={() => setIsPrivateBattle(!isPrivateBattle)}
                text={trans("SLOT_BATTLE__PRIVAT")}
            />
            <CSSTransition timeout={200} in={isPrivateBattle} unmountOnExit>
                <LLInput
                    customClasses={'slots-battles__private-pass'}
                    maxLength={16}
                    value={privateBattlePass}
                    onValueChanged={(e) => setPrivateBattlePass(e.value)}
                    label={trans('SLOT_BATTLE__CREATE_PASSWORD')}
                    labelPosition="inside"
                    stylingMode="outlined"
                    showClearButton={true}
                >
                    <Validator>
                        <CustomRule
                            message={trans('PASSWORD_EMPTY')}
                            validationCallback={() => privateBattlePass.length}
                        />
                    </Validator>
                </LLInput>
            </CSSTransition>
            <LLBtn2
                onClick={createBattleBtnHandler}
                disabled={!isCreateBattleBtnActive}
                customClasses={cx(
                    'slots-battles__btn--accent',
                    `create-battle-menu__form-handle-btn${!isCreateBattleBtnActive ? "--disabled" : ""}`
                )}
            >
                {trans("SLOT_BATTLE__CREATE")}
            </LLBtn2>
        </div>
    );
});

const optionPlayersCount = [
    {value: 2, name: "2"},
    {value: 3, name: "3"},
    {value: 4, name: "4"},
    {value: 5, name: "5"},
    {value: 6, name: "6"},
    {value: 7, name: "7"},
    {value: 8, name: "8"},
    {value: 9, name: "9"},
    {value: 10, name: "10"},
    {value: 11, name: "11"},
    {value: 12, name: "12"},
    {value: 13, name: "13"},
    {value: 14, name: "14"},
    {value: 15, name: "15"},
];
