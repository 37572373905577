import React, { useEffect, useMemo, useState } from 'react';
import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';
import Pagination from '@mui/material/Pagination';

import { LLScrollBox, withCommon, WithDropdown, WithPopover } from '../../';
import {formatDate, formatNumber} from '../../../common/utils';

import trMessages from './tr.json';
import aaMessages from './aa.json';
import { locale, loadMessages } from 'devextreme/localization';


const PAGE_SIZES = [ 5, 10, 20, 50 ];
const pageSizerSource = PAGE_SIZES.map((size) => ({
    element: (<div>{size}</div>),
    code: size,
}));

let filteredData = {},
    tempData = {
        'bet-history': [],
        'bet-details': [],
    };

const LLTable = withCommon({
    store: ({ store }) => ({
        currency: store.user.currency,
        language: store.user.language,
        mobileUserAgent: store.user.mobileUserAgent,
        viewSize: store.site.status.viewSize,
        currentView: store.site.status.currentWidth,
    }),
    propTypes: {},
    defaultName: 'LLTable',
    isTrans: true,
    isObserver: true,
})(({
    data = [],
    customConfig = {},
    size,
    viewSize,
    currentView,
    currency,
    language,
    trans,
    ds = null,
    dependenciesForRerender = [],
    name,
}) => {
    const [tableState, setTableState] = useState({
        totalSum: 0,
        totalPageSum: 0,
        pageSize: size || 20,
        pageNumber: 0,
        activePage: 0,
    });
    
    const [currentFilteringColumnIndex, setCurrentFilteringColumnIndex] = useState(null),
          [dataSource, setDataSource] = useState(data);

    tempData[name] = (filteredData[name] && filteredData[name].length) ?
        filteredData[name] : data;

    useEffect(() => {
        switch (language) {
            case 'tr':
                loadMessages(trMessages);
                break;
            case 'aa':
                loadMessages(aaMessages);
                break;
            default:
                break;
        }
        locale(language);
    }, []);

    useEffect(() => {
        if (!customConfig.remoteUrl) {
            if (
                (viewSize === 'mobile' && !customConfig.mobile?.noPagination) ||
                (viewSize !== 'mobile' && !customConfig.desktop?.noPagination)
            ) {
                setDataSource(paginationDataFilter(tempData[name], tableState.activePage));
            } else {
                setDataSource(tempData[name]);
            }
        }
    }, [ tempData[name], tableState.pageSize, tableState.activePage ]);

    useEffect(() => {
        if (
            (viewSize === 'mobile' && !customConfig.mobile?.noPagination) ||
            (viewSize !== 'mobile' && !customConfig.desktop?.noPagination)
        ) {
            setTableState({
                ...tableState,
                pageNumber: Math.ceil(tempData[name].length / tableState.pageSize),
                activePage: 0,
            });
        }
    }, [tempData[name].length, tableState.pageSize]);

    const customCellRender = {
        currency: (options) => formatNumber(options.value, 2, language, currency)?.toString(),
        odds: (options) => formatNumber(options.value, 2, language)?.toString(),
        date: (options) => formatDate(options.value, 'DD.MM.YYYY'),
        time: (options) => formatDate(options.value, 'HH:mm'),
        dateTime: (options) => formatDate(options.value, 'DD.MM.YYYY HH:mm'),
        dateTimeWithoutYear: (options) => formatDate(options.value, 'DD.MM HH:mm'),
        dateTimeWithSeconds: (options) => formatDate(options.value, 'DD.MM.YYYY HH:mm:SS'),
        capitalize: (options) => <span style={{textTransform: 'capitalize'}}>{options.value}</span>,
        default: (options) => options.value,
    };

    const totalSumCalculate = (pageData) => {
        if (
            (viewSize === 'mobile' && customConfig.mobile &&
                (customConfig.mobile.totalColumnKey || customConfig.mobile.customTotal))
            ||
            (viewSize !== 'mobile' && customConfig.desktop &&
                (customConfig.desktop.totalColumnKey || customConfig.desktop.customTotal))
        ) {
            const totalPageSum = pageData.reduce(
                    (acc, item) => item.rowType === 'data' ? (acc + item.data[customConfig[
                        viewSize === 'mobile' ? 'mobile' : 'desktop'].totalColumnKey]) : acc, 0
                ) || 0;
            const totalSum = tempData[name]?.length ? tempData[name].reduce(
                (acc, item) => acc + item[customConfig[
                    viewSize === 'mobile' ? 'mobile' : 'desktop'].totalColumnKey], 0) : 0;

            setTableState((currentState) => ({
                ...currentState,
                totalSum,
                totalPageSum,
            }));
        }
    };

    const onOptionChanged = (e) => {
        if (e.fullName.includes('.filterValues')) {
            if (e.value) {
                const filteredColumnIndex = +e.fullName
                    .replace('columns[', '')
                    .replace('].filterValues','');
                if(currentFilteringColumnIndex && filteredColumnIndex !== currentFilteringColumnIndex) {
                    e.component.columnOption(currentFilteringColumnIndex, 'filterValues', []);
                }

                setCurrentFilteringColumnIndex(filteredColumnIndex);

                filteredData[name] = data.filter(item =>
                    e.value.includes(item[e.component.columnOption(filteredColumnIndex).dataField]));

                setTableState({
                    ...tableState,
                    pageNumber: Math.ceil(filteredData[name].length / tableState.pageSize),
                    activePage: 0,
                });
                setDataSource(paginationDataFilter(filteredData[name], 0))
            } else {
                filteredData[name] = [];
                setTableState({
                    ...tableState,
                    pageNumber: Math.ceil(tempData[name].length / tableState.pageSize),
                    activePage: 0,
                });
                setDataSource(paginationDataFilter(tempData[name], 0));
            }
        } else {
            setCurrentFilteringColumnIndex(null);
        }
    }

    const userTableDefaultConfig = useMemo(() => ({
        mobile: {
            showColumnLines: false,
            rowAlternationEnabled: true,
            columnHidingEnabled: true,
            paging: {
                enabled: false,
            },
            headerFilter: {
                texts: {
                    ok: trans('Ok'),
                    cancel: trans('Cancel')
                },
                ...customConfig.mobile?.headerFilter
            },
            onContentReady: (e) => {
                return !ds ? totalSumCalculate(e.component.getVisibleRows() ?? []) : null
            },
            language: language,
        },
        desktop: {
            showColumnLines: true,
            showRowLines: true,
            showBorders: true,
            rowAlternationEnabled: true,
            paging: {
                enabled: false,
            },
            headerFilter: {
                texts: {
                    ok: trans('Ok'),
                    cancel: trans('Cancel')
                },
                ...customConfig.desktop?.headerFilter
            },
            onOptionChanged: !ds ? onOptionChanged : null,
            onContentReady: (e) => {
                if (typeof customConfig.desktopGridProps.onContentReady === 'function') {
                    customConfig.desktopGridProps.onContentReady();
                }
                !ds && totalSumCalculate(e.component.getVisibleRows() ?? []);
            },
            language: language,
        },
    }), [tableState.pageSize, tableState.activePage]);

    const paginationDataFilter = (tempData, activePage) => tempData.slice(
        tableState.pageSize * activePage,
        tableState.pageSize * (activePage + 1));

    const renderTableFooter = () => (
        <>
            {customConfig.desktop && (customConfig.desktop.totalColumnKey || customConfig.desktop.customTotal) && (
                <div className="data-table__summary">
                    {customConfig.desktop.totalColumnKey && (<>
                        <div className="data-table__summary-item">
                            <span>{`${trans('Page Total')}: `}</span>
                            <span>
                                {formatNumber(tableState.totalPageSum, 2, language, currency)}
                            </span>
                        </div>
                        <div className="data-table__summary-item">
                            <span>{`${trans('Total')}: `}</span>
                            <span>
                                {formatNumber(tableState.totalSum, 2, language, currency)}
                            </span>
                        </div>
                    </>)}
                    {customConfig.desktop?.customTotal && customConfig.desktop.customTotal(tempData[name]) && (
                        <div className="data-table__summary-item data-table__summary-item--odds">
                            <span>{`${trans(customConfig.desktop.customTotal(tempData[name]).title)}: `}</span>
                            <span>
                                {formatNumber(customConfig.desktop.customTotal(tempData[name])?.calcCustom, 2, language)}
                            </span>
                        </div>
                    )}
                </div>
            )}
            { tableState.pageNumber > 1 &&
                ( viewSize === 'mobile' || !customConfig.desktop.noPagination) ? (
                    <div className="data-table__pagination">
                        <ul className="data-table__page-sizer">
                            { !size &&
                                <WithDropdown
                                    dataSource={{submenu: pageSizerSource}}
                                    onMenuItemClick={(item) => item.code !== tableState.pageSize &&
                                        setTableState((currentState) => ({
                                            ...currentState,
                                            pageSize: item.code,
                                        }))}
                                    openBehavior="click"
                                    menuItemIsActive={(item) => item.code === tableState.pageSize}
                                    customClasses="data-table__page-size-dropdown"
                                    menuClasses="data-table__page-size-dropdown-menu"
                                >
                                    <span className="data-table__page-size">{tableState.pageSize}</span>
                                </WithDropdown>
                            }
                        </ul>
                        <div className="data-table__pagination-root js_data-table__pagination-root">
                            <Pagination
                                count={tableState.pageNumber}
                                page={tableState.activePage + 1}
                                onChange={(e, page) => {
                                    if (page > 0) {
                                        setTableState({
                                            ...tableState,
                                            activePage: page - 1,
                                        })
                                        setDataSource(paginationDataFilter(tempData[name], page - 1));
                                    }
                                }}
                                classes={{
                                    ul: 'data-table__pagination-numbers'
                                }}
                                siblingCount={0}
                                boundaryCount={
                                    currentView === 'small-mobile' || name === 'account-history' ? 0 :
                                        name === 'bet-history' && viewSize === 'desktop' ? 2 : 1 }
                            />
                        </div>
                        <div className="data-table__pagination-dummy-box" />
                    </div>
                ) : null
            }
        </>
    );

    const renderMobileGrid = (src) => (
        <DataGrid
            dataSource={src}
            {...userTableDefaultConfig.mobile}
            {...customConfig.mobileGridProps}
        >
            { customConfig.mobile?.columns?.length &&
            customConfig.mobile?.columns.map((column, i) => {
                const {key, render, format, alignment, title, ...rest} = column;
                return (
                    <Column
                        key={i}
                        dataField={key}
                        cellRender={(options) =>
                            <span className="data-table__cell-content">
                                        {typeof render === 'function'
                                            ? render(options)
                                            : customCellRender[format || 'default'](
                                                options
                                            )}
                                    </span>
                        }
                        alignment={alignment || 'center'}
                        caption={title || key}
                        {...rest}
                    />
                );
            })}
        </DataGrid>
    );

    const renderDesktopGrid = (src) => (
        <DataGrid
            dataSource={src}
            {...userTableDefaultConfig.desktop}
            {...customConfig.desktopGridProps}
        >
            { customConfig.desktop?.columns?.length &&
            customConfig.desktop?.columns.map((column, i) => {
                const {
                    key,
                    render,
                    format,
                    title,
                    alignment,
                    sortOrder,
                    ...rest
                } = column;

                return (
                    <Column
                        dataField={key}
                        cellRender={(options) =>
                            viewSize === 'desktop' ? (
                                <WithPopover popoverClasses="ll-popover__text">
                                    <span>
                                        { typeof render === 'function'
                                            ? render(options)
                                            : customCellRender[format || 'default'](options)
                                        }
                                    </span>
                                </WithPopover>
                            ) : (
                                <span className="data-table__cell-content">
                                            {typeof render === 'function'
                                                ? render(options)
                                                : customCellRender[format || 'default'](
                                                    options
                                                )}
                                        </span>
                            )
                        }
                        headerFilter={
                            (rest.allowHeaderFiltering && !ds) ?
                            {
                                dataSource:
                                    [...new Set(data.map(item => item[key]))].sort().map(item => ({
                                        text: item,
                                        value: item,
                                    }))
                            } :
                            null
                        }
                        caption={title || key}
                        alignment={alignment || 'center'}
                        groupIndex={
                            customConfig.desktop.groupingIndex === i ? 0 : null
                        }
                        sortOrder={sortOrder}
                        key={i}
                        {...rest}
                    />
                );
            })}
            { customConfig.desktop && customConfig.desktop.buildSubItem &&
                customConfig.desktopGridProps?.onRowClick &&
                    <MasterDetail
                        enabled={false}
                        render={customConfig.desktop.buildSubItem}
                    />
            }
        </DataGrid>
    );

    const renderTableContent = (src) => {
        return (
        tempData[name].length || customConfig.remoteUrl ? (
            viewSize === 'mobile' ? (
                <div
                    id={customConfig.mobile.id}
                    className={`data-table--mobile ${customConfig.mobile.customClasses}`}
                >
                    { customConfig.mobile.scrollClass ?
                        <LLScrollBox
                            id={customConfig.mobile.id + '-scroll'}
                            customClasses={customConfig.mobile.scrollClass}
                        >
                            {renderMobileGrid(src)}
                        </LLScrollBox>
                        :
                        renderMobileGrid(src)
                    }
                    { renderTableFooter() }
                </div>
            ) : (
                <div
                     id={customConfig.desktop.id}
                     className={`data-table--${viewSize}${customConfig.desktop.customClasses ? ' ' + customConfig.desktop.customClasses : ''}`}
                >
                    { customConfig.desktop.scrollClass ?
                        <LLScrollBox
                            id={customConfig.desktop.id + '-scroll'}
                            customClasses={customConfig.desktop.scrollClass}
                        >
                            {renderDesktopGrid(src)}
                        </LLScrollBox>
                        :
                        renderDesktopGrid(src)
                    }
                    {renderTableFooter()}
                </div>
            )
        ) : (
            <div className="data-table--empty">
                {trans(customConfig[
                    viewSize === 'mobile' ? 'mobileGridProps' : 'desktopGridProps'
                    ]?.noDataText)
                }
            </div>
        )
    )};

    return (
        ds ? renderTableContent(ds) :
            useMemo(() =>
                renderTableContent(dataSource),
        [JSON.stringify(tableState), dataSource, ...dependenciesForRerender])
    );
});

export default LLTable;
