const parseUrl = (url, user) => {
    const elementAttributes = {};
    let someClick = () => {};

    if (url) {
        if (url?.includes('http')) {
            elementAttributes.href = url;
            elementAttributes.target = '_blank';
            elementAttributes.rel = 'noopener noreferrer';
        } else if (url.includes('/api/games/play')) {
            elementAttributes.href = url;
            elementAttributes.target = '_blank';
            elementAttributes.rel = 'noopener noreferrer';
            someClick = (e) => {
                if (
                    !url.includes('&demo=1')
                    && !user.isLogged
                    && user.mobileUserAgent
                ) {
                    e.preventDefault();
                    user.openLogin();
                }
            }
        } else if (url !== '#') {
            elementAttributes.to = url;
        }
    }
    return {elementAttributes, someClick};
}

export default parseUrl;
