import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";

import {trans} from "../../../../common/utils";
import {LLBtn2} from "../../../../components";


const BattleRules = ({store}) => {
    const viewSize = store.site.status.viewSize;
    const setTutorialStep = store.slotsBattles.setTutorialStep;
    const setBackDropClosable = store.site.status.setBackDropClosable;
    const isLiveChatOpen = store.liveChat.isLiveChatOpen;
    const close = store.modal.close;
    const tutorialOnCloseGoTo = store.slotsBattles.tutorialOnCloseGoTo;
    const openChat = store.liveChat.open;
    const router = store.router;
    const language = store.user.language;
    const timeToPlay = store.site.slotBattlesConfig.timeToPlay;
    const timeToStart = store.site.slotBattlesConfig.timeToStart;
    const timeToWait = store.site.slotBattlesConfig.timeToWait;

    useEffect(() => {
        setBackDropClosable(false);
    }, []);

    return (
        <div className={`battle-tutorial-modal battle-rules-modal battle-tutorial-modal--${viewSize}`}>
            <iframe
                className="battle-tutorial-modal__content"
                frameBorder="0"
                title="rules-iframe"
                src={`/battle-rules.html?lang=${language}&view=${viewSize}&timeToPlay=${timeToPlay}&timeToStart=${timeToStart}&timeToWait=${timeToWait}`}
            />
            <div className="battle-tutorial-modal__buttons">
                <LLBtn2
                    onClick={() => {
                        setTutorialStep(0);
                        close();
                        router.push(tutorialOnCloseGoTo);
                    }}
                    customClasses="slots-battles__btn--accent"
                >
                    {trans("SLOT_BATTLE__TUTORIAL_GOT_IT")}
                </LLBtn2>
                {isLiveChatOpen ? <LLBtn2
                        onClick={() => {
                            openChat();
                            close();
                        }}
                        customClasses="slots-battles__btn slots-battles__btn--outlined"
                    >
                        {trans("Live Support")}
                    </LLBtn2>
                    : null
                }
            </div>
        </div>
    );
};

export default inject('store')(observer(BattleRules));