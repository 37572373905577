import React from 'react';

import { withCommon, ForgotPasswordForm } from '../../components';


const ForgotPassword = withCommon({
    store: ({ store }) => ({
        viewSize: store.site.status.viewSize,
    }),
    isTrans: true,
})(({ trans, viewSize }) => {
    return (
        <div
            className={`ll-page forgot-password${
                viewSize === 'mobile' ? '--mobile' : '--tablet'
            }`}
        >
            <p className="forgot-password__header">{trans('Fill the form please')}</p>
            <ForgotPasswordForm />
        </div>
    );
});

export default ForgotPassword;
