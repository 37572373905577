import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';

import { LLInput, llNotifier } from '../../../../components';
import { trans } from '../../../../common/utils';


let searchPanelHandlerTimeout = null;

const SearchPanel = ({ store, focusSearch }) => {
    const
        initializeSearch = store.betting.branch.search.initializeSearch,
        clearSearchStore = store.betting.branch.search.clearSearch,
        clearErrors = store.betting.branch.search.clearErrors,
        searchString = store.betting.branch.search.searchString,
        setBackdropShown = store.site.status.setBackdropShown;

    const [searchText, setSearchText] = useState('');
    const [canNotify, setCanNotify] = useState(true);

    const
        inputRef = useRef(),
        currentSearchVal = useRef(searchString);

    useEffect(() => {
        clearErrors();
        if (focusSearch) {
            inputRef.current.instance.focus();
        }
    }, []);
    useEffect(() => {setSearchText(searchString)}, [searchString]);
    useEffect(() => {currentSearchVal.current = searchText}, [searchText]);

    const searchValueChangeHandler = (e) => {
        setSearchText(e.value);
        setCanNotify(true);
    };

    const search = () => {
        if (currentSearchVal.current?.length >= 3) {
            setBackdropShown(false);
            initializeSearch({ searchString: currentSearchVal.current });
        }

        if (currentSearchVal.current?.length && currentSearchVal.current.length < 3) {
            if (canNotify) {
                llNotifier({
                    message: 'Minimum 3 characters required',
                    type: 'warning',
                    options: {},
                });
                setCanNotify(false);
                setTimeout(() => setCanNotify(true), 1000);
            }
        }
    };

    const searchHandler = (e) => {
        clearTimeout(searchPanelHandlerTimeout);
        if (currentSearchVal.current?.length >= 3 && e.event.keyCode === 13) {
            search();
            inputRef.current.instance.blur();
        } else {
            searchPanelHandlerTimeout = setTimeout(() => {
                if (currentSearchVal.current?.length === 0) {
                    clearSearch();
                }
                if (currentSearchVal.current?.length >= 6) {
                    search();
                    inputRef.current.instance.blur();
                }
            }, 1000);
        }
    };

    const clearSearch = () => {
        clearSearchStore();
        setSearchText(null);
    };

    return (
        <div className="sport-search">
            <LLInput
                ref={inputRef}
                customClasses="sport-search__container"
                id="sport-search__input"
                name="sport-search__input"
                placeholder={trans("PREMATCH_FILTERS_PANEL_LOOKING_FOR")}
                value={searchText}
                valueChangeEvent="input"
                onValueChanged={searchValueChangeHandler}
                onKeyDown={(e) => searchHandler(e)}
                adormentAfter={(
                    <div
                        className={`sport-search__icons${
                            searchString && searchString === searchText
                                ? '--clear'
                                : '--search'
                        }`}
                    >
                        <span
                            className="sport-search__icon dx-icon-search icon"
                            onClick={() => search()}
                        />
                        <span
                            className="sport-search__icon dx-icon-remove icon"
                            onClick={() => clearSearch()}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default inject('store')(observer(SearchPanel));
