import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { LLFooterMenu, LLFooterLicense } from './components';
import {withCommon} from "../../../components";

/**
 * @desc Represents the markup for Footer of Content
 *
 * @author A.Huskova
 */

const LLContentFooter = withCommon({
    store: ({ store }) => ({
        socialIcons: store.site.socialIcons.icons,
        config: store.modifiedConfig,
        viewSize: store.site.status.viewSize,
    }),
})(({ config, socialIcons }) => {
    const [ state, setState ] = useState({
        isAdditionalColumn: false,
        columnsClass: ''
    });

    useEffect(() => {
        const isAdditionalColumn = !!(
            config.siteConfig?.appearanceRules?.isLiveChat ||
            config.siteConfig?.email ||
            socialIcons.length ||
            config.footerLicenceIcons?.length);

        setState({
            isAdditionalColumn,
            columnsClass: (config.footerContentMenu?.length + +isAdditionalColumn)
                %2 === 0 ? 'even' : 'odd'
        });
    }, [socialIcons, config]);

    return (
    <div className="ll-content__footer">
        <div className="footer-menu">
            <LLFooterMenu customClasses={cx(
                state.columnsClass,
                state.isAdditionalColumn && ' with-additional'
            )} />
            { state.isAdditionalColumn &&
                <LLFooterLicense customClasses={state.columnsClass} />
            }
        </div>
        <div className="footer-info__partners-logo" />
    </div>
)});

export default LLContentFooter;