import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import cx from 'classnames';

import {LLErrorVisualizer} from '../../components';
import SportDesktop from './desktop/sport-desktop';
import SportMobile from './mobile/sport-mobile';
import SportTablet from './tablet/sport-tablet';
import LLBackdrop from "../../layout/backdrop/backdrop";


const SportPage = ({store}) => {
    const viewSize = store.site.status.viewSize,
        isSiteActive = store.site.status.isActive,
        mountSportPage = store.betting.mountSportPage,
        branch = store.betting.branch,
        sportMountStatus = store.betting.sportMountStatus,
        clearAllUpdaters = store.betting.clearAllUpdaters,
        loadrequestedData = store.betting.loadrequestedData,
        globalError = store.betting.bettingErrors.global,
        errorTitle = store.betting.bettingErrors.title,
        location = store.router.location,
        couponsList = store.user.coupons.list,
        previousBranchId = store.router.previousPrematchPathParams.branchId,
        isSportBackdrop = !store.site.status.isBackDropFullScreen;

    const [isFirstLoading, setFirstLoading] = useState(true);

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                         Load requested by router data at mount                       ##
    // ##                                                                                      ##
    // ##========================================================================================

    //Detect if branch is changed within new URL
    const refreshDataProps = () => {
        const requestProps = {
            takerequestedDataFromRouter: true,
        };
        let urlArray = location.pathname.split('/');
        urlArray = urlArray.slice(2);
        const [branchId] = [...urlArray];
        if (previousBranchId !== branchId) {
            clearAllUpdaters();
            requestProps.initialLoading = true;
        }
        return requestProps;
    };

    useEffect(() => {
        mountSportPage(true);
        return () => {
            mountSportPage(false);
        };
    }, []);

    useEffect(() => {
        if (isSiteActive) {
            loadrequestedData({
                takerequestedDataFromRouter: true,
                initialLoading: true,
            });
            setFirstLoading(false);
        }
    }, [isSiteActive, viewSize]);

    useEffect(() => {
        if (!isFirstLoading) {
            if (sportMountStatus) {
                loadrequestedData({
                    ...refreshDataProps(),
                });
            }
        } else {
            setFirstLoading(false);
        }
    }, [location.pathname]);

    const renderPrematch = () =>
        globalError ? (
            <LLErrorVisualizer error={globalError} title={errorTitle}/>
        ) : viewSize === 'mobile' ? (
            <SportMobile/>
        ) : viewSize === 'tablet' && branch.id === 'Live' ? (
            <SportTablet/>
        ) : (
            <SportDesktop/>
        );

    return (
        <div
            className={cx('ll-page',
                globalError && 'll-page--error',
                `prematch--${viewSize}`,
                branch.id === 'Live' && 'sports-live',
                (viewSize === 'tablet' && couponsList.length) && `prematch--with-tickets`,
                'js_prematch-page'
            )}
        >
            {renderPrematch()}
            {isSportBackdrop && <LLBackdrop/>}
        </div>
    );
};

export default inject('store')(observer(SportPage));