import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {
    CouponsPanelDesktop,
    LLScrollBox,
} from "../../../../../components";
import {trans} from '../../../../../common/utils';
import {
    ActiveBetsPanelDesktop,
    MiniBanners
} from "./";
import {VAIXWidget} from '../../../components';


const SportsRightPanelTabs = ({store}) => {
    const coupons = store.user.coupons,
        activeBetsCount = store.user.activeBetsCount,
        couponsPanelView = store.user.couponsPanelView,
        setCouponsPanelView = store.user.setCouponsPanelView,
        isCustom = store.user.coupons.isCustom;

    let bananaDefender = true;

    useEffect(() => () => {
        bananaDefender = false;
    }, []);

    useEffect(() => {
        if (activeBetsCount && bananaDefender) {
            const t = document.querySelector('.js_active-bets-count');
            t.classList.add('animated');
            setTimeout(() => {
                t.classList.remove('animated');
            }, 300);
        }
    }, [activeBetsCount]);

    return <div className="coupon-tabs">
        <div className="coupon-tabs__header">
            <div
                onClick={() => setCouponsPanelView('coupons')}
                className={cx('coupon-tabs__header-tab',
                    couponsPanelView === 'coupons' && 'active',
                    'coupon-tabs__header-tab--btn'
                )}
            >
                <i className="doruk-bets-slip-01 coupon-tabs__header-icon">
                    {coupons.coupons.size ?
                        <div className="coupon-tabs__header-icon-count js_coupon-icon-count">
                            {coupons.coupons.size}
                        </div>
                        : null
                    }
                </i>
                <span>{!isCustom ? trans('Bets') : trans('Bet Builder')}</span>
            </div>
            <div
                onClick={() => setCouponsPanelView('bets')}
                className={cx('coupon-tabs__header-tab',
                    couponsPanelView === 'bets' && 'active',
                    'coupon-tabs__header-tab--btn'
                )}
            >
                <i className="doruk-rules-01 coupon-tabs__header-icon">
                    {(activeBetsCount) ?
                        <div className="coupon-tabs__header-icon-count js_active-bets-count">
                            {activeBetsCount}
                        </div>
                        : null
                    }
                </i>
                {trans('activeBets')}
            </div>
        </div>
        <div className={`coupon-tabs__content ${
            couponsPanelView === 'bets' ? 'coupon-tabs__content--bets' : ''
        }`}>
            {couponsPanelView === 'coupons' &&
                <div className="coupon-tabs__content-item">
                    <LLScrollBox id="prematch-coupons" customClasses="coupons__scrollbox">
                        <CouponsPanelDesktop/>
                        <div className="prematch-widgets">
                            <VAIXWidget />
                            <MiniBanners/>
                        </div>
                    </LLScrollBox>
                </div>
            }
            {couponsPanelView === 'bets' &&
                <div className="coupon-tabs__content-item">
                    <ActiveBetsPanelDesktop/>
                </div>
            }
        </div>
    </div>
};

export default inject('store')(observer(SportsRightPanelTabs));