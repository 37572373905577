import React from 'react';

import { withCommon } from '../../../../components';
import {API_PREFIX} from "../../../../common/api/api-url-config";
import apiUrls from "../../../../common/api/api-urls";

const TvBet = withCommon({
    store: ({ store }) => ({
        viewSize: store.site.status.viewSize,
    }),
    defaultName: 'TvBetPage',
    isObserver: true,
})(({ viewSize }) => {

    return (
        <div className={`ll-page tvbet--${viewSize}`}>
            <iframe
                className={`tvbet-iframe--${viewSize}`}
                title="tvbet"
                src={`${API_PREFIX}${apiUrls.games.openGame}?providerId=800`}
                frameBorder="0"
                allowFullScreen
            />
        </div>
    );
});

export default TvBet;
