import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import { LLScrollBox, LLTable, LLErrorVisualizer } from '../../../../../../components';
import {trans} from '../../../../../../common/utils';
import apiUrls from "../../../../../../common/api/api-urls";
import {API_PREFIX} from "../../../../../../common/api/api-url-config"


const LiveCalendarData = ({ store }) => {
    const viewSize = store.site.status.viewSize;

    const [ liveCalendarError, setLiveCalendarError ] = useState(false);

    let dataSource = {
        store: createStore({
            loadUrl: API_PREFIX + apiUrls.betting.liveCalendar,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: true };
                if(method === "load") ajaxOptions.method = "POST";
            },
            onAjaxError: () => {
                setLiveCalendarError(true);
            }
        })
    };

    if (viewSize === 'mobile') {
        dataSource = {
            ...dataSource,
            searchValue: store.betting.liveMatches.liveCalendarSearchString,
            searchExpr: [
                'MatchDateOnly',
                'MatchTime',
                'SportName',
                'CategoryName',
                'TournamentName',
                'homeNm',
                'awayNm'
            ],
        }
    }

    const onEditorPreparing = (e) => {
        if (e.parentType === 'searchPanel') {
            e.editorOptions.onValueChanged = function (editor) {
                if ( editor.value?.length == 0 || editor.value?.length > 2) {
                    e.component.searchByText(editor.value);
                }
            };
        }
    };

    const searchHighliter = (str) => {
        let res = '';
        if (store.betting.liveMatches.liveCalendarSearchString.length) {
            res = str.replace(new RegExp(store.betting.liveMatches.liveCalendarSearchString, 'gmi'), (p1, p2) =>
                '<span class="dx-datagrid-search-text">' + p1 + '</span>'
            )
        }
        return res.length ? res : str;
    }

    const liveCalendarTableConfig = {
        desktop: {
            columns: [
                {
                    dataField: 'MatchDateOnly',
                    title: trans('Date'),
                    width: viewSize === 'desktop' ? 140 : '16%',
                    allowSorting: true,
                    sortOrder: 'asc',
                },
                {
                    dataField: 'MatchTime',
                    title: trans('Time'),
                    allowHeaderFiltering: true,
                    render: (options) => options.data?.MatchTime?.slice(0, -3),
                    width: 140,
                    allowSorting: true,
                    sortOrder: 'asc',
                },
                {
                    dataField: 'SportName',
                    title: trans('Sport'),
                    width: '16%',
                    allowHeaderFiltering: true,
                    allowSorting: true,
                },
                {
                    dataField: 'CategoryName',
                    title: trans('Category'),
                    allowHeaderFiltering: true,
                    allowSorting: true,
                },
                {
                    dataField: 'TournamentName',
                    title: trans('Tournament'),
                    allowHeaderFiltering: true,
                    allowSorting: true,
                },
                {
                    dataField: 'homeNm',
                    title: trans('Home team'),
                    allowHeaderFiltering: true,
                    allowSorting: true,
                },
                {
                    dataField: 'awayNm',
                    title: trans('Away team'),
                    allowHeaderFiltering: true,
                    allowSorting: true,
                },
            ],
            noPagination: true,
            id: 'live-calendar-table',
        },
        desktopGridProps: {
            noDataText: trans('CALENDAR_NO_DATA_TEXT'),
            paging: {
                pageSize: 100,
                enabled: true,
            },
            scrolling: {
                mode: 'infinite',
            },
            remoteOperations: true,
            searchPanel: {
                visible: true,
                width: 150,
                placeholder: trans('Search'),
            },
            sorting: {
                mode: 'multiple',
            },
            headerFilter: {
                visible: true,
            },
            errorRowEnabled: false,
            onEditorPreparing,
        },
        mobile: {
            columns: [
                {
                    key: 'MatchDateOnly',
                    allowSorting: true,
                    sortOrder: 'asc',
                    render: (options) => (
                        <p className="live-calendar__item-row">
                            <i className="doruk-time live-calendar__item-icon" />
                            <span className="live-calendar__item-text"
                                  dangerouslySetInnerHTML={{
                                      __html: searchHighliter(
                                          options.data.MatchDateOnly + ' ' + options.data?.MatchTime?.slice(0, -3)
                                      )
                                  }}
                            />
                        </p>
                    ),
                },
                {
                    dataField: 'MatchTime',
                    sortOrder: 'asc',
                    visible: false,
                },
                {
                    key: 'SportName',
                    render: (options) => (
                        <p className="live-calendar__item-row live-calendar__sport-row">
                            <i className={`sport_icon_font-${options.data.SportID} live-calendar__item-icon`} />
                            <span className="live-calendar__item-text"
                                  dangerouslySetInnerHTML={{
                                      __html: searchHighliter(options.data.SportName)
                                  }}
                            />
                        </p>
                    ),
                },
                {
                    key: 'CategoryName',
                    render: (options) => (
                        <p className="live-calendar__item-row">
                            <i className="doruk-category live-calendar__item-icon" />
                            <span className="live-calendar__item-text"
                                  dangerouslySetInnerHTML={{
                                      __html: searchHighliter(options.data.CategoryName)
                                  }}
                            />
                        </p>
                    ),
                },
                {
                    key: 'TournamentName',
                    render: (options) => (
                        <p className="live-calendar__item-row">
                            <i className="doruk-tournament live-calendar__item-icon" />
                            <span className="live-calendar__item-text"
                                  dangerouslySetInnerHTML={{
                                      __html: searchHighliter(options.data.TournamentName)
                                  }}
                            />
                        </p>
                    ),
                },
                {
                    dataField: 'homeNm',
                    render: (options) => (
                        <p className="live-calendar__item-row">
                            <i className="doruk-away live-calendar__item-icon" />
                            <span className="live-calendar__item-text"
                                  dangerouslySetInnerHTML={{
                                      __html: searchHighliter(options.data.homeNm)
                                  }}
                            />
                        </p>
                    ),
                },
                {
                    dataField: 'awayNm',
                    render: (options) => (
                        <p className="live-calendar__item-row">
                            <i className="doruk-away live-calendar__item-icon" />
                            <span className="live-calendar__item-text"
                                  dangerouslySetInnerHTML={{
                                      __html: searchHighliter(options.data.awayNm)
                                  }}
                            />
                        </p>
                    ),
                },
            ],
            noPagination: true,
            id: 'live-calendar-table',
        },
        mobileGridProps: {
            noDataText: trans('CALENDAR_NO_DATA_TEXT'),
            paging: {
                pageSize: 30,
                enabled: true,
            },
            scrolling: {
                mode: 'infinite',
            },
            remoteOperations: {
                paging: true,
                filtering: true,
                sorting: true,
            },
            columnHidingEnabled: false,
            showColumnHeaders: false,
            errorRowEnabled: false,
        },
        remoteUrl: true
    };

    return (
        <div className="live-calendar__data">
            {liveCalendarError ? (
                <div className="live-calendar__preloader-wrapper">
                    <LLErrorVisualizer error="LIVE_CALENDAR_ERROR" />
                </div>
            ) : (
                <LLScrollBox
                    customClasses="dx-swatch-dev-extreme-table-dark-custom-scheme"
                    id="live-calendar-scroll"
                >
                    <LLTable customConfig={liveCalendarTableConfig} ds={dataSource} />
                </LLScrollBox>
            )}
        </div>
    );
};

export default inject('store')(observer(LiveCalendarData));
