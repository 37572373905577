import React from 'react';

import { withCommon } from '../../../../components';
import {API_PREFIX} from "../../../../common/api/api-url-config";
import apiUrls from "../../../../common/api/api-urls";

const Twain = withCommon({
    store: ({ store }) => ({
        viewSize: store.site.status.viewSize,
    }),
    defaultName: 'BetOnGamesPage',
    isObserver: true,
})(({ viewSize }) => {

    return (
        <div className={`ll-page bet-games--${viewSize}`}>
            <iframe
                className={`bet-games-iframe--${viewSize}`}
                title="game"
                src={`${API_PREFIX}${apiUrls.games.openGame}?providerId=509`}
                frameBorder="0"
                allowFullScreen
            />
        </div>
    );
});

export default Twain;