import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import cx from "classnames";

import {GameImgLoader} from "../img-loader";
import {LLBtn2, WithPopover, GameButtonBuilder, BattleCounter} from "../../../../components";
import {formatNumber, trans} from "../../../../common/utils";
import {SlotBattlesPrivatePassForm} from '../../components';

import NoBattleImg from "../../../../static/themes/theme/images/slots-and-casino/no-chosen-game.png";


const BattleCard = ({
                        store,
                        game,
                        setGame,
                        withoutButton = false
                    }) => {

    const
        slotsBattles = store.slotsBattles,
        activeBattle = store.slotsBattles.activeBattle,
        username = store.user.username,
        language = store.user.language,
        currency = store.user.currency,
        userBattleId = store.user.userBattleId,
        isLogged = store.user.isLogged,
        openLogin = store.user.openLogin,
        canPlaySlotBattles = store.user.canPlaySlotBattles,
        timeToStart = store.site.slotBattlesConfig.timeToStart,
        viewSize = store.site.status.viewSize;

    const [loading, setLoading] = useState(false);
    const [passForm, setPassForm] = useState(false);

    const CardButton = () => (game && !withoutButton) ?
        (game.room_status === 'started') ?
            (userBattleId === activeBattle.RoomUUID && !activeBattle?.players.find(player => player.Customer === username && player.IsPlayed))
                ?
                <GameButtonBuilder
                    item={{
                        providerId: game.ProviderID,
                        iD: game.GameID
                    }}
                    mode={0}
                    customClasses="battle-info-card__content-btn"
                >
                    <LLBtn2
                        customClasses={"join-battle-btn slots-battles__btn--accent"}
                        onClick={() => {
                            activeBattle.setHideBattleCounter(true);
                        }}
                    >
                        {trans('SLOT_BATTLE__PLAY_BUTTON')}
                    </LLBtn2>
                </GameButtonBuilder>
                : null
            :
            (game.room_status === 'available' && activeBattle?.players && !activeBattle.players.find(player => player.Customer === username)) ? (
                <LLBtn2
                    customClasses={"join-battle-btn slots-battles__btn--accent"}
                    disabled={!!userBattleId || loading || !canPlaySlotBattles}
                    onClick={() => {
                        if (isLogged) {
                            if (activeBattle.IsPrivate) {
                                setPassForm(true);
                            } else {
                                setLoading(true);
                                try {
                                    slotsBattles.joinBattle({roomId: game.RoomUUID}).then(() => setLoading(false));
                                } catch {
                                    setLoading(false)
                                }
                            }
                        } else {
                            openLogin();
                        }
                    }}
                    isLoading={loading}
                    title={!canPlaySlotBattles ? trans('SLOT_BATTLE__USER_CANT_PLAY') : userBattleId ? trans('SLOT_BATTLE__ALREADY_IN_BATTLE') : ''}
                >
                    {trans("SLOT_BATTLE__JOIN")}
                </LLBtn2>
            ) : null
        : null;

    return (
        <div className={`battle-info-card battle-info-card--${viewSize}`}>
            <div className="battle-info-card__wrp">
                <div className="battle-info-card__image">
                    {game ?
                        <>
                            {game?.IsPrivate ?
                                <i className={`llg-lock slots-battles-lock slots-battles-lock--${viewSize}`}/>
                                : null
                            }
                            <GameImgLoader item={game}/>
                        </>
                        :
                        <div className="game-img">
                            <div className={cx("game-img__wrp")}>
                                <img
                                    className="game-img__logo"
                                    src={NoBattleImg}
                                    alt={"Not chosen game"}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className={"battle-info-card__content"}>
                    <div className={"battle-info-card__content-item battle-info-card__content-header"}>
                        <WithPopover>
                            {game?.GameName !== undefined && game?.GameName !== null ?
                                <span className="battle-info-card__content-item-name">{game.GameName}</span>
                                :
                                <span>{trans("SLOT_BATTLE__GAME_NAME")}</span>
                            }
                        </WithPopover>
                        {((!activeBattle && game?.GameName) ||
                            (activeBattle && activeBattle.room_status === 'available' &&
                                userBattleId === activeBattle.RoomUUID)) ?
                            <LLBtn2
                                onClick={() => {
                                    if (activeBattle) {
                                        setLoading(true);
                                        try {
                                            slotsBattles.leaveBattle(activeBattle.RoomUUID).then(() => setLoading(false))
                                        } catch {
                                            setLoading(false)
                                        }
                                    } else {
                                        setGame(null)
                                    }
                                }}
                                customClasses="battle-info-card__del-btn"
                                title={trans("SLOT_BATTLE__LEAVE")}
                                isLoading={loading}
                            >
                                <i className="doruk-close"/>
                            </LLBtn2>
                            : null
                        }
                    </div>
                    {activeBattle?.room_status !== "completed" &&
                        <div className={"battle-info-card__content-item battle-info-card__content-message"}>
                            {activeBattle?.room_status ?
                                <div className="battle-info-card__timer">
                                    {(['started', 'available'].includes(game?.room_status)
                                        && !activeBattle?.hideBattleCounter) ?
                                        <BattleCounter
                                            battle={activeBattle}
                                            startTime={activeBattle?.sdt ?? null}
                                            insertTime={activeBattle?.idt}
                                        />
                                        : activeBattle?.prizeCollectingTimer ?
                                            <p>{trans('SLOT_BATTLE__RESULTS_TIMER').replace('{{TIMER}}', activeBattle?.prizeCollectingTimer)}</p> : ''
                                    }
                                </div>
                                : <div className="battle-info-card__not-real-timer">
                                    {Math.trunc((timeToStart ?? 300) / 60).toString().padStart(2, '0') + ":" + ((timeToStart ?? 300) % 60).toString().padStart(2, '0')}
                                </div>
                            }
                        </div>}
                    <div className="battle-info-card__content-items">
                        <div className={"battle-info-card__content-item"}>
                            <div>{trans("SLOT_BATTLE__BET")}</div>
                            <div>{formatNumber(+game?.Bet || 0, 2, language, currency)}</div>
                        </div>
                        <div className={"battle-info-card__content-item"}>
                            <div>{trans("SLOT_BATTLE__PLAYERS")}</div>
                            <div>{`${game?.ConnectedPlayersQty || 0}/${game?.PlayersQty || 0}`}</div>
                        </div>
                        <div className={"battle-info-card__content-item"}>
                            <div>{trans("SLOT_BATTLE__OWNER")}</div>
                            <div>{
                                game?.owner || username
                            }</div>
                        </div>
                    </div>
                    {viewSize === "desktop" ? <CardButton /> : null}
                </div>
                {passForm ?
                    <SlotBattlesPrivatePassForm
                        setLoading={setLoading}
                        setPassForm={setPassForm}
                        joinBattle={slotsBattles.joinBattle}
                        roomId={game?.RoomUUID}
                    />
                    : null}
            </div>
            {viewSize !== "desktop" ? <CardButton /> : null}
        </div>
    );
};

export default inject('store')(observer(BattleCard));
