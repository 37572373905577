import React from 'react';
import PropTypes from 'prop-types';
import {
    Validator,
    RequiredRule,
    StringLengthRule,
    PatternRule,
    CustomRule,
    EmailRule,
    CompareRule,
} from 'devextreme-react/validator';

import {LLInput, withCommon} from '../../../index';
import {passwordStrength} from '../../../../common/utils';

/**
 * @file Represents first part of SignUp form;
 * @memberOf SignUpDesktop, SignUpMobile
 *
 * @param {func} clearServerError - clear errors in state
 * @param {object} form - data from all fields in form
 * @param {boolean} mobileUserAgent - detect mobile version
 * @param {object} serverResponseErrors - list of errors from server if server send errors
 * @param {func} serverValidationCheck - custom validation in brouser
 * @param {func} setForm - save data from fields to state
 * @param {string} username - username data
 * @param {string} email - email data
 * @param {string} emailRepeat - repeat email data
 * @param {string} password - password data
 *
 * @author A.Green
 */

const FormFirstPart = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
    }),
    PropTypes: {
        clearServerError: PropTypes.func.isRequired,
        form: PropTypes.shape({
            username: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            emailRepeat: PropTypes.string.isRequired,
            password: PropTypes.string.isRequired,
        }),
        serverResponseErrors: PropTypes.object.isRequired,
        serverValidationCheck: PropTypes.func.isRequired,
        setForm: PropTypes.func.isRequired,
    },
    isTrans: true,
})(
    ({
         clearServerError,
         form,
         form: {username, email, emailRepeat, password, passwordRepeat, pwdStrength},
         serverResponseErrors,
         serverValidationCheck,
         setForm,
         trans,
         pwdStrengthClass,
     }) => {
        const commonInputProps = {
            labelPosition: 'inside',
            showClearButton: true,
            stylingMode: 'outlined',
        };
        if (password.length > 0) {
            if (pwdStrength) {
                switch (pwdStrength) {
                    case 100:
                        pwdStrengthClass = 'perfect';
                        break;
                    default:
                        pwdStrengthClass = 'weak';
                }
            }
        }

        return (

            <div className="signup__part-one">
                <LLInput
                    {...commonInputProps}
                    label={trans('Username')}
                    id="sign-up__username"
                    name="sign-up__username"
                    maxLength={17}
                    value={username}
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            username: e.value,
                        });
                        clearServerError('username');
                    }}
                >
                    <Validator>
                        <PatternRule
                            message={trans(
                                'Username can contain only Latin letters, numbers and underscores'
                            )}
                            pattern={'^[a-zA-Z0-9_]+$'}
                        />
                        <RequiredRule message={trans('USERNAME_EMPTY')}/>
                        <StringLengthRule
                            message={trans(
                                'Username must be at least 3 characters and maximum 17 characters'
                            )}
                            min={3}
                            max={17}
                        />
                        <CustomRule
                            message={trans(serverResponseErrors['username'])}
                            validationCallback={(e) =>
                                serverValidationCheck(e, 'username')
                            }
                        />
                    </Validator>
                </LLInput>
                <LLInput
                    {...commonInputProps}
                    label={trans('E-Mail')}
                    maxLength={100}
                    value={email}
                    mode="email"
                    id="sign-up__email"
                    name="sign-up__email"
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            email: e.value,
                        });
                        clearServerError('email');
                    }}
                >
                    <Validator>
                        <RequiredRule message={trans('EMAIL_EMPTY')}/>
                        <EmailRule
                            message={trans('Please enter a valid e-mail address')}
                        />
                        <CustomRule
                            message={trans(serverResponseErrors['email'])}
                            validationCallback={(e) => serverValidationCheck(e, 'email')}
                        />
                    </Validator>
                </LLInput>
                <LLInput
                    {...commonInputProps}
                    label={trans('E-mail repeat')}
                    maxLength={100}
                    value={emailRepeat}
                    id="sign-up__repeat-email"
                    name="sign-up__repeat-email"
                    customClasses="sign-up__repeat-email"
                    mode="email"
                    onPaste={(e) => e.event.preventDefault()}
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            emailRepeat: e.value,
                        });
                    }}
                >
                    <Validator>
                        <RequiredRule message={trans('REPEAT_EMAIL_EMPTY')}/>
                        <CompareRule
                            message={trans("EMAIL_NOT_MATCH")}
                            comparisonTarget={() => email}
                        />
                    </Validator>
                </LLInput>
                <LLInput
                    {...commonInputProps}
                    label={trans('Password')}
                    maxLength={100}
                    value={password}
                    // adormentAfter={(
                    //     <div className={`ll-input__pwdStrength${' '+pwdStrengthClass}`}>{trans(pwdStrengthClass)}</div>
                    // )}
                    mode="password"
                    id="sign-up__password"
                    name="sign-up__password"
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            password: e.value,
                            pwdStrength: passwordStrength(e.value),
                        });
                        clearServerError('password');
                    }}

                >
                    <Validator>
                        <PatternRule
                            message={trans(
                                'You have entered incorrect character in password'
                            )}
                            pattern={
                                '^[a-zA-ZğüşöçİĞÜŞÖÇ0-9~!@#$%^*)(?_\\-\\\\.,\';<>:"/]*$'
                            }
                        />
                        <RequiredRule message={trans('PASSWORD_EMPTY')}/>
                        <CustomRule
                            message={trans(
                                'At least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters required'
                            )}
                            validationCallback={(e) => passwordStrength(e.value) === 100}
                        />
                        <CustomRule
                            message={trans(serverResponseErrors['password'])}
                            validationCallback={(e) =>
                                serverValidationCheck(e, 'password')
                            }
                        />
                    </Validator>

                </LLInput>
                <LLInput
                    {...commonInputProps}
                    label={trans('Password Repeat')}
                    maxLength={100}
                    value={passwordRepeat}
                    onPaste={(e) => e.event.preventDefault()}
                    mode="password"
                    id="sign-up__repeat-password"
                    name="sign-up__repeat-password"
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            passwordRepeat: e.value,
                        });
                    }}
                >
                    <Validator>
                        <RequiredRule message={trans('REPEAT_PASSWORD_EMPTY')}/>
                        <CompareRule
                            message={trans("PASSWORD_NOT_MATCH")}
                            comparisonTarget={() => password}
                        />
                    </Validator>

                </LLInput>
            </div>
        );
    }
);

export default FormFirstPart;
