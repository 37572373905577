import React from 'react';
import PropTypes from 'prop-types';

import {LLBtn2, withCommon} from '../../../components';
import config from "../../../static/themes/config";

/**
 * @file Represents React Component to render the modal of bonus description
 * @memberOf LLModal
 *
 * @param {object} modalContent - selected bonus
 * @param {'descr'|'rules'} modalType - identify what kind of modal react should render
 * @param {func} closeModal - close modal on 'cancel' button click
 * @param {func} sendPromo - submit post request to get a bonus
 *
 * @author I.Mykhailychenko
 */

const PromoModal = withCommon({
    store: ({store}) => ({
        modalContent: store.promos.modalContent,
        sendPromo: store.promos.sendPromo,
        modalType: store.promos.modalType,
        closeModal: store.modal.close,
        siteName: store.site.siteName,
        sId: store.user.sId,
        openLogin: store.user.openLogin,
        isLogged: store.user.isLogged,
    }),
    propTypes: {
        modalType: PropTypes.oneOf(['descr', 'rules']).isRequired,
        modalContent: PropTypes.shape({
            descr: PropTypes.string,
            shortDescr: PropTypes.string,
            icon: PropTypes.string,
            id: PropTypes.number.isRequired,
            idt: PropTypes.string.isRequired,
            imgDesktop: PropTypes.string.isRequired,
            imgMobile: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            ord: PropTypes.number,
            rules: PropTypes.string,
        }),
        sendPromo: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    },
    defaultProps: {
        modalContent: {},
    },
    defaultName: 'PromoModal',
    isTrans: true,
    isObserver: true,
})(({trans, sId, openLogin, isLogged, siteName, closeModal, modalContent = {}, modalType, sendPromo}) => {
    const handleSumbit = () => {
        sendPromo(modalContent.id);
    };

    const bonusesByLink = config(siteName).siteConfig.bonusesByLink + sId;

    return (
        <div className="promo-modal">
            {modalContent[modalType] ? (
                <div
                    className="promo-modal__descr"
                    dangerouslySetInnerHTML={{__html: modalContent[modalType]}}
                />
            ) : null}

            <div className="promo-modal__btn-wrp">
                {modalContent?.isRequestEnabled ? <LLBtn2 className="promo-modal__btn--secondary" onClick={closeModal}>
                        {trans('Cancel')}
                    </LLBtn2>
                    : null
                }
                {config(siteName).siteConfig.bonusesByLink ?
                    <LLBtn2 className="promo-modal__btn--primary"
                            onClick={(e) => {
                                if (!isLogged) {
                                    e.preventDefault();
                                    closeModal();
                                    openLogin();
                                }
                            }}
                    >
                        <a href={bonusesByLink} target='_blank' rel='noopener noreferrer'>
                            {trans('Get Bonus')}
                        </a>
                    </LLBtn2>
                    : modalContent?.isRequestEnabled ?
                        <LLBtn2 className="promo-modal__btn--primary" onClick={handleSumbit}>
                            {trans('Get Bonus')}
                        </LLBtn2>
                        : null
                }
            </div>
        </div>
    );
});

export default PromoModal;
