import { values } from 'mobx';
import { types, flow, getParent } from 'mobx-state-tree';
import orderBy from 'lodash/orderBy';

import api from '../../../../common/api';
import { llattempt } from '../../../../common/utils';
import { llNotifier } from '../../../../components';


const AccountHistoryItem = types.model('CasinoReportItem', {
    amount: types.number,
    balance: types.number,
    tableType: types.string,
    dateTime: types.Date,
    note: types.maybeNull(types.string),
});

const AccountHistory = types
    .model('AccountHistory', {
        accountHistory: types.array(AccountHistoryItem),
    })
    .actions((self) => ({
        _set(data) {
            self.accountHistory = [];

            orderBy(data, ['TransactionDateTime'], ['desc']).map((report) => {
                self.accountHistory.push(
                    AccountHistoryItem.create({
                        amount: report.Amount,
                        balance: report.Calculated,
                        tableType: report.TableType,
                        dateTime: new Date(report.TransactionDateTime.replace(' ', 'T') + '+03:00'),
                        note: report.TransactionType,
                    })
                );
            });
            self.fetchTime = Date.now();
            self.isTableLoading = false;
        },
        getAccountHistory: flow(function* fetch() {
            if (self.checkFetchTime()) {
                self.isTableLoading = true;
                const data = {
                    id: getParent(self).customerId,
                };
                yield llattempt(
                    () =>
                        api.user.getAccountHistory(data).then((response) => {
                            if (response.success) {
                                self._set(response.data);
                            } else {
                                self.resetFetchTimeAndLoading();
                                // Thing below needed to avoid double error notifier on logout
                                if (response.data?.error === 'AUTH_REQUIRED') {
                                    return true;
                                } else {
                                    llNotifier({
                                        message: response.data?.error ?? 'GENERAL_ERROR',
                                        type: 'error',
                                    });
                                }
                            }
                        }),
                    {
                        msg: 'GENERAL_ERROR',
                        at: 'user.getAccountHistory',
                        withParams: data,
                        onError: () => {
                            self.resetFetchTimeAndLoading();
                        },
                    }
                );
            }
        }),
    }))
    .views((self) => ({
        get reports() {
            return values(self.accountHistory);
        },
    }));

export default AccountHistory;
