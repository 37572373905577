import React, {useEffect, useRef, useState} from "react";
import {inject, observer} from "mobx-react";

import trans from "../../../common/utils/trans";


const BattleCounter = ({
                           store,
                           battle,
                           startTime,
                           insertTime,
                           isPopup = false,
                       }) => {
    const isSiteActive = store.site.status.isActive;
    const timeToStart = store.site.slotBattlesConfig.timeToStart;
    const timeToPlay = store.site.slotBattlesConfig.timeToPlay;
    const timeToWait = store.site.slotBattlesConfig.timeToWait;
    const whatWasChanged = store.user.battlePopup.whatWasChanged;
    const [time, setTime] = useState(0);
    const [timerId, _setTimerId] = useState('');

    const timerIdRef = useRef(timerId);

    const setTimerId = (id) => {
        timerIdRef.current = id;
        _setTimerId(id);
    }

    const tick = (time) => {
        if (time > 0) {
            setTime(time - 1);
            window[timerIdRef.current] = setTimeout(() => tick(time - 1), 1000);
        }
    };

    useEffect(() => {
        clearTimeout(window[timerIdRef.current]);
        if (battle && isSiteActive) {
            setTimerId('__TIMER_' + battle.RoomUUID + (isPopup ? '_popup' : ''));
            let timer;
            if (battle.room_status === 'started') {
                let waitForOthers = 0;
                if (whatWasChanged === 'userIsPlayed') {
                    waitForOthers = timeToWait * 1000;
                }
                timer = new Date(startTime + '+03:00').getTime() - Date.now() + ((timeToPlay ? (timeToPlay * 1000) : (1000 * 60 * 1.5)) + waitForOthers);
            } else {
                timer = new Date(insertTime + '+03:00').getTime() - Date.now() + (timeToStart ? (timeToStart * 1000) : (1000 * 60 * 5));
            }
            if (timer > 0 && battle.room_status !== 'completed') {
                let date = new Date(timer);
                setTime(date.getMinutes() * 60 + date.getSeconds());
                window[timerIdRef.current] = setTimeout(() => tick(date.getMinutes() * 60 + date.getSeconds()), 1000);
            }
        } else {
            setTime(0);
        }
    }, [startTime, isSiteActive, whatWasChanged]);

    useEffect(() => () => {
        clearTimeout(window[timerIdRef.current])
    }, []);

    if (battle && isSiteActive) {
        if (time > 0) {
            if (battle.PlayersQty === battle.players?.length && battle.room_status !== 'started') {
                return trans('SLOT_BATTLE__WILL_START_IN_A_MOMENT');
            } else {
                return <span dangerouslySetInnerHTML={{__html:
                        (startTime ?
                                whatWasChanged === 'userIsPlayed' ?
                                    trans('SLOT_BATTLE__YOUR_GAME_ENDED_WAIT_FOR_OTHERS')
                                    :
                                    whatWasChanged === "invitation" ? trans("SLOT_BATTLE__LEFT_TIME_TO_PLAY_INVITATION") : trans('SLOT_BATTLE__LEFT_TIME_TO_PLAY')
                                :
                                whatWasChanged === "invitation" ? trans("SLOT_BATTLE__LEFT_TIME_TO_PLAY_INVITATION") : trans('SLOT_BATTLE__WAITING_FOR_PLAYERS')
                        ).replace('{{TIMER}}', '<span class="ll-popup__timer-count">' + Math.trunc(time / 60).toString().padStart(2, '0') + ":" + (time % 60).toString().padStart(2, '0') + '</span>')
                }} />;
            }
        } else {
            if (battle.room_status === 'started') {
                if (battle.players && battle.players.find(player => !!player.IsPlayed)) {
                    return trans('SLOT_BATTLE__WAITING_FOR_RESULTS');
                } else {
                    return '';
                }
            }
            if (battle.room_status === 'completed') {
                return '';
            }
            if (whatWasChanged === "invitation") {
                return trans("SLOT_BATTLE__LEFT_TIME_TO_PLAY_INVITATION").replace('{{TIMER}}', Math.trunc(time / 60).toString().padStart(2, '0') + ":" + (time % 60).toString().padStart(2, '0'));
            }
            return trans('SLOT_BATTLE__WILL_START_IN_A_MOMENT')
        }
    } else {
        return '';
    }
};

export default inject('store')(observer(BattleCounter));
