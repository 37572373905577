import {types, getRoot} from 'mobx-state-tree';

import setWidth from '../../../common/utils/set-width';


const SiteStatuses = types
    .model('SiteStatuses', {
        isGlobalError: types.maybeNull(types.boolean),
        globalErrorText: types.maybeNull(types.string),
        currentWidth: types.string,
        currentScrollPosition: types.maybeNull(types.number),
        isScrollToTop: types.maybeNull(types.boolean),
        isLandscapeOrientation: false,
        isBackdropShown: false,
        isBackDropFullScreen: false,
        isBackDropClosable: true,
        isBackDropTransparent: true,
        isActive: true,
    })
    .volatile((s) => ({
        backdropOnClick: () => {},
    }))
    .actions((self) => {
        return {
            setCurrentWidth(width) {
                let newWidth = setWidth(width);
                if (self.currentWidth !== newWidth) {
                    self.currentWidth = newWidth;
                    if (!getRoot(self).user.mobileUserAgent && (newWidth !== 'desktop')) {
                        document.body.classList.add('body--small-desktop');
                    } else {
                        document.body.classList.remove('body--small-desktop');
                    }
                }
            },
            setCurrentScrollPosition(position) {
                self.currentScrollPosition = position;
            },
            setGlobalError(status, errorText) {
                self.isGlobalError = status;
                self.globalErrorText = (status && errorText) ? errorText : null;
            },
            scrollToTop() {
                self.isScrollToTop = !self.isScrollToTop;
            },
            setLandscapeOrientation(flag) {
                self.isLandscapeOrientation = flag;
            },
            setBackDropFullScreen(flag) {
                self.isBackDropFullScreen = flag;
            },
            setBackdropShown(flag) {
                self.isBackdropShown = flag;
                if(!flag) self.isBackDropClosable = true;
            },
            setBackdropTransparent(flag) {
                self.isBackDropTransparent = flag;
            },
            setBackDropClosable(flag) {
                self.isBackDropClosable = flag;
            },
            setBackdropOnClick(func) {
                self.backdropOnClick = func;
            },
            setBackdrop({
                backdropOnClick = () => {},
                backDropFullScreen = true,
                backdropShown = true,
                transparent = false,
            }) {
                self.backdropOnClick = backdropOnClick;
                self.isBackDropFullScreen = backDropFullScreen;
                self.isBackDropTransparent = transparent;
                self.setBackdropShown(backdropShown);
            },
            checkPageActivity() {
                self.isActive = !(document.visibilityState === 'hidden');
            },
        };
    })
    .views((self) => {
        return {
            get viewSize() {
                const mobileUserAgent = getRoot(self).user.mobileUserAgent;
                return mobileUserAgent && self.currentWidth === 'desktop'
                    ? 'tablet'
                    :
                    self.currentWidth === 'small-mobile' ?
                        'mobile'
                        :
                        self.currentWidth;
            },
        };
    });

export default SiteStatuses;
