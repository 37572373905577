import * as signalR from '@microsoft/signalr';
import {MessagePackHubProtocol} from '@microsoft/signalr-protocol-msgpack';
import urls from "./urls";


class CustomerNotificationAPI {
    connect = async (sid, onClose = () => {}, onError = () => {}) => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(urls.customerNotification + sid, {
                skipNegotiation: true,   // !!! do not change !!!
                transport: signalR.HttpTransportType.WebSockets   // !!! do not change !!!
            })
            .withAutomaticReconnect()
            .withHubProtocol(new MessagePackHubProtocol())
            .configureLogging(signalR.LogLevel.None)
            .build();

        async function start() {
            try {
                await connection.start();
            } catch (err) {
                onError(err);
            }
        }

        await start();
        connection.onclose((err) => {
            onClose();
            if (err) {
                onError(err);
            }
        });
        return connection;
    };

    disconnect = (connection) => {
        connection.stop();
        return connection;
    };
}

const webSocketApi = new CustomerNotificationAPI();

export default webSocketApi;
