import React from "react";
import cx from "classnames";

import {LLElementTypeSetter, WithPopover} from "../../../HOCs";
import {getS3ImageUrl, trans} from "../../../../common/utils";
import urls from "../../../../configs/urls";


const WidgetGame = (props) => {

    const {
        slide,
        language,
        elementAttributes,
        isLogged,
        mobileUserAgent,
        openLogin,
    } = props;

    const {
        promoTitle,
        promoText,
        defaultLang,
        gameImg,
        gameId,
        thumbnail,
    } = slide.settings

    return (
        <div className="ll-widget-game">
            <div className="ll-widget-game__left-part">
                <div className="ll-widget-game__left-part--text">
                    <div className="ll-widget-game__left-part--promo-title">
                        <WithPopover customClasses={cx("ll-widget-game-left-part--title")}>
                                    <span>
                                        {promoTitle[language] || promoTitle[defaultLang]}
                                    </span>
                        </WithPopover>
                    </div>
                    <div className="ll-widget-game__left-part--promo-text">
                        <WithPopover customClasses={cx("ll-widget-game-left-part--text")}>
                                    <span>
                                        {promoText[language] || promoText[defaultLang]}
                                    </span>
                        </WithPopover>
                    </div>
                </div>
                <div className="ll-widget-game__left-part--button">
                    <LLElementTypeSetter
                        className="ll-widget-game-button"
                        data-interact-target={gameId}
                        {...elementAttributes}
                        onClick={(e) => {
                            if (!isLogged && mobileUserAgent) {
                                e.preventDefault();
                                openLogin();
                            }
                        }}
                    >
                        {trans("WIDGET_GAME__PLAY_BUTTON")}
                    </LLElementTypeSetter>
                </div>
            </div>
            <div className="ll-widget-game__right-part">
                <LLElementTypeSetter
                    data-interact-target={gameId}
                    {...elementAttributes}
                    onClick={(e) => {
                        if (!isLogged && mobileUserAgent) {
                            e.preventDefault();
                            openLogin();
                        }
                    }}
                >
                    {slide.settings.withNewThumbnail ? <img className="ll-widget-game__right-part--image-new"
                                                            src={`${getS3ImageUrl()}/images/thumbnails/${thumbnail}`}
                                                            alt=""/> :
                        <img className="ll-widget-game__right-part--image"
                             src={gameImg}
                             alt=""/>
                    }
                </LLElementTypeSetter>
            </div>
        </div>
    )

}

export default WidgetGame;
