import { API_PREFIX } from '../api/api-url-config';
import apiUrls from '../api/api-urls';


export const getGameElemAttributes = (
    {
        mobileUserAgent,
        providerId,
        gameId,
        mode,
        height,
        width,
    }
) => (
    mobileUserAgent ? {
        href: `${API_PREFIX}${apiUrls.games.openGame}?providerId=${providerId}&gameId=${gameId}&demo=${mode}`,
        target: "_blank",
        rel: "noopener noreferrer",
    } : {
        to: `?launch=${mode}-${providerId}-${gameId}${height ? '-' + height : ''}${width ? '-' + width : ''}`,
    }
);