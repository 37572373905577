export const aa = {
    /* CASINO / SLOTS */
    FUN_PLAY_BTN: 'تجربة',
    REAL_PLAY_BTN: 'مراهنة',

    /* PROVIDERS (ist from prod )*/
    'KALAMBA': 'KALAMBA',
    'SPINOMENAL': 'SPINOMENAL',
    'YGGDRASIL': 'YGGDRASIL',
    'WAZDAN': 'WAZDAN',
    'BTG': 'BIGTIMEGAMING',
    'BOOONGO': 'BOOONGO',
    'REDTIGER': 'REDTIGER',
    'BLUEPRINT': 'BLUEPRINT',
    'TRUELAB': 'TRUELAB',
    'PUSH': 'PUSH GAMING',
    'QUICKSPIN': 'QUICKSPIN',
    'SOFTSWISS': 'BGAMING',
    'BOOMING': 'BOOMING GAMES',
    'TKICK': 'THUNDERKICK',
    'NOLCITY': 'NOLIMITCITY',
    'REDRAKE': 'REDRAKE',
    'PRAGMPLAY': 'PRAGMATIC PLAY',
    'SPRIBE': 'SPRIBE',
    'ENDORPHINA': 'ENDORPHINA',
    'MRSLOTTY': 'MR SLOTTY',
    'AMATIC': 'AMATIC',
    'BETSOFT': 'BETSOFT',
    'EGT': 'EGT',
    'HABANERO': 'HABANERO',
    'ISOFTBET': 'ISOFTBET',
    'NETENT': 'NETENT',
    'PARIPLAY': 'PARIPLAY',
    'PGSOFT': 'PGSOFT',
    'PLAYSON': 'PLAYSON',
    'RGAMING': 'RELAX GAMING',
    'WORLDMATCH': 'WORLDMATCH',
    '1X2LEAP': '1X2',
    'HACKSAW': 'HACKSAW',
    'PRAGMLIVE': 'PRAGMATIC',
    '1LIVE': '1LIVE',
    'EZUGI': 'EZUGI',
    'EVOLUTION': 'EVOLUTION',
    'VIVO': 'VIVO GAMING',
    'ATMOSFERA': 'ATMOSFERA',
    'LSTREAK': 'LSTREAK',

    /* SAVE COUPONS ERROR CODES */
    //usual
    SAVE_ERROR1: 'لا يمكن لمبلغ الرهان أن يكون صفر أو أقل',
    SAVE_ERROR11:
        'لقد وصلت للحد الأقصى اليومي من الكوبونات. يتم إعادة ضبط الحد اليومي كل يوم عند منتصف الليل',
    SAVE_ERROR15: 'المبارايات أدناه مغلقة أو قد بدأت: #1',
    SAVE_ERROR17: 'المباريات أدناه مغلقة أو توقفت: #1',
    'SAVE_ERROR2': 'يجب أن تختار رهاناً تراكمي واحد على الأقل',
    'SAVE_ERROR3': 'المدراء لا يمكنهم وضع كوبون',
    'SAVE_ERROR4': 'يجب أن تضيف #1 من الرهانات على الأقل في الكوبون',
    'SAVE_ERROR5': 'لا تملك حق إضافة كوبون تراكمي',
    'SAVE_ERROR6': 'لا تملك حق إضافة كوبون ما قبل المباراة',
    'SAVE_ERROR7': 'لا تملك حق إضافة رهان البطولة',
    'SAVE_ERROR8': 'لا تملك حق إضافة كوبون مباشر',
    'SAVE_ERROR9': 'الحد الأدنى لمبلغ الرهان يجب أن يكون #1',
    'SAVE_ERROR10': 'الحد الأقصى لمبلغ الرهان الممكن هو #1',
    'SAVE_ERROR12': 'رصيدك غير كاف',
    'SAVE_ERROR13': 'الحد الأقصى الممكن لرهان هذا الكوبون هو #1',
    'SAVE_ERROR14': 'حدود المراهنة للمباراة #1',
    'SAVE_ERROR14_1': 'تم تخطي حد الرهان لأحد أو عدة خيارات',
    'SAVE_ERROR14_2': '{E#2} هو {E#1} الحد المتبقي للرهان في',
    'SAVE_ERROR16': 'تغيرت معدلات الرهان أدناه #1',
    'SAVE_ERROR18': 'حدث خطأ في حفظ الكوبون يرجى المحاولة مجدداً',
    'SAVE_ERROR19': 'رهانات في كوبونك أغلقت أو توقفت',
    'SAVE_ERROR20': 'يرجى تحديث الكوبون',
    'SAVE_ERROR22': 'رهانات في كوبونك أغلقت أو توقفت #1',
    'SAVE_ERROR23': 'لا يمكن جمع هذا الخيار مع خيارات أخرى: #1',
    'SAVE_ERROR24': 'هذا الخيار ممكن جمعه فقط مع مباريات من نفس الدوري: #1',
    //mts
    MTS_PROXY_ERROR: 'خطأ في الاتصال, يرجى طلب المساعدة',
    MTS_DELETE_ERROR: 'خطأ في حذف الكوبون',
    MTS_SAVE_ERROR1: 'الرهانات أدناه أغلقت أو توقفت #1',
    MTS_SAVE_ERROR2: 'خيارات من المباريات أدناه أغلقت أو توقفت #1',
    MTS_SAVE_ERROR3: 'تغيرت معدلات الرهان أدناه #1',
    MTS_SAVE_ERROR4: 'هناك رهانات مرتبطة في القسيمة, يرجى التحقق منها و المحاولة مجدداً',
    MTS_SAVE_ERROR:
        'لم يتم قبول الكوبون. يرجى التواصل مع المساعدة المباشرة أو المحاولة مجدداً بعد ثواني. الرمز: #1',
    //custom
    ODDS_ERROR: 'النتيجة مغلقة أو معلقة',
    PRE_CLOSED: 'المباراة أنتهت أو تم إزالة الخيار',
    LIVE_CLOSED: 'المباراة أنتهت أو تم إزالة الخيار',
    OUTRIGHT_CLOSED: 'المباراة أنتهت أو تم إزالة الخيار',
    MAX_EVENTS_REACHED: 'لقد وصلت للحد الأقصى من المبارات في الكوبون',
    UNAVALABLE_FOR_COMBINE:
        ' المباراة التي تحاول إضافتها غير مناسبة للجمع مع رهانات الكوبون الحالي',
    UNAVALABLE_FOR_COMBINE2: 'لا يمكنك جمع رهانات البطولة مع رهانات أخرى',
    UNAVALABLE_FOR_COMBINE3: 'لا يمكن جمع الرهان المركب مع رهانات أخرى',
    EVENT_FINISHED: 'المباراة أنتهت أو تم إزالة الخيار',

    EVENT_NOT_FOUND: 'المباراة غير موجودة',
    EVENT_STARTED: 'المباراة بدأت أو غير متاحة',

    // betbuilder
    BET_BUILDER_UNAVAILABLE_MARKET: 'المباراة غير متاحة',
    BET_BUILDER_NO_DATA_FOR_EVENT: 'البيانات غير موجودة لهذه المباراة',
    'Request body missing or malformed': 'حدث خطأ غير معروف',
    'This combination is not possible, contradictory selections': 'هذه التركيبة غير ممكنة, خيارات متناقضة',

    /* DELETE COUPONS ERROR CODES */
    COUPONDELETE_ALREADY: 'تم مسح الكوبون',
    COUPON_ALREADY_CLOSED: 'تم رفض إلغاء الكوبون',
    COUPON_ALREADY_DELETED: 'تم مسح القسيمة',
    COUPONDELETE_PASSED: 'إلغاء القسيمة غير ممكن. أنتهت المدة',
    COUPONDELETE_LIVE: 'لا يمكنك حذف كوبون يحتوي على رهانات مباشرة',
    COUPONDELETE_STARTED: 'المباراة أدناه بدأت لا يمكنك حذف الكوبون #1',

    /* FORMS ERRORS */
    WRONG_TR_ID: 'يرجى التأكد من إدخال المعلومات الصحيحة',
    NAME_AND_SURNAME_ALREADY_REGISTERED: 'هذا الحساب لا يخصك, يرجى طلب المساعدة لديك حساب مسجل بالمعلومات المستخدمة. اذا كنت تعتقد أن',
    PARAM_NOT_SET:
        'لم تستلم بعض المعلومات المهمة من قبل المخدم, يرجى التأكد من المعلومات المدخلة',
    NAME_EMPTY: 'يرجى إدخال أسم المستخدم',
    SURNAME_EMPTY: 'يرجى إدخال النسبة',
    USERNAME_EMPTY: 'أدخل اسم المستخدم',
    EMAIL_EMPTY: 'أدخل البريد الالكتروني',
    REPEAT_EMAIL_EMPTY: 'فشل تكرار البريد الألكتروني',
    EMAIL_NOT_MATCH: 'عناوين البريد الإلكتروني غير متطابقة',
    PASSWORD_EMPTY: 'يرجى إدخال كلمة المرور',
    REPEAT_PASSWORD_EMPTY: 'كلمة المرور غير مطابقة',
    PASSWORD_SAME_AS_OLDPASSWORD: 'كلمة المرور الجديدة يجب ان تكون مختلفة عن كلمة السر الحالية',
    PASSWORD_NOT_MATCH: 'كلمة المرور غير مطابقة',
    SEX_EMPTY: 'أختر الجنس',
    ID_EMPTY: 'فشل رقم الهوية فارغ',
    'At least 8 characters': 'ثمانية رموز على الأقل',
    WRONG_DATE_FORMAT: 'تاريخ خاطئ',
    COUNTRY_EMPTY: 'أختر البلد',
    PHONE_EMPTY: 'يرجى إدخال رقم الهاتف',
    PHONE_TOO_SHORT: 'رقم الهاتف قصير جداً',
    PHONE_WRONG_FORMAT: 'رقم الهاتف خاطئ',
    USERNAME_TOO_SHORT: 'يجب أن يحتوي أسم المستخدم ثلاث رموز على الأقل',
    USERNAME_WRONG_FORMAT: 'يمكن لإسم المستخدم إحتواء أحرف لاتينية, أرقام و  خط سفلي',
    WRONG_EMAIL: 'بريد إلكتروني خاطئ',
    USERNAME_ALREADY_EXISTS: 'أسم المستخدم موجود',
    EMAIL_ALREADY_EXISTS: 'البريد الإلكتروني موجود',
    PHONE_ALREADY_EXISTS: 'رقم الهاتف موجود',
    CURRENCY_EMPTY: 'أختر العملة',
    ID_NUMBER_WRONG_FORMAT:
        'تتحقق خانة رقم المستخدم من الأسم النسبة و تاريخ الميلاد, يرجى التأكد من إدخال المعلومات الصحيحة ',
    ID_NUMBER_ALREADY_EXISTS: 'هناك عضوية موجودة لرقم الهوية المستخدم',
    PASSPORT_NUMBER_WRONG_FORMAT:
        'رقم جواز السفر خاطئ, اذا أدخلته صحيحاً و تلقيت هذا الخطاً يرجى طلب المساعدة',
    PASSPORT_NUMBER_EXISTS: 'جواز السفر مسجل من قبل',
    USERNAME_TOO_LONG: 'أسم المستخدم طويل جداً',
    NAME_WRONG_FORMAT: 'الأسم يحتوي رموز ممنوعة',
    SURNAME_WRONG_FORMAT: 'النسبة تحتوي رموز ممنوعة',
    CAPTCHA_ERROR: 'أدخل الرموز الظاهرة',
    PASSWORD_WRONG_FORMAT: 'كلمة السر غير قوية أو قصيرة جداً',
    WRONG_GOOGLE_CODE: 'رمز خاطئ',
    WRONG_CODE: 'رمز خاطئ',
    WRONG_REPEATED: 'كلمة السر غير مطابقة',
    WRONG_OLD_PASSWORD: 'كلمة السر القديمة خاطئة',
    NEW_PASSWORD_EQUALS_OLD: 'يجب أن تكون كلمة السر الجديدة مختلفة عن القديمة',
    WRONG_CAPTCHA: 'يرجى إدخال الرموز الظاهرة',
    EMAIL_NOT_FOUND: 'البريد الإلكتروني غير موجود',
    WRONG_AUTH_DATA: 'خطأ في أسم المستخدم و كلمة المرور',
    EMAIL_NOT_VERIFIED: 'يرجى إثبات البريد الإلكتروني',
    TOO_MANY_RETRIES:
        'لقد أغلق حسابك لمدة ۱٥ دقيقة بسبب ۳ محاولات فاشلة. اذا نسيت كلمة المرور يرجى إتباع تعليمات عملية إعادة ضبط كلمة المرور',
    FORM_REGISTRATION_REJECTED: 'تم رفض طلب تسجيلك. شكراً لأهتمامك بـ لاكي لاند غيمينغ ',
    FRAUD_DETECTED: 'تم رفض طلب تسجيلك. شكراً لأهتمامك بـ لاكي لاند غيمينغ ',
    LOGIN_MAILVERIFY: 'البريد الإلكتروني غير مثبت',
    AUTH_TIME_EXPIRED: 'أنتهى وقت التصديق',
    WRONG_SMS_CODE: 'رمز الرسالة خاطىء',
    PERMISSION_DENIED: 'فشل الدخول. تحقق من المعلومات',
    IP_IS_BLACKLISTED: 'حسابك مغلق لمدة ١٥ دقيقة. يرجى المحاولة في وقت لاحق',

    /* BONUS ERROR CODES */
    BONUS_NOT_FOUND: 'المكافأة التي طلبتها أنتهت مدتها أو حذفت من قبل الموقع',
    BONUS_USED: 'لقد قدمت طلباً لهذه المكافأة',

    /* OTHER ERRORS (PAGES ERRORS) */
    Error: 'خطأ',
    TECHNICAL_ISSUES_ERROR: 'صيانة غير مخططة. يرجى المحاولة لاحقا',
    CONNECTION_ISSUES_ERROR: 'هناك خطأ, لطفاً حاول لاحقاً',
    EMPTY_BETS_ERROR: 'لا مباريات مباشرة',
    EMPTY_LIVE_BETS_ERROR: 'لا مباريات مباشرة',
    ERROR_404: 'الصفحة غير موجودة',
    ERROR_404_HEAD: '404',
    NOT_FOUND_QRCODE: 'هناك خطأ, لطفاً حاول لاحقاً',
    GENERAL_ERROR: 'هناك خطأ, لطفاً حاول لاحقاً',
    MAINTENANCE_ERROR: 'يرجى المحاولة مجددأ لاحقاً\n\n' +
        'لتزويد خدمة أفضل لكم. الموقع تحت صيانة مخططة من قبل فريقنا\n\n' +
        'الوقت المتوقع لنهاية الصيانة هو الثامنة صباحاً\n\n' +
        'من الممكن أن تنتهي الصيانة قبل أو بعد الوقت المتوقع\n\n' +
        'شكراً لتفهمكمً',
    NOT_FOUND: 'هناك خطأ, لطفاً حاول لاحقاً',
    LIVE_CALENDAR_ERROR: 'خطأ في قائمة تقويم المباريات',
    USER_NOT_FOUND: 'خطأ في أسم المستخدم و كلمة المرور',
    WRONG_PASSWORD: 'خطأ في أسم المستخدم و كلمة المرور',
    WRONG_PASSWORD_QRCODE: 'خطأ في أسم المستخدم و كلمة المرور',
    SUSPENDED:
        'حسابك مغلق مؤقتاً. عند أنتهاء هذه العملية سيتم إعادة تفعيله. لمزيد من المعلومات يرجى الأتصال بالمساعدة المباشرة. شكراً لتفهمكم',
    LOGIN_NEEDED: 'يرجى تسجيل الدخول',

    /**** BETTING ****/
    MENU_ALL: 'الكل',
    MENU_OUTRIGHTS: 'رهانات البطولة',
    MENU_OUTRIGHTS_20040: 'معدلات رهانك',
    MENU_OUTRIGHTS_25500: 'كأس العالم المميزة',
    PRE_LEFTMENU_STATISTICS_BTN: 'الاحصائيات',
    PRE_LEFTMENU_LIVESCORES_BTN: 'النتائج المباشرة',
    PRE_TOP: 'الأحداث الرياضية الشعبية',
    PRE_FAVORITE: 'الأحداث الرياضية المفضلة',
    PRE_TITLE_SPORTS: 'الرياضة',
    /* SEARCH */
    SPORTS_SEARCH_NO_DATA_TEXT: 'لم تجد بيانات متعلقة بالبحث المطلوب',
    SPORTS_SEARCH_TECHNICAL_ISSUES_ERROR: 'هناك خطأ, لطفاً حاول لاحقاً',
    MATCH_TV_MODAL_HEADER: 'إذاعة',
    MATCH_STATISTICS_MODAL_HEADER: 'الاحصائيات',
    CALENDAR_NO_DATA_TEXT: 'البيانات غير موجودة',
    /*** --------   */

    /* LANGUAGE */
    LANG_EN: 'English',
    LANG_TR: 'Türkçe',
    LANG_AA: 'عربي',

    /* sites dependence translations */
    HOME_SLOTS_TITLE_CIPROBET: 'PRAGMATIC SLOT OYUNLARIMIZI DENEDİNİZ Mİ ?',
    HOME_SLOTS_SUBTITLE_CIPROBET:
        'Aztec Gems, Vegas Magic ve daha birçoğu kazandırmak için sizi bekliyor!',
    BRANCH_PICKER_TITLE_DESKTOP: 'أقسام الرياضة',
    BRANCH_PICKER_TITLE_MOBILE: 'مدى الوقت',
    USER_DISABLED: 'ليس لديك إذن دخول لهذد اللعبة',

    /* Rest of trans */
    '1x2': '1x2',
    '1 Hour': 'ساعة',
    '12 Hour': 'الساعة 12',
    '3 Hour': '٣ساعات',
    '6 Hour': '٦ساعات',
    'A - Z': 'A - Z',
    'About Us': 'عن الشركة',
    'Account report': 'تقرير الحساب',
    'account-history': 'تاريخ الحساب',
    'Account info': 'المعلومات االشخصية',
    'Account information': 'المعلومات االشخصية',
    Account: 'الحساب',
    'Account suspended': 'الحساب معلق',
    ACTIVE_BETS_NO_DATA_TEXT: 'لا يوجد رهانات مفتوحة',
    Address: 'العنوان',
    Affiliate: 'Affiliate',
    All: 'الكل',
    'All Markets': 'كل الرهانات',
    Amount: 'المبلغ',
    'At least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters required':
        'يجب إدخال حرف كبير, حرف صغير و ۸ رموز على الأقل',
    Available: 'متاح',
    Aviator: 'الطيار',
    'Away team': 'الضيف',
    Back: 'إلى الخلف',
    Badminton: 'تنس الريشة',
    Balance: 'الرصيد',
    Baseball: 'البيسبول',
    Basketball: 'ن.ب.أ الأفتراضي',
    'Bet Games': 'ألعاب بيت أون',
    'Bet on games': 'ألعاب بيت أون',
    'BET_DETAILS_NO_DATA': 'You have no details for this coupon',
    'BET_SLIP_EMPTY': 'اختر',
    'Bet slip': 'الكوبون',
    'Bet slips': 'الكوبونات',
    Bets: 'الكوبون',
    'betting-history': 'الكوبونات',
    Bettings: 'المراهنة',
    Bonus: 'المكافأة',
    'Bonus Talep': 'Bonus Talep',
    'Bonus points': 'نقاط المكافأت',
    'Bonus Rules': 'القواعد',
    'Bonus/discount': 'المكافأة',
    CABINET_WITHDRAW_STATUS_NO_DATA_TEXT: 'لا يوجد بيانات متاحة لهذه الطاولة',
    CABINET_BET_HISTORY_NO_DATA_TEXT: `لا يوجد بيانات متاحة لهذه الطاولة`,
    CABINET_ACCOUNT_HISTORY_NO_DATA_TEXT: `لا يوجد بيانات متاحة لهذه الطاولة`,
    CABINET_CASINO_REPORT_NO_DATA_TEXT: `لا يوجد بيانات متاحة لهذه الطاولة`,
    Calendar: 'تقويم المباريات المباشرة',
    Cancel: 'إلغاء',
    'casino-report': 'تقرير الكازينو',
    Casino: 'الكازينو',
    Casinos: 'الكازينو',
    Category: 'الفئة',
    'Change password': 'تغيير كلمة المرور',
    'Choose to bet': 'أختر أن تراهن',
    City: 'المدينة',
    'Clear Bet': 'مسح الرهانات',
    'Click on error to fix corresponding field': 'أنقر على الخطأ لتصليح الحقل المقابل',
    Close: 'أغلق',
    Code: 'خطا بالكود',
    Combination: 'مزيج',
    Commission: 'عمولة',
    'Congratulations!': 'تهانينا',
    'Contact': 'معلومات الأتصال',
    'Contact Information': 'معلومات الأتصال',
    'Contact Us': 'أتصل بنا',
    Country: 'البلد',
    'Coupon number': 'رقم الكوبون',
    Credit: 'الرصيد الدائن',
    Currency: 'العملة',
    Date: 'التاريخ',
    'Date of birth': 'تاريخ الميلاد',
    'Date range': 'مجال التاريخ',
    'Deposit Money': 'إيداع المال',
    'Deposit money': 'الإيداع',
    'Deposit options': 'خيارات الإيداع',
    deposits: 'الإيداع',
    deposit: 'الإيداع',
    'Details': 'التفاصيل',
    Delete: 'حذف',
    Deleted: 'حذف',
    DELETE_COUPON_MODAL_TEXT: 'متأكد أنك تريد الحذف',
    'Deselect All': 'اختيار واضح',
    'Dog racing': 'سباق الكلاب',
    'Don\'t have an account?': 'Don\'t have an account ?',
    'E-Mail': 'البريد الالكتروني',
    'E-mail repeat': 'كرر البريد الالكتروني',
    'Error placing bet': 'خطأ',
    'Error 404': '404',
    Event: 'تطابق',
    BET_DETAILS_EVENT: 'المباراة',
    'F.A.Q.': 'الأسئلة المتكررة',
    Featured: 'متميز',
    Female: 'أنثى',
    'Fill the form please': 'يرجى تعبئة الإستمارة',
    'Filter time': 'مدى الوقت',
    'Filters': 'التصنيف',
    'Follow us at social media': 'تابعونا على وسائل التواصل الاجتماعي',
    'Football (Asian cup)': 'كأس آسيا',
    'Football (World cup)': 'كرة القدم كأس العالم',
    'Football (Euro cup)': 'كأس أوروبا',
    'Football (Nations cup)': 'كأس الأمم',
    'Football (Bundesliga)': 'كرة القدم (بوندسليغا)',
    'Football (Champions cup)': 'كرة القدم (كأس الأبطال)',
    'Football (England League)': 'كرة القدم (دوري أنجلترا)',
    'Cricket': 'كريكيت',
    Football: 'كرة القدم',
    'Forgot Password': 'نسيت كلمة المرور',
    'Fortune wheel': 'Efsane Kazı Kazan',
    'Frequently asked questions': 'الأسئلة المتكررة',
    Fullname: 'الأسم الأسم الأخير',
    'Game Type': 'النوع',
    Gender: 'الجنس',
    'Get Bonus': 'أحصل على مكافأة',
    'google-auth': 'رمز إثبات غوغل',
    HOME_CASINO: 'الكازينو',
    HOME_SLOT: 'السلوت',
    HOME_SLOT_TEXT: 'لقد حضرنا المئات من ألعاب السلوت و العروض المميزة لك',
    HOME_SLOT_TITLE: 'ألعاب السلوت',
    HOME_SPORTS_BTN: 'ألعب الآن',
    HOME_SPORTS_TEXT:
        'أضمن ربحك مع مئات الرهانات المختلفة من كرة القدم لكرة السلة, كرة الطائرة إلى رياضة المحركات',
    HOME_SPORTS_TITLE: 'ألاف رهانات الرياضة',
    'Home team': 'المضيف',
    Help: 'المساعدة',
    Hide: 'أخفي',
    Homepage: 'الصفحة الرئيسية',
    'Horse racing': 'سباق الأحصنة',
    'I accept odds changes': 'أنا أقبل تغيرات معدلات الرهان',
    'ID/Passport number': 'رقم جواز السفر',
    Id: 'رقم الهوية',
    Info: 'معلومات',
    'Information Message': 'رسالة التعريف',
    'Last 30 Days': 'أخر ۳۰ يوم',
    'Last 7 Days': 'أخر ٧ أيام',
    'Last Month': 'الشهر الماضي',
    'Live Betting': 'المراهنة المباشرة',
    'Live Bingo': 'البينغو المباشر',
    'Live Casino': 'الكازينو',
    'Live Match': 'المباشر',
    'Live Support': 'المساعدة المباشرة',
    'Live betting': 'المراهنة المباشرة',
    'Live games': 'المباريات المباشرة',
    'Live matches': 'الرهانات المباشرة',
    'Live support': 'المساعدة المباشرة',
    'List': 'القائمة',
    Live: 'مباشر',
    Livegames: 'لايف غيمز',
    'Livematch bets': 'رهانات مباشرة',
    Login: 'تسجيل الدخول',
    Logout: 'تسجيل الخروج',
    'Lobby': 'صالة الألعاب',
    Lost: 'ضائع',
    Lottery: 'لوتو',
    'Main menu': 'القائمة الرئيسية',
    Male: 'ذكر',
    'Maximum Payout': 'الحد الأقصى للربح',
    'Maximum Stake': 'الحد الأقصى لمبلغ الرهان',
    'Detailed Information': 'معلومات',
    'MY_LIMITS_INFO':
        'الحد الأقصى لرهان الروليت.<br /> الحد الأقصى لرهان البكارات.<br /> مع رهان التأمين, الحد الأقصى لرهان اليد في البلاك جاك. (مضاعفة الرهان أو التقسيم متاح)<br /> الحد الأقصى لرهان هولديم. (الحد الأقصى لرهان الأنتي = الحد/۲)',
    Medium: 'متوسط',
    'Member Login': 'تسجيل دخول الأعضاء',
    Menu: 'القائمة',
    messages: 'رسائل',
    'Minimize games list': 'تصغير قائمة الألعاب',
    'Minimum 2 characters required': 'يجب إدخال رمزين على الأقل',
    'Minimum 3 characters required': 'يجب إدخال ثلاثة رموز على الأقل',
    'Minimum 4 characters required': 'يجب إدخال أربع رموز على الأقل',
    'Minimum Stake per betslip': 'الحد الأقصى للكوبون',
    'Minimum Stake': 'الحد الأدنى لمبلغ الرهان',
    'My Account': 'حسابي',
    'My Bets': 'رهاناتي',
    'My account': 'حسابي',
    'my-info': 'معلوماتي',
    'limits': 'حدود المراهنة',
    NOT18_ERROR: 'يجب أن يكون العمر فوق الـ١٨ لتتمكن من إنشاء حساب',
    'Name contains disallowed characters': 'الأسم يحتوي رموز ممنوعة',
    Name: 'الاسم',
    New: 'جديد',
    Newest: 'الأحدث',
    'New password': 'كلمة المرور الجديدة',
    Net: 'الربح',
    'Next Live Events': 'المباراة المباشرة القادمة',
    Next: 'التالي',
    'No games matching these parameters': 'لا نتائج للبحث',
    'No options': 'لا خيار',
    OVER18TEXT:
        'أنا هنا أقبل أنني فوق الـ١٨ و أتفق مع{{LINK_START}}الشروط و الأحكام.{{LINK_END}}',
    Odds: 'المعدلات',
    Other: 'آخر',
    Ok: 'نعم',
    Oldest: 'الأقدم',
    'Old password': 'كلمة المرور القديمة',
    'Option': 'الخيارات',
    'Outright bets': 'رهانات البطولة',
    'Page Total': 'مجموع الصفحة',
    'Paid total': 'مجموع المدفوع',
    Paid: 'مجموع المدفوع',
    'Passport': 'جواز سفر',
    'Password Repeat': 'كرر كلمة المرور',
    'Password strength': 'قوة كلمة المرور',
    Password: 'كلمة المرور',
    'Password changed successfully': 'كلمة المرور تغيرت',
    perfect: 'في احسن الاحوال',
    'Personal': 'المعلومات االشخصية',
    'Personal info': 'المعلومات االشخصية',
    'Phone number': 'رقم الهاتف',
    Phone: 'الهاتف',
    'Ping-Pong': 'كرة الطاولة',
    'Place bet': 'ضع الرهان',
    'Please accept agreement': 'لم تأكد أنك فوق الـ۱۸ من العمر',
    'Please enter a valid e-mail address': 'يرجى إدخال بريد إلكتروني صالح',
    'Please enter your passport number': 'يرجى إدخال رقم جواز السفر',
    'Please make sure you entered your details correctly':
        'تتحقق خانة رقم المستخدم من الأسم النسبة و تاريخ الميلاد, يرجى التأكد من إدخال المعلومات الصحيحة ',
    'Please, input your password to get QR code': 'أدخل رمز المرور',
    'Please, input Code from Google Authenticator': 'الرمز من موثق غوغل',
    'Popular Games': 'الألعاب الرائجة',
    Popular: 'شائع',
    'Possible payout': 'الحد الأقصى للربح',
    'Pre Match': 'الرهان العادي',
    Prematch: 'ماقبل المباراة',
    'Promo page': 'العروض',
    Promo: 'المكافأت',
    Promotions: 'المكافأت',
    Providers: 'المزود',
    Provider: 'المزود',
    Ragby: 'رجبي',
    'Requested data not exist': 'المباراة غير متاحة',
    'Reset Password': 'إعادة ضبط رمز المرور',
    'Repeat new password': 'كرر كلمة المرور',
    Result: 'نتيجة المباراة',
    'Battle Result': 'نتيجة المباراة',
    'Registration successful. Please check your e-mail.':
        'تم التسجيل بنجاح. يرجى التحقق من البريد الالكتروني',
    Rules: 'القواعد',
    'Return to homepage': 'العودة إلى الصفحة الرئيسية',
    Returned: 'İade', /// todo: need trans
    'Scan QR code or enter a secret key from Google Authenticator':
        'أمسح الرمز الظاهر أو أدخل المفتاح السري من موثق غوغل',
    SERVICE_UNAVAILABLE:
        'خطأ في معالجة بياناتك - يرجى المحاولة مجدداً بعد دقائق أو طلب المساعدة',
    'Send again': 'أرسل مجدداً',
    'SMS already sent. You can send another after: {{TIME}} seconds.':
        'لقد تم إرسال الرمز. يمكنك الإرسال مجدداً بعد {{TIME}} ثواني ',
    'Sign in': 'تسجيل الدخول',
    SIGNUP_HINT_PASS_STRENGTH:
        'أكتب رمز المرور, يرجى التفكير بإنشاء رمز آمن من خلال خلط حروف صغيرة و كبيرة و أرقام, على الأقل ٨ خانات, هنا يمكنك أن ترى قوة رمز المرور المختار',
    'Search Game': 'أبحث عن اللعبة',
    Search: 'البحث',
    'Select All': 'أختر الكل',
    'Selection': 'الأختيار',
    'Selected Systems': 'خيارات الرهان التراكمي',
    Shortcuts: 'الاختصارات',
    'Show all games': 'أظهر كل الألعاب',
    'Sign up': 'تسجيل حساب',
    Slots: 'سلوت',
    'Sorry, this game are not available': 'المباراة غير متاحة',
    'Sort by': 'ترتيب حسب',
    'Sport Betting': 'الرياضة',
    Sport: 'الرياضة',
    'Sports bets': 'الرياضة',
    Sports: 'الرياضة',
    'Stake total': 'مجموع مبلغ الرهان',
    Stake: 'مبلغ الرهان',
    Status: 'الحالة',
    Strong: 'قوي',
    Submit: 'نعم',
    Support: 'المساعدة',
    'Surname contains disallowed characters': 'الأسم يحتوي رموز ممنوعة',
    Surname: 'النسبة',
    System: 'تراكمي',
    TVBET: 'تيفي بيت',
    Tennis: 'كرة المضرب',
    'There is no available deposit methods for your account.':
        'لا يوجد أسلوب إيداع مناسب لحسابك',
    'There is no available withdraw methods for your account.':
        'لا يوجد أسلوس سحب مناسب لحسابك',
    'There is no bonus for you yet. Please try again later':
        'لا توجد مكافآت في هذه اللحظة',
    'This Month': 'هذا الشهر',
    'This field is required': '',
    Time: 'الوقت',
    Today: 'اليوم',
    Total: 'المجموع',
    Tournament: 'الدوري',
    'Transaction note': 'مذكرة العملية',
    'Turn On': 'شغل',
    'Turn Off': 'أطفأ',
    'Type': 'النوع',
    'Install Google Authenticator': 'نصب برنامج مصدق غوغل',
    'User information': 'معلومات المستخدم',
    'Username can contain only Latin letters, numbers and underscores':
        'اسم المستخدم ممكن أن يتضمن أحرف لاتينية, أرقام و إشارة (_) فقط',
    'Username must be at least 3 characters and maximum 17 characters':
        'أسم المستخدم يجب أن يتضمن ٣ حروف على الأقل',
    Username: 'اسم المستخدم',
    'View Games': 'أظهر الألعاب',
    'Virtual Sports': 'الرياضة الأفتراضية',
    Virtual: 'الرياضة الافتراضية',
    Voleyball: 'الكرة الطائرة',
    Waiting: 'الأنتظار',
    weak: 'ضعيف',
    Welcome: 'أهلا و سهلا',
    'Withdraw options': 'خيارات السحب',
    'withdraw-status': 'سحب الوضع',
    withdraws: 'السحب',
    'We have received your bonus request': 'لقد تلقينا طلب المكافأة',
    'We have sent you link to change your password':
        'لقد أرسلنا لك رابط لتغيير كلمة المرور',
    Won: 'ربح',
    Yesterday: 'البارحة',
    'Your bet slip has been deleted successfully.': 'نعم',
    'You have been logged out': 'لقد قمت بتسجيل الخروج',
    'You have entered incorrect character in password':
        'لا يمكنك وضع إشارة في رمز المرور',
    'You have no account history records.': 'لا يوجد بيانات تاريخ الحساب',
    'You have no livematch limits': 'لا سجلات',
    'You have no outright limits': 'لا سجلات',
    'You have no prematch limits': 'لا سجلات',
    'You have no withdraw status records for the chosen period of time.':
        'لا يوجد بيانات وضع سحب المال للفترة المختارة',
    'Your phone number should be started from "5"': 'يجب أن يبدأ رقم هاتفك بالرقم 5.',
    'TFA_ENABLED': 'تم تخويل التوثيق العاملين بنجاح',
    'TFA_DISABLED': 'تم إبطال التوثيق العاملين بنجاح',
    'Your ticket has been accepted': 'كوبونك قد حفظ',
    'Your ticket has been rejected': 'تم رفض الكوبون',
    'You will be redirected to main page in {{TIMER}} seconds': '{{TIMER}} إرشادك تلقائياً للصفحة الرئيسية',
    'Z - A': 'Z - A',
    Warning: 'إنذار',
    'OPEN_GAME_ERROR_WITH_CODE': 'حدث خطأ. يرجي المحاولة لاحقاً أو طلب المساعدة مع رمز الخطأ. رمز الخطأ {{ERROR_CODE}}',
    'Welcome to': 'أهلا وسهلا في',
    'Your membership has approved': 'تم الموافقة على عضويت',

    'Search result for': 'نتائج البحث',
    at: 'الوقت',
    odds: 'المعدلات',
    Payout: 'الربح',
    'Payout Total': 'مجموع الربح',
    Yes: 'نعم',
    No: 'لا',

    //widgets
    LMT: 'تعقب المباراة',
    BET_ASSISTANT: 'مساعد الرهان',
    MATCH_PREVIEW: 'تخمين المباراة',
    HEAD_TO_HEAD: 'رأس لرأس',
    Streaming: 'البث المباشر',

    LIVE_STREAMING_ERROR_1000: 'المصدر غير موجود',
    LIVE_STREAMING_ERROR_1001: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_1002: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_2000: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_2001: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_2002: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_2003: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3000: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3001: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3002: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3003: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3004: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3005: 'البث المباشر المطلوب غير موجود. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3006: 'البث المباشر لهذة المباراة غير مفعل بعد',
    LIVE_STREAMING_ERROR_3007: 'البث المباشر المطلوب غير موجود. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3008:
        'الجهاز غير ملائم لمشاهدة البث المباشر. يرجى طلب المساعدة',
    LIVE_STREAMING_ERROR_3009: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3010: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3011:
        'عنوان الاتصال المستخدم ($ip$) غير مخول لمشاهدة البث المباشر',
    LIVE_STREAMING_ERROR_3012: 'المباراة غير متاحة',
    LIVE_STREAMING_ERROR_3014:
        'الجهاز غير ملائم لمشاهدة البث المباشر. يرجى طلب المساعدة',
    LIVE_STREAMING_ERROR_3015: 'هناك مشكلة. يرجى المحاولة لاحقاً',
    LIVE_STREAMING_ERROR_3023: 'هناك مشكلة. يرجى المحاولة لاحقاً',

    USER_IS_BLOCKED: 'حسابك أغلق مؤقتاً. عند أنتهاء هذه العملية سيفعل من جديد. للمزيد من المعلومات تواصل مع المساعدة المباشرة. شكراً لتفهمكم',

    'Sizi Arayalım': 'Sizi Arayalım',//todo: add trans

    BET_BUILDER_TEXT: 'رهان المركّب نوع رهان جديد, يسمح لك بزيادة الأرباح بجمع خيارات رهان من نفس المباراة',
    'What is Bet Builder?': 'ما هو رهان المركّب',
    'You need to pick at least one more selection to place your bet via Bet Builder': 'يجب إضافة خيار اخر على الاقل للسماح بالرهان المركّب',
    'Bet builder': 'رهان المركّب',
    'Bet Builder': 'رهان المركّب',

    'BUTTON_PRINT': "طباعة",

    'CAPTCHA_TEXT': 'هذا الموقع محمي بـ reCAPTCHA  و غوغل {{FIRST_LINK}}سياسة الخصوصية{{CLOSE_LINK}} و {{SECOND_LINK}}شروط الخدمة{{CLOSE_LINK}} مطبقة.',

    'Terms of Service': 'الأحكام و الشروط',
    'Responsible Gaming': 'Responsible Gaming',//todo: add trans
    'Self Exclusion': 'Self Exclusion',//todo: add trans
    'Dispute Resolution': 'Dispute Resolution',//todo: add trans
    'AML / KYC': 'AML / KYC',//todo: add trans
    'Privacy': 'Privacy',//todo: add trans
    'User Accounts': 'User Accounts',//todo: add trans

    TOO_MUCH_SELECTIONS: 'وصلت لحد المباريات الأقصى للكوبون',
    CLEAR_EXISTING_SELECTION: 'لإضافة هذا الخيار يجب أن تمسح خيارات موجودة في قسيمة الرهان. هل توافق على هذا',

    'Terms & Conditions': 'الشروط و الأحكام',
    'Illegal arguments combination': 'التركيبة غير مسموحة. خيارات مرتبطة',
    'Illegal selection, more than one outcome per market line.': 'هذه التركيبة غير ممكنة, خيارات متناقضة',

    GETTING_ERROR_PROVIDERS_TYPES: 'لقد حدث شيء خاطئ',

    'Bet Builder information': 'معلومات رهان المركّب',

    'Cashouted': 'سحب الأرباح',
    'Are you sure to want Cashout this coupon?': 'هل واثق أنك تريد سحب أرباح هذا الكوبون',
    'Cashout value': 'قيمة سحب الأرباح',
    'Cash-out my money': 'قيمة سحب الأرباح المدفوعة',
    'Your bet has been successfully cashouted': 'تم سحب الأرباح بنجاح',
    'activeBets': 'الرهانات الفعالة',
    'Delete coupon': 'حذف الكوبون',
    'See All Coupons': 'أظهر كل الكوبونات',
    'TotalOdds': 'مجموع معدلات الرهان',
    'CASHOUT_1': 'سحب الأرباح غير متاح لهذا الكوبون',
    'CASHOUT_2': 'سحب الأرباح غير متاح لهذا الكوبون',
    'CASHOUT_3': 'سحب الأرباح غير متاح لهذا الكوبون',
    'CASHOUT_4': 'سحب الأرباح غير متاح لهذا الكوبون',
    'CASHOUT_5': 'سحب الأرباح غير متاح',
    'CASHOUT_6': 'تغيرت قيمة سحب الأرباح. يرجى المحاولة مجدداً',
    'CASHOUT_9': 'محاولات كثيرة يرجى الأنتظار',

    'ALREADY_ACCOUNT': 'لديك بالفعل في الحساب؟',
    'Popular Tournaments': 'الدوريات الشائعة',

    'Share': 'شارك',
    'Cashout possible': 'إمكانية سحب الأرباح',
    'Cashout not possible': 'سحب الأرباح غير متاح',

    PREMATCH_FILTERS_PANEL_HEADER: 'البحث و التصنيف',
    PREMATCH_FILTERS_PANEL_CHOOSE: 'الرياضة / الفئة / الدوري',
    PREMATCH_FILTERS_PANEL_CHOOSE_FILTER: 'المختار',
    PREMATCH_FILTERS_PANEL_CHOOSER_HEADER: '{{numberOfFilters}} المجموع',
    PREMATCH_FILTERS_PANEL_LOOKING_FOR: "أبحث عن",
    PREMATCH_FILTERS_PANEL_SHOW_RESULT: 'أظهر النتائج',
    PREMATCH_FILTERS_PANEL_CLEAR: 'أمسح الخيار',
    PREMATCH_FILTERS_PANEL_LIMIT_REACHED: 'لقد وصلت للحد الأقصى من التصنيفات المختارة',
    'Today_sport': 'كرة {{sportName}} لليوم',
    'Tomorrow_sport': 'كرة {{sportName}} للغد',
    'Weekend_sport': 'كرة {{sportName}} لنهاية الأسبوع',

    RANGED_EVENTS_NO_DATA_TEXT: 'لا يوجد مباريات لهذه الرياضة في الفترة المختارة',
    'All sports': 'جميع الألعاب الرياضية',

    SLOT_BATTLE__ERROR_100301: 'المعركة غير موجودة',
    SLOT_BATTLE__ERROR_100302: 'المعركة بدأت',
    SLOT_BATTLE__ERROR_100303: 'كلمة مرور خاطئة',
    SLOT_BATTLE__ERROR_100304: 'أنت في معركة الآن',
    SLOT_BATTLE__ERROR_100305: 'أنت في معركة الآن',
    SLOT_BATTLE__ERROR_100306: 'المعركة مكتملة',
    SLOT_BATTLE__ERROR_100307: 'ليس لديك إذن لهذه المعركة',
    SLOT_BATTLE__ERROR_100308: 'رصيد غير كاف',
    SLOT_BATTLE__ERROR_100309: 'ليس لديك إذن لإنشاء معركة',
    SLOT_BATTLE__ERROR_100310: 'المعركة غير متاحة',
    SLOT_BATTLE__ERROR_100311: 'تخطى الحد',
    SLOT_BATTLE__ERROR_100312: 'العملة خاطئة',
    SLOT_BATTLE__CONNECTION_ISSUES_ERROR: 'خطأ في الأتصال, يرجى المحاولة لاحقاً',
    SLOT_BATTLE__GENERAL_ERROR: 'خطأ عام',

    SLOT_BATTLE__POPUP_TITLE: 'تحديث معركة السلوت',

    SLOT_BATTLE__WILL_START_IN_A_MOMENT: 'المعركة ستبدأ خلال لحظات',
    SLOT_BATTLE__LEFT_TIME_TO_PLAY: '{{TIMER}} أبدأ اللعب في',
    SLOT_BATTLE__WAITING_FOR_PLAYERS: 'بأنتظار بقية اللاعبين {{TIMER}}',
    SLOT_BATTLE__WAITING_FOR_RESULTS: 'بأنتظار النتائج',
    SLOT_BATTLE__BATTLE_ENDED: 'أنتهت المعركة',
    SLOT_BATTLE__YOU_WON: 'لقد ربحت',
    SLOT_BATTLE__CLICK_HERE_TO_OPEN_RESULTS: 'أضغط هنا لفتح النتائج',
    SLOT_BATTLE__YOUR_GAME_ENDED_WAIT_FOR_OTHERS: '{{TIMER}} أنتهت معركتك. يرجى أنتظار نتيجة بقية اللاعبين',
    SLOT_BATTLE__OPEN_BATTLE_DETAIL: 'أضغط هنا لتفاصيل المعركة',
    SLOT_BATTLE__BATTLE_STARTED_CLICK_TO_PLAY: 'بدأت المعركة, أضغط هنا لفتح اللعبة',
    SLOT_BATTLE__BATTLE_CANCELED: 'لم يتم تجميع اللاعبين المطلوبين. ألغيت المعركة',
    SLOT_BATTLE__PLAYERS_IN_BATTLE: '{{PLRS_QTY}} اللاعبين في المعركة', //(2/3 players in battle!)

    SLOT_BATTLE__TUTORIAL_VIDEO_HEADER: 'فيديو تعليمي عن معركة سلوت',
    SLOT_BATTLE__TUTORIAL_STEP_1_HEADER: 'أهلاً بكم في معركة السلوت',
    SLOT_BATTLE__TUTORIAL_STEP_4_HEADER: 'شاشة اللعبة الخطوة الأخيرة',
    SLOT_BATTLE__TUTORIAL_STEP_1_TEXT: 'هل أنتم جاهزون للتعرف على معركة السلوت! تجربة السلوت المثيرة تتلاقى مع حس المنافسة لمضاعفة الجائزة الأخيرة\n\n' +
        'مع معركة السلوت, يمكنكم تحدي اللاعبين الآخرين ضمن شروط محدد تنصف بين كل المتسابقين و فوز جوائز في نهاية كل معركة',
    SLOT_BATTLE__TUTORIAL_STEP_2_TEXT: 'شاهد الألعاب المفعلة في الردهة, أنضم لهم و أبدأ مغامرتك هنا. يمكنك أيضاً التحقق من المعارك السابقة من هذا القسم',
    SLOT_BATTLE__TUTORIAL_STEP_3_TEXT: 'أختر لعبتك المفضلة, حدد قيمة الرهان و عدد المتنافسين \n\n' +
        'اذا كنت تفضل يمكنك التنافس مع أصدقائك من خلال أختيار معركة خاصة و نشر الرابط المميز بعد إنشاء المعركة',
    SLOT_BATTLE__TUTORIAL_STEP_4_TEXT: 'ًعليك إكمال كل الخطوات بنجاح لتتأهل للفوز. التعليمات أنتهت, يمكنك اللعب و نتمنى لك حظاً موفقاً',
    SLOT_BATTLE__TUTORIAL_STEP_4_TIP_1: 'أخفض/أرفع الرهان الأولي بـ + و - حسب رهانك',
    SLOT_BATTLE__TUTORIAL_STEP_4_TIP_2: 'يجب أن تكون مساوياً لرهانك',
    SLOT_BATTLE__TUTORIAL_STEP_4_BUY: 'أشتري ميزة الدورات المجانية',
    SLOT_BATTLE__TUTORIAL_DONT_SHOW_AGAIN: 'لا تظهر مجدداً',
    SLOT_BATTLE__TUTORIAL_START_BUTTON: 'أبدأ',
    SLOT_BATTLE__TUTORIAL_SKIP_BUTTON: 'تخطى',
    SLOT_BATTLE__TUTORIAL_GOT_IT: 'لقد فهمت',
    SLOT_BATTLE_TUTORIAL__TITLE: 'فتحة معركة تعليمية',
    SLOT_BATTLE__TUTORIAL_START: 'أبدأ التعليمات',
    SLOT_BATTLE__FAQ: 'معركة السلوت هي لعبة فريدة من نوعها, حيث يتحدى لاعبين أو أكثر بعضهم من خلال شراء مكافأة الدورات المجانية في اللعبة المختارة\n\n' +
        'يجب على كل متنافس أن يشتري المكافأة خلال دقيقتين من بداية المعركة و أن ينهي دورات المكافأة في اللعبة خلال عشر دقائق من وقت البداية\n\n' +
        'المتسابق الذي ينهي دورات المكافأة بالربح الأكبر سيكسب أرباح المتنافسين الآخرين\n\n' +
        'اذا تساوى أرباح متنافسين أو أكثر سيقسم مجموع الأرباح بينهم بشكل متساوي \n\n' +
        'أي سوء أستعمال أو محاولة تعطيل المعارك و تجربة المتنافسين من قبل أحد اللاعبين تسبب بخصم مباشرة من الحساب و خصم من الرصيد\n\n' +
        'يمكنك بدأ التعليمات الآن. يرجى التأكد من قرأة و التعرف على قواعد معركة السلوت',
    SLOT_BATTLE__BATTLE_RULES: 'قواعد معركة السلوت',
    SLOT_BATTLE__GO_TO_BATTLE_RULES: 'أظهر القواعد',

    SLOT_BATTLE__BACK_BUTTON_AVAILABLE: 'المعارك المتاحة',
    SLOT_BATTLE__BACK_BUTTON_STARTED: 'المعارك البادئة',
    SLOT_BATTLE__BACK_BUTTON_COMPLETED: 'المعارك المنتهية',

    'available': 'المتاحة',
    'started': 'البادئة',
    'completed': 'المنتهية',

    'My battles': 'معاركي',
    'Lowest bet': 'أصغر رهان',
    'Highest bet': 'أعلى رهان',

    SLOT_BATTLE__NO_DATA_TITLE: 'تحقق من حظك في المعركة',
    SLOT_BATTLE__ALREADY_IN_BATTLE: 'أنت في المعركة الآن',
    SLOT_BATTLE__USER_CANT_PLAY: 'معركة السلوت غير مخولة لك. يرجى التواصل مع المساعدة المباشرة',
    SLOT_BATTLE__CREATE: 'أنشأ معركة السلوت',
    SLOT_BATTLE__START_NOW: 'ابدأ الآن',
    SLOT_BATTLE__OPEN_MY_BATTLE: 'افتح معركتي',
    SLOT_BATTLE__LEAVE: 'أترك المعركة',
    SLOT_BATTLE__JOIN: 'أنضم للمعركة',

    SLOT_BATTLE__CHOOSE_FAV_GAME: 'اختر لعبتك المفضلة',
    SLOT_BATTLE__SET_WAGERING_AMOUNT: 'حدد مبلغ الرهان',
    SLOT_BATTLE__SELECT_NUMBER_OF_PARTICIPANTS: 'حدد عدد المشاركين',
    SLOT_BATTLE__WIN_YOUR_MONEY: 'اكسب أموالك',

    SLOT_BATTLE__BANNER_TEXT: 'جرب حظك',

    SLOTS_BATTLE__AVAILABLE_NO_DATA_TEXT: 'لا معارك متاحة. أنشأ معركة جديدة الآن',
    SLOTS_BATTLE__STARTED_NO_DATA_TEXT: 'لا معارك بدأت موجودة',
    SLOTS_BATTLE__COMPLETED_NO_DATA_TEXT: 'لا معاركة منتهية موجودة',
    SLOTS_BATTLE__NO_DATA_TEXT_BY_THIS_PARAMS: 'لا معارك موجودة للخيارات المحددة',

    SLOT_BATTLE__BET: 'شراء المكافأة',
    SLOT_BATTLE__PLAYERS: 'عدد المشتركين',
    SLOT_BATTLE__OWNER: 'صاحب المعركة',

    SLOT_BATTLE__GAME_NAME: 'اسم اللعبة',
    SLOT_BATTLE__DETAILS: 'تفاصيل المعركة',
    SLOT_BATTLE__INVITE: 'Invite',
    SLOT_BATTLE__URL_COPIED: 'تم نسخ رابط المعركة بنجاح',
    SLOT_BATTLE__SHARE: 'أنشر المعركة',

    SLOT_BATTLE__PLAYER: 'اللاعب',
    SLOT_BATTLE__STATUS: 'الحالة',
    SLOT_BATTLE__SCORE: 'النتيجة',
    SLOT_BATTLE__RESULT: 'نتيجة المعركة',

    SLOT_BATTLE__DISABLED_PLAY_BUTTON: 'لم تتمكن من اللعب خلال الفترة المحددة',
    SLOT_BATTLE__PLAY_BUTTON: 'ألعب',
    SLOT_BATTLE__NOT_AVAILABLE: 'المعركة غير متاحة',
    SLOT_BATTLE__ROW_STATUS_DONE: 'أنتهى',
    SLOT_BATTLE__ROW_STATUS_LEFT: 'لم يشارك',
    SLOT_BATTLE__ROW_STATUS_NO_RESULT: 'No result',
    SLOT_BATTLE__ROW_STATUS_IN_PLAY: 'جاري اللعب',
    SLOT_BATTLE__ROW_STATUS_WAIT: '-',

    SLOT_BATTLE__CHOOSE_GAME: 'أختر اللعبة',
    SLOT_BATTLE__NO_BET_OPTION: 'لا خيارات لهذه اللعبة',
    SLOT_BATTLE__NO_GAMES: 'لا ألعاب',
    SLOT_BATTLE__START_NOT_FULL: "أبدأ بلاعبين على الأقل",
    SLOT_BATTLE__PRIVAT: 'معركة خاصة',
    SLOT_BATTLE__CREATE_PASSWORD: 'أنشأ كلمة مرور',

    SLOT_BATTLE__RESULTS_TIMER: 'بإنتظار النتائج {{TIMER}}',

    SLOT_BATTLE: 'معركة السلوت',

    'Coupon Status': 'الوقت',
    'Score': 'النتيجة',
    'Twain': 'تواين',
    'Random Game': 'لعبة عشوائية',

    LMT_INFO_TEXT: 'النتيجة, المدة و المعلومات الأخرى المعروضة بصفحة الرهان المباشر هي فقط لغرض مرجعي',

    WIDGET_GAME__PLAY_BUTTON: 'ألعب',

    'Favorite': 'ألعابي المفضلة',
    'Recently played': 'آخر ألعابي',

    YOUR_POPULAR_ACCUMULATORS: 'رهانك المجمع',
    POPULAR_ACCUMULATORS: 'الكوبونات الرائجة',
    TOTAL_ODDS: 'مجموع المعدل',
    ADD_TO_BETSLIP: 'أضف للكوبون',
    REMOVE_FROM_BETSLIP: 'أمسح من الكوبون',

    GRAND_JACKPOT: 'Grand Jackpot',
    MAJOR_JACKPOT: 'Major',
    MINOR_JACKPOT: 'Minor',
    MINI_JACKPOT: 'Mini',
    BACK_TO_JACKPOTS: 'للخلف',
    SHOW_ALL_GAMES: 'أظهر الكل',
    JACKPOT_TOP_WIN: 'أعلى ربح',
    JACKPOT_LAST_WIN: 'أخر ربح',
    JACKPOT_TOTAL_WINS: 'مجموع الأرباح',

    BOT_DETECTED: 'ممنوع من الدخول, يرجى طلب المساعدة',

    COUPON_PRESET_SAVE: 'أحفظ التغيرات',
    COUPON_BET_LIMIT_TEXT: 'أعلى مبلغ لهذه الرهانات',
    AMOUNT_PER_BET: 'ملبغ الرهان',
    FIRST_BET: 'الرهان الأول',
    SECOND_BET: 'الرهان الثاني',
    THIRD_BET: 'الرهان الثالث',
    SINGLE: 'فردي',
    ACCUMULATED: 'تراكمي',

    ONLY_SINGLE_ERROR_TEXT: 'لا يمكن جمع بعض الخيارات في الكوبون, يرجى تغيير الخيارات',

    TOTAL_AMOUNT: 'مجموع المبلغ',

    BET_BUILDER_COMMON_ERROR: 'هذه التركيبة غير ممكنة. خيارات متناقضة',

    GAME_HAS_NO_FUN_MODE: 'أسلوب تجربة هذه اللعبة غير ممكن',
};
