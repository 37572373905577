export default function getSportIconsClassnames(type, viewSize, sportId) {
    switch (type) {
        case 'svg':
            return `sport_icon_font-${sportId}`;
        case 'png':
            return viewSize === 'desktop'
                ? `sport-icon-${sportId}`
                : `sport-icon-m-${sportId}`;
        default:
            return false;
    }
}
