import React from 'react';
import {observer} from "mobx-react";

import LLCouponListItem from './coupon-list-item';
import CouponTabs from './coupon-tabs';


const CouponList = (
    {
        list,
        isCustom,
        isMobile = false,
        activeTab,
        setActiveTab,
        isSingleOnly
    }
) => {

    if (list.length) {
        if (isCustom) {
            return <div className={`coupons-list${isMobile ? '--mobile' : ''}`}>
                <LLCouponListItem coupon={list[0]} list={list}/>
            </div>
        } else {
            return <CouponTabs
                {...{
                    list,
                    activeTab,
                    setActiveTab,
                    isMobile,
                    isSingleOnly
                }}
            />
        }
    } else {
        return null;
    }
};

export default observer(CouponList);