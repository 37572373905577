import React from 'react';
import {NavLink} from "react-router-dom";

import {trans} from "../../../../../common/utils";
import {crutchDanoHideBets, LiveMatchCompetitorHolder} from "../../../components";
import {LLPreloader, LLSportBettingButtonWrapper} from "../../../../../components";
import cx from "classnames";


export const LiveMatchItemMobile = (
    {
        match,
        branch,
        activeItems,
        userCanCashout,
        isLogged,
        siteName,
        viewSize,
    }
) => {
    const mainMarket = match.mainMarket();

    return (
        <NavLink
            to={match.matchUrl(branch.id)}
            className={cx(
                'livematches__match-items common-match-item',
                `common-match-item--${viewSize}`
            )}
        >
            <div className="livematch__title-container">
                <div className="livematch__info">
                    <p>
                        <span className="livematch__info-time">
                            {match.fullMatchTime ?? ''}{' '}
                        </span>
                        <span className="livematch__info-status">
                            {match.statusName ?? ''}
                        </span>
                        { userCanCashout &&
                            <i className={`doruk-cashout-01${match.canCashout ? ' match__icon--can-cashout' : ' match__icon--cant-cashout'}`}
                               title={trans(match.canCashout ? 'Cashout possible' : 'Cashout not possible')}/>
                        }
                        { match.isStreaming && match.streamingId && isLogged ?
                            <span className="live-match__stream-icon">
                                <i className="doruk-streaming-icon"/>
                            </span>
                            : null
                        }
                    </p>
                </div>
                <LiveMatchCompetitorHolder match={match} isLogged={isLogged} viewSize={viewSize}/>
            </div>
            <div className="livematch__bets">
                { !crutchDanoHideBets(siteName, match) &&
                    match?.oddsCount && mainMarket?.betsList ?
                        mainMarket.betsList.map(
                            (bet) => (
                                <div key={bet.id}
                                    className={`matches__bets-item ${
                                        bet.outcomeValue === 0 ? 'matches__bets-item--disabled' : ''
                                    }`}
                                >
                                    <LLSportBettingButtonWrapper
                                        className="matches__bets-btn"
                                        type="live"
                                        bet={bet}
                                        nameAppearance={false}
                                        key={bet.id}
                                    />
                                </div>
                            )
                        )
                        : null
                }

                <div className="matches__bets-item">
                    <div
                        className="matches__bets-btn matches__odds-count-btn">
                        { !crutchDanoHideBets(siteName, match) && match?.oddsCount ?
                            '+' + match.oddsCount
                            : trans('No options')
                        }
                        <i className="match__odds-count-icon llg-icon llg-chevron-right"/>
                        { activeItems.matches.includes(match.id) && match.initialFetching && (
                            <LLPreloader
                                className="bet__loader"
                                height={20}
                                width={20}
                            />
                        )}
                    </div>
                </div>
            </div>
        </NavLink>
    );
};