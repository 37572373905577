import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";

import {trans} from "../../../../common/utils";
import {LLBtn2} from "../../../../components";


const FirstModal = ({ store }) => {

    const viewSize = store.site.status.viewSize;
    const setTutorialStep = store.slotsBattles.setTutorialStep;
    const setBackDropClosable = store.site.status.setBackDropClosable;
    const setTutorialOnCloseGoTo = store.slotsBattles.setTutorialOnCloseGoTo;
    const router = store.router;
    const close = store.modal.close;

    useEffect(() => {
        setBackDropClosable(false);
    },[]);

    return (
        <div className={`battle-tutorial-modal battle-tutorial-modal--${viewSize}`}>
            <div className="battle-tutorial-modal__content">
                <p>{trans('SLOT_BATTLE__TUTORIAL_STEP_1_TEXT')}</p>
            </div>
            <div className="battle-tutorial-modal__buttons">
                <LLBtn2
                    onClick={() => {
                        close();
                        setTutorialOnCloseGoTo(router.location.pathname);
                        router.location.pathname !== '/slots-battles/available' && router.push('/slots-battles/available');
                        setTutorialStep(1);
                    }}
                    customClasses="slots-battles__btn--accent"
                >
                    {trans("SLOT_BATTLE__TUTORIAL_START_BUTTON")}
                </LLBtn2>
                <LLBtn2
                    onClick={close}
                    customClasses="slots-battles__btn slots-battles__btn--outlined"
                >
                    {trans("SLOT_BATTLE__TUTORIAL_SKIP_BUTTON")}
                </LLBtn2>
            </div>
        </div>
    );
};

export default inject('store')(observer(FirstModal));
