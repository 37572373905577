import React, {useState} from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {LLBtn, LLDatePicker} from '../..';
import {getMidnightDate, trans} from '../../../common/utils';
import {LLDateRangePickerDesktop} from "./desktop-date-range-picker";

import 'react-datepicker/dist/react-datepicker.css';


/**
 * @file Represents React Component to render Date Range Picker
 *
 * @param {function} actionHandler - callback on submit dates changes
 * @param {object} storeItem - item in store that contain range (dates)
 * @param {function} setDate - function
 * @param {Date} startDate - startDate of range
 * @param {Date} endDate - endDate of range
 *
 * @author A.Huskova
 */

const LLDateRangePicker = (
    {
        store,
        actionHandler = () => {},
        storeItem: {setDate, startDate, endDate, setRange, setNewDateRange = () => {}},
        mobileDateRange = 0,
        withoutRanges,
        withSubmitButton = true,
        customClassnames = 'll-date-range-picker--user-cabinet',
        isFutureDates,
        withoutPortal
    }) => {

    const viewSize = store.site.status.viewSize;

    const [innerPickerStartDate, setInnerPickerStartDate] = useState(startDate),
        [innerPickerEndDate, setInnerPickerEndDate] = useState(endDate);

    const setDates = (range, isFetchNotificationShown = true) => {
        if (viewSize === 'desktop' || mobileDateRange !== range.id) {
            setDate({
                startDate: getMidnightDate(range.startDate),
                endDate: getMidnightDate(range.endDate),
            });
            actionHandler(isFetchNotificationShown);
        }
        if (typeof setRange === 'function') {
            setRange(range.id);
        }
    };

    const onChangeStartPickerHandler = (date) => {
        setInnerPickerStartDate(date);
        if (date > innerPickerEndDate) {
            setInnerPickerEndDate(date);
            setNewDateRange({
                startDate: date,
                endDate: date,
            });
        } else { setNewDateRange({ startDate: date }); }
    };
    const onChangeEndPickerHandler = (date) => {
        setInnerPickerEndDate(date);
        if (date < innerPickerStartDate) {
            setTimeout(() => {
                setInnerPickerStartDate(date);
                setNewDateRange({
                    startDate: date,
                    endDate: date,
                });
            }, 1);
        } else {
            setNewDateRange({ endDate: date });
        }
    };

    return viewSize !== 'desktop' || withoutRanges ? (
        <div className={cx(
            'll-date-range-picker--mobile', customClassnames
        )}>
            <div className="ll-date-range-picker__pickers">
                <div className="ll-date-range-picker__picker-box">
                    <LLDatePicker
                        selected={innerPickerStartDate}
                        onChange={onChangeStartPickerHandler}
                        minDate={isFutureDates ? new Date() : undefined}
                        maxDate={isFutureDates ? undefined : innerPickerEndDate}
                        withPortal={!withoutPortal}
                        icon="doruk-live-calendar"
                        className="ll-date-range-picker__picker-input"
                        dateFormat="dd.MM.yyyy"
                    />
                </div>
                <div className="ll-date-range-picker__picker-box">
                    <LLDatePicker
                        selected={innerPickerEndDate}
                        onChange={onChangeEndPickerHandler}
                        minDate={isFutureDates ? innerPickerStartDate : undefined}
                        maxDate={isFutureDates ? undefined : new Date()}
                        withPortal={!withoutPortal}
                        icon="doruk-live-calendar"
                        className="ll-date-range-picker__picker-input"
                        dateFormat="dd.MM.yyyy"
                    />
                </div>
            </div>
            { withSubmitButton && (
                <LLBtn
                    customClasses={
                        'll-btn--full-rounded ll-date-range-picker__request-btn'
                    }
                    onClick={() =>
                        setDates({
                            startDate: innerPickerStartDate,
                            endDate: innerPickerEndDate,
                        })
                    }
                >
                    {trans('List')}
                </LLBtn>
            )}
        </div>
    ) : (
        <LLDateRangePickerDesktop
            {...{
                startDate,
                endDate,
                setDates,
                innerPickerStartDate,
                innerPickerEndDate,
                onChangeStartPickerHandler,
                onChangeEndPickerHandler,
            }}
        />
    );
};

export default inject('store')(observer(LLDateRangePicker));
