import React from "react";
import cx from "classnames";

import {LLElementTypeSetter, WithPopover} from "../../../HOCs";
import {API_PREFIX} from "../../../../common/api/api-url-config";
import apiUrls from "../../../../common/api/api-urls";


const WidgetMultigames = (props) => {

    const {
        slide,
        language,
        isLogged,
        mobileUserAgent,
        openLogin,
    } = props;

    const {
        promoTitle,
        promoText,
        defaultLang,
        gameId,
        games,
    } = slide.settings;

    return (
        <div className="ll-widget-game ll-widget-game--multiple">
            <div className="ll-widget-game__game-items">
                {games.length === 2 &&
                    <div className="ll-widget-game__game-item ll-widget-game__game-item--promo">
                        <div className="ll-widget-game--promo-title">
                            <WithPopover customClasses={cx("ll-widget-game-left-part--title")}>
                                    <span>
                                        {promoTitle[language] || promoTitle[defaultLang]}
                                    </span>
                            </WithPopover>
                        </div>
                        <div className="ll-widget-game--promo-text">
                            <WithPopover customClasses={cx("ll-widget-game-left-part--text")}>
                                    <span>
                                        {promoText[language] || promoText[defaultLang]}
                                    </span>
                            </WithPopover>
                        </div>
                    </div>
                }
                {games.map((game, index) => {
                    const elementAttributes = mobileUserAgent ? {
                        href: `${API_PREFIX}${apiUrls.games.openGame}${game.mobileLink}`,
                        target: "_blank",
                        rel: "noopener noreferrer",
                    } : {
                        to: game.desktopLink,
                    };
                    return (
                        <div className="ll-widget-game__game-item-new" key={index}>
                            <LLElementTypeSetter
                                data-interact-target={gameId}
                                {...elementAttributes}
                                onClick={(e) => {
                                    if (!isLogged && mobileUserAgent) {
                                        e.preventDefault();
                                        openLogin();
                                    }
                                }}
                            >
                                <img className="ll-widget-game-image"
                                     src={game.gameImg}
                                     alt=""/>
                            </LLElementTypeSetter>
                        </div>
                    )
                })}
            </div>
        </div>
    );

}

export default WidgetMultigames;
