import React from "react";
import {LLElementTypeSetter} from "../../../../components";

const MatchDetailsTitle = ({match}) =>
    <p className="match-details__title">
        <LLElementTypeSetter
            as="NavLink"
            to={match.categoryUrl()}
        >
            {match.nameOf('category')}
        </LLElementTypeSetter>
        <LLElementTypeSetter
            as="NavLink"
            to={match.tournamentUrl()}
        >
            {match.nameOf('tournament')}
        </LLElementTypeSetter>
    </p>;

export default MatchDetailsTitle;
