import React, {Fragment, useState} from 'react';
import {NavLink} from "react-router-dom";
import cx from "classnames";

import {devInfo, trans} from '../../../../../common/utils';
import {LiveMatchCompetitorHolder} from "../../../components";
import BetsList from '../components/live-bets-list';
import {
    LIVE_MATCH_ADD_INFO_SPORT_IDS
} from '../../../components/live-match-item-components/live-match-competitor-holder';


export const LiveTournamentItemDesktop = (
    {
        branch,
        sport,
        category,
        tournament,
        activeItems,
        userCanCashout,
        isLogged,
        viewSize,
        siteName,
    }
) => {
    const [isOpened, setOpened] = useState(true);

    const AdditionalInfoMatchHeader = ({sportId}) => {
        const isTwoWay = (sportId === '2');
        return (
            <div className="live-left-menu__row-header">
                <div className="live-left-menu__row-header-time">{trans('Time')}</div>
                <div className="live-left-menu__row-header-competitors">
                    <div className="live-left-menu__row-header-event">{trans('Event')}</div>
                </div>
                <div className="live-left-menu__bets">

                    <div className={cx(
                        'live-left-menu__oddsv',
                        isTwoWay && 'live-left-menu__oddsv--half'
                    )}>1
                    </div>

                    {!isTwoWay && (<div className="live-left-menu__oddsv">x</div>)}

                    <div className={cx(
                        'live-left-menu__oddsv',
                        isTwoWay && 'live-left-menu__oddsv--half'
                    )}>2
                    </div>

                </div>
                <div className="live-left-menu__sides">M</div>
            </div>
        );
    }

    return (
        <>
            <div className={cx(
                'live-common-menu__item-name',
                isOpened && 'live-common-menu__item-name--expanded'
            )} onClick={() => {
                setOpened(v => !v)
            }}>
                <span className="live-common-menu__category-expand-wrp">
                    <i className={cx(
                        'doruk-mg-arrow-2',
                        'live-common-menu__category-expand-icon',
                        isOpened && 'live-common-menu__category-expand-icon--expanded'
                    )}/>
                </span>
                <span>{category.name + ' > ' + tournament.name}</span>
            </div>
            <div className={cx(
                'live-common-menu__item-content',
                isOpened && 'live-common-menu__item-content--expanded'
            )}>
                {LIVE_MATCH_ADD_INFO_SPORT_IDS.includes(sport.id) ?
                    <AdditionalInfoMatchHeader sportId={sport.id}/> : null
                }
                <div
                    className="live-left-menu__item"
                    id={`tournament-${tournament.id}`}
                >
                    {tournament.listLiveMatches.map((match, i) => (
                        <NavLink
                            id={`match-${match.id}`}
                            to={match.matchUrl(branch.id)}
                            className={`live-left-menu__list-row${
                                activeItems.isActive({
                                    id: match.id,
                                    type: 'matches',
                                })
                                    ? '--active'
                                    : ''
                            }`}
                            {...devInfo({data: {'data-match': match}})}
                            key={match.id}
                        >
                            <>
                                <div className="live-left-menu__time open_detail">
                                    {match.matchTime
                                        ? match.matchTimeMinute
                                        : match.shortStatusName}
                                </div>
                                {userCanCashout && <div className="live-left-menu__can-cashout open_detail">
                                    <i className={`doruk-cashout-01${match.canCashout ? ' match__icon--can-cashout' : ' match__icon--cant-cashout'}`}
                                       title={trans(match.canCashout ? 'Cashout possible' : 'Cashout not possible')}/>
                                </div>}
                                <LiveMatchCompetitorHolder match={match} isFirstInList={i === 0} isLogged={isLogged}
                                                           viewSize={viewSize}/>
                                <BetsList match={match} siteName={siteName}/>
                            </>
                        </NavLink>
                    ))}
                </div>
            </div>
        </>
    );
};