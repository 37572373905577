import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";


const VideoModal = ({store}) => {

    const viewSize = store.site.status.viewSize;
    const setBackDropClosable = store.site.status.setBackDropClosable;

    useEffect(() => {
        setBackDropClosable(false);
    }, []);

    return (
        <div className={`battle-tutorial-modal battle-tutorial-modal--${viewSize}`}>
            <div className="battle-tutorial-modal__content battle-tutorial-modal__video">
                <video className="battle-tutorial-modal__content-video"
                       src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                       poster="https://i.ytimg.com/vi/dQw4w9WgXcQ/hq720.jpg"
                       width={"100%"}
                       autoPlay
                       controls={true}
                >
                    Sorry, your browser doesn't support embedded videos,
                    but don't worry, you can download it
                    and watch it with your favorite video player!
                </video>
            </div>
        </div>
    );
};

export default inject('store')(observer(VideoModal));
