import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import LLProgressBar from '../progress-bar/progress-bar';

import './menu-accordion.scss';

const LLMenuAccordion = (props) => {
    const {
        itemId,
        children,
        timeout = 400,
        icon: { enable: enableIcon = true, iconClass } = {},
        titleTemplate,
        titleTemplateRender,
        customClasses,
        onClickAwaitContentLoad,
        onClick,
        defaultExpanded = false,
        expanded,
        backgroundUrl,
        loadingAppearance = false,
        disabled = false,
        ...restProps
    } = props;

    /**** ~~ Define expand state of accordion content ****/
    const [expandState, setExpandState] = useState(defaultExpanded);

    let bananaDefender = true;

    useEffect(() => {
        bananaDefender && setExpandState(expanded);
    }, [expanded]);

    useEffect(
        () => () => {
            bananaDefender = false;
        },
        []
    );

    /**** ~~ Click event handler ****/
    const onAccordionHeaderClick = (e) => {
        if (!disabled) {
            /**** ~~ Wait for promise resolve if its  exists ****/
            if (
                onClickAwaitContentLoad &&
                expandState === false &&
                loadingAppearance === false
            ) {
                onClickAwaitContentLoad().then(() => {
                    setExpandState(!expandState);
                });
            }

            onClick &&
                onClick({ e, itemId, action: expandState ? 'collapse' : 'expand' });

            if (loadingAppearance === false) {
                setExpandState(!expandState);
            }
        }
    };

    /**** ~~ Render ****/
    return (
        <div
            className={cx(
                {
                    'll-accordion': !expandState,
                    'll-accordion--active': expandState,
                    'll-accordion--disabled': disabled,
                },
                customClasses
            )}
            style={{
                ...(backgroundUrl ? { backgroundImage: `url(${backgroundUrl})` } : {}),
            }}
            {...restProps}
        >
            <div className="ll-accordion__header" onClick={onAccordionHeaderClick}>
                {titleTemplate && (
                    <span className="ll-accordion__header-text">{titleTemplate}</span>
                )}

                {!!titleTemplateRender && titleTemplateRender()}

                {enableIcon && (
                    <i
                        className={`ll-accordion__icon ${
                            iconClass ? iconClass : 'doruk-mg-arrow-2'
                        }`}
                    />
                )}

                {loadingAppearance && (
                    <LLProgressBar customClasses="ll-accordion__progress-bar" />
                )}
            </div>
            {(!timeout && expandState) ? 
                <div>{children}</div>
                :
                <CSSTransition
                    classNames="ll-accordion__content" /* ❓ This class need for animation */
                    timeout={timeout}
                    in={expandState}
                    unmountOnExit
                >
                    <div>{children}</div>
                </CSSTransition>
            }
        </div>
    );
};

export default LLMenuAccordion;
