import { getRoot, flow } from 'mobx-state-tree';
import { values } from 'mobx';

/*
 ######     ###    ########  ######    #######  ########  ##    ##     ######   #######  ##     ## ##     ##    ###    ##    ## ########   ######
##    ##   ## ##      ##    ##    ##  ##     ## ##     ##  ##  ##     ##    ## ##     ## ###   ### ###   ###   ## ##   ###   ## ##     ## ##    ##
##        ##   ##     ##    ##        ##     ## ##     ##   ####      ##       ##     ## #### #### #### ####  ##   ##  ####  ## ##     ## ##
##       ##     ##    ##    ##   #### ##     ## ########     ##       ##       ##     ## ## ### ## ## ### ## ##     ## ## ## ## ##     ##  ######
##       #########    ##    ##    ##  ##     ## ##   ##      ##       ##       ##     ## ##     ## ##     ## ######### ##  #### ##     ##       ##
##    ## ##     ##    ##    ##    ##  ##     ## ##    ##     ##       ##    ## ##     ## ##     ## ##     ## ##     ## ##   ### ##     ## ##    ##
 ######  ##     ##    ##     ######    #######  ##     ##    ##        ######   #######  ##     ## ##     ## ##     ## ##    ## ########   ######
*/

const categoriesActions = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                 Put update categories                                ##
    // ##                                                                                      ##
    // ##========================================================================================

    putUpdateData({ dataSource, requestedCategoryId }) {
        if (
            requestedCategoryId &&
            requestedCategoryId !== '0' &&
            parseInt(requestedCategoryId) &&
            dataSource.findIndex(({ id }) => id == requestedCategoryId) == -1 &&
            s.categories.has(requestedCategoryId)
        ) {
            /**** ~~ Set this category id as removable after initial loading will complete ****/
            getRoot(s).betting.setRemovableInitialInstances({
                cid: requestedCategoryId,
            });
        }

        /**** ~~ Define all parents id ****/
        const parentsIds = [
            ...new Set(
                dataSource.reduce((a, { parentId }) => {
                    return parentId ? [...a, parentId] : a;
                }, [])
            ),
        ];

        dataSource.map((newCategory) => {
            if (!s.categories.has(newCategory.id)) {
                s.categories.put({
                    ...newCategory,
                    isParent: parentsIds.some((parentId) => {
                        return parentId == newCategory.id;
                    }),
                });
            } else {
                s.categories.get(newCategory.id).update(newCategory);
            }
        });
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                        Compare categories and delete old ones                        ##
    // ##                                                                                      ##
    // ##========================================================================================
    deleteOldCategories: flow(function* fetch({ categories, categoryIds }) {
        if (categories) {
            //for pre and for removeItems pre and live
            values(s.categories)
                .filter(
                    ({ id }) =>
                        !categories.some(({ id: incomingId }) => incomingId === id)
                )
                .map((oldCategory) => {
                    oldCategory.removeItems();
                });
        } else if (categoryIds) {
            //for live
            yield Promise.all([
                values(s.categories).map((category) => {
                    if (!categoryIds.includes(category.id)) {
                        category.removeItems();
                    }
                }),
            ]);
        }
    }),
});

export default categoriesActions;
