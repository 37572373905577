import React from 'react';
import PropTypes from 'prop-types';

import { withCommon } from '../../components';
import configAboutUs from './config';

/**
 * @file Represents About Us page
 *
 * @param {string} language - active site language
 * @param {string} siteName - site name
 *
 * @author A. Green
 */

const AboutUsPage = withCommon({
    store: ({ store }) => ({
        siteName: store.site.siteName,
        language: store.user.language,
    }),
    propTypes: {
        siteName: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
    },
    isTrans: true,
})(({ trans, siteName, language }) => (
    <div className="ll-page about-us">
        <div className="about-us__header">{trans('About Us')}</div>
        <div className="about-us__content">
            {configAboutUs[siteName]
                ? configAboutUs[siteName][language].map((paragraph, i) => (
                      <p key={i}>{paragraph}</p>
                  ))
                : configAboutUs['Default'][language].map((paragraph, i) => (
                      <p key={i}>{paragraph}</p>
                  ))}
        </div>
    </div>
));

export default AboutUsPage;
