import { values } from 'mobx';
import { types, flow, getRoot, getParent, getType } from 'mobx-state-tree';
import orderBy from 'lodash/orderBy';

import api from '../../../../common/api';
import { llNotifier } from '../../../../components';
import { llattempt } from '../../../../common/utils';
import {vendorTextReplacer} from '../../../../common/utils';

export const PaymentItem = types
    .model('PaymentItem', {
        id: types.identifierNumber,
        customerGroup: types.string,
        url: types.string,
        modifiedUrl: types.string,
        logoURL: types.string,
        description: types.maybeNull(types.string),
        name: types.string,
        sortOrder: types.number,
    })
    .views((self) => ({
        get paymentType() {
            return getType(getParent(self, 2)).name;
        },
    }));

const Payments = types
    .model('Payments', {
        methods: types.array(PaymentItem),
    })
    .actions((self) => {
        return {
            _set(payments) {
                const getModifiedUrl = (url) => {
                    let result = url.trim();
                    result = result.replace('{{USER_ID}}',getRoot(self).user.customerId);
                    result = result.replace('{{USERNAME}}',getRoot(self).user.username);
                    result = result.replace('{{SESSION_ID}}',getRoot(self).user.sId);
                    return result;
                };

                self.methods = [];
                orderBy(payments, ['SortOrder'], 'asc').map((payment) =>
                    self.methods.push(
                        PaymentItem.create({
                            id: payment.PaymentMethodID,
                            customerGroup: payment.CustomerGroup,
                            url: vendorTextReplacer(payment.IframeURL),
                            modifiedUrl: vendorTextReplacer(getModifiedUrl(payment.IframeURL)),
                            logoURL: vendorTextReplacer(payment.LogoURL),
                            description: vendorTextReplacer(payment.PaymentMethodDescription),
                            name: vendorTextReplacer(payment.PaymentMethodName),
                            sortOrder: payment.SortOrder,
                        })
                    )
                );
                self.fetchTime = Date.now();
                self.isTableLoading = false;
            },
            getPayments: flow(function* fetch(getDataFunctionName) {
                if (self.checkFetchTime()) {
                    self.isTableLoading = true;
                    const parent = getParent(self);
                    const data = {
                        mobile: getRoot(self).user.mobileUserAgent,
                        currency: parent.currency,
                        id: parent.currency.customerId,
                    };
                    yield llattempt(
                        () =>
                            api.user[getDataFunctionName](data).then((response) => {
                                if (response.success) {
                                    self._set(response.data);
                                } else {
                                    self.resetFetchTimeAndLoading();
                                    // Thing below needed to avoid double error notifier on logout
                                    if (response.data?.error === 'AUTH_REQUIRED') {
                                        return true;
                                    } else {
                                        llNotifier({
                                            message: response.data?.error ?? 'GENERAL_ERROR',
                                            type: 'error',
                                        });
                                    }
                                }
                            }),
                        {
                            msg: 'GENERAL_ERROR',
                            at: 'user.getPayments',
                            withParams: data,
                            onError: () => {
                                self.resetFetchTimeAndLoading();
                            },
                        }
                    );
                }
            }),
        };
    })
    .views((self) => ({
        get payments() {
            return values(self.methods);
        },
    }));

export default Payments;
