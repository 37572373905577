import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {
    withCommon,
    LLElementTypeSetter,
    LLMenuItem,
    LLTextSlider,
} from '../../../../components';
import SlotsCasinosGamesSearch from '../games-search';
import SlotCasinoMobileMenu from './mobile-menu';
import config from '../../../../static/themes/config';
import {GamesContext} from "../../slots-and-casinos";
import {API_PREFIX} from "../../../../common/api/api-url-config";
import apiUrls from "../../../../common/api/api-urls";


const SlotsCasinosGamesMenu = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
        siteName: store.site.siteName,
        slotsCasinoGames: store.slotsCasinoGames,
        casinoFiltersLoaded: store.slotsCasinoGames.casinoFiltersLoaded,
        slotsFiltersLoaded: store.slotsCasinoGames.slotsFiltersLoaded,
        isLandscapeOrientation: store.site.status.isLandscapeOrientation,
        presetFilters: store.slotsCasinoGames.presetFilters,
        jackpotGroupsList: store.slotsCasinoGames.jackpotGroupsList,
        router: store.router,
        collectActivity: store.user.collectActivity,
        isLogged: store.user.isLogged,
    }),
    PropTypes: {
        gamesType: PropTypes.oneOf(['slots', 'casino']).isRequired,
        slotsCasinoGames: PropTypes.object.isRequired,
        setSorting: PropTypes.func.isRequired,
        resetFiltering: PropTypes.func.isRequired,
        menuState: PropTypes.object.isRequired,
        gamesMenu: PropTypes.object.isRequired,
    },
    defaultName: 'SlotsCasinosGamesMenu',
    isTrans: true,
    isObserver: true,
})((
    {
        trans,
        gamesType,
        resetFiltering,
        slotsCasinoGames,
        jackpotGroupsList,
        presetFilters: {currentView, activeProvider, activeType},
        currentSorting,
        setSorting,
        gamesMenu,
        viewSize,
        siteName,
        router,
        activeGameMenuItem,
        setActiveGameMenuItem,
        setActiveSubSubMenuItem,
        isSubMenuVisible,
        setSubMenuVisible,
        isLandscapeOrientation,
        collectActivity,
        isLogged,
    }
) => {
    const [isMobileSubSubMenuVisible, setMobileSubSubMenuVisible] = useState(false);
    const [activeProviderItem, setActiveProviderItem] = useState(
        slotsCasinoGames.getProvider(gamesType, activeProvider)
    );

    const {mode, setMode} = useContext(GamesContext) ?? {};

    const gamesUpperMenu =
        viewSize === 'mobile'
            ? config(siteName).slotsCasinoMobileUpperMenu
            : config(siteName).slotsCasinoUpperMenu;

    /************* LISTENER TO SET activeProviderIcon **************/
    useEffect(() => {
        setActiveProviderItem(
            slotsCasinoGames.getProvider(gamesType, activeProvider)
        );
    }, [activeProvider]);

    useEffect(() => {
        setActiveSubSubMenuItem('');
        setMobileSubSubMenuVisible(false);
    }, [viewSize]);

    const createMenuItemConfig = (item) => {
        const itemAttributes = {...item.elementAttributes};
        if (itemAttributes?.to) {
            itemAttributes.to = '/' + gamesType + itemAttributes.to;
        }
        const subConfig =
            typeof item.submenu === 'string'
                ? slotsCasinoGames[item.type + 'List']({
                    type: gamesType,
                    provider: activeProvider,
                    GameTypeName: activeType
                })
                : item.submenu;
        const submenu = subConfig?.reduce((acc, item) => {
            const elementAttributes = {};
            if (item.link) {
                let link = item.link;
                if (link.includes('provider') && activeType) {
                    if (activeProvider === item.name) {
                        link = `/${gamesType}/gameType=${activeType}`;
                    } else {
                        link = link + '.gameType=' + activeType;
                    }
                } else if (link.includes('gameType') && activeProvider) {
                    if (activeType === item.name) {
                        link = `/${gamesType}/provider=${activeProvider}`;
                    } else {
                        link = link + '.provider=' + activeProvider;
                    }
                } else {
                    link = (activeProvider === item.name || activeType === item.name) ? `/${gamesType}` : link;
                }
                elementAttributes.as = 'NavLink';
                elementAttributes.to = link;
            }
            return [
                ...acc,
                {
                    ...item,
                    elementAttributes
                }
            ];
        }, []);
        return {
            itemAttributes,
            submenu
        };
    }

    const isItemActive = ({item, activeProvider, currentSorting}) => {
        return item.type ?
            (activeProvider === item.name && item.type === 'provider')
            ||
            (activeType === item.name && item.type === 'gameType') ?
                '--active'
                : ''
            : JSON.stringify(item.sort) === JSON.stringify(currentSorting)
                ? '--active'
                : '';
    };

    /***************** RENDER ITEMS ****************/
    const renderHeaderMenuItem = (item) => {
        const itemConfig = createMenuItemConfig(item);

        if (!isLogged && ["recently-played", "favorite"].includes(item.type)) {
            return null;
        }

        if ((gamesType === 'casino' || !jackpotGroupsList.length) && ["jackpots"].includes(item.type)) {
            return null;
        }

        const submenu = itemConfig.submenu?.map((item, i) => {
            return (
                <LLElementTypeSetter
                    {...item.elementAttributes}
                    key={i}
                    className={`menu-item sub-item${
                        isItemActive({item, activeProvider, currentSorting})
                    }`}
                    onClick={() => setSubMenuVisible(false)}
                >
                    {item.icon ? (
                        <i className={`${item.icon} menu-item__icon`}/>
                    ) : item.hasImage ? (
                        <img
                            className="menu-item__img"
                            src={item.imgLink}
                            alt="provider"
                        />
                    ) : (
                        <i className="llg-question slots-casino-menu__provider-without-img"/>
                    )}
                    <span className="menu-item__label">{trans(item.name)}</span>
                </LLElementTypeSetter>
            );
        });

        return (
            <LLMenuItem
                customClasses={`slots-casino-menu__item${
                    currentView.includes(item.type) ?
                        '--active'
                        :
                        item.type === 'gameType' && !slotsCasinoGames.providerHasSubGameGroups({
                            type: gamesType,
                            provider: activeProvider
                        }) ? '--disabled' : ''
                }`}
                elementAttributes={itemConfig.itemAttributes}
                item={item}
                key={item.type}
                id={item.type}
                triggeredOnHover={false}
                activeIfOpened={true}
                triggeredOnClick={submenu?.length}
                submenu={submenu}
                subItemCloseTrigger={isSubMenuVisible}
                resetSubItemTrigger={() => setSubMenuVisible(false)}
                subItemClasses="slots-casino-menu__sub-items"
                icon={
                    item.type === 'provider' && activeProviderItem?.hasImage ? (
                        <img
                            className="menu-item__img"
                            src={activeProviderItem.imgLink}
                            alt="provider"
                        />
                    ) : (
                        <i className={`${item.icon} menu-item__icon`}/>
                    )
                }
                label={
                    <>
                        {item.type === 'provider' && activeProvider
                            ? trans(activeProvider)
                            : item.type === 'gameType' && activeType
                                ? activeType
                                : trans(item.name)}
                    </>
                }
            />
        );
    };

    const resetCurrentSorting = () => {
        setSorting();
    };

    /***************** RENDER UPPER MENU ITEM ****************/

    const renderUpperMenuItem = (item) => {
        const submenu = item.submenu.map((subItem) => (
                !(gamesType === 'casino' && item.type === 'playMode') &&
                !(subItem.name === 'Popular' && currentView.includes('favorite'))) &&
            (
                <LLElementTypeSetter
                    {...subItem.elementAttributes}
                    key={subItem.name}
                    className={`menu-item sub-item${
                        JSON.stringify(subItem.sort) ===
                        JSON.stringify(currentSorting)
                            ? '--active'
                            : ''
                    }`}
                    onClick={() => {
                        if (item.type === 'sortBy') {
                            if (
                                JSON.stringify(subItem.sort) ===
                                JSON.stringify(currentSorting)
                            ) {
                                resetCurrentSorting();
                            } else {
                                setSorting(subItem.sort);
                            }
                        } else if (item.type === 'playMode') {
                            (subItem.demo || subItem.demo === 0) &&
                            setMode(subItem.demo);
                        }
                    }}
                >
                    <i className={`${subItem.icon} menu-item__icon`}/>
                    <span className="menu-item__label">
                            {trans(subItem.name)}
                        </span>
                </LLElementTypeSetter>
            )
        );

        return (
            <LLMenuItem
                customClasses={`slots-casino-upper-menu__item slots-casino-menu__item${
                    item.type === 'playMode' ? '-start' : ''
                }${
                    !!item.submenu.find(
                        (c) =>
                            JSON.stringify(c.sort) === JSON.stringify(currentSorting)
                    )
                        ? '--active'
                        : ''
                }`}
                item={item}
                key={item.type}
                placement={item.type === 'playMode' ? 'bottom-start' : 'bottom-end'}
                triggeredOnClick={
                    !(item.type === 'playMode' && gamesType === 'casino') &&
                    (!currentView.includes('new') &&
                        !currentView.includes('popular') || item.type === 'playMode')
                }
                triggeredOnHover={false}
                activeIfOpened={true}
                submenu={submenu}
                subItemInParent={viewSize === 'mobile'}
                subItemClasses="header-upper-menu__sub-items"
                subItemCloseTrigger={activeGameMenuItem === item.type}
                resetSubItemTrigger={() => setActiveGameMenuItem('')}
                onClick={() => {
                    if (
                        ((currentView.includes(['popular', 'recently-played']) ||
                            currentView.includes('new')) && item.type !== 'sortBy') &&
                        (item.type !== 'playMode' ||
                            (item.type === 'playMode' && gamesType === 'slots'))
                    ) {
                        activeGameMenuItem === item.type
                            ? setActiveGameMenuItem('')
                            : setActiveGameMenuItem(item.type);
                    }
                }}
                icon={
                    item.type === 'playMode' ? (
                        <i
                            className={`${
                                item.submenu.find((s) => s.demo === mode).icon
                            } menu-item__icon`}
                        />
                    ) : item.type === 'sortBy' &&
                    item.submenu.find(
                        (s) =>
                            JSON.stringify(s.sort) ===
                            JSON.stringify(currentSorting)
                    ) ? (
                        <i
                            className={`${
                                item.submenu.find(
                                    (s) =>
                                        JSON.stringify(s.sort) ===
                                        JSON.stringify(currentSorting)
                                ).icon
                            } menu-item__icon`}
                        />
                    ) : (
                        <i className={`${item.icon} menu-item__icon`}/>
                    )
                }
                label={
                    <>
                        {item.type === 'playMode'
                            ? !mode
                                ? trans('REAL_PLAY_BTN')
                                : trans('FUN_PLAY_BTN')
                            : item.type === 'sortBy' &&
                            item.submenu.find(
                                (s) =>
                                    JSON.stringify(s.sort) ===
                                    JSON.stringify(currentSorting)
                            )
                                ? trans(
                                    item.submenu.find(
                                        (s) =>
                                            JSON.stringify(s.sort) ===
                                            JSON.stringify(currentSorting)
                                    ).name
                                )
                                : trans(item.name)}
                    </>
                }
            />
        );
    };

    /***************** RENDER ****************/
    return (
        slotsCasinoGames[gamesType + 'FiltersLoaded'] && (
            <div className={`games__header-menu-container`}>
                <div className={`slots-casino-menu-wrapper${viewSize === 'tablet' ? ' games-menu--tablet' : ''}`}>
                    {viewSize === 'mobile' ? (
                        <SlotCasinoMobileMenu
                            siteName={siteName}
                            createMenuItemConfig={createMenuItemConfig}
                            isMobileSubSubMenuVisible={isMobileSubSubMenuVisible}
                            setMobileSubSubMenuVisible={setMobileSubSubMenuVisible}
                            activeProvider={activeProvider}
                            isItemActive={isItemActive}
                            currentView={currentView}
                            activeType={activeType}
                            slotsCasinoGames={slotsCasinoGames}
                            collectActivity={collectActivity}
                            isLogged={isLogged}
                            jackpotGroupsList={jackpotGroupsList}
                        />
                    ) : viewSize === 'tablet' ? (
                        <>
                            <LLElementTypeSetter
                                className="games-menu__btn"
                                href={`${API_PREFIX}${apiUrls.games.random}?type=${(router.location.pathname.includes('casino') ? "casino" : "slot")}&demo=0`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                    collectActivity({
                                        area: `${gamesType}-random-button`,
                                        itemId: 1,
                                        interactTarget: 'random-button'
                                    });
                                }}
                            >
                                <i className="menu-item__icon doruk-random-arrows"/>
                                <span>{trans('Random Game')}</span>
                            </LLElementTypeSetter>
                            <div className="games__header-menu">
                                <LLTextSlider
                                    id="slots-casino-menu-slider"
                                    viewSize={viewSize}
                                    isLandscapeOrientation={isLandscapeOrientation}
                                    customClasses={{
                                        slider: 'slots-casino-menu-slider',
                                    }}
                                    activeItem={currentView}
                                    slidesToShow={3}
                                    responsive={[
                                        {
                                            minSize: 1024,
                                            slidesToShow: 5,
                                        },
                                    ]}
                                >
                                    {gamesMenu.map((item) =>
                                        renderHeaderMenuItem(item)
                                    )}
                                </LLTextSlider>
                            </div>
                            {gamesUpperMenu.map(
                                (item) =>
                                    (!item.position.mobileOnly ||
                                        viewSize === 'tablet') &&
                                    renderUpperMenuItem(item)
                            )}
                            <SlotsCasinosGamesSearch
                                gamesType={gamesType}
                                resetFiltering={resetFiltering}
                                resetSorting={resetCurrentSorting}
                            />
                        </>
                    ) : (
                        <div className={`games__header-menu`}>
                            <Link to={'?launch=random'}
                                  className="games-menu__btn"
                                  onClick={() => {
                                      collectActivity({
                                          area: `${gamesType}-random-button`,
                                          itemId: 1,
                                          interactTarget: 'random-button'
                                      });
                                  }}
                            >
                                <i className="menu-item__icon doruk-random-arrows"/>
                                <span>{trans('Random Game')}</span>
                            </Link>
                            <LLTextSlider
                                id="slots-casino-menu-slider"
                                viewSize={viewSize}
                                customClasses={{slider: 'slots-casino-menu-slider',}}
                                activeItem={currentView}
                            >
                                {gamesMenu.map((item) => renderHeaderMenuItem(item))}
                                {gamesUpperMenu.map((item) => !item.position.mobileOnly &&
                                    renderUpperMenuItem(item)
                                )}
                            </LLTextSlider>
                            <SlotsCasinosGamesSearch
                                gamesType={gamesType}
                                resetFiltering={resetFiltering}
                                resetSorting={resetCurrentSorting}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    );
});

export default SlotsCasinosGamesMenu;
