import React from 'react';
import PropTypes from 'prop-types';

import {LLBtn, withCommon} from '../../../components';

/**
 * @file Represents React Component to render buttons that open modals
 * @memberOf PromoCards
 *
 * @param {number} id - id of selected bonus
 * @param {func} openModal - open modal function / params { id, type }: id - selected bonus id, type - type of modal
 *
 * @author I.Mykhailychenko
 */

const PromoButtons = withCommon({
    store: ({store}) => ({
        openModal: store.promos.openModal,
        viewSize: store.site.status.viewSize,
        mobileUserAgent: store.user.mobileUserAgent,
    }),
    propTypes: {
        id: PropTypes.number.isRequired,
        rules: PropTypes.string,
        openModal: PropTypes.func.isRequired,
    },
    defaultProps: {
        rules: null,
    },
    defaultName: 'PromoButtons',
    isObserver: true,
    isTrans: true,
})(({trans, id, rules, isRequestEnabled, openModal, viewSize}) => {
    const handleRulesModal = () => {
        openModal(id, 'rules');
    };

    const handleDescrModal = () => {
        openModal(id, 'descr');
    };

    return (
        <div className="promo-content__btn-wrp">
            {isRequestEnabled ? (
                    <LLBtn
                        customClasses={`promo-content__btn ll-btn--square--${viewSize}`}
                        onClick={handleDescrModal}
                    >
                        {trans('Get Bonus')}
                    </LLBtn>)
                : null
            }

            {(rules || !isRequestEnabled) ?
                <LLBtn
                    customClasses={`promo-content__btn ll-btn--square-outlined--${viewSize}`}
                    onClick={() => rules && handleRulesModal()}
                >
                    {rules && trans('Bonus Rules')}
                </LLBtn>
                : null
            }
        </div>
    );
});

export default PromoButtons;
