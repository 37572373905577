import {useEffect} from 'react';
import {inject, observer} from "mobx-react";

import {API_PREFIX} from "../../common/api/api-url-config";
import apiUrls from "../../common/api/api-urls";
import {LLPreloader} from '../../components';
import LobbyItem from './components/lobby-item';
import {trans} from "../../common/utils";
import config from "../../static/themes/config";

const Lobby = ({store}) => {
    const viewSize = store.site.status.viewSize,
        getLobbies = store.lobbies.getLobbies,
        providerId = store.lobbies.providerId,
        siteName = store.site.siteName,
        loading = store.lobbies.loading,
        lobbies = store.lobbies.items;

    const forbiddenLobbyIds = config(siteName).forbiddenLobbyIds ?? [];

    useEffect(() => {
        getLobbies();
    }, []);

    return (
        <div className={`ll-page lobby-page lobby-page--${viewSize}`}>
            {loading ? (
                <div className="lobby-page--loader-container">
                    <LLPreloader/>
                </div>
            ) : (providerId ? (
                <iframe
                    className="lobby-page__iframe"
                    title="lobby"
                    src={`${API_PREFIX}${apiUrls.games.openGame}?providerId=${providerId}`}
                    allowFullScreen
                />
            ) : (
                <div className="lobby-page__body">
                    {lobbies.length ? lobbies.map((lobby) => (
                            !forbiddenLobbyIds.includes(lobby.providerId) ?
                                <LobbyItem
                                    key={lobby.lobbyId}
                                    lobby={lobby}
                                    viewSize={viewSize}
                                />
                                : null
                        ))
                        : <p className="lobby-page__body--empty">{trans('No Lobbies for now')}</p>}
                </div>
            ))}
        </div>
    );
};

export default inject('store')(observer(Lobby));
