import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {withCommon} from '../../../components/HOCs';
import {LLElementTypeSetter} from '../../../components';


const LicenseIcons = withCommon({
    store: ({store}) => ({
        closeDrawer: store.drawer.close,
        setModal: store.modal.setModal,
        language: store.user.language,
        siteName: store.site.siteName,
        modifiedConfig: store.modifiedConfig,
    }),
    propTypes: {
        classSuffix: PropTypes.string,
        closeDrawer: PropTypes.func,
        licenseIcons: PropTypes.arrayOf(PropTypes.object).isRequired,
        setModal: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
    },
    isObserver: true,
})(({classSuffix = '', closeDrawer, licenseIcons, setModal, language, siteName, modifiedConfig}) => {
    const openModal = (link, customClasses) => {
        setModal({
            active: true,
            externalLink: link,
            currentKey: 'external-link',
            showFullscreenButton: true,
            customClasses: `external-link ${customClasses}`,
            showCloseButton: true,
        });
    };

    const [sealId] = useState(licenseIcons.map((iconProps) => iconProps.sealId)[0] || null)

    useEffect(() => {
        if (sealId) {
            let script = document.createElement('script');
            script.id = 'script-license';
            script.async = true;
            script.src = 'https://validator.pagcorlicence.com/js/' + sealId;
            document.body.appendChild(script);
        }
    }, [])

    return (
        <div className="ll-license-icons">
            {licenseIcons.map((iconProps, i) => {
                if (
                    iconProps.elementAttributes?.href &&
                    typeof iconProps.elementAttributes?.href === 'object' &&
                    iconProps.needLang
                ) {
                    iconProps.elementAttributes.href =
                        iconProps.elementAttributes.href[language];
                }
                return (iconProps.sealId ?
                        siteName === 'Betaestas' ?
                            <div key={i} className="ll-license-curacao">
                                <div id={`ceg-${iconProps.sealId}`} data-ceg-seal-id={iconProps.sealId}
                                     data-ceg-image-size="128" data-ceg-image-type="basic-small"/>
                                <a href="https://verification.curacao-egaming.com/validateview.aspx?domain=betaestas.com"
                                   target="_blank">
                                    <img
                                        src="https://verification.curacao-egaming.com/validate.ashx?domain=betaestas.com"
                                        alt="license"/>
                                </a>
                            </div>
                            :
                            <a className="license-img"
                               key={i}
                               href={modifiedConfig.pagcorLink}
                               target="_blank"
                            >
                                <img src={modifiedConfig.pagcorImgSrc} alt="Logo" width="100" />
                            </a>
                        :
                        <LLElementTypeSetter
                            className="ll-license-icons__link"
                            key={i}
                            onClick={() => {
                                closeDrawer();
                                iconProps.modalLink &&
                                openModal(
                                    iconProps.modalLink
                                        ? iconProps.needLang
                                        ? iconProps.modalLink[language]
                                        : iconProps.modalLink
                                        : null,
                                    iconProps.customClasses ?? null
                                );
                            }}
                            {...iconProps.elementAttributes}
                        >
                            <i
                                className={`${iconProps.icon}-icon ll-license-icons__icon${classSuffix}`}
                            />
                        </LLElementTypeSetter>
                );
            })}
        </div>
    );
});

export default LicenseIcons;
