import React, {useState, useEffect, useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import ReactGA from "react-ga4";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

import LLRouter from './common/router/router';
import {LLErrorVisualizer, LLPreloader} from './components';
import {errorLogger} from './common/utils';
import {TIMERS} from "./store/config";
import './common/utils/polyfills';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/scss/fonts.scss';
import './styles/scss/doruk-icons.scss';
import './App.scss';
import './styles/scss/sport_icon_font.scss';
import config from "./static/themes/config";


function App({store}) {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (['-dev'].find(el => window.location.href.includes(el))) {
            window.addEventListener("error", ({message, error, target: {location: location}}) => {
                errorLogger({errorObj: {message: message, error: error, location: location.href}});
            });
        }
        Promise.all([store.site.getConfig(), store.user.getSessionInfo()]).finally(
            () => {
                setLoading(false);
                if (!store.user.mobileUserAgent) {
                    document.body.classList.add('body--with-cursor');
                    if (document.body.clientWidth < 1367) {
                        document.body.classList.add('body--small-desktop');
                    }
                }
                const urlSearchPart = store.router.location?.search;
                if (urlSearchPart && urlSearchPart.includes('btag=')) {
                    const btag = urlSearchPart.substring(urlSearchPart.lastIndexOf('=') + 1, urlSearchPart.length);
                    store.user.setBTag(btag);
                }
                if (
                    !['localhost', '-dev', '-int', '-stg'].find(el => window.location.href.includes(el)) &&
                    config(store.site.siteName).withGoogleAnalytics
                ) {
                    ReactGA.initialize('G-JFL9YRQFW5');
                }
            }
        );
    }, []);

    useEffect(() => {
        if ((store.site.status.isGlobalError || !!store.betting.bettingErrors.global) && store.site.status.isActive && !isLoading) {
            window.location.reload();
        } else if (store.site.status.isActive && store.user.userBattleId) {
            clearTimeout(window._userBattleUpdateInterval);
            window._userBattleUpdateInterval = setTimeout(() => {
                store.user.setUserBattleUpdate(store.user.userBattleId);
            }, TIMERS.refreshBattleDataFetchTime);
        }
    }, [store.site.status.isActive]);

    useEffect(() => {
        document.addEventListener(
            'visibilitychange',
            store.site.status.checkPageActivity
        );
        return () =>
            document.removeEventListener(
                'visibilitychange',
                store.site.status.checkPageActivity
            );
    }, []);

    useEffect(() => {
        document.addEventListener(
            'closePopupEvt',
            store.modal.close
        )
    }, []);

    const renderApp = () =>
        isLoading ? null : store.site.status.isGlobalError ? (
            <div className="global-error">
                <LLErrorVisualizer
                    error={store.site.status.globalErrorText || "TECHNICAL_ISSUES_ERROR"}
                    btnText="Ok"
                    withoutButton={!!store.site.status.globalErrorText}
                    isSiteGlobal={true}
                />
            </div>
        ) : (
            store.site.captchaV3 ? (
                <GoogleReCaptchaProvider reCaptchaKey={store.site.captchaV3}>
                    <LLRouter/>
                </GoogleReCaptchaProvider>
            ) : <LLRouter/>
        );

    return (
        <>
            <LLPreloader fullPage visible={isLoading} height={50} width={50}/>
            {useMemo(() => renderApp(), [store.site.status.isGlobalError, isLoading])}
        </>
    );
}

export default inject('store')(observer(App));
