import React from 'react';
import Slide from '@mui/material/Slide';

import { LLBtn2 } from '../index';
import { trans } from '../../../common/utils';

const LLErrorVisualizer = ({
    error,
    title,
    subMessage = null,
    onClick = () => {},
    btnText = 'Return to homepage',
    withoutButton = false,
}) => {
    const errorHeader = () => (
        <div className="ll-error-visualizer__header">
            { title ?
                <p>{trans(title)}</p>
                :
                error === 'ERROR_404' ? (
                    <p>{trans('ERROR_404_HEAD')}</p>
                ) : (
                    <p>{trans('Error')}</p>
                )
            }
        </div>
    );

    return (
        <Slide direction="up" in={true}>
            <div className="ll-error-visualizer">
                {errorHeader()}
                <div className="ll-error-visualizer__container">
                    <p>{trans(error)}</p>
                    {subMessage && <p>{subMessage}</p>}
                    {!withoutButton ? (
                        <div className="ll-error-visualizer__btn">
                            <LLBtn2 onClick={onClick}>
                                <a href="/">{trans(btnText)}</a>
                            </LLBtn2>
                        </div>
                    ) : null}
                </div>
            </div>
        </Slide>
    );
};

export default LLErrorVisualizer;
