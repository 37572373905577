import React, { useEffect, useState } from 'react';
import {
    CompareRule,
    CustomRule,
    PatternRule,
    RequiredRule,
    Validator,
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import PropTypes from 'prop-types';

import { LLInput, withCommon, llNotifier, LLBtn } from '../../index';
import { passwordStrength } from '../../../common/utils';
import { PasswordStrengthBar } from '../../elements';

/**
 * @file Represents Change Password Form
 * @memberOf LLModal, ChangePassword
 *
 * @param {boolean} isLogged - flag is user logged
 * @param {func} modal - modal data
 * @param {boolean} mobileUserAgent - detect mobile
 * @param {func} changePassword - send old and new passwort to
 * @param {func} history - router history
 * @param {func} close - function to close modal
 *
 * @author S.Hakhodov feat. A. Green
 */

const ChangePasswordForm = withCommon({
    store: ({ store }) => ({
        changePassword: store.user.changePassword,
        isLogged: store.user.isLogged,
        viewSize: store.site.status.viewSize,
        close: store.modal.close,
    }),
    PropTypes: {
        changePassword: PropTypes.func.isRequired,
        isLogged: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    },
    isRouter: true,
    isTrans: true,
    isObserver: true,
})(({ changePassword, isLogged, viewSize, trans, close, history }) => {
    const [form, setForm] = useState({
            oldPassword: '',
            password: '',
            passwordRepeat: '',
            pwdStrength: 0,
        }),
        [serverResponseErrors, setServerResponseErrors] = useState({});

    useEffect(() => {
        if (!isLogged) {
            close(history.push('/'));
        }
    }, [isLogged]);

    const serverValidationCheck = (fieldName) => !serverResponseErrors[fieldName];

    const clearServerError = (type) => {
        const tempObj = { ...serverResponseErrors };
        if (tempObj[type]) {
            delete tempObj[type];
            setServerResponseErrors(tempObj);
        }
    };

    const submitForm = () => {
        changePassword({
            oldPassword: form.oldPassword,
            password: form.password,
            passwordRepeat: form.passwordRepeat,
        }).then((res) => {
            try {
                if (res.success) {
                    llNotifier({
                        message: 'Password changed successfully',
                        type: 'success',
                    });
                } else {
                    switch (res.data.error) {
                        case 'WRONG_REPEATED':
                            setServerResponseErrors({
                                passwordRepeat: res.data.error,
                            });
                            break;
                        case 'WRONG_OLD_PASSWORD':
                            setServerResponseErrors({
                                oldPassword: res.data.error,
                            });
                            break;
                        case 'NEW_PASSWORD_EQUALS_OLD':
                        case 'PASSWORD_EMPTY':
                        case 'PASSWORD_WRONG_FORMAT':
                            setServerResponseErrors({
                                password: res.data.error,
                            });
                            break;
                        default:
                            llNotifier({
                                message: res.data.error,
                                type: 'error',
                            });
                            break;
                    }
                }
            } catch {
                llNotifier({
                    message: 'GENERAL_ERROR',
                    type: 'error',
                });
            }
        });
    };

    const commonInputProps = {
        labelPosition: viewSize === 'mobile' ? 'inside' : 'top',
        showClearButton: true,
        stylingMode: 'outlined',
    };

    return (
        <div className="change-password">
            {viewSize !== 'desktop' ? (
                <p className="change-password__header">
                    {trans('Fill the form please')}
                </p>
            ) : null}
            <ValidationGroup className="change-password__form-container">
                <LLInput
                    {...commonInputProps}
                    label={trans('Old password')}
                    maxLength={100}
                    value={form.oldPassword}
                    id="change-password__old-password"
                    name="change-password__old-password"
                    mode="password"
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            oldPassword: e.value,
                        });
                        clearServerError('oldPassword');
                    }}
                >
                    <Validator>
                        <PatternRule
                            message={trans(
                                'You have entered incorrect character in password'
                            )}
                            pattern={
                                '^[a-zA-ZğüşöçİĞÜŞÖÇ0-9~!@#$%^*)(?_\\-\\\\.,\';<>:"/]*$'
                            }
                        />
                        <RequiredRule message={trans('WRONG_OLD_PASSWORD')} />
                        <CustomRule
                            message={trans(serverResponseErrors['oldPassword'])}
                            validationCallback={() =>
                                serverValidationCheck('oldPassword')
                            }
                        />
                    </Validator>
                </LLInput>
                <LLInput
                    {...commonInputProps}
                    label={trans('New password')}
                    maxLength={100}
                    value={form.password}
                    id="change-password__new-password"
                    name="change-password__new-password"
                    mode="password"
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            password: e.value,
                            pwdStrength: passwordStrength(e.value),
                        });
                        clearServerError('password');
                    }}
                >
                    <Validator>
                        <PatternRule
                            message={trans(
                                'You have entered incorrect character in password'
                            )}
                            pattern={
                                '^[a-zA-ZğüşöçİĞÜŞÖÇ0-9~!@#$%^*)(?_\\-\\\\.,\';<>:"/]*$'
                            }
                        />
                        <RequiredRule message={trans('This field is required')} />
                        <CustomRule
                            message={trans(
                                'PASSWORD_SAME_AS_OLDPASSWORD'
                            )}
                            validationCallback={() => form.oldPassword !== form.password}
                        />
                        <CustomRule
                            message={trans(
                                'At least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters required'
                            )}
                            validationCallback={(e) => passwordStrength(e.value) === 100}
                        />
                        <CustomRule
                            message={trans(serverResponseErrors['password'])}
                            validationCallback={() => serverValidationCheck('password')}
                        />
                    </Validator>
                </LLInput>
                <LLInput
                    {...commonInputProps}
                    label={trans('Repeat new password')}
                    maxLength={100}
                    value={form.passwordRepeat}
                    onPaste={(e) => e.event.preventDefault()}
                    id="change-password__repeat-new-password"
                    name="change-password__repeat-new-password"
                    mode="password"
                    onValueChanged={(e) => {
                        setForm({
                            ...form,
                            passwordRepeat: e.value,
                        });
                        clearServerError('passwordRepeat');
                    }}
                >
                    <Validator>
                        <RequiredRule message={trans('WRONG_OLD_PASSWORD')} />
                        <CompareRule
                            message="WRONG_REPEATED"
                            comparisonTarget={() => form.password}
                        />
                        <CustomRule
                            message={trans(serverResponseErrors['passwordRepeat'])}
                            validationCallback={() =>
                                serverValidationCheck('passwordRepeat')
                            }
                        />
                    </Validator>
                </LLInput>
                <PasswordStrengthBar form={form} setForm={setForm} />
                <div className="change-password__submit-btn-box">
                    <LLBtn
                        className={`change-password__submit-btn${
                            viewSize !== 'desktop' ? '--mobile' : ''
                        }`}
                        onClick={(e) => {
                            let result = e.validationGroup.validate();
                            if (result.isValid) {
                                submitForm();
                            }
                        }}
                    >
                        {trans('Submit')}
                    </LLBtn>
                    {viewSize === 'desktop' ? (
                        <LLBtn
                            className="change-password__submit-btn ll-btn--outlined"
                            onClick={() => close()}
                        >
                            {trans('Cancel')}
                        </LLBtn>
                    ) : null}
                </div>
            </ValidationGroup>
        </div>
    );
});

export default ChangePasswordForm;
