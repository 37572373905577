import {currencySymbols} from '../../configs';

/**
 * @file Function that return reformated given number
 *
 * @param {(number | string)} amount - amount of money to format
 * @param {number} digits - how many digits after fraction separator
 * @param {string} locale - locale for type of fraction separator
 * @param {string} currency - if this is amount of money and we need currency
 * @param {boolean} isArabicInput - stupid arabic coupon amount input issue crutch
 *
 * @author A.Huskova
 */

export function formatNumber(amount, digits, locale, currency = '', isArabicInput = false) {
    return (Number(typeof amount === 'number') && !isArabicInput)
        ? `${new Intl.NumberFormat(locale, {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        }).format(Number(amount))}${currency ? ' ' + currencySymbols[currency] : ''}`
        : amount ? amount.toString()
            : amount;
}

export function reverseFormatNumber(val) {
    if (val) {
        let reversedVal = val.replace(/,/g, '.');
        const reversedArr = reversedVal.split('.');
        if (reversedArr.length > 1) {
            reversedVal = '';
            reversedArr.forEach((item, i) => {
                reversedVal += (i === reversedArr.length - 1) ? '.' + item : item;
            })
        }
        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } else {
        return 0;
    }
}

export function betOddsFormat(val, language, currency) {
    return val >= 100 ?
        formatNumber(val, 0, language, currency)
        :
        formatNumber(val, 2, language, currency)
}
