import React, {useCallback, useEffect, useRef, useState} from 'react';
import {throttle} from "lodash";
import cx from 'classnames';

import {WithDropdown, WithPopover, LLElementTypeSetter} from '../../';
import LLWidgetSportBets from "./widget-bets";
import {isCombinedMatch} from "../../../common/utils";
import {urls} from "../../../configs";
import LLWidgetHeader from "./components/widget-header";
import LLWidgetCompetitors from "./components/widget-competitors";
import LLWidgetBetsNames from "./components/widget-bets-names";


const LLWidgetSportItem = ({event, betting, widget, viewSize, isLandscapeOrientation}) => {
    const featuredMarket = widget.featuredMarket(event) ?? {};
    const isBetBuilder = !!event.betBuilderOdds;
    const withDropdown = featuredMarket?.betsList?.length > 3;
    const withMarket = !isCombinedMatch(event.id);
    const shouldHandleResize = withDropdown || isBetBuilder;

    const [activeSubMarket, setActiveSubMarket] = useState(
        featuredMarket.specifierBasedMarkets?.length ?
            featuredMarket.specifierBasedMarkets[0]
            :
            null
    );

    const [dropdownWidth, setDropdownWidth] = useState(0);
    const [widgetItemWidth, setWidgetItemWidth] = useState(0);

    const homeLogo = urls.prematchCompetitorImg + event?.competitor1.id + '.png';
    const awayLogo = urls.prematchCompetitorImg + event?.competitor2.id + '.png';

    const dropdownRef = useRef();

    const resizeCallback = useCallback(throttle(() => {
        setTimeout(() => {
            setDropdownWidth(
                (dropdownRef.current?.getBoundingClientRect().width) || 250
            );
            setWidgetItemWidth(
                document.querySelector('.js-ll-widget-item__market-dropdown')?.getBoundingClientRect().width || 250
            );
        }, 50);
    }, 1000), []);

    useEffect(() => {
        if (shouldHandleResize) {
            window.addEventListener('resize', resizeCallback);
            return () => {
                window.removeEventListener('resize', resizeCallback);
            }
        }
    }, [shouldHandleResize, resizeCallback]);

    useEffect(() => {
        if (shouldHandleResize) {
            resizeCallback();
        }
    }, [isLandscapeOrientation, shouldHandleResize, resizeCallback]);

    const bets = isBetBuilder ?
        widget.betBuilderBets(event)
        :
        activeSubMarket ?
            activeSubMarket.betsList
            :
            featuredMarket.betsList;

    const isWinnerMarket = (featuredMarket.id === 1 || featuredMarket.id === 186);

    return (event && bets?.length) && (
        <div
            className={cx(
                'll-widget-item js__activity-area-item',
                viewSize && `ll-widget-item--${viewSize}`,
                withMarket && 'll-widget-item--with-market',
                !withDropdown && 'll-widget-item--with-bets',
                isCombinedMatch(event.id) && 'll-widget-item--superodds',
                isBetBuilder && 'll-widget-item--bet-builder',
                withDropdown && 'll-widget-item--select',
            )}
            data-area-item-id={event.slideId}
        >
            {isCombinedMatch(event.id) && <i className={'ll-widget-item__widget-bg doruk-rocket1'}/>}
            {(isWinnerMarket || (!isBetBuilder && !withDropdown && !isCombinedMatch(event.id))) &&
                <i className={`ll-widget-item__widget-bg sport_icon_font-${event.sportId}`}/>}

            <LLElementTypeSetter
                className="ll-widget-item__top-box"
                data-interact-target={event.id}
                to={isCombinedMatch(event.id) ? null : event.matchUrl(betting.branch.id)}
            >
                <LLWidgetHeader
                    event={event}
                    betting={betting}
                />
                <LLWidgetCompetitors
                    event={event}
                    homeLogo={homeLogo}
                    awayLogo={awayLogo}
                    isWinnerMarket={isWinnerMarket}
                />
            </LLElementTypeSetter>

            <div className="ll-widget-item__bets-content">
                {withMarket &&
                    <div className={cx('ll-widget-item__market-name',
                        (!isBetBuilder && !withDropdown && !isCombinedMatch(event.id)) && "ll-widget-item__market-name--top")}>
                        {featuredMarket.specifierBasedMarkets?.length ?
                            featuredMarket.specifierBasedMarkets?.length > 1 ?
                                <>
                                    <WithDropdown
                                        dataSource={{
                                            submenu: featuredMarket.specifierBasedMarkets.map(market => ({
                                                label: market.name,
                                                element: (
                                                    <p className="ll-widget-item-dropdown-content__bet">
                                                    <span className="ll-widget-item-dropdown-content__bet-name">
                                                        {market.name}
                                                    </span>
                                                    </p>
                                                )
                                            }))
                                        }}
                                        menuItemIsActive={(item) => item?.label === activeSubMarket?.name}
                                        onMenuItemClick={(item) => setActiveSubMarket(featuredMarket.specifierBasedMarkets.find((market) => market.name === item.label))}
                                        openBehavior="click"
                                        customClasses="ll-widget-item__market-dropdown js-ll-widget-item__market-dropdown"
                                        menuClasses={cx(
                                            "ll-widget-item-dropdown-content",
                                            `ll-widget-item-dropdown-content--${viewSize}`
                                        )}
                                        disablePortal={false}
                                        popoverWidth={widgetItemWidth}
                                    >
                                        <WithPopover>
                                            <span>{activeSubMarket?.name}</span>
                                        </WithPopover>
                                    </WithDropdown>
                                </>
                                :
                                <WithPopover>
                                <span>
                                    {activeSubMarket?.name}
                                </span>
                                </WithPopover>
                            :
                            <>
                                <WithPopover>
                                <span>
                                    {isBetBuilder ? null : isWinnerMarket ? null : featuredMarket.name}
                                </span>
                                </WithPopover>
                            </>
                        }
                    </div>
                }
                {!isCombinedMatch(event.id) && bets.length <= 3 &&
                    <LLWidgetBetsNames
                        names={
                            isWinnerMarket ?
                                ["1", "x", "2"] :
                                bets.reduce((names, bet) => [...names, bet.name ?? " "], [])
                        }
                    />
                }
                <LLWidgetSportBets
                    bets={bets}
                    event={event}
                    isBetBuilder={isBetBuilder}
                    betBuilderOdds={event.betBuilderOdds}
                    featuredMarket={featuredMarket}
                    viewSize={viewSize}
                    columnCount={featuredMarket.columnCount}
                    withDropdown={withDropdown}
                    isCombined={isCombinedMatch(event.id)}
                    dropdownWidth={dropdownWidth}
                    key={activeSubMarket?.name}
                    dropdownRef={dropdownRef}
                    isWinnerMarket={isWinnerMarket}
                />
            </div>
        </div>
    );
};

export default LLWidgetSportItem;
