import React, {Children, useEffect, useRef, useState} from 'react';
import {throttle} from "lodash";


const llScrollSliderState = {
    eventTimeout: null,
    lastScrollPosition: 0,
    itemWidth: 0,
};
let handleScroll;

const LLScrollSlider = (
    {
        viewSize,
        isLandscapeOrientation,
        children,
    }
) => {
    const
        [scrollLeft, setScrollLeft] = useState(0),
        [resizeTrigger, setResizeTrigger] = useState(0);

    const sliderRef = useRef();
    const isMultiSlider = sliderRef.current?.getBoundingClientRect().width > '540' && viewSize !== 'desktop';

    /**** ~~ Calculate width of a slide with recalculating on resizing ****/
    useEffect(() => {
        const resizeCallback = throttle(() => {
            setResizeTrigger((v) => ++v)
        }, 500);
        window.addEventListener('resize', resizeCallback);
        return () => {
            window.removeEventListener('resize', resizeCallback);
        }
    }, []);

    useEffect(() => {setResizeTrigger((v) => ++v)}, [isLandscapeOrientation]);

    useEffect(() => {
        const sliderWidth = sliderRef.current?.getBoundingClientRect().width;
        llScrollSliderState.itemWidth = isMultiSlider ? (sliderWidth / 2 - 2) : sliderWidth;
    }, [resizeTrigger, sliderRef.current, viewSize]);
    /**** ~~ END - Calculate width of a slide with recalculating on resizing ****/

    const scroll = (coordinate) => {
        sliderRef.current?.removeEventListener('scroll', handleScroll);
        requestAnimationFrame(() => sliderRef.current?.scrollTo({ left: coordinate, behavior: 'smooth' }));
        setScrollLeft(coordinate);
        setTimeout(function() {
            sliderRef.current?.addEventListener('scroll', handleScroll, {passive: true});
        }, 200);
    };

    useEffect(() => {
        handleScroll = (e) => {
            clearTimeout(llScrollSliderState.eventTimeout);
            llScrollSliderState.eventTimeout = setTimeout(() => {
                const visibleSlide = Math.round(e.target?.scrollLeft/ llScrollSliderState.itemWidth);
                llScrollSliderState.lastScrollPosition = llScrollSliderState.itemWidth * visibleSlide;
                scroll(llScrollSliderState.lastScrollPosition);
            }, 100);
        };
        if ('scrollBehavior' in document.documentElement.style) {
            sliderRef.current.addEventListener('scroll', handleScroll, {passive: true});
        }
    }, []);

    const isFirstChildVisible = () => scrollLeft <= 0;
    const isLastChildVisible = () => scrollLeft >=
        llScrollSliderState.itemWidth * (children.length - (isMultiSlider ? 2 : 1));
    const isDotActive = (i) => Math.round(scrollLeft/(llScrollSliderState.itemWidth || 1)) === i;

    const withArrows = viewSize !== 'mobile' || isMultiSlider;

    return (
        <div className="scroll-slider">
            { (withArrows && !!children.length && !isFirstChildVisible()) &&
                <span
                    onClick={() => scroll(scrollLeft - llScrollSliderState.itemWidth)}
                    className="scroll-slider__prev"
                >
                    <i className="doruk-mg-arrow-2" />
                </span>
            }
            <div className="scroll-slider__wrp"
                 ref={sliderRef}
                 style={{maxWidth: '100%'}}
            >
                {
                    Children.map(children, child =>
                        <div className="scroll-slider__item"
                             style={{width: isMultiSlider ? 'calc(50% - 2px)' : `100%`}}
                        >{child}</div>
                    )
                }
            </div>
            { (withArrows && !!children.length && !isLastChildVisible()) &&
                <span
                    onClick={() => scroll(scrollLeft + llScrollSliderState.itemWidth)}
                    className="scroll-slider__next"
                >
                    <i className="doruk-mg-arrow-2" />
                </span>
            }
            { (viewSize === 'mobile' && !isMultiSlider && !!children.length) &&
                <div className="scroll-slider__dots" key={scrollLeft+resizeTrigger}>
                    {children.map((child, i) =>
                        <div key={i} className={`scroll-slider__dot${isDotActive(i) ? ' filled' : ''}`}/>
                    )}
                </div>
            }
        </div>
    );
};

export default LLScrollSlider;