import React, {useEffect, useRef, useState} from 'react';
import Slider from "@mui/material/Slider";
import {sortBy} from 'lodash';

import {LLSportBettingButtonWrapper} from "../../../../../components";


const sportScoreMarketVars = {
    availableScores: [],
    lastChangedScores: null,
};
const SportScoreMarket = ({match, bets, oddAppearance}) => {
    const
        [currentScore, setCurrentScore] = useState({1: null, 2: null,}),
        [scores, setScores] = useState({1: [], 2: []});

    const currentScoreRef = useRef({1: null, 2: null});

    useEffect(() => {
        let c1 = [], c2 = [], ss = [], withOthers = null;
        /***************** ~~ Gather arrays of score values for the sliders ****************/
        bets.map(bet => {
            const [s1, s2] = bet.baseName.split(':');
            if (!c1.find(item => item.value == s1) && !isNaN(+s1)) {
                c1.push({value: +s1, label: s1})
            }
            if (!c2.find(item => item.value == s2) && !isNaN(+s2)) {
                c2.push({value: +s2, label: s2})
            }
            ss.push([+s1, +s2]);
            if (!bet.baseName.includes(':')) {
                withOthers = bet.baseName;
            }
        })
        sportScoreMarketVars.availableScores = ss;

        /***************** ~~ Add 'Other' item to each slider if needed ****************/
        c1 = sortBy(c1, ['value']);
        withOthers && c1.push({value: c1[c1.length - 1]?.value + 1, label: withOthers, isOtherBet: true});
        c2 = sortBy(c2, ['value']);
        withOthers && c2.push({value: c2[c2.length - 1]?.value + 1, label: withOthers, isOtherBet: true});

        setScores(prevState => ({...prevState, 1: c1, 2: c2}));
        setCurrentScore(prevState => ({...prevState, 1: c1[0]?.value}));
    }, []);

    const resetCorrectScore = (index) => {
        currentScoreRef.current[index] = currentScore[index];
        const otherSliderIndex = index === 1 ? 2 : 1;

        /***************** ~~ If score === 'Other' than set the other slider to 'Other' too ****************/
        const temp = scores[index].find(score => score.value === currentScore[index]);
        if (temp && isNaN(+temp.label)) {
            sportScoreMarketVars.lastChangedScores = null;
            setCurrentScore(prevState => ({
                ...prevState,
                [otherSliderIndex]: scores[otherSliderIndex].find(score => isNaN(+score.label))?.value
            }));
            return true;
        }

        /***************** ~~ Setting the first correct score for non-existing choice ****************/
        const isCorrectScore = sportScoreMarketVars.availableScores.find(
            scores => scores[0] === currentScoreRef.current[1] && scores[1] === currentScoreRef.current[2]
        );
        if (!isCorrectScore) {
            sportScoreMarketVars.lastChangedScores = index;
            const correctScore = sportScoreMarketVars.availableScores.find(scores =>
                scores[index - 1] === currentScoreRef.current[index]
            );
            correctScore && setCurrentScore(prevState => ({
                ...prevState, [otherSliderIndex]: correctScore[otherSliderIndex - 1]
            }));
        }
    };

    useEffect(() => {resetCorrectScore(1)}, [currentScore[1]]);
    useEffect(() => {resetCorrectScore(2)}, [currentScore[2]]);

    const checkDisabled = (index, children) => {
        /***************** ~~ If current bet is 'Other', then it's always enabled ****************/
        if (isNaN(+children)) {
            return false;
        }
        /***************** ~~ Set disabled non-existing labels ****************/
        const otherSliderIndex = index === 1 ? 2 : 1;
        const arr = sportScoreMarketVars.availableScores.filter(score => score[otherSliderIndex - 1] === currentScore[otherSliderIndex]);
        const val = arr.find(score => score[index - 1] == children);
        return sportScoreMarketVars.lastChangedScores === otherSliderIndex && !val?.length;
    };

    const findActiveBet = () => bets.find(bet => {
        /***************** ~~ If both current scores are the last in their arrays, then it's 'Other' bet ****************/
        if (
            currentScore[1] === scores[1][scores[1].length - 1]?.value &&
            currentScore[2] === scores[2][scores[2].length - 1]?.value
        ) {
            return !bet.baseName.includes(':');
        }
        const [s1, s2] = bet.baseName.split(':');
        return s1 == currentScore[1] && s2 == currentScore[2];
    })

    return (
        <div className="match-details__bets match-details__bets--score">
            {
                findActiveBet() ?
                    <LLSportBettingButtonWrapper
                        bet={findActiveBet()}
                        className="match-details__bet"
                        oddAppearance={oddAppearance}
                        key={currentScore[1] + currentScore[2]}
                    />
                    : null
            }
            <div className="fancy-bets-slider-wrp">
                <p className="fancy-bets-slider-name">
                    {match.competitor1?.name}
                </p>
                <Slider
                    value={currentScore[1] ?? null}
                    onChange={(event, newValue) => setCurrentScore(prevState => ({...prevState, 1: newValue}))}
                    min={scores[1][0]?.value}
                    max={scores[1][scores[1].length - 1]?.value}
                    marks={scores[1]}
                    valueLabelDisplay="auto"
                    classes={{
                        root: 'fancy-bets-slider',
                        markLabel: 'fancy-bets-slider__label',
                        rail: 'fancy-bets-slider__rail',
                    }}
                    slots={{
                        markLabel: ({children, style, className, ...props}) => {
                            const isDisabled = checkDisabled(1, children);
                            return (
                                <span
                                    style={{ opacity: isDisabled ? .3 : 1, ...style}}
                                    {...{
                                        className,
                                        'aria-hidden': props['aria-hidden'],
                                        'data-index': props['data-index']
                                }}>
                                    {children}
                                </span>
                            );
                        },
                    }}
                />
                <p className="fancy-bets-slider-name">
                    {match.competitor2?.name}
                </p>
                <Slider
                    value={currentScore[2] ?? null}
                    onChange={(event, newValue) => setCurrentScore(prevState => ({...prevState, 2: newValue}))}
                    min={scores[2][0]?.value}
                    max={scores[2][scores[2].length - 1]?.value}
                    marks={scores[2]}
                    valueLabelDisplay="auto"
                    classes={{
                        root: 'fancy-bets-slider',
                        markLabel: 'fancy-bets-slider__label',
                        rail: 'fancy-bets-slider__rail',
                    }}
                    slots={{
                        markLabel: ({children, style, className, ...props}) => {
                            const isDisabled = checkDisabled(2, children);
                            return (
                                <span
                                    style={{ opacity: isDisabled ? .3 : 1, ...style}}
                                    {...{
                                        className,
                                        'aria-hidden': props['aria-hidden'],
                                        'data-index': props['data-index']
                                }}>
                                    {children}
                                </span>
                            );
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default SportScoreMarket;