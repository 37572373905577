import ApiClient from './api-client';
import { ConfigAPI, HomeAPI, BettingAPI, GamesAPI, UserAPI, PromoAPI, LogsAPI } from './methods/';

import { API_PREFIX } from './api-url-config';

const api = new ApiClient({ prefix: API_PREFIX });

const apiSingleton = {
    config: new ConfigAPI({ apiClient: api }),
    home: new HomeAPI({ apiClient: api }),
    betting: new BettingAPI({ apiClient: api }),
    games: new GamesAPI({ apiClient: api }),
    user: new UserAPI({ apiClient: api }),
    promo: new PromoAPI({ apiClient: api }),
    logs: new LogsAPI({ apiClient: api }),
};

export default apiSingleton;
