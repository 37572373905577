import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import withCommon from './common-props';

/**
 * @desc Represents a WithPopover component.
 *
 * @param {boolean} mobileUserAgent - flag is mobile layout
 * @param {boolean} isActive - flag is popover opened
 * @param {function} setPopover - set popover props
 * @param {function} hidePopover - set isActive = false
 * @param {string} customClasses - classNames for custom styles this component
 * @param {object} popoverContent - it is popover content element)))
 * @param {string} popoverClasses - classNames for custom styles popover component
 * @param {object} children - children
 *
 * @author S.Nakhodov
 */

const WithPopover = withCommon({
    store: ({store}) => ({
        mobileUserAgent: store.user.mobileUserAgent,
        setPopover: store.popover.setPopover,
        hidePopover: store.popover.hidePopover,
        anchorEl: store.popover.anchorEl,
    }),
    propTypes: {
        setPopover: PropTypes.func.isRequired,
        hidePopover: PropTypes.func.isRequired,
        customClasses: PropTypes.string,
        popoverClasses: PropTypes.string,
        popoverContent: PropTypes.object,
        children: PropTypes.element.isRequired,
        anchorEl: PropTypes.object.isRequired,
    },
    defaultName: 'WithPopover',
    isObserver: true,
})(({
    mobileUserAgent,
    setPopover,
    hidePopover,
    customClasses,
    popoverClasses = 'll-popover__text',
    popoverContent,
    placement = 'top',
    triggeredOnHover = true,
    triggeredOnClick = true,
    dropDownRole = false,
    children,
    anchorEl,
    offset,
    withNewProps = false,
    popoverWidth,
}) => {
    const [isOverflow, setOverflow] = useState(false);
    const ref = useRef(null);
    popoverContent = popoverContent ?? children;

    const isPopoverNeedToBeShowed = (elem) => dropDownRole || (
        !!elem && (Math.ceil(elem.getBoundingClientRect().width) <
            elem.children[0].getBoundingClientRect().width ||
            Math.ceil(elem.getBoundingClientRect().height) <
            elem.children[0].getBoundingClientRect().height) &&
        !!popoverContent);

    useEffect(() => {
        withNewProps && setTimeout(() =>
            setOverflow(
                isPopoverNeedToBeShowed(ref.current)
            ), 0
        );
    }, [popoverWidth]);

    const propObj = {};
    if (mobileUserAgent || triggeredOnClick) {
        propObj.onClick = (e) => {
            if (isPopoverNeedToBeShowed(e.currentTarget)) {
                setPopover({
                    anchorEl: e.currentTarget,
                    isActive: !!popoverContent,
                    popoverClasses,
                    popoverContent,
                    placement,
                    stayShovedByHover: dropDownRole,
                    offset,
                    width: popoverWidth,
                });
            }
        };
    }

    if (triggeredOnHover) {
        propObj.onMouseEnter = (e) => {
            if (isPopoverNeedToBeShowed(e.currentTarget)) {
                setPopover({
                    anchorEl: e.currentTarget,
                    isActive: !!popoverContent,
                    popoverClasses,
                    popoverContent,
                    placement,
                    stayShovedByHover: true,
                    offset,
                    width: popoverWidth,
                });
            }
        };
        propObj.onMouseLeave = (e) => {
            anchorEl === e.currentTarget && hidePopover();
        };
    }

    return (
        <div className={cx('ll-popover-wrapper', customClasses)} ref={ref} {...propObj}>
            { React.Children.map(children, (child) => {
                if (withNewProps) {
                    return React.isValidElement(child) ?
                        React.cloneElement(child, {isOverflow})
                        : null;
                } else {
                    return child;
                }
            })}
        </div>
    );
});

export default WithPopover;
