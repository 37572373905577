import React, {Fragment, useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

import {trans} from '../../../../../common/utils';
import {
    LLPreloader,
    LLScrollBox,
    LLMenuAccordion,
} from '../../../../../components';

import {LiveSearch} from '../../../components';
import {LiveTournamentItemDesktop} from './live-tournament-item-desktop';

import vars from '../../../../../styles/themes/theme/variables.scss';


const LiveLeftPanel = ({store}) => {
    const branch = store.betting.branch,
        initialFetching = store.betting.branch.initialFetching,
        mobileUserAgent = store.user.mobileUserAgent,
        viewSize = store.site.status.viewSize,
        activeItems = store.betting.activeItems,
        activeMenuItems = store.betting.activeMenuItems,
        isMenuDisabled = store.betting.isMenuDisabled,
        liveMatches = store.betting.liveMatches,
        siteName = store.site.siteName,
        isLogged = store.user.isLogged,
        userCanCashout = store.user.canCashout;

    const [isInitialOpen, setInitialOpen] = useState(false);

    const parentScroll = store.scrollBoxes.getScrollBox('live-page-left');

    let bananaDefender = true;

    const bananaDefenderState = () => bananaDefender;

    useEffect(() => {
        if (bananaDefender) {
            !activeMenuItems.sports.length && activeMenuItems.setActiveItem({
                ids: [...activeItems.sports],
                type: 'sports',
            });
        }
    }, [JSON.stringify(activeItems.sports)]);

    useEffect(() => {
        //если матч закончился и появился новый, сетим активный спорт в меню, если его там не было
        if (!liveMatches.activeMatchExist) {
            setInitialOpen(true);
            setTimeout(() => {
                if (bananaDefenderState() && activeItems?.sports?.length) {
                    if (
                        !activeMenuItems.isActive({
                            id: activeItems?.sports?.length ? activeItems.sports[0] : 1,
                            type: 'sports',
                        }) && activeItems.sports.length
                    ) {
                        activeMenuItems.setActiveItem({
                            ids: [...activeItems.sports],
                            type: 'sports',
                        });
                    }
                    if (
                        store.scrollBoxes.getScrollBox('live-page-left')
                    ) {
                        store.scrollBoxes
                            .getScrollBox('live-page-left')
                            .scrollMethods.scrollToTop();
                    }
                }
            }, 300);
        }
    }, [liveMatches.activeMatchExist]);

    useEffect(
        () => () => {
            bananaDefender = false;
        },
        []
    );

    const renderSectionInner = () => <>
        {initialFetching ? (
            <div className="live-menu__loading">
                <LLPreloader/>
            </div>
        ) : (
            <>
                <div className="live-left-menu__header-wrapper">
                    <div className="live-left-menu__header">
                        {trans('Live betting')}
                        <div className="live-left-menu__odds-count">
                            {liveMatches.liveMatchesCount}
                        </div>
                    </div>
                    {store.site.status.viewSize === 'tablet' && (
                        <div
                            className="live-left-menu__view-btn"
                            onClick={() => liveMatches.setCalendar(true)}
                        >
                            <i className="llg-calendar1"/>
                            <span className="live-left-menu__view-btn-text">
                                {trans('Calendar')}
                            </span>
                        </div>
                    )}
                </div>
                {branch.sportsList.map((sport) => (
                    <LLMenuAccordion
                        id={`sport-${sport.id}`}
                        key={sport.id}
                        icon={{enable: false}}
                        timeout={!isInitialOpen ? 0 : 400}
                        expanded={activeMenuItems.isActive({
                            id: sport.id,
                            type: 'sports',
                        })}
                        onClick={() => {
                            setInitialOpen(true);
                            activeMenuItems.toggleActiveMenuItem({
                                id: sport.id,
                                type: 'sports',
                            });
                            if (
                                activeMenuItems.isActive({
                                    id: sport.id,
                                    type: 'sports',
                                })
                            ) {
                                setTimeout(() => {
                                    if (mobileUserAgent) {
                                        const sportElement = document.getElementById(
                                            `sport-${sport.id}`
                                        );
                                        if (sportElement) {
                                            const headerHeight = parseInt(vars['css_sizeHeight_contentHeader_' + viewSize])
                                                + parseInt(vars['css_sizeHeight_contentHeaderMenu_' + viewSize]);
                                            const mobileHeightFix = mobileUserAgent ? 100 : 0;
                                            const newPosition = sportElement.getBoundingClientRect().top + window.scrollY - headerHeight - mobileHeightFix;
                                            window.scrollTo({top: newPosition, behavior: "smooth"});
                                        }
                                    } else {
                                        parentScroll && parentScroll.scrollMethods.scrollToElement(
                                            `#sport-${sport.id}`
                                        )
                                    }
                                }, 300);
                            }
                        }}
                        titleTemplateRender={() => (
                            <>
                                <p className="ll-accordion__header-text">
                                    <i className={`ll-accordion__sport-icon sport_icon_font-${sport.id}`}/>
                                    <span>{sport.name}</span>
                                </p>
                                <p className="ll-accordion__header-count">
                                    {sport.liveMatchesCount}
                                </p>
                            </>
                        )}
                    >
                        { sport.categoriesList.map((category) =>
                            category.tournamentsList.map((tournament) =>
                                <LiveTournamentItemDesktop {...{
                                    branch,
                                    sport,
                                    category,
                                    tournament,
                                    activeItems,
                                    userCanCashout,
                                    isLogged,
                                    viewSize,
                                    siteName,
                                }} key={tournament.id} />
                        ))}
                    </LLMenuAccordion>
                ))}
            </>
        )}
    </>;

    return (
        <div
            className={`live-menu__section ${
                isMenuDisabled ? 'live-menu__section--disabled' : ''
            }`}
        >
            <LiveSearch view="menu"/>
            {!mobileUserAgent ? <LLScrollBox id="live-page-left" customClasses={'live-page__scrollbox live-page__menu-scrollbox'}>
                    {renderSectionInner()}
                </LLScrollBox>
                : renderSectionInner()
            }
        </div>
    );
};

export default inject('store')(observer(LiveLeftPanel));
