import { en } from './translations/_en';
import { tr } from './translations/_tr';
import { aa } from './translations/_aa';

const languages = {
    i18n: {
        en: en,
        tr: tr,
        aa: aa,
    },
    date: {
        en: 'en',
        tr: 'tr',
        aa: 'ar',
    },
    languageCodes: {
        en: 'en',
        tr: 'tr',
        aa: 'iq',
    },
    languageNames: {
        en: 'LANG_EN',
        tr: 'LANG_TR',
        aa: 'LANG_AA',
    }
};

export default languages;
