/**
 * @crutch array to invert teams in widget
 *
 * @task 3632
 *
 * @key widget type
 * @value sport id
 *
 * @author I.Mykhailychenko
 */

const invert = [{ 'match.lmtPlus': 16 }, { 'match.lmtPlus': 3 }];

export default invert;
