import React from "react";
import cx from "classnames";

import {formatDate, isCombinedMatch, trans} from "../../../../common/utils";


const LLWidgetHeader = (props) => {
    const {event, betting, slide, type} = props;

    const isBetWidget = !!event;

    return isBetWidget ? (
            <div className="ll-widget-item__info">
                <span className="ll-widget-item__info-item">
                    <i className="ll-widget-item__icon doruk-time"/>
                    <span className="ll-widget-item__date">
                        {formatDate(event.timeStart, "DD.MM")}
                    </span>
                    <span>
                        {formatDate(event.timeStart, "HH:mm")}
                    </span>
                </span>
                {isCombinedMatch(event.id) && <span className="ll-widget-item__info-item ll-widget-title">
                    {event?.title}
                </span>}
                <span className="ll-widget-item__info-item ll-widget-item__info-item--sport">
                    <i className={`ll-widget-item__icon sport_icon_font-${event.sportId}`}/>
                    <span className="ll-widget-item__sport-name">
                        {betting.branch.sports.get(event.sportId)?.name}
                    </span>
                </span>
            </div>
        )
        :
        (
            <div className="ll-widget-item__game-info">
                <span className="ll-widget-item__game-info-item">
                    <i className="ll-widget-item__icon doruk-filter-featured-01"/>
                    <span>
                        {trans("Featured")}
                    </span>
                </span>
                <span className="ll-widget-item__game-info-item ll-widget-title">
                    <span>
                        {slide.title}
                    </span>
                </span>
                <span className="ll-widget-item__game-info-item ll-widget-item__game-info-item--type">
                    <i className={cx(
                        'll-widget-item__icon',
                        type === 'slot' ? 'doruk-slots-01' : 'doruk-casino-01'
                    )}/>
                    <span className="ll-widget-item__type-name">
                        {trans(`${type === 'slot' ? 'Slots' : 'Casino'}`)}
                    </span>
                </span>
            </div>
        )
};

export default LLWidgetHeader;
