import { types } from 'mobx-state-tree';

const Popover = types
    .model('Popover', {
        isActive: types.boolean,
        popoverClasses: types.maybeNull(types.string),
        /**
         * Variants:
         * 'bottom-end'
         * 'bottom-start'
         * 'bottom'
         * 'left-end'
         * 'left-start'
         * 'left'
         * 'right-end'
         * 'right-start'
         * 'right'
         * 'top-end'
         * 'top-start'
         * 'top'
         */
        placement: types.maybeNull(types.string),
        width: types.maybeNull(types.number),
    })
    .volatile((s) => ({
        anchorEl: {},
        popoverContent: {},
        offset: [],
    }))
    .actions((self) => ({
        setPopover({
            isActive = false,
            anchorEl = {},
            popoverContent = '',
            popoverClasses = '',
            placement,
            offset = [],
            width
        }) {
            self.isActive = isActive;
            self.anchorEl = anchorEl;
            self.popoverContent = popoverContent;
            self.popoverClasses = popoverClasses;
            self.placement = placement;
            self.offset = offset;
            self.width = width;
        },
        setAnchorEl(anchorEl) {
            self.anchorEl = anchorEl;
        },
        hidePopover() {
            self.isActive = false;
            self.anchorEl = {};
            self.offset = [];
            self.width = null;
        },
    }));

export default Popover;
