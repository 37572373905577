import React from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {LLTextSlider, LLWidgetSportItem, LLWidgetGameItem, WithUserActivity} from '../../../../components';


const LLSportWidget = ({store}) => {
    const
        data = store.widgets.llWidget.widgetsList,
        viewSize = store.site.status.viewSize,
        isLogged = store.user.isLogged,
        language = store.user.language,
        mobileUserAgent = store.user.mobileUserAgent,
        openLogin = store.user.openLogin,
        isLandscapeOrientation = store.site.status.isLandscapeOrientation,
        hidePopover = store.popover.hidePopover;

    return (
        <WithUserActivity areaId={'widget-area'}>
            <div
                className={cx(
                    'll-sport-widget js__activity-area',
                    !data.length && 'll-sport-widget--empty'
                )}
                id={'widget-area'}
            >
                {data.length ?
                    <LLTextSlider
                        id="ll-sport-widget-slider"
                        slidesToShow={1}
                        responsive={[
                            {
                                minSize: 1680,
                                slidesToShow: 3,
                            },
                            {
                                minSize: 756,
                                slidesToShow: 2,
                            },
                            {
                                minSize: 500,
                                slidesToShow: data.length > 1 ? 2 : 1,
                            },
                            {
                                minSize: 375,
                                slidesToShow: 1,
                            },
                        ]}
                        viewSize={viewSize}
                        isLandscapeOrientation={isLandscapeOrientation}
                        withAbsoluteArrows={true}
                        customClasses={{
                            slider: 'll-sport-widget__slider',
                            arrow: 'll-sport-widget__slider-arrow'
                        }}
                    >
                        {data.map(slide => slide.isSportSlide ?
                            <LLWidgetSportItem
                                event={slide}
                                key={slide.slideId}
                                betting={store.betting}
                                widget={store.widgets.llWidget}
                                viewSize={viewSize}
                                isLandscapeOrientation={isLandscapeOrientation}
                            />
                            :
                            <LLWidgetGameItem
                                slide={slide}
                                key={slide.slideId}
                                viewSize={viewSize}
                                isLogged={isLogged}
                                openLogin={openLogin}
                                mobileUserAgent={mobileUserAgent}
                                language={language}
                                isLandscapeOrientation={isLandscapeOrientation}
                                hidePopover={hidePopover}
                            />
                        )}
                    </LLTextSlider>
                    :
                    null
                }
            </div>
        </WithUserActivity>
    );
};

export default inject('store')(observer(LLSportWidget));
