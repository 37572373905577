import React from 'react';
import PropTypes from 'prop-types';

import { withCommon, WithDropdown } from '../../../../components';
import {languages} from "../../../../configs";

/**
 * Represents Language Picker component
 * @memberOf LLHeaderDesktop, LLHeaderMobile
 *
 * @param {string} language - active language from server
 * @param {array} siteLanguages - list of siteLanguages from server which displays in picker
 * @param {func} setLanguage - function to change active language
 * @param {string} viewSize - detect mobile, tablet or desktop version
 *
 * @author A. Green
 */

const LanguagePicker = withCommon({
    store: ({ store }) => ({
        language: store.user.language,
        siteLanguages: store.site.languages,
        setLanguage: store.user.setLanguage,
        viewSize: store.site.status.viewSize,
    }),
    PropTypes: {
        language: PropTypes.string.isRequired,
        siteLanguages: PropTypes.arrayOf(PropTypes.string.isRequired),
        setLanguage: PropTypes.func.isRequired,
        viewSize: PropTypes.string.isRequired,
    },
    isObserver: true,
    isTrans: true,
})(({ language, siteLanguages, setLanguage, trans, viewSize }) => {

    const languageDropDown = () =>
        siteLanguages.map((code) => ({
            element: (
                <div
                    className="ll-language-picker__item"
                    onClick={() => {
                        code !== language && setLanguage({ language: code });
                    }}
                >
                    <img
                        className="ll-language-picker__flag"
                        src={`/country-flags/4x3/${code && languages.languageCodes[code].toLowerCase()}.svg`}
                        alt=""
                    />
                    <p className="ll-language-picker__text">
                        {trans(languages.languageNames[code])}
                    </p>
                </div>
            ),
            code: code,
        }));

    return (
        <WithDropdown
            dataSource={{
                submenu: languageDropDown(),
            }}
            openBehavior={viewSize !== 'desktop' ? 'click' : 'hybrid'}
            menuItemIsActive={(item) => item.code === language}
            customClasses={`ll-language-picker--${viewSize}`}
            menuClasses="ll-language-picker__items"
            delay={viewSize !== 'desktop' ? 300 : 0}
        >
            <div className="ll-language-picker__wrapper">
                <img
                    className="ll-language-picker__flag"
                    src={`/country-flags/4x3/${languages.languageCodes[language]}.svg`}
                    alt=""
                />
                <p className="ll-language-picker__text">
                    {trans(languages.languageNames[language])}
                </p>
            </div>
        </WithDropdown>
    );
});

export default LanguagePicker;
