import React, {useEffect, useRef, useState} from 'react';

import {formatDate, getMidnightDate, trans} from "../../../common/utils";
import {LLBtn2, LLDatePicker, LLInput, LLScrollBox} from "../index";


/**
 * @crutch
 *
 * @desc Helper for dx TextBox component with mask prop https://js.devexpress.com/Demos/WidgetsGallery/Demo/TextBox/Overview/React/Light/
 *      The value returned from component is string in format 'DDMMYYYY'. Js native data class expect string in format similar to 'MM-DD-YYYY' this function
 *      will transform dx stirng to 'MM-DD-YYYY' and return a data object
 *
 * @param {string} value
 *
 * @author I.Mykhailychenko
 */
const dxSringToDate = (value) => {
    if (!/[0-9]{8}/.test(value)) return new Date();
    const day = value.slice(0, 2);
    const month = value.slice(2, 4);
    const yaer = value.slice(4);
    return new Date(month + '-' + day + '-' + yaer);
};

export const LLDateRangePickerDesktop = (
    {
        startDate,
        endDate,
        setDates,
        innerPickerStartDate,
        innerPickerEndDate,
        onChangeStartPickerHandler,
        onChangeEndPickerHandler,
    }
) => {
    const dateNow = getMidnightDate(new Date()),
        yesterday = new Date(new Date().setDate(dateNow.getDate() - 1)),
        last7DaysStart = new Date(new Date().setDate(dateNow.getDate() - 7)),
        last30DaysStart = new Date(new Date().setDate(dateNow.getDate() - 30)),
        thisMonthStart = new Date(new Date().setDate(1)),
        lastMonthStart = new Date(
            new Date(new Date().setMonth(dateNow.getMonth() - 1)).setDate(1)
        ),
        lastMonthEnd = new Date(
            new Date().setDate(new Date(new Date().setDate(1)).getDate() - 1)
        );

    const datePickerRanges = [
        {
            name: 'Today',
            startDate: dateNow,
            endDate: dateNow,
        },
        {
            name: 'Yesterday',
            startDate: yesterday,
            endDate: yesterday,
        },
        {
            name: 'Last 7 Days',
            startDate: last7DaysStart,
            endDate: dateNow,
        },
        {
            name: 'Last 30 Days',
            startDate: last30DaysStart,
            endDate: dateNow,
        },
        {
            name: 'This Month',
            startDate: thisMonthStart,
            endDate: dateNow,
        },
        {
            name: 'Last Month',
            startDate: lastMonthStart,
            endDate: lastMonthEnd,
        },
    ];

    const
        [isRangePickerOpen, setRangePickerOpen] = useState(false),
        [isPickersShown, setPickersShown] = useState(false);

    const datePickerBoxRef = useRef(null);
    /**** ~~ Custom event-listener for www date-ranges container onBlur ****/
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                datePickerBoxRef.current &&
                !datePickerBoxRef.current.contains(e.target)
            )
                closeDatePickerDropdown();
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const closeDatePickerDropdown = () => {
        setRangePickerOpen(false);
        setPickersShown(false);
    };

    const onChangeStartInputHandler = (e) => {
        onChangeStartPickerHandler(dxSringToDate(e.value));
    };
    const onChangeEndInputHandler = (e) => {
        onChangeEndPickerHandler(dxSringToDate(e.value));
    };

    const onPickerSubmitHandler = (range) => {
        setDates(range);
        closeDatePickerDropdown();
    };

    return (
        <div className="ll-date-range-picker--desktop">
            <div
                className="ll-date-range-picker__values-box"
                onClick={() => setRangePickerOpen((v) => !v)}>
                <i className="doruk-live-calendar" />
                <div className="ll-date-range-picker__input-wrap">
                        <span className="ll-date-range-picker__input">
                            {formatDate(startDate, 'DD-MM-YYYY')}
                        </span>
                </div>
                <span className="ll-date-range-picker__values-divider">|</span>
                <div className="ll-date-range-picker__input-wrap">
                        <span className="ll-date-range-picker__input">
                            {formatDate(endDate, 'DD-MM-YYYY')}
                        </span>
                </div>
                <i className="ll-date-range-picker__values-down-icon llg-chevron-down" />
            </div>
            {isRangePickerOpen && (
                <div
                    className="ll-date-range-picker__dropdown-box"
                    ref={datePickerBoxRef}
                >
                    <div className="ll-date-range-picker__btn-box">
                        <LLScrollBox
                            id="date-range-picker"
                            autoHeightMax={280}
                            customClasses="ll-date-range-picker__scrollbox"
                        >
                            {datePickerRanges.map((range, i) => (
                                <LLBtn2
                                    customClasses="ll-date-range-picker__range-btn"
                                    key={i}
                                    onClick={() => onPickerSubmitHandler(range)}
                                >
                                    {trans(range.name)}
                                </LLBtn2>
                            ))}
                            <LLBtn2
                                customClasses="ll-date-range-picker__range-btn"
                                onClick={() => setPickersShown(true)}
                            >
                                {trans('Date range')}
                            </LLBtn2>
                        </LLScrollBox>
                        <div className="ll-date-range-picker__action-btns">
                            <LLBtn2
                                customClasses="ll-date-range-picker__action-btn ll-btn--accent"
                                onClick={() =>
                                    onPickerSubmitHandler({
                                        startDate: innerPickerStartDate,
                                        endDate: innerPickerEndDate,
                                    })
                                }
                            >
                                {trans('Ok')}
                            </LLBtn2>
                            <LLBtn2
                                customClasses="ll-date-range-picker__action-btn"
                                onClick={closeDatePickerDropdown}>
                                {trans('Cancel')}
                            </LLBtn2>
                        </div>
                    </div>
                    {isPickersShown && (
                        <div className="ll-date-range-picker__pickers-box">
                            <div className="ll-date-range-picker__picker-box">
                                {innerPickerStartDate <= innerPickerEndDate ? (
                                    <LLDatePicker
                                        selected={innerPickerStartDate}
                                        onChange={onChangeStartPickerHandler}
                                        maxDate={innerPickerEndDate}
                                        calendarClassName="ll-date-range-picker__picker"
                                        language="en"
                                        inline
                                    >
                                        <LLInput
                                            mask="00-00-0000"
                                            value={formatDate(
                                                innerPickerStartDate,
                                                'DD-MM-YYYY'
                                            )}
                                            onChange={onChangeStartInputHandler}
                                            customClasses="ll-date-range-picker__picker-input"
                                            id="ll-date-range-picker__start-date"
                                            name="ll-date-range-picker__start-date"
                                            maskRules={{ X: /[02-9]/ }}
                                        />
                                    </LLDatePicker>
                                ) : null}
                            </div>
                            <div className="ll-date-range-picker__picker-box">
                                <LLDatePicker
                                    selected={innerPickerEndDate}
                                    onChange={onChangeEndPickerHandler}
                                    maxDate={new Date()}
                                    calendarClassName="ll-date-range-picker__picker"
                                    language="en"
                                    inline
                                >
                                    <LLInput
                                        mask="00-00-0000"
                                        value={formatDate(
                                            innerPickerEndDate,
                                            'DD-MM-YYYY'
                                        )}
                                        onChange={onChangeEndInputHandler}
                                        customClasses="ll-date-range-picker__picker-input"
                                        id="ll-date-range-picker__end-date"
                                        name="ll-date-range-picker__end-date"
                                        maskRules={{ X: /[02-9]/ }}
                                    />
                                </LLDatePicker>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};