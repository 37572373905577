import React from 'react';
import PropTypes from 'prop-types';

import { LLBtn2, withCommon } from '../../../../components';
import config from '../../../../static/themes/config';


const LLHeaderGuest = withCommon({
    store: ({ store }) => ({
        openLogin: store.user.openLogin,
        router: store.router,
        siteName: store.site.siteName,
        openDrawer: store.drawer.open,
    }),
    propTypes: {
        openLogin: PropTypes.func.isRequired,
    },
    isTrans: true,
    isObserver: true,
})(({ openLogin, siteName, openDrawer, trans }) => (
    <div className="ll-header__top-section-guest">
        <LLBtn2
            customClasses="ll-header-btn"
            onClick={openLogin}
        >
            {trans('Sign in')}
        </LLBtn2>
        {config(siteName).siteConfig?.appearanceRules?.isSignUp && <LLBtn2
            onClick={() => openDrawer({
                side: 'right',
                currentKey: 'signup-form-drawer',
            })}
            customClasses="ll-header-btn"
        >
            {trans('Sign up')}
        </LLBtn2>}
    </div>
));

export default LLHeaderGuest;
