import ciprobetPagesConfig from './ciprobet';
import efsanebahisPagesConfig from './efsanebahis';
import bahisseninPagesConfig from './bahissenin';
import jumpoddsPagesConfig from './jumpodds';
import maksatbahisPagesConfig from './maksatbahis';
import retrobetPagesConfig from './retrobet';
import colonybetPagesConfig from './colonybet';
import showbahisPagesConfig from './showbahis';
import betlimaPagesConfig from './betlima';
import danobetPagesConfig from './danobet';
import betaestasPagesConfig from './betaestas';

const config = {
    ciprobet: ciprobetPagesConfig,
    efsanebahis: efsanebahisPagesConfig,
    jumpodds: jumpoddsPagesConfig,
    maksatbahis: maksatbahisPagesConfig,
    bahissenin: bahisseninPagesConfig,
    retrobet: retrobetPagesConfig,
    colonybet: colonybetPagesConfig,
    'show bahis': showbahisPagesConfig,
    betlima: betlimaPagesConfig,
    danobet: danobetPagesConfig,
    betaestas: betaestasPagesConfig,
};

const pagesConfig = (siteName) => config[siteName.toLowerCase()] || {};

export default pagesConfig;
