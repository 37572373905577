import { types } from 'mobx-state-tree';


const CrashGamesItem = types
    .model('CrashGamesItem', {
        name: types.string,
        link: types.maybeNull(types.string),
        gameId: types.maybeNull(types.number),
        providerId: types.maybeNull(types.number),
        sortOrder: types.number,
    });

const CrashGamesConfig = types
    .model('CrashGamesConfig', {
        games: types.array(CrashGamesItem),
        isMenuItemDisabled: true,
    })
    .actions((self) => ({
        setGames(data = []) {
            self.games = [];
            self.isMenuItemDisabled = true;
            if (data && Array.isArray(data)) {
                data = data.sort((a, b) => a.SortOrder - b.SortOrder);
                data.map((game) => {
                    self.games.push(
                        CrashGamesItem.create({
                            name: game.GameName,
                            gameId: game.GameId,
                            providerId: game.ProviderId,
                            sortOrder: game.SortOrder ?? 99999999998,
                        })
                    );
                });
                if (self.games.length) {
                    self.games.push(
                        CrashGamesItem.create({
                            name: 'Crash Games',
                            link: '/slots/gameType=Crash%20Games',
                            sortOrder: 99999999999,
                        })
                    );
                    self.isMenuItemDisabled = false;
                }
            }
        },
        setMenuItemDisabled(flag) {
            self.isMenuItemDisabled = flag;
        },
    }));

export default CrashGamesConfig;