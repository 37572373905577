import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { trans } from '../../../../common/utils';
import { LLPreloader } from '../../../../components';


let _avvplInstance = null;

const LiveStream = ({ store }) => {
    const match = store.betting.liveMatches.mountedLiveMatchInstance;

    const activatePlayer = () => {
        match.getStreamUrl().then((res) => {
            if (res) {
                _avvplInstance = new window.avvpl.setupPlayer({
                    id: 'stream-container',
                    mute: true,
                });
                _avvplInstance && _avvplInstance.load(res.data.url);
            }
        });
    };

    useEffect(() => {
        if (!document.querySelector('#playerStylesLink')) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.id = 'playerStylesLink';
            link.href = 'https://avplayer-cdn.sportradar.com/dist/latest/styles.css';
            document.head.appendChild(link);
        }
        if (!document.querySelector('#playerScript')) {
            const script = document.createElement('script');
            script.src =
                'https://avplayer-cdn.sportradar.com/dist/latest/avvpl-player.js';
            script.id = 'playerScript';
            script.async = true;
            document.body.appendChild(script);

            script.addEventListener('load', () => {
                activatePlayer();
            });
        } else {
            activatePlayer();
        }
        return () => _avvplInstance && _avvplInstance.remove();
    }, []);

    return (
        <div className="live-widgets__item stream">
            <div id="stream-container" />
            {match.isStreamLoading ? (
                <div className="stream__loader-box">
                    <LLPreloader />
                </div>
            ) : match.streamErrorMessage ? (
                <div className="stream__error-content">
                    <div className="stream__error-content-image"/>
                    <p className="stream__error-text">
                        {trans(match.streamErrorMessage)
                            .replace('$countryCode$', match.streamErrorCountryCode)
                            .replace('$ip$', match.streamErrorIp)}
                    </p>
                </div>
            ) : null}
        </div>
    );
};

export default inject('store')(observer(LiveStream));
