import {types} from "mobx-state-tree";


const RangedItem = types
    .model('RangedItem', {
        id: types.identifier,
        matchIds: types.array(types.string),
        lastFetchTime: types.maybeNull(types.Date),
        initialFetching: true,
    })
    .actions((s) => ({
        setFetchTime(date) {
            s.lastFetchTime = date;
        },
        setFetching(flag) {
            s.initialFetching = flag;
        },
        setMatchIds(ids) {
            s.matchIds = ids;
        },
    }));

export default RangedItem;