import React from 'react';

import RangedEventsPicker from "../../components/ranged-events-picker/ranged-events-picker";
import {trans} from '../../../../common/utils';
import BranchPicker from "../../components/branch-picker/branch-picker";


const PreMenuFiltersBoardMobile = (
    {
        mountedBranch,
        setPanelOpened,
        language,
        viewSize,
        siteStatus,
    }
) => (
    <div className="prematch__filters--mobile">
        <div className="prematch__filters-search-btn"
            onClick={() => setPanelOpened(true)}
        >
            <span>{trans('PREMATCH_FILTERS_PANEL_HEADER')}</span>
            <span className="sport-search__icon dx-icon-search icon" />
        </div>
        <div className="prematch__filters-branches-wrp">
            <BranchPicker
                {...{
                    language,
                    viewSize,
                    setPanelOpened,
                    mountedBranch,
                    siteStatus,
                }}
            />
        </div>
    </div>
);

export default PreMenuFiltersBoardMobile;