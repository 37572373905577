import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import {formatDate, formatNumber, trans} from "../../../common/utils";
import cx from "classnames";


const JackpotGroupCardItemDetails = (
    {
        info,
        type,
        currency,
        language
    }
) => (
    <div className={cx('jackpot-group__details', `jackpot-group__details--${type}`)}>
        <p className="jackpot-group__details-row">
            <span className="jackpot-group__details-row-info--first">
                <span className="jackpot-group__details-label">{trans('JACKPOT_TOP_WIN')}: </span>
                <span>{formatNumber(info.maxWinValue, 2, language, currency)}</span>
            </span>
            <span className="jackpot-group__details-date">{formatDate(info.maxWinDate, "DD.MM.YY", language)}</span>
        </p>
        <p className="jackpot-group__details-row">
            <span>
                <span className="jackpot-group__details-label">{trans('JACKPOT_LAST_WIN')}: </span>
                <span>{formatNumber(info.lastWinValue, 2, language, currency)}</span>
            </span>
            <span className="jackpot-group__details-date">{formatDate(info.lastWinDate, "DD.MM.YY", language)}</span>
        </p>
        <p className="jackpot-group__details-totals">
            <span className="jackpot-group__details-label">{trans('JACKPOT_TOTAL_WINS')}: </span>
            <span>{info.totalWins}</span>
        </p>
    </div>
);
const JackpotGroupDetailItem = (
    {
        store,
        group,
        type
    }
) => {
    const jackpotGroupDetails = store.slotsCasinoGames.getJackpotGroupDetail({id: group.id});
    const language = store.user.language;

    const [value, setValue] = useState(0);
    const [change, setChange] = useState(false);

    useEffect(() => {
        if (jackpotGroupDetails) {
            if (!value) {
                setValue(jackpotGroupDetails[type].currentValue);
            } else {
                setTimeout(() => {
                    setValue(jackpotGroupDetails[type].currentValue);
                    setChange(v => !v);
                    setTimeout(() => setChange(v => !v), 500);
                }, Math.random() * 3000 + 1000)
            }
        }
    }, [jackpotGroupDetails && jackpotGroupDetails[type].currentValue]);

    return ((jackpotGroupDetails && jackpotGroupDetails[type] && jackpotGroupDetails[type].currentValue) ? <>
            <p className={`jackpot-group__label jackpot-group__label--${type}`}>
                {trans(`${type.toUpperCase()}_JACKPOT`)}
            </p>
            <div className={`jackpot-group__label-value jackpot-group__label-value--${type}`}>
                <span className={change ? 'jackpot-group__label-value-number' : ''}>
                    {formatNumber(value, 2, language, jackpotGroupDetails.currency)}
                </span>
                <JackpotGroupCardItemDetails
                    type={type}
                    info={jackpotGroupDetails[type]}
                    currency={jackpotGroupDetails.currency}
                    language={language}
                />
            </div>
        </> : null
    );
}

export default inject('store')(observer(JackpotGroupDetailItem));