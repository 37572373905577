import React from 'react';

const LLBurgerIcon = ({open, onClick = () => {}, customClasses}) => (
    <div
        className={`ll-burger-icon${
            !!open ? '--opened' : ''
        } ${customClasses || ''}`}
        onClick={onClick}
    >
        <div/>
        <div/>
        <div/>
    </div>
);

export default LLBurgerIcon;