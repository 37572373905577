import {values} from 'mobx';
import {flow, getRoot, types as t} from "mobx-state-tree";
import orderBy from "lodash/orderBy";

import api from "../../../common/api";
import {RangedItem} from "./helpers";
import {refreshTime} from "./config";
import {trans} from "../../../common/utils";


const RangedEventsConfigItem = t
    .model('RangedEventsConfigItem', {
        id: t.identifier,
        groupId: t.number,
        groupName: t.string,
        groupOrder: t.number,
        sportId: t.number,
        sportName: t.string,
        sportOrder: t.number,
    })

const RangedEvents = t
    .model('RangedEvents', {
        events: t.map(RangedItem),
        config: t.map(RangedEventsConfigItem),
        fetchTime: t.maybeNull(t.number),
    })
    .actions((s) => ({
        getConfig: flow(function* fetch() {
            const res = yield api.betting.getSportsRangedByDate() || {};
            if (res.success && !res.data?.error) {
                if (Array.isArray(res.data)) {
                    s.config.clear();
                    res.data.map(item => {
                        s.config.put({
                            id: item.GroupName + '_' + item.SportID,
                            groupId: item.GroupId,
                            groupName: item.GroupName,
                            groupOrder: item.GroupOrder,
                            sportId: item.SportID,
                            sportName: item.SportName,
                            sportOrder: item.SportOrder,
                        });
                    })
                }
                s.fetchTime = Date.now();
            } else {
                s.fetchTime = null;
            }
        }),
        successInstruction({data}) {
            const currentEvent = s.events.put({id: getRoot(s).betting.activeItems.sports[0], matchIds: []});

            getRoot(s).betting.matches.setMatchesData({
                data,
                isRanged: true,
                addFilteredMatchesIds: (id) => currentEvent.matchIds.push(id),
            });
            currentEvent.setFetchTime(new Date());
        },
        unsuccessInstruction({err}) {
            getRoot(s).betting.setError({
                error: err ? err : 'RANGED_EVENTS_TECHNICAL_ISSUES_ERROR',
                type: 'list',
            });
        },
        successUpdateInstruction({data}) {
            const sportId = getRoot(s).betting.activeItems.sports[0];
            const currentEvent = s.events.get(sportId) || {};
            currentEvent.matchIds.clear();

            if (currentEvent) {
                getRoot(s).betting.matches.setMatchesData({
                    data,
                    isRanged: true,
                    addFilteredMatchesIds: (id) => currentEvent.matchIds.push(id),
                });

                currentEvent.setFetchTime(new Date());
            }
        },
        unsuccessUpdateInstruction({err}) {
            getRoot(s).betting.setError({
                error: err ? err : 'RANGED_EVENTS_TECHNICAL_ISSUES_ERROR',
                type: 'list',
            });
        },
        initializeInstruction: flow(function* fetch() {
            initializeInstruction: {
                const sportId = getRoot(s).betting.activeItems.sports[0];
                const currentEvent = s.events.get(sportId) || {};
                const currentEventSettings = s.rangedEventsConfig[sportId];

                if (
                    currentEvent.lastFetchTime &&
                    Date.now() < +new Date(+currentEvent.lastFetchTime + refreshTime.matchesList)
                ) {
                    const timeLeft =
                        +new Date(+currentEvent.lastFetchTime + refreshTime.matchesList) - Date.now();
                    s.setUpdateForEvents({sportId, timeLeft});
                    break initializeInstruction;
                }

                !!Object.keys(currentEvent).length && currentEvent.setFetching(true);

                const params = {
                    sportId: currentEventSettings.sportId,
                    to: currentEventSettings.to,
                };

                if (typeof currentEventSettings.from) {
                    params.from = currentEventSettings.from;
                }
                const res = yield api.betting.getSportMatches(params) || {};

                if (res.success && !res.data?.error) {
                    s.successInstruction({data: res.data});
                    s.setUpdateForEvents({sportId});
                } else {
                    s.unsuccessInstruction({err: res.data?.error});
                }
                s.events.get(sportId)?.setFetching(false);
            }
        }),
        updateInstruction: flow(function* fetch({updatedEventsId}) {
            updateInstruction: {
                const sportId = getRoot(s).betting.activeItems.sports.length ?
                    getRoot(s).betting.activeItems.sports[0] : null;
                const currentEventSettings = s.rangedEventsConfig[sportId];

                if (updatedEventsId !== sportId) {
                    clearTimeout(window.__rangedSportsEventsUpdater);
                    break updateInstruction;
                }

                if (!getRoot(s).site?.status.isActive) {
                    s.setUpdateForEvents({updatedEventsId});
                    break updateInstruction;
                }

                const params = {
                    sportId: currentEventSettings.sportId,
                    to: currentEventSettings.to,
                };
                if (typeof currentEventSettings.from) {
                    params.from = currentEventSettings.from;
                }
                const res = yield api.betting.getSportMatches(params) || {};

                if (res.success && !res.data?.error) {
                    s.successUpdateInstruction({data: res.data});
                    s.setUpdateForEvents({sportId});
                } else {
                    s.unsuccessUpdateInstruction({err: res.data?.error});
                }
            }
        }),
        setUpdateForEvents({sportId, timeLeft}) {
            clearTimeout(window.__rangedSportsEventsUpdater);
            window.__rangedSportsEventsUpdater = setTimeout(
                () => s.updateInstruction({updatedEventsId: sportId}),
                timeLeft ?? refreshTime.matchesList
            );
        },
    }))
    .views((s) => ({
            get rangedEventsConfig() {
                const config = {
                    'popular': {
                        id: 'popular',
                        order: 0,
                        name: 'Popular',
                        url: {
                            desktop: '',
                            tablet: '',
                            mobile: 'popular'
                        },
                        view: 'topFavoriteMatches'
                    },
                };
                orderBy(
                    values(s.config),
                    ['groupOrder', 'sportOrder'],
                    ['asc', 'asc']
                ).map((configItem, i) => {
                    config[configItem.id] = {
                        id: configItem.id,
                        name: trans(configItem.groupName + '_sport').replace('{{sportName}}', configItem.sportName),
                        url: {
                            desktop: configItem.id,
                            tablet: configItem.id,
                            mobile: configItem.id,
                        },
                        order: i + 1,
                        sportId: configItem.sportId,
                    };
                    switch (configItem.groupName) {
                        case 'Today' :
                            config[configItem.id].to = parseInt(new Date().setHours(23, 59, 59, 999) / 1000);
                            break;
                        case 'Weekend' :
                            const startOfWeekend = parseInt(new Date(new Date().setHours(0, 0, 0, 1)).setDate(new Date().getDate() + 6 - new Date().getDay()) / 1000);
                            const now = parseInt(Date.now() / 1000);
                            config[configItem.id].from = (startOfWeekend < now) ? now : startOfWeekend;
                            config[configItem.id].to = parseInt(new Date(new Date().setHours(23, 59, 59, 999)).setDate(new Date().getDate() + 7 - new Date().getDay()) / 1000);
                            break;
                        case 'Tomorrow' :
                            config[configItem.id].from = parseInt(new Date(new Date().setHours(0, 0, 0, 1)).setDate(new Date().getDate() + 1) / 1000);
                            config[configItem.id].to = parseInt(new Date(new Date().setHours(23, 59, 59, 999)).setDate(new Date().getDate() + 1) / 1000);
                            break;
                    }
                });
                return config;
            },
            get rangedEventsMatches() {
                return s.getGroupedByDateEvents(s.events.get(getRoot(s).betting.activeItems.firstActiveSport)?.matchIds || [])
            },
        })
    );

export default RangedEvents;