import React from 'react';
import {ClickAwayListener} from "@mui/material";
import cx from "classnames";

import {withCommon} from '../../../../components';
import {SearchInput} from './search-input';


export const BattlesSearch = withCommon({
    store: ({store}) => ({

        viewSize: store.site.status.viewSize,
    }),
})(({
    openedItem,
    setOpenedItem,
    viewSize,
}) => {
    return (
        viewSize === 'mobile' ?
            <>
                <div className="slots-battles-search-dummy" />
                <div className={cx(
                    'slots-battles-search',
                    'slots-battles-search--mobile',
                    openedItem === 'search' ? 'open' : ''
                )}>
                    <div
                        className="slots-battles-search__input-wrp"
                        onClick={() => {
                            setOpenedItem('search');
                            setTimeout(() =>
                                document.querySelector('.js_battles-search input')
                                .focus(), 1);
                        }}
                    >
                        <SearchInput
                            openedItem={openedItem}
                            setOpenedItem={setOpenedItem}
                        />
                    </div>
                </div>
            </>
            :
            <div className="slots-battles-search slots-battles-search--desktop">
                <SearchInput />
            </div>
    );
})
