/* Uset to get all uniques. Needs mostly for market groups gathers */

const generateCombinations = (variants) => {
    return (function recurse(keys) {
        if (!keys.length) return [{}];
        let result = recurse(keys.slice(1));
        return variants[keys[0]].reduce(
            (acc, value) => [
                ...acc,
                ...result.map((item) => Object.assign({}, item, { [keys[0]]: value })),
            ],
            []
        );
    })(Object.keys(variants));
};

export default generateCombinations;
