import React, {useState} from 'react';
import {
    Validator,
    RequiredRule,
    EmailRule,
    CustomRule,
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import PropTypes from 'prop-types';

import {withCommon, LLInput, LLBtn, llNotifier} from '../..';


const ForgotPasswordForm = withCommon({
    store: ({store}) => ({
        user: store.user,
        mobileUserAgent: store.user.mobileUserAgent,
        modal: store.modal,
        captchaV3: store.site.captchaV3,
        viewSize: store.site.status.viewSize,
    }),
    PropTypes: {
        user: PropTypes.object.isRequired,
        modal: PropTypes.object.isRequired,
        captcha: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired,
    },
    isRouter: true,
    isTrans: true,
    isObserver: true,
})(
    ({
         user: {forgetPassword},
         mobileUserAgent,
         viewSize,
         trans,
         modal,
         captchaV3,
         history,
     }) => {
        const [loading, setLoading] = useState(false),
            [email, setEmail] = useState(''),
            [formValid, setFormValid] = useState(false),
            [error, setError] = useState('');

        const serverValidationCheck = () => !error.length;

        const {executeRecaptcha} = useGoogleReCaptcha();

        const sendData = async () => {
            captchaV3 ?
                executeRecaptcha().then((token) => {
                    submitForm(token);
                }) :
                submitForm();
        };

        const submitForm = (token = null) => {
            setLoading(true);
            if (formValid) {
                const dataObj = {email};
                if (token) dataObj.token = token;
                forgetPassword({...dataObj}).then((res) => {
                    if (res) {
                        if (res.success) {
                            llNotifier({
                                message: 'We have sent you link to change your password',
                                type: 'success',
                            });
                            mobileUserAgent ? history.push('/') : modal.close();
                        } else if (res.data?.error) {
                                llNotifier({
                                    message: res.data.error,
                                    type: 'error',
                                });
                        } else if (res.data?.email) {
                            llNotifier({
                                message: res.data.email,
                                type: 'error',
                            });
                        }
                    } else {
                        llNotifier({
                            message: 'GENERAL_ERROR',
                            type: 'error',
                        });
                    }
                });
            } else {
                llNotifier({
                    message: 'WRONG_EMAIL',
                    type: 'error',
                });
            }
            setLoading(false);
        };

        return (
            <ValidationGroup className="forgot-password-form">
                <LLInput
                    label={trans('E-Mail')}
                    labelPosition={viewSize === 'mobile' ? 'inside' : 'top'}
                    maxLength={150}
                    value={email}
                    disabled={loading}
                    mode="email"
                    id="forgot-password__email"
                    name="forgot-password__email"
                    showClearButton={true}
                    onValueChanged={(e) => {
                        setError('');
                        setEmail(e.value);
                    }}
                    stylingMode="outlined"
                >
                    <Validator onValidated={() => setFormValid(true)}>
                        <RequiredRule message={trans('EMAIL_EMPTY')}/>
                        <EmailRule message={trans('WRONG_EMAIL')}/>
                        <CustomRule
                            message={trans(error)}
                            validationCallback={serverValidationCheck}
                        />
                    </Validator>
                </LLInput>
                {!!captchaV3 && (
                    <p className="form__captcha-text" dangerouslySetInnerHTML={{
                        __html: trans('CAPTCHA_TEXT').replace('{{FIRST_LINK}}', `<a href="https://policies.google.com/privacy" class="form__captcha-link">`)
                            .replace('{{SECOND_LINK}}', `<a href="https://policies.google.com/terms" class="form__captcha-link">`)
                            .replace('{{CLOSE_LINK}}', `</a>`)
                            .replace('{{CLOSE_LINK}}', `</a>`)
                    }}
                    />
                )}
                <div className="forgot-password-form__footer">
                    <LLBtn
                        className={`forgot-password-form__ok-btn ${
                            mobileUserAgent ? '' : 'll-btn--accent'
                        }`}
                        onClick={(e) => {
                            let result = e.validationGroup.validate();
                            if (result.isValid) {
                                sendData();
                            }
                        }}
                        disabled={loading}
                    >
                        <span>{trans('Ok')}</span>
                    </LLBtn>
                    {viewSize === 'desktop' ? (
                        <LLBtn
                            className="forgot-password-form__close-btn"
                            onClick={() => {
                                modal.close();
                            }}
                        >
                            {trans('Close')}
                        </LLBtn>
                    ) : null}
                </div>
            </ValidationGroup>
        );
    }
);

export default ForgotPasswordForm;
