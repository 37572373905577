import Base from '../base';
import urls from '../api-urls';

export default class GamesAPI extends Base {
    async getGames(data) {
        return this.apiClient.post(urls.games.list, data);
    }
    async getBanner(data, type) {
        return this.apiClient.post(urls.games[type + 'Banner'], data);
    }
    async getFilters(data) {
        return this.apiClient.post(urls.games.sortedFilters, data);
    }
    async favoriteGame(data) {
        return this.apiClient.post(urls.games.favoriteGame, data);
    }
    async jackpotDetails(data) {
        return this.apiClient.post(urls.games.jackpotDetails, data);
    }
    async getLobbies() {
        return this.apiClient.post(urls.games.lobbies);
    }

    // Battles
    async getRooms(data) {
        return this.apiClient.post(urls.games.roomsList, data);
    }
    async getMyRooms(data) {
        return this.apiClient.post(urls.games.myRoomsList, data);
    }
    async getBattleGames(data) {
        return this.apiClient.post(urls.games.battleGamesList, data);
    }
    async createRoom(data) {
        return this.apiClient.post(urls.games.createRoom, data);
    }
    async joinRoom(data) {
        return this.apiClient.post(urls.games.joinRoom, data);
    }
    async leaveRoom(data) {
        return this.apiClient.post(urls.games.leaveRoom, data);
    }
    async roomInfo(data) {
        return this.apiClient.post(urls.games.roomInfo, data);
    }
    async getBrands(data) {
        return this.apiClient.post(urls.games.brands, data);
    }
    async getBattlesBanner(data) {
        return this.apiClient.post(urls.games.battlesBanner, data);
    }
}
