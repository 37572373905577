import React from 'react';
import {inject, observer} from "mobx-react";

import {LLScrollBox, WithPopover} from '../../../../components';
import {useCopyToClipboard} from '../../../../common/hooks';
import {trans} from '../../../../common/utils';
import LobbyPlayer from './lobby-player';


const BattleLobby = ({store}) => {
    const [copied, copy] = useCopyToClipboard(window.location.href);
    const players = store.slotsBattles.activeBattle.playersList,
        room_status = store.slotsBattles.activeBattle.room_status,
        owner = store.slotsBattles.activeBattle.owner,
        IsPrivate = store.slotsBattles.activeBattle.IsPrivate,
        hidePopover = store.popover.hidePopover,
        onBattleGroupChanel = store.user.onBattleGroupChanel,
        userSentBattleInvite = store.user.userSentBattleInvite,
        username = store.user.username,
        viewSize = store.site.status.viewSize;

    const shareBattle = () => {
        copy();
        setTimeout(() => hidePopover(), 1000);
    };

    const BattleDetails = () => {
        return players && players.map(player => (
            <div
                key={player.CustomerID}
                className={`battle-lobby__detail-row${player.Prize ? ' battle-lobby__detail-winner-row' : ''}`}
            >
                <LobbyPlayer player={player}/>
            </div>
        ))
    };

    const InviteButton = () => {
        return (!IsPrivate && username === owner && onBattleGroupChanel && room_status === 'available' && !userSentBattleInvite) ?
            <div className="battle-lobby__battle-share-btn battle-lobby__battle-invite-btn"
                 onClick={() => store.user.sendInvite()}>
                <i className="doruk-players battle-lobby__battle-share-icon"/>
                {trans('SLOT_BATTLE__INVITE')}
            </div> : <div>{trans('SLOT_BATTLE__DETAILS')}</div>
    };

    return players ? (
        <div className={`battle-lobby battle-lobby--${viewSize}`}>
            <div className="battle-lobby__header">
                <InviteButton/>
                <WithPopover
                    popoverClasses="slot-battles-copy-tooltip"
                    placement="top-end"
                    dropDownRole={true}
                    triggeredOnHover={false}
                    popoverContent={
                        <div className="slot-battles-copy-tooltip__text">
                            {trans('SLOT_BATTLE__URL_COPIED')}
                        </div>
                    }
                >
                    <div className="battle-lobby__battle-share-btn" onClick={shareBattle}>
                        <i className="doruk-copy-icon battle-lobby__battle-share-icon"/>
                        <span>{trans('SLOT_BATTLE__SHARE')}</span>
                    </div>
                </WithPopover>
            </div>
            <div className="battle-lobby__detail">
                <div className="battle-lobby__detail-row battle-lobby__detail-row--header">
                    <div className="battle-lobby__detail-item battle-lobby__detail-row-name">
                        {trans('SLOT_BATTLE__PLAYER')}
                    </div>
                    <div className="battle-lobby__detail-item battle-lobby__detail-row-status">
                        {trans('SLOT_BATTLE__STATUS')}
                    </div>
                    <div className="battle-lobby__detail-item battle-lobby__detail-row-status">
                        {trans('SLOT_BATTLE__SCORE')}
                    </div>
                    <div className="battle-lobby__detail-item battle-lobby__detail-row-result">
                        {trans('SLOT_BATTLE__RESULT')}
                    </div>
                </div>
                <LLScrollBox customClasses="battle-lobby__detail-content">
                    <BattleDetails/>
                </LLScrollBox>
            </div>
        </div>
    ) : null;
};

export default inject('store')(observer(BattleLobby));
