import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import cx from "classnames";

import {LLBtn2, LLElementTypeSetter, LLPreloader} from "../../../components";
import {FilterSortSearch, BackToAvailableBtn} from "./";
import {parseUrl, trans} from "../../../common/utils";
import TutorialPopup from "./tutorials/tutorial-popup";
import FirstPopup from "./tutorials/first-popup";
import QuestionPopup from "./tutorials/question-popup";
import VideoModal from "./tutorials/video-modal";
import FirstModal from "./tutorials/first-modal";


const SLOT_BATTLES_TYPES = ["available", "started", "completed"];

const SlotBattlesHeader = ({store, rooms}) => {
    const
        slotsBattles = store.slotsBattles,
        bannerLoading = store.slotsBattles.banners.loading,
        banner = store.slotsBattles.banners.banner,
        slotBattlesType = store.slotsBattles.slotBattlesType,
        activeBattle = store.slotsBattles.activeBattle,
        tutorialStep = store.slotsBattles.tutorialStep,
        viewSize = store.site.status.viewSize,
        userBattleId = store.user.userBattleId,
        whatWasChanged = store.user.battlePopup.whatWasChanged,
        isActive = store.user.battlePopup.isActive,
        isLogged = store.user.isLogged,
        canPlaySlotBattles = store.user.canPlaySlotBattles,
        user = store.user,
        setModal = store.modal.setModal,
        close = store.modal.close,
        setBackdropShown = store.site.status.setBackdropShown,
        setBackdropOnClick = store.site.status.setBackdropOnClick,
        router = store.router;

    const [isQuestionsShowed, setQuestionsShowed] = useState(false);

    const OpenVideoTutorial = () => {
        setModal({
            active: true,
            maskBackground: true,
            title: trans("SLOT_BATTLE__TUTORIAL_VIDEO_HEADER"),
            showCloseButton: true,
            currentKey: "custom-content",
            closeOnOuterClick: false,
            customContent: <VideoModal/>,
            customClasses: 'll-modal--battles-tutorial ll-modal--battles-tutorial--video-modal'
        })
    };

    let bananaDefender = true;

    useEffect(() => () => {
        bananaDefender = false
    }, []);

    useEffect(() => {
        if (isQuestionsShowed && bananaDefender) {
            setBackdropShown(true);
            setBackdropOnClick(() => {
                viewSize === 'mobile' && close();
                setQuestionsShowed(false);
            });
            if (viewSize === 'mobile') {
                setModal({
                    active: true,
                    showHeader: false,
                    currentKey: "custom-content",
                    customContent: <QuestionPopup setQuestionsShowed={setQuestionsShowed}/>,
                    customClasses: 'll-modal--battles-tutorial',
                    closeOnOuterClick: false,
                })
            }
        }
    }, [isQuestionsShowed])

    const BattlesTypesMenu = () => (
        <div className="slots-battles-types-menu">
            {SLOT_BATTLES_TYPES.map((type, index) => (
                <LLElementTypeSetter
                    to={`/slots-battles/${type}`}
                    key={type}
                    className="slots-battles-types-menu__item"
                    onClick={() => slotsBattles.setSlotBattlesType(type)}
                >
                    <div
                        className={cx(
                            "slots-battles-types-menu__game-type",
                            slotBattlesType === type &&
                            "slots-battles-types-menu__game-type--active"
                        )}
                    >
                        {trans(type)}
                    </div>
                </LLElementTypeSetter>
            ))}
            <div className="slots-battles-types-menu__questions"
                 onClick={() => {
                     setQuestionsShowed(!isQuestionsShowed);
                 }}>
                <i className={`doruk-${viewSize === 'desktop' ? 'questions' : 'queston'} slots-battles-types-menu__questions-icon`}/>
                {(isQuestionsShowed && viewSize !== 'mobile') ?
                    <TutorialPopup
                        content={<QuestionPopup setQuestionsShowed={setQuestionsShowed}/>}
                        showIndicator={false}
                        indicatorPosition={viewSize === 'desktop' ? 'center' : 'right'}
                        popupPosition={viewSize === 'desktop' ? 'center' : 'right'}
                    /> : null
                }
            </div>
            {/*{viewSize === 'desktop' && //TODO: Turn on when video tutorial is available*/}
            {/*<div className="slots-battles-types-menu__questions"*/}
            {/*     onClick={() => {*/}
            {/*         OpenVideoTutorial();*/}
            {/*     }}>*/}
            {/*    <i className={`doruk-${viewSize === 'desktop' ? 'streaming-icon' : 'streaming-icon'} slots-battles-types-menu__questions-icon`}/>*/}
            {/*</div>}*/}
        </div>
    );

    const CreateBattleBtn = () => {
        return rooms.length > 0 ?
            <LLBtn2
                customClasses="slots-battles__btn--accent slots-battles__create-btn"
                onClick={() => {
                    if (isLogged) {
                        router.push('/slots-battles/create');
                    } else {
                        user.openLogin();
                    }
                }}
                disabled={(whatWasChanged === "canceled" && isActive) || !canPlaySlotBattles}
                title={!canPlaySlotBattles ? 'You can\'t play slot battles. Please contact live support' : userBattleId ? trans('SLOT_BATTLE__ALREADY_IN_BATTLE') : ''}
            >
                {trans("SLOT_BATTLE__CREATE")}
            </LLBtn2> :
            <div className="slots-battles__text">
                {trans("SLOT_BATTLE__CREATE")}
            </div>

    };

    return (
        <>
            <div className={cx(
                'slots-battles-banner',
                `slots-battles-banner--${viewSize}`,
            )}>
                {bannerLoading ?
                    <div className="slots-battles-banner__loader-container">
                        <LLPreloader/>
                    </div>
                    :
                    banner?.hasImg ?
                        <LLElementTypeSetter
                            className="slots-battles-banner__item slots-battles-banner__item--custom"
                            style={{backgroundImage: `url(${banner?.image})`}}
                            {...parseUrl(banner?.url, user).elementAttributes}
                            onClick={parseUrl(banner?.url, user).someClick}
                        />
                        :
                        <div className="slots-battles-banner__item slots-battles-banner__item--default">
                            {trans('SLOT_BATTLE__BANNER_TEXT')}
                        </div>
                }
            </div>
            <div className={cx(`slots-battles-top-menu slots-battles-top-menu--${viewSize}`)}>
                <div className="slots-battles-top-menu__content ll-full-width-content-wrapper">
                    {(!activeBattle && slotBattlesType !== 'create') ?
                        <>
                            {viewSize === "desktop" &&
                                <>
                                    <div className="slots-battles-top-menu__left">
                                        <BattlesTypesMenu/>
                                        {(tutorialStep === 1) ? <TutorialPopup content={<FirstPopup/>}/> : null}
                                    </div>
                                    <div className="slots-battles-top-menu__center">
                                        <CreateBattleBtn/>
                                    </div>
                                    <FilterSortSearch/>
                                </>
                            }
                            {(viewSize === "mobile" || viewSize === 'tablet') &&
                                <>
                                    <div className="slots-battles-top-menu__filter-btn-search">
                                        <FilterSortSearch>
                                            <CreateBattleBtn/>
                                        </FilterSortSearch>
                                    </div>
                                    <div className="slots-battles-top-menu__slot-category">
                                        <BattlesTypesMenu/>
                                        {(tutorialStep === 1) ? <TutorialPopup content={<FirstPopup/>}/> : null}
                                    </div>
                                </>
                            }
                        </>
                        :
                        <BackToAvailableBtn/>
                    }
                </div>
            </div>
        </>
    );
};

export default inject('store')(observer(SlotBattlesHeader));
