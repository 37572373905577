import React, {Fragment, useEffect, useState} from 'react';
import cx from "classnames";
import debounce from 'lodash/debounce';

import LLBtn2 from "../button/btn";
import {formatNumber, reverseFormatNumber, trans} from "../../../common/utils";
import {LLInput} from "../index";


const presetAmountEditorBetName = {
    0: 'FIRST_BET',
    1: 'SECOND_BET',
    2: 'THIRD_BET'
};

export const PresetAmounts = (
    {
        customerId,
        transformAmount,
        language,
    }
) => {
    const [userPresetAmounts, setUserPresetAmounts] = useState([100, 500, 2000]);
    const [newPresetAmounts, setNewPresetAmounts] = useState([100, 500, 2000]);
    const [isEditorOpened, setEditorOpened] = useState(false);

    useEffect(
        () => {
            const presets = localStorage.getItem('userPresetAmounts_' + [customerId]);
            if (presets?.length) {
                setUserPresetAmounts(JSON.parse(presets).data);
                setNewPresetAmounts(JSON.parse(presets).data);
            } else {
                localStorage.setItem('userPresetAmounts_' + [customerId], JSON.stringify({data: userPresetAmounts}));
            }
        }, []
    );

    return (
        <>
            <div className="coupons__amount-preset">
                {userPresetAmounts.map((preset, i) => (
                    <Fragment key={i}>
                        <LLBtn2
                            customClasses='coupons__amount-preset-btn'
                            onClick={() => {
                                transformAmount(formatNumber(preset, 2, language, '', language === 'aa'))
                            }}
                        >
                            <span>{formatNumber(preset, 2, language, '', language === 'aa')}</span>
                        </LLBtn2>
                        {(userPresetAmounts.length - 1 === i) &&
                            <LLBtn2
                                customClasses={cx('coupons__amount-preset-edit', !isEditorOpened && 'll-btn--outlined')}
                                onClick={() => setEditorOpened(v => !v)}
                            >
                                <i className={cx('coupons__amount-preset-edit-icon', isEditorOpened ? 'doruk-close' : 'doruk-edit')}/>
                            </LLBtn2>
                        }
                    </Fragment>
                ))}
            </div>
            {isEditorOpened && (
                <div className="coupons__amount-preset-editor">
                    {newPresetAmounts.map((preset, i) => (
                        <div key={i} className="amount-preset-editor__item">
                    <span className="amount-preset-editor__item-label">
                        {trans(presetAmountEditorBetName[i])}:
                    </span>
                            <LLInput
                                customClasses="coupons__amount-input"
                                inputAttr={{ inputmode: 'numeric',}}
                                language={language}
                                labelPosition="inside"
                                stylingMode="outlined"
                                value={formatNumber(preset, 2, language, '', language === 'aa')}
                                valueChangeEvent="change"
                                onKeyUp={debounce((e) => {
                                    const newPresets = [...newPresetAmounts];
                                    newPresets[i] = parseFloat(reverseFormatNumber(e.event.target.value)) || 0;
                                    setNewPresetAmounts(newPresets);
                                }, 500)}
                            />
                        </div>
                    ))}
                    <LLBtn2
                        onClick={() => {
                            localStorage.setItem('userPresetAmounts_' + [customerId], JSON.stringify({data: newPresetAmounts}));
                            setUserPresetAmounts(newPresetAmounts);
                        }}
                        customClasses={cx('amount-preset-editor__btn',
                            JSON.stringify(newPresetAmounts) === JSON.stringify(userPresetAmounts) && 'll-btn--disabled')}
                        disabled={JSON.stringify(newPresetAmounts) === JSON.stringify(userPresetAmounts)}
                    >
                        <span>{trans('COUPON_PRESET_SAVE')}</span>
                    </LLBtn2>
                </div>
            )}
        </>
    );
}