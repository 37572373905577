import {getRoot, types} from "mobx-state-tree";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";

import {formatDate} from "../../../../common/utils";


const EventsViews = types
    .model('EventsViews', {})
    .views((self) => ({
        getGroupedByDateEvents(matchIds, forceShowResults) {
            if (['rangedEvents'].includes(getRoot(self).router.prematchDashboardView.view) || forceShowResults) {
                let data = matchIds.reduce((acc, id) => {
                    const match = getRoot(self).betting.matches.matches.get(id);
                    if (match) {
                        const sport = getRoot(self).betting.branch.sports.get(
                            match.sportId
                        );
                        const category = sport?.categories.get(match.categoryId);
                        const tournament = category?.tournaments.get(match.tournamentId);
                        return [
                            ...acc,
                            {
                                ...match,
                                nameOf: match.nameOf,
                                matchUrl: match.matchUrl,
                                mainMarket: match.mainMarket,
                                sportOrder: sport?.order ?? 9999,
                                categoryOrder: category?.order ?? 9999,
                                tournamentOrder: tournament?.order ?? 999999,
                                formatedTimeStart: match.formatedTimeStart,
                            },
                        ];
                    } else {
                        return acc;
                    }
                }, []);

                data = sortBy(
                    data,
                    (dateObj) => new Date(dateObj.timeStart)
                );

                const searchTempResult = data.reduce((entryMap, v) => {
                    const formatedTimeStart = formatDate(
                        new Date(v.timeStart),
                        'weekday, DD month YYYY',
                        getRoot(self).user.language
                    );
                    return {
                        ...entryMap,
                        [v.tournamentId]: {
                            matches: {
                                ...(entryMap[v.tournamentId]?.matches || {}),
                                [formatedTimeStart]: [
                                    ...(entryMap[v.tournamentId]?.matches[
                                        formatedTimeStart
                                        ] || []),
                                    v,
                                ],
                            },
                            sportOrder: v.sportOrder,
                            categoryOrder: v.categoryOrder,
                            tournamentOrder: v.tournamentOrder,
                            sportId: v.sportId,
                            categoryId: v.categoryId,
                            tournamentId: v.tournamentId,
                            nameOf: v.nameOf,
                        },
                    };
                }, {});
                return orderBy(searchTempResult, [
                    'sportOrder',
                    'categoryOrder',
                    'tournamentOrder',
                ]);
            }
            return null;
        },
    }));

export default EventsViews;