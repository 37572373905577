import React, {useEffect, useState} from 'react';

import { withCommon } from '../../../../components';
import {API_PREFIX} from "../../../../common/api/api-url-config";
import apiUrls from "../../../../common/api/api-urls";

const LiveGames = withCommon({
    store: ({ store }) => ({
        viewSize: store.site.status.viewSize,
    }),
    defaultName: 'LiveGamesPage',
    isObserver: true,
})(
    ({
        viewSize,
    }) => {
        const [container, setContainer] = useState({});

        useEffect(() => {
            setContainer(document.querySelector('.js_live-games-container').getBoundingClientRect())
        }, [])

        return (
            <div className={`ll-page live-games--${viewSize} js_live-games-container`}>
                <iframe
                    className={`live-games-iframe--${viewSize}`}
                    title="livegames"
                    src={`${API_PREFIX}${apiUrls.games.openGame}?providerId=230&height=${container?.height}&width=${container?.width}`}
                    frameBorder="0"
                    allowFullScreen
                    scrolling="no"
                />
            </div>
        );
    }
);

export default LiveGames;
