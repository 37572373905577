import { types, getRoot } from "mobx-state-tree";
import { reaction } from "mobx";


let currentReaction;

const PopoverCashout = types
    .model("PopoverCashout", {
        isActive: types.boolean,
        popoverClasses: types.maybeNull(types.string),
        /**
         * Variants:
         * 'bottom-end'
         * 'bottom-start'
         * 'bottom'
         * 'left-end'
         * 'left-start'
         * 'left'
         * 'right-end'
         * 'right-start'
         * 'right'
         * 'top-end'
         * 'top-start'
         * 'top'
         */
        placement: types.maybeNull(types.string)
    })
    .volatile((s) => ({
        anchorEl: {},
        popoverContent: {}
    }))
    .actions((self) => ({
        setPopover({
                       isActive = false,
                       anchorEl = {},
                       popoverContent = "",
                       popoverClasses = "",
                       placement,
                       betId,
                       listType,
                       watch
                   }) {
            self.isActive = isActive;
            self.anchorEl = anchorEl;
            self.popoverContent = popoverContent;
            self.popoverClasses = popoverClasses;
            self.placement = placement;

            const parentBet = getRoot(self).user.userBets[listType].bets.get(betId);
            const reactionValue = () => {
                if (watch === "delete") {
                    return parentBet.isCanBeDeleted
                } else if (watch === "cashout") {
                    return parentBet.cashout
                }
            }

            currentReaction = reaction(() => reactionValue(), (currentValue) => {
                if (watch === "delete") {
                    if (!currentValue) {
                        this.hidePopover();
                    }
                } else if (watch === "cashout") {
                    if (currentValue < 0.01) {
                        this.hidePopover();
                    }
                }
            });

        },
        setAnchorEl(anchorEl) {
            self.anchorEl = anchorEl;
        },
        hidePopover() {
            self.isActive = false;
            self.anchorEl = {};
            if (currentReaction) {
                currentReaction();
            }
        }
    }));

export default PopoverCashout;
