import { flow } from 'mobx-state-tree';

import api from '../../../../../common/api';
import llattempt from '../../../../../common/utils/attempt';

// let qwe = 0;

const liveMatchesFetches = () => ({
    fetchAndConvertDataForLiveMatches: flow(function* fetch() {
        // qwe++;
        // if (qwe > 3) {
        //     return yield llattempt(() => api.betting.getLiveMatches2(), {
        //         withNotifier: false,
        //         at: 'liveMatchesFetches.fetchAndConvertDataForLiveMatches',
        //         withParams: {},
        //     });
        // } else {
        return yield llattempt(() => api.betting.getLiveMatches(), {
            withNotifier: false,
            at: 'liveMatchesFetches.fetchAndConvertDataForLiveMatches',
            withParams: {},
        });
        // }
    }),
});

export default liveMatchesFetches;
